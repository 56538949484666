import { Injectable } from '@angular/core';
import { EntityState, StoreConfig } from '@datorama/akita';
import { StatusPageState, StatusPageStore, initialStatusPageState } from '../status-page-store/status-page.store';

export interface ManageTemplateCounterState extends EntityState, StatusPageState {
    ui: {
        entitiesLoading: boolean;
        showReloadCounters: boolean;
        calculatingCountersInProgress: boolean;

    }
}

const initialState = {
    ui: {
        entitiesLoading: false,
        showReloadCounters: false,
        calculatingCountersInProgress: false
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'manage-template-counter' })
export class ManageTemplateCounterStore extends StatusPageStore<ManageTemplateCounterState> {
    
    constructor() {
        super({...initialState, ...initialStatusPageState});
    }

    public updateEntitiesLoadingState(entitiesLoading: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, entitiesLoading };
            
            return { ...state, ui };
        });
    }

    public updateReloadCountersState(showReloadCounters: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showReloadCounters };
            
            return { ...state, ui };
        });
    }

    public updateCalculatingCountersInProgressState(calculatingCountersInProgress: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, calculatingCountersInProgress };
            
            return { ...state, ui };
        });
    }
}
