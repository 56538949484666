import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { CountersExportRequestFilters } from './counters-export-request-filters.model';


export interface CountersExportState extends EntityState {
    ui: {
        selectedFilterSettingId: number;
        validIntervalDates: boolean;
        activityTypeCategoryIds: Array<number>;
        organizationUnitForActivityIds : Array<number>;
        requestFilters: CountersExportRequestFilters;
    };
}

const initialState: CountersExportState = {
    ui: {
        selectedFilterSettingId: undefined,
        validIntervalDates: true,
        activityTypeCategoryIds: undefined,
        organizationUnitForActivityIds: undefined,
        requestFilters: {
            start: undefined,
            end: undefined,
            organizationUnitIds: undefined,
            resourceTypeIds: undefined,
            extraResourceTypeIds: undefined,
            activityTypeIds: undefined,
            exportUnplannedLeaves: false
        },
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'counters-export' })
export class CountersExportStore extends EntityStore<CountersExportState> {
    constructor() {
        super(initialState);
    }

    public resetState(): void {
        this.update(() => {
            return { ...initialState };
        });
    }

    public updateStartDate(startDate: string): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, start: startDate };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateEndDate(endDate: string): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, end: endDate };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateOrganizationUnitIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, organizationUnitIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateResourceTypeIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, resourceTypeIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateExtraResourceTypeIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, extraResourceTypeIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, activityTypeIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateOrganizationUnitsIdsForActivityTypes(ids: Array<number>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                organizationUnitForActivityIds: ids
            };

            return { ...state, ui };
        });
    }

    public updateActivityTypeCategoryIds(ids: Array<number>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                activityTypeCategoryIds: ids
            };

            return { ...state, ui };
        });
    }

    public updateExportUnplannedLeaves(newState: boolean): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, exportUnplannedLeaves: newState };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedFilterSettingId: id
            };

            return { ...state, ui };
        });
    }

    public updateValidIntervalDates(intervalState: boolean): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                validIntervalDates: intervalState
            };

            return { ...state, ui };
        });
    }

    public updateCountersState(updatedState: CountersExportState): void {
        this.update(() => ({ ...updatedState }));
    }
}
