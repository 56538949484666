import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { StatusPageService } from '../status-page-store/status-page.service';
import { STATUS } from '../status-page-store/status-page.store';
import { AdtCounter } from '../manage-template-store/manage-template.model';
import { ManageTemplateCounterState, ManageTemplateCounterStore } from './manage-template-counter.store';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplateCounterService extends StatusPageService<ManageTemplateCounterState> {
    private ongoingRequestsCount = 0;

    constructor(
        private readonly http: HttpClient,
        protected readonly manageTemplateCounterStore: ManageTemplateCounterStore,
        private readonly errorDialogService: ErrorDialogService,
    ) {
        super(manageTemplateCounterStore);
    }

    public getCounters(id: number, calculate = true): Observable<Array<AdtCounter>> {
        this.manageTemplateCounterStore.updateEntitiesLoadingState(true);
        this.manageTemplateCounterStore.updateCalculatingCountersInProgressState(true);
        
        return this.http.get<Array<AdtCounter>>(`/api/v1/ActivityDemand/Templates/${id}/Counters?calculate=${calculate}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTemplateCounterStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((counters: Array<AdtCounter>) => {
                this.manageTemplateCounterStore.set(counters);
                this.manageTemplateCounterStore.updateEntitiesLoadingState(false);
                this.manageTemplateCounterStore.updateReloadCountersState(false);
            }),
            finalize(() => {
                this.manageTemplateCounterStore.updateCalculatingCountersInProgressState(false);
            }
        ));
    }

    public updateActivityDemandCounters(counters: Array<number> , templateId: number): Observable<Array<AdtCounter>> {
        if (counters.length > 0) {
            this.manageTemplateCounterStore.updateEntitiesLoadingState(true);
            this.ongoingRequestsCount++;
        }

        const counterParams = counters.map((counterId) => ({ activityDemandCounterId: counterId }));

        return this.http.post<Array<AdtCounter>>(`/api/v1/ActivityDemand/Templates/${templateId}/Counters`, counterParams).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTemplateCounterStore.updatePageStatusState(STATUS.DEFAULT);
                this.manageTemplateCounterStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((addedCounters: Array<AdtCounter>) => {
                this.manageTemplateCounterStore.add(addedCounters);
            }),
            finalize(() => {
                this.manageTemplateCounterStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING);
                this.ongoingRequestsCount--; 
                if (this.ongoingRequestsCount === 0) {
                    this.manageTemplateCounterStore.updateEntitiesLoadingState(false); 
                }
            }),
        );
    }

    public deleteActivityDemandCounter(activityDemandTemplateId: number, activityDemandCounterId: number, counterId: number): Observable<void> {
        // NOTE: the Ids here refer to the table "PLN_ADT_Counters_L"
        // "counterId" is the primary key of the table
        this.manageTemplateCounterStore.updateEntitiesLoadingState(true);
        this.ongoingRequestsCount++;

        return this.http.delete<void>(`/api/v1/ActivityDemand/Templates/${activityDemandTemplateId}/Counters/${activityDemandCounterId}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTemplateCounterStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((response) => {
                if (response === null) { // no error was thrown
                    this.manageTemplateCounterStore.remove(counterId);
                }
            }),
            finalize(() => {
                this.ongoingRequestsCount--; 
                if (this.ongoingRequestsCount === 0) {
                    this.manageTemplateCounterStore.updateEntitiesLoadingState(false);
                }
            }),
        );
    }

    public updateReloadCountersState(state: boolean): void {
        this.manageTemplateCounterStore.updateReloadCountersState(state);
    }
}