import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OwsDutySetForFiltering } from './ows-duty-set.model';
import { OwsDutySetState, OwsDutySetStore } from './ows-duty-set.store';
import { OwsDepartmentQuery } from '../../ows-department-mapping/ows-department-store/ows-department.query';

@Injectable({
    providedIn: 'root'
})
export class OwsDutySetQuery extends QueryEntity<OwsDutySetState> {

    constructor(
        protected store: OwsDutySetStore,
        private readonly owsDepartmentQuery: OwsDepartmentQuery
    ) {
        super(store);
    }

    public getDutySetsForFiltering(): Observable<Array<OwsDutySetForFiltering>> {
        return combineLatest([
            this.selectAll(),
            this.owsDepartmentQuery.selectAll()
        ]).pipe(
            map(([dutySets, departments]) => dutySets.map(dutySet => {
                let displayName = dutySet.name;
                const matchingDepartment = departments.find(d => d.id === dutySet.departmentId);
                if (matchingDepartment) {
                    displayName += ' (' + matchingDepartment.name + ')';
                }

                return {
                    ...dutySet, 
                    displayName
                };
            }))
        );
    }
}
