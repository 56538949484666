import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Counter } from '../counters-store/counters.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CountersScenarioState extends EntityState<Counter> {}
const initialState: CountersScenarioState = {};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'counters-scenario' })
export class CountersScenarioStore extends EntityStore<CountersScenarioState> {
    constructor() {
        super(initialState);
    }
}
