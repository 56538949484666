/* eslint-disable prefer-arrow/prefer-arrow-functions */
import * as moment from 'moment-timezone';

/**
 * Creates a new date for the given input date and timezone in such a way that the timezone difference between the specified timezone and
 * local (browser/OS) timezone is compensated.
 *
 * THIS FUNCTION SHOULD ONLY BE USED AS A WORKAROUND FOR THE FACT THAT THE BRYNTUM SCHEDULER DOES NOT SUPPORT TIMEZONES!
 * **DON'T USE IT IN OTHER PLACES, AS THIS IS NOT THE RECOMMENDED WAY OF HANDLING TIME ZONES.**
 * Should you need similar features please make use of `moment-timezone`, to create timezone aware dates with proper display formatting.
 *
 * @param  date     Date which needs to be adjusted.
 * @param  timezone Target timezone in which the date should be presented.
 * @param  toLocal  Whether the source date should be converted from the specified time zone to local time (`true`). If the conversion
 *                  should be made the other way around then this argument should set to `false`.
 * @return          A new date which has been adjusted for the specified time zone.
 */
export function adjustDateForTimezone(date: Date, timezone: string, toLocal: boolean = true): Date {
    const newDate = moment.tz(date, timezone);
    const offsetDifference = moment(date).utcOffset() - newDate.utcOffset();
    const sign = toLocal ? -1 : 1;

    return newDate.add(sign * offsetDifference, 'minutes').toDate();
}

/**
 * Creates a new date for the given input date and timezone in such a way that the timezone difference between the specified timezone and
 * local (browser/OS) timezone is compensated.
 *
 * THIS FUNCTION SHOULD ONLY BE USED AS A WORKAROUND FOR THE FACT THAT THE BRYNTUM SCHEDULER DOES NOT SUPPORT TIMEZONES!
 * **DON'T USE IT IN OTHER PLACES, AS THIS IS NOT THE RECOMMENDED WAY OF HANDLING TIME ZONES.**
 * Should you need similar features please make use of `moment-timezone`, to create timezone aware dates with proper display formatting.
 *
 * @param  date     Date which needs to be adjusted.
 * @param  timezone Target timezone in which the date should be presented.
 * @param  toLocal  Whether the source date should be converted from the specified time zone to local time (`true`). If the conversion
 *                  should be made the other way around then this argument should set to `false`.
 * @return          A new date which has been adjusted for the specified time zone.
 */
export function adjustMomentDateForTimezone(date: moment.Moment, timezone: string, toLocal: boolean = true): moment.Moment {
    const newDate = moment.tz(date, timezone);
    const offsetDifference = moment(date).utcOffset() - newDate.utcOffset();
    const sign = toLocal ? -1 : 1;

    return newDate.add(sign * offsetDifference, 'minutes');
}
