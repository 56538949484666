<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="Treatment OWS"
                    [entities]="workTreatmentTexts$ | async"
                    [searchProperties]="['displayName']"
                    showCheckboxes="true"
                    hideAddButton="true"
                    (selectionClickChanged)="onSelectWorkTreatmentText($event)"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="Activity Type OWS"
                    [entities]="visibleOwsActivityTypes$ | async"
                    (selectionClickChanged)="onSelectOwsActivityType($event)"
                    showCheckboxes="true"
                    hideAddButton="true"
                    [searchProperties]="['displayName']"
                >
                </app-entity-list-panel>
            </div>
        </div>
    </div>
</div>
