<div class="schedule__overview__container" *ngIf="daypartsReadySubject | async">
    <app-empty-filters-panel
        [collapsePanelMessage]="'Show schedule'"
        [filtersPanelState]="filtersPanelState$ | async"
        (updateShowFiltersState)="updateShowFiltersStateAndGetScheduleData($event)">
        <app-schedule-filters-header
            filtersHeader
            (hideFiltersPanelOnSelectedFilterSetting)="hidePanelOnSelectedFilterSetting()"
        ></app-schedule-filters-header>

        <div filtersContent class="filters__content">
            <app-schedule-filters></app-schedule-filters>
        </div>
    </app-empty-filters-panel>

    <div class="empty__state__container"  *ngIf="(initialLoadingFinished$ | async) === true && (filterSettingsAvailable$ | async) === false && (loading$ | async) === false  && (filtersPanelState$ | async) === true">
        <div class="empty__state__container__content">
            <mat-icon>calendar_today</mat-icon>
            <div class="empty__state__container__message__huge">
                {{ "No schedules to show yet." | translate }}
            </div>
            <div>
                {{ "Use the filters panel to create a planning overview." | translate }}
            </div>
        </div>        
    </div>

    <div class="overlay-spinner" *ngIf="(loading$ | async) === true">
        <mat-spinner diameter="100"></mat-spinner>
    </div>

    <div class="schedule__container" *ngIf="(initialLoadingFinished$ | async) === true && (filtersPanelState$ | async) === false">
        <div class="schedule">
            <app-schedule-calendar></app-schedule-calendar>
        </div>
    </div>
</div>