/* eslint-disable @angular-eslint/use-component-selector */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { SHOW_OPTIONS } from '../../schedule-helpers/enums';

export const SCHEDULE_ACTIVITY_TAG = 'app-schedule-activity-template';

@Component({
    selector: SCHEDULE_ACTIVITY_TAG,
    templateUrl: './schedule-activity-template.component.html',
    styleUrls: ['./schedule-activity-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleActivityTemplateComponent {
    @Input() public set id(activityTypeId: string) {
        // eslint-disable-next-line radix
        const parsedId = parseInt(activityTypeId);
        if (!Number.isNaN(parsedId)) {
            this.setData(parsedId);
        }
    }

    public allLeaves: Array<ActivityType>;
    public showActivityName = false;
    public showActivityNameLeaf = false;
    public showActivityShortName = false;
    public showActivityShortNameLeaf = false;
    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly activityTypeQuery: ActivityTypeQuery,
    ) {}

    private setData(activityTypeId: number): void {
        const showOptions = this.scheduleQuery.getActivityShowOptionsSync();
        this.showActivityName = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_NAME)?.state;
        this.showActivityNameLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_NAME_LEAF)?.state;
        this.showActivityShortName = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME)?.state;
        this.showActivityShortNameLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_LEAF)?.state;
        
        const allSelectedLeafIds = this.scheduleQuery.getAllSelectedActivityTypesFromParentId(activityTypeId)?.map(l => l.id) ?? [];

        // Only include selected leaves for processing
        this.allLeaves = this.activityTypeQuery.getFullActivityStructureAndSortByRootIdSync(activityTypeId);
        this.allLeaves = this.allLeaves?.filter(leaf => allSelectedLeafIds.includes(leaf.id));
    }   
}