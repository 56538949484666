import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Resource } from './resource.model';
import { ResourceState, ResourceStore } from './resource.store';


@Injectable({
    providedIn: 'root'
})
export class ResourceQuery extends QueryEntity<ResourceState> {

    constructor(
        protected store: ResourceStore
    ) {
        super(store);
    }

    public getResources(): Observable<Array<Resource>> {
        return this.selectAll().pipe(map(skills =>
            skills.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getEntitiesLoading(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoading);
    }
    
    public getResourcesSync(): Array<Resource> {
        const resources = Object.values(this.getValue().entities);

        return resources.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getValidResourceIdsForSkill(skillId: number): Array<number> {
        const resources = this.getResourcesSync();

        return resources
            .filter(r => (r.skillMemberships || []).some(m => m.skillId === skillId))
            .map(r => r.id);
    }

    public getValidResourceIdsForOrganizationUnit(unitId: number): Array<number> {
        const resources = this.getResourcesSync();

        return resources
            .filter(r => (r.organizationUnitMemberships || []).some(m => m.organizationUnitId === unitId))
            .map(r => r.id);
    }

    public getValidResourceIdsForResourceType(resourceTypeId: number): Array<number> {
        const resources = this.getResourcesSync();

        return resources
            .filter(r => (r.resourceTypeMemberships || []).some(m => m.resourceTypeId === resourceTypeId))
            .map(r => r.id);
    }
}
