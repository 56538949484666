import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { FilterSetting } from './filter-setting.model';

export interface FilterSettingState extends EntityState<FilterSetting> {
    ui: {
        selectedId: number;
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        selectedId: undefined,
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filter-setting' })
export class FilterSettingStore extends EntityStore<FilterSettingState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedId: id
            };

            return { ...state, ui };
        });
    }

    public updateEntitiesLoadingState(loading: boolean): void {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}
