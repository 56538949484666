import { Injectable } from '@angular/core';

import { COUNTERS_COUNTER_TYPE, COUNTERS_GROUPING_OPTIONS, COUNTERS_STORAGE_KEYS, COUNTERS_TOTALS_OPTIONS } from './enums';
import { CountersEnumToFilterItem } from './filters.model';

type EnumType<T> = {
    [K in keyof T]: T[K];
};

@Injectable({
    providedIn: 'root'
})
export class CountersFiltersService {
    public getInitialGroupingOptions(): Array<CountersEnumToFilterItem> {
        let id = 1;

        return Object.keys(COUNTERS_GROUPING_OPTIONS).map(key => {
            const newFilterItem: CountersEnumToFilterItem = ({ id, displayName: COUNTERS_GROUPING_OPTIONS[key], type: COUNTERS_GROUPING_OPTIONS[key] });
            id++;

            return newFilterItem;
        });
    }

    public getInitialTotals(): Array<CountersEnumToFilterItem> {
        let id = 1;

        return Object.keys(COUNTERS_TOTALS_OPTIONS).map(key => {
            const newFilterItem: CountersEnumToFilterItem = ({ id, displayName: COUNTERS_TOTALS_OPTIONS[key], type: COUNTERS_TOTALS_OPTIONS[key] });
            id++;

            return newFilterItem;
        });
    }

    public getInitialCounterTypes(): Array<CountersEnumToFilterItem> {
        let id = 1;

        return Object.keys(COUNTERS_COUNTER_TYPE).map(key => {
            const newFilterItem: CountersEnumToFilterItem = ({ id, displayName: COUNTERS_COUNTER_TYPE[key], type: COUNTERS_COUNTER_TYPE[key] });
            id++;

            return newFilterItem;
        });
    }

    public setCountersItemInStorage(storageKey: COUNTERS_STORAGE_KEYS, value: any): void {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }

    public getCountersItemFromStorage<T>(storageKey: COUNTERS_STORAGE_KEYS): T {
        return JSON.parse(localStorage.getItem(storageKey));
    }

    public transformIdsToEnumForTotalOptions<T extends Record<string, V>, V extends number | string>(ids: Array<number>,enumValues: EnumType<T>): Array<T[keyof T]> {
        const transformedArray: Array<T[keyof T]> = [];
      
        for (const id of ids) {
          const values = Object.values(enumValues) as Array<T[keyof T]>;
          if (id >= 1 && id <= values.length) {
            transformedArray.push(values[id - 1]);
          }
        }
      
        return transformedArray;
    }
      
    public transformEnumToIds<T extends Record<string, V>, V extends number | string>(values: Array<T[keyof T]>, enumValues: EnumType<T>): Array<number> {
        const transformedArray: Array<number> = [];
      
        for (const value of values) {
          const id = Object.values(enumValues).indexOf(value) + 1;
          if (id !== 0) {
            transformedArray.push(id);
          }
        }
      
        return transformedArray;
    }

    public transformIdsToEnum<T extends Record<string, V>, V extends number | string>(ids: Array<number>, enumValues: EnumType<T>): Array<T[keyof T]> {
        const transformedArray: Array<T[keyof T]> = [];
      
        for (const id of ids) {
          const enumValue = Object.values(enumValues)[id - 1];
          if (enumValue !== undefined) {
            transformedArray.push(enumValue);
          }
        }
      
        return transformedArray;
    }
}
