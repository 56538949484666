import { Injectable } from '@angular/core';
import { combineQueries, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityUI, EntityUIState } from '../entity-ui-models';

import { User } from './user.model';
import { UserState, UserStore } from './user.store';

@Injectable({
    providedIn: 'root'
})
export class UserQuery extends QueryEntity<UserState> {
    public ui: EntityUIQuery<EntityUIState>;
    
    constructor(
        protected store: UserStore
    ) {
        super(store);
        this.createUIQuery();
    }

    public getUsers(): Observable<Array<User>> {
        return combineQueries([
            this.selectAll(),
            this.ui.selectAll()
        ]).pipe(map(([users, usersUI]) => users
            .map(user => ({...user, ...usersUI.find(u => u.id === user.id)}))
            .sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getUsersSync(): Array<User> {
        return Object.values(this.getValue().entities);
    }

    public getUser(id: number): Observable<User> {
        return this.selectEntity(id);
    }

    public getUIUser(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }

    public getUserSync(id: number): User {
        return this.getEntity(id);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }
}
