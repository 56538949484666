import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { EntityUI, EntityUIState, getDefaultUIState } from '../entity-ui-models';
import { ManageCounter } from './manage-counter.model';

export interface ManageCounterState extends EntityState<ManageCounter> {
    ui: {
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'manage-counter' })
export class ManageCounterStore extends EntityStore<ManageCounterState> {
    public ui: EntityUIStore<EntityUIState>;

    constructor() {
        super(initialState);
        this.createUIStore().setInitialEntityState<EntityUI>(getDefaultUIState());
    }

    public updateEntitiesLoadingState(entitiesLoading: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, entitiesLoading };
            
            return { ...state, ui };
        });
    }
}
