import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, skip, withLatestFrom } from 'rxjs/operators';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';

import { OrganizationUnitService } from '../../..//shared/stores/organization-unit-store/organization-unit.service';
import { DateTimeUtilityService } from '../../../shared/services/date-time-utility.service';
import { ActivityTypeCategoryQuery } from '../../../shared/stores/activity-type-category-store/activity-type-category.query';
import {
    ActivityTypeCategoryService,
} from '../../../shared/stores/activity-type-category-store/activity-type-category.service';
import { ActivityTypeService } from '../../../shared/stores/activity-type-store/activity-type.service';
import { OrganizationUnitQuery } from '../../../shared/stores/organization-unit-store/organization-unit.query';
import { ResourceTypeQuery } from '../../../shared/stores/resource-type-store/resource-type.query';
import { ResourceTypeService } from '../../../shared/stores/resource-type-store/resource-type.service';
import { CountersFiltersOptions, CountersFiltersSelectedOptions } from '../counters/counters-helpers/filters.model';

import { CountersExportQuery } from './stores/counters-export.query';
import { CountersExportService } from './stores/counters-export.service';
import { CountersExportState } from './stores/counters-export.store';

export interface CountersExportConfiguration {
    start: string;
    end: string;
    resourceOrganizationUnitIds: Array<number>;
    resourceTypeIds: Array<number>;
    activityTypeIds: Array<number>;
    activityTypeCategoryIds: Array<number>;
    organizationUnitForActivityIds: Array<number>;
}

@Component({
    selector: 'app-counters-export',
    templateUrl: './counters-export.component.html',
    styleUrls: ['./counters-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountersExportComponent implements OnInit, OnDestroy {
    public countersFiltersOptions: CountersFiltersOptions = {
        organizationUnits$: of([]),
        resourceTypes$: of([]),
        extraResourceTypes$: of([]),
        skills$: of([]),
        activityTypes$: of([]),
        activityTypeCategories$: of([]),
        totals$: of([]),
        counterTypes$: of([]),
        scenarios$: of([]),
        groupingOptions$: of([]),
        enableActivityTypeDetailState$: of(false),
        startDate$: of(''),
        endDate$: of(''),
        exportUnplannedLeavesState$: of(false)
    };

    public countersFiltersSelectedOptions: CountersFiltersSelectedOptions = {
        selectedOrganizationUnits$: of([]),
        selectedOrganizationUnitsForActivities$: of([]),
        selectedActivityTypeIds$: of([]),
        selectedGroupingOptions$: of([]),
        selectedScenario$: of(),
        selectedActivityTypeCategoryIds$: of([]),
        selectedCounterTypes$: of([]),
        selectedResourceTypeIds$: of([]),
        selectedExtraResourceTypeIds$: of([]),
        selectedSkillIds$: of([]),
        selectedTotals$: of([]),
    };

    public filterSettingControl = new UntypedFormControl('', [Validators.required]);

    public isExportEnabled$: Observable<boolean>;
    public exportCountersReportRequest$: Observable<boolean>;
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public isLoadingFilters$!: Observable<boolean>;

    public isLoadingExportFileSubject = new BehaviorSubject<boolean>(false);

    public exportFiltersState: CountersExportState;

    private readonly subscription = new Subscription();

    constructor(
        private readonly exportService: CountersExportService,
        private readonly exportQuery: CountersExportQuery,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeCategoryService: ActivityTypeCategoryService,
        private readonly activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
        private readonly utilsService: UtilsService,
    ) { }

    public ngOnInit(): void {
        const countersConfig = history.state as CountersExportConfiguration;
        this.initializeCountersExportState(countersConfig);

        this.setStartAndEndDates();
        this.setResourceTypesFilter();
        this.setExtraResourceTypesFilter();
        this.setActivityTypeFilter();
        this.setActivityTypeCategoryFilter();
        this.setOrganizationUnitFilter();
        this.setExportUnplannedLeaves();
        this.setOrganizationUnitForActivitiesFilter();

        this.isExportEnabled$ = this.exportQuery.getRequestFiltersValidityState();
        this.isLoadingFilters$ = this.exportQuery.loadedCountersExportFilters();

        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.EXPORT_ACTIVITIES_FILTER_SETTING).pipe(
            map(filters => filters.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
        );
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();

        this.setCurrentFilterSetting();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.exportService.resetState();
    }

    public exportReports(): void {
        this.isLoadingExportFileSubject.next(true);
        this.exportCountersReportRequest$ = this.exportService.getCountersReport();

        this.subscription.add(
            this.exportCountersReportRequest$.subscribe(
                () => this.isLoadingExportFileSubject.next(false),
                () => this.isLoadingExportFileSubject.next(false)
            )
        );
    }

    public onSelectedResourceTypeIdsChanged(ids: Array<number>): void {
        const storeIds = this.exportQuery.getSelectedResourceTypeIdsSync();
        ids = ids.length > 0 ? ids : undefined;

        if (!this.utilsService.arraysEqual(storeIds, ids) && !this.resourceTypeQuery.getEntitiesLoadingStateSync()) {
            this.exportService.updateResourceTypeIds(ids);
        }
    }

    public onSelectedExtraResourceTypeIdsChanged(ids: Array<number>): void {
        const storeIds = this.exportQuery.getSelectedExtraResourceTypeIdsSync();
        ids = ids.length > 0 ? ids : undefined;

        if (!this.utilsService.arraysEqual(storeIds, ids)) {
            this.exportService.updateExtraResourceTypeIds(ids);
        }
    }

    public onSelectedActivityTypeIdsChanged(ids: Array<number>): void {
        this.exportService.updateActivityTypeIds(ids);
    }

    public onSelectedActivityTypeCategoryIdsChanged(ids: Array<number>): void {
        this.exportService.updateActivityTypeCategoryIds(ids);
    }

    public onOrganizationUnitsForActivityIdsChanges(ids: Array<number>): void {
        this.exportService.updateOrganizationUnitsIdsForActivityTypes(ids);
    }

    public onStartDateChanged(date: string): void {
        this.exportService.updateStartDate(date);
    }

    public onEndDateChanged(date: string): void {
        this.exportService.updateEndDate(date);
    }

    public onEnabledExportUnplannedLeavesStateChanged(newState: boolean): void {
        this.exportService.updateExportUnplannedLeavesState(newState);
    }

    public onSelectedOrganizationUnitIdsChanged(ids: Array<number>): void {
        this.exportService.updateOrganizationUnitIds(ids);
    }

    public onIntervalDatesValidityStateChanged(state: boolean): void {
        this.exportService.updateValidIntervalDates(state);
    }

    public setCurrentFilterSetting(): void {
        this.subscription.add(
            combineLatest([
                this.selectedFilterSettingId$,
                this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.EXPORT_ACTIVITIES_FILTER_SETTING)
            ]).pipe(
                distinctUntilChanged()
            ).subscribe(([id, filterSettings]) => {
                if (this.exportFiltersState) {
                    this.exportService.updateCountersState(this.exportFiltersState);
                }

                if (filterSettings && filterSettings.length > 0 && !id && !this.exportFiltersState) {
                    const firstFilterId = this.filterSettingQuery.getFirstFilterSettingIdAvailableByType(FILTER_SETTING_TYPE.EXPORT_ACTIVITIES_FILTER_SETTING);

                    this.filterSettingControl.setValue(firstFilterId, { emitEvent: false });
                    this.filterSettingService.updateSelectedFilterSettingsId(firstFilterId);
                }

                if (id) {
                    this.filterSettingControl.setValue(id, { emitEvent: false });

                    this.exportFiltersState = undefined;
                }
            })
        );
    }

    public openedFilterSettingSelect(state: boolean): void {
        if (state) {
            return;
        }

        const id = this.filterSettingControl.value;
        this.filterSettingService.updateSelectedFilterSettingsId(id);
    }

    private setOrganizationUnitFilter(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();

        this.countersFiltersSelectedOptions.selectedOrganizationUnits$ = combineLatest([
            this.exportQuery.getSelectedOrganizationUnitIds(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([selectedOrganizationUnitIds, state]) => !state && !!selectedOrganizationUnitIds),
            map(([_]) =>  this.exportQuery.getSelectedOrganizationUnitsSync())
        );

        this.countersFiltersOptions.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();

        this.subscription.add(
            this.exportQuery.getSelectedOrganizationUnitIds()
                .pipe(filter(ids => !!ids))
                .subscribe(ids => {
                    this.resourceTypeService.getReportResourceTypes(ids).pipe(first()).subscribe();
                })
        );
    }

    private setResourceTypesFilter(): void {
        this.countersFiltersOptions.resourceTypes$ = combineLatest([
            this.exportQuery.getSelectedExtraResourceTypeIds(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([, state]) => !state),
            map(([extraResourceTypeIds, _]) => {
                const resourceTypes = this.resourceTypeQuery.getResourceTypesSync();
                const mutableResTypes = resourceTypes.map(rt => {
                    return { ...rt };
                });

                return mutableResTypes.filter((resourceType) => 
                    !extraResourceTypeIds ? true : !extraResourceTypeIds.find((id) => id === resourceType.id)
                );
            })
        );

        this.countersFiltersSelectedOptions.selectedResourceTypeIds$ =  this.countersFiltersOptions.resourceTypes$.pipe(
            withLatestFrom(this.exportQuery.getSelectedResourceTypeIds()),
            map(([_, ids]) => ids)
        );
    }

    private setExtraResourceTypesFilter(): void {
        this.countersFiltersOptions.extraResourceTypes$ = combineLatest(([
            this.exportQuery.getSelectedResourceTypeIds(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
        ])).pipe(
            filter(([_, state]) => !state),
            map(([selectedResourceTypeIds, _]) => {
                const resourceTypes = this.resourceTypeQuery.getResourceTypesSync();
                const mutableResTypes = resourceTypes.map(rt => {
                    return { ...rt };
                });

                return mutableResTypes.filter((resourceType) => 
                    !selectedResourceTypeIds ? true : !selectedResourceTypeIds.find((id) => id === resourceType.id)
                );
            }),
        );

        this.countersFiltersSelectedOptions.selectedExtraResourceTypeIds$ = this.countersFiltersOptions.extraResourceTypes$.pipe(
            withLatestFrom(this.exportQuery.getSelectedExtraResourceTypeIds()),
            map(([_, ids]) => ids)
        );
    }

    private setOrganizationUnitForActivitiesFilter(): void {
        this.countersFiltersSelectedOptions.selectedOrganizationUnitsForActivities$ = this.exportQuery.getSelectedOrganizationUnitForActivityIds();
    }

    private setActivityTypeCategoryFilter(): void {
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();

        this.countersFiltersSelectedOptions.selectedActivityTypeCategoryIds$ = this.exportQuery.getSelectedActivityTypeCategoryIds();

        this.countersFiltersOptions.activityTypeCategories$ = combineLatest([
            this.activityTypeCategoryQuery.getActivityTypeCategories().pipe(skip(1)),
            this.exportQuery.getSelectedActivityTypeCategoryIds(),
            this.exportQuery.getSelectedFilterSettingId()
        ]).pipe(
            map(([categories, _, __]) => {

                return categories;
            })
        );

        
        const activityTypeCategoryIdsOnFilterChanged$ = this.exportQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.countersFiltersOptions.activityTypeCategories$),
            filter(([_, selectedActivityTypeCategories]) => selectedActivityTypeCategories.length > 0),
            map(([_, selectedActivityTypeCategories]) => selectedActivityTypeCategories)
        );

        merge(
            activityTypeCategoryIdsOnFilterChanged$,
            this.countersFiltersOptions.activityTypeCategories$.pipe(filter(activityTypeCategories => activityTypeCategories.length > 0), first())
        ).subscribe(activityTypeCategories => {
            let selectedActTypeCategoryIds = this.exportQuery.getSelectedActivityTypeCategoryIdsSync();
            if (!selectedActTypeCategoryIds) {
                selectedActTypeCategoryIds = activityTypeCategories.map(cat => cat.id);
            }

            const selectedActivityTypeCategories = activityTypeCategories.filter(cat => selectedActTypeCategoryIds?.includes(cat.id)).map(cat => cat.id);

            this.exportService.updateActivityTypeCategoryIds(selectedActivityTypeCategories);
        });
    }

    private initializeCountersExportState(config: CountersExportConfiguration | undefined): void {
        this.exportService.updateStartDate(this.dateTimeUtilityService.getFirstDayOfTheCurrentMonth(true));
        this.exportService.updateEndDate(this.dateTimeUtilityService.getLastDayOfTheCurrentMonth(true));

        if (config === undefined || Object.keys(config).length <= 1) {
            return;
        }

        const updatedState: CountersExportState = {
            ui: {
                selectedFilterSettingId: this.exportQuery.getSelectedFilterSettingIdSync(),
                validIntervalDates: true,
                activityTypeCategoryIds: config.activityTypeCategoryIds,
                organizationUnitForActivityIds: config.organizationUnitForActivityIds,
                requestFilters: {
                    start: config.start,
                    end: config.end,
                    organizationUnitIds: config.resourceOrganizationUnitIds,
                    resourceTypeIds: config.resourceTypeIds,
                    extraResourceTypeIds: this.exportQuery.getSelectedExtraResourceTypeIdsSync(),
                    activityTypeIds: config.activityTypeIds,
                    exportUnplannedLeaves: this.exportQuery.getExportUnplannedLeavesStateSync()
                }
            }
        };

        this.exportFiltersState = updatedState;
    }

    private setActivityTypeFilter(): void {
        this.activityTypeService.getMainActivityTypes().pipe(first()).subscribe();

        this.countersFiltersOptions.activityTypes$ = combineLatest([
            this.exportQuery.getSelectedFilterSettingId(),
            this.activityTypeQuery.getActivityTypes()
        ]).pipe(
            map(([_, actTypes]) => actTypes)
        );

        this.countersFiltersSelectedOptions.selectedActivityTypeIds$ = this.exportQuery.getActivityTypeSelectedIds();
    }

    private setStartAndEndDates(): void {
        this.countersFiltersOptions.startDate$ = this.exportQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.exportQuery.getSelectedStartDate()),
            map(([_, start]) => start)
        );

        this.countersFiltersOptions.endDate$ = this.exportQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.exportQuery.getSelectedEndDate()),
            map(([_, end]) => end)
        );
    }

    private setExportUnplannedLeaves(): void {
        this.countersFiltersOptions.exportUnplannedLeavesState$ = this.exportQuery.getExportUnplannedLeavesState();
    }
}
