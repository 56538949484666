import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/language';
import {
    RESOURCE_PROPERTY_TYPE,
    ResourcePropertyLanguage,
} from 'src/app/shared/stores/resource-property-language-store/resource-property-language.model';

import {
    ScheduleShowOption,
} from '../stores/schedule-store/schedule.model';
import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES, SCHEDULE_STORAGE_KEYS, SHOW_OPTIONS } from './enums';

export interface GroupingOption {
    type: GROUPING_OPTIONS;
    value: string;
}

export interface DaterangeTypes {
    type: SCHEDULE_DATERANGE_TYPES;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleHelperService {
    constructor(
        private readonly translateService: TranslateService,
        private readonly languageService: LanguageService
    ) { }

    public getInitialShowOptions(isFullUser: boolean): Array<ScheduleShowOption> {
        const showOptions = [
            {
                value: SHOW_OPTIONS.SHOW_ACTIVE_PANEL,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_SAT_SUN,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_FILL,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ROOT,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_TIME,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_NAME,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_TIME_LEAF,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_NAME_LEAF,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_LEAF,
                state: true
            },
        ];

        if (!isFullUser) {
            showOptions.splice(1, 0, {
                value: SHOW_OPTIONS.SHOW_ONLY_ME,
                state: false
            })
        }

        return showOptions;
    }

    public getInitialActivityShowOptions(isFullUser: boolean): Array<ScheduleShowOption> {
        const showOptions = [
            {
                value: SHOW_OPTIONS.SHOW_ACTIVE_PANEL,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_SAT_SUN,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_FILL,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_TIME,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_NAME,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_TIME_LEAF,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_NAME_LEAF,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_LEAF,
                state: true
            },
        ];

        if (!isFullUser) {
            showOptions.splice(1, 0, {
                value: SHOW_OPTIONS.SHOW_ONLY_ME,
                state: false
            })
        }

        return showOptions;
    }

    public replaceMissingShowOptionsWithDefaults(showOptions: Array<any>, isFullUser: boolean): Array<ScheduleShowOption> {
        const defaultShowOptionValues: Array<ScheduleShowOption> = this.getInitialShowOptions(isFullUser);
        const updatedShowOptions: Array<ScheduleShowOption> = [];

        const customToEnumMappingOldShowOptions: { [key: string]: SHOW_OPTIONS } = {
            'Show sat/sun': SHOW_OPTIONS.SHOW_SAT_SUN,
            'Show start time and end time': SHOW_OPTIONS.SHOW_ACTIVITY_TIME,
            'Show start time and end time of extra resource': SHOW_OPTIONS.SHOW_ACTIVITY_TIME_LEAF,
            'Show activity name': SHOW_OPTIONS.SHOW_ACTIVITY_NAME,
            'Show activity name of extra resource': SHOW_OPTIONS.SHOW_ACTIVITY_NAME_LEAF,
            'Show activity short name': SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME,
            'Show activity short name of extra resource': SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_LEAF,
        };
    
        const processedOptions: { [key: string]: boolean } = {};

        for (const option of showOptions) {
            const enumValue = customToEnumMappingOldShowOptions[option.value];
            if (enumValue) {
                updatedShowOptions.push({ value: enumValue, state: option.state });
                processedOptions[enumValue] = true;
            }
        }
    
        for (const defaultOption of defaultShowOptionValues) {
            if (!processedOptions[defaultOption.value] && !Object.values(SHOW_OPTIONS).includes(defaultOption.value)) {
                updatedShowOptions.push(defaultOption);
                processedOptions[defaultOption.value] = true;
            } else if(!processedOptions[defaultOption.value] && Object.values(SHOW_OPTIONS).includes(defaultOption.value)){
                const showOption = showOptions.find(option => option.value === defaultOption.value);
                const stateShowOpt = showOption ? showOption.state : defaultOption.state;
                updatedShowOptions.push({ value: defaultOption.value, state: stateShowOpt });
            }
        }    
        
        return updatedShowOptions;
    }
    
    public getInitialGroupingOptions(): Array<GroupingOption> {
        const groupingOptions = [
            { type: GROUPING_OPTIONS.NO_GROUPING, value: 'No grouping' },
            { type: GROUPING_OPTIONS.RESOURCE_TYPE, value: 'Resource type' },
            { type: GROUPING_OPTIONS.ORGANIZATION_UNIT, value: 'Organization unit' }
        ];

        return groupingOptions;
    }

    public convertDaterangeTypes(oldNumberOfDays: any, daterangeType: SCHEDULE_DATERANGE_TYPES): SCHEDULE_DATERANGE_TYPES {
        if (daterangeType !== undefined) {
            return daterangeType;
        } else {
            switch (oldNumberOfDays) {
                case 1:
                    return SCHEDULE_DATERANGE_TYPES.DAY;
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 14:
                    return SCHEDULE_DATERANGE_TYPES.WEEK;
                case 28:
                    return SCHEDULE_DATERANGE_TYPES.MONTH;
                default: {
                    return SCHEDULE_DATERANGE_TYPES.WEEK;
                }
            }
        }
    }

    public getDaterangeTypeOptions(): Array<DaterangeTypes> {
        const daterangeTypes = [
            { type: SCHEDULE_DATERANGE_TYPES.DAY, value: 'Day' },
            { type: SCHEDULE_DATERANGE_TYPES.WEEK, value: 'Week' },
            { type: SCHEDULE_DATERANGE_TYPES.MONTH, value: 'Month' },
            { type: SCHEDULE_DATERANGE_TYPES.CONFIGURABLE, value: 'Configurable' }
        ];

        return daterangeTypes;
    }

    public getStandardResourceProperties(): Array<ResourcePropertyLanguage> {
        // 'Display name' and 'Email address' are standard properties of a resource,
        //      but are added in the list of resource properties to be able to filter them along with the extra resource properties.
        //      added in the same list of resource properties with the extra resource properties
        // The resourcePropertyIds for the standard properties are -1, -2,
        //       so the ids won't overlap with the ones of the extra properties.
        const standardResourceProperties: Array<ResourcePropertyLanguage> = [
            {
                languageCode: this.languageService.getCurrentLanguage(),
                resourcePropertyId: -1,
                resourcePropertyType: RESOURCE_PROPERTY_TYPE.DISPLAY_NAME,
                text: this.translateService.instant('Display name')
            },
            {
                languageCode: this.languageService.getCurrentLanguage(),
                resourcePropertyId: -2,
                resourcePropertyType: RESOURCE_PROPERTY_TYPE.EMAIL_ADDRESS,
                text: this.translateService.instant('Email address')
            }
        ];

        return standardResourceProperties;
    }

    public setInStorage(storageKey: SCHEDULE_STORAGE_KEYS, value: any): void {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }

    public getScheduleItemFromStorage<T>(storageKey: SCHEDULE_STORAGE_KEYS): T {
        return JSON.parse(localStorage.getItem(storageKey));
    }
}
