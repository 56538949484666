<div class="filters__panel">
    <div class="top-filters">
        <div class="date_organizations_container">
            <div class="date__input">
                <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" data-cy="daterangeOverviewField">
                    <mat-label translate>Daterange</mat-label>
                    <mat-select
                        #daterangeTypeSelect
                        data-cy="OverviewDaterangeTypeSelect"
                        (openedChange)="openedDaterangeTypeSelect($event)"
                        [value]="dateRangeTypeSelected$ | async"
                    >
                        <mat-option *ngFor="let daterangeType of daterangeTypes" [value]="daterangeType.type">{{
                            daterangeType.value | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="default-mat-form-field">
                <app-multiselect-tree
                    data-cy="ScheduleOverviewDayParts"
                    [entities]="daypartOptions$ | async"
                    [preSelectedEntities]="preselectedDayparts$ | async"
                    [searchProperties]="searchProperties"
                    [showSelectAll]="true"
                    [required]="false"
                    searchFieldPlaceholder="Search dayparts"
                    multiselectTreePlaceholder="Dayparts"
                    (selectedEntityIdsChanged)="onFilteredDaypartsChanged($event)"
                >
                </app-multiselect-tree>
            </div>
        </div>
        <div class="display_settings">
            <div class="organization-units_container">
                <app-multiselect-tree
                    data-cy="OverviewOrganizationUnitsFilter"
                    [entities]="organizationsForFiltering$ | async"
                    [preSelectedEntities]="preselectedOrganizationUnits$ | async"
                    [showSelectAll]="true"
                    searchFieldPlaceholder="Search organization unit"
                    multiselectTreePlaceholder="Organization units"
                    (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
                >
                </app-multiselect-tree>
            </div>
            <button mat-button class="bol-button-outlined default-button options" (click)="onClickDisplayOptions()" data-cy="displayOptionsButton">
                <span translate>Display options</span>
            </button>
        </div>
    </div>

    <hr class="line" />
    <p class="bol-text bol-text-bold" translate>Activity schedule</p>
    <div
        [ngClass]="{ 'extended-container': (selectedResourcePropertiesLength$ | async) > 0 }"
        class="resource-options__container__activity-types"
    >
        <app-activity-type-selection-new
            class="activity-types-wrapper"
            [dialogTitle]="buttonTitleActivitiesLabel"
            [buttonTitle]="buttonTitleActivities$ | async"
            [activityTypes]="activityTypesForActivities$ | async"
            [noActivityTypesSelectedByDefault]="true"
            [activityTypeCategories]="activityTypeCategories$ | async"
            [selectedActivityTypeIds]="selectedActivityTypeIdsForActivities$ | async"
            [organizationUnits]="organizationsForActivityTypes$ | async"
            [applyFilterState]="applyFilterForActivitiesState$ | async"
            (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIdsForActivities($event)"
            (updateApplyFilterStateEvent)="updateApplyFilterStateForActivities($event)"
        ></app-activity-type-selection-new>

        <app-activity-type-sorting
            [dialogTitle]="'Sort activity types'"
            [buttonTitle]="'Sort activity types'"
            [activityTypes]="activityTypesForSorting$ | async"
            [entityName]="'activity types'"
            [sortedActivityTypeIds]="sortedActivityTypeIdsForActivities$ | async"
            [selectedActivityTypeIds]="selectedActivityTypeIdsForActivities$ | async"
            (updateSortedActivityTypeIdsEvent)="updateSortedActivityTypeIdsForActivities($event)"
        ></app-activity-type-sorting>
    </div>
    <hr class="line" />

    <div class="bottom-filters">
        <p class="bol-text bol-text-bold" translate>Resource schedule</p>
        <div class="resources__container">
            <app-filter-chip-list
                data-cy="ScheduleOverviewBaseResourceTypes"
                class="resources__container__base-resources"
                required="true"
                [entities]="baseResourceTypesForFiltering$ | async"
                [preselectedEntities]="selectedBaseResourceTypes$ | async"
                [forceErrorState]="forceErrorStateOnBaseResourceTypes$ | async"
                errorMessage="{{ 'Please select a base resource type' | translate }}"
                noResultsFoundText="{{ 'No base resource types found' | translate }}"
                placeholderText="{{ 'Base Resource Types...' | translate }}"
                (selectedEntityIdsChanged)="onFilteredBaseResourceTypesChanged($event)"
            ></app-filter-chip-list>
        </div>

        <div class="resource-options__container">
            <div
                [ngClass]="{ 'extended-container': (selectedResourcePropertiesLength$ | async) > 0 }"
                class="resource-options__container__activity-types"
            >
                <app-activity-type-selection-new
                    [dialogTitle]="buttonTitleResourcesLabel"
                    [buttonTitle]="buttonTitleResources$ | async"
                    [activityTypes]="activityTypes$ | async"
                    [activityTypeCategories]="activityTypeCategories$ | async"
                    [selectedActivityTypeIds]="selectedActivityTypeIds$ | async"
                    [organizationUnits]="organizationsForActivityTypes$ | async"
                    [applyFilterState]="applyFilterForResourcesState$ | async"
                    (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
                    (updateApplyFilterStateEvent)="updateApplyFilterState($event)"
                ></app-activity-type-selection-new>
            </div>

            <div class="resource-options__container__sort-options">
                <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
                    <mat-label translate>Grouping option</mat-label>
                    <mat-select
                        #groupingOptionSelect
                        data-cy="OverviewGroupingOptionsSelect"
                        (openedChange)="openedGroupingOptionSelect($event)"
                        [value]="groupingOptionSelected$ | async"
                    >
                        <mat-option *ngFor="let groupingOption of groupingOptions" [value]="groupingOption.type">{{
                            groupingOption.value | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="default-mat-form-field">
                <app-multiselect-tree
                    data-cy="ScheduleOverviewExtraResourceTypes"
                    class="resource-options__container__extra-resources"
                    [entities]="extraResourceTypesForFiltering$ | async"
                    [preSelectedEntities]="selectedExtraResourceTypesIds$ | async"
                    [searchProperties]="searchProperties"
                    [showSelectAll]="true"
                    [required]="false"
                    searchFieldPlaceholder="Search resource type"
                    multiselectTreePlaceholder="Extra Resource Types"
                    (selectedEntityIdsChanged)="onFilteredExtraResourceTypesChanged($event)"
                >
                </app-multiselect-tree>
            </div>
        </div>
    </div>
</div>
