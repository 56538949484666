
<div 
    class="add-entity-backbdrop mat-elevation-z8"
    *ngIf="mode === manageMode.ADD"
></div>
<mat-card 
     
    class="bol-card full-height" 
    [class.mat-elevation-z8]="mode === manageMode.ADD">

    <ng-container *ngIf="mode !== undefined">
    
        <mat-card-title class="bol-card-title entity-title" data-cy="entityManageTitle">
            <span *ngIf="mode === manageMode.VIEW">{{ "VIEW-ENTITY-TITLE" | translate: { entityName: prefixedEntityName | translate } }}</span>
            <span *ngIf="mode === manageMode.EDIT">{{ "EDIT-ENTITY-TITLE" | translate: { entityName: prefixedEntityName | translate } }}</span>
            <span *ngIf="mode === manageMode.ADD">{{ "ADD-ENTITY-TITLE" | translate: { entityName: prefixedEntityName | translate } }}</span>
        </mat-card-title>

        <div class="status-indication__bar" data-cy="loadingStatusIndication">
            <ng-container *ngIf="uiEntity?.isLoading">
                <div class="status-indication__text" translate>saving</div>
                <mat-spinner class="status-indication__spinner" diameter="16"></mat-spinner>
            </ng-container>

            <ng-container *ngIf="uiEntity?.isFinishedSaving">
                <div class="status-indication__text" translate>saved</div>
            </ng-container>

            <ng-container *ngIf="uiEntity?.hasPendingChanges">
                <div class="status-indication__text" translate>changes pending</div>
            </ng-container>
        </div>
        
        <form [formGroup]="form" (ngSubmit)="onAddEntity()" class="form-padding">
            <formly-form 
                [form]="form"
                [model]="internalModel" 
                [fields]="internalFormFields" 
                (modelChange)="onModelChanged()"
            ></formly-form>

            <div class="entity-buttons__wrapper">
                <div class="entity-buttons">
                    <ng-container *ngIf="mode === manageMode.ADD">
                        <button 
                            type="submit"
                            mat-button 
                            class="bol-button-solid bol-positive"
                            data-cy="saveEntityButton">
                            <span translate>Save</span>
                        </button>
            
                        <button 
                            type="button"
                            (click)="onCancelAdd()"
                            mat-button 
                            class="bol-button-outlined"
                            data-cy="cancelEntityButton">
                            <span translate>Cancel</span>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="mode === manageMode.EDIT">
                        <button 
                            *ngIf="canClone"
                            type="button"
                            (click)="onClone()"
                            mat-button 
                            class="bol-button-outlined"
                            data-cy="cloneEntityButton">
                            <span translate>Clone</span>
                        </button>

                        <button
                            *ngIf="canDelete"
                            type="button"
                            (click)="onDelete()"
                            mat-button 
                            class="bol-button-outlined bol-negative"
                            data-cy="deleteEntityButton">
                            <span translate>Delete</span>
                        </button>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="message">
                <div [ngClass]="messageStyleClass">{{ message | translate }}</div>
            </ng-container>
        </form>
    </ng-container>
</mat-card>
