import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

import { DisplaySetting } from './display-setting.model';
import { DisplaySettingQuery } from './display-setting.query';
import { DisplaySettingStore } from './display-setting.store';

@Injectable({
    providedIn: 'root'
})
export class DisplaySettingService {
    constructor(
        private readonly http: HttpClient,
        protected displaySettingStore: DisplaySettingStore,
        protected displaySettingQuery: DisplaySettingQuery,
    ) { }

    public getDisplaySettings(): Observable<Array<DisplaySetting>> {
        const settingsInQuery = this.displaySettingQuery.getDisplaySettingsSync();
        if (settingsInQuery.length === 0) {
            return this.http.get<Array<DisplaySetting>>('/api/Users/DisplaySettings').pipe(
                tap((displaySettings) => {
                    this.displaySettingStore.set(displaySettings);
                }),
            );
        }

        return of(settingsInQuery);
    }

    public createDisplaySetting(name: string, value: any): Observable<DisplaySetting> {
        let convertedValue = value;
        // booleans are saved as "1" (true) or "0" (false)
        if (typeof value === 'boolean') {
            convertedValue = value ? '1' : '0';
        }

        if (Array.isArray(value)) {
            convertedValue = value.join(',');
        }

        const displaySetting: DisplaySetting = {
            settingName: name,
            settingValue: convertedValue
        };

        return this.http.post<DisplaySetting>('/api/Users/DisplaySettings', displaySetting).pipe(
            tap(() => {
                this.displaySettingStore.upsert(displaySetting.settingName, displaySetting.settingValue);
                
                return displaySetting;
            })
        );
    }
}
