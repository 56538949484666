import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

export interface MultiselectTreePropagationOptions {
    shouldPropagate: boolean;
    rememberDialogAction: boolean;
}

@Component({
    standalone: true,
    selector: 'app-multiselect-tree-propagation-dialog',
    templateUrl: './multiselect-tree-propagation-dialog.component.html',
    styleUrls: ['./multiselect-tree-propagation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        
        MatButtonModule,
        TranslateModule,
        MatDialogModule
    ]
})
export class MultiselectTreePropagationDialogComponent {
    @Output() public readonly changeSelectionTreePropagationOptionsEvent = new EventEmitter<MultiselectTreePropagationOptions>();

    constructor(
        public dialogRef: MatDialogRef<any>,
    ) {}

    public onChangeEntityStatus(shouldPropagate: boolean, rememberDialogAction: boolean): void {
        this.changeSelectionTreePropagationOptionsEvent.next({ shouldPropagate, rememberDialogAction });

        this.dialogRef.close();
    }
}
