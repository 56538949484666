import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ActivityType, GetActivityTypesResponse } from './activity-type.model';
import { ActivityTypeStore } from './activity-type.store';
import { ActivityTypeHelperService } from './activity-type-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeService {
    constructor(
        protected activityTypeStore: ActivityTypeStore,
        protected activityTypeHelperService: ActivityTypeHelperService,
        private readonly http: HttpClient,
    ) { }

    public getActivityTypes(): Observable<Array<ActivityType>> {
        this.activityTypeStore.updateEntitiesLoadingState(true);

        return this.http.get<GetActivityTypesResponse>('/api/v1/ActivityTypes').pipe(
            tap((response) => {
                this.activityTypeStore.set(response.activityTypes);
                this.activityTypeStore.updateActivityTypeMap(response.activityTypes);

                if (response.activityTypes.length > 0) {
                    this.activityTypeStore.selectActivityType(response.activityTypes[0].id);
                }
                this.activityTypeStore.updateEntitiesLoadingState(false);
            }),
            map((response) => response.activityTypes)
        );
    }

    public getMainActivityTypes(): Observable<Array<ActivityType>> {
        this.activityTypeStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<ActivityType>>('/api/ActivityTypes/MainActivityTypes/WithUserGroupReadPermissions').pipe(
            tap((activityTypes) => {
                const sortedActivityTypes = activityTypes.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
                this.activityTypeStore.set(sortedActivityTypes);
            }),
            tap(() => this.activityTypeStore.updateEntitiesLoadingState(false))
        );
    }

    public selectActivityType(id: number): void {
        this.activityTypeStore.selectActivityType(id);
    }

    public createActivityTypeAndSorting(activityTypes: Array<ActivityType>): void {
        const rootActivityTypes = activityTypes.filter(type => type.parentId === null);
        const activityTypeStructureAndSorting = new Map<number, Array<ActivityType>>();
    
        for (const root of rootActivityTypes) {
            const fullStructureAndSorted = this.activityTypeHelperService.getFullActivityStructureAndSort(root.id, activityTypes);
            activityTypeStructureAndSorting.set(root.id, fullStructureAndSorted);
        }
    
        this.activityTypeStore.updateActivityTypeStructureAndSorting(activityTypeStructureAndSorting);
    }
}
