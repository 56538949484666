<mat-card  class="bol-card" class="content-wrapper">
    <mat-card-content>
        <div class="title-status">
            <h3 class="filtering-title" translate data-cy="filterPaletteTitle">FILTER-PALETTE.PAGE-TITLE</h3>
            
            <app-loading-status-indication
                [statusPageState]="statusPageState$ | async"
            ></app-loading-status-indication>
        </div>
       
        <div class="activities-checkbox">
            <mat-checkbox
                class="bol-checkbox"
                data-cy="applyFilterPalette"
                [checked]='applyFilterState$ | async'
                (change)="onApplyFilter($event)">
                <span>{{ 'Apply filter' | translate }}</span>
            </mat-checkbox>
        </div>
        <div>
            <div class="filters-activity-types">
                <app-multiselect-tree
                    data-cy="activityTypeCategoriesFilter"
                    class="activity-type-categories-filter-activity-type"
                    [entities]="activityTypeCategories$ | async"
                    [preSelectedEntities]="selectedActivityTypeCategoryIds"
                    [showSelectAll]="true"
                    [required]="false"
                    [showSearch]="false"
                    [disable]="disableFilterFields"
                    multiselectTreePlaceholder="Activity type categories"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnActivityTypeCategories($event)"
                ></app-multiselect-tree>

                <app-multiselect-tree
                    data-cy="organizationUnitFilter"
                    class="organization-unit-filter-activity-type"
                    [entities]="organizationUnits$ | async"
                    [preSelectedEntities]="selectedOrganizationIds"
                    [showSelectAll]="true"
                    [required]="false"
                    [disable]="disableFilterFields"
                    searchFieldPlaceholder="Search organization unit"
                    multiselectTreePlaceholder="Organization units"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
                ></app-multiselect-tree>
            </div>

            <div class="activities-checkbox">
                <mat-checkbox
                    class="bol-checkbox"
                    data-cy="showOnlySelected"
                    [checked]='showOnlySelectedState$ | async'
                    (change)="showOnlyActivityTypesSelected($event)">
                    <span>{{ 'Show only selected' | translate }}</span>
                </mat-checkbox>
            </div>
            <div>
                <app-multiselect-table
                    searchFieldPlaceholder="Activity type filter"
                    [entities]="filteredActivityTypes$ | async"
                    [columnDefinition]="columnDefinition"
                    [searchProperties]="searchProperties"
                    [hideSearch]="false"
                    (selectedEntityIdsChanged)="updateActivityTypeStates($event)"
                ></app-multiselect-table>
            </div>
            
            <div class="activities-length-info-message">
                <div class="bol-text bol-text-regular" data-cy="selectedActTypeMessage"> {{ selectedActivityTypeIds?.length }} {{ '' +  ACTIVITY_SELECTED_MESSAGE | translate}} </div>
            </div>

        </div>
    </mat-card-content>
</mat-card>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
