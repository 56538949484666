<div class="bol-modal__header">
    <span class="modal-title">{{ dialogTitle | translate }}</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content [style.min-height.px]="((applyFilterState$ | async) || !showApplyFilter) ? 710 : 0">
    <div class="activities-checkbox" *ngIf="showApplyFilter">
        <mat-checkbox
            data-cy="applyFilterCheckbox"
            class="bol-checkbox"
            [checked]='applyFilterState$ | async'
            (change)="onApplyFilter($event)">
            <span>{{ 'Apply filter' | translate }}</span>
        </mat-checkbox>
    </div>
    <div *ngIf="(applyFilterState$ | async) || !showApplyFilter">
        <div class="filters-activity-types">
            <app-multiselect-tree
                class="activity-type-categories-filter-activity-type"
                [entities]="activityTypeCategories$ | async"
                [preSelectedEntities]="selectedActivityTypeCategoryIds"
                [showSelectAll]="true"
                [required]="false"
                [showSearch]="false"
                [disable]="disableFilterFields"
                data-cy="activityTypeCategoriesFilter"
                multiselectTreePlaceholder="Activity type categories"
                (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnActivityTypeCategories($event)"
            ></app-multiselect-tree>

            <app-multiselect-tree
                class="organization-unit-filter-activity-type"
                [entities]="organizationUnits$ | async"
                [preSelectedEntities]="selectedOrganizationIds"
                [showSelectAll]="true"
                [required]="false"
                [disable]="disableFilterFields"
                searchFieldPlaceholder="Search organization unit"
                data-cy="activityTypeDialogOrganizationUnitsFilter"
                multiselectTreePlaceholder="Organization units"
                (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
            ></app-multiselect-tree>
        </div>

        <div class="activities-checkbox">
            <mat-checkbox
                data-cy="showOnlySelectedCheckbox"
                class="bol-checkbox"
                [checked]='showOnlySelectedState$ | async'
                (change)="showOnlyActivityTypesSelected($event)">
                <span>{{ 'Show only selected' | translate }}</span>
            </mat-checkbox>
        </div>
        <app-multiselect-table
            searchFieldPlaceholder="Activity type filter"
            [entities]="filteredActivityTypes$ | async"
            [columnDefinition]="columnDefinition"
            [searchProperties]="searchProperties"
            [hideSearch]="false"
            (selectedEntityIdsChanged)="updateActivityTypeStates($event)"
            data-cy="activityTypesMultiselectTable"
        ></app-multiselect-table>

        
        <div class="activities-length-info-message" data-cy="numberOfSelectedActivityTypes">
            <div class="bol-text bol-text-regular"> {{ selectedActivityTypeIds.length }} {{ '' +  ACTIVITY_SELECTED_MESSAGE | translate}} </div>
        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        data-cy="activityTypeDialogConfirmButton"
        class="bol-button-solid bol-positive"
        (click)="onConfirm()">
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>