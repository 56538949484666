import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { OwsSkill } from './ows-skill.model';
import { OwsSkillStore } from './ows-skill.store';

@Injectable({
    providedIn: 'root'
})
export class OwsSkillService {
    constructor(
        private readonly http: HttpClient,
        protected owsSkillStore: OwsSkillStore
    ) { }

    public get() {
        return this.http.get<Array<OwsSkill>>('/api/OwsInterface/OwsSkills').pipe(
            map((skills) => {
                this.owsSkillStore.set(skills);
            }));
    }
}
