import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { EntityListColumnDefinition } from 'src/app/shared/components/entity-management/entity-list-panel/entity-list-panel.component';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';

import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { SkillLevel } from 'src/app/shared/stores/skill-level-store/skill-level.model';
import { SkillLevelQuery } from 'src/app/shared/stores/skill-level-store/skill-level.query';
import { SkillLevelService } from 'src/app/shared/stores/skill-level-store/skill-level.service';

import { SkillLevelsManagementQuery } from './store/skill-levels-management.query';
import { SkillLevelsManagementService } from './store/skill-levels-management.service';
import { getSkillLevelColumnDefinition, getSkillLevelFormFields } from './skill-levels-form-definition';

@Component({
    selector: 'app-skill-levels',
    templateUrl: './skill-levels.component.html',
    styleUrls: ['./skill-levels.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SkillLevelsComponent implements OnInit, OnDestroy {
    public filteredSkillLevel$: Observable<Array<SkillLevel>>;
    public loadingError$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public selectedUISkillLevel$: Observable<EntityUI>;
    public selectedSkillLevelId$: Observable<number>;
    public selectedSkillLevel$ = new BehaviorSubject<SkillLevel>(undefined);
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public initialLoadingFinished$: Observable<boolean>;
    public columnDefinition: Array<EntityListColumnDefinition>;
    public formFields: Array<FormlyFieldConfig>;

    private readonly subscription = new Subscription();
    constructor(
        private readonly skillLevelService: SkillLevelService,
        private readonly skillLevelQuery: SkillLevelQuery,
        private readonly skillLevelManagementQuery: SkillLevelsManagementQuery,
        private readonly skillLevelManagementService: SkillLevelsManagementService,
        private readonly organizationQuery: OrganizationUnitQuery,
        private readonly autosaveService: AutosaveService
    ) { }
   
    public ngOnInit(): void {
        this.skillLevelService.getSkillLevels().pipe(first()).subscribe();
        this.columnDefinition = getSkillLevelColumnDefinition();
        
        combineLatest([
            this.organizationQuery.getOrganizationsForFiltering(),
        ]).pipe(
            filter(([units]) =>
                units && 
                units.length > 0 ),
            first(),
            tap(([units])  => {
                this.formFields = getSkillLevelFormFields(units);
            })
        ).subscribe();

        this.initialLoadingFinished$ = this.skillLevelManagementQuery.allEntitiesLoaded();
        this.loadingError$ = this.skillLevelQuery.selectError();
        this.selectedUISkillLevel$ = this.skillLevelManagementQuery.getSelectedUISkillLevel();
        this.selectedSkillLevelId$ = this.skillLevelManagementQuery.getCurrentSkillLevelId();
        this.manageMode$ = this.skillLevelManagementQuery.getManageMode();
        this.filteredSkillLevel$ = this.skillLevelManagementQuery.getFilteredSkillLevels();
      
        this.subscription.add(
            this.skillLevelManagementQuery.getSelectedSkillLevel().pipe(
                tap(skillLevel => this.selectedSkillLevel$.next(skillLevel))
            ).subscribe()
        );
    }
    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
    }

    public onSelectedSkillLevelChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.skillLevelService.setSkillLevelToCleanUIState(id);
        const manageMode = id === undefined ? undefined : MANAGE_MODE.EDIT;
        this.skillLevelManagementService.updateManageMode(manageMode);
        this.skillLevelManagementService.updateSelectedSkillId(id);
    }

    public onAddNewSkillLevel(): void {
        const newSkillLevel = this.skillLevelManagementQuery.getNewSkillLevelObject();
        const lastCorrectVersionOfSkillLevel = this.skillLevelQuery.getSkillLevelSync(this.skillLevelManagementQuery.getCurrentSkillLevelIdSync());
        this.showLoadingSpinner$.next(true);

        this.skillLevelService.saveSkillLevel(newSkillLevel, lastCorrectVersionOfSkillLevel).pipe(
            tap((addedSkillLevel: SkillLevel) => {
                this.showLoadingSpinner$.next(false);
                this.skillLevelManagementService.setStateForNewSkillLevel(addedSkillLevel.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onReorderdEntitiesChanged(skillLevels: Array<SkillLevel>): void {

        skillLevels.forEach(skillLevel => this.saveSkillLevel(skillLevel));
    }

    public onDeleteSkillLevel(skillLevelId: number): void {
        this.showLoadingSpinner$.next(true);
        this.skillLevelService.deleteSkillLevel(skillLevelId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.skillLevelManagementService.updateSelectedSkillId(undefined);
                this.skillLevelManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onEditSkillLevel(skillLevel: SkillLevel): void {
        this.skillLevelService.setSkillLevelToPendingChanges(skillLevel.id);
        this.autosaveService.autoSave(skillLevel, this.saveSkillLevel.bind(this));
    }

    private saveSkillLevel(skillLevel: SkillLevel): void {
        const lastCorrectVersionOfSkillLevel = this.skillLevelQuery.getSkillLevelSync(skillLevel.id);

        this.skillLevelService.saveSkillLevel(skillLevel, lastCorrectVersionOfSkillLevel).pipe(
            first(),
        ).subscribe({
            error: this.handleSaveError.bind(this)
        });
    }

    private handleSaveError(skillLevel: SkillLevel): void {
        if (this.skillLevelManagementQuery.getCurrentSkillLevelIdSync() === skillLevel.id) {
            this.selectedSkillLevel$.next({...skillLevel});
        }
    }

    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }
}