import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorDialogService } from '../../services/error-dialog.service';

import { getDefaultUIState } from '../entity-ui-models';
import { GetOrganizationUnitResponse, OrganizationUnit } from './organization-unit.model';
import { OrganizationUnitStore } from './organization-unit.store';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUnitService {
    
    constructor(
        protected organizationUnitStore: OrganizationUnitStore,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public get(): Observable<Array<OrganizationUnit>> {
        this.organizationUnitStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<OrganizationUnit>>('/api/OrganizationUnits').pipe(
            tap((organizationUnits) => {
                this.organizationUnitStore.set(organizationUnits);

                if (organizationUnits.length > 0) {
                    this.organizationUnitStore.selectOrganizationUnit(organizationUnits[0].id);
                    this.organizationUnitStore.toggleExpansionForAll(true);
                }
            }),
            tap(() => this.organizationUnitStore.updateEntitiesLoadingState(false))
        );
    }

    public getOrganizationUnitsForActivityTypes(): Observable<Array<OrganizationUnit>> {
        this.organizationUnitStore.updateEntitiesLoadingStateForActivityTypes(true);

        return this.http.get<GetOrganizationUnitResponse>('/api/v1/OrganizationUnits/ForActivityTypes').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            map((organizationUnitResponse) => {
                this.organizationUnitStore.setOrganizationUnitsForActivityTypes(organizationUnitResponse.organizationUnits);
                this.organizationUnitStore.updateEntitiesLoadingStateForActivityTypes(false);

                return organizationUnitResponse.organizationUnits;
            })
        );
    }

    public filterVisibleOrganizationsByIds(ids: Array<number>): void {
        this.organizationUnitStore.setIdsOfVisibleOrganizations(ids);
    }

    public selectOrganizationUnit(id: number): void {
        this.organizationUnitStore.selectOrganizationUnit(id);
    }

    public setOrganizationUnitToPendingChanges(orgUnitId: number): void {
        this.organizationUnitStore.ui.update(orgUnitId, { hasPendingChanges: true, isFinishedSaving: false });
    }

    public setOrganizationUnitToCleanUIState(organizationUnitId: number): void {
        if (organizationUnitId !== undefined && organizationUnitId !== -1) {
            this.organizationUnitStore.ui.update(organizationUnitId, getDefaultUIState());
        }
    }

    public saveOrganizationUnit(orgUnit: OrganizationUnit, lastCorrectVersionOfOrgUnit: OrganizationUnit): Observable<OrganizationUnit> {
        if (orgUnit.id !== -1) {
            this.organizationUnitStore.ui.update(orgUnit.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<OrganizationUnit>('/api/OrganizationUnits', orgUnit).pipe(
            catchError((error) => {
                if (orgUnit.id !== -1) {
                    this.organizationUnitStore.ui.update(orgUnit.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING',
                    { 
                        entityName: this.translateService.instant('organization unit'),
                        entityDisplayName: orgUnit.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => lastCorrectVersionOfOrgUnit);
            }),
            tap((updatedOrgUnit: OrganizationUnit) => {
                // Note: an object is returned from the "put" request only for organization units saved with id -1 
                // for updating organization units we will use the organization unit object that we stored client-side
                if (orgUnit.id !== -1) {
                    this.organizationUnitStore.upsert(orgUnit.id, orgUnit);
                    this.organizationUnitStore.ui.update(orgUnit.id, { isLoading: false, isFinishedSaving: true });
                } else {
                    this.organizationUnitStore.upsert(updatedOrgUnit.id, updatedOrgUnit);
                }

                return orgUnit;
            }),
        );
    }

    public deleteOrganizationUnit(orgUnitId: number): Observable<void> {
        this.organizationUnitStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/OrganizationUnits/' + orgUnitId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('organization unit') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.organizationUnitStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.organizationUnitStore.remove(orgUnitId);
                }
            }),
        ); 
    }
}
