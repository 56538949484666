import { Injectable } from '@angular/core';
import { combineQueries, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityUI, EntityUIState } from '../entity-ui-models';
import { SkillLevel } from './skill-level.model';

import { SkillLevelState, SkillLevelStore } from './skill-level.store';

@Injectable({
    providedIn: 'root'
})
export class SkillLevelQuery extends QueryEntity<SkillLevelState> {
    public ui: EntityUIQuery<EntityUIState>;

    constructor(
        protected store: SkillLevelStore
    ) {
        super(store);
        this.createUIQuery();
    }

    public getSkillLevels(): Observable<Array<SkillLevel>> {
        return this.selectAll().pipe(map(skillLevels =>
            skillLevels.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getSkillLevelsSync(): Array<SkillLevel> {
        const skillLevels = this.getValue().entities;

        return Object.values(skillLevels).sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getUISkillLevel(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }

    public getSkillLevelSync(id: number): SkillLevel {
        return this.getEntity(id);
    }
    
    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getSkillLevel(id: number): Observable<SkillLevel> {
        return this.selectEntity(id);
    }

    public getHighestSkillLevelSync(): number {
        const skillLevels = this.getSkillLevelsSync();

        return skillLevels.reduce((highestLevel, skillLevel) => highestLevel = highestLevel > skillLevel.level ? highestLevel : skillLevel.level, 0);
    }
}
