import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PERMISSIONS } from 'src/app/shared/models/Enums';
import { PermissionQuery } from 'src/app/shared/stores/permission-store/permission.query';

@Component({
    selector: 'app-activity-demand',
    templateUrl: './activity-demand.component.html',
    styleUrls: ['./activity-demand.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityDemandComponent implements OnInit {
    public menuItems: Array<any> = [];

    constructor( 
        private readonly permissionQuery: PermissionQuery
    ){}

    public ngOnInit(): void {
        
        this.menuItems.push(
        {
            title: 'ACTIVITY-DEMAND.MENU.TEMPLATE',
            icon: 'extension',
            url: '/activity-demand/manage-templates'
        }
        // {
        //     title: 'ACTIVITY-DEMAND.MENU.PLAN',
        //     icon: 'my_location',
        //     url: '/activity-demand/activity-plans'
        // },
        // {
        //     title: 'ACTIVITY-DEMAND.MENU.OVERVIEW',
        //     icon: 'list_alt',
        //     url: '/activity-demand/plan-overview'
        // }
        );

        this.updateMenuItemBasedOnPermissions();
    }

    private updateMenuItemBasedOnPermissions(): void {
        const permissions = this.permissionQuery.getPermissionsSync();
        const manageCountersPermission = permissions.find(perm => perm.permissionName === PERMISSIONS.ACTIVITY_DEMAND_MANAGE_COUNTERS);
        
        //numberUserUserGroupsWithThisPermission is the number of user groups with this permission that the user is member of
        if (manageCountersPermission && manageCountersPermission.numberUserUserGroupsWithThisPermission >= 1) {
            //if we have permissions we can see the Manage counters menu card
            this.menuItems.unshift({
                title: 'ACTIVITY-DEMAND.MENU.COUNTER',
                icon: 'calculate',
                url: '/activity-demand/manage-counters'
            });
        }
    }
}
