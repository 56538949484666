import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import moment from 'moment';

import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

import { SortingState, SortingStore } from './sorting.store';
import { SortableResource, SortingRequestParameters } from './sorting.model';
import { SortingQuery } from './sorting.query';
import { StatusPageService } from 'src/app/shared/stores/status-page-store/status-page.service';

@Injectable({
    providedIn: 'root'
})
export class SortingService extends StatusPageService<SortingState> {
    constructor(
        protected sortingStore: SortingStore,
        protected sortingQuery: SortingQuery,
        private readonly errorDialogService: ErrorDialogService,
        private readonly http: HttpClient,
    ) {
        super(sortingStore);
    }

    public getResourcesForSelectedResourceType(parameters: SortingRequestParameters): Observable<Array<SortableResource>> {
        this.sortingStore.updateEntitiesResourcesLoading(true);

        return this.http.post<Array<SortableResource>>('/api/Resources/ForPlanningBoard/Filtered', parameters).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((filtredResourcesBasedOnSelectedResourceType) => {
                this.sortingStore.setResourcesForSelectedResourceType(filtredResourcesBasedOnSelectedResourceType);
                this.sortingStore.updateEntitiesResourcesLoading(false);
            })
        );
    }

    public getResources(): void {
        const selectedResourceTypeId = this.sortingQuery.getSelectedResourceTypeIdSync();
        const parameters: SortingRequestParameters = {
            applyResourceFilter: false,
            applyUnitFilter: false,
            beforeReferenceDate: null,
            referenceDate: moment(new Date()).format('YYYY-MM-DDT00:00:00'),
            resourceTypeId: selectedResourceTypeId,
            sortByResourcePropertyId: -1,
            sortDescending: false
        };
        this.getResourcesForSelectedResourceType(parameters).pipe(first()).subscribe();
        this.getSelectedSortableResourcesIds(selectedResourceTypeId).pipe(first()).subscribe();
    }

    public getSelectedSortableResourcesIds(resourceTypeId: number): Observable<Array<number>> {
        this.sortingStore.updateEntitiesSelectedResourcesLoading(true);
     
        return this.http.get<Array<number>>('/api/Users/SelectedResources/' + resourceTypeId).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((selectedResourceIds) => {
                this.sortingStore.setSelectedSortableResourcesIds(selectedResourceIds, true);
                this.sortingStore.updateEntitiesSelectedResourcesLoading(false);
            })
        );
    }

    public updateSelectedSortedResourceIds(resourceTypeId: number, selectedResourceIds: Array<number>): Observable<void> {
        this.sortingStore.updateEntitiesSelectedResourcesLoading(true); 
        this.sortingStore.updatePageStatusState(STATUS.IS_LOADING);

        return this.http.put<Array<number>>('/api/Users/SelectedResources/' + resourceTypeId, selectedResourceIds).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);
                this.sortingStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap((response: any) => {
                this.sortingStore.updateEntitiesSelectedResourcesLoading(false);
                this.sortingStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING);
            }),
        );
    }

    public updateSelectedResourcePropertiesIds(selectedResourcePropertiesIds: Array<number>): void {
        this.sortingStore.updateSelectedResourcePropertiesIds(selectedResourcePropertiesIds);
    }

    public updateResourcesForSelectedResourceType(state: Array<SortableResource>): void {
        this.sortingStore.setResourcesForSelectedResourceType(state);
    }

    public updateSelectedResourceTypeId(state: number): void {
        this.sortingStore.updateSelectedResourceTypeId(state);
    }

    public updateEntitiesResourcesLoading(state: boolean): void {
        this.sortingStore.updateEntitiesResourcesLoading(state);
    }

    public updateEntitiesSelectedResourcesLoading(state: boolean): void {
        this.sortingStore.updateEntitiesSelectedResourcesLoading(state);
    }

    public updateSelectedSortableResourceIdsList(ids: Array<number>): void {
        this.sortingStore.updateSelectedSortableResourceIdsList(ids);
    }

    public updateSortDescending(state: boolean): void {
        this.sortingStore.updateSortDescending(state);
    }

    public setSelectedSortableResourcesIds(ids: Array<number>, addIds: boolean, replaceIds: boolean = false): void {
        this.sortingStore.setSelectedSortableResourcesIds(ids, addIds, replaceIds);
    }
    
    public updateSelectedUnsortedResourcesIdsList(ids: Array<number>): void {
        this.sortingStore.updateSelectedUnsortedResourcesIdsList(ids);
    }
    
    public resetStore(): void {
        this.sortingStore.reset();
    }
}
