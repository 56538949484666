import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Counter } from '../counters-store/counters.model';
import { CountersScenarioState, CountersScenarioStore } from './counters-scenario.store';


@Injectable({
    providedIn: 'root',
})
export class CountersScenarioQuery extends QueryEntity<CountersScenarioState> {
    constructor(
        protected store: CountersScenarioStore,
    ) {
        super(store);
    }

    public getCountersScenariosSync(): Array<Counter> {
        return Object.values(this.getValue().entities);
    }
}
