import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { filter, first } from 'rxjs/operators';

import { UserInfoQuery } from '../stores/user-info-store/user-info.query';
import { defaultLanguage } from './language.config';

@Injectable({
    providedIn: 'root'
})
export class UserLanguageInitializeService {

    constructor(
        private readonly dateAdapter: DateAdapter<Date>,
        private readonly userInfoQuery: UserInfoQuery,
    ) {}

    public initialize(): void {
        this.dateAdapter.setLocale(defaultLanguage.codeDto);

        this.userInfoQuery.getUserLanguageCode().pipe(
            filter(langCode => !!langCode),
            first()
        ).subscribe(langCode => this.dateAdapter.setLocale(langCode));
    }
}
