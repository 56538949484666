import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OwsWorkTreatmentQuery } from '../ows-work-treatment-store/ows-work-treatment.query';
import { OwsActivityTypeTableElement, OWSTreatmentTextTableElement } from './ows-activity-type.model';
import { OwsActivityTypeState, OwsActivityTypeStore } from './ows-activity-type.store';

@Injectable({
    providedIn: 'root'
})
export class OwsActivityTypeQuery extends QueryEntity<OwsActivityTypeState> {

    constructor(
        protected store: OwsActivityTypeStore,
        private readonly owsWorkTreatmentQuery: OwsWorkTreatmentQuery
    ) {
        super(store);
    }

    public getOwsActivityTypeTableElements(): Observable<Array<OwsActivityTypeTableElement>> {
        return combineLatest([
            this.selectAll(), 
            this.owsWorkTreatmentQuery.getWorkTreatmentTexts(),
            this.owsWorkTreatmentQuery.getWorkActivityTypes(),
        ]).pipe(
            map(([activityTypes, selectedTreatmentTexts, selectedWorkActivityTypes]) => activityTypes
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                .map(activityType => {
                    const includedInTreatmentText = selectedTreatmentTexts.includes(activityType.treatment);
                    const displayName = activityType.name + ' (' + activityType.treatment + ')';

                    return {
                        ...activityType,
                        checked: includedInTreatmentText || selectedWorkActivityTypes.includes(activityType.id),
                        disabled: includedInTreatmentText,
                        displayName
                    };
                })
            )
        );
    }

    public getAllTreatmentText(): Observable<Array<OWSTreatmentTextTableElement>> {
        return combineLatest([
            this.selectAll(), 
            this.owsWorkTreatmentQuery.getWorkTreatmentTexts()
        ]).pipe(
            map(([activityTypes, selectedTreatmentTexts]) => {
                const treatmentTexts = activityTypes
                    .map(activity => activity.treatment)
                    .sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
                
                return treatmentTexts.filter((text, idx) => treatmentTexts.indexOf(text) === idx)
                    .map((text, idx) => ({
                        id: idx,
                        displayName: text,
                        checked: selectedTreatmentTexts.includes(text),
                        disabled: false
                    }));
            })
        );
    }
}
