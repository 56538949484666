/* eslint-disable prefer-arrow/prefer-arrow-functions */
import * as moment from 'moment-timezone';

import { Counter } from '../counters-store/counters.model';

export interface ActivityDto {
    activityTypeId?: number;
    end: string;
    id: number;
    parentId?: number;
    resourceId?: number;
    resourceTypeId?: number;
    rootId?: number;
    start: string;
    workloadHours: number;
    rootActivityType?: RootActivityTypeDto;
}

export interface RootActivityTypeDto {
    displayName: string;
    shortName: string;
    textColor: string;
    backColor: string;
    sortOrder?: number;
    id: number;
}

export interface ActivitiesDto {
    activities: Array<ActivityDto>;
}

export function convertFromActivitiesDto(activitiesDto: ActivitiesDto, timeZone: string): Array<Counter> {
    return convertFromDto(activitiesDto.activities, timeZone);
}

export function convertFromDto(activities: Array<ActivityDto>, timeZone: string): Array<Counter> {
    return activities.map((dto) => convertFromActivityDto(dto, timeZone));
}

export function convertFromActivityDto(activityDto: ActivityDto, timeZone: string): Counter {
    const result: Counter = {
        activityTypeId: activityDto.activityTypeId,
        end: moment.utc(activityDto.end).tz(timeZone).format(),
        id: activityDto.id,
        parentId: activityDto.parentId,
        resourceId: activityDto.resourceId,
        resourceTypeId: activityDto.resourceTypeId,
        rootId: activityDto.rootId,
        start: moment.utc(activityDto.start).tz(timeZone).format(),
        workloadHours: activityDto.workloadHours,
        rootActivityType: {
            displayName: activityDto.rootActivityType?.displayName,
            shortName: activityDto.rootActivityType?.shortName,
            textColor: activityDto.rootActivityType?.textColor,
            backColor: activityDto.rootActivityType?.backColor,
            sortOrder: activityDto.rootActivityType?.sortOrder,
            id: activityDto.rootActivityType?.id
        }
    };

    return result;
}
