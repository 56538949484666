import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ActivityTypeCategory } from './activity-type-category.model';

export interface ActivityTypeCategoryState extends EntityState<ActivityTypeCategory> {
    ui: {
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-type-category' })
export class ActivityTypeCategoryStore extends EntityStore<ActivityTypeCategoryState> {
    constructor() {
        super(initialState);
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}