<ng-container *ngIf="(initialLoadingFinished$ | async) === false">
    <div class="bol-modal__header">
        <span class="modal-title">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.TITLE" | translate }} {{ activityTypeNameAndTimeslotPeriod$ | async }}</span>
        <div class="bol-modal__close" (click)="onCloseModal()">
        <i class="material-icons">close</i>
        </div>
    </div>
    
    <mat-dialog-content>
        <div>
            <div class="top-filters">
                <div class="week-day-container">
                    <div class="week-input">
                        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" data-cy="weekField">
                            <mat-label translate>Week</mat-label>
                            <mat-select
                                #weekSelect
                                data-cy="weekSelect"
                                (openedChange)="openedWeekSelect($event)"
                                [value]="weekSelect$ | async"
                            >
                            <mat-option *ngFor="let week of weeks" [value]="weekSelectSubject | async">{{ week.type }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="day-input">
                        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" data-cy="dayField">
                            <mat-label translate>Day</mat-label>
                            <mat-select
                                #daySelect
                                data-cy="daySelect"
                                (openedChange)="openedDaySelect($event)"
                                [value]="daySelect$ | async"
                            >
                            <mat-option *ngFor="let day of days" [value]="day.value">{{ day.type | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="info-icon-sign" *ngIf="missingPreferredResources$ | async" [bolPopoverHover]="popoverHover" data-cy="missingResourcesInfoIcon">
                        <mat-icon>info</mat-icon>
                    </div>

                    <ng-template #popoverHover>
                        <!-- we need to add the logic to display Affected weeks: Week 1, Week 2, Week 3, etc. -->
                        <!-- Affected days for week {{current week number}}: Mon, Tue, Wed, etc. -->
                        <div class="popover-hover"></div>
                      </ng-template>
                </div>

                <div class="demand-amount-info-message" data-cy="demandAmount">
                    <h4 class="bol-text bol-text-medium">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.DEMAND-AMOUNT" | translate}}{{ demandAmount$ | async }}</h4>
                </div>

                <div class="org-units-preferred-resources-container">
                    <div class="organization-units default-mat-form-field">
                        <app-multiselect-tree
                            data-cy="organizationUnitsFilter"
                            [entities]="organizationsForFiltering$ | async"
                            [preSelectedEntities]="preselectedOrganizationUnits$ | async"
                            [showSelectAll]="true"
                            [disable]="disableOrganizationUnitAndResourceFields$ | async"
                            [required]="false"
                            searchFieldPlaceholder="Search organization unit"
                            multiselectTreePlaceholder="Organization units"
                            (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
                        >
                        </app-multiselect-tree>
                    </div>
                    <div class="preferred-resources default-mat-form-field">
                        <app-multiselect-tree
                            data-cy="preferredResourcesFilter"
                            [entities]="preferredResources$ | async"
                            [preSelectedEntities]="preselectedPreferredResources$ | async"
                            [showSelectAll]="true"
                            [disable]="disableOrganizationUnitAndResourceFields$ | async"
                            [required]="false"
                            searchFieldPlaceholder="Search preferred resources"
                            multiselectTreePlaceholder="Preferred resources"
                            (selectedEntityIdsChanged)="onPreferredResourcesChanged($event)"
                        >
                        </app-multiselect-tree>
                    </div>
                </div>
            </div>
            
            <div class="space-callout-message">
                <ng-container *ngIf="(disableOrganizationUnitAndResourceFields$ | async) === false && (demandExceeded$ | async) === true">
                    <bol-callout [type]="'error'" class="callout-message">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.DEMAND-EXCEEDED" | translate }}</bol-callout>
                </ng-container>

                <ng-container *ngIf="(disableOrganizationUnitAndResourceFields$ | async) === true">
                    <bol-callout class="callout-message">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.NO-SELECTION-CALLOUT" | translate }}</bol-callout>
                </ng-container>
            </div>

            <div class="activity-types-table">
                <table mat-table [dataSource]="activityTypes$ | async" class="bol-table bol-table-striped-odd bol-table-row-clickable preferred-resources-table">
        
                    <ng-container matColumnDef="shortName">
                        <th mat-header-cell *matHeaderCellDef translate class="narrow-column">Activity type</th>
                        <td mat-cell *matCellDef="let activityType">
                            <div class="table-cell"
                            [ngStyle]="activityType.nameStyling">
                                {{ activityType.shortName }}
                            </div>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef translate class="wide-column">Display name</th>
                        <td mat-cell *matCellDef="let activityType">{{ activityType.displayName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="resourceTypes">
                        <th mat-header-cell *matHeaderCellDef translate class="wide-column">Resource types</th>
                        <td mat-cell *matCellDef="let activityType" [bolPopoverOverflow]="popoverHover">
                            <ng-template #popoverHover> {{ activityType.resourceTypeNames }}
                            </ng-template>
                            {{ activityType.resourceTypeNames }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="preferredResources">
                        <th mat-header-cell *matHeaderCellDef translate class="wide-column">Preferred resources</th>
                        <td mat-cell *matCellDef="let activityType" [bolPopoverOverflow]="popoverHover">
                            <ng-template #popoverHover> {{ activityType.preferredResources }}
                            </ng-template>
                            {{ activityType.preferredResources }}
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0"(click)="onSelectActivityRow(row)" [class.selected]="row.id === internalSelectedEntityId"></tr>
                    <tr *matNoDataRow class="bol-table-empty-state">
                        <td [attr.colspan]="displayedColumns.length" translate>Select an activity type to choose timeslots</td>
                    </tr>
                </table>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="dialog-actions-buttons">
            <button 
            mat-button 
            data-cy="preferredResourceDialogConfirmButton"
            (click)="onCancel()">
                <span translate>Cancel</span>
            </button>
        </div>
        <div class="dialog-actions-buttons">
            <button 
            mat-button 
            data-cy="preferredResourceDialogConfirmButton"
            class="bol-button-solid"
            (click)="onConfirm()">
                <span translate>Confirm</span>
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>

<div class="overlay-spinner" *ngIf="(initialLoadingFinished$ | async) === true">
    <mat-spinner diameter="100"></mat-spinner>
</div>
