<div class="content-wrapper">
    <mat-card class="bol-card">
        <mat-card-content class="bol-card-content align-center">
            <p *ngIf="loginBusy$ | async" translate>Authentication in progress. Please wait a moment...</p>

            <div class="inline-spinner">
                <mat-spinner diameter="30" *ngIf="loginBusy$ | async"></mat-spinner>
            </div>

            <div id="loginAlert" class="message error" *ngIf="showError$ | async">
                <h3 translate>Error while trying to authenticate user</h3>
                <p translate>See details below:</p>
                <pre>{{ message }}</pre>
            </div>

        </mat-card-content>
    </mat-card>
</div>