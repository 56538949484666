import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LanguageService } from 'src/app/shared/language';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { ActivityHelperService } from 'src/app/shared/services/activity-helper.service';

import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';
import { ScheduleStore } from '../../stores/schedule-store/schedule.store';
import { STATUS_NOT_REQUIRED } from '../../stores/schedule-store/schedule.model';
import { Activity } from '../../stores/schedule-activity-store/schedule-activity.model';

export interface ScheduleDetailDialogData {
    eventId: number;
}

@Component({
    selector: 'app-schedule-detail-dialog',
    templateUrl: './schedule-detail-dialog.component.html',
    styleUrls: ['./schedule-detail-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleDetailDialogComponent {
    public isLoading$!: Observable<boolean>;
    public statusNotRequired: number = STATUS_NOT_REQUIRED;
    public dateFormat: string;
    public timeZone: string;

    public allActivities: Array<Activity> = [];
    public description: string;
    public showDescription: boolean;

    constructor(
        public dialogRef: MatDialogRef<ScheduleDetailDialogData>,
        @Inject(MAT_DIALOG_DATA) dialogData: ScheduleDetailDialogData,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly languageService: LanguageService,
        private readonly scheduleService: ScheduleService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly activityHelperService: ActivityHelperService,
        protected scheduleStore: ScheduleStore,
    ) {
        this.dateFormat = this.languageService.getDateTimeFormatMomentByCurrentLanguage();
        this.timeZone = this.globalSettingsQuery.getTimeZoneSync();
        this.setData(dialogData.eventId);

        if (this.timeZone === 'Etc/UTC') {
            this.timeZone = 'UTC';
        }
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    private getDisplayStartTime(activity: Activity, displayDateTime: boolean, displayTime: boolean): string {
        if (displayDateTime && !displayTime) {
            return this.formatDate(activity.start);
        } else if (displayDateTime || displayTime) {
            return this.formatTime(activity.start);
        } else {
            return '';
        }
    }
    
    private getDisplayEndTime(activity: Activity, displayDateTime: boolean, displayTime: boolean): string {
        if (displayDateTime && !displayTime) {
            return this.formatDate(activity.end);
        } else if (displayDateTime || displayTime) {
            return this.formatTime(activity.end);
        } else {
            return '';
        }
    }

    private formatDate(date: string): string {        
        return moment.utc(date).tz(this.timeZone).format(this.dateFormat);
    }

    private formatTime(date: string): string {
        return moment.utc(date).tz(this.timeZone).format('HH:mm');
    }

    private setData(eventId: number): void {
        this.scheduleService.updateCurrentActivityIdSelected(eventId);
        this.scheduleService.getActivitiesForActivityId(eventId).pipe(
            first(),
            catchError((error: any) => {
                this.dialogRef.close();

                return throwError(() => error)
            })
        ).subscribe((activities) => this.setActivityData(activities));

        this.isLoading$ = this.scheduleQuery.getScheduleLoadingStateActivityDetailsDialog();
    }

    private compareTimes(dateTime1: string, dateTime2: string): boolean {
        const time1 = new Date(dateTime1).getTime();
        const time2 = new Date(dateTime2).getTime();
    
        return time1 !== time2;
    }

    private setActivityData(activities: Array<Activity>): void {
        const writeableActivities = [ ...activities];
        if (writeableActivities.length > 1) {
            const root = writeableActivities.find(a => a.rootId === null);
            this.description = root.description;
            this.showDescription = !!this.description;
            
            this.allActivities = this.activityHelperService.sortActivities(writeableActivities)
            .map((act, idx) => {
                const displayDateTime = act.id === root.id || act.start !== root?.start || act.end !== root?.end;
                const displayTime = this.compareTimes(act.start, root.start) || this.compareTimes(act.end, root.end);
                const resourceName = idx === 0 ? 'n/a' : act.resource;

                return {
                    ...act,
                    displayDateTime,
                    displayTime,
                    start: this.getDisplayStartTime(act, displayDateTime, displayTime),
                    end: this.getDisplayEndTime(act, displayDateTime, displayTime),
                    resource: resourceName
                };
            });
        }
        else {
            this.allActivities = writeableActivities.map(act => {
                const displayDateTime = true; 
                const displayTime = false;
                
                return {
                    ...act,
                    displayDateTime,
                    displayTime,
                    start: this.getDisplayStartTime(act, displayDateTime, displayTime),
                    end: this.getDisplayEndTime(act, displayDateTime, displayTime),
                    resource: act.resource
                };
            });

            this.description = this.allActivities[0].description;
            this.showDescription = !!this.description;
        }
    }
}
