import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { LanguageService } from 'src/app/shared/language';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';

import { ChangesHelperService } from '../changes-helpers/changes-helpers.service';
import { ActivityChangeState, ActivityChangeStore } from './activity-change.store';
import { ACTIVITY_STATUS, ActivityChange, ActivityChangeInformation, ActivityChangeTypeForTable, CHANGE_ACTION_TYPES } from './activity-change.model';

@Injectable({
    providedIn: 'root'
})
export class ActivityChangeQuery extends Query<ActivityChangeState> {
    constructor(
        protected store: ActivityChangeStore,
        protected changesHelperService: ChangesHelperService,
        protected activityTypeQuery: ActivityTypeQuery,
        protected resourceOuery: ResourceQuery,
        protected translateService: TranslateService,
        protected languageService: LanguageService,
        private readonly globalSettingsQuery: GlobalSettingsQuery
    ) {
        super(store);
    }

    public getActivityChangesSync(): Array<ActivityChange> {
        return this.getValue().activityChanges;
    }

    public getActivityChanges(): Observable<Array<ActivityChange>> {
        return this.select(value => value.activityChanges);
    }

    public getActivityChangesFiltered(): Observable<Array<ActivityChange>> {
        return this.getActivityChanges().pipe(
            map((activityChanges) => {
                //At the moment, we're only retaining activity changes initiated by a user or from the Multiselect screen, while filtering out those made by OWS
                return activityChanges.filter(activityChange => activityChange.activityChangeUserType !== 1);
            })
        );
    }

    public getSelectedOrginsStateSync(): Array<number> {
        return this.getValue().ui.selectedOrgins;
    }

    public getSelectedOrginsState(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedOrgins);
    }

    public getSelectedActionTypesSync(): Array<number> {
        return this.getValue().ui.selectedActionTypes;
    }

    public getSelectedActionTypes(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedActionTypes);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public transformActivityChangesForTable(): Observable<Array<ActivityChangeTypeForTable>> {
        return combineLatest([
          this.getActivityChangesFiltered(),
          this.activityTypeQuery.getActivityTypes(),
          this.globalSettingsQuery.getTimeZone()
        ]).pipe(
            filter(([changesArray, actTypes, timeZone]) => !!changesArray && actTypes.length > 0 && !!timeZone),
            map(([changesArray, actTypes, timeZone]) => {
                return [].concat(
                    ...changesArray.map(({ changes, ...value }) => {
                        return changes.map((change) => {
                            const property = this.getProperty(change, value, actTypes);
                            const valueToShow = this.getValueToShow(change, value);
                            const actionType = this.getActionType(change);
                            const activityIdToSearch = value?.rootActivityTypeId !== null ? value.rootActivityTypeId : value.activityTypeId;
                            const activityType = actTypes.find(actType => actType.id === activityIdToSearch);
                            const activityChangeUserTypeValue = this.changesHelperService.getOriginTypeByNumber(value.activityChangeUserType);

                            return {
                                activityChangeUserType: activityChangeUserTypeValue,
                                activityStart: moment.utc(value.activityStart).format(this.languageService.getDateTimeFormatMomentByCurrentLanguage()),
                                activityTypeDisplayName: activityType?.displayName,
                                changeType: actionType,
                                timestamp: moment.utc(value.timestamp).tz(timeZone).format(this.languageService.getDateTimeFormatMomentByCurrentLanguage()),
                                userDisplayName: value.userDisplayName,
                                activityStyling: this.changesHelperService.addStylingForActivityColumn(activityType),
                                changeProperty: property,
                                changeValue: valueToShow,
                            };
                        });
                    })
                );
            })
        );
    }
        
    private getActionType(obj: any): string | null {
        switch (obj.changeType) {
            case 1:
            return CHANGE_ACTION_TYPES.ADD;
            case 2:
                if ((obj.parameters.activityStatusOld === ACTIVITY_STATUS.PlannedAndLocked && obj.parameters.activityStatusNew !== ACTIVITY_STATUS.PlannedAndLocked) ||
                    (obj.parameters.activityStatusOld !== ACTIVITY_STATUS.PlannedAndLocked && obj.parameters.activityStatusNew === ACTIVITY_STATUS.PlannedAndLocked)
                ) { return obj.parameters.activityStatusNew === ACTIVITY_STATUS.PlannedAndLocked ? CHANGE_ACTION_TYPES.LOCKED : CHANGE_ACTION_TYPES.UNLOCKED;
                } else if (obj.parameters.activityStatusNew === ACTIVITY_STATUS.Deleted) {
                    return CHANGE_ACTION_TYPES.DELETE;
                } else if (obj.parameters.activityStatusNew === ACTIVITY_STATUS.NotRequired) {
                    return CHANGE_ACTION_TYPES.NOT_REQUIRED;
                } else if (obj.parameters.activityStatusNew === ACTIVITY_STATUS.Cancelled) {
                    return CHANGE_ACTION_TYPES.CANCEL;
                } else if (obj.parameters.activityStatusOld === ACTIVITY_STATUS.NotRequired && obj.parameters.activityStatusNew === ACTIVITY_STATUS.Planned) {
                    return CHANGE_ACTION_TYPES.UNASSIGN;
                }
            break;
            case 3:
                if (obj.parameters.resourceIdNew === '0') {
                    return CHANGE_ACTION_TYPES.UNASSIGN;
                } else if (obj.parameters.resourceIdNew !== null && obj.parameters.resourceIdNew !== '0') {
                    return CHANGE_ACTION_TYPES.ASSIGN;
                } else if(obj.parameters.resourceIdNew === '0' && obj.activityChangeUserType === '2') {
                    return CHANGE_ACTION_TYPES.UNASSIGN;
                }
            break;
            case 4:
                return CHANGE_ACTION_TYPES.CHANGE;
            case 5:
                return obj.parameters.detailValueOld === '' ? CHANGE_ACTION_TYPES.ADD : CHANGE_ACTION_TYPES.CHANGE;
            case 6:
                return CHANGE_ACTION_TYPES.CHANGE;
            default:
                return null;
        }
    }

    private getProperty(obj: ActivityChangeInformation, value: any, actTypes: Array<ActivityType>): string {
        switch (obj.changeType) {
            case 1:
                return actTypes.find(({ id }) => id === value.activityTypeId).displayName;
            case 2:
                return actTypes.find(({ id }) => id === value.activityTypeId).displayName;
            case 3:
                return actTypes.find(({ id }) => id === value.activityTypeId).displayName;
            case 4:
                return this.getDateTimeProperty(obj.parameters.startNew, obj.parameters.startOld, obj.parameters.endNew, obj.parameters.endOld);
            case 5:
                return this.getDetailTypeProperty(obj.parameters.detailType);
            case 6:
                return this.translateService.instant('Note');
            default:
                return actTypes.find(({ id }) => id === value.activityTypeId).displayName;
        }
    }
      
    private getValueToShow(obj: ActivityChangeInformation, value: any): string {
        switch (obj.changeType) {
            case 1:
                return value.resourceDisplayName ?? value.resourceDisplayName !== null ? value.resourceDisplayName : '';
            case 2:
                return value.resourceDisplayName ?? value.resourceDisplayName !== null ? value.resourceDisplayName : '';
            case 3:
                return value.resourceDisplayName;
            case 4:
                return value.resourceDisplayName ?? value.resourceDisplayName !== null ? value.resourceDisplayName : '';
            case 5:
                return '(...)';
            case 6:
                return '(...)';
        }
    }

    private getDetailTypeProperty(detailType: string): string {
        switch (detailType) {
            case '1':
                return this.translateService.instant('Description');
            case '2':
                return this.translateService.instant('Hyperlink');
            case '3':
                return this.translateService.instant('Imposes risk');
            case '4':
                return this.translateService.instant('Number of subjects');
            case '5':
                return this.translateService.instant('Cancellation reason');
            default:
                return "";
        }
    }
      
    private getDateTimeProperty(startNew: string, startOld: string, endNew: string, endOld: string): string {
        if (startNew !== startOld && endNew !== endOld) {
            return this.translateService.instant('Start date/End date');
        } else if (startNew !== startOld) {
            return this.translateService.instant('Start time');
        } else if (endNew !== endOld) {
            return this.translateService.instant('End time');
        }
    }
}
