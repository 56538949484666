import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { OmrpAuthGuard } from 'src/app/core/authentication/omrp-authentication.guard';
import { PermissionsGuard } from 'src/app/shared/guards/permissions.guard';
import { PERMISSIONS } from 'src/app/shared/models/Enums';

import { ActivityDemandComponent } from './activity-demand.component';
import { ManageCountersComponent } from './manage-counters/manage-counters.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { ManageTemplatesComponent } from './manage-templates/manage-templates.component';

export const activityDemandRoutes: Routes = [
    {
        path: 'activity-demand',
        canActivate: [OmrpAuthGuard, PermissionsGuard],
        data: {
            permission: PERMISSIONS.ACTIVITY_DEMAND,
        },
        children: [
            {
                path: '',
                component: ActivityDemandComponent,
                data: {
                    breadcrumbs: [
                        {
                            caption: 'Activity demand',
                        },
                    ],
                },
            },
            {
                path: 'manage-counters',
                component: ManageCountersComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/activity-demand',
                            caption: 'Activity demand',
                        },
                        {
                            caption: 'Manage counters'
                        },
                    ],
                },
            },
            {
                path: 'edit-template/:id',
                component: EditTemplateComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/activity-demand',
                            caption: 'Activity demand',
                        },
                        {
                            route: '/activity-demand/manage-templates',
                            caption: 'Manage templates',
                        }
                    ],
                },
            },
            {
                path: 'manage-templates',
                component: ManageTemplatesComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/activity-demand',
                            caption: 'Activity demand',
                        },
                        {
                            caption: 'Manage templates'
                        },
                    ],
                },
            },
            //will be added later, when we will have the components for these screens
            // 
            // {
            //     path: 'activity-plans',
            //     component: ,
            //     data: {
            //         breadcrumbs: [
            //             {
            //                 route: '/activity-demand',
            //                 caption: 'Activity demand',
            //             },
            //             {
            //                 caption: 'Manage activity plans'
            //             },
            //         ],
            //     },
            // },
            // {
            //     path: 'plan-overview',
            //     component: ,
            //     data: {
            //         breadcrumbs: [
            //             {
            //                 route: '/activity-demand',
            //                 caption: 'Activity demand',
            //             },
            //             {
            //                 caption: 'Activity plan overview'
            //             },
            //         ],
            //     },
            // }
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(activityDemandRoutes)],
    exports: [RouterModule],
})
export class ActivityDemandRoutingModule {}
