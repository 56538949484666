<div class="schedule__overview__container" *ngIf="daypartsReadySubject | async">
    <app-empty-filters-panel
        [collapsePanelMessage]="'Show schedule'"
        [filtersPanelState]="filtersPanelState"
        (updateShowFiltersState)="updateShowFiltersStateAndGetScheduleData($event)">
        <app-schedule-filters-header-old
            filtersHeader
            (hideFiltersPanelOnSelectedFilterSetting)="hidePanelOnSelectedFilterSetting()"
        ></app-schedule-filters-header-old>

        <div filtersContent class="filters__content">
            <app-schedule-filters-old></app-schedule-filters-old>
            <app-schedule-filters-settings-old></app-schedule-filters-settings-old>
        </div>
    </app-empty-filters-panel>


    <div *ngIf="(schedules$ | async).length === 0" class="empty__state__container">
        <div class="empty__state__container__content" *ngIf="(isLoadingSchedule$ | async) === false">
            <mat-icon>calendar_today</mat-icon>
            <div class="empty__state__container__message__huge">
                {{ "No schedules to show yet." | translate }}
            </div>
            <div>
                {{ "Use the filters panel to create a planning overview." | translate }}
            </div>
        </div>

        <div class="overlay-spinner-container" *ngIf="isLoadingSchedule$ | async">
            <mat-spinner diameter="100"></mat-spinner>
        </div>
    </div>

    <div
        class="schedule__container"
        [ngClass]="{'extended-schedule-container': (filterSettings$ | async) > 0}"
        *ngIf="(schedules$ | async).length > 0">
        <div class="schedule">
            <div class="navigation-header">
                <button mat-button class="arrow-button" (click)="onUpdatePeriod(-28)">
                    <mat-icon>chevron_left</mat-icon>M
                </button>
                <button mat-button class="arrow-button" (click)="onUpdatePeriod(-7)">
                    <mat-icon>chevron_left</mat-icon>W
                </button>
                <button mat-button class="arrow-button" (click)="onUpdatePeriod(-1)">
                    <mat-icon>chevron_left</mat-icon>D
                </button>
    
                <button mat-button class="arrow-button" (click)="onUpdatePeriod(1)">
                    D<mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
                <button mat-button class="arrow-button" (click)="onUpdatePeriod(7)">
                    W<mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
                <button mat-button class="arrow-button" (click)="onUpdatePeriod(28)">
                    M<mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
            
                <div class="date-period">
                    <div *ngIf="scheduleStartDate$ | async as startDate">{{ startDate | date: (dateFormat$ | async) }}</div> 
                    <div *ngIf="scheduleEndDate$ | async as endDate">&nbsp;-&nbsp;{{ endDate | date: (dateFormat$ | async) }}</div>
                    &nbsp;(<span translate>week&nbsp;</span> <strong *ngIf="scheduleStartDate$ | async as scheduleStartDate">{{ scheduleStartDate | date:'w'}}</strong>)
                </div>

                <div class="configuration-container">
                    <mat-slider
                        *ngIf="(scheduleNoOfDays$ | async) > 7"
                        [formControl]="sliderControl"
                        [min]="minWidthSlider$ | async"
                        [max]="300"><input matSliderThumb />
                    </mat-slider>
    
                    <mat-slide-toggle
                        class="bol-slide-toggle slide-toggle-position"
                        [formControl]="activityDialogControl">
                        <span
                            class="activity-dialog"
                            translate>
                            Enable activity details dialog
                        </span>
                    </mat-slide-toggle>
                </div>
            </div>
            <app-schedule-calendar-old></app-schedule-calendar-old>
        </div>
    </div>
</div>