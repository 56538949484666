<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<table mat-table [dataSource]="dataSource" class="bol-table">
    <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef> 
            <span class="display-name-header" translate>Name</span>
        </th>
        <td mat-cell *matCellDef="let data">
            <div class="permission-label">
                <button mat-icon-button
                    [class.button-children-selected]="hasChildrenSelected(data)"
                    [style.visibility]="!data.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 32"
                    (click)="toggleExpandableEntity(data)">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(data) ? 'arrow_drop_down' : 'arrow_right'}}
                    </mat-icon>
                </button>
                {{data.displayName}}
            </div>
        </td>
    </ng-container>

    <ng-container *ngFor="let column of definedColumnDefinitions" [matColumnDef]="column.entityProperty">
        <th mat-header-cell *matHeaderCellDef translate> {{column.columnDisplayName}} </th>
        <td mat-cell *matCellDef="let data">
            <mat-checkbox
                class="bol-checkbox bol-primary"
                [checked]="getNodePermissionState(data, column.permissionType)"
                [disabled]="disabledPermissions"
                (change)="onSelectEntity(data.id, column.entityProperty, column.permissionType)">
            </mat-checkbox>
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-container *ngIf="this.dataSource?.data?.length === 0">
    <p>{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase }  }}</p>
</ng-container>

