import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { cache } from 'src/app/core/rxjs-utils/cache.operator';
import { observeProperty } from 'src/app/core/rxjs-utils/observe-property';

import { FilterSetting } from '../../stores/filter-settings-store/filter-setting.model';
import {
    FilterSettingConfigurationDialogComponent,
    IFilterSettingConfigurationModalData,
} from './components/filter-setting-configuration-dialog/filter-setting-configuration-dialog.component';
import {
    FilterSettingSaveDialogComponent,
} from './components/filter-setting-save-dialog/filter-setting-save-dialog.component';

@Component({
    selector: 'app-filter-setting-configuration',
    templateUrl: './filter-setting-configuration.component.html',
    styleUrls: ['./filter-setting-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSettingConfigurationComponent implements OnInit, OnDestroy {
    @Input() public filterSettings: Array<FilterSetting>;
    @Input() public selectedFilterSettingId: number;
    @Input() public filterChangesInPending: boolean;
    @Input() public swapButtons: boolean;

    @Output() public readonly updateSelectedFilterSettingIdEvent = new EventEmitter<number>();
    @Output() public readonly removeFilterSettingEvent = new EventEmitter<FilterSetting>();
    @Output() public readonly updateFilterSettingEvent = new EventEmitter<FilterSetting>();

    @Output() public readonly saveNewFilterSettingEvent = new EventEmitter<string>();
    @Output() public readonly overwriteSelectedFilterSettingEvent = new EventEmitter<undefined>();

    public filterSettings$: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$: Observable<number>;
    public selectedFilterSetting$: Observable<FilterSetting>;
    public filterChangesInPending$: Observable<boolean>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: MatDialog
    ) { }

    public onDisplayFilterSettingConfigurationDialog(): void {
        const dialogRef = this.dialogService.open(FilterSettingConfigurationDialogComponent, {
            data: {
                filterSettings$: this.filterSettings$,
                selectedFilterSettingId$: this.selectedFilterSettingId$
            } as IFilterSettingConfigurationModalData,
            width: '760px',
            autoFocus: false,
        });

        this.subscription.add(
            dialogRef.componentInstance.removeFilterSettingEvent.subscribe(filterSetting =>
                this.removeFilterSettingEvent.emit(filterSetting)
            )
        );

        this.subscription.add(
            dialogRef.componentInstance.selectFilterSettingIdEvent.subscribe(id =>
                this.updateSelectedFilterSettingIdEvent.emit(id)
            )
        );

        this.subscription.add(
            dialogRef.componentInstance.updateFilterSettingEvent.subscribe(filterSetting =>
                this.updateFilterSettingEvent.emit(filterSetting)
            )
        );
    }

    public onDisplayFilterSettingSaveDialog(): void {
        const dialogRef = this.dialogService.open(FilterSettingSaveDialogComponent, {
            data: {
                selectedFilterSetting: this.selectedFilterSettingId ?
                    this.filterSettings.find(setting => setting.id === this.selectedFilterSettingId) : undefined
            },
            width: '600px',
            autoFocus: false,
        });

        this.subscription.add(
            dialogRef.componentInstance.saveNewFilterSettingEvent.subscribe(filterSettingName =>
                this.saveNewFilterSettingEvent.emit(filterSettingName)
            )
        );

        this.subscription.add(
            dialogRef.componentInstance.overwriteSelectedFilterSettingEvent.subscribe(() =>
                this.overwriteSelectedFilterSettingEvent.emit()
            )
        );
    }

    public ngOnInit() {
        this.filterSettings$ = observeProperty(this as FilterSettingConfigurationComponent, 'filterSettings').pipe(
            cache()
        );

        this.selectedFilterSettingId$ = observeProperty(this as FilterSettingConfigurationComponent, 'selectedFilterSettingId').pipe(
            cache()
        );

        this.filterChangesInPending$ = observeProperty(this as FilterSettingConfigurationComponent, 'filterChangesInPending').pipe(
            cache()
        );

        this.selectedFilterSetting$ = combineLatest([
            this.filterSettings$,
            this.selectedFilterSettingId$
        ]).pipe(
            map(([
                filterSettings,
                selectedId
            ]) => filterSettings.find(setting => setting.id === selectedId))
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
