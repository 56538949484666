import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateTimeUtilityService } from '../../services/date-time-utility.service';
import { StatusPageQuery } from '../status-page-store/status-page.query';
import { AdtCounter } from '../manage-template-store/manage-template.model';
import { ManageTemplateCounterState, ManageTemplateCounterStore } from './manage-template-counter.store';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplateCounterQuery extends StatusPageQuery<ManageTemplateCounterState> {
    
    constructor(
        protected store: ManageTemplateCounterStore,
        protected dateTimeUtilityService: DateTimeUtilityService 
    ) {
        super(store);
    }

    public getTemplateCounters(): Observable<Array<AdtCounter>> {
        return this.selectAll().pipe(
            map(counters => counters.sort((a, b) => {
                return a.activityDemandCounterInfo.displayName.localeCompare(b.activityDemandCounterInfo.displayName);
            }))
        );
    }

    public getTemplateCountersSync(): Array<AdtCounter> {
        const counters = this.getValue().entities;

        return Object.values(counters);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getCalculatingCountersInProgressState(): Observable<boolean> {
        return this.select(state => state.ui.calculatingCountersInProgress);
    }

    public getCounterIdSync(id: number): number {
        const counters = this.getValue().entities;
        const counterId = Object.values(counters).find(counter => counter.id === id)?.activityDemandCounterInfo.id;

        return counterId;
    }

    public getSelectedCounterIdsForTemplate(): Observable<Array<number>> {
        return this.selectAll().pipe(
            map(counters => counters.map(counter => counter.activityDemandCounterInfo.id))
        );
    }

    public getReloadCountersState(): Observable<boolean> {
        return this.select(state => state.ui.showReloadCounters);
    }
}