import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { BolMultiselectLabelsService } from '../../services/bol-multiselect-labels.service';
import { UtilsService } from '../../services/utils.service';
import { BolSelectItem, BolSelectMultiTextConfiguration } from '@ortec/bolster/select';

@Component({
    selector: 'app-formly-multiselect',
    templateUrl: './formly-multiselect.component.html',
    styleUrls: ['./formly-multiselect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMultiSelectComponent extends FieldType implements OnInit, OnDestroy {
    public selectLabels$!: Observable<BolSelectMultiTextConfiguration>;

    public multiselectOptions: Array<BolSelectItem<any>> = [];
    public selectedMultiselectItemIds: Array<number> = [];

    public bolMultiSelectFormControl = new UntypedFormControl([]);

    private readonly subscription = new Subscription();

    constructor(
        private readonly bolMultiselectLabelsService: BolMultiselectLabelsService,
        private readonly utilsService: UtilsService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.selectLabels$ = this.bolMultiselectLabelsService.selectLabels$;
        this.multiselectOptions = this.field.props.entities?.map(option => ({
            label: option.displayName,
            value: option.id
        }));
        if (this.props.required) {
            this.bolMultiSelectFormControl.addValidators([Validators.required]);
        }
        
        if (this.props.disabled) {
            this.bolMultiSelectFormControl.disable();
        }
        
        this.setPreselectedMultiselectItems();
        this.handleMultiselectItemsOnChange();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private setPreselectedMultiselectItems(): void {
        this.selectedMultiselectItemIds = this.model[this.field.key as string] || [];
        const filteredMultiselectItemIds = this.selectedMultiselectItemIds.filter(id => this.multiselectOptions.some(item => item.value === id));

        this.bolMultiSelectFormControl.setValue(filteredMultiselectItemIds, {emitEvent: false});
    }

    private handleMultiselectItemsOnChange(): void {
        this.subscription.add(
            this.bolMultiSelectFormControl.valueChanges.pipe(
                debounceTime(0),
                filter(selectedItems =>
                    !this.utilsService.arraysEqual(selectedItems, this.selectedMultiselectItemIds)
                ),
                tap((selectedItems: Array<number>) => {
                    this.selectedMultiselectItemIds = selectedItems;

                    this.onFilteredEntitiesChanged(this.selectedMultiselectItemIds);
                })
            ).subscribe()
        );
    }

    private onFilteredEntitiesChanged(ids: Array<number>): void {
        this.formControl.setValue(ids, {emitModelToViewChange: false});
    }
}
