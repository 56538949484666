<mat-card class="bol-card" class="content-wrapper">
    <div *ngIf="true">
        <div>
            <h3 data-cy="EntityTitle" translate>Holidays</h3>
        </div>
        <div class="outer">
            <div class="inner">
                <soca-slim-dropdown
                    [selectedItem]="selectedItem"
                    [items]="items$ | async"
                    (selectedItemChanged)="onSelectedYear($event)"
                >
                </soca-slim-dropdown>
            </div>
            <div class="divider"></div>
            <div class="inner">
                <button
                    mat-button
                    class="bol-button-solid"
                    (click)="onAddHoliday()">
                    <div data-cy="AddEntityButton" translate>Add holiday</div>
                </button>
            </div>
        </div>
        <div class="holidays-list-container"
            *ngIf="((holidays$ | async)?.length > 0 )"
            data-cy="HolidaysListContainer">
            <div class="holidays-list-header holiday-row">
                <div class="holiday-date" data-cy="HolidayDateTitle" translate>
                    Date
                </div>
                <div class="holiday-text" data-cy="HolidayDescriptionTitle" translate>
                    Description
                </div>
            </div>

            <div *ngFor="let holiday of (holidays$ | async); let i = index">
                <app-holiday-item
                    [ngModel]="holiday"
                    [holidays]="holidays$ | async"
                    [index]="i"
                    (removeHolidayEvent)="onDeleteHoliday($event)"
                    (updateHolidayEvent)="onUpdateHoliday($event)"
                ></app-holiday-item>
            </div>
        </div>

        <div class="empty-state"
            *ngIf="showEmptyState$ | async">
            <mat-icon>timelapse</mat-icon>
            <div class="empty-state-description" data-cy="EntityNoEntitiesFound" translate>
                No holidays available yet
            </div>
        </div>
    </div>

    <div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false) || (showLoadingSpinner$ | async)">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
</mat-card>
