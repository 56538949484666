import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { OwsActivityType } from './ows-activity-type.model';
import { OwsActivityTypeStore } from './ows-activity-type.store';

@Injectable({ providedIn: 'root' })
export class OwsActivityTypeService {
    constructor(
        protected owsActivityTypeStore: OwsActivityTypeStore,
        private readonly http: HttpClient,
    ) { }

    public get() {
        return this.http.get<Array<OwsActivityType>>('/api/OwsInterface/OwsActivityTypes').pipe(
            map((activityTypes) => {
                this.owsActivityTypeStore.set(activityTypes);
            }));
    }
}
