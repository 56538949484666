<mat-form-field
    #matFormField
    class="bol-form-field select-form-field"
    floatLabel="{{floatLabel ? 'always' : 'auto'}}"
    [ngClass]="{'select-form-field-underline': (entitySelectControl.valid || !entitySelectControl.touched) && removeUnderlinePadding}">

    <mat-label translate>{{singleSelectTablePlaceholder}}</mat-label>
    <mat-select
        #matSelect
        matInput
        multiple
        [required]="required"
        [formControl]="entitySelectControl"
        ngDefaultControl
        placeholder="{{placeholderText}}"
        panelClass="select-panel">
                
            <mat-form-field class="bol-form-field entity-search" *ngIf="dataSource?.data?.length > 0">
                <input
                    #input
                    matInput
                    data-cy="SearchSingleSelectTable"
                    [formControl]="searchControl"
                    placeholder="{{searchFieldPlaceholder | translate}}"
                    autocomplete="off"/>

                <button
                    mat-button
                    matSuffix
                    disabled
                    class="bol-button-text"
                    *ngIf="!searchControl || !searchControl.value || searchControl.value.length === 0">
                    <mat-icon class="search-icon">search</mat-icon>
                </button>

                <button
                    mat-button
                    matSuffix
                    class="cancel-button bol-button-text icon-only bol-gray"
                    *ngIf="searchControl && searchControl.value && searchControl.value.length > 0"
                    (click)="onResetValue()">
                    <mat-icon class="search-icon">cancel</mat-icon>
                </button>
            </mat-form-field>

            <div class="table-container">
                <mat-table 
                    *ngIf="dataSource?.data?.length > 0"
                    matSort
                    matSortDisableClear
                    [dataSource]="dataSource"
                    [ngClass]="{'striped-table': stripedTable}"
                    class="bol-table bol-table-row-hover">
    
                    <ng-container *ngFor="let column of columnDefinition; first as isFirst" [matColumnDef]="column.entityProperty">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span translate>{{column.columnDisplayName}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" [ngStyle]="column.stylingProperty ? row[column.stylingProperty]: ''">
                             <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                            {{ column.cell ? column.cell(row) : row[column.entityProperty]}}
                        </mat-cell>
                    </ng-container>
    
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row 
                        mat-row
                        (click)="onEntitySelected(row)" 
                        [class.selected]="row.id === internalSelectedEntityId"
                        *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>

                    <ng-container *ngIf="!dataSource?.data?.length">
                        <tr matNoDataRow class="bol-table-empty-state">
                            <td [attr.colspan]="displayedColumns.length">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase } }}</td>
                        </tr>
                    </ng-container>
                </mat-table>
            </div>
            <mat-option class="hide-option" disabled></mat-option>

            <div class="no-results-founds" *ngIf="dataSource?.data?.length === 0">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase } }}</div>
    </mat-select>

    <mat-error *ngIf="entitySelectControl.invalid" translate>{{errorMessage}}</mat-error>
</mat-form-field>