import { Injectable } from '@angular/core';
import { combineQueries, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityUI, EntityUIState } from '../entity-ui-models';
import { OrganizationUnitQuery } from '../organization-unit-store/organization-unit.query';
import { Skill } from './skill.model';
import { SkillService } from './skill.service';
import { SkillState, SkillStore } from './skill.store';

@Injectable({
    providedIn: 'root'
})
export class SkillQuery extends QueryEntity<SkillState> {
    public ui: EntityUIQuery<EntityUIState>;

    constructor(
        protected store: SkillStore,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly skillService: SkillService
    ) {
        super(store);
        this.createUIQuery();
    }

    public getSkillsSync(): Array<Skill> {
        const skills = this.getValue().entities;

        return Object.values(skills).sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getSkillSync(id: number): Skill {
        return this.getEntity(id);
    }

    public getSkills(): Observable<Array<Skill>> {
        return this.selectAll().pipe(map(skills =>
            skills.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getSkillsUI(): Observable<Array<Skill>> {
        return combineQueries([
            this.selectAll(),
            this.ui.selectAll()
        ]).pipe(map(([skills, skillsUI]) => skills
            .map(skill => ({...skill, ...skillsUI.find(s => s.id === skill.id)}))
            .sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getSkill(id: number): Observable<Skill> {
        return this.selectEntity(id);
    }

    public getUISkill(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }
}
