/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { OwsActivityType } from './ows-activity-type.model';

export interface OwsActivityTypeState extends EntityState<OwsActivityType> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-activity-type' })
export class OwsActivityTypeStore extends EntityStore<OwsActivityTypeState> {

    constructor() {
        super();
    }
}
