import { Permission } from 'src/app/shared/models/Enums';

import { PermissionColumnDefinition } from '../permissions-tree.model';

export class PermissionsTreeAdapterService {
    
    public permissionColumnDefinitions: Array<PermissionColumnDefinition> = [
        {
            columnDisplayName: 'Read',
            entityProperty: 'readPermission',
            permissionType: Permission.read
        },
        {
            columnDisplayName: 'Write',
            entityProperty: 'readAndWritePermission',
            permissionType: Permission.readWrite
        },
        {
            columnDisplayName: 'Owner',
            entityProperty: 'ownerPermission',
            permissionType: Permission.owner
        },
    ];

    public getNodeStatusForPermissionType(newState: boolean, nodePermissionType: Permission, permissionType: Permission): boolean {      
        if (newState) {
            return permissionType <= nodePermissionType;
        } else {
            return permissionType !== nodePermissionType && permissionType <= nodePermissionType;
        }
        
    }

    public getUpdatedPermissionTypeStatus(newState: boolean, permissionType: Permission): Permission {
        switch (permissionType) {
            case Permission.read: {
                return newState ? permissionType : Permission.noPermission;
            }
            case Permission.readWrite: {
                return newState ? permissionType : Permission.read;
            }
            case Permission.owner: {
                return newState ? permissionType : Permission.readWrite;
            }
            default: return Permission.noPermission;
        }
    }
}