<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-table 
    data-cy="entityManageGridTable"
    matSort 
    [dataSource]="dataSource" 
    class="entity-manage-grid">
    <ng-container *ngFor="let column of columns; let lastColumn = last" [matColumnDef]="column.columnDef">
        <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-cy]="column.header">
            <div [bolPopoverOverflow]="popoverHoverHeader" class="popover-container-grid">
                <ng-template #popoverHoverHeader> {{ column.header | translate }} </ng-template>
                {{ translateService.get(column.header)| async }}
            </div>
        </mat-header-cell>
    
        <mat-cell *matCellDef="let row; let lastCell = last">
            <div class="table-cell"
                [class.custom-column-class]="column.columnDef === specificStylingColumn"
                [ngStyle]="column.stylingProperty ? row[column.stylingProperty]: ''"
                [attr.data-cy]="column.cell(row)">
                <ng-container *ngIf="column.icon">
                    <div data-cy="iconManageGrid">
                        <mat-icon>{{ column.icon(row) }}</mat-icon>
                    </div>
                </ng-container>
                <div [bolPopoverOverflow]="popoverHover" class="popover-container-grid">
                    {{ column.cell(row) }}
                </div>
                <ng-container *ngIf="lastColumn && rowMenuOptions.length > 0">
                    <button data-cy="rowMenuButton" mat-icon-button [matMenuTriggerFor]="rowMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    
                    <mat-menu #rowMenu="matMenu">
                        <ng-container *ngFor="let rowMenuOption of rowMenuOptions">
                            <button mat-menu-item
                                [attr.data-cy]="rowMenuOption.dataCy ?? rowMenuOption.label"
                                (click)="rowMenuOption.click()(row)"
                                [attr.data-cy]="rowMenuOption.label"
                            >
                                <mat-icon>{{rowMenuOption.icon}}</mat-icon>
                                <span translate>{{rowMenuOption.label}}</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
                <ng-template #popoverHover> {{ column.cell(row) }} </ng-template>
            </div>
        </mat-cell>
    </ng-container>

    <!-- Create filter fields based on filter type -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="getFilterFieldPerColumn(column)">
        <mat-header-cell *matHeaderCellDef class="filter-header-cell">
            <div class="filter-container">
                <div *ngIf="column.filterType === filterType.SEARCH">
                    <mat-form-field class="bol-form-field filter-container-field-search" data-cy="searchEntityField">
                        <input 
                            matInput 
                            placeholder="{{ 'Search' | translate }}..."
                            (input)="applyFilter($event.target.value, column)" />
                    </mat-form-field>
                </div> 
                <div *ngIf="column.filterType === filterType.MULTISELECT" class="filter-container-field">
                    <app-multiselect-tree
                        [entities]="column.filterOptions.entities"
                        [preSelectedEntities]="column.filterOptions.selectedIds$ | async"
                        (selectedEntityIdsChanged)="onUpdateMultiselectFilter($event, column)"
                        multiselectTreePlaceholder="{{column.filterOptions.name}}"
                        [required]="false"
                        [showSelectAll]="false"
                        [translationNeeded]="column.filterOptions.translationNeeded"
                        [attr.data-cy]="column.filterOptions.name">
                    </app-multiselect-tree>
                </div>
            </div>
        </mat-header-cell>
    </ng-container>

    <!-- Add the rows -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-header-row class="filter-header" *matHeaderRowDef="getFilterFieldPerColumns() sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <tr *matNoDataRow class="bol-table-empty-state">
        <td [attr.colspan]="displayedColumns.length"*ngIf="(entitiesLoadingState$ | async) === false" data-cy="noEntityFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase } }}</td>
    </tr>
</mat-table>

<div class="overlay-spinner" *ngIf="(entitiesLoadingState$ | async) === true">
    <mat-spinner diameter="100"></mat-spinner>
</div>
