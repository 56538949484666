import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';

import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';
import { GROUPING_OPTIONS, SCHEDULE_STORAGE_KEYS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';
import { ScheduleShowOption } from '../../stores/schedule-store/schedule.model';
import { ScheduleState } from '../../stores/schedule-store/schedule.store';

@Component({
    selector: 'app-schedule-filters-settings',
    templateUrl: './schedule-filters-settings.component.html',
    styleUrls: ['./schedule-filters-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleFiltersSettingsComponent implements OnInit, OnDestroy {
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public filterChangesInPending$: Observable<boolean> = of(true);
    public scheduleFiltersValidity$!: Observable<boolean>;

    private readonly subscription = new Subscription();
    private isFullUser: boolean;

    constructor(
        private readonly scheduleService: ScheduleService,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly userInfoQuery: UserInfoQuery,
    ) { }

    public ngOnInit(): void {
        this.isFullUser = this.userInfoQuery.getIsFullUserSync();
        this.filterSettingService.getFilterSettings().pipe(first()).subscribe();

        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_V2_FILTER_SETTING);
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();
        this.scheduleFiltersValidity$ = this.scheduleQuery.getScheduleFiltersValidity();

        this.filterChangesInPending$ = combineLatest([
            this.scheduleQuery.getScheduleFiltersValidity(),
            this.scheduleQuery.getUIState(),
            this.filterSettingQuery.filterSettingsUpdated()
        ]).pipe(map(([valid, _]) => valid && this.scheduleQuery.currentFilterSettingChangesUnsavedSync()));

        this.subscription.add(
            this.filterSettingQuery.getFilterSettingBySelectedId().pipe(
                tap((filterSetting) => {
                    if (!filterSetting) {
                        this.scheduleService.updateSelectedFilterSettingId(undefined);
                    } else {
                        const settings = JSON.parse(filterSetting.settings);
                        const loadingState = this.scheduleQuery.getScheduleLoadingStateSync();
                        const filterSettingsPanelOpenState = this.scheduleQuery.getFilterSettingsPanelStatusSync();
                        const loadingStateActivityDetailsDialog = this.scheduleQuery.getScheduleLoadingStateActivityDetailsDialogSync();
                        const defaultShowOptionValues: Array<ScheduleShowOption> = this.scheduleHelperService.getInitialShowOptions(this.isFullUser);
                        const allShowOptionsPresent = defaultShowOptionValues.every(defaultOption => settings.showOptions.some(showOption => showOption.value === defaultOption.value));
                        
                        //We set the alphabetical sorting when nothing is defined yet, which is only needed for existing filters
                        if (settings.sortedActivityTypeRootIdsForActivities !== undefined) {
                            this.scheduleService.updateScheduleStateOnFilterSettingChanged(
                                this.createScheduleState(
                                    filterSetting, 
                                    settings, 
                                    settings.sortedActivityTypeRootIdsForActivities, 
                                    loadingState, 
                                    filterSettingsPanelOpenState, 
                                    loadingStateActivityDetailsDialog, 
                                    allShowOptionsPresent
                                )
                            );
                        } 
                        else {
                            //wait for activity types to be loaded
                            this.scheduleQuery.getAlphabeticallySortedActivityTypeIdsForActivities(settings.activityTypeIdsForActivities)
                                .pipe(first())
                                .subscribe((sortedActivityTypeIds) => {
                                    this.scheduleService.updateScheduleStateOnFilterSettingChanged(
                                        this.createScheduleState(
                                            filterSetting, 
                                            settings, 
                                            sortedActivityTypeIds, 
                                            loadingState, 
                                            filterSettingsPanelOpenState, 
                                            loadingStateActivityDetailsDialog, 
                                            allShowOptionsPresent
                                        )
                                    );
                                }
                            );
                        }
                    }
                })
            ).subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSelectedFilterSettingIdChanged(id: number): void {
        this.filterSettingService.updateSelectedFilterSettingsId(id);
        this.scheduleService.updateSelectedFilterSettingId(id);

        if (id) {
            this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTER_SETTING_ID, id);
        }
    }

    public onSaveNewFilterSettingEvent(settingName: string): void {
        const newFilterSetting = this.scheduleQuery.getNewScheduleFilterSetting(settingName);

        this.filterSettingService.createFilterSetting(newFilterSetting).pipe(first()).subscribe(setting => {
            this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTER_SETTING_ID, setting.id);
        });
    }

    public onRemoveFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.deleteFilterSetting(setting).pipe(first()).subscribe();
    }

    public onUpdateFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.updateFilterSetting(setting).pipe(first()).subscribe();
    }

    public onOverwriteSelectedFilterSettingEvent(): void {
        const updatedFilterSetting = this.scheduleQuery.getUpdatedScheduleFilterSetting();

        this.filterSettingService.updateFilterSetting(updatedFilterSetting).pipe(first()).subscribe();
    }

    private createScheduleState(
        filterSetting: FilterSetting,
        settings: any,
        sortedActivityTypeIds: Array<number>,
        loadingState: boolean, 
        filterSettingsPanelOpenState: boolean, 
        loadingStateActivityDetailsDialog: boolean, 
        allShowOptionsPresent: boolean
    ): ScheduleState['ui']{
        return {
            selectedFilterSettingId: filterSetting.id,
            requestParameters: settings.requestParameters,
            showOptions: allShowOptionsPresent ? settings.showOptions : this.scheduleHelperService.replaceMissingShowOptionsWithDefaults(settings.showOptions, this.isFullUser),
            activityShowOptions: settings.activityShowOptions ?? this.scheduleHelperService.getInitialActivityShowOptions(this.isFullUser),
            activityTypeIds: settings.activityTypeIds,
            allOrganizationUnitsSelectedState: settings.allOrganizationUnitsSelectedState,
            applyFilterOnResourceScheduleState: settings.applyFilterOnResourceScheduleState ?? false,
            applyFilterOnActivityScheduleState: settings.applyFilterOnActivityScheduleState ?? true,
            activityTypeIdsForActivities: settings.activityTypeIdsForActivities,
            sortedActivityTypeRootIdsForActivities: sortedActivityTypeIds,
            groupingOptionType: settings.groupingOptionType === GROUPING_OPTIONS.DAY_PART ? GROUPING_OPTIONS.NO_GROUPING : settings.groupingOptionType,
            daterangeType: this.scheduleHelperService.convertDaterangeTypes(settings.numberOfDays, settings.daterangeType),
            daypartIds: settings.daypartIds,
            loadingState,
            savedMultisortingOrder: settings.savedMultisortingOrder ?? [],
            savedBaseResourceTypes: settings.savedBaseResourceTypes ?? [],
            savedExtraResourceTypes: settings.savedExtraResourceTypes ?? [],
            savedOrganizationUnits: settings.savedOrganizationUnits ?? [],
            savedResourceProperties: settings.savedResourceProperties ?? [],
            filterSettingsPanelOpenState,
            slotWidth: settings.slotWidth,
            hideEmptyRows: settings.hideEmptyRows,
            splitterPosition: settings.splitterPosition,
            loadingStateActivityDetailsDialog,
        };
    }
}
