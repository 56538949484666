import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ResourcesToHide } from 'src/app/features/planboard/filtering/store/filtering.model';

import { Resource } from '../../stores/resource-store/resource.model';
import { ResourceType } from '../../stores/resource-type-store/resource-type.model';
import { IResourceModalData, ResourceDialogComponent } from './components/resource-dialog.component';
import { Memoized } from '@ortec/utilities/core';

@Component({
    selector: 'app-resource-selection',
    templateUrl: './resource-selection.component.html',
    styleUrls: ['./resource-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceSelectionComponent implements OnDestroy {
    @Output() public readonly updateSelectedResourcesToHideEvent = new EventEmitter<Array<ResourcesToHide>>();

    @Input() public selectedResourcesToHide: Array<ResourcesToHide>;
    @Input() public selectedResourceTypeId: number;
    @Input() public required: boolean;
    @Input() public buttonText: string;
    @Input() public buttonIcon: string;
    @Input() private set resources(resources: Array<Resource>) {
        this.resourcesSubject.next(resources);
    };
    @Input() private set resourceTypes(resourceTypes: Array<ResourceType>) {
        this.resourceTypesSubject.next(resourceTypes);
    };

    private readonly resourcesSubject = new BehaviorSubject<Array<Resource>>(undefined);
    private readonly resourceTypesSubject = new BehaviorSubject<Array<ResourceType>>(undefined);
    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: MatDialog,
    ) { }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onDisplaySelectionDialog(): void {
        const dialogRef = this.dialogService.open(ResourceDialogComponent, {
            data: {
                resources$: this.resources$,
                resourceTypes$: this.resourceTypes$,
                selectedResourcesToHide: this.selectedResourcesToHide,
                selectedResourceTypeId: this.selectedResourceTypeId
            } as IResourceModalData,
            panelClass: 'dialog-large',
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IResourceModalData }) => {
                    if (dialogData) {
                        this.updateSelectedResourcesToHideEvent.next(dialogData.data.selectedResourcesToHide);
                    }
                })
            ).subscribe()
        );
    }

    @Memoized public get resources$(): Observable<Array<Resource>> {
        return this.resourcesSubject.asObservable();
    }

    @Memoized public get resourceTypes$(): Observable<Array<ResourceType>> {
        return this.resourceTypesSubject.asObservable();
    }
}
