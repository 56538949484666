import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { IManageEntitiesGridColumn } from '@ortec/soca-web-ui/grids';import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Memoized } from '@ortec/utilities/core';

import { EntityManageGridMenuOptions, RequestFinished, STATUS_REQUEST_FINISHED } from 'src/app/shared/components/entity-management/entity-manage-grid/entity-manage-grid.model';
import { EntityManageGridComponent } from 'src/app/shared/components/entity-management/entity-manage-grid/entity-manage-grid.component';
import { ManageTemplateService } from 'src/app/shared/stores/manage-template-store/manage-template.service';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { ManageTemplatesManagementQuery } from './store/manage-templates-management.query';
import { ManageTemplatesManagementService } from './store/manage-templates-management.service';
import { ManageTemplate } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { getManageTemplateGridColumns, getManageTemplatesForCloningFormFields, getManageTemplatesFormFields } from './manage-templates-form-definition';
import { ManageTemplatesHelperService } from './manage-templates-helpers/manage-templates-helpers.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
    selector: 'app-manage-templates',
    templateUrl: './manage-templates.component.html',
    styleUrls: ['./manage-templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageTemplatesComponent implements OnInit {
    @ViewChild('entityManageGrid') public entityManageGrid: EntityManageGridComponent;

    public requestFinishedSubject = new BehaviorSubject<RequestFinished>(undefined);
    public rowMenuOptions: Array<EntityManageGridMenuOptions> = [];
    public columns: Array<IManageEntitiesGridColumn>;
    public newEntityModel: ManageTemplate;
    public formFields: Array<FormlyFieldConfig>;

    constructor(
        private cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly manageTemplateService: ManageTemplateService,
        private readonly manageTemplateQuery: ManageTemplateQuery,
        private readonly manageTemplatesManagementQuery: ManageTemplatesManagementQuery,
        private readonly manageTemplatesManagementService: ManageTemplatesManagementService,
        private readonly manageTemplatesHelperService: ManageTemplatesHelperService,
        protected utilsService: UtilsService
    ) { }

    public ngOnInit(): void {
        this.newEntityModel = this.manageTemplatesHelperService.getNewManageTemplateObject();
        this.manageTemplateService.getTemplates(false).pipe(first()).subscribe();
        this.initialLoadingFinished$.pipe(
            filter((initialLoadingFinished) => initialLoadingFinished),
            first(),
            switchMap(() => this.manageTemplatesManagementQuery.getLengthInWeeksForFiltering()),
            filter(lengthInWeeks => lengthInWeeks !== undefined),
            tap(lengthInWeeks => {
                this.columns = getManageTemplateGridColumns(lengthInWeeks, this.utilsService);
                this.formFields = getManageTemplatesFormFields();
                this.manageTemplatesManagementService.updateInitialLoadingStateForGrid(false);
            })
        ).subscribe();

        this.rowMenuOptions = [
            {
                icon: 'edit',
                label: 'Edit',
                click: () => (row) => { this.onEditButtonClicked(row); },
            },
            {
                icon: 'file_copy',
                label: 'Clone',
                click: () => (row) => { 
                    this.formFields = getManageTemplatesForCloningFormFields();
                    this.cdr.detectChanges();
                    this.entityManageGrid.cloneGridEntity(row) 
                },
            },
            {
                icon: 'delete',
                label: 'Delete',
                click: () => (row) => { this.entityManageGrid.deleteSelectedGridEntity(row, 'ACTIVITY-DEMAND.TEMPLATE.DELETE-MESSAGE') },
            }
        ];
    }

    public onAddEntityClicked(): void {
        this.formFields = getManageTemplatesFormFields();
        this.cdr.detectChanges();
        this.entityManageGrid.addNewGridEntity();
    }

    public onEditButtonClicked(row: ManageTemplate): void {
        this.router.navigate(['/activity-demand/edit-template', row.id], { state: row });
    }

    public onDeleteTemplate(template: ManageTemplate): void {       
        this.manageTemplateService.deleteTemplate(template.id).pipe(first()).subscribe({
            next: () => {
                this.requestFinishedSubject.next({ statusAddRequest: STATUS_REQUEST_FINISHED.SUCCESS });
            },
            error: (error) => {
                this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.FAILED });
            },
        });
    };

    public onAddNewTemplate(template: ManageTemplate): void {
        this.manageTemplateService.saveTemplate(template).pipe(first()).subscribe({
            next: (savedTemplate) => {
                this.requestFinishedSubject.next({ statusAddRequest: STATUS_REQUEST_FINISHED.SUCCESS });
                this.router.navigate(['/activity-demand/edit-template', savedTemplate.id], { state: savedTemplate });
            },
            error: (error) => {
                this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.FAILED });
            },
        });
    };

    public onCloneNewTemplate(template: ManageTemplate): void {
        this.manageTemplateService.cloneTemplate(template).pipe(first()).subscribe({
            next: (clonedTemplate) => {
                this.requestFinishedSubject.next({ statusAddRequest: STATUS_REQUEST_FINISHED.SUCCESS });
                this.router.navigate(['/activity-demand/edit-template', clonedTemplate.id], { state: clonedTemplate });
            },
            error: (error) => {
                this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.FAILED });
            },
        });
    };

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return  this.manageTemplateQuery.getEntitiesLoadingState();
    }

    @Memoized public get initialLoadingStateForGrid$(): Observable<boolean> {
        return  this.manageTemplatesManagementQuery.getInitialLoadingStateForGrid();
    }

    @Memoized public get requestFinished$(): Observable<RequestFinished> {
        return  this.requestFinishedSubject.asObservable();
    }

    @Memoized public get entities$(): Observable<Array<ManageTemplate>> {
        return  this.manageTemplateQuery.getTemplates();
    }
}
