import { Injectable } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';

import { StatusPageQuery } from 'src/app/shared/stores/status-page-store/status-page.query';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';

import { OrganizationUnitConfigurationsState, OrganizationUnitsConfigurationsStore } from './organization-units-configurations.store';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUnitsConfigurationsQuery extends StatusPageQuery<OrganizationUnitConfigurationsState> {
    constructor(
        protected store: OrganizationUnitsConfigurationsStore,
        protected activityTypeQuery: ActivityTypeQuery,
    ) {
        super(store);
    }

    public getCurrentActivityTypeId(): Observable<number> {
        return this.select(state => state.selectedActivityTypeId);
    }

    public getCurrentOrganizationUnitId(): Observable<number> {
        return this.select(state => state.selectedOrganizationUnitId);
    }

    public getCurrentOrganizationUnitIdSync(): number {
        return this.getValue().selectedOrganizationUnitId;
    }

    public getMutableActivityTypesFilteredByOrganizationUnitId(): Observable<Array<ActivityType>> {
        return combineLatest([
                this.activityTypeQuery.getActivityTypes(),
                this.getCurrentOrganizationUnitId()
            ]).pipe(
            map(([activityTypes, selectedOrganizationUnitId]) => {
                const filteredActivityTypes = activityTypes.filter(activityType => activityType.ownerOrganizationUnitId === selectedOrganizationUnitId)
                const mutableActivityTypes = filteredActivityTypes.map(actType => {
                    return { ...actType};
                });

                return mutableActivityTypes;        
            })
        );
    }
}
  
