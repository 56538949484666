/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { ActivityTypeQuery } from '../../stores/activity-type-store/activity-type.query';
import { ActivityTypeCategory } from '../../stores/activity-type-category-store/activity-type-category.model';
import { ActivityType, IActivityTypeTree } from '../../stores/activity-type-store/activity-type.model';
import { IOrganizationUnitTree } from '../../stores/organization-unit-store/organization-unit.model';
import {
    ActivityTypeDialogComponent,
    IActivityTypeModalData,
} from './components/activity-type-dialog/activity-type-dialog.component';

@Component({
    selector: 'app-activity-type-selection',
    templateUrl: './activity-type-selection.component.html',
    styleUrls: ['./activity-type-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
    })
export class ActivityTypeSelectionComponent implements OnDestroy, OnInit {
    @Output() public readonly updateSelectedActivityTypeIdsEvent = new EventEmitter<Array<number>>();
    @Output() public readonly updateSelectedActivitiesOrganizationUnitIdsEvent = new EventEmitter<Array<number>>();
    @Output() public readonly updateSelectedActivityTypeCategoryIdsEvent = new EventEmitter<Array<number>>();

    @Input() public selectedActivityTypeIds: Array<number>;
    @Input() public selectedActivityTypeCategoryIds: Array<number>;
    @Input() public required: boolean;
    @Input() public noActivityTypesSelectedByDefault: boolean = false;
    @Input() public selectedOrganizationUnitsIds: Array<number>;
    @Input() private set activityTypes(actTypes: Array<ActivityType | IActivityTypeTree>) {
        this.activityTypesSubject.next(actTypes);
        this.totalActivityTypesCount = this.activityTypeQuery.getTotalActivityTypesCount(actTypes);
    };
    @Input() private set activityTypeCategories(actTypeCategories: Array<ActivityTypeCategory>) {
        this.activityTypeCategoriesSubject.next(actTypeCategories);
    };

    @Input() private set organizationUnits(orgUnits: Array<IOrganizationUnitTree>) {
        this.organizationUnitsSubject.next(orgUnits);
    };

    public totalActivityTypesCount: number;
    public activityTypes$: Observable<Array<ActivityType | IActivityTypeTree>>;
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;

    private readonly activityTypesSubject = new BehaviorSubject<Array<ActivityType | IActivityTypeTree>>(undefined);

    private readonly organizationUnitsSubject = new BehaviorSubject<Array<IOrganizationUnitTree>>(undefined);

    private activityTypeCategories$: Observable<Array<ActivityTypeCategory>>;
    private readonly activityTypeCategoriesSubject = new BehaviorSubject<Array<ActivityTypeCategory>>(undefined);

    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: MatDialog,
        private readonly activityTypeQuery: ActivityTypeQuery
    ) { }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.activityTypes$ = this.activityTypesSubject.asObservable();
        this.activityTypeCategories$ = this.activityTypeCategoriesSubject.asObservable();
        this.organizationUnits$ = this.organizationUnitsSubject.asObservable();
    }

    public onDisplaySelectionDialog(): void {
        const dialogRef = this.dialogService.open(ActivityTypeDialogComponent, {
            data: {
                activityTypes$: this.activityTypes$,
                activityTypeCategories$: this.activityTypeCategories$,
                selectedActivityTypeIds: this.selectedActivityTypeIds,
                selectedActivityTypeCategoryIds: this.selectedActivityTypeCategoryIds,
                organizationUnits$: this.organizationUnits$,
                selectedActivitiesOrganizationUnitsIds: this.selectedOrganizationUnitsIds,
                noActivityTypesSelectedByDefault: this.noActivityTypesSelectedByDefault
            } as IActivityTypeModalData,
            panelClass: 'dialog-large',
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IActivityTypeModalData }) => {
                    if (dialogData) {
                        this.updateSelectedActivityTypeIdsEvent.next(dialogData.data.selectedActivityTypeIds);
                        this.updateSelectedActivityTypeCategoryIdsEvent.next(dialogData.data.selectedActivityTypeCategoryIds);
                        this.updateSelectedActivitiesOrganizationUnitIdsEvent.next(dialogData.data.selectedActivitiesOrganizationUnitsIds);
                    }
                })
            ).subscribe()
        );
    }
}
