import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityUIState,EntityUI } from '../entity-ui-models';
import { ResourceProperty} from './resource-property.model';
import { ResourcePropertyState, ResourcePropertyStore } from './resource-property.store';

@Injectable({
    providedIn: 'root'
})
export class ResourcePropertyQuery extends QueryEntity<ResourcePropertyState> {
    public ui: EntityUIQuery<EntityUIState>;

    constructor(
        protected store: ResourcePropertyStore
    ) {
        super(store);
        this.createUIQuery();
    }

    public getResourceProperties(): Observable<Array<ResourceProperty>> {
        return this.selectAll().pipe(map((resourceproperties) => 
            resourceproperties.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getResourceProperty(id: number ): Observable<ResourceProperty> {
        return this.selectEntity(id);
    }

    public getUIResourceProperty(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }

    public getResourcePropertySync(id: number): ResourceProperty {
        return this.getEntity(id);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }
}
