/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';

export enum FILTER_SETTING_SAVE_ACTION_TYPE {
    SAVE_NEW = 'Save new',
    OVERWRITE_SELECTED = 'Overwrite selected'
}

export interface IFilterSettingSaveModalData {
    selectedFilterSetting: FilterSetting;
}

@Component({
    selector: 'app-filter-setting-save-dialog',
    templateUrl: './filter-setting-save-dialog.component.html',
    styleUrls: ['./filter-setting-save-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSettingSaveDialogComponent {
    @Output() public readonly overwriteSelectedFilterSettingEvent = new EventEmitter<undefined>();
    @Output() public readonly saveNewFilterSettingEvent = new EventEmitter<string>();

    public selectedOption = FILTER_SETTING_SAVE_ACTION_TYPE.SAVE_NEW;
    public saveNewOption = FILTER_SETTING_SAVE_ACTION_TYPE.SAVE_NEW;
    public overwriteSelectedOption = FILTER_SETTING_SAVE_ACTION_TYPE.OVERWRITE_SELECTED;

    public filterSettingNameControl = new UntypedFormControl('', [Validators.required]);
    public selectedFilterSetting!: FilterSetting;

    constructor(
        public dialogRef: MatDialogRef<IFilterSettingSaveModalData>,
        @Inject(MAT_DIALOG_DATA) dialogData: IFilterSettingSaveModalData
    ) {
        this.selectedFilterSetting = dialogData.selectedFilterSetting;
    }

    public onSaveChanges(): void {
        if ((this.selectedOption === this.saveNewOption) || !this.selectedFilterSetting) {
            this.saveNewFilterSettingEvent.emit(this.filterSettingNameControl.value);
        } else {
            this.overwriteSelectedFilterSettingEvent.emit();
        }

        this.onCloseModal();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }
}
