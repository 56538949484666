<div class="bol-modal__header">
    <span class="modal-title" translate>Choose Activity Type Timeslots</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content>
    <div>
        <div class="filters-activity-types">
            <ng-container *ngIf="mode === manageMode.ADD; else editContent">
                <app-multiselect-tree
                    class="organization-unit-filter-activity-type"
                    [entities]="organizationUnits$ | async"
                    [showSelectAll]="true"
                    [required]="false"
                    searchFieldPlaceholder="Search organization unit"
                    data-cy="activityTypeDialogOrganizationUnitsFilter"
                    multiselectTreePlaceholder="Organization units"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
                ></app-multiselect-tree>
            
                <app-single-select-table
                    entityName="Activity type"
                    singleSelectTablePlaceholder="Activity type"
                    [entities]="activityTypesForFiltering$ | async"
                    [columnDefinition]="columnDefinition"
                    [searchProperties]="searchProperties"
                    [entityProperty]="'shortName'"
                    [selectedEntityId]="selectedActivityTypeId$ | async"
                    (selectedEntityIdChanged)="onSelectedActivityTypeChange($event)">
                </app-single-select-table>
            </ng-container>
            
            <ng-template #editContent>
                <div *ngIf="selectedActivityType$ | async as selectedActivityType" style="display: flex;">
                    <div [style.background-color]="'#' + selectedActivityType.backColor" class="short-name-div">
                        {{ selectedActivityType.shortName }}
                    </div>
                    <div class="display-name-div">
                        {{ selectedActivityType.displayName }}
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="timeslots-table">
            <table mat-table [dataSource]="timeslotsOfActivityType$ | async" class="bol-table bol-table-striped-odd">
    
                <ng-container matColumnDef="startTime">
                    <th mat-header-cell *matHeaderCellDef translate>Start time</th>
                    <td mat-cell *matCellDef="let timeslot">{{timeslot.startTime}}</td>
                </ng-container>
    
                <ng-container matColumnDef="endTime">
                    <th mat-header-cell *matHeaderCellDef translate>End time</th>
                    <td mat-cell *matCellDef="let timeslot">{{timeslot.endTime}}</td>
                </ng-container>

                <ng-container matColumnDef="addToTemplate">
                    <th mat-header-cell *matHeaderCellDef  class="adt-checkbox-column" translate>Add to template</th>
                    <td mat-cell *matCellDef="let timeslot" class="adt-checkbox-column">
                        <mat-checkbox  
                            class="bol-checkbox"
                            [checked]="timeslot.addToTemplate"
                            [disabled]="timeslot.disabledInAddMode"
                            (change)="onUpdateTimeslot($event, timeslot)"
                            data-cy="addToTemplate">
                        </mat-checkbox>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0"></tr>
                <tr *matNoDataRow class="bol-table-empty-state">
                    <ng-container *ngIf="(timeslotsOfActivityType$ | async)?.length === 0 && (selectedActivityTypeId$ | async) !== null; else selectActivityType">
                        <td [attr.colspan]="displayedColumns.length" translate>No timeslots configured for this activity type</td>
                    </ng-container>
                    <ng-template #selectActivityType>
                        <td [attr.colspan]="displayedColumns.length" translate>Select an activity type to choose timeslots</td>
                    </ng-template>
                </tr>
            </table>
        </div>
        <div class="callout-message">
            <bol-callout *ngIf="isDaymark$ | async">{{ "ACTIVITY-DEMAND.EDIT-TEMPLATES.DAYPART-CALLOUT" | translate }}</bol-callout>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        data-cy="activityTypeDialogConfirmButton"
        (click)="onCancel()">
            <span translate>Cancel</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        data-cy="activityTypeDialogConfirmButton"
        class="bol-button-solid"
        (click)="onConfirm()">
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>