import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BolNotificationService } from '@ortec/bolster/notification';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { SavedEntityListForSchedule } from '../stores/schedule-store/schedule.model';
import { ScheduleQuery } from '../stores/schedule-store/schedule.query';
import { ScheduleState } from '../stores/schedule-store/schedule.store';

export interface MissingEntitiesResponse {
    filterId: number;
    updatedFilterOrganizationUnits: Array<number>;
    updatedFilterBaseResourceTypes: Array<number>;
    updatedFilterExtraResourceTypes: Array<number>;
    updatedFilterResourceProperties: Array<number>;
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleMissingEntitiesService {
    constructor(
        private readonly translateService: TranslateService,
        private readonly notificationService: BolNotificationService,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
    ) { }

    public handleMissingEntities(state: ScheduleState): Observable<MissingEntitiesResponse> {
        // We need to ensure that organization units and resource properties are loaded
        const organizationUnits$ = this.organizationUnitQuery.selectAll().pipe(
            filter(units => units?.length > 0),
            first()
        );

        const resourceProperties$ = this.resourcePropertyLanguageQuery.selectAll().pipe(
            filter(units => units?.length > 0),
            first()
        );

        return combineLatest([organizationUnits$, resourceProperties$]).pipe(
            switchMap(() => {
                const allOrganizationUnits = this.organizationUnitQuery.getOrganizationUnitsSync();
                const availableFilterOrganizationUnits = allOrganizationUnits.filter(unit =>
                    state.ui.requestParameters.organizationUnitIds.includes(unit.id)
                );
                const availableFilterOrganizationUnitIds = availableFilterOrganizationUnits.map(unit => unit.id);
        
                const allResourceProperties = this.resourcePropertyLanguageQuery.getResourcePropertiesLanguageIdsSync();
                const availableFilterResourcePropertyIds = allResourceProperties.filter(id =>
                    state.ui.requestParameters.resourcePropertyIds?.includes(id)
                );

                const savedOrganizationUnits = state.ui.savedOrganizationUnits;
                const savedBaseResourceTypes = state.ui.savedBaseResourceTypes;
                const savedExtraResourceTypes = state.ui.savedExtraResourceTypes;
                const savedResourceProperties = state.ui.savedResourceProperties;
            
                let idsToRetrieveResourceTypes: Array<number> = [];

                const isFullUser = this.userInfoQuery.getIsFullUserSync();
                if (isFullUser) {
                    idsToRetrieveResourceTypes = availableFilterOrganizationUnitIds;
                }
                else {
                    idsToRetrieveResourceTypes = [...availableFilterOrganizationUnitIds];
                    availableFilterOrganizationUnits.map(unit => {
                        if (!idsToRetrieveResourceTypes.includes(unit.parentId) && unit.parentId) {
                            idsToRetrieveResourceTypes.push(unit.parentId);
                        }
                    });
                }

                return this.resourceTypeService.getReportResourceTypes(idsToRetrieveResourceTypes).pipe(
                    first(), 
                    map((resourceTypes) => {
                        const allResourceTypesIds = resourceTypes.map(rt => rt.id);
                        const availableFilterBaseResourceTypeIds = allResourceTypesIds.filter(id =>
                            state.ui.requestParameters.baseResourceTypeIds.includes(id)
                        );
                        const availableFilterExtraResourceTypeIds = allResourceTypesIds.filter(id =>
                            state.ui.requestParameters.extraResourceTypeIds.includes(id)
                        );
    
                        this.getMissingFiltersMessage(
                            availableFilterOrganizationUnitIds,
                            availableFilterBaseResourceTypeIds,
                            availableFilterExtraResourceTypeIds,
                            availableFilterResourcePropertyIds,
                            savedOrganizationUnits,
                            savedBaseResourceTypes,
                            savedExtraResourceTypes,
                            savedResourceProperties,
                            state.ui.selectedFilterSettingId
                        );

                        const updatedFilterOrganizationUnits = state.ui.requestParameters.organizationUnitIds?.length === availableFilterOrganizationUnitIds.length ? undefined : availableFilterOrganizationUnitIds;
                        const updatedFilterBaseResourceTypes = state.ui.requestParameters.baseResourceTypeIds?.length === availableFilterBaseResourceTypeIds.length ? undefined : availableFilterBaseResourceTypeIds;
                        const updatedFilterExtraResourceTypes = state.ui.requestParameters.extraResourceTypeIds?.length === availableFilterExtraResourceTypeIds.length ? undefined : availableFilterExtraResourceTypeIds;
                        const updatedFilterResourceProperties = state.ui.requestParameters.resourcePropertyIds?.length === availableFilterResourcePropertyIds.length ? undefined : availableFilterResourcePropertyIds;
    
                        const response: MissingEntitiesResponse = {
                            filterId: state.ui.selectedFilterSettingId,
                            updatedFilterOrganizationUnits,
                            updatedFilterBaseResourceTypes,
                            updatedFilterExtraResourceTypes,
                            updatedFilterResourceProperties
                        };

                        return response;
                    })
                );
            })
        );
    }

    private getMissingFiltersMessage(
        preselectedOrganizationUnitIds: Array<number>,
        preselectedBaseResourceTypeIds: Array<number>,
        preselectedExtraResourceTypeIds: Array<number>,
        preselectedResourcePropertyIds: Array<number>,
        savedOrganizationUnits: Array<SavedEntityListForSchedule>,
        savedBaseResourceTypes: Array<SavedEntityListForSchedule>,
        savedExtraResourceTypes: Array<SavedEntityListForSchedule>,
        savedResourceProperties: Array<SavedEntityListForSchedule>,
        filterId: number
    ): void {
        let message = '';

        if (savedOrganizationUnits) {
            const unavailableOrganizationUnits = savedOrganizationUnits.filter(unit => !preselectedOrganizationUnitIds.some(id => id === unit.id));
            if (unavailableOrganizationUnits.length > 0) {
                const unavailableOrganizationNames = unavailableOrganizationUnits.map(ou => ou.displayName).join(', ');
                message += this.translateService.instant('The following organization units have been removed: ')
                    + unavailableOrganizationNames + '\n';
            }
        }

        if (savedBaseResourceTypes) {
            const unavailableBaseResourceTypes = savedBaseResourceTypes.filter(type => !preselectedBaseResourceTypeIds.some(id => id === type.id));
            if (unavailableBaseResourceTypes.length > 0) {
                const unavailableBaseResourceNames = unavailableBaseResourceTypes.map(rt => rt.displayName).join(', ');
                message += this.translateService.instant('The following base resource types have been removed: ')
                    + unavailableBaseResourceNames + '\n';
            }
        }

        if (savedExtraResourceTypes) {
            const unavailableExtraResourceTypes = savedExtraResourceTypes.filter(type => !preselectedExtraResourceTypeIds.some(id => id === type.id));
            if (unavailableExtraResourceTypes.length > 0) {
                const unavailableExtraResourceNames = unavailableExtraResourceTypes.map(rt => rt.displayName).join(', ');
                message += this.translateService.instant('The following extra resource types have been removed: ')
                    + unavailableExtraResourceNames + '\n';
            }
        }

        if (savedResourceProperties) {
            const unavailableResourceProperties = savedResourceProperties.filter(prop => !preselectedResourcePropertyIds.some(id => id === prop.id));
            if (unavailableResourceProperties.length > 0) {
                const unavailableResourcePropertyNames = unavailableResourceProperties.map(rp => rp.displayName).join(', ');
                message += this.translateService.instant('The following resource properties have been removed: ')
                    + unavailableResourcePropertyNames + '\n';
            }
        }

        if (message !== '' && this.scheduleQuery.getValue().ui.selectedFilterSettingId === filterId) {
            this.notificationService.warning(
                message,
                'warning',
                '',
                { panelClass: ['missing-filters-warning'] }
            );
        }
    }
}
