import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { OwsWorkTreatmentStore, OwsWorkTreatmentState } from './ows-work-treatment.store';

@Injectable({
    providedIn: 'root'
})
export class OwsWorkTreatmentQuery extends Query<OwsWorkTreatmentState> {

    constructor(
        protected store: OwsWorkTreatmentStore
    ) {
        super(store);
    }

    public getWorkTreatmentTexts(): Observable<Array<string>> {
        return this.select()
            .pipe(map(state => {
                return state.workTreatmentTexts;
            }));
    }

    public getWorkTreatmentTextsSync(): Array<string> {
        return this.getValue().workTreatmentTexts;
    }

    public getWorkActivityTypes(): Observable<Array<number>> {
        return this.select()
            .pipe(map(state => state.workActivityTypes));
    }

    public getWorkActivityTypesSync(): Array<number> {
        return this.getValue().workActivityTypes;
    }
}
