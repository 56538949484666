import { Injectable } from '@angular/core';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';

import { SkillsManagementStore } from './skills-management.store';

@Injectable({
    providedIn: 'root'
})
export class SkillsManagementService {
    constructor(
        protected skillsManagementStore: SkillsManagementStore
    ) { }

    public updateSelectedSkillId(id: number): void {
        this.skillsManagementStore.updateSelectedSkillId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.skillsManagementStore.updateSelectedOrganizationUnitId(id);
    }

    public updateShowChildUnits(value: boolean): void {
        this.skillsManagementStore.updateShowChildUnits(value);
    }

    public updateShowAllSkills(value: boolean): void {
        this.skillsManagementStore.updateShowAllSkills(value);
    }

    public updateManageMode(mode: MANAGE_MODE): void {
        this.skillsManagementStore.updateManageMode(mode);
    }

    public setStateForNewSkill(skillId: number): void {
        this.skillsManagementStore.setStateForNewSkill(skillId);
    }
}
