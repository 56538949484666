<div class="content-wrapper">
    <mat-card class="bol-card">
        <mat-card-content class="bol-card-content align-center">
            <h3 translate>An error occurred</h3>
            <p translate>See details below:</p>
            <pre>{{error}}</pre>

            <button 
                *ngIf="isFullUser$ | async"
                mat-button 
                class="bol-button-solid bol-blue">
                <a href="/omrp#!/portal" translate>Go back to portal</a>
            </button>

            <button 
                *ngIf="(isFullUser$ | async) === false"
                mat-button 
                class="bol-button-solid bol-blue">
                <a href="/angularomrp/overview/schedule" translate>Go back to schedule overview</a>
        </button>
        </mat-card-content>
    </mat-card>
</div>