import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Permission } from 'src/app/shared/models/Enums';
import { PermissionsTreePropagationOptions } from '../../permissions-tree.model';

export interface IPermissionsTreePropagationModalData {
    permission: Permission;
    status: boolean;
    entityName: string;
    entityNamePlural: string;
}

@Component({
    selector: 'app-permissions-tree-propagation-dialog',
    templateUrl: './permissions-tree-propagation-dialog.component.html',
    styleUrls: ['./permissions-tree-propagation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionsTreePropagationDialogComponent {
    @Output() public readonly changePermissionsTreePropagationOptionsEvent = new EventEmitter<PermissionsTreePropagationOptions>();

    public prefixedEntityName: string;
    public prefixedEntityNamePlural: string;
    public permissionStatus: boolean;
    public permissionType;

    constructor(
        public dialogRef: MatDialogRef<IPermissionsTreePropagationModalData>,
        @Inject(MAT_DIALOG_DATA) dialogData: IPermissionsTreePropagationModalData,
    ) {
        this.prefixedEntityName = 'MANAGE-PERMISSION-NAME.' + dialogData.entityName.toUpperCase();
        this.prefixedEntityNamePlural = 'MANAGE-PERMISSION-NAME.' + dialogData.entityNamePlural.toUpperCase();
        this.permissionStatus = dialogData.status;
        this.permissionType = Object.values(dialogData.permission);
    }

    public onChangeEntityPermission(shouldPropagate: boolean, rememberDialogAction: boolean): void {
        this.changePermissionsTreePropagationOptionsEvent.next({ shouldPropagate, rememberDialogAction, demoteParentPermission: false });

        this.dialogRef.close();
    }
}
