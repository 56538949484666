import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, Store } from '@datorama/akita';
import { ResourceTypeMapping } from './resource-type-mapping.model';

export interface ResourceTypeMappingState extends EntityState<ResourceTypeMapping> {
    ui: {
        selectedResourceTypeId: number;
        selectedOrganizationUnitId: number;
        showUnderlyingUnits: boolean;
        selectedOwsDepartmentId: number;
        selectedOwsDepartmentIdForMapping: number;
        showAllOwsResourceTypes: boolean;
        hideUnlinkedOwsResourceTypes: boolean;
        showLinkedOwsResourceTypes: boolean;
        isGlobal: boolean;
        mappings: Array<ResourceTypeMapping>;
    };
}

const initialState = {
    ui: {
        selectedResourceTypeId: undefined,
        selectedOrganizationUnitId: undefined,
        showUnderlyingUnits: false,
        selectedOwsDepartmentIdForMapping: undefined,
        selectedOwsDepartmentId: undefined,
        showAllOwsResourceTypes: true,
        hideUnlinkedOwsResourceTypes: false,
        showLinkedOwsResourceTypes: false,
        isGlobal: true,
        mappings: []
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource-type-mapping' })
export class ResourceTypeMappingStore extends Store<ResourceTypeMappingState> {

    constructor() {
        super(initialState);
    }

    public updateSelectedResourceTypeId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedResourceTypeId: id };

            return { ...state, ui };
        });
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOrganizationUnitId: id };

            return { ...state, ui };
        });
    }

    public updateIsGlobal(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, isGlobal: value };

            return { ...state, ui };
        });
    }

    public updateSelectedOwsDepartmentIdForMapping(value: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOwsDepartmentIdForMapping: value };

            return { ...state, ui };
        });
    }

    public updateShowChildUnits(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showUnderlyingUnits: value };

            return { ...state, ui };
        });
    }

    public updateSelectedOwsDepartmentId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOwsDepartmentId: id, showAllOwsResourceTypes: false };

            return { ...state, ui };
        });
    }

    public updateShowAllOwsResourceTypes(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showAllOwsResourceTypes: value, selectedOwsDepartmentId: undefined };

            return { ...state, ui };
        });
    }

    public updateHideUnlinkedOwsResourceTypes(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, hideUnlinkedOwsResourceTypes: value };

            return { ...state, ui };
        });
    }

    public updateShowLinkedOwsResourceTypes(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showLinkedOwsResourceTypes: value };

            return { ...state, ui };
        });
    }

    public updateMappings(mappingsLoaded: Array<ResourceTypeMapping>): void {
        this.update(state => {
            const mappings = [...mappingsLoaded];
            const uiState = { ...state.ui, mappings };
    
            return { ...state, ui: uiState };
        });
    }
}
