import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, combineLatest, merge, Observable, Subscription } from 'rxjs';
import { filter, first, map, skip, startWith, take, tap, withLatestFrom } from 'rxjs/operators';

import { cache } from 'src/app/core/rxjs-utils/cache.operator';
import { LanguageService } from 'src/app/shared/language';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityTypeCategoryService } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';
import { ActivityType, GetActivityTypesResponse, IActivityTypeTree } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { FILTER_SETTING_TYPE } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { ResourcePropertyLanguageService } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.service';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';

import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';
import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES } from '../../schedule-helpers/enums';
import { DaterangeTypes, GroupingOption, ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleRequestParameters } from '../../stores/schedule-store/schedule-request-parameters.model';
import { ScheduleShowOption } from '../../stores/schedule-store/schedule.model';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';
import { DisplayOptionsData, ShowOptionsConfigurationDialogComponent } from './show-options-configuration-dialog/show-options-configuration-dialog.component';

@Component({
    selector: 'app-schedule-filters',
    templateUrl: './schedule-filters.component.html',
    styleUrls: ['./schedule-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleFiltersComponent implements OnInit, OnDestroy {
    @ViewChild('groupingOptionSelect') public groupingOptionSelect!: MatSelect;
    @ViewChild('daterangeTypeSelect') public daterangeTypeSelect!: MatSelect;

    public scheduleShowOptions$!: Observable<Array<ScheduleShowOption>>;
    public scheduleActivityShowOptions$!: Observable<Array<ScheduleShowOption>>;
    public organizationsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public organizationsForActivityTypes$: Observable<Array<IOrganizationUnitTree>>;
    public daypartOptions$: Observable<Array<Daypart>>;
    public preselectedDayparts$: Observable<Array<number>>;
    public preselectedOrganizationUnits$: Observable<Array<number>>;  
    public activityTypesForActivities$!: Observable<Array<IActivityTypeTree>>;
    public activityTypes$!: Observable<Array<ActivityType>>;
    public activityTypesForSorting$!: Observable<Array<ActivityType>>;
    public activityTypeCategories$!: Observable<Array<ActivityTypeCategory>>;
    public selectedActivityTypeIds$: Observable<Array<number>>;
    public selectedActivityTypeIdsForActivities$: Observable<Array<number>>;
    public sortedActivityTypeIdsForActivities$: Observable<Array<number>>;
    public baseResourceTypesForFiltering$: Observable<Array<ResourceType>>;
    public extraResourceTypesForFiltering$: Observable<Array<ResourceType>>;
    public selectedBaseResourceTypes$: Observable<Array<ResourceType>>;
    public selectedExtraResourceTypesIds$: Observable<Array<number>>;
    public selectedResourcePropertiesLength$: Observable<number>;
    public forceErrorStateOnBaseResourceTypes$: Observable<boolean>;
    public dateRangeTypeSelected$: Observable<SCHEDULE_DATERANGE_TYPES>;
    public groupingOptionSelected$: Observable<GROUPING_OPTIONS>;
    public applyFilterForActivitiesState$: Observable<boolean>;
    public applyFilterForResourcesState$: Observable<boolean>;
    public groupingOptions: Array<GroupingOption>;
    public daterangeTypes: Array<DaterangeTypes>;
    public buttonTitleActivitiesLabel = 'Select activity types';
    public buttonTitleResourcesLabel = 'Filter activity types';
    public buttonTitleActivities$: Observable<string>;
    public buttonTitleResources$: Observable<string>;
   
    public searchProperties: Array<string> = ['displayName'];

    private readonly FILTER_ON = 'on';
    private readonly FILTER_OFF = 'off';
    private readonly APPLY_FILTER_NONE = 'none';
    private readonly APPLY_FILTER_ACTIVE = 'active';
    private isFullUser: boolean;

    private readonly selectedOrganizationUnitIds$ = new BehaviorSubject<Array<number>>(undefined);
    private readonly subscription: Subscription = new Subscription();

    constructor(
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly daypartQuery: DaypartQuery,
        private readonly scheduleService: ScheduleService,
        private readonly languageService: LanguageService,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly resourcePropertyLanguageService: ResourcePropertyLanguageService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeCategoryService: ActivityTypeCategoryService,
        private readonly activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly dialogService: MatDialog
    ) { }

    public ngOnInit(): void {
        this.isFullUser = this.userInfoQuery.getIsFullUserSync();
        this.baseResourceTypesForFiltering$ = this.scheduleQuery.getBaseResourceTypesForFiltering();
        this.extraResourceTypesForFiltering$ = this.scheduleQuery.getExtraResourceTypesForFiltering();
        this.groupingOptions = this.scheduleHelperService.getInitialGroupingOptions();
        this.daterangeTypes = this.scheduleHelperService.getDaterangeTypeOptions();
        this.scheduleShowOptions$ = this.scheduleQuery.getShowOptions();
        this.scheduleActivityShowOptions$ = this.scheduleQuery.getActivityShowOptions();

        this.initializeFilters();

        this.subscription.add(
            this.languageService.currentLanguage$.subscribe((lang) => {
                const standardResporceProperties = this.scheduleHelperService.getStandardResourceProperties();
                this.resourcePropertyLanguageService.getResourcePropertiesByLanguageCode(lang.codeDto, standardResporceProperties).pipe(first()).subscribe();
            })
        );

        this.initGroupingOptionFilter();
        this.initOrganizationUnitsFilter();
        this.initBaseResourceTypesFilter();
        this.initExtraResourceTypesFilter();
        this.initActivityTypesFilter();
        this.initActivityTypeCategoriesFilterForActivities();
        this.initActivityTypesFilterForActivities();
        this.setupRemoveSelectedIfNotAvailable();
        this.initDayparts();
        this.initDaterangeTypeFilter();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    
    public openedGroupingOptionSelect(state: boolean): void {
        if (!state) {
            this.scheduleService.updateGroupingOptionType(this.groupingOptionSelect.value);
        }
    }

    public openedDaterangeTypeSelect(state: boolean): void {
        if (!state) {
            this.scheduleService.updateDaterangeType(this.daterangeTypeSelect.value);
        }
    }

    public onFilteredDaypartsChanged(ids: Array<number>): void {
        this.scheduleService.updateDaypartIds(ids);
    }

    public onFilteredOrganizationsChanged(ids: Array<number>): void {
        this.scheduleService.updateOrganizationUnitIdsParameter(ids);
        const allOrganizationUnitsSelected = this.scheduleQuery.allOrganizationUnitsSelected();
        this.scheduleService.updateAllOrganizationUnitsSelectedState(allOrganizationUnitsSelected);
        this.selectedOrganizationUnitIds$.next(ids);
    }

    public onFilteredBaseResourceTypesChanged(ids: Array<number>): void {
        this.scheduleService.updateBaseResourceTypeIdsParameter(ids);
    }

    public onFilteredExtraResourceTypesChanged(ids: Array<number>): void {
        this.scheduleService.updateExtraResourceTypeIdsParameter(ids);
    }

    public initializeFilters(): void {
        combineLatest([
            this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_V2_FILTER_SETTING),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingStateForActivityTypes(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.filterSettingQuery.getEntitiesLoadingState(),
            this.daypartQuery.getEntitiesLoadingState()
        ]).pipe(
            skip(1),
            filter(([_, ouLoading, ouLoadingAT, rtLoading, fLoading, dLoading]) => !ouLoading && !ouLoadingAT && !rtLoading && !fLoading && !dLoading),
            take(1)
        ).subscribe(([filterSettings, _]) => {
            const parameters: ScheduleRequestParameters = this.scheduleQuery.getRequestParametersSync();

            if (filterSettings.length === 0 && !this.isFullUser) {
                const organizationUnitIds$ = this.organizationUnitQuery.getAllIds().pipe(
                    filter(ids => ids.length > 0)
                );
                const validResourceIds$ = this.resourceTypeService.getValidResourceTypeIdsForCurrentResource();

                combineLatest([organizationUnitIds$, validResourceIds$]).pipe(
                    first(),
                    tap(([unitIds, validResourceIds]) => {
                        const updatedParameters: ScheduleRequestParameters = {
                            startDate: parameters.startDate,
                            endDate: parameters.endDate,
                            organizationUnitIds: unitIds,
                            baseResourceTypeIds: validResourceIds,
                            extraResourceTypeIds: [],
                            activityTypeIdsForActivity: parameters.activityTypeIdsForActivity,
                            activityTypeIdsForResource: parameters.activityTypeIdsForResource,
                            daypartIdsSelected: parameters.daypartIdsSelected,
                            fillActivityForActivity: parameters.fillActivityForActivity,
                            fillActivityForResource: parameters.fillActivityForResource
                        };

                        this.initFiltersBasedOnSelectedOrganization(updatedParameters);
                    })
                ).subscribe();
            }
        });

        const showOptions = this.scheduleHelperService.getInitialShowOptions(this.isFullUser);
        const activityShowOptions = this.scheduleHelperService.getInitialActivityShowOptions(this.isFullUser);
        this.scheduleService.updateShowOptions(showOptions);
        this.scheduleService.updateActivityShowOptions(activityShowOptions);
    }

    public updateSelectedActivityTypeIds(selectedIds: Array<number>): void {
        this.scheduleService.updateActivityTypeIds(selectedIds);
    }

    public updateSelectedActivityTypeIdsForActivities(selectedIds: Array<number>): void {
        this.scheduleService.updateActivityTypeIdsForActivities(selectedIds);
        const newSortedIds = this.scheduleQuery.updateSortedActivityTypeRootIdsBasedOnSelectedActivityTypeIds();
        this.scheduleService.updateSortedActivityTypeRootIdsForActivities(newSortedIds);
    }

    public updateSortedActivityTypeIdsForActivities(sortedIds: Array<number>): void {
        this.scheduleService.updateSortedActivityTypeRootIdsForActivities(sortedIds);
    }

    public updateApplyFilterStateForActivities(state: boolean): void {
        this.scheduleService.updateApplyFilterOnActivityScheduleState(state);
        if(state) {
            const newSortedIds = this.scheduleQuery.updateSortedActivityTypeRootIdsBasedOnSelectedActivityTypeIds();
            this.scheduleService.updateSortedActivityTypeRootIdsForActivities(newSortedIds);
        } else {
            this.scheduleService.updateSortedActivityTypeRootIdsForActivities([]);
        }
    }

    public updateApplyFilterState(state: boolean): void {
        this.scheduleService.updateApplyFilterOnResourceScheduleState(state);
    }

    public onClickDisplayOptions(): void {
        this.dialogService.open(ShowOptionsConfigurationDialogComponent, {
            data: {
                showOptions$: this.scheduleShowOptions$,
                activityShowOptions$: this.scheduleActivityShowOptions$
            } as DisplayOptionsData
        });
    }

    private initOrganizationUnitsFilter(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.organizationUnitService.getOrganizationUnitsForActivityTypes().pipe(first()).subscribe();

        this.preselectedOrganizationUnits$ = combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.scheduleQuery.getSelectedOrganizations()
        ]).pipe(
            map(([_, units]) => units.map(unit => unit.id))
        );

        this.organizationsForFiltering$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.organizationsForActivityTypes$ = this.organizationUnitQuery.getOrganizationUnitsForActivityTypes();

        this.subscription.add(
            this.scheduleQuery.getSelectedOrganizationUnitIds()
                .pipe(filter(ids => ids?.length > 0))
                .subscribe(() => {
                    const idsToRetrieveResourceTypes = this.scheduleQuery.getOrganizationIdsToRetrieveResourceTypesSync();
                    this.resourceTypeService.getReportResourceTypes(idsToRetrieveResourceTypes).pipe(first()).subscribe();
                })
        );
    }

    private initDayparts(): void {
        this.daypartOptions$ = this.daypartQuery.getDaypartsWithDisplayName();

        this.preselectedDayparts$ = combineLatest([
            this.daypartQuery.getDaypartsWithDisplayName(),
            this.scheduleQuery.getSelectedDayparts()
        ]).pipe(
            map(([_, dayparts]) => dayparts.map(daypart => daypart.id))
        );
    }

    private initActivityTypesFilter(): void {
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe(
            (activityTypes) => {
                this.activityTypeService.createActivityTypeAndSorting(activityTypes);
            }
        );

        this.activityTypes$ = combineLatest([
            this.scheduleQuery.getSelectedFilterSettingId(),
            this.activityTypeQuery.getMainActivityTypes()
        ]).pipe(
            map(([_, actTypes]) => actTypes)
        );

        this.applyFilterForResourcesState$ = this.scheduleQuery.getApplyFilterOnResourceScheduleState();
        
        this.selectedActivityTypeIds$ = this.scheduleQuery.getActivityTypeSelectedIds();
        const selectedActivityTypeIdsOnFilterChanged$ = this.scheduleQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.selectedActivityTypeIds$),
            map(([_, selectedActivityTypeIds]) => selectedActivityTypeIds)
        );

        merge(
            selectedActivityTypeIdsOnFilterChanged$,
            this.selectedActivityTypeIds$.pipe(filter(selectedActivityTypeIds => selectedActivityTypeIds.length > 0), first())
        ).subscribe(selectedActivityTypeIds => this.scheduleService.updateActivityTypeIds(selectedActivityTypeIds));

        this.buttonTitleResources$ = this.applyFilterForResourcesState$.pipe(
            map(applyFilter => {
              const filterStatus = applyFilter ? this.FILTER_ON : this.FILTER_OFF;

              return `${this.buttonTitleResourcesLabel}: ${filterStatus}`;
            })  
        );
    }

    private initActivityTypesFilterForActivities(): void {
        this.activityTypesForActivities$ = combineLatest([
            this.scheduleQuery.getSelectedFilterSettingId(),
            this.activityTypeQuery.getNestedActvityTypes()
        ]).pipe(
            map(([_, actTypes]) => actTypes)
        );

        this.activityTypesForSorting$ = combineLatest([
            this.scheduleQuery.getSelectedFilterSettingId(),
            this.activityTypeQuery.getAllActivityTypeRoots()
        ]).pipe(
            map(([_, actTypes]) => actTypes)
        );

        this.applyFilterForActivitiesState$ = this.scheduleQuery.getApplyFilterOnActivityScheduleState();

        this.selectedActivityTypeIdsForActivities$ = this.scheduleQuery.getActivityTypeSelectedIdsForActivities();
        this.sortedActivityTypeIdsForActivities$ = this.scheduleQuery.getSortedActivityTypeRootIdsForActivities();

        const selectedActivityTypeIdsOnFilterChangedForActivity$ = this.scheduleQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.selectedActivityTypeIdsForActivities$),
            map(([_, selectedActivityTypeIdsForActivities]) => selectedActivityTypeIdsForActivities)
        );

        const sortedActivityTypeIdsOnFilterChangedForActivity$ = this.scheduleQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.sortedActivityTypeIdsForActivities$),
            map(([_, sortedActivityTypeIdsForActivities]) => sortedActivityTypeIdsForActivities)
        );

        merge(
            selectedActivityTypeIdsOnFilterChangedForActivity$,
            this.selectedActivityTypeIdsForActivities$.pipe(first())
        ).subscribe(selectedActivityTypeIdsForActivities => this.scheduleService.updateActivityTypeIdsForActivities(selectedActivityTypeIdsForActivities));

        merge(
            sortedActivityTypeIdsOnFilterChangedForActivity$,
            this.sortedActivityTypeIdsForActivities$.pipe(first())
        ).subscribe(sortedActivityTypeIdsForActivities => this.scheduleService.updateSortedActivityTypeRootIdsForActivities(sortedActivityTypeIdsForActivities));

        this.buttonTitleActivities$ = combineLatest([
            this.applyFilterForActivitiesState$,
            this.selectedActivityTypeIdsForActivities$
        ]).pipe(
            map(([applyFilterForActivitiesState, selectedActivityTypeIdsForActivities]) => {
                if (applyFilterForActivitiesState) {
                    if (selectedActivityTypeIdsForActivities?.length > 0) {
                        return `${this.buttonTitleActivitiesLabel}: ${this.APPLY_FILTER_ACTIVE}`;
                    } else {
                        return `${this.buttonTitleActivitiesLabel}: ${this.APPLY_FILTER_NONE}`;
                    }
                } else {
                    return `${this.buttonTitleActivitiesLabel}: ${this.APPLY_FILTER_NONE}`;
                }
            }),
            startWith(`${this.buttonTitleActivitiesLabel}: ${this.APPLY_FILTER_NONE}`)
        );
    }

    private initActivityTypeCategoriesFilterForActivities(): void {
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();
 
        this.activityTypeCategories$ = this.activityTypeCategoryQuery.getActivityTypeCategories();
    }

    private initGroupingOptionFilter(): void {
        this.groupingOptionSelected$ = this.scheduleQuery.getGroupingOptionType();
    }

    private initDaterangeTypeFilter(): void {
        this.dateRangeTypeSelected$ = this.scheduleQuery.getDaterangeType();
    }

    private initBaseResourceTypesFilter(): void {
        this.selectedBaseResourceTypes$ = this.scheduleQuery.getSelectedBaseResourceTypes().pipe(
            filter((resourceTypes) => !!resourceTypes),
            cache()
        );

        this.forceErrorStateOnBaseResourceTypes$ = combineLatest([
            this.scheduleQuery.getSelectedFilterSettingId(),
            this.scheduleQuery.getScheduleFiltersLoadingState(),
            this.selectedOrganizationUnitIds$,
            this.selectedBaseResourceTypes$
        ]).pipe(
            map(([selectedFilterSettingId, filtersLoading, selectedUnitIds, selectedBaseResourceTypes]) => {
                return (!!selectedUnitIds || selectedFilterSettingId) && selectedBaseResourceTypes?.length === 0 && !filtersLoading;
            })
        );
    }

    private initExtraResourceTypesFilter(): void {
        this.selectedExtraResourceTypesIds$ = this.scheduleQuery.getSelectedExtraResourceTypesIds().pipe(cache());
    }

    private initFiltersBasedOnSelectedOrganization(parameters: ScheduleRequestParameters): void {
        this.organizationUnitService.filterVisibleOrganizationsByIds(parameters.organizationUnitIds);
        this.scheduleService.updateOrganizationUnitIdsParameter(parameters.organizationUnitIds);
        this.scheduleService.updateBaseResourceTypeIdsParameter(parameters.baseResourceTypeIds);
        this.scheduleService.updateExtraResourceTypeIdsParameter(parameters.extraResourceTypeIds);
    }

    private setupRemoveSelectedIfNotAvailable(): void {
        this.subscription.add(
            this.baseResourceTypesForFiltering$.pipe(
                withLatestFrom(this.selectedBaseResourceTypes$),
                filter(([_, selectedResourceTypes]) => selectedResourceTypes?.length > 0),
                tap(([availableResourceTypes, selectedResourceTypes]) => {
                    const availableSelectedResourceTypes = selectedResourceTypes.filter(rt => availableResourceTypes?.find(art => art.id === rt.id)).map(rt => rt.id);
                    if (availableSelectedResourceTypes.length !== selectedResourceTypes?.length) {
                        this.onFilteredBaseResourceTypesChanged(availableSelectedResourceTypes);
                    }
                })
            ).subscribe()
        );

        this.subscription.add(
            this.extraResourceTypesForFiltering$.pipe(
                withLatestFrom(this.selectedExtraResourceTypesIds$),
                filter(([_, selectedResourceTypes]) => selectedResourceTypes?.length > 0),
                tap(([availableResourceTypes, selectedResourceTypes]) => {
                    const availableSelectedResourceTypes = selectedResourceTypes.filter(rt => availableResourceTypes?.find(art => art.id === rt));
                    if (availableSelectedResourceTypes.length !== selectedResourceTypes?.length) {
                        this.onFilteredExtraResourceTypesChanged(availableSelectedResourceTypes);
                    }
                })
            ).subscribe()
        );
    }
}
