import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from 'src/app/shared/shared.module';
import { SocaWebUiCommonModule } from '@ortec/soca-web-ui';
import { SocaWebUiFormlyModule } from '@ortec/soca-web-ui/formly';
import { ActivityDemandComponent } from './activity-demand.component';
import { ActivityDemandRoutingModule } from './activity-demand-routing';
import { ManageCountersComponent } from './manage-counters/manage-counters.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { AdtActivityTypeTableComponent } from './edit-template/components/adt-activity-type-table/adt-activity-type-table.component';
import { AdtActivityTypeTableMenuComponent } from './edit-template/components/adt-activity-type-table-menu/adt-activity-type-table-menu.component';
import { AdtCounterTableComponent } from './edit-template/components/adt-counter-table/adt-counter-table.component';
import { AdtCounterTableMenuComponent } from './edit-template/components/adt-counter-table-menu/adt-counter-table-menu.component';
import { ManageTemplatesComponent } from './manage-templates/manage-templates.component';
import { LoadingStatusIndicationComponent } from 'src/app/shared/components/loading-status-indication/loading-status-indication.component';

@NgModule({
    declarations: [
        ActivityDemandComponent,
        ManageCountersComponent,
        EditTemplateComponent,
        AdtActivityTypeTableComponent,
        AdtCounterTableComponent,
        AdtActivityTypeTableMenuComponent,
        AdtCounterTableMenuComponent,
        ManageTemplatesComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        ActivityDemandRoutingModule,
        SharedModule,
        SocaWebUiCommonModule,
        SocaWebUiFormlyModule,
        LoadingStatusIndicationComponent
    ]
})
export class ActivityDemandModule { }
