import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DisplaySetting } from './display-setting.model';
import { DisplaySettingState, DisplaySettingStore } from './display-setting.store';

@Injectable({
    providedIn: 'root'
})
export class DisplaySettingQuery extends QueryEntity<DisplaySettingState> {
    constructor(
        protected store: DisplaySettingStore,
    ) {
        super(store);
    }

    public getDisplaySettingsSync(): Array<DisplaySetting> {
        return Object.values(this.getValue().entities);
    }

    public getValueBySettingName<T>(settingName: string, type: 'number' | 'boolean' | 'string' | 'array'): Observable<T> {
        return this.getDisplaySettings().pipe(
            map(settings => {
                const setting = settings.find(s => s.settingName === settingName);

                if (setting === undefined) {
                    return undefined;
                }

                if (type === 'boolean') {
                    return setting.settingValue === '1';
                }

                if (type === 'number') {
                    return Number(setting.settingValue);
                }

                if (type === 'array') {
                    if (setting.settingValue) {
                      const arrayValues = setting.settingValue.split(',');
                      return arrayValues.map(value => Number(value));
                    } else {
                      return [];
                    }
                }

                return setting.settingValue;
            })
        );
    }

    public getDisplaySettings(): Observable<Array<DisplaySetting>> {
        return this.selectAll();
    }
}
