import { Injectable } from '@angular/core';

import { Activity } from 'src/app/features/overviews/schedule/stores/schedule-activity-store/schedule-activity.model';
import { ExtendedScheduleActivity } from 'src/app/features/overviews/schedule/stores/schedule-store/schedule.model';

@Injectable({
    providedIn: 'root'
})

export class ActivityHelperService {

    public sortActivities<T extends ExtendedScheduleActivity | Activity>(activities: Array<T>): Array<T> {
        const entityMap = new Map();
        const sortedActivities: Array<T> = [];

        activities.forEach(entity => {
            const parentId = entity.parentId;
            if (!entityMap.has(parentId)) {
                entityMap.set(parentId, []);
            }
            entityMap.get(parentId).push(entity);
        });

        const sortAndAppend = (parentId): void => {
            const entities = entityMap.get(parentId);
            if (entities) {
                entities.sort((a, b) => a.activityType.sortOrder - b.activityType.sortOrder)
                        .forEach(entity => {
                            sortedActivities.push(entity);
                            sortAndAppend(entity.id);
                        });
            }
        };

        sortAndAppend(null);

        return sortedActivities;
    }
}
