import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { ConfigurationState, ConfigurationStore } from './configuration.store';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationQuery extends Query<ConfigurationState> {
    constructor(
        protected store: ConfigurationStore
    ) {
        super(store);
    }

    public getSSRSReportsConfigurationEnabledSync(): boolean {
        return this.getValue().configuration?.featureManagement?.ssrsReports;
    }

    public getFeatureUseActivityRequestHandlingSync(): boolean {
        return this.getValue().configuration?.featureManagement?.featureUseActivityRequestHandling;
    }

    public getFeaturePlanboardAPlanningBoard(): Observable<boolean> {
        return this.select(state => state?.configuration?.featureManagement?.featurePlanboardAPlanningBoard);
    }

    public getFeatureOWSInterfaceReplacedShiftsSync(): boolean {
        return this.getValue().configuration?.featureManagement?.featureOWSInterfaceReplacedShifts;
    }
}
