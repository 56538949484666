import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { StatusPageState, StatusPageStore, initialStatusPageState } from 'src/app/shared/stores/status-page-store/status-page.store';

export interface OrganizationUnitsConfigurations {
    organizationUnitId: number;
    activityTypeId: number;
}

export interface OrganizationUnitConfigurationsState extends StatusPageState {
    selectedOrganizationUnitId: number;
    selectedActivityTypeId: number;
    loadingState: boolean;
}

const initialState = {
    selectedOrganizationUnitId: undefined,
    selectedActivityTypeId: undefined,
    loadingState: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-units-configurations' })
export class OrganizationUnitsConfigurationsStore extends StatusPageStore<OrganizationUnitConfigurationsState> {
    constructor() {
        super({...initialState,  ...initialStatusPageState});
    }

    public updateSelectedOrganizationUnitId(selectedOrganizationUnitId: number): void {
        this.update(state => ({ ...state, selectedOrganizationUnitId }));
    }

    public updateSelectedActivityTypeId(selectedActivityTypeId: number): void {
        this.update(state => ({ ...state, selectedActivityTypeId}));
    }

    public updateLoadingState(loadingState: boolean): void {
        this.update(state => ({ ...state, loadingState}));
    }
}
