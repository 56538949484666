import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ActivityTypeCategory } from './activity-type-category.model';
import { ActivityTypeCategoryState, ActivityTypeCategoryStore } from './activity-type-category.store';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeCategoryQuery extends QueryEntity<ActivityTypeCategoryState> {

    constructor(
        protected store: ActivityTypeCategoryStore,
        private readonly translateService: TranslateService,
    ) {
        super(store);
    }

    public getActivityTypeCategoriesSync(): Array<ActivityTypeCategory> {
        const activityTypeCategories = Object.values(this.getValue().entities);

        return activityTypeCategories.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getActivityTypeCategories(): Observable<Array<ActivityTypeCategory>> {
        return this.selectAll().pipe(map(categories =>
            categories.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getActivityTypeCategoriesForFiltering(): Observable<Array<ActivityTypeCategory>> {
        return this.selectAll().pipe(map((categories => 
            categories.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
            .map(category => {
                const displayName = this.translateService.instant(category.displayName);

                return {
                    ...category, 
                    displayName
                };
            })))
        );
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }
}