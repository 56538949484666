import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { ACTIVITY_CATEGORY_TYPE_ID } from 'src/app/shared/models/Enums';
import { ActivityTypeMapping } from './activity-type-mapping.model';

export interface ActivityTypeMappingState extends EntityState<ActivityTypeMapping> {
    ui: {
        selectedActivityTypeId: number;
        selectedOrganizationUnitId: number;
        showUnderlyingUnits: boolean;
        showPastActivityTypes: boolean;
        showFutureActivityTypes: boolean;
        showPastDuties: boolean;
        showFutureDuties: boolean;
        hideUnlinkedOwsDuties: boolean;
        selectedDutySet: string;
        selectedActivityTypeCategory: number;
        selectedDepartmentId: number;
    };
}

const initialState = {
    ui: {
        selectedActivityTypeId: undefined,
        selectedOrganizationUnitId: undefined,
        showUnderlyingUnits: false,
        showPastActivityTypes: false,
        showFutureActivityTypes: false,
        showPastDuties: false,
        showFutureDuties: false,
        hideUnlinkedOwsDuties: false,
        selectedDutySet: undefined,
        selectedActivityTypeCategory: ACTIVITY_CATEGORY_TYPE_ID.ACTIVITY,
        selectedDepartmentId: undefined
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-type-mapping', idKey: 'owsDutyId' })
export class ActivityTypeMappingStore extends EntityStore<ActivityTypeMappingState> {

    constructor() {
        super(initialState);
    }

    public updateSelectedActivityTypeId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedActivityTypeId: id };

            return { ...state, ui };
        });
    }

    public updateSelectedDepartmentId( selectedDepartmentId: number): void {
        this.update(state => {
            const ui = { 
                ...state.ui, 
                selectedDepartmentId,
                selectedDutySet: undefined
            };

            return { ...state, ui };
        });
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOrganizationUnitId: id };
            
            return { ...state, ui };
        });
    }

    public updateShowChildUnits(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showUnderlyingUnits: value };
            
            return { ...state, ui };
        });
    }

    public updateShowPastActivityTypes(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showPastActivityTypes: value };
            
            return { ...state, ui };
        });
    }

    public updateShowFutureActivityTypes(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showFutureActivityTypes: value };
            
            return { ...state, ui };
        });
    }

    public updateShowPastDuties(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showPastDuties: value };
            
            return { ...state, ui };
        });
    }

    public updateShowFutureDuties(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showFutureDuties: value };
            
            return { ...state, ui };
        });
    }

    public toggleUnlinkedOwsDutyVisibility(): void {
        this.update(state => {
            const oldState = state.ui.hideUnlinkedOwsDuties;

            const ui = {
                ...state.ui,
                hideUnlinkedOwsDuties: !oldState
            };

            return { ...state, ui };
        });
    }

    public updateSelectedDutySet(id: string): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedDutySet: id
            };

            return { ...state, ui };
        });
    }

    public updateSelectedActivityTypeCategory(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedActivityTypeCategory: id };

            return { ...state, ui };
        });
    }
}
