import { Injectable } from '@angular/core';
import { EntityState, StoreConfig } from '@datorama/akita';

import { StatusPageState, StatusPageStore, initialStatusPageState } from 'src/app/shared/stores/status-page-store/status-page.store';

import { ResourcesToHide } from './filtering.model';

export interface FilteringState extends EntityState, StatusPageState {
    ui: {
        selectedOrganizationUnitIds: Array<number>;
        selectedResourceSkillIds: Array<number>;
        entitiesLoading: boolean;
        entitiesLoadingSelectedHideResources: boolean;
        entitiesLoadingOrganizationUnitSelection: boolean;
        entitiesLoadingSelectedActivityTypes: boolean;
        entitiesLoadingResourcePropertySelection: boolean;
        entitiesLoadingSelectedResourceTypeId: boolean;
        selectedActivityTypeIds: Array<number>;
        selectedResourcesToHide: Array<ResourcesToHide>;
        selectedVisibleColumns: Array<number>;
        selectedResourceTypeId: number;
    };
}

const initialState = {
    ui: {
        selectedOrganizationUnitIds: [],
        selectedResourceSkillIds: [],
        entitiesLoading: false,
        entitiesLoadingSelectedHideResources: false,
        entitiesLoadingOrganizationUnitSelection: false,
        entitiesLoadingSelectedActivityTypes: false,
        entitiesLoadingResourcePropertySelection: false,
        entitiesLoadingSelectedResourceTypeId: false,
        selectedActivityTypeIds: [],
        selectedResourcesToHide: [],
        selectedVisibleColumns: [],
        selectedResourceTypeId: undefined,
    },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filtering' })
export class FilteringStore extends StatusPageStore<FilteringState> {
    constructor() {
        super({...initialState, ...initialStatusPageState});
    }

    public updateSelectedOrganizationUnitIds(selectedOrganizationUnitIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedOrganizationUnitIds}
        }));
    }
    
    public updateSelectedResourceSkillIds(selectedResourceSkillIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedResourceSkillIds}
        }));
    }
    
    public updateEntitiesLoading(entitiesLoading: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoading}
        }));
    }

    public updateEntitiesLoadingSelectedResourceTypeId(entitiesLoadingSelectedResourceTypeId: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoadingSelectedResourceTypeId}
        }));
    }

    public updateEntitiesLoadingOrganizationUnitSelection(entitiesLoadingOrganizationUnitSelection: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoadingOrganizationUnitSelection}
        }));
    }

    public updateEntitiesLoadingSelectedHideResources(entitiesLoadingSelectedHideResources: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoadingSelectedHideResources}
        }));
    }
    
    public updateEntitiesLoadingSelectedActivityTypes(entitiesLoadingSelectedActivityTypes: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoadingSelectedActivityTypes}
        }));
    }

    public updateEntitiesLoadingResourcePropertySelection(entitiesLoadingResourcePropertySelection: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoadingResourcePropertySelection}
        }));
    }

    public updateSelectedActivityTypeIds(selectedActivityTypeIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedActivityTypeIds}
        }));
    }
    
    public updateSelectedResourcesToHide(selectedResourcesToHide: Array<ResourcesToHide>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedResourcesToHide}
        }));
    }

    public updateSelectedVisibleColumns(selectedVisibleColumns: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedVisibleColumns}
        }));
    }

    public updateSelectedResourceTypeId(selectedResourceTypeId: number): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedResourceTypeId}
        }));
    }
}
