<ng-container *ngIf="(isLoading$ | async) === false">
    <div class="bol-modal__header">
        <span class="modal-title">{{ 'Activity detail' | translate }}</span>
        <div class="bol-modal__close" (click)="onCloseModal()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <mat-dialog-content class="dialog">
        <div class="activity-description" *ngIf="showDescription">
            <span class="activity-description-text">{{ 'Description:' | translate }} </span>{{ description }}
        </div>
        <div *ngIf="allActivities.length > 0">
            <div class="activity-row activity-header">
                <div class="activity-time" translate>Begin</div>
                <div class="activity-time" translate>End</div>
                <div class="activity-short-name" translate>Activity</div>
                <div class="activity-detail-name" translate>Display name</div>
                <div class="activity-detail-name" translate>Resource</div>
                <div class="activity-detail-name" translate>Organization unit</div>
            </div>
            <div class="activity"*ngFor="let activity of allActivities; let i = index" [class.activity-root-line]="i === 0 && allActivities.length > 1">
                <div class="activity-row">
                    <div class="activity-time">
                        {{ activity?.start }}
                    </div>
                    <div class="activity-time">
                        {{ activity?.end }}
                    </div>
                    <div class="activity-short-name">
                        <div class="activity-short-name-cell"
                            [style.color]="'#' + activity?.activityType?.textColor"
                            [style.background-color]="'#' + activity?.activityType?.backColor">
                            {{ activity?.activityType?.shortName }}
                        </div>
                    </div>
                    <div class="activity-detail-name">{{ activity?.activityType?.displayName }}</div>
                    <div class="activity-detail-name">{{ activity?.resource }}</div>
                    <div class="activity-detail-name">{{ activity?.organizationUnit ?? '-' }}</div>
                </div>
                <div class="activity-memo" *ngIf="activity.memo">
                    <div class="memo">
                        <span class="activity-detail-name-memo-title">{{ 'Memo:' | translate }}</span> {{ activity.memo }}
                    </div>
                  </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>

<div class="spinner-container" *ngIf="(isLoading$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>