import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { UserInfo } from './user-info.model';

export interface UserInfoState {
    displayName: string;
    isFullUser: boolean;
    resourceId: number | null;
    userId: number | null;
    languageCode: string;
}

const initialState = {
    displayName: '',
    isFullUser: false,
    resourceId: null,
    userId: null,
    languageCode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-info' })
export class UserInfoStore extends Store<UserInfoState> {
    constructor() {
        super(initialState);
    }

    public setCurrentUser(currentUser: UserInfo): void {
        this.update({ displayName: currentUser.displayName });
        this.update({ isFullUser: currentUser.id > 0 });
        this.update({ resourceId: currentUser.resourceId });
        this.update({ userId: currentUser.id });
    }

    public setUserLanguageCode(language: string): void {
        this.update({ languageCode: language });
    }
}
