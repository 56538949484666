import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first, map, startWith, tap } from 'rxjs/operators';
import { SelectionCheckBoxClickEvent, filterUndefined } from '@ortec/soca-web-ui';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Memoized } from '@ortec/utilities/core';
import { combineSubscriptions } from '@ortec/utilities/rxjs';

import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { ActivityTypeSelection } from 'src/app/shared/components/activity-type-selection-new/components/activity-type-dialog.component';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityTypeCategoryService } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

import { FilteringPaletteQuery } from './store/filtering-palette.query';
import { FilteringPaletteService } from './store/filtering-palette.service';

@Component({
    selector: 'app-filtering-palette',
    templateUrl: './filtering-palette.component.html',
    styleUrls: ['./filtering-palette.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteringPaletteComponent implements OnInit, OnDestroy {
    public activityTypes: Array<ActivityTypeSelection> = [];
    public filteredActivityTypes: Array<ActivityTypeSelection> = [];
    public selectedOrganizationIds: Array<number> = [];
    public selectedActivityTypeCategoryIds: Array<number> = [];
    public filteredActivityTypesSubject = new BehaviorSubject<Array<ActivityTypeSelection>>([]);
    public selectActivityTypeEventSubject = new BehaviorSubject<boolean>(false);
    public disableFilterFields: boolean = false;
    public selectedActivityTypeIdsSet = new Set<number>();
    public selectedActivityTypeIds: Array<number>;

    public columnDefinition = [{
        columnDisplayName: 'Short name',
        entityProperty: 'shortName',
        stylingProperty: 'nameStyling'
    },
    {
        columnDisplayName: 'Display name',
        entityProperty: 'displayName',
    }];
    public ACTIVITY_SELECTED_MESSAGE = 'activity types selected';
    public searchProperties: Array<string> = ['displayName', 'shortName'];

    private readonly subscriptions: Subscription = new Subscription();
    
    constructor(
        private readonly autosaveService: AutosaveService,
        private readonly displaySettingQuery: DisplaySettingQuery,
        private readonly displaySettingService: DisplaySettingService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly activityTypeCategoryService: ActivityTypeCategoryService,
        private readonly filteringPaletteQuery: FilteringPaletteQuery,
        private readonly filteringPaletteService: FilteringPaletteService
    ){}

    public ngOnInit(): void {
        this.filteringPaletteService.getSelectedActivityTypes().pipe(first()).subscribe();
        this.subscriptions.add(
            combineSubscriptions(
                this.getSelectedActivityTypeIds(),
                this.initializeActivityTypes(),
                this.setFilteredActivityTypes()
            )
        );
       
        this.setInitialStatesFromDisplaySettings();
        this.activityTypeService.getMainActivityTypes().pipe(first()).subscribe();
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();
        this.organizationUnitService.getOrganizationUnitsForActivityTypes().pipe(first()).subscribe();
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscriptions.unsubscribe();
    }

    // Updates the checked property of activity types based on selected activity type ids
    public updateActivityTypeStates(event: SelectionCheckBoxClickEvent): void {
        const activityTypeIds = event.entityIds;
        const showOnlySelectedValue = this.filteringPaletteQuery.getShowOnlySelectedStateSync();

        if (showOnlySelectedValue) {
            const updatedActivityTypes = this.updateActivityTypeChecked(this.filteredActivityTypes, activityTypeIds);
            this.filteredActivityTypes = this.updateAndFilterActivityTypes(updatedActivityTypes, this.selectedActivityTypeIds, true);
            this.updateFilteredActivityTypes();
        } else {
            this.filteredActivityTypes = this.updateActivityTypeChecked(this.filteredActivityTypes, activityTypeIds);
        }
        
        this.filteringPaletteService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
        this.autosaveService.autoSave(this.selectedActivityTypeIds, this.updateSelectedActivityTypes.bind(this));
    }

    public updateActivityTypeTableBasedOnOrganizationUnits(orgUnitsIds: Array<number>): void {
        this.autosaveService.saveUnsavedChanges();
        this.selectedOrganizationIds = orgUnitsIds;
        this.filteringPaletteService.updateSelectedOrganizationUnitIds(orgUnitsIds);
    }

    public updateActivityTypeTableBasedOnActivityTypeCategories(actTypeCategoryIds: Array<number>): void {
        this.autosaveService.saveUnsavedChanges();
        this.selectedActivityTypeCategoryIds = actTypeCategoryIds;
        this.filteringPaletteService.updateSelectedActivityTypeCategoryIds(actTypeCategoryIds);
    }

    public onApplyFilter(event: MatCheckboxChange): void {
        this.filteringPaletteService.updateApplyFilterState(event.checked);
        this.filteringPaletteService.updatePageStatusState(STATUS.IS_LOADING);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.APPLY_ACTIVITY_TYPE_FILTER, event.checked).pipe(
            first(),
            tap(() => this.filteringPaletteService.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        ).subscribe();
    }

    // Updates the state to show only selected activity types and clears selected filters if checked
    public showOnlyActivityTypesSelected(event: MatCheckboxChange): void {
        this.disableFilterFields = event.checked;
        if (this.disableFilterFields) {
            this.selectedOrganizationIds = [];
            this.selectedActivityTypeCategoryIds = [];
            this.filteringPaletteService.updateSelectedOrganizationUnitIds([]);
            this.filteringPaletteService.updateSelectedActivityTypeCategoryIds([]);
        }
        this.filteringPaletteService.updateShowOnlySelectedState(event.checked);
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
            this.filteringPaletteQuery.getEntitiesLoading(),
            this.activityTypeCategoryQuery.getEntitiesLoadingState(),
            this.activityTypeQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([loading, actTypeCatLoading, actTypeLoading, orgUnitLoading]) => {
                return !loading && !actTypeCatLoading && !actTypeLoading && !orgUnitLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    @Memoized public get organizationUnits$(): Observable<Array<IOrganizationUnitTree>> {
        return this.organizationUnitQuery.getOrganizationUnitsForActivityTypes();
    }

    @Memoized public get filteredActivityTypes$(): Observable<Array<ActivityTypeSelection>> {
        return this.filteredActivityTypesSubject.asObservable();
    }

    @Memoized public get activityTypeCategories$(): Observable<Array<ActivityTypeCategory>> {
        return this.activityTypeCategoryQuery.getActivityTypeCategoriesForFiltering();
    }

    @Memoized public get applyFilterState$(): Observable<boolean> {
        return this.filteringPaletteQuery.getApplyFilterState();
    }

    @Memoized public get showOnlySelectedState$(): Observable<boolean> {
        return this.filteringPaletteQuery.getShowOnlySelectedState();
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return this.filteringPaletteQuery.getStatusPageState();
    }

    private updateSelectedActivityTypes(selectedActivityTypes: Array<number>): void {
        this.filteringPaletteService.updateSelectedActivityTypes(selectedActivityTypes).pipe(first()).subscribe();
    }

    // Updates the checked property of activity types based on selected activity type ids
    private updateActivityTypeChecked(activityTypes: Array<ActivityTypeSelection>, activityTypeIds: Array<number | string>): Array<ActivityTypeSelection> {
        const updatedActivityTypes = activityTypes.map(activityType => {
            const activitySelected = activityTypeIds?.includes(activityType.id);
            if (activitySelected) {
                this.selectedActivityTypeIdsSet.add(activityType.id);
            } else {
                this.selectedActivityTypeIdsSet.delete(activityType.id);
            }

            return {
                ...activityType,
                checked: activitySelected,
            };
        });
    
        this.selectedActivityTypeIds = Array.from(this.selectedActivityTypeIdsSet);
    
        return updatedActivityTypes;
    }

    private getSelectedActivityTypeIds(): Subscription {
        return this.filteringPaletteQuery.getSelectedActivityTypeIds().pipe(filter(ids => !!ids),).subscribe(
            (selectedActivityTypeIds) => {
                this.selectedActivityTypeIds = selectedActivityTypeIds;
                this.selectedActivityTypeIds.forEach(id => this.selectedActivityTypeIdsSet.add(id));
            }
        )
    }
    private setFilteredActivityTypes(): Subscription {
        return combineLatest([
            this.filteringPaletteQuery.getSelectedActivityTypeCategoryIds(),
            this.filteringPaletteQuery.getSelectedOrganizationUnitIds(),
            this.filteringPaletteQuery.getShowOnlySelectedState(),
            this.activityTypeQuery.getActivityTypes(),
            this.filteringPaletteQuery.getSelectedActivityTypeIds()
        ]).pipe(
            filter(([_,__, showOnlyActivityTypesSelected, actTypes, selectedActivityTypeIds]) => showOnlyActivityTypesSelected !== undefined && actTypes.length > 0 && selectedActivityTypeIds !== undefined),
            tap(([selectedActivityCategoryIds, selectedOrganizationUnits, showOnlyActivityTypesSelected]) => {
                if (showOnlyActivityTypesSelected) {
                    this.filteredActivityTypes = this.updateAndFilterActivityTypes(this.activityTypes, this.selectedActivityTypeIds, true);
                    this.updateFilteredActivityTypes();

                    return;
                }

                const filteredByOrgUnits = this.filterActivityTypesByOrganizationUnits(selectedOrganizationUnits);

                this.filteredActivityTypes = selectedActivityCategoryIds.length > 0
                    ? this.filterActivityTypesByActivityTypeCategories(filteredByOrgUnits, selectedActivityCategoryIds)
                    : filteredByOrgUnits;
        
                this.updateFilteredActivityTypes();
            })
        ).subscribe();
    }

    private filterActivityTypesByOrganizationUnits(selectedOrganizationUnits: Array<number>): Array<ActivityTypeSelection> {
        const updatedActivityTypes = this.updateAndFilterActivityTypes(this.activityTypes, this.selectedActivityTypeIds);
    
        if (selectedOrganizationUnits && selectedOrganizationUnits.length > 0) {
            return this.getFilteredActivityTypesByOrganizationUnits(updatedActivityTypes, selectedOrganizationUnits);
        } else {
            return updatedActivityTypes;
        }
    }

    private getFilteredActivityTypesByOrganizationUnits(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeOrganizationUnitsIds: Array<number>): Array<ActivityTypeSelection> {
        if (!selectedActivityTypeOrganizationUnitsIds || selectedActivityTypeOrganizationUnitsIds.length === 0) {
            return activityTypes;
        }
    
        return activityTypes.filter(activityType =>
            selectedActivityTypeOrganizationUnitsIds.includes(activityType.ownerOrganizationUnitId)
        );
    }

    private updateFilteredActivityTypes(): void {
        this.filteredActivityTypesSubject.next(this.filteredActivityTypes);
    }
      
    private filterActivityTypesByActivityTypeCategories(activityTypes: Array<ActivityTypeSelection>, selectedCategoryIds: Array<number>): Array<ActivityTypeSelection> {
        return activityTypes.filter((activityType) =>
            selectedCategoryIds.includes(activityType.categoryId)
        );
    }

    private initializeActivityTypes(): Subscription {
        return this.activityTypeQuery.getMainActiveActivityTypes().pipe(
            filter(activityTypes => activityTypes.length > 0),
            map(activityTypes => {
                this.activityTypes = activityTypes.map(activityType => ({
                    ...activityType,
                    checked: false,
                    nameStyling: {
                        backgroundColor: '#' + activityType.backColor,
                        color: '#' + activityType.textColor
                    }
                })) as any;
            })
        ).subscribe();
    }

    // Updates the checked property of activity types based on selected activity type ids and optionally filters the activity types to show only the selected ones
    private updateAndFilterActivityTypes(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeIds: Array<number>, showOnlySelected: boolean = false): Array<ActivityTypeSelection> {
        if (!showOnlySelected) {
            return activityTypes.map(activityType => ({
                ...activityType,
                checked: selectedActivityTypeIds.includes(activityType.id),
            }));
        } else {
            return activityTypes.filter(activityType => selectedActivityTypeIds.includes(activityType.id)).map(activityType => ({
                ...activityType,
                checked: true,
            }));
        }
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.APPLY_ACTIVITY_TYPE_FILTER, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => {this.filteringPaletteService.updateApplyFilterState(state);})
        ).subscribe();
    }
}