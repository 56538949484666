import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { StatusPageService } from 'src/app/shared/stores/status-page-store/status-page.service';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';
import { OtherSettingsState, OwsOtherSettingsStore } from './ows-other-settings.store';

@Injectable({
    providedIn: 'root'
})
export class OwsOtherSettingsService extends StatusPageService<OtherSettingsState>{
    constructor(
        protected otherSettingsStore: OwsOtherSettingsStore,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
    ) { 
        super(otherSettingsStore);
    }

    public getActivityTypeForShiftReplacedByLeave(): Observable<void> {
        this.otherSettingsStore.updateLoadingStateForActivityTypeForShiftReplacedByLeave(true);

        return this.http.get<number>('/api/OwsInterface/ActivityTypeForShiftReplacedByLeave').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
    
                return throwError(() => error);
            }),
            map((activityTypeId) => {
                this.otherSettingsStore.updateSelectedDaymarkIdForLeaveShift(activityTypeId);
                this.otherSettingsStore.updateLoadingStateForActivityTypeForShiftReplacedByLeave(false);
            })
        );
    }

    public getActivityTypeForShiftReplacedBySick(): Observable<void> {
        this.otherSettingsStore.updateLoadingStateForActivityTypeForShiftReplacedBySick(true);

        return this.http.get<number>('/api/OwsInterface/ActivityTypeForShiftReplacedBySick').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
    
                return throwError(() => error);
            }),
            map((activityTypeId) => {
                this.otherSettingsStore.updateSelectedDaymarkIdForSickShift(activityTypeId);
                this.otherSettingsStore.updateLoadingStateForActivityTypeForShiftReplacedBySick(false);
            })
        );
    }

    public updateActivityTypeForShiftReplacedByLeave(activityTypeId: number): Observable<any> {
        this.otherSettingsStore.updatePageStatusState(STATUS.IS_LOADING);

        return this.http.post('/api/OwsInterface/ActivityTypeForShiftReplacedByLeave', activityTypeId).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
    
                return throwError(() => error);
            }),
            tap(() => this.otherSettingsStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public updateActivityTypeForShiftReplacedBySick(activityTypeId: number): Observable<any> {
        this.otherSettingsStore.updatePageStatusState(STATUS.IS_LOADING);
        
        return this.http.post('/api/OwsInterface/ActivityTypeForShiftReplacedBySick', activityTypeId).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
    
                return throwError(() => error);
            }),
            tap(() => this.otherSettingsStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public updateSelectedDaymarkIdForLeaveShift(id: number): void {
        this.otherSettingsStore.updateSelectedDaymarkIdForLeaveShift(id);
    }

    public updateSelectedDaymarkIdForSickShift(id: number): void {
        this.otherSettingsStore.updateSelectedDaymarkIdForSickShift(id);
    }
}