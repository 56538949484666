<div class="manage-counters-panel"> 
    <mat-card class="bol-card">
        <h3 class="manage-counters-title" translate data-cy="manageCountersTitle">Manage counters</h3>
        <div class="content" *ngIf="(initialLoadingFinished$ | async) === true && (initialLoadingStateForGrid$ | async) === false">
            <div class="add-button">
                <button 
                    (click)="onAddEntityClicked()"
                    mat-button 
                    class="bol-button-solid bol-default"
                    data-cy="addEntityButton">
                    <span translate>Add</span>
                </button>
            </div>
            <app-entity-manage-grid #entityManageGrid
                [entities]="entities$ | async"
                [columns]="columns"
                [newEntityModel]="newEntityModel"
                [entityName]="'Counter'"
                [formFields]="formFields"
                [requestFinished]="requestFinished$ | async"
                [rowMenuOptions]="rowMenuOptions"
                [emptyStateMessage]="'No counters'"
                (updateEntity)="onEditCounter($event)"
                (deleteEntity)="onDeleteCounter($event)"
                (addEntity)="onAddNewCounter($event)">
            </app-entity-manage-grid>
        </div>
    </mat-card>
</div>

<div class="overlay-spinner" *ngIf="(initialLoadingFinished$ | async) === false || (initialLoadingStateForGrid$ | async) === true">
    <mat-spinner diameter="100"></mat-spinner>
</div>