import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { RequestService } from '../../../../../shared/services/request.service';
import { GlobalSettingsQuery } from '../../../../../shared/stores/global-settings/global-settings.query';
import { ResourceTypeQuery } from '../../../../../shared/stores/resource-type-store/resource-type.query';
import { COUNTERS_COUNTER_TYPE, COUNTERS_GROUPING_OPTIONS, COUNTERS_TOTALS_OPTIONS } from '../../counters-helpers/enums';
import { ActivitiesDto, convertFromActivitiesDto } from '../shared/dto-converter';
import { Counter } from './counters.model';
import { CountersQuery } from './counters.query';
import { CountersState, CountersStore } from './counters.store';

@Injectable({
    providedIn: 'root'
})
export class CountersService {
    private readonly maxDaysInCall = 14;

    constructor(
        protected countersStore: CountersStore,
        protected countersQuery: CountersQuery,
        protected resourceTypeQuery: ResourceTypeQuery,
        protected requestService: RequestService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
        private readonly translateService: TranslateService,
    ) { }

    public getActivitiesForCounters(): Observable<Array<Counter>> {
        const params = this.countersQuery.getRequestParametersForScheduleSync();
        const startDate = this.countersQuery.getSelectedStartDateSync();
        const endDate = this.countersQuery.getSelectedEndDateSync();
        const requestList: Array<Observable<Array<Counter>>> = [];

        const paramList = this.requestService.splitUpParamsBasedOnDates(
            params,
            startDate,
            endDate,
            'start',
            'end',
            this.maxDaysInCall
        );

        paramList.forEach(param => requestList.push(this.getRequestForParams(param)));

        return this.requestService.loadSequential(requestList).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.LOADING-LARGE-DATA-ERROR');
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(error);
            }),
            map(counterResponses => {
                let activities: Array<Counter> = [];
                counterResponses.forEach(counters => activities = activities.concat(counters));
                this.countersStore.set(activities);

                return activities;
            })
        );
    }

    public emptyStore(): void {
        this.countersStore.set([]);
    }

    public updateTotalsOptions(totals: Array<COUNTERS_TOTALS_OPTIONS>): void {
        this.countersStore.updateTotalsOptions(totals);
    }

    public updateGroupingOptions(groupingOptions: Array<COUNTERS_GROUPING_OPTIONS>): void {
        this.countersStore.updateGroupingOptions(groupingOptions);
    }

    public updateStartDate(date: string): void {
        this.countersStore.updateStartDate(date);
    }

    public updateEndDate(date: string): void {
        this.countersStore.updateEndDate(date);
    }

    public updateOrganizationUnitForActivityIds(ids: Array<number>): void {
        this.countersStore.updateOrganizationUnitForActivityIds(ids);
    }

    public updateResourceOrganizationUnitIds(ids: Array<number>): void {
        this.countersStore.updateResourceOrganizationUnitIds(ids);
    }

    public updateResourceTypeIds(ids: Array<number>): void {
        this.countersStore.updateResourceTypeIds(ids);
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.countersStore.updateActivityTypeIds(ids);
    }

    public updateSkillIds(ids: Array<number>): void {
        this.countersStore.updateSkillIds(ids);
    }

    public updateActivityTypeDetailsState(state: boolean): void {
        if (!state) {
            let selected = this.countersQuery.getSelectedGroupingOptionsSync();
            if (selected.includes(COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE)) {
                selected = selected.filter(s => s !== COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE);
                this.updateGroupingOptions(selected);
            }
        }
        this.countersStore.updateActivityTypeDetailsState(state);
    }

    public updateCounterTypeOptions(counterTypes: Array<COUNTERS_COUNTER_TYPE>): void {
        this.countersStore.updateCounterTypeOptions(counterTypes);

        if (counterTypes.every(counter => counter !== COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO &&
            counter !== COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO)) {
            this.updateScenarioId(undefined);
        }
    }

    public updateScenarioId(scenarioId: number): void {
        this.countersStore.updateScenarioId(scenarioId);
    }

    public updateActivityTypeCategoryIds(ids: Array<number>): void {
        this.countersStore.updateActivityTypeCategoryIds(ids);
    }

    public updateAllActivityTypeIdsSelectedState(value: boolean): void {
        this.countersStore.updateAllActivityTypeIdsSelectedState(value);
    }

    public updateCountersStateOnFilterSettingChanged(state: CountersState): void {
        this.countersStore.updateCountersState(state);
    }

    public updateAllOrganizationUnitsSelectedStateChanged(state: boolean): void {
        this.countersStore.updateAllOrganizationUnitsSelectedState(state);
    }

    public updateAllResourceOrganizationUnitsSelectedState(state: boolean): void {
        this.countersStore.updateAllResourceOrganizationUnitsSelectedState(state);
    }

    public updateValidIntervalDates(state: boolean): void {
        this.countersStore.updateValidIntervalDates(state);
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.countersStore.updateSelectedFilterSettingId(id);
    }

    public resetState(): void {
        this.countersStore.resetState();
    }

    public updateErrorState(state: boolean): void {
        this.countersStore.updateErrorState(state);
    }

    public updateSliderWidthValues(nameColumnWidth: number, dataColumnWidth: number, totalTableWidth: number): void {
        this.countersStore.updateSliderWidthValues(nameColumnWidth, dataColumnWidth, totalTableWidth);
    }

    private getRequestForParams(params: HttpParams): Observable<Array<Counter>> {
        return this.http.get<ActivitiesDto>('/api/v1/Activities', { params }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(error);
            }),
            withLatestFrom(this.globalSettingsQuery.getTimeZone()),
            map(([dto, timeZone]) => convertFromActivitiesDto(dto, timeZone))
        );
    }

}
