<div class="bol-modal__header">
    <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.EDIT-WEEK-LENGTH</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content>
    <mat-form-field>
        <mat-label translate>Length in weeks</mat-label>
        <input matInput type="number" [formControl]="weekLengthForm" min="1" max="53" required>
        <mat-error *ngIf="weekLengthForm.errors?.required">{{ 'VALIDATORS.REQUIRED' | translate }}</mat-error>
        <mat-error *ngIf="weekLengthForm.errors?.min">{{ 'VALIDATORS.MIN_VALUE' | translate:{minValue: 1} }}</mat-error>
        <mat-error *ngIf="weekLengthForm.errors?.max">{{ 'VALIDATORS.MAX_VALUE' | translate:{maxValue: 53} }}</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <ng-container>
            <button 
                type="button"
                (click)="onCancel()"
                mat-button 
                class="bol-button-outlined"
                data-cy="cancelEntityButton">
                <span translate>Cancel</span>
            </button>
        
            <button 
                type="button"
                mat-button 
                (click)="onSave()"
                class="bol-button-solid bol-positive"
                [disabled]= "weekLengthForm.invalid"
                data-cy="saveEntityButton">
                <span translate>Save</span>
            </button>
        </ng-container>
    </div>
</mat-dialog-actions>
  