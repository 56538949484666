/* eslint-disable radix */
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateHelper, GridColumnConfig, ResourceTimeRangeStore, Scheduler, SchedulerConfig, ViewPresetConfig } from '@bryntum/scheduler';
import { BryntumSchedulerComponent } from '@bryntum/scheduler-angular';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter, first, map, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { NestedTreeService } from '@ortec/soca-web-ui';

import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import {
    MultisortingOption,
    ExtendedScheduleActivity,
    ScheduleShowOption,
} from '../../stores/schedule-store/schedule.model';
import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES, SCHEDULE_NAVIGATION_OPTIONS, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleLanguageService } from '../../schedule-helpers/schedule-language.service';
import { EVENT_HEIGHT, ScheduleCalendarHelperService } from '../../schedule-helpers/schedule-calendar-helper.service';
import { ScheduleCalendarTimeHelperService } from '../../schedule-helpers/schedule-calendar-time-helper.service';
import { ScheduleActivity } from '../../stores/schedule-activity-store/schedule-activity.model';

@Component({
    selector: 'app-schedule-calendar',
    templateUrl: './schedule-calendar.component.html',
    styleUrls: ['./schedule-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy {
    @ViewChild('resourceToNavigate', { static: false }) public resourceToNavigate: any;
    @ViewChild('filterField', { static: false }) public filterField: any;
    @ViewChild('resourceCalendar', { static: false }) public resourceCalendarComponent: BryntumSchedulerComponent;
    @ViewChild('activityCalendar', { static: false }) public activityCalendarComponent: BryntumSchedulerComponent;
    public navigationOptions = SCHEDULE_NAVIGATION_OPTIONS;
    public daterangeTypes = SCHEDULE_DATERANGE_TYPES;
    public showScheduleSpinner$!: Observable<boolean>;
    public filterSettingsPanelOpenState$: Observable<boolean>;
    public daterangeConfigurable$: Observable<boolean>;
    public daterangeType$: Observable<SCHEDULE_DATERANGE_TYPES>;
    public datepickerStartDate$: Observable<Date>;
    public currentStartDate: Date;
    public datepickerEndDate$: Observable<Date>;
    public sliderControl = new UntypedFormControl();
    public showActivityCalendar: boolean;
    public showResourceCalendar: boolean;
    
    public resourceCalendar: Scheduler;
    public activityCalendar: Scheduler;
    public schedulerConfigResources: Partial<SchedulerConfig>;
    public schedulerConfigActivities: Partial<SchedulerConfig>;
    public viewPreset$ = new BehaviorSubject<Partial<ViewPresetConfig>>({});
    public startDate: Date;
    public endDate: Date;
    public resources = [];
    public activityTypes = [];
    public hideEmptyRows: boolean = false;
    public searchTerm: string;
    public searchResource: string = '';
    public searchEvent = '';
    public ready$ = new BehaviorSubject<boolean>(false);
    public finishedRenderingResources$ = new BehaviorSubject<boolean>(false);
    public finishedRenderingActivities$ = new BehaviorSubject<boolean>(false);
    public timeZone: string;
    public initialSliderWidth: number = 40;

    private readonly subscription = new Subscription();
    private isFullUser: boolean;
    private userResourceId: number;
    private readonly resourceOnlyUserIdForSchedule = 'me';

    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly scheduleService: ScheduleService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly translateService: TranslateService,
        private readonly scheduleLanguageService: ScheduleLanguageService,
        private readonly scheduleCalendarHelperService: ScheduleCalendarHelperService,
        private readonly scheduleCalendarTimeHelperService: ScheduleCalendarTimeHelperService,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly nestedTreeService: NestedTreeService,
        private readonly utilsService: UtilsService,
    ) { }

    public ngOnInit(): void {
        this.isFullUser = this.userInfoQuery.getIsFullUserSync();
        if (!this.isFullUser) {
            this.userResourceId = this.userInfoQuery.getUserResourceIdSync();
        }
        this.timeZone = this.globalSettingsQuery.getTimeZoneSync();
        this.showScheduleSpinner$ = combineLatest([
            this.scheduleQuery.getScheduleLoadingState(),
            this.finishedRenderingResources$.asObservable(),
            this.finishedRenderingActivities$.asObservable()
        ]).pipe(
            // We don't want to show a spinner while "scheduleLoading" is true, since we already have a spinner
            // in a different component. The spinner in this component should only be shown when Bryntum is rendering
            map(([scheduleLoading, finishedRenderingResources, finishedRenderingActivities]) => {
                return !scheduleLoading && finishedRenderingResources && finishedRenderingActivities;
            })
        );
        this.daterangeType$ = this.scheduleQuery.getDaterangeType();
        this.datepickerStartDate$ = this.scheduleQuery.getStartDate();
        this.subscription.add(
            this.scheduleQuery.getStartDate()
                .pipe(filter(startDate => startDate !== this.currentStartDate))
                .subscribe(startDate => this.currentStartDate = startDate)
        );

        this.datepickerEndDate$ = this.scheduleQuery.getEndDate();
        this.daterangeConfigurable$ = this.scheduleQuery.getDaterangeType().pipe(
            map(daterangeType => daterangeType === SCHEDULE_DATERANGE_TYPES.CONFIGURABLE)
        );
        
        this.setSchedulerResourceConfig([]);
        this.setSchedulerActivityConfig([]);
        this.filterSettingsPanelOpenState$ = this.scheduleQuery.getFilterSettingsPanelStatus();
        this.scheduleLanguageService.setLanguage();
        this.setScheduleData();
        this.initViewsInSchedule();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onUpdatePeriod(daysToNavigate: SCHEDULE_NAVIGATION_OPTIONS, add: boolean): void {
        this.scheduleService.updateDatesParameters(daysToNavigate, add);
        this.scheduleService.getScheduleData().pipe(first()).subscribe();
    }

    public onStartDatePickerChange(event: any) : void {
        if (event.userAction && event.valid) {
            const selectedDate = moment(event.value).toDate();
            const endDate = this.scheduleQuery.getEndDateSync();
            this.currentStartDate = moment(event.value).toDate();
            const daterangeTypeSelected = this.scheduleQuery.getDaterangeTypeSync();

            if ((selectedDate <= endDate && daterangeTypeSelected === SCHEDULE_DATERANGE_TYPES.CONFIGURABLE) || (daterangeTypeSelected !== SCHEDULE_DATERANGE_TYPES.CONFIGURABLE)) {
                this.scheduleService.updateScheduleStartDate(selectedDate);
                this.scheduleService.getScheduleData().pipe(first()).subscribe();
            }
        }
    } 

    public onEndDatePickerChange(event: any) : void {
        if (event.valid && event.userAction) {
            if (this.currentStartDate !== this.scheduleQuery.getStartDateSync()){
                this.scheduleService.updateScheduleStartDate(this.currentStartDate);
            }
            this.scheduleService.updateScheduleEndDate(event.value);
            this.scheduleService.getScheduleData().pipe(first()).subscribe();
        }
    }

    public onNavigateToResource(): void {
        this.searchResource = this.resourceToNavigate.instance.value;
        const matchingResource = this.resourceCalendar.resourceStore.find(resource =>
            resource.name.match(new RegExp(this.searchResource, 'i'))
        );

        this.resourceCalendar.scrollRowIntoView(matchingResource);
    }

    public onUpdateHideEmptyResources(checked: boolean): void {
        this.hideEmptyRows = checked;
        this.scheduleService.updateHideEmptyRows(checked);
        this.applyRowFilters();
    }

    // NOTE: see https://forum.bryntum.com/viewtopic.php?f=44&p=110488
    // Angular wrappers aren't working like they should
    // Using "input" instead of "onInput" is the suggested workaround
    public onFilterEvents(): void {
        this.searchTerm = this.filterField.instance.value;
        this.applyRowFilters();
    }

    private applyRowFilters(): void {
        const filterResourceFilter = {
            filters: [],
            replace: true
        };
      
        const filterEventsFilter = {
            filters: [],
            replace: true
        };

        const filterActivitiesFilter = {
            filters: [],
            replace: true
        };

        if (this.hideEmptyRows && this.searchTerm && this.searchTerm.length > 0) {
            const searchTermLower = this.searchTerm.toLowerCase();
            if (this.showResourceCalendar && this.resourceCalendar !== undefined) {
                filterResourceFilter.filters.push((resource) => {

                    return (
                        resource.events.some(event => event.startDate >= this.resourceCalendar.startDate && event.endDate <= this.resourceCalendar.endDate) &&
                        resource.events.some(event => event.name.toLowerCase().includes(searchTermLower))
                    );
                });
            }

            if (this.showActivityCalendar && this.activityCalendar !== undefined) {
                filterActivitiesFilter.filters.push((activity) => {
                    return (
                        activity.events.some(event => event.startDate >= this.activityCalendar.startDate && event.endDate <= this.activityCalendar.endDate) &&
                        activity.events.some(event => event.name.toLowerCase().includes(searchTermLower))
                    );
                });
            }

        } else if (this.hideEmptyRows) {
            if (this.showResourceCalendar && this.resourceCalendar !== undefined) {
                filterResourceFilter.filters.push((resource) => {
                    return resource.events.some(event => event.startDate >= this.resourceCalendar.startDate && event.endDate <= this.resourceCalendar.endDate);
                });
            }

            if (this.showActivityCalendar && this.activityCalendar !== undefined) {
                filterActivitiesFilter.filters.push((resource) => {
                    return resource.events.some(event => event.startDate >= this.activityCalendar.startDate && event.endDate <= this.activityCalendar.endDate);
                });
            }
        }
      
        if (this.searchTerm && this.searchTerm.length > 0) {
            const searchTermLower = this.searchTerm.toLowerCase();
            filterEventsFilter.filters.push((event) => {
                return event.name.toLowerCase().includes(searchTermLower);
            });

            filterResourceFilter.filters.push((resource) => {
                return (
                    resource.events.some(event => event.name.toLowerCase().includes(searchTermLower))
                );
            });

            filterActivitiesFilter.filters.push((activity) => {
                return (
                    activity.events.some(event => event.name.toLowerCase().includes(searchTermLower))
                );
            });
        }
      
        if (this.showResourceCalendar && this.resourceCalendar !== undefined) {
            this.resourceCalendar.resourceStore.filter(filterResourceFilter);
            this.resourceCalendar.eventStore.filter(filterEventsFilter);
        }

        if (this.showActivityCalendar && this.activityCalendar !== undefined) {
            this.activityCalendar.resourceStore.filter(filterActivitiesFilter);
            this.activityCalendar.eventStore.filter(filterEventsFilter);
        }
    }

    private navigateToResource(): void {
        if (this.searchResource) {
            const matchingResource = this.resourceCalendar.resourceStore.find(resource =>
                resource.name.match(new RegExp(this.searchResource, 'i'))
            );
            
            this.resourceCalendar.scrollRowIntoView(matchingResource);
        }
    }
    
    private setScheduleData(): void {
        this.subscription.add(
            combineLatest([
                this.scheduleQuery.getScheduleLoadingState(),
                this.scheduleQuery.getFilterSettingsPanelStatus()
            ]).pipe(
                tap(() => {
                    this.finishedRenderingActivities$.next(false);
                    this.finishedRenderingResources$.next(false);
                }),
                filter(([loading, filterpanelStatus]) => !loading && !filterpanelStatus),
                tap(() => {
                    const showOptions = this.scheduleQuery.getShowOptionsSync();
                    const activityShowOptions = this.scheduleQuery.getActivityShowOptionsSync();
                    
                    this.setViewsInSchedule(showOptions, activityShowOptions);
                    
                    this.ready$.next(false); 
                    setTimeout(() => {
                        this.ready$.next(true);

                        const calendarCheckInterval = setInterval(() => {
                            this.resourceCalendar = this.resourceCalendarComponent?.instance;
                            this.activityCalendar = this.activityCalendarComponent?.instance;

                            // Not ready yet for rendering
                            if ((this.resourceCalendar === undefined && this.showResourceCalendar) || this.activityCalendar === undefined && this.showActivityCalendar) {
                                return;
                            }

                            clearInterval(calendarCheckInterval);

                            const groupingOptionType = this.scheduleQuery.getGroupingOptionTypeSync();
                            const selectedDayparts = this.scheduleQuery.getSelectedDaypartsSync();
                            const daterangeType = this.scheduleQuery.getDaterangeTypeSync();

                            this.startDate = this.scheduleQuery.getStartDateSync();
                            this.endDate = this.scheduleQuery.getEndDateSync();
                            this.setSlotMinWidth();

                            // if we are rendering both panels, we need to register the partner panel
                            if (((this.resourceCalendar !== undefined && this.showResourceCalendar) && (this.activityCalendar !== undefined && this.showActivityCalendar))) {
                                this.resourceCalendar.addPartner(this.activityCalendar);
                            }

                            // rendering resource
                            if (this.resourceCalendar !== undefined && this.showResourceCalendar) {
                                const activitiesForResourcePanel = this.utilsService.mapToArray(this.scheduleQuery.getActivitiesForResourcePanelSync());
                                const fillOutCell = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_FILL)?.state;
                                const resources = this.scheduleQuery.getResourcesSync();

                                this.resourceCalendar.setTimeSpan(this.startDate, this.endDate);
                                this.setCalendarResourcesForResources(resources, groupingOptionType);
                                this.setCalendarEventsForResources(activitiesForResourcePanel, groupingOptionType, selectedDayparts, fillOutCell);
                                this.setSorting();
                                this.resourceCalendar.expandAll();
                                this.setSchedulerResourceConfig(selectedDayparts);
                                this.setTimeConfigResourceSchedule(showOptions, selectedDayparts);

                                this.navigateToResource();
                                this.finishedRenderingResources$.next(false);
                                this.setResourceEventCellLayout(fillOutCell);
                            }

                            // rendering activity
                            if (this.activityCalendar !== undefined && this.showActivityCalendar) {
                                const activitiesForActivityPanel = this.utilsService.mapToArray(this.scheduleQuery.getActivitiesForActivityPanelSync());
                                const fillOutCell = activityShowOptions.find(so => so.value === SHOW_OPTIONS.SHOW_FILL)?.state;
                                const selectedParentActivityTypesForActivities = this.scheduleQuery.getSelectedParentActivityTypesForActivities(this.scheduleQuery.getSortedActivityTypeRootIdsForActivitiesSync(), true, false);
                                this.activityCalendar.setTimeSpan(this.startDate, this.endDate);
                                this.setCalendarDataForActivity(selectedParentActivityTypesForActivities, activitiesForActivityPanel);
                                this.setSchedulerActivityConfig(selectedDayparts);
                                this.setTimeConfigActivitySchedule(activityShowOptions, selectedDayparts);

                                this.finishedRenderingActivities$.next(false);
                                this.setActivitiesEventCellLayout(fillOutCell);
                            }

                            this.setViewPreset(daterangeType, selectedDayparts);
                            this.setSplitterPosition();
                            this.applyRowFilters();
                            this.setHideEmptyRows();
                            this.listenToFinishRendering();

                        }, 50);
                    }, 50);
                })
            ).subscribe()
        );
    }

    /**
     * Configure the display of an event in a cell of the Resources view, if cell fill is selected by the user then set the required scheduler
     * configuration to let an event fill the cell and if it has multiple events it must be stacked on top of each other.
     * @param fillOutCell 
     */
     private setResourceEventCellLayout(fillOutCell: boolean) : void {
        if (fillOutCell) {
            this.schedulerConfigResources.fillTicks = true;
            this.schedulerConfigActivities.allowOverlap = false;
        } else {
            this.schedulerConfigResources.fillTicks = false;
            this.schedulerConfigActivities.allowOverlap = true;
        }
    }

    /**
     * Configure the display of an event in a cell of the Resources view, if cell fill is selected by the user then set the required scheduler
     * configuration to let an event fill the cell and if it has multiple events it must be stacked on top of each other.
     */
    private setActivitiesEventCellLayout(fillOutCell: boolean) : void {
        if (fillOutCell) {
            this.schedulerConfigActivities.fillTicks = true;
            this.schedulerConfigActivities.allowOverlap = false;
        } else {
            this.schedulerConfigActivities.fillTicks = false;
            this.schedulerConfigActivities.allowOverlap = true;
        }
    }

    private setViewPreset(daterangeType: SCHEDULE_DATERANGE_TYPES, selectedDayparts: Array<Daypart>): void {
        // Set initially
        const daypartsSelected = selectedDayparts.length > 0;
        this.viewPreset$.next(this.scheduleCalendarTimeHelperService.getViewPreset(daterangeType, daypartsSelected));

        // Alter when the slider changes
        this.subscription.add(
            this.scheduleQuery.getSlotWidth().pipe().subscribe(
                slotWidth => this.viewPreset$.next(this.scheduleCalendarTimeHelperService.getViewPreset(daterangeType, daypartsSelected, slotWidth))
            )
        );
    }

    private setSchedulerResourceConfig(selectedDayparts: Array<Daypart>): void {
        const columns: Array<Partial<GridColumnConfig>> = [{ 
            type: 'tree',
            field: 'name',
            text: this.translateService.instant('Resources'),
            width: 300, 
            sortable: (a, b) => this.isFullUser ? this.scheduleCalendarHelperService.getSortedResourcesAndResourceProperties(a, b) : this.scheduleCalendarHelperService.getSortedResourcesAndResourcePropertiesForResourceOnlyUser(a, b, this.userResourceId),
            renderer: ({ value }): any => {

                return value;
            },
        }];

        const allResourceProperties = this.resourcePropertyLanguageQuery.getResourcePropertiesLanguageSync();
        const resourcePropertiesSelected = this.scheduleQuery.getFilteredResourcePropertiesSync();

        allResourceProperties.forEach(prop => {
            if (prop.resourcePropertyId !== -1) {
                const propertyIsSelected = resourcePropertiesSelected.find(p => p.resourcePropertyId === prop.resourcePropertyId);

                const property = prop?.resourcePropertyId.toString();
                columns.push({
                    field: prop.resourcePropertyId.toString(),
                    text: prop.text,
                    width: 150,
                    hidden: !propertyIsSelected,
                    sortable: (a, b) => this.scheduleCalendarHelperService.getSortedResourcesAndResourceProperties(a[property], b[property])
                });
            }
        });

        const timeAxis = {
            continuous: false,
            generateTicks: this.scheduleCalendarTimeHelperService.getGenerateTicksFunction(selectedDayparts)
        };

        this.schedulerConfigResources = {
            columns,
            partner: this.activityCalendar,
            features: {
                headerMenu: {
                    items: {
                        columnPicker: {
                            listeners: {
                                item: (event): void => {
                                    const selectedResourcePropertiesIds = new Set();

                                    event.items.columnPicker.menu.items.forEach(item => {
                                        const propertyId = item.column.field;
                                        const isHidden = item.column.data.hidden;
                                        
                                        if (!isHidden) {
                                            if (!resourcePropertiesSelected.some(prop => prop.resourcePropertyId.toString() === propertyId)) {
                                                const resourcePropSelected = allResourceProperties.find(resourceProp => resourceProp.resourcePropertyId.toString() === propertyId);
                                                if (resourcePropSelected) {
                                                    resourcePropertiesSelected.push(resourcePropSelected);
                                                }
                                            }
                                            
                                            selectedResourcePropertiesIds.add(parseInt(propertyId));
                                        } else {
                                            selectedResourcePropertiesIds.delete(parseInt(propertyId));
                                        }
                                    });
                                    
                                    const updatedIds = Array.from(selectedResourcePropertiesIds);
                                    this.scheduleService.updateResourcePropertyIdsParameter(updatedIds as Array<number>);
                                }
                            }
                        }
                    }
                },
                resourceTimeRanges: true,
                timeRanges: true,
                filterBar: true,
                tree: true,
                cellMenu: false,
                nonWorkingTime: true,
                timeAxisHeaderMenu: false,
                scheduleTooltip: false,
                stripe: true,
                eventTooltip: false
            },
            eventLayout: 'stack',
            eventStyle: 'minimal',
            allowOverlap: true,
            fillTicks: false,
            barMargin: 0,
            resourceMargin: 0,
            zoomKeepsOriginalTimespan: true,
            managedEventSizing: false,
            weekStartDay: 1,
            timeAxis,
            eventRenderer: ({ eventRecord, renderData }: any): string => { 
                renderData.eventColor = '';
                const data: ExtendedScheduleActivity = eventRecord.data;
                const id = data?.originalId !== undefined ? `id="${data.originalId}"` : '';
                const resourceid = data?.originalResourceId !== undefined ? `resourceid="${data.originalResourceId}"` : '';

                return `<app-schedule-event ${id} ${resourceid}></app-schedule-event>`;
            },
        };

        // Here because we need a start value, we'll get errors if we remove it
        this.viewPreset$.next({
            timeResolution: {
                unit: 'day',
                increment: 1,
            },
            headers: [
                {
                    unit: 'week',
                    renderer: (startDate) => `Week ${DateHelper.format(startDate, 'WW')} ${DateHelper.format(startDate, 'YYYY')}`
                },
                {
                    unit: 'day',
                    dateFormat: 'ddd DD-MM',
                }
            ],
        });
    }

    private setSchedulerActivityConfig(selectedDayparts: Array<Daypart>): void {
        const columns: Array<Partial<GridColumnConfig>> = [{ 
            type: 'template',
            field: 'name',
            text: this.translateService.instant('Activity Types'),
            width: 300, 
            template: (data): string => { 
                const id = data.record?.id !== undefined ? `id="${data.record.id}"` : '';
            
                return `<app-schedule-activity-template ${id}></app-schedule-activity-template>`
            },
            sortable: (a: any, b: any) => this.scheduleCalendarHelperService.getSortedResourcesAndResourceProperties(a.name, b.name),
        }];

        const timeAxis = {
            continuous: false,
            generateTicks: this.scheduleCalendarTimeHelperService.getGenerateTicksFunction(selectedDayparts)
        };

        this.schedulerConfigActivities = {
            columns,
            partner: this.resourceCalendar,
            features: {
                resourceTimeRanges: true,
                timeRanges: true,
                filterBar: true,
                tree: false,
                cellMenu: false,
                nonWorkingTime: true,
                timeAxisHeaderMenu: false,
                scheduleTooltip: false,
                stripe: true,
                eventTooltip: false
            },
            eventLayout: 'stack',
            eventStyle: 'minimal',
            allowOverlap: true,
            fillTicks: false,
            barMargin: 0,
            resourceMargin: 0,
            zoomKeepsOriginalTimespan: true,
            managedEventSizing: false,
            weekStartDay: 1,
            timeAxis,
            eventRenderer: ({ eventRecord }: any): string => { 
                const data: ExtendedScheduleActivity = eventRecord.data;
                const id = data?.id !== undefined ? `id="${data.id}"` : '';

                return `<app-schedule-activity-event ${id}"></app-schedule-activity-event>`;
            }
        };
    }

    private setTimeConfigResourceSchedule(showOptions: Array<ScheduleShowOption>, selectedDayparts: Array<Daypart>): void {
        const hideWeekend = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_SAT_SUN)?.state;

        if (!hideWeekend) {
            this.resourceCalendar.timeAxis.filter(tick => tick.startDate.getDay() !== 6 && tick.startDate.getDay() !== 0 );
        }

        this.resourceCalendar.timeRangeStore = new ResourceTimeRangeStore();
        const timeRanges = this.scheduleCalendarTimeHelperService.getTimeRanges(this.startDate, this.endDate, selectedDayparts);
        timeRanges?.forEach(tr => (this.resourceCalendar.timeRangeStore as any).add(tr));
    }

    private setTimeConfigActivitySchedule(showOptions: Array<ScheduleShowOption>, selectedDayparts: Array<Daypart>): void {
        const hideWeekend = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_SAT_SUN)?.state;

        if (!hideWeekend) {
            this.activityCalendar.timeAxis.filter(tick => tick.startDate.getDay() !== 6 && tick.startDate.getDay() !== 0 );
        }
        
        this.activityCalendar.timeRangeStore = new ResourceTimeRangeStore();
        const timeRanges = this.scheduleCalendarTimeHelperService.getTimeRanges(this.startDate, this.endDate, selectedDayparts);
        timeRanges?.forEach(tr => (this.activityCalendar.timeRangeStore as any).add(tr));
    }

    private setCalendarEventsForResources(activities: Array<ScheduleActivity>, groupingOption: GROUPING_OPTIONS, selectedDayparts: Array<Daypart>, isFillCell: boolean): void {
        // Failsafe to set groupingoption if it wasn't set yet
        if (groupingOption === undefined) {
            groupingOption = this.scheduleQuery.getGroupingOptionTypeSync();
            this.scheduleService.updateGroupingOptionType(groupingOption);
        }

        const extraResources = this.scheduleQuery.getExtraResourceTypeIdsSync();
        const timeZone = this.globalSettingsQuery.getTimeZoneSync();
        const calendarStart = moment.utc(this.startDate).tz(timeZone).startOf('day');
        const showFullTree = this.scheduleQuery.getExtraResourceTypeIdsSync()?.length > 0;
        
        this.resourceCalendar.eventStore.removeAll(true);

        const activitiesToAddToStore = [];

        /*We need to modify the resource and activity IDs in Bryntum because it cannot handle situations where resources and activities,
        share the same ID. To address this, we plan to add a prefix to all resource IDs, using their parent ID, 
        and also modify the resourceId property in activities to match the new IDs.*/
        switch (groupingOption) {
            case GROUPING_OPTIONS.RESOURCE_TYPE:
            case GROUPING_OPTIONS.ORGANIZATION_UNIT: {
                this.resources?.forEach(resource => {
                    resource.children?.forEach(r => {
                        const existingResource = this.resourceCalendar.resourceStore.getById(r.id) as any;
                        
                        if (existingResource) {
                            const processActivitiesForResource = this.scheduleCalendarHelperService.processActivitiesForResource(activities, r.id, r.originalId, showFullTree, extraResources);
                            activitiesToAddToStore.push(...processActivitiesForResource.activitiesToAddToStore);
                            existingResource.rowHeight = processActivitiesForResource.tallestHeight;
                        }
                    });
                });
                break;
            }
    
            default: {
                this.resources.forEach(resource => {
                    const existingResource = this.resourceCalendar.resourceStore.getById(resource.id) as any;
                    
                    if (existingResource) {
                        const processActivitiesForResource = this.scheduleCalendarHelperService.processActivitiesForResource(activities, resource.id, resource.id, showFullTree, extraResources);
                        activitiesToAddToStore.push(...processActivitiesForResource.activitiesToAddToStore);
                        existingResource.rowHeight = processActivitiesForResource.tallestHeight;
                    }
                });
            }
        }

        if (!this.isFullUser) {
            const resourceOnlyResource = this.resourceCalendar.resourceStore.getById(this.resourceOnlyUserIdForSchedule) as any;

            if (resourceOnlyResource) {
                const processActivitiesForResource = this.scheduleCalendarHelperService.processActivitiesForResource(activities, this.resourceOnlyUserIdForSchedule, this.userResourceId, showFullTree, extraResources);
                activitiesToAddToStore.push(...processActivitiesForResource.activitiesToAddToStore);
                resourceOnlyResource.rowHeight = processActivitiesForResource.tallestHeight;
            }
        }

        // Add events in bulk to avuid triggering "on changes" for each event
        this.resourceCalendar.eventStore.add(activitiesToAddToStore);
    }

    private setCalendarDataForActivity(activityTypes: Array<ActivityType>, activities: Array<ScheduleActivity>): void {
        this.activityCalendar.eventStore.removeAll(true);
        this.activityCalendar.resourceStore.removeAll(true);

        // If filter is inactive, show nothing
        if (!this.scheduleQuery.getApplyFilterOnActivityScheduleStateSync()) {
            activityTypes = [];

            return;
        }
       
        const activityShowOptions = this.scheduleQuery.getActivityShowOptionsSync();
        const showOnlyMe = activityShowOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ONLY_ME)?.state;

        activityTypes?.forEach(r => {
            const selectedActivityTypes = this.scheduleQuery.getAllSelectedActivityTypesFromParentId(r.id);
            const numberOfNodes = selectedActivityTypes.length;
            const allSelectedLeafIds = new Set(selectedActivityTypes.map(l => l.id));
        
            const allLeaves = this.activityTypeQuery.getFullActivityStructureAndSortByRootIdSync(r.id) ?? [];
            const filteredLeaves = allLeaves.filter(leaf => allSelectedLeafIds.has(leaf.id));
            const displayNameAndShortName = filteredLeaves.map(leaf => leaf.displayName + ' ' + leaf.shortName).join(' ');
        
            const activityType = {
                name: displayNameAndShortName,
                id: r.id,
                rowHeight: numberOfNodes * EVENT_HEIGHT
            };
            this.activityCalendar.resourceStore.add(activityType);
        });

        const eventsToAddToStore = [];

        activities.forEach(activity => {
            
            // For resource only users, only show their own activities
            if (!this.isFullUser && showOnlyMe && 
                // If the activity has leaves and none belong to the resource, skip it
                ((activity.childrenRecursedResourceIds !== null && !activity.childrenRecursedResourceIds.includes(this.userResourceId)) || 
                // If the activity has no leaves but doesn't belong to the user, skip it
                (activity.childrenRecursedResourceIds === null && activity.resourceId !== this.userResourceId))) {
                return;
            }
            
            const event = {
                ...activity,
                name: activity.searchString,
                startDate: this.scheduleCalendarHelperService.subtractTimeZoneHoursFromDateString(activity?.adjustedStart),
                endDate: this.scheduleCalendarHelperService.subtractTimeZoneHoursFromDateString(activity?.adjustedEnd),
                resourceId: activity.activityType.id
            }
            
            // Note: Bryntum starts to interpret the children property, so remove it since we don't need it here
            delete event.children;

            eventsToAddToStore.push(event);
        });

        // Add events in bulk to avuid triggering "on changes" for each event
        this.activityCalendar.eventStore.add(eventsToAddToStore);
    }

    private setCalendarResourcesForResources(resources: Array<Resource>, groupingOption: GROUPING_OPTIONS): void {
        // Failsafe to set groupingoption if it wasn't set yet
        if (groupingOption === undefined) {
            groupingOption = this.scheduleQuery.getGroupingOptionTypeSync();
            this.scheduleService.updateGroupingOptionType(groupingOption);
        }
        const showOptions = this.scheduleQuery.getShowOptionsSync();
        this.resourceCalendar.resourceStore.removeAll(true);
        const extraProperties = this.resourcePropertyLanguageQuery.getResourcePropertiesLanguageSync();
        const showOnlyMe = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ONLY_ME)?.state;
        this.resources = [];

        // for resource only users, add their resource outside of grouping options
        if (!this.isFullUser && (groupingOption !== GROUPING_OPTIONS.NO_GROUPING || showOnlyMe)) {
            let userResource: any = resources?.find(r => r.id === this.userResourceId);
            
            if (userResource !== undefined) {
                userResource = {
                    name: userResource.displayName,
                    originalId: userResource.id,
                    id: this.resourceOnlyUserIdForSchedule,
                    children: []
                }
                extraProperties?.forEach(prop => {
                    userResource[prop.resourcePropertyId.toString()] = userResource.resourceProperties?.find(p => p.resourcePropertyId === prop.resourcePropertyId)?.value ?? '';
                });

                this.resources.push(userResource);
            }
        }

        // For full users and resource only users that "only me" to false, populate resources with everything
        if (!(!this.isFullUser && showOnlyMe)) {
            switch(groupingOption) {
                case GROUPING_OPTIONS.RESOURCE_TYPE: {
                    const resourceTypes = this.scheduleQuery.getFilteredBaseResourceTypesSync();
   
                    resourceTypes.forEach(type => {
                        const resourceType = this.scheduleCalendarHelperService.addExtraPropertiesToParentNodeEntity(type, extraProperties);
    
                        resourceType.children = (resources ?? [])
                            .filter(r => r.resourceTypeMemberships?.some(m => m.resourceTypeId === type.id))
                            .map(r => (this.scheduleCalendarHelperService.addExtraPropertiesToResource(r, extraProperties, resourceType.id)));
    
                        this.resources.push(resourceType);
                    });
                    break;
                }
    
                case GROUPING_OPTIONS.ORGANIZATION_UNIT: {
                    const organizationUnits = this.scheduleQuery.getSelectedOrganizationsSync();
    
                    organizationUnits.forEach(unit => {
                        const oUnit = this.scheduleCalendarHelperService.addExtraPropertiesToParentNodeEntity(unit, extraProperties);
    
                        oUnit.children = (resources ?? [])
                            .filter(r => r.organizationUnitMemberships.some(m => m.organizationUnitId === unit.id))
                            .map(r => (this.scheduleCalendarHelperService.addExtraPropertiesToResource(r, extraProperties, oUnit.id)));
    
                        this.resources.push(oUnit);
                    });
                    break;
                }
                default: {
                    if (this.isFullUser) {
                        resources?.forEach(r => {
                            this.resources.push(this.scheduleCalendarHelperService.addExtraPropertiesToResource(r, extraProperties, undefined));
                        });
                    } else {
                        const userResource: any = resources?.find(r => r.id === this.userResourceId);
                        if (userResource) {
                            this.resources.unshift(this.scheduleCalendarHelperService.addExtraPropertiesToResource(userResource, extraProperties, undefined));
                        }
                        
                        resources?.forEach(r => {
                            if (r !== userResource) {
                                this.resources.push(this.scheduleCalendarHelperService.addExtraPropertiesToResource(r, extraProperties, undefined));
                            }
                        });
                    }
                }
            }
        }
        
        // Add resources in bulk to avuid triggering "on changes" for each resource
        this.resourceCalendar.resourceStore.add(this.resources);
    }

    private setSorting(): void {
        this.resourceCalendar.resourceStore.on('sort', (event) => {
            const multisortOrder: Array<MultisortingOption> = event.sorters.map(option => ({
                ascending: option.ascending,
                field: option.field,
            }));
           
            this.scheduleService.updateMultiselectOrderState(multisortOrder);
        });

        const currentMultiselectOrder = this.scheduleQuery.getSelectedMultiselectOrderSync();
        const modifiedMultiselectOrder = currentMultiselectOrder?.map(opt => ({
            ascending: opt.ascending,
            field: opt.field,
            columnOwned: true,
            originalSortFn: (a, b) => this.scheduleCalendarHelperService.getSortedResourcesAndResourceProperties(a, b),
        }));
        this.resourceCalendar.resourceStore.sort(modifiedMultiselectOrder);
    }

    private setSlotMinWidth(): void {
        this.subscription.add(
            this.scheduleQuery.getSlotWidth().pipe(first()).subscribe(width => {
                const sliderControlValue = width ? width : 100;
                this.sliderControl.setValue(sliderControlValue, { emitEvent: false });
            })
        );

        this.subscription.add(
            this.sliderControl.valueChanges
                .pipe(debounceTime(1000))
                .subscribe(width =>{
                    this.scheduleService.updateSlotWidth(width)}
                )
        );
    }

    private setViewsInSchedule(showOptions: Array<ScheduleShowOption>, activityShowOptions: Array<ScheduleShowOption>): void {
        this.showResourceCalendar = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL)?.state ?? true;
        this.showActivityCalendar = activityShowOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL)?.state;
    }

    private initViewsInSchedule(): void {
        const showOptions = this.scheduleQuery.getShowOptionsSync();
        this.showResourceCalendar = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL)?.state;
        const activityShowOptions = this.scheduleQuery.getActivityShowOptionsSync();
        this.showActivityCalendar = activityShowOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL)?.state;
    }

    private setHideEmptyRows(): void {
        const hideEmptyRowsValue = this.scheduleQuery.getHideEmptyRowsStatusSync();
        this.onUpdateHideEmptyResources(hideEmptyRowsValue);
    }

    private setSplitterPosition(): void {
        const splitterPosition = this.scheduleQuery.getSplitterPositionSync() ?? 300;

        // Note: use "activityCalendar" listener, unless only resourceCalendar is shown
        if (this.showActivityCalendar) {
            this.activityCalendar.subGrids.locked.on('resize', (event) => {
                this.scheduleService.updateSplitterPosition(event.width);
            });
    
            this.activityCalendar.subGrids.locked.width = splitterPosition;

            // Only set resourceCalendar when shown
            if (this.showResourceCalendar) {
                this.resourceCalendar.subGrids.locked.width = splitterPosition;
            }
        }
        // Only resourceCalendar is shown
        else {
            this.resourceCalendar.subGrids.locked.on('resize', (event) => {
                this.scheduleService.updateSplitterPosition(event.width);
            });

            this.resourceCalendar.subGrids.locked.width = splitterPosition;
        }
    }

    private listenToFinishRendering(): void {
        if (this.resourceCalendar !== undefined) {
            (this.resourceCalendar.project as any).on({
                dataReady: () => {
                    this.finishedRenderingResources$.next(true);
                }
            });
        }
        // not loading the resource calendar, so it's already finished
        else {
            this.finishedRenderingResources$.next(true);
        }
          
        if (this.activityCalendar !== undefined) {
            (this.activityCalendar.project as any).on({
                dataReady: () => {
                    this.finishedRenderingActivities$.next(true);
                }
            });
        }
        // not loading the activity calendar, so it's already finished
        else {
            this.finishedRenderingActivities$.next(true);
        }
    }
}