export const globalSettings = {
    ows: {
        resourceImport: {
            enabled: 'ows.resourceImport.enabled',
            startTime: 'ows.resourceImport.startTime',
            frequency: 'ows.resourceImport.frequency',
            endTime: 'ows.resourceImport.endTime',
            dayOfWeek: 'ows.resourceImport.dayOfWeek',
            dayOfMonth: 'ows.resourceImport.dayOfMonth',
            interval: 'ows.resourceImport.interval',
        },
        activityImport: {
            enabled: 'ows.activityImport.enabled',
            startTime: 'ows.activityImport.startTime',
            frequency: 'ows.activityImport.frequency',
            endTime: 'ows.activityImport.endTime',
            dayOfWeek: 'ows.activityImport.dayOfWeek',
            dayOfMonth: 'ows.activityImport.dayOfMonth',
            interval: 'ows.activityImport.interval',
        },
        miscSettings : {
            daysInPast: 'ows.miscSettings.daysInPast',
            daysInFuture: 'ows.miscSettings.daysInFuture',
            weekend: 'ows.miscSettings.weekend',
            deleteOld: 'ows.miscSettings.deleteOld',
            daysOld: 'ows.miscSettings.daysOld'
        },
        instanceName: 'ows.instanceName'
    }
};
