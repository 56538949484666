<mat-card class="bol-card content-wrapper">
    <mat-card-content>
        <h3 translate>Application Settings</h3>

        <mat-list>
            <mat-list-item>
                <a routerLink="/application-settings/dayparts" translate>Dayparts</a>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>