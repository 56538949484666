<div class="status-indication__bar" data-cy="loadingStatusIndication">
    <ng-container *ngIf="statusPageState === STATUS.IS_LOADING">
        <div class="status-indication__text" translate>saving</div>
        <mat-spinner class="status-indication__spinner" diameter="16"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="statusPageState === STATUS.IS_FINISHED_SAVING">
        <div class="status-indication__text" translate>saved</div>
    </ng-container>

    <ng-container *ngIf="statusPageState === STATUS.HAS_PENDING_CHANGES">
        <div class="status-indication__text" translate>changes pending</div>
    </ng-container>
</div>