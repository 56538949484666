import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FILTER_SETTING_TYPE, FilterSetting } from './filter-setting.model';
import { FilterSettingState, FilterSettingStore } from './filter-setting.store';

@Injectable({
    providedIn: 'root'
})
export class FilterSettingQuery extends QueryEntity<FilterSettingState> {
    constructor(
        protected store: FilterSettingStore,
    ) {
        super(store);
    }

    public getFilterSettingsSync(): Array<FilterSetting> {
        return Object.values(this.getValue().entities);
    }

    public getSelectedFilterSettingIdSync(): number {
        return this.getValue().ui.selectedId;
    }

    public getSelectedFilterSettingSync(): FilterSetting {
        const selectedId = this.getValue().ui.selectedId;

        return this.getFilterSettingsSync().find(setting => setting.id === selectedId);
    }

    public getFilterSettingsByTypeSync(type: FILTER_SETTING_TYPE): Array<FilterSetting> {
        return this.getFilterSettingsSync().filter(setting => setting.settingType === type);
    }

    public getFilterSettings(): Observable<Array<FilterSetting>> {
        return this.selectAll();
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getFilterSettingsByType(type: FILTER_SETTING_TYPE): Observable<Array<FilterSetting>> {
        return this.selectAll().pipe(map(settings => settings.filter(setting => setting.settingType === type)));
    }

    public getSelectedFilterSettingId(): Observable<number> {
        return this.select(state => state.ui.selectedId);
    }

    public getFilterSettingBySelectedId(): Observable<FilterSetting> {
        return combineLatest([
            this.getEntitiesLoadingState(),
            this.getSelectedFilterSettingId()
        ]).pipe(
            filter(([state, _]) => !state),
            map(([_, selectedId]) => this.getFilterSettingsSync().find(setting => setting.id === selectedId))
        );
    }

    public getFirstFilterSettingIdAvailableByType(type: FILTER_SETTING_TYPE): number | undefined {
        const sortedFilters = this.getFilterSettingsSync().filter(setting => setting.settingType === type)
            .sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

        return sortedFilters && sortedFilters.length > 0 ? sortedFilters[0].id : undefined;
    }

    public filterSettingsUpdated(): Observable<any> {
        return this.selectAll().pipe(map(() => undefined));
    }
}
