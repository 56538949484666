/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Resource } from './resource.model';

export interface ResourceState extends EntityState<Resource> {
    ui: {
        entitiesLoading: boolean;
    }
}

const initialState = {
    ui: {
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource' })
export class ResourceStore extends EntityStore<ResourceState> {
    constructor() {
        super(initialState);
    }

    public updateEntitiesLoading(entitiesLoading: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoading}
        }));
    }
}
