<div class="bol-modal__header">
    <span class="modal-title">{{ 'Save filter changes' | translate }}</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
        <i class="material-icons">close</i>
    </div>
</div>

<mat-dialog-content>
    <div *ngIf="selectedFilterSetting">
        <mat-radio-group
            class="bol-radio-group bol-vertical"
            [(ngModel)]="selectedOption">
            <mat-radio-button class="bol-radio-button" [value]="overwriteSelectedOption">
                <div translate>Overwrite the selected filter with the new filter changes.</div>
            </mat-radio-button>
            <mat-radio-button class="bol-radio-button" [value]="saveNewOption">
                <div class="save-new-filter">
                    <div translate>Save the new filter changes as a new filter.</div>
                </div>
            </mat-radio-button>
        </mat-radio-group>
        
        <br>
        
        <mat-form-field
            *ngIf="selectedOption === saveNewOption"
            class="bol-form-field no-errors-form-field"
            appearance="fill">
            <mat-label translate>Filter name</mat-label>
            <input
                data-cy="saveNewFilterName"
                matInput
                [formControl]="filterSettingNameControl">
            <mat-error *ngIf="filterSettingNameControl.invalid" translate>{{ 'Field is required' }}</mat-error>
        </mat-form-field>
    </div>

    <div class="save-new-filter no-selected-filter" *ngIf="!selectedFilterSetting">
        <div translate>Save the new filter changes as a new filter.</div>
    
        <mat-form-field class="bol-form-field no-errors-form-field" appearance="fill" >
            <mat-label translate>Filter name</mat-label>
            <input
                data-cy="saveFirstFilterName"
                matInput
                [formControl]="filterSettingNameControl">
            <mat-error *ngIf="filterSettingNameControl.invalid" translate>{{ 'Field is required' }}</mat-error>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            data-cy="saveFilterButton"
            mat-button
            class="bol-button-solid bol-positive"
            [disabled]="(filterSettingNameControl.invalid && selectedOption !== overwriteSelectedOption)"
            (click)="onSaveChanges()">
            <span translate>Save</span>
        </button>
    </div>
</mat-dialog-actions>