import { ChangeDetectionStrategy, Component, OnInit, ViewChild, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SocaWebUiCommonModule, cache, filterUndefined } from '@ortec/soca-web-ui';
import { BehaviorSubject, Observable, combineLatest, filter, first, map, switchMap } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { MatTableDataSource } from '@angular/material/table';
import { BolCalloutModule } from '@ortec/bolster/callout';
import { BolPopoverModule } from '@ortec/bolster/popover';
import { MatSelect } from '@angular/material/select';

import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditTemplatesManagementQuery } from '../../store/edit-template-management.query';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { AdtHelperService } from '../adt-helper/adt-helper.service';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { Resource, ResourceRequestParameters } from 'src/app/shared/stores/resource-store/resource.model';
import { ResourceService } from 'src/app/shared/stores/resource-store/resource.service';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';

export interface IPreferredResourceDialogData {
    preferredResources?: Array<any>;
    selectedActivityTimeslotId?: number;
}

@Component({
    standalone: true,
    selector: 'app-adt-preferred-resource-dialog',
    templateUrl: './adt-preferred-resource-dialog.component.html',
    styleUrls: ['./adt-preferred-resource-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        SocaWebUiCommonModule,
        SharedModule,
        BolCalloutModule,
        BolPopoverModule,

        MultiselectTreeComponent,
    ],
})
export class AdtPrefferedResourceDialogComponent implements OnInit {
    @ViewChild('weekSelect') public weekSelect!: MatSelect;
    @ViewChild('daySelect') public daySelect!: MatSelect;

    public weeks = [];
    public days = [];
    public displayedColumns: Array<string> = ['shortName', 'displayName', 'resourceTypes', 'preferredResources'];
    public dataSource = new MatTableDataSource<any>([]);
    public weekSelectSubject = new BehaviorSubject<number>(1);
    public daySelectSubject = new BehaviorSubject<number>(1);
    public selectedOrganizationUnitIdsSubject = new BehaviorSubject<Array<number>>([]);
    public selectedPreferredResourceIdsSubject = new BehaviorSubject<Array<number>>([]);
    public disableOrganizationUnitAndResourceFieldsSubject = new BehaviorSubject<boolean>(true);
    public demandExceededSubject = new BehaviorSubject<boolean>(false);
    public selectedResourceTypeIdsSubject = new BehaviorSubject<Array<number>>([]);
    public demandAmountSubject = new BehaviorSubject<number>(0);
    //it needs to be false
    public missingPreferredResourcesSubject = new BehaviorSubject<boolean>(true);
    public internalSelectedEntityId = undefined;

    private readonly resourceService = inject(ResourceService);
    private readonly resourceQuery = inject(ResourceQuery);
    private readonly adtHelperService = inject(AdtHelperService);
    private readonly organizationUnitsQuery = inject(OrganizationUnitQuery);
    private readonly dialogRef = inject<MatDialogRef<IPreferredResourceDialogData>>(MatDialogRef);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly manageTimeslotQuery = inject(ManageTimeslotQuery);

    public ngOnInit(): void {
        this.setInitialData();
    }

    public openedWeekSelect(state: boolean): void {
        if (!state) {
            this.weekSelectSubject.next(this.weekSelect.value);
        }
    }

    public openedDaySelect(state: boolean): void {
        if (!state) {
            this.daySelectSubject.next(this.daySelect.value);
        }
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                preferredResources: []
            } as IPreferredResourceDialogData
        });
    }

    public onFilteredOrganizationsChanged(organizationIds: Array<number>): void {
        this.selectedOrganizationUnitIdsSubject.next(organizationIds);
    }

    public onPreferredResourcesChanged(resourceIds: Array<number>): void {
        this.selectedPreferredResourceIdsSubject.next(resourceIds);
    }

    public onSelectActivityRow(activityTypeInfo: any): void {
        this.internalSelectedEntityId = activityTypeInfo.id;
        this.disableOrganizationUnitAndResourceFieldsSubject.next(false);
        this.selectedResourceTypeIdsSubject.next(activityTypeInfo.resourceTypeIds);
    }

    @Memoized public get activityTypeNameAndTimeslotPeriod$(): Observable<string> {
        return this.editTemplatesManagementQuery.getSelectedActivityTimeslotId().pipe(
            filter(id => id !== undefined && id !== 0),
            first(),
            switchMap(id => this.manageTimeslotQuery.getActivityTypeNameAndTimeslotPeriodByActivityTypeTimeslotId(id)),
            cache()
        );
    }

    @Memoized public get weekSelect$(): Observable<number> {
        return this.weekSelectSubject.asObservable();
    }

    @Memoized public get daySelect$(): Observable<number> {
        return this.daySelectSubject.asObservable();
    }

    @Memoized public get organizationsForFiltering$(): Observable<Array<IOrganizationUnitTree>> {
        return this.organizationUnitsQuery.getOrganizationUnitsForActivityTypes().pipe(cache());
    }

    @Memoized public get preselectedOrganizationUnits$(): Observable<Array<number>> {
        return this.selectedOrganizationUnitIdsSubject.asObservable().pipe(cache());
    }

    @Memoized public get preferredResources$(): Observable<Array<Resource>> {
        return combineLatest([
            this.resourceQuery.getResources(),
            this.preselectedOrganizationUnits$,
            this.selectedResourceTypeIdsSubject,
        ]).pipe(
            filterUndefined(),
            map(([allResources, filteredOrganizationIds, selectedResourceTypeIds]) => {
                return allResources.filter(resource => 
                    resource.resourceTypeMemberships.some(membership => 
                        (selectedResourceTypeIds.length === 0 || selectedResourceTypeIds.includes(membership.resourceTypeId)) &&
                        (filteredOrganizationIds.length === 0 || filteredOrganizationIds.includes(membership.organizationUnitId))
                    )
                ).map(resource => ({...resource}));
            })
        );
    }

    @Memoized public get preselectedPreferredResources$(): Observable<Array<number>> {
        return this.selectedPreferredResourceIdsSubject.asObservable().pipe(cache());
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return this.resourceQuery.getEntitiesLoading().pipe(cache());
    }

    @Memoized public get disableOrganizationUnitAndResourceFields$(): Observable<boolean> {
        return this.disableOrganizationUnitAndResourceFieldsSubject.asObservable().pipe(cache());
    }

    @Memoized public get demandExceeded$(): Observable<boolean> {
        return this.demandExceededSubject.asObservable().pipe(cache());
    }

    @Memoized public get demandAmount$(): Observable<number> {
        return this.demandAmountSubject.asObservable().pipe(cache());
    }

    @Memoized public get missingPreferredResources$(): Observable<boolean> {
        return this.missingPreferredResourcesSubject.asObservable().pipe(cache());
    }

    @Memoized public get activityTypes$(): Observable<Array<any>> {
        return this.editTemplatesManagementQuery.getSelectedActivityTimeslotId().pipe(
            filter(id => id !== undefined && id !== 0),
            first(),
            switchMap(id => 
                this.manageTimeslotQuery.getActivityTypesForPreferredResources(id)
            ),
            cache()
        );
    }

    public setInitialData(): void {
        const parameters: ResourceRequestParameters = {
            includeResourceTypeMemberships: true,
            includeOrganizationUnitMemberships: true
        };
    
        this.resourceService.getResources(parameters).pipe(first()).subscribe();

        this.days = this.adtHelperService.getDayOptions();
        this.weeks = this.adtHelperService.getWeekOptions(this.editTemplatesManagementQuery.getMaxWeekNumberSync());
    }
}