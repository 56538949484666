import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BaseUrlInterceptor implements HttpInterceptor {

    public intercept(request: HttpRequest<any>, nextHttpHandler: HttpHandler): Observable<HttpEvent<any>> {
        if ((request.url.toLowerCase().startsWith('/api'))) {
            request = request.clone({
                url: environment.omrpApiUrl + request.url
            });
        }

        return nextHttpHandler.handle(request);
    }
}
