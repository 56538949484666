import { Injectable } from '@angular/core';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';

import { ResourceTypesManagementStore } from './resource-types-management.store';
@Injectable({
    providedIn: 'root'
})
export class ResourceTypesManagementService {
    constructor(
        protected resourceTypesManagementStore: ResourceTypesManagementStore
    ) { }


    public updateSelectedResourceTypeId(id: number): void {
        this.resourceTypesManagementStore.updateSelectedResourceTypeId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.resourceTypesManagementStore.updateSelectedOrganizationUnitId(id);
    }

    public updateShowChildUnits(value: boolean): void {
        this.resourceTypesManagementStore.updateShowChildUnits(value);
    }
    
    public updateManageMode(mode: MANAGE_MODE): void {
        this.resourceTypesManagementStore.updateManageMode(mode);
    }

    public setStateForNewResourceType(resourceTypeId: number): void {
        this.resourceTypesManagementStore.setStateForNewResourceType(resourceTypeId);
    }
}