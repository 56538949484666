import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateTimeUtilityService } from '../../services/date-time-utility.service';
import { StatusPageQuery } from '../status-page-store/status-page.query';
import { ADTActivityType } from 'src/app/features/activity-demand/edit-template/store/models';
import { ManageTimeslotState, ManageTimeslotStore } from './manage-timeslot.store';
import { ActivityTypeInfo, ActivityTypeTimeslots, PreferredResourcesInfoForTable } from '../manage-template-store/manage-template.model';
import { ActivityTypeTimeslotsPutPost } from './manage-timeslot.model';
import { ActivityTypeQuery } from '../activity-type-store/activity-type.query';

@Injectable({
    providedIn: 'root'
})
export class ManageTimeslotQuery extends StatusPageQuery<ManageTimeslotState> {
    
    constructor(
        protected store: ManageTimeslotStore,
        protected dateTimeUtilityService: DateTimeUtilityService,
        protected activityTypeQuery: ActivityTypeQuery
    ) {
        super(store);
    }

    public getActivityTypeTimeslots(): Observable<Array<ActivityTypeTimeslots>> {
        return this.selectAll().pipe(
            map(timeslots => timeslots.sort((a, b) => {
                const nameComparison = a.activityTypeInfo.displayName.localeCompare(b.activityTypeInfo.displayName);
                if (nameComparison !== 0) {
                    return nameComparison;
                } else {
                    return a.activityTypeTimeslotInfo.start - b.activityTypeTimeslotInfo.start;
                }
            }))
        );
    }

    public getActivityTypeTimeslotsSync(): Array<ActivityTypeTimeslots> {
        const timeslots = this.getValue().entities;

        return Object.values(timeslots);
    }
    
    public getActivityTypeTimeSlotsForSelectedTemplateForEdit(): Observable<Array<ADTActivityType>> {
        return this.getActivityTypeTimeslots().pipe(
            map(timeslots => timeslots
                ? timeslots.map(timeslot => ({
                    ...timeslot,
                    id: timeslot.id,
                    activityTypeId: timeslot.activityTypeTimeslotInfo.activityTypeId,
                    displayName: timeslot.activityTypeInfo.displayName,
                    startTime: this.dateTimeUtilityService.convertMinutesToHours(timeslot.activityTypeTimeslotInfo.start),
                    endTime: this.dateTimeUtilityService.convertMinutesToHours(timeslot.activityTypeTimeslotInfo.end),
                    distributionTotal: timeslot.activityDemandTotal,
                    weekDemand: timeslot.weeksDemand.map(week => ({
                        weekNumber: week.weekNumber,
                        weekdaysDemand: week.weekdaysDemand.map(day => ({
                            dayNumber: day.dayNumber,
                            amount: day.amount
                        }))
                    }))
                }))
                : []
            )
        );
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getUpdatedActivityTypeTimeslotsSync(updatedActivityTimeslotsIds: Array<number>, selectedActivityTypeId: number): { addedTimeslots: Array<ActivityTypeTimeslotsPutPost>, deletedTimeslotIds: Array<number> } {
        const timeslots: Array<ActivityTypeTimeslots> = this.getActivityTypeTimeslotsSync();
    
        const currentTimeslotsIds = new Set(timeslots
            .filter(timeslot => timeslot.activityTypeTimeslotInfo.activityTypeId === selectedActivityTypeId)
            .map(timeslot => timeslot.activityTypeTimeslotInfo.id)
        );
    
        const updatedActivityTimeslotsIdsSet = new Set(updatedActivityTimeslotsIds);
    
        // Directly calculate ids to delete and their corresponding timeslot ids
        const deletedTimeslotIds = timeslots
            .filter(timeslot => !updatedActivityTimeslotsIdsSet.has(timeslot.activityTypeTimeslotInfo.id) && timeslot.activityTypeTimeslotInfo.activityTypeId === selectedActivityTypeId)
            .map(timeslot => timeslot.id);
    
        // Directly calculate ids to add
        const idsToAdd = Array.from(updatedActivityTimeslotsIdsSet).filter(id => !currentTimeslotsIds.has(id));
    
        // Create new timeslots for ids to add
        const newTimeslots: Array<ActivityTypeTimeslotsPutPost> = idsToAdd.map(id => ({
            id: 0,
            activityTypeTimeslotId: id,
            weeksDemand: [],
            activityDemandTotal: 0
        }));
    
        return {
            addedTimeslots: newTimeslots,
            deletedTimeslotIds: deletedTimeslotIds
        };
    }

    public getActivityTypeInfoByActivityTypeTimeslotId(id: number): Observable<ActivityTypeInfo> {
        return this.selectAll().pipe(
            map(timeslots => timeslots.find(timeslot => timeslot.id === id).activityTypeInfo)
        );
    }

    public getActivityTypeNameAndTimeslotPeriodByActivityTypeTimeslotId(id: number): Observable<string> {
        return this.selectAll().pipe(
            map(timeslots => {
                const timeslot = timeslots.find(timeslot => timeslot.id === id) as ActivityTypeTimeslots;
                const displayName = timeslot.activityTypeInfo.displayName;
                const start = this.dateTimeUtilityService.convertMinutesToHours(timeslot.activityTypeTimeslotInfo.start);
                const end = this.dateTimeUtilityService.convertMinutesToHours(timeslot.activityTypeTimeslotInfo.end);

                return `${displayName} ${start} - ${end}`;
            })
        );
    }

    public getActivityTypeIdSync(id: number): number {
        const timeslots = this.getValue().entities;
        const activityTypeId = Object.values(timeslots).find(timeslot => timeslot.id === id)?.activityTypeInfo.id;

        return activityTypeId;
    }

    public getSelectedTimeslotIdsForActivityType(id?: number): Array<number> {
        const timeslots = this.getActivityTypeTimeslotsSync();
        if (id !== undefined) {
            return timeslots.filter(timeslot => timeslot.activityTypeInfo.id === id).map(timeslot => timeslot.activityTypeTimeslotInfo.id);
        } else {
            return timeslots.map(timeslot => timeslot.activityTypeTimeslotInfo.id);
        }
    }

    public getActivityTypesForPreferredResources(activityTimeslotId: number): Observable<Array<PreferredResourcesInfoForTable>> {
        return this.activityTypeQuery.getFullActivityStructureAndSortByRootId(this.getActivityTypeIdSync(activityTimeslotId)).pipe(
            map(activityTypes => activityTypes.map(activityType => ({
                id: activityType.id, 
                shortName: activityType.shortName,
                nameStyling: {
                    backgroundColor: '#' + activityType.backColor,
                    color: '#' + activityType.textColor
                },
                displayName: activityType.displayName,
                resourceTypeNames: activityType.resourceTypeList?.map(resourceType => resourceType.displayName).join(', '),
                resourceTypeIds: activityType.resourceTypeList?.map(resourceType => resourceType.id) ?? [],
                preferredResources: []
            })))
        );
    }
}
