import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { GROUPING_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleMissingEntitiesService } from '../../schedule-helpers/schedule-missing-entities.service';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';
import { Schedule, ScheduleActivityResponse, ScheduleFilterSettings, ScheduleShowOption } from './schedule.model';
import { ScheduleQuery } from './schedule.query';
import { ScheduleState, ScheduleStore } from './schedule.store';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {
    constructor(
        protected scheduleStore: ScheduleStore,
        private readonly http: HttpClient,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly scheduleMissingEntitiesService: ScheduleMissingEntitiesService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly utilsService: UtilsService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public get(parameters: ScheduleRequestParameters): Observable<Array<Schedule>> {
        this.updateLoadingState(true);
        this.scheduleStore.updateResourcesDialog(undefined);

        return this.http.post<Array<Schedule>>('/api/Reports/GetSchedule', parameters).pipe(
            tap((schedule) => {
                this.scheduleStore.set(schedule);
                this.updateLoadingState(false);
            })
        );
    }

    public getActivitiesForActivityId(activityId: number): Observable<ScheduleActivityResponse> {
        const params = new HttpParams()
            .set('includeActivityTree', 'true')
            .set('includeMemoText', 'true')
            .set('includeActivityTypeDetails', 'true')
            .set('includeRootActivityTypeDetails', 'true')
            .set('includeResourceDetails', 'true')
            .set('includeWorkloadHours', 'true')
            .set('includeResourceOrganizationUnit', 'true')
        ;

        const apiUrl = `/api/v1/Activities/${activityId}`;
    
        return this.http.get<ScheduleActivityResponse>(apiUrl, { params }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(error);
            }),
            tap((response) => {
                this.scheduleStore.updateResourcesDialog({transformedActivitiesForDialog: undefined, activities: response.activities});
            })
        );
    }

    public resetStore(): void {
        this.scheduleStore.set([]);
    }

    public updateDatesParameters(startDate: string): void {
        this.scheduleStore.updateDatesParameters(startDate);
    }

    public updateLanguageCodeParameter(code: string): void {
        this.scheduleStore.updateLanguageCodeParameter(code);
    }

    public updateOrganizationUnitIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateOrganizationUnitIdsParameter(ids);
        const savedUnits = this.organizationUnitQuery.getOrganizationUnitsByIdsSync(ids);
        const savedEntities = savedUnits.map(unit => ({id: unit.id, displayName: unit.displayName}));
        this.scheduleStore.updateSavedOrganizationUnits(savedEntities);
    }

    public updateBaseResourceTypeIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateBaseResourceTypeIdsParameter(ids);
        const savedResourceTypes = this.resourceTypeQuery.getResourceTypesByIdsSync(ids);
        const savedEntities = savedResourceTypes.map(type => ({id: type.id, displayName: type.displayName}));
        this.scheduleStore.updateSavedBaseResourceTypes(savedEntities);
    }

    public updateExtraResourceTypeIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateExtraResourceTypeIdsParameter(ids);
        const savedResourceTypes = this.resourceTypeQuery.getResourceTypesByIdsSync(ids);
        const savedEntities = savedResourceTypes.map(type => ({id: type.id, displayName: type.displayName}));
        this.scheduleStore.updateSavedExtraResourceTypes(savedEntities);
    }

    public updateResourcePropertyIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateResourcePropertyIdsParameter(ids);
        const savedProps = this.resourcePropertyLanguageQuery.getResourcePropertiesByIdsSync(ids);
        const savedEntities = savedProps.map(prop => ({id: prop.resourcePropertyId, displayName: prop.text}));
        this.scheduleStore.updateSavedResourceProperties(savedEntities);
    }

    public updateShowOption(option: ScheduleShowOption, checked: boolean): void {
        this.scheduleStore.updateShowOption(option, checked);
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.scheduleStore.updateActivityTypeIds(ids);
    }

    public updateActivityTypeCategoryIds(ids: Array<number>): void {
        this.scheduleStore.updateActivityTypeCategoryIds(ids);
    }

    public updateGroupingOptionType(type: GROUPING_OPTIONS): void {
        this.scheduleStore.updateGroupingOptionType(type);
    }

    public updateNumberOfDays(days: number): void {
        this.scheduleStore.updateNumberOfDays(days);
    }

    public setSchedulesToEmpty(): void {
        this.scheduleStore.set(null);
    }

    public updateRequestParameters(parameters: ScheduleRequestParameters): void {
        this.scheduleStore.updateRequestParameters(parameters);
    }

    public updateShowOptions(showOptions: Array<ScheduleShowOption>): void {
        this.scheduleStore.updateShowOptions(showOptions);
    }

    public updateSlotMinWidth(minWidth: number): void {
        this.scheduleStore.updateSlotMinWidth(minWidth);
        this.updateCurrentFilterSettingWithCalendarOptions();
    }

    public updateResourceAreaWidth(width: number): void {
        this.scheduleStore.updateResourceAreaWidth(width);
        this.updateCurrentFilterSettingWithCalendarOptions();
    }

    public updateScheduleStartDate(startDate: Date): void {
        this.scheduleStore.updateScheduleStartDate(startDate);
    }

    public updateScheduleEndDate(endDate: Date): void {
        this.scheduleStore.updateScheduleEndDate(endDate);
    }

    public updateScheduleNoOfDaysToNavigate(noOfDays: number): void {
        this.scheduleStore.updateScheduleNoOfDaysToNavigate(noOfDays);
    }

    public updateLoadingState(state: boolean): void {
        this.scheduleStore.updateLoadingState(state);
    }

    public updateSelectedActivityId(activityId: number): void {
        this.scheduleStore.updateSelectedActivityId(activityId);
    }

    public updateActivityDialogState(state: boolean): void {
        this.scheduleStore.updateActivityDialogState(state);
        this.updateCurrentFilterSettingWithCalendarOptions();
    }

    public updateScheduleStateOnFilterSettingChanged(state: ScheduleState): void {
        this.scheduleMissingEntitiesService.handleMissingEntities(state).pipe(first()).subscribe((response) => {
            if (this.scheduleQuery.getValue().ui.selectedFilterSettingId === response.filterId) {
                if (response.updatedFilterOrganizationUnits !== undefined) {
                    this.updateOrganizationUnitIdsParameter(response.updatedFilterOrganizationUnits);
                }
                if (response.updatedFilterBaseResourceTypes !== undefined) {
                    this.updateBaseResourceTypeIdsParameter(response.updatedFilterBaseResourceTypes);
                }
                if (response.updatedFilterExtraResourceTypes !== undefined) {
                    this.updateExtraResourceTypeIdsParameter(response.updatedFilterExtraResourceTypes);
                }
                if (response.updatedFilterResourceProperties !== undefined) {
                    this.updateResourcePropertyIdsParameter(response.updatedFilterResourceProperties);
                }
                if (response.updatedFilterOrganizationUnits !== undefined || 
                    response.updatedFilterBaseResourceTypes !== undefined ||
                    response.updatedFilterExtraResourceTypes !== undefined ||
                    response.updatedFilterResourceProperties !== undefined
                ) {
                    const updatedFilterSetting = this.scheduleQuery.getUpdatedScheduleFilterSetting();
                    this.filterSettingService.updateFilterSetting(updatedFilterSetting).pipe(first()).subscribe();
                }
            }
        });

        this.scheduleStore.updateScheduleState(state);
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.scheduleStore.updateSelectedFilterSettingId(id);
    }

    public updateCurrentFilterSettingWithCalendarOptions(): void {
        const currentFilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        if (currentFilterSetting) {
            const filterSetting = this.utilsService.deepCopy(this.filterSettingQuery.getSelectedFilterSettingSync());
            const settings: ScheduleFilterSettings = JSON.parse(filterSetting.settings);

            const activityDialogState = this.scheduleQuery.getActivityDialogStateSync();
            const slotMinWidth = this.scheduleQuery.getSlotMinWidthSync();
            const resourceAreaWidth = this.scheduleQuery.getResourceAreaWidthSync();

            if (settings.slotMinWidth !== slotMinWidth ||
                settings.activityDialogState !== activityDialogState ||
                settings.resourceAreaWidth !== resourceAreaWidth) {
                settings.slotMinWidth = slotMinWidth ?? settings.slotMinWidth;
                settings.resourceAreaWidth = resourceAreaWidth ?? settings.resourceAreaWidth;
                settings.activityDialogState = activityDialogState ?? settings.activityDialogState;

                filterSetting.settings = JSON.stringify(settings);
                this.filterSettingService.updateFilterSetting(filterSetting).pipe(first()).subscribe();
            }
        }
    }
}
