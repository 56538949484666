import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Permission } from 'src/app/shared/models/Enums';

import { IPermissionsTreePropagationModalData, PermissionsTreePropagationDialogComponent } from '../components/permissions-tree-propagation-dialog/permissions-tree-propagation-dialog.component';
import { PermissionFlatNode, PermissionItem, PermissionsTreePropagationOptions } from '../permissions-tree.model';
import { NestedTreeService } from 'src/app/shared/services/nested-tree.service';

@Injectable()
export class PermissionsTreePropagationService {
    public permissionsTreePropagationOptions: PermissionsTreePropagationOptions = { rememberDialogAction: false, shouldPropagate: false, demoteParentPermission: false };

    constructor(
        private readonly dialogService: MatDialog,
        private readonly nestedTreeService: NestedTreeService
    ) { }

    public getPermissionItemsOnStatusChange(entity: PermissionFlatNode, permissionType: Permission, newStatus: boolean): Observable<Array<PermissionItem>> {
        // Check if the entity's status is false and none of its children are checked
        if (!newStatus && this.nestedTreeService.areAllChildrenUnchecked(entity)) {
            return of(this.getPermissionItemsForEntity(entity, permissionType, []));
        }

        if (!this.permissionsTreePropagationOptions.rememberDialogAction && entity.children?.length > 0) {
            return this.onPermissionsTreePropagationDialog(entity, permissionType, newStatus);
        }

        this.permissionsTreePropagationOptions.demoteParentPermission = false;

        if (this.permissionsTreePropagationOptions.shouldPropagate || entity.children?.length === 0) {
            return of(this.getPermissionItemsForEntity(entity, permissionType, []));
        } else {
            return of([{ userGroupId: entity.id, permission: permissionType }]);
        }
    }   

    private onPermissionsTreePropagationDialog(entity: PermissionFlatNode, permissionType: Permission, newStatus: boolean): Observable<Array<PermissionItem>> {
        const dialogRef = this.dialogService.open(PermissionsTreePropagationDialogComponent, {
            data: {
                permission: permissionType,
                status: newStatus,
                entityName: 'User group',
                entityNamePlural: 'User groups'
            } as IPermissionsTreePropagationModalData,
            autoFocus: false,
            disableClose: true,
            width: '600px',
            height: '154px'
        });

        return dialogRef.componentInstance.changePermissionsTreePropagationOptionsEvent.pipe(
            map(propagationOptions => {
                this.permissionsTreePropagationOptions = propagationOptions;
                this.permissionsTreePropagationOptions.demoteParentPermission = this.getPermissionbyEntityStatuses(entity) === Permission.owner || this.getPermissionbyEntityStatuses(entity) === Permission.readWrite;

                if (propagationOptions.shouldPropagate) {
                    return this.getPermissionItemsForEntity(entity, permissionType, []);
                }

                return [{ userGroupId: entity.id, permission: permissionType }];
            })
        );
    }

    private getPermissionItemsForEntity(entity: PermissionFlatNode, permissionType: Permission, permissionItems: Array<PermissionItem>): Array<PermissionItem> {
        // the demoteParentPermission check is made to see if we should skip or not editing a child node when its parent has the permission reverted from Owner to Read permission 
        if (!(this.permissionsTreePropagationOptions.demoteParentPermission &&
            ((permissionType === Permission.read && !entity.readAndWritePermission && !entity.readPermission) || (permissionType === Permission.readWrite && !entity.ownerPermission)))) {
            permissionItems.push({ userGroupId: entity.id, permission: permissionType });
        }

        (entity?.children || []).forEach(child => {
            this.getPermissionItemsForEntity(child, permissionType, permissionItems);
        });

        return permissionItems;
    }

    private getPermissionbyEntityStatuses(entity: PermissionFlatNode): Permission {
        switch (true) {
            case entity.ownerPermission:
                return Permission.owner;
            case entity.readAndWritePermission:
                return Permission.readWrite;
            case entity.readPermission:
                return Permission.read;
            default: return Permission.noPermission;
        }
    }
}