import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { COUNTERS_COUNTER_TYPE, COUNTERS_GROUPING_OPTIONS, COUNTERS_TOTALS_OPTIONS } from '../../counters-helpers/enums';
import { CountersRequestFilters } from './counters-request-parameters.model';
import { Counter } from './counters.model';

export interface CountersState extends EntityState<Counter> {
    ui: {
        selectedFilterSettingId: number;
        validIntervalDates: boolean;
        groupingOptions: Array<COUNTERS_GROUPING_OPTIONS>;
        includeActivityTypeDetails: boolean;
        totalsOptions: Array<COUNTERS_TOTALS_OPTIONS>;
        counterTypeOptions: Array<COUNTERS_COUNTER_TYPE>;
        activityTypeCategoryIds: Array<number>;
        activityTypeIds: Array<number>;
        allActivityTypeIdsSelectedState: boolean;
        organizationUnitForActivityIds: Array<number>;
        allOrganizationUnitsSelectedState: boolean;
        start: string;
        end: string;
        requestFilters: CountersRequestFilters;
        scenarioId?: number;
        error: boolean;
    };
    // note: they are not added to "UI", so "UI" won't change when sliderValues change
    // this is important because matrix listens to "UI" changes and then empties, which we don't want on slider changes
    sliderValues: {
        nameColumnWidth?: number;
        dataColumnWidth?: number;
        totalTableWidth?: number;
    };
}

const initialState: CountersState = {
    ui: {
        selectedFilterSettingId: undefined,
        validIntervalDates: true,
        groupingOptions: [COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT],
        includeActivityTypeDetails: false,
        totalsOptions: [
            COUNTERS_TOTALS_OPTIONS.TOTAL,
            COUNTERS_TOTALS_OPTIONS.WEEK
        ],
        counterTypeOptions: [COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE],
        activityTypeCategoryIds: undefined,
        activityTypeIds: undefined,
        organizationUnitForActivityIds: undefined,
        allOrganizationUnitsSelectedState: false,
        allActivityTypeIdsSelectedState: false,
        start: undefined,
        end: undefined,
        requestFilters: {
            resourceOrganizationUnitIds: undefined,
            resourceTypeIds: undefined,
            skillIds: undefined,
            allResourceOrganizationUnitsSelectedState: false
        },
        error: false
    },
    sliderValues: {
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'counters' })
export class CountersStore extends EntityStore<CountersState> {
    constructor() {
        super(initialState);
    }

    public resetState(): void {
        this.update(() => {
            return { ...initialState };
        });
    }

    public updateAllResourceOrganizationUnitsSelectedState(all: boolean): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, allResourceOrganizationUnitsSelectedState: all };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateGroupingOptions(options: Array<COUNTERS_GROUPING_OPTIONS>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                groupingOptions: options
            };

            return { ...state, ui };
        });
    }

    public updateAllOrganizationUnitsSelectedState(value: boolean): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                allOrganizationUnitsSelectedState: value
            };

            return { ...state, ui };
        });
    }

    public updateAllActivityTypeIdsSelectedState(value: boolean): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                allActivityTypeIdsSelectedState: value
            };

            return { ...state, ui };
        });
    }

    public updateTotalsOptions(totals: Array<COUNTERS_TOTALS_OPTIONS>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                totalsOptions: totals
            };

            return { ...state, ui };
        });
    }

    public updateValidIntervalDates(intervalState: boolean): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                validIntervalDates: intervalState
            };

            return { ...state, ui };
        });
    }

    public updateCounterTypeOptions(counterTypes: Array<COUNTERS_COUNTER_TYPE>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                counterTypeOptions: counterTypes
            };

            return { ...state, ui };
        });
    }

    public updateOrganizationUnitForActivityIds(ids: Array<number>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                organizationUnitForActivityIds: ids
            };

            return { ...state, ui };
        });
    }

    public updateStartDate(startDate: string): void {
        this.update(state => {
            const ui = { ...state.ui, start: startDate };

            return { ...state, ui };
        });
    }

    public updateEndDate(endDate: string): void {
        this.update(state => {
            const ui = { ...state.ui, end: endDate };

            return { ...state, ui };
        });
    }

    public updateResourceOrganizationUnitIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, resourceOrganizationUnitIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateResourceTypeIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, resourceTypeIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.update(state => {
            const ui = { ...state.ui, activityTypeIds: ids };

            return { ...state, ui };
        });
    }

    public updateSkillIds(ids: Array<number>): void {
        this.update(state => {
            const requestFilters = { ...state.ui.requestFilters, skillIds: ids };
            const ui = { ...state.ui, requestFilters };

            return { ...state, ui };
        });
    }

    public updateScenarioId(scenarioId: number): void {
        this.update(state => {
            const ui = { ...state.ui, scenarioId };

            return { ...state, ui };
        });
    }

    public updateActivityTypeDetailsState(newState: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, includeActivityTypeDetails: newState };

            return { ...state, ui };
        });
    }

    public updateActivityTypeCategoryIds(ids: Array<number>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                activityTypeCategoryIds: ids
            };

            return { ...state, ui };
        });
    }

    public updateCountersState(updatedState: CountersState): void {
        this.update(() => ({ ...updatedState }));
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedFilterSettingId: id
            };

            return { ...state, ui };
        });
    }

    public updateErrorState(errorState: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, error: errorState };

            return { ...state, ui };
        });
    }

    public updateSliderWidthValues(nameColumnWidth: number, dataColumnWidth: number, totalTableWidth: number): void {
        this.update(state => {
            const sliderValues = { ...state.sliderValues, nameColumnWidth, dataColumnWidth, totalTableWidth};

            return { ...state, sliderValues };
        });
    }
}
