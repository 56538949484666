<div class="filters-header">
    <div class="filters-settings">
        <div *ngIf="(filterSettings$ | async) && (filterSettings$ | async).length > 0" class="filter-field">
            <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" data-cy="overviewFilters">
                <mat-label>{{ 'Filters' | translate }}</mat-label>
                <mat-select
                    #filterSettingSelect
                    required
                    (openedChange)="openedFilterSettingSelect($event)"
                    [formControl]="filterSettingControl">
                    <mat-option
                        *ngFor="let filterSetting of filterSettings$ | async"
                        [value]="filterSetting.id">
                        {{ filterSetting.displayName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="filterSettingControl.invalid" translate>Field is required</mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!(filterSettings$ | async).length" class="highlight-summary" data-cy="noFilterSelected">
            <div class="no-filter-selected">{{ 'No filter selected' | translate }}</div>
        </div>

        <div class="summary"*ngIf="selectedGroupingOptionType$ | async as selectedOption" data-cy="groupingOptionSelectionHeader">
            <div class="highlight-summary">{{ 'Grouping option' | translate }}</div>&nbsp;&nbsp;&nbsp;{{ selectedOption | translate }}
        </div>
    </div>
    <div 
        class="manage_buttons" 
        [style.padding-top.px]="(filterSettings$ | async).length === 0 ? 20 : 0">
        <app-schedule-filters-settings></app-schedule-filters-settings>
    </div>
</div>
