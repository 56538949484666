/* eslint-disable @typescript-eslint/naming-convention */
export interface ActivityChange {
    activityChangeUserType: number;
    activityId: number;
    activityStart: string;
    activityTypeId: number;
    changes: Array<ActivityChangeInformation>;
    resourceId: number;
    timestamp: string;
    userDisplayName: string;
    userId: number;
    resourceDisplayName: string;
    rootActivityTypeId: number;
}

export interface ActivityChangeInformation {
    changeType: number;
    parameters: ActivityChangeParameters;
}

export interface ActivityChangeParameters {
    activityTypeId?: string;
    end?: string;
    resourceId?: string;
    rootActivityTypeId?: string;
    start?: string;
    resourceIdNew?: string;
    resourceIdOld?: string;
    detailType?: string;
    detailValueNew?: string;
    detailValueOld?: string;
    activityStatusNew?: string;
    activityStatusOld?: string;
    startNew?: string;
    startOld?: string;
    endNew?: string;
    endOld?: string;
}

export interface ActivityChangeRequestParameters {
    activityTypeIdList: Array<number>;
    fromDateInclusive: string;
    resourceIdList: Array<number>;
    toDateExclusive: string;
    activityFromDate: string;
    activityToDate: string;
}

export interface ActivityChangeTypeForTable {
    activityChangeUserType: string;
    activityStart: string;
    activityTypeDisplayName: string;
    changeType: string;
    timestamp: string;
    userDisplayName: string;
    activityStyling?: string;
    changeProperty: string;
    changeValue: string;
}

export enum CHANGE_ACTION_TYPES {
    ADD = 'Add',
    ASSIGN = 'Assign',
    CHANGE = 'Change',
    DELETE = 'CHANGE_DELETE',
    UNASSIGN = 'Unassign',
    LOCKED = 'Locked',
    UNLOCKED = 'Unlocked',
    NOT_REQUIRED = 'Not required',
    CANCEL = 'CHANGE_CANCEL',
}

export enum ACTIVITY_STATUS {
    Planned = '1',
    Deleted = '2',
    Cancelled = '3',
    PlannedAndLocked = '4',
    NotRequired = '5',
    Reclaimable = '6',
    Reclaimed = '7',
}

export enum CHANGE_FILTER_TYPES {
    MULTISELECT = 'multiselect',
    SEARCH = 'search',
}

export enum CHANGE_COLUMNS_FILTER {
    CHANGE_TYPE = 'changeType',
    ORIGIN = 'activityChangeUserType',
}

export enum ORIGIN_TYPES {
    USER = 'User',
    //OWS = 'OWS',
    MULTISELECT = 'Multiselect'
}