<div class="interval-item" data-cy="intervalItem">
    <div class="interval-start">
        <mat-form-field class="bol-form-field date-input dynamic-subscript-height" appearance="fill">
            <mat-label translate>Start time</mat-label>
            <input 
                matInput
                bolTimeInput
                data-cy="ResourceImportsStartTime"
                [formControl]="formControls.startMinutes"
                [bolTimeAutocomplete]="autocompleteFrom"
                autocomplete="off"
                required>
            <mat-icon matSuffix>access_time</mat-icon>
            <mat-error *ngIf="formControls.startMinutes.errors?.required" translate>{{ requiredErrorMessage }}</mat-error>
            <bol-time-autocomplete #autocompleteFrom="bolTimeAutocomplete" [timeValues]="timeRange$ | async"></bol-time-autocomplete>
        </mat-form-field> 
    </div>
    
    <div class="interval-end">
        <mat-form-field class="bol-form-field date-input dynamic-subscript-height" appearance="fill">
            <mat-label translate>End time</mat-label>
            <input 
                matInput
                bolTimeInput 
                data-cy="ResourceImportsEndTime"
                [formControl]="formControls.endMinutes"
                [bolTimeAutocomplete]="autocompleteFrom"
                autocomplete="off"
                required>
                <mat-icon matSuffix>access_time</mat-icon>
                <mat-error *ngIf="formControls.endMinutes.errors?.required" translate>{{ requiredErrorMessage }}</mat-error>
                <mat-error *ngIf="formControls.endMinutes.errors?.startEndMinuteValidator" translate>{{ startTimeAfterEndTime }}</mat-error>
                <bol-time-autocomplete #autocompleteFrom="bolTimeAutocomplete" [timeValues]="timeRange$ | async"></bol-time-autocomplete>
        </mat-form-field> 
    </div>

    <div class="interval">
        <mat-form-field class="bol-form-field dynamic-subscript-height" color="primary" appearance="fill">
            <mat-label>Interval (min)</mat-label>
            <input 
                matInput 
                type="number"
                data-cy="intervalDuration"
                name="interval" 
                placeholder="{{ 'Interval (min)' | translate}}" 
                [formControl]="formControls.interval" />
                <mat-error *ngIf="formControls.interval.errors?.required" translate>{{ requiredErrorMessage }}</mat-error>
                <mat-error *ngIf="formControls.interval.errors?.min" translate>{{ 'Invalid duration format' }}</mat-error>
                <mat-error *ngIf="formControls.interval.errors?.max" translate>{{ intervalExceedsAmountOfMinutes }}</mat-error>
          </mat-form-field>
    </div>
    
    <div class="delete-interval-button">
        <button
            mat-button
            data-cy="deleteEntityButton"
            class="bol-button-text icon-only bol-negative"
            (click)="onDeleteImport(interval)">
            <mat-icon>delete</mat-icon> 
        </button>
    </div>
</div>