<div class="navigation-toolbar">
    <button 
        mat-button class="arrow-button bol-button-text bol-gray" 
        (click)="onUpdatePeriod(navigationOptions.MONTH, false)">
        <mat-icon>chevron_left</mat-icon>M
    </button>
    <button 
        mat-button class="arrow-button bol-button-text bol-gray" 
        (click)="onUpdatePeriod(navigationOptions.WEEK, false)">
        <mat-icon>chevron_left</mat-icon>W
    </button>
    <button 
        *ngIf="(daterangeType$ | async) === daterangeTypes.DAY"
        mat-button class="arrow-button bol-button-text bol-gray" 
        (click)="onUpdatePeriod(navigationOptions.DAY, false)">
        <mat-icon>chevron_left</mat-icon>D
    </button>

    <bryntum-button
        text="{{ 'Today' | translate }}"
        (onClick)="onUpdatePeriod(navigationOptions.TODAY, true)"
    ></bryntum-button>

    <button 
        *ngIf="(daterangeType$ | async) === daterangeTypes.DAY"
        mat-button class="arrow-button bol-button-text bol-gray" 
        (click)="onUpdatePeriod(navigationOptions.DAY, true)">
        D<mat-icon iconPositionEnd>chevron_right</mat-icon>
    </button>
    <button 
        mat-button class="arrow-button bol-button-text bol-gray" 
        (click)="onUpdatePeriod(navigationOptions.WEEK, true)">
        W<mat-icon iconPositionEnd>chevron_right</mat-icon>
    </button>
    <button 
        mat-button class="arrow-button bol-button-text bol-gray" 
        (click)="onUpdatePeriod(navigationOptions.MONTH, true)">
        M<mat-icon iconPositionEnd>chevron_right</mat-icon>
    </button>

    <bryntum-date-field
        label="{{ 'Start date' | translate }}"
        [format]="'DD-MM-YYYY'"
        [weekStartDay]="1"
        [required]="true"
        [value]="datepickerStartDate$ | async"
        (onChange)="onStartDatePickerChange($event)"
    ></bryntum-date-field>

    <div *ngIf="daterangeConfigurable$ | async"
        class="spacer-filters">
        <bryntum-date-field
            label="{{ 'End date' | translate }}"
            [format]="'DD-MM-YYYY'"
            [weekStartDay]="1"
            [required]="true"
            [value]="datepickerEndDate$ | async"
            [min]="currentStartDate"
            (onChange)= "onEndDatePickerChange($event)"
        ></bryntum-date-field>
    </div>
    
    <div class="spacer"></div>
    
    <mat-slider
        *ngIf="(daterangeType$ | async) !== daterangeTypes.DAY && (daterangeType$ | async) !== daterangeTypes.WEEK"
        [min]="40"
        [max]="700"><input matSliderThumb [formControl]="sliderControl"/>
    </mat-slider>

    <mat-checkbox
        class="bol-checkbox"
        [checked]='hideEmptyRows'
        (change)="onUpdateHideEmptyResources($event.checked)">
        <span>{{ 'Hide empty rows' | translate }}</span>
    </mat-checkbox>

    <bryntum-text-field
        #filterField
        placeholder="{{ 'Filter activities' | translate }}"
        clearable=true
        (input)="onFilterEvents()"
        (onClear)="onFilterEvents()"
    ></bryntum-text-field>

    <div class="spacer-filters"></div>

    <bryntum-text-field
        #resourceToNavigate
        placeholder="{{ 'Navigate to resource' | translate }}"
        clearable="true"
        (input)="onNavigateToResource()"
        (onClear)="onNavigateToResource()"
    ></bryntum-text-field>
</div>
<div class="bryntum-calendar-container" *ngIf="(ready$ | async)">
    <div *ngIf="showActivityCalendar && (filterSettingsPanelOpenState$ | async) === false"
        [ngClass]="{'full-height': showActivityCalendar, 'half-height': showActivityCalendar && showResourceCalendar}">
        <bryntum-scheduler 
            #activityCalendar
            [startDate]="startDate"
            [endDate]="endDate"
            [eventRenderer]="schedulerConfigActivities.eventRenderer"
            [eventStyle]="schedulerConfigActivities.eventStyle"
            [eventLayout]="schedulerConfigActivities.eventLayout"
            [barMargin]="schedulerConfigActivities.barMargin"
            [timeAxis]="schedulerConfigActivities.timeAxis"
            [managedEventSizing]="schedulerConfigActivities.managedEventSizing"
            [viewPreset]="viewPreset$ | async"
            [resourceMargin]="schedulerConfigActivities.resourceMargin"
            [columns]="schedulerConfigActivities.columns"
            [filterBarFeature]="schedulerConfigActivities.features.filterBar"
            [treeFeature]="schedulerConfigActivities.features.tree"
            [cellMenuFeature]="schedulerConfigActivities.features.cellMenu"
            [scheduleTooltipFeature]="schedulerConfigActivities.features.scheduleTooltip"
            [timeAxisHeaderMenuFeature]="schedulerConfigActivities.features.timeAxisHeaderMenu"
            [eventTooltipFeature]="schedulerConfigActivities.features.eventTooltip"
            [nonWorkingTimeFeature]="schedulerConfigActivities.features.nonWorkingTime"
            [timeRangesFeature]="schedulerConfigActivities.features.timeRanges"
            [resourceTimeRangesFeature]="schedulerConfigActivities.features.resourceTimeRanges"
            [eventEditFeature]="false"
            [weekStartDay]="schedulerConfigActivities.weekStartDay"
            [zoomOnTimeAxisDoubleClick]="false"
            [zoomKeepsOriginalTimespan]="schedulerConfigActivities.zoomKeepsOriginalTimespan"
            [stripeFeature]="schedulerConfigActivities.features.stripe"
            [readOnly]="true"
            [fillTicks]="schedulerConfigActivities.fillTicks"
            [allowOverlap]="schedulerConfigActivities.allowOverlap"
        ></bryntum-scheduler>
    </div>

    <bryntum-splitter *ngIf="showResourceCalendar && showActivityCalendar" ></bryntum-splitter>

    <div *ngIf="showResourceCalendar && (filterSettingsPanelOpenState$ | async) === false"
        [ngClass]="{'full-height': showResourceCalendar, 'half-height': showActivityCalendar && showResourceCalendar}">
        <bryntum-scheduler 
            #resourceCalendar
            [startDate]="startDate"
            [endDate]="endDate"
            [eventRenderer]="schedulerConfigResources.eventRenderer"
            [eventStyle]="schedulerConfigResources.eventStyle"
            [eventLayout]="schedulerConfigResources.eventLayout"
            [barMargin]="schedulerConfigResources.barMargin"
            [timeAxis]="schedulerConfigResources.timeAxis"
            [managedEventSizing]="schedulerConfigResources.managedEventSizing"
            [viewPreset]="viewPreset$ | async"
            [resourceMargin]="schedulerConfigResources.resourceMargin"
            [columns]="schedulerConfigResources.columns"
            [filterBarFeature]="schedulerConfigResources.features.filterBar"
            [treeFeature]="schedulerConfigResources.features.tree"
            [cellMenuFeature]="schedulerConfigResources.features.cellMenu"
            [headerMenuFeature]="schedulerConfigResources.features.headerMenu"
            [scheduleTooltipFeature]="schedulerConfigResources.features.scheduleTooltip"
            [timeAxisHeaderMenuFeature]="schedulerConfigResources.features.timeAxisHeaderMenu"
            [eventTooltipFeature]="schedulerConfigResources.features.eventTooltip"
            [nonWorkingTimeFeature]="schedulerConfigResources.features.nonWorkingTime"
            [timeRangesFeature]="schedulerConfigResources.features.timeRanges"
            [resourceTimeRangesFeature]="schedulerConfigResources.features.resourceTimeRanges"
            [eventEditFeature]="false"
            [weekStartDay]="schedulerConfigResources.weekStartDay"
            [zoomOnTimeAxisDoubleClick]="false"
            [zoomKeepsOriginalTimespan]="schedulerConfigResources.zoomKeepsOriginalTimespan"
            [hideHeaders]="false"
            [stripeFeature]="schedulerConfigResources.features.stripe"
            [readOnly]="true"
            [fillTicks]="schedulerConfigResources.fillTicks"
            [allowOverlap]="schedulerConfigResources.allowOverlap"
        ></bryntum-scheduler>
    </div>
</div>

<div class="overlay-spinner" *ngIf="(showScheduleSpinner$ | async) === false">
    <mat-spinner diameter="100"></mat-spinner>
</div>