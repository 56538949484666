import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SocaWebUiCommonModule } from '@ortec/soca-web-ui';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, first, map, switchMap, tap } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BolCalloutModule } from '@ortec/bolster/callout';
import { cache } from '@ortec/utilities/rxjs';

import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { SingleSelectTableComponent } from 'src/app/shared/components/inputs/single-select-table/single-select-table.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivityTypeInfo, ActivityTypeTimeslotInfoForTable } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { EditTemplatesManagementQuery } from '../../store/edit-template-management.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';

export interface IActivityTypeTimeslotsDialogData {
    selectedTimeslotIds?: Array<number>;
    manageMode: MANAGE_MODE;
    selectedActivityTypeId?: number;
}

@Component({
    standalone: true,
    selector: 'app-adt-activity-type-timeslots-dialog',
    templateUrl: './adt-activity-type-timeslots-dialog.component.html',
    styleUrls: ['./adt-activity-type-timeslots-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        SocaWebUiCommonModule,
        SharedModule,
        BolCalloutModule,

        MultiselectTreeComponent,
        SingleSelectTableComponent
    ],
})
export class AdtActivityTypeTimeslotsDialogComponent implements OnDestroy {
    public filteredActivityTypesSubject = new BehaviorSubject<Array<ActivityType>>([]);
    public selectedOrganizationUnitIdsSubject = new BehaviorSubject<Array<number>>([]);
    public selectedActivityTypeIdSubject = new BehaviorSubject<number>(null);
    public activityTypes: Array<ActivityType>;
    public columnDefinition = [];
    public searchProperties: Array<string> = ['displayName', 'shortName'];
    public displayedColumns: Array<string> = ['startTime', 'endTime', 'addToTemplate'];
    public dataSource = new MatTableDataSource<any>([]);
    public mode: MANAGE_MODE;
    public manageMode = MANAGE_MODE;
    public isDaymark$: Observable<boolean>;

    private timeslotsOfActivityTypeSubject = new BehaviorSubject<Array<ActivityTypeTimeslotInfoForTable>>([]);
    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogRef: MatDialogRef<number>,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly manageTimeslotQuery: ManageTimeslotQuery,
        private readonly organizationUnitsQuery: OrganizationUnitQuery,
        private readonly editTemplatesManagementQuery: EditTemplatesManagementQuery,
        @Inject(MAT_DIALOG_DATA) dialogData: IActivityTypeTimeslotsDialogData,
    ) { 
        this.mode = dialogData.manageMode;

        if (this.mode === MANAGE_MODE.ADD) {
            this.subscription.add(
                combineLatest([
                    this.activityTypeQuery.getMainActivityTypes(),
                    this.organizationUnits$
                ]).pipe(
                    filter(([activityTypes, organizationUnits]) => activityTypes !== undefined && activityTypes.length > 0 &&
                        organizationUnits !== undefined && organizationUnits.length > 0),
                    map(([activityTypes, _]) => {
                        return activityTypes.map(activityType => ({
                            ...activityType,
                            nameStyling: {
                                backgroundColor: '#' + activityType.backColor,
                                color: '#' + activityType.textColor
                            }
                        }));
                    }),
                    tap((activityTypes) => {
                        this.activityTypes = activityTypes;
                        this.filteredActivityTypesSubject.next(activityTypes);
                    })
                ).subscribe()
            );
        }

        if (this.mode === MANAGE_MODE.EDIT) {
            let selectedTimeslots = [];
            this.editTemplatesManagementQuery.getSelectedActivityTimeslotId().pipe(
                filter(id => id !== undefined && id !== 0),
                first()
            ).subscribe(id => {
                this.isDaymark$ = this.activityTypeQuery.isActivityTypeDaymark(id);
                this.selectedActivityTypeIdSubject.next(this.manageTimeslotQuery.getActivityTypeIdSync(id));
                selectedTimeslots = this.manageTimeslotQuery.getSelectedTimeslotIdsForActivityType(id);
            });
         
            this.activityTypeQuery.getActivityTypeTimeslotsForSelectedActivityType(this.selectedActivityTypeIdSubject.value, selectedTimeslots).pipe(first()).subscribe(checkedTimeslots => {
                this.timeslotsOfActivityTypeSubject.next(checkedTimeslots);
            });
        }

        this.columnDefinition.push( 
            {
                columnDisplayName: 'Short name',
                entityProperty: 'shortName',
                stylingProperty: 'nameStyling'
            },
            {
                columnDisplayName: 'Display name',
                entityProperty: 'displayName',
            }
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                selectedTimeslotIds: this.timeslotsOfActivityTypeSubject.getValue().filter(timeslot => timeslot.addToTemplate).map(timeslot => timeslot.id),
                manageMode: this.mode,
                selectedActivityTypeId: this.selectedActivityTypeIdSubject.value ?? null
            } as IActivityTypeTimeslotsDialogData
        });
    }

    public updateActivityTypeTableBasedOnOrganizationUnits(organizationUnitsIds: Array<number>): void {
        this.selectedOrganizationUnitIdsSubject.next(organizationUnitsIds);
        const organizationUnitsIdsSet = new Set(organizationUnitsIds);

        // Filter activity types based on selected organization units
        // If no organization units are selected, show all activity types
        let filteredActivityTypesBasedOnOrgUnitIds = this.activityTypes;
        if (organizationUnitsIds.length > 0) {
            filteredActivityTypesBasedOnOrgUnitIds = this.activityTypes.filter(activityType => organizationUnitsIdsSet.has(activityType.ownerOrganizationUnitId));
        }
    
        this.filteredActivityTypesSubject.next(filteredActivityTypesBasedOnOrgUnitIds);
        if (!filteredActivityTypesBasedOnOrgUnitIds.some(activityType => activityType.id === this.selectedActivityTypeIdSubject.value)) {
            this.selectedActivityTypeIdSubject.next(null);
        }
    }
    
    public onSelectedActivityTypeChange(activityTypeId: number): void {
        this.isDaymark$ = this.activityTypeQuery.isActivityTypeDaymark(activityTypeId);
        this.selectedActivityTypeIdSubject.next(activityTypeId);
        const selectedTimeslots = this.manageTimeslotQuery.getSelectedTimeslotIdsForActivityType(activityTypeId);
        
        this.activityTypeQuery.getActivityTypeTimeslotsForSelectedActivityType(activityTypeId, selectedTimeslots, true).pipe(first()).subscribe(checkedTimeslots => {
            this.timeslotsOfActivityTypeSubject.next(checkedTimeslots);
        });
    }

    public onUpdateTimeslot(state: MatCheckboxChange, timeslot: ActivityTypeTimeslotInfoForTable): void {
        const currentTimeslots = this.timeslotsOfActivityTypeSubject.getValue();
        const index = currentTimeslots.findIndex(t => t.id === timeslot.id);
  
        if (index !== -1) {
            currentTimeslots[index].addToTemplate = state.checked;
            this.timeslotsOfActivityTypeSubject.next(currentTimeslots);
        }
    }

    @Memoized public get timeslotsOfActivityType$(): Observable<Array<ActivityTypeTimeslotInfoForTable>> {
        return this.timeslotsOfActivityTypeSubject.asObservable().pipe(cache());
    }

    @Memoized public get activityTypesForFiltering$(): Observable<Array<ActivityType>> {
        return this.filteredActivityTypesSubject.asObservable().pipe(cache());
    }

    @Memoized public get selectedOrganizationIds$(): Observable<Array<number>> {
        return this.selectedOrganizationUnitIdsSubject.asObservable().pipe(cache());
    }

    @Memoized public get selectedActivityTypeId$(): Observable<number> {
        return this.selectedActivityTypeIdSubject.asObservable().pipe(cache());
    }

    @Memoized public get selectedActivityType$(): Observable<ActivityTypeInfo> {
        return this.editTemplatesManagementQuery.getSelectedActivityTimeslotId().pipe(
            filter(id => id !== undefined && id !== 0),
            switchMap(id => this.manageTimeslotQuery.getActivityTypeInfoByActivityTypeTimeslotId(id)),
            cache()
        );
    }

    @Memoized public get organizationUnits$(): Observable<Array<IOrganizationUnitTree>> {
        return this.organizationUnitsQuery.getOrganizationUnitsForActivityTypes().pipe(cache());
    }
}