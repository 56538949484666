import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { ManageCounterStore } from './manage-counter.store';
import { ManageCounterQuery } from './manage-counter.query';
import { ManageCounter } from './manage-counter.model';

@Injectable({
    providedIn: 'root'
})
export class ManageCounterService {
    constructor(
        private readonly http: HttpClient,
        protected readonly manageCounterStore: ManageCounterStore,
        protected readonly manageCounterQuery: ManageCounterQuery,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getCounters(): Observable<Array<ManageCounter>> {
        this.manageCounterStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<ManageCounter>>('/api/v1/ActivityDemand/Counters').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((counters: Array<ManageCounter>) => {
                this.manageCounterStore.set(counters);
                this.manageCounterStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public saveCounter(counter: ManageCounter): Observable<ManageCounter> {
        return this.http.post<ManageCounter>('/api/v1/ActivityDemand/Counters', counter).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                const lastCorrectVersionOfCounter = this.manageCounterQuery.getCounterSync(counter.id);

                return throwError(() => lastCorrectVersionOfCounter);
            }),
            tap((createdActivityDemandCounter: ManageCounter) => {
                this.manageCounterStore.upsert(createdActivityDemandCounter.id, createdActivityDemandCounter);
            }),
        );
    }

    public updateCounter(counter: ManageCounter, lastCorrectVersionOfCounter: ManageCounter): Observable<ManageCounter> {
        this.manageCounterStore.ui.update(counter.id, { isLoading: true, hasPendingChanges: false });
        //remove FE-only properties from object before sending to BE
        const counterForUpdate: ManageCounter = {
            id: counter.id,
            displayName: counter.displayName,
            daypartId: counter.daypartId,
            skillIds: counter.skillIds,
            resourceTypeId: counter.resourceTypeId,
            usedInActivityDemandTemplate: counter.usedInActivityDemandTemplate,
            usedInActivityPlan: counter.usedInActivityPlan
        };

        return this.http.put<ManageCounter>('/api/v1/ActivityDemand/Counters/' + counterForUpdate.id, counterForUpdate).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => lastCorrectVersionOfCounter);
            }),
            tap((updatedManageCounter: ManageCounter) => {
                this.manageCounterStore.upsert(counterForUpdate.id, updatedManageCounter);
                this.manageCounterStore.ui.update(counter.id, { isLoading: false, isFinishedSaving: true });
            }),
        );
    }

    public deleteCounter(counterId: number): Observable<void> {
        this.manageCounterStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/v1/ActivityDemand/Counters/' + counterId).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                
                return throwError(() => error);
            }),
            tap((response) => {
                this.manageCounterStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.manageCounterStore.remove(counterId);
                }
            }),
        ); 
    }
}
