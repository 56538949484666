import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { SortingPaletteState, SortingPaletteStore } from './sorting-palette.store';
import { SortableActivityType } from './sorting-palette.model';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';
import { StatusPageService } from 'src/app/shared/stores/status-page-store/status-page.service';

@Injectable({
    providedIn: 'root'
})
export class SortingPaletteService extends StatusPageService<SortingPaletteState> {
    constructor(
        protected sortingPaletteStore: SortingPaletteStore,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly errorDialogService: ErrorDialogService,
    ) { 
        super(sortingPaletteStore);
    }

    public getSortedActivityTypes(): Observable<Array<SortableActivityType>> {
        this.sortingPaletteStore.updateEntitiesLoading(true);

        return this.http.get<Array<SortableActivityType>>('/api/Users/SelectedActivityTypesFilterAndSorting').pipe(
            tap((activityTypesSelection) => {
                this.updateSortedActivityTypeIds(activityTypesSelection);
                this.sortingPaletteStore.updateEntitiesLoading(false);
            })
        );
    }

    public updateSortedActivityTypes(activityTypeSelection: Array<any>, selectedActivityTypeCategoryId: number): Observable<void> {
        this.sortingPaletteStore.updatePageStatusState(STATUS.IS_LOADING);

        return this.http.put<void>('/api/Users/SelectedActivityTypesFilterAndSorting/' + selectedActivityTypeCategoryId, activityTypeSelection).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('Error');
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);
                this.sortingPaletteStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.sortingPaletteStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public updateEntitiesLoading(state: boolean): void {
        this.sortingPaletteStore.updateEntitiesLoading(state);
    }

    public updateSortedActivityTypeIds(ids: Array<SortableActivityType>): void {
        this.sortingPaletteStore.updateSortedActivityTypeIds(ids);
    }

    public updateSelectedActivityTypeCategoryId(id: number): void {
        this.sortingPaletteStore.updateSelectedActivityTypeCategoryId(id);
    }

    public updateApplySortState(state: boolean): void {
        this.sortingPaletteStore.updateApplySortState(state);
    }
}
