<mat-card  class="bol-card content-wrapper">
    <mat-card-content>
        <div class="content-wrapper">
            <div class="flex-wrapper-card">
                <soca-menu-card
                    *ngFor= "let item of menuItems"
                    [url]="item.url"
                    [title]="item.title" 
                    [icon]="item.icon"
                >
                </soca-menu-card>
            </div>
        </div>
    </mat-card-content>
</mat-card>
