/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { fieldNonEmptyValidatorKey } from '@ortec/soca-web-ui/validators';

import { Permission } from 'src/app/shared/models/Enums';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { UserGroup } from 'src/app/shared/stores/user-group-store/user-group.model';

export function getOrganizationUnitsFormFields(userGroups: Array<UserGroup>,organizationUnitsTrees: Array<IOrganizationUnitTree>, selectedEntityId: number): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'fte',
            type: 'bolster-input',
            props: {
                label: 'Number of hours equal to 1 fte',
                maxLength: 40,
                type: 'number',
                min: 0,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'parentId',
            type: 'tree-single-select',
            props: {
                entities: organizationUnitsTrees,
                placeholderText: 'Parent organization unit',
                hiddenEntityId: selectedEntityId
            }
        },
        {
            key: 'userGroupPermissionList',
            type: 'permissions-tree',
            props: {
                title: 'Permissions',
                entities: userGroups,
                entityName: 'User groups',
                columnDefinition: [Permission.read, Permission.readWrite, Permission.owner]
            }
        }
    ];
}
