import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OmrpAuthGuard } from 'src/app/core/authentication/omrp-authentication.guard';
import { PermissionsGuard } from 'src/app/shared/guards/permissions.guard';
import { PERMISSIONS } from 'src/app/shared/models/Enums';

import { ApplicationSettingsComponent } from './application-settings.component';
import { SettingsDaypartsComponent } from './components/settings-dayparts/settings-dayparts.component';

export const applicationSettingsRoutes: Routes = [
    {
        path: 'application-settings',
        canActivate: [ OmrpAuthGuard, PermissionsGuard],
        data: {
            permission: PERMISSIONS.MANAGE_APPLICATION_SETTINGS,
        },
        children: [
            {
                path: '',
                component: ApplicationSettingsComponent,
                data: {
                    breadcrumbs: [
                        {
                            caption: 'Application Settings',
                        },
                    ],
                },
            },
            {
                path: 'dayparts',
                component: SettingsDaypartsComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/application-settings',
                            caption: 'Application Settings',
                        },
                        {
                            caption: 'Dayparts'
                        },
                    ],
                },
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(applicationSettingsRoutes)],
    exports: [RouterModule],
})
export class SettingsRoutingModule {}
