/* eslint-disable @typescript-eslint/ban-types */
import { Observable } from "rxjs";

export interface FilterEntityManageGridColumn {
	columnDef: string;
	header: string;
	stylingProperty?: string;
	filterType?: FILTER_TYPE;
	filterOptions?: FilterEntityManageGridDetails;
	cell: (element: any) => any;
	class?: (element: any) => string;
	sort?: {
		direction: 'asc' | 'desc';
	};
	sortValue?: (element: any) => any;
	icon?: (element: any) => string;
}

export enum FILTER_TYPE {
    SEARCH = 'SEARCH',
    MULTISELECT = 'MULTISELECT',
}

export interface FilterEntityManageGridDetails {
	entities?: Array<any>;
	name?: string;
	selectedIds$?: Observable<Array<number>>;
	translationNeeded?: boolean;
}

export interface EntityManageGridMenuOptions {
    label: string;
    click?: (entity) => {};
    showProperty?: boolean;
    icon?: string;
}

export enum STATUS_REQUEST_FINISHED {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export interface RequestFinished {
	statusAddRequest?: STATUS_REQUEST_FINISHED;
	statusEditRequest?: STATUS_REQUEST_FINISHED;
	statusDeleteRequest?: STATUS_REQUEST_FINISHED;
}

export interface UpdatedEntityStructure {
	lastCorrectVersionOfEntity: any,
	newVersionOfEntity: any
}

export interface MultiselectFilterSelection {
	value: Array<any>,
	column: string
}
