import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { Permission } from 'src/app/shared/models/Enums';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { PermissionQuery } from 'src/app/shared/stores/permission-store/permission.query';
import { UserGroup } from 'src/app/shared/stores/user-group-store/user-group.model';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';
import { User } from 'src/app/shared/stores/user-store/user.model';
import { UserQuery } from 'src/app/shared/stores/user-store/user.query';

import { UserGroupsManagementState, UserGroupsManagementStore } from './user-groups-management.store';

@Injectable({
    providedIn: 'root'
})
export class UserGroupsManagementQuery extends Query<UserGroupsManagementState> {
    constructor(
        protected store: UserGroupsManagementStore,
        protected permissionQuery: PermissionQuery,
        protected userGroupQuery: UserGroupQuery,
        protected userQuery: UserQuery,
        private readonly translateService: TranslateService,
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return combineLatest([
            this.permissionQuery.getEntitiesLoadingState(),
            this.userGroupQuery.getEntitiesLoadingState(),
            this.userQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([permissionsLoading, userGroupsLoading, usersLoading]) => {
                return !permissionsLoading && !userGroupsLoading && !usersLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }

    public getSelectedUserGroup(): Observable<UserGroup | any> {
        return this.getCurrentUserGroupId().pipe(
            switchMap((userGroupId) => {
                if (userGroupId === -1) {
                    return of(this.getNewUserGroupObject());
                }
                else {
                    return this.userGroupQuery.getUserGroup(userGroupId);
                }
            })
        );
    }

    public getSelectedUserGroupSync(): UserGroup | any {
        const selectedUserGroupId = this.getCurrentUserGroupIdSync();

        if (selectedUserGroupId === -1) {
            return this.getNewUserGroupObject();
        }
        else {
            return this.userGroupQuery.getUserGroupSync(selectedUserGroupId);
        }
    }

    public getNewUserGroupObject(): UserGroup {
        return {
            id: -1,
            displayName: this.translateService.instant('New user group'),
        };
    }

    public getselectedUIUserGroup(): Observable<EntityUI> {
        return this.getCurrentUserGroupId().pipe(
            switchMap((userGroupId) => {
                return this.userGroupQuery.getUIUserGroup(userGroupId);
            })
        );
    }

    public getCurrentUserGroupId(): Observable<number> {
        return this.select(state => state.selectedUserGroupId);
    }

    public getCurrentUserGroupIdSync(): number {
        return this.getValue().selectedUserGroupId;
    }

    public getUsersForSelectedUserGroup(): Observable<Array<User>> {
        return this.select(state => state.selectedUsers);
    }

    public getCanDeleteSelectedEntityState(): Observable<boolean> {
        return combineLatest([
            this.getSelectedUserGroup(),
            this.getUsersForSelectedUserGroup()
        ]).pipe(
            map(([userGroup, usersForSelectedUserGroup]) =>
                userGroup?.maxPermissionForCurrentUser >= Permission.owner &&
                this.userGroupQuery.getAllChildrenForUserGroupSync(userGroup.id)?.length === 0 &&
                (!usersForSelectedUserGroup || usersForSelectedUserGroup?.length === 0))
        );
    }

    public getCanCloneSelectedEntityState(): Observable<boolean> {
        return this.getSelectedUserGroup().pipe(
            map(userGroup => userGroup?.maxPermissionForCurrentUser >= Permission.owner)
        );
    }
}
