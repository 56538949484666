import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';
import { CountersFiltersService } from '../../counters-helpers/counters-filters.service';

import { COUNTERS_STORAGE_KEYS } from '../../counters-helpers/enums';
import { CountersFilterSetting } from '../../stores/counters-store/counters-request-parameters.model';
import { CountersQuery } from '../../stores/counters-store/counters.query';
import { CountersService } from '../../stores/counters-store/counters.service';

@Component({
    selector: 'app-counters-filter-settings',
    templateUrl: './counters-filter-settings.component.html',
    styleUrls: ['./counters-filter-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountersFilterSettingsComponent implements OnInit, OnDestroy {
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public filterChangesInPending$!: Observable<boolean>;
    public countersFiltersValidity$!: Observable<boolean>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly countersService: CountersService,
        private readonly countersQuery: CountersQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly countersFiltersService: CountersFiltersService
    ) { }

    public ngOnInit(): void {
        this.filterSettingService.getFilterSettings().pipe(first()).subscribe(() => {
            const countersFilterSettings = this.filterSettingQuery.getFilterSettingsByTypeSync(FILTER_SETTING_TYPE.COUNTER_OVERVIEW_FILTER_SETTING);

            this.setFilterSettingFromLocalStorage(countersFilterSettings);
        });

        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.COUNTER_OVERVIEW_FILTER_SETTING);
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();
        this.filterChangesInPending$ = combineLatest([
            this.countersQuery.getCountersFiltersValidity(),
            this.countersQuery.getUIState(),
            this.filterSettingQuery.filterSettingsUpdated()
        ]).pipe(map(([valid, _]) => this.countersQuery.currentFilterSettingChangesUnsavedSync() && valid));
        this.countersFiltersValidity$ = this.countersQuery.getCountersFiltersValidity();

        this.subscription.add(
            this.filterSettingQuery.getFilterSettingBySelectedId().pipe(
                tap(filterSetting => {
                    if (!filterSetting) {
                        this.countersService.updateSelectedFilterSettingId(undefined);
                    } else {
                        const settings: CountersFilterSetting = JSON.parse(filterSetting.settings);
                        const start = this.countersQuery.getSelectedStartDateSync();
                        const end = this.countersQuery.getSelectedEndDateSync();
                        const scenarioId = this.countersQuery.getSelectedScenarioIdSync();

                        this.countersService.updateCountersStateOnFilterSettingChanged({
                            ui: {
                                selectedFilterSettingId: filterSetting.id,
                                validIntervalDates: true,
                                scenarioId,
                                start,
                                end,
                                error: false,
                                ...settings
                            },
                            sliderValues: {
                                dataColumnWidth: settings.dataColumnWidth,
                                nameColumnWidth: settings.nameColumnWidth,
                                totalTableWidth: settings.totalTableWidth
                            }
                        });
                    }
                })
            ).subscribe()
        );
    }

    public onSelectedFilterSettingIdChanged(id: number): void {
        this.filterSettingService.updateSelectedFilterSettingsId(id);

        if (id) {
            this.countersFiltersService.setCountersItemInStorage(COUNTERS_STORAGE_KEYS.FILTER_SETTING_ID, id);
        }
    }

    public onRemoveFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.deleteFilterSetting(setting).pipe(first()).subscribe();
    }

    public onUpdateFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.updateFilterSetting(setting).pipe(first()).subscribe();
    }

    public onSaveNewFilterSettingEvent(settingName: string): void {
        const newFilterSetting = this.countersQuery.getNewFilterSetting(settingName);

        this.filterSettingService.createFilterSetting(newFilterSetting).pipe(first()).subscribe();
    }

    public onOverwriteSelectedFilterSettingEvent(): void {
        const updatedFilterSetting = this.countersQuery.getUpdatedFilterSetting();

        this.filterSettingService.updateFilterSetting(updatedFilterSetting).pipe(first()).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private setFilterSettingFromLocalStorage(filterSettings: Array<FilterSetting>): void {
        const filterSettingId = this.countersFiltersService.getCountersItemFromStorage<number>(COUNTERS_STORAGE_KEYS.FILTER_SETTING_ID);

        if (filterSettingId && filterSettings.map(setting => setting.id).includes(filterSettingId)) {
            this.filterSettingService.updateSelectedFilterSettingsId(filterSettingId);
        } else {
            this.countersService.updateSelectedFilterSettingId(undefined);
        }
    }
}
