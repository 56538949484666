import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { OwsDuty } from '../ows-duty-set-store/ows-duty-set.model';
import { ActivityTypeMapping } from './activity-type-mapping.model';
import { ActivityTypeMappingQuery } from './activity-type-mapping.query';
import { ActivityTypeMappingStore } from './activity-type-mapping.store';

@Injectable({ providedIn: 'root' })
export class ActivityTypeMappingService {
    constructor(
        protected activityTypeMappingStore: ActivityTypeMappingStore,
        private readonly activityTypeMappingQuery: ActivityTypeMappingQuery,
        private readonly http: HttpClient
    ) { }

    public get(): Observable<void> {
        return this.http.get<Array<ActivityTypeMapping>>('/api/OwsInterface/OwsActivityTypeMapping').pipe(
            map((activityTypeMappings) => {
                this.activityTypeMappingStore.set(activityTypeMappings);
            }));
    }

    public updateMappings(owsDuty: OwsDuty): void {
        let activityTypeMappings = this.activityTypeMappingQuery.getAll();
        const activityTypeId = this.activityTypeMappingQuery.getValue().ui.selectedActivityTypeId;
        const updatedMapping: ActivityTypeMapping = {
            activityTypeId,
            owsDutyId: owsDuty.id
        };

        if (owsDuty.linkedOmrpActivityType !== null) {
            activityTypeMappings = activityTypeMappings.filter(mapping =>
                !(mapping.activityTypeId === activityTypeId && mapping.owsDutyId === owsDuty.id)
            );
        }
        else if (!activityTypeMappings.includes(updatedMapping)) {
            activityTypeMappings.push(updatedMapping);
        }
        // TODO: set in server with timeout to collect calls
        this.http.post<void>('/api/OwsInterface/OwsActivityTypeMapping', activityTypeMappings).subscribe();
        this.activityTypeMappingStore.set(activityTypeMappings);
    }

    public updateSelectedActivityId(id: number): void {
        this.activityTypeMappingStore.updateSelectedActivityTypeId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.activityTypeMappingStore.updateSelectedOrganizationUnitId(id);
    }

    public updateShowChildUnits(value: boolean): void {
        this.activityTypeMappingStore.updateShowChildUnits(value);
    }

    public updateShowPastActivityTypes(value: boolean): void {
        this.activityTypeMappingStore.updateShowPastActivityTypes(value);
    }

    public updateShowFutureActivityTypes(value: boolean): void {
        this.activityTypeMappingStore.updateShowFutureActivityTypes(value);
    }

    public toggleUnlinkedOwsDutyVisibility(): void {
        this.activityTypeMappingStore.toggleUnlinkedOwsDutyVisibility();
    }

    public updateSelectedDutySet(id: number): void {
        this.activityTypeMappingStore.updateSelectedDutySet(id);
    }

    public updateSelectedActivityTypeCategory(id: number): void {
        this.activityTypeMappingStore.updateSelectedActivityTypeCategory(id);
    }
}
