import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { ActivityType } from '../../stores/activity-type-store/activity-type.model';
import { ActivityTypeSortingDialogComponent, IActivityTypeSortingModalData } from './components/activity-type-sorting-dialog.component';

@Component({
    selector: 'app-activity-type-sorting',
    templateUrl: './activity-type-sorting.component.html',
    styleUrls: ['./activity-type-sorting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeSortingComponent implements OnDestroy, OnInit {
    @Output() public readonly updateSortedActivityTypeIdsEvent = new EventEmitter<Array<number>>();

    @Input() public selectedActivityTypeIds: Array<number>;
    @Input() public sortedActivityTypeIds: Array<number>;
    @Input() public dialogTitle: string;
    @Input() public buttonTitle: string;
    @Input() public entityName: string;
    @Input() private set activityTypes(actTypes: Array<ActivityType>) {
        this.activityTypesSubject.next(actTypes);
    };

    public activityTypes$: Observable<Array<ActivityType>>;

    private readonly activityTypesSubject = new BehaviorSubject<Array<ActivityType>>(undefined);

    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: MatDialog,
    ) { }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.activityTypes$ = this.activityTypesSubject.asObservable();
    }
    
    public onDisplaySelectionDialog(): void {
        const dialogRef = this.dialogService.open(ActivityTypeSortingDialogComponent, {
            data: {
                activityTypes$: this.activityTypes$,
                selectedActivityTypeIds: this.selectedActivityTypeIds,
                sortedActivityTypeIds: this.sortedActivityTypeIds,
                dialogTitle: this.dialogTitle,
                entityName: this.entityName
            } as IActivityTypeSortingModalData,
            panelClass: 'dialog-large',
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IActivityTypeSortingModalData }) => {
                    if (dialogData) {
                        this.updateSortedActivityTypeIdsEvent.next(dialogData.data.sortedActivityTypeIds);
                    }
                })
            ).subscribe()
        );
    }
}
