<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true">
                    <app-filter-tree-single-select 
                        [entities]="organizationsForFiltering$ | async"
                        [labelText]="'Organization unit'"
                        [selectedEntityId] = "selectedOrganizationUnit$ | async"
                        (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                        [dataCyLabel]="'organizationUnitFilter'">
                    </app-filter-tree-single-select>
    
                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showUnderlyingUnits$ | async"
                            (change)="onUpdateShowChildUnits($event)"
                            data-cy="showResourceTypesForUnderlyingOrganizationUnits"
                        >{{ 'Also show resource types for underlying organization units' | translate }}</mat-checkbox>
                    </div>
                </app-entity-filter-panel>
            </div>
        </div>
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true">
                    <mat-button-toggle-group #viewMode="matButtonToggleGroup" [value]="defaultToggle" class="bol-button-toggle-group">
                        <mat-button-toggle 
                            class="toggle-button-text" 
                            [value]="defaultToggle" 
                            (change)="onToggleChange($event.value)"
                            data-cy="defaultMapping">
                            <span class="text"translate>Default mapping</span>
                        </mat-button-toggle>
                        <mat-button-toggle 
                            class="toggle-button-text" 
                            [value]="departmentSpecificToggle" 
                            (change)="onToggleChange($event.value)"
                            data-cy="specificMapping">
                            <span class="text"translate>Department specific mapping</span>
                            <mat-icon class="icon-specific-mapping">business</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>

                    <app-filter-tree-single-select 
                        [entities]="owsDepartmentsForFiltering$ | async"
                        [labelText]="'OWS department'"
                        [selectedEntityId] = "selectedOwsDepartment$ | async"
                        (selectedEntityIdChanged)="onSelectedOwsDepartmentChange($event)"
                        [dataCyLabel]="'departmentFilterOWS'">
                    </app-filter-tree-single-select>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showAllOwsResourceTypes$ | async"
                            (change)="onShowAllOwsResourceTypesChange($event)"
                            data-cy="showOwsPositionsFromAllDepartments"
                        >{{ 'Show OWS Positions from all Departments' | translate }}</mat-checkbox>
                    </div>
    
                    <br/>
    
                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="hideUnlinkedOwsResourceTypes$ | async"
                            (change)="onHideUnlinkedOwsResourceTypesChange($event)"
                            data-cy="hideLinkedPositions"
                        >{{ 'Hide linked Positions' | translate }}</mat-checkbox>
                    </div>
    
                    <br/>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showLinkedOwsResourceTypes$ | async"
                            (change)="onShowLinkedOwsResourceTypesChange($event)"
                            data-cy="onlySelectedPositions"
                        >{{ 'Show linked Positions' | translate }}</mat-checkbox>
                    </div>
    
                    <br/>
                    
                    <span class="unlinked__title">{{'Unlinked Positions' | translate}}:</span>
                    <div class="bol-badge-solid"
                        data-cy="numberUnlinkedPositions"
                        [ngClass]="{'bol-warning': (unlinkedResourceTypeCount$ | async) > 0, 'bol-primary': (unlinkedResourceTypeCount$ | async) === 0}">
                        {{unlinkedResourceTypeCount$ | async}}
                    </div>
   
                    <p data-cy="noSelectionWarningMessage" *ngIf="(selectedResourceTypeId$ | async) === undefined && (visibleOwsResourceTypes$ | async)?.length > 0">
                        <mat-icon class="inline-icon">warning</mat-icon>
                        <span translate>Please select an OMRP Resource Type from the left panel to map to!</span>
                    </p>
    
                    <div *ngIf="currentMapping === departmentSpecificToggle">
                        <hr class="line">
                        <p class="bol-text bol-text-medium" translate>Select OWS department for mapping</p>
                        <app-filter-tree-single-select 
                            [entities]="owsDepartmentsForFiltering$ | async"
                            [labelText]="'OWS department'"
                            [selectedEntityId] = "selectedOwsDepartmentForMapping$ | async"
                            (selectedEntityIdChanged)="onSelectedOwsDepartmentSpecificMappingChange($event)"
                            required
                            [dataCyLabel]="'departmentFilterOWS'">
                        </app-filter-tree-single-select>
                        <p data-cy="noSelectionOwsDepartmentWarningMessage" *ngIf="(selectedOwsDepartmentIdForMapping$ | async) === undefined">
                            <mat-icon class="inline-icon">warning</mat-icon>
                            <span translate>Please select an OWS department from the list!</span>
                        </p>
                    </div>

                </app-entity-filter-panel>
            </div>
        </div>
    </div>

    <div class="flex-wrapper">
        <div class="flex-column" >
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OMRP Resource Types"
                    [entities]="visibleResourceTypes$ | async"
                    [selectedEntityId]="selectedResourceTypeId$ | async"
                    hideAddButton="true"
                    [icon]="icon"
                    [popoverMessage]="popoverMessage"
                    (selectedEntityIdChanged)="onSelectResourceType($event)"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'resourceTypesOMRP'"
                >
                </app-entity-list-panel>
            </div>
        </div>
        
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OWS Positions"
                    [entities]="visibleOwsResourceTypes$ | async"
                    (selectionClickChanged)="onSelectOwsResourceTypeForMapping($event)"
                    (selectAllClicked)="onSelectAllOwsResourceTypeForMapping($event)"
                    showCheckboxes="true"
                    hideAddButton="true"
                    enableSelectAll="true"
                    [selectAllDisabled]="enableSelectAllPosition$ | async"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'positionsOWS'"
                >
                </app-entity-list-panel>
            </div>
        </div>
    </div>
</div>
