<app-filter-tree-single-select 
    [entities]="organizationUnits$ | async"
    [labelText]="'Organization unit'"
    [selectedEntityId] = "selectedOrganizationUnit$ | async"
    (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
    data-cy="OrganizationUnitFilter">
</app-filter-tree-single-select>

<div>
    <mat-checkbox
        class="bol-checkbox"
        [checked]="showUnderlyingUnits$ | async"
        (change)="onUpdateShowChildUnits($event)"
        data-cy="ShowForUnderlyingOrganizationUnits"
    >{{ 'Also show resource types for underlying organization units' | translate }}</mat-checkbox>
</div>