import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Memoized } from '@ortec/utilities/core';
import { Observable, combineLatest, filter, first, map, startWith, take } from 'rxjs';

import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';
import { OwsOtherSettingsService } from './ows-other-settings-store/ows-other-settings.service';
import { OwsOtherSettingsQuery } from './ows-other-settings-store/ows-other-settings.query';

@Component({
    selector: 'app-ows-other-settings',
    templateUrl: './ows-other-settings.component.html',
    styleUrls: ['./ows-other-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsOtherSettingsComponent implements OnInit {

    constructor(
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly owsOtherSettingsQuery: OwsOtherSettingsQuery,
        private readonly owsOtherSettingsService: OwsOtherSettingsService,
    ){}

    public ngOnInit(): void {
       this.initCallsForDataRetrieval();
    }

    public onSelectedDaymarkForLeaveShiftChange(id: number): void {
        this.owsOtherSettingsService.updateSelectedDaymarkIdForLeaveShift(id);
        this.owsOtherSettingsService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
        this.owsOtherSettingsService.updateActivityTypeForShiftReplacedByLeave(id).pipe(first()).subscribe();
    }

    public onSelectedDaymarkForSickLeaveChange(id: number): void {
        this.owsOtherSettingsService.updateSelectedDaymarkIdForSickShift(id);
        this.owsOtherSettingsService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
        this.owsOtherSettingsService.updateActivityTypeForShiftReplacedBySick(id).pipe(first()).subscribe();
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
           this.owsOtherSettingsQuery.getLoadingStateForActivityTypeForShiftReplacedByLeave(),
           this.owsOtherSettingsQuery.getLoadingStateForActivityTypeForShiftReplacedBySick(),
           this.activityTypeQuery.getEntitiesLoadingState()
        ]).pipe(
            filter(([loadingStateForActivityTypeForShiftReplacedByLeave, loadingStateForActivityTypeForShiftReplacedBySick, loadingState]) => {
                return !loadingStateForActivityTypeForShiftReplacedByLeave && !loadingStateForActivityTypeForShiftReplacedBySick && !loadingState;
            }),
            map(() => true),
            take(1),
            startWith(false)
        );
    }

    @Memoized public get selectedDaymarkForLeaveShift$(): Observable<number> {
        return this.owsOtherSettingsQuery.getCurrentDaymarkIdForLeaveShiftId();
    }

    @Memoized public get activityTypes$(): Observable<Array<ActivityType>> {
        return this.owsOtherSettingsQuery.getMutableActivityTypesFilteredByDaymark()
    }

    @Memoized public get selectedDaymarkForSickLeaveShift$(): Observable<number> {
        return this.owsOtherSettingsQuery.getCurrentDaymarkIdForSickShiftId();
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return this.owsOtherSettingsQuery.getStatusPageState();
    }

    private initCallsForDataRetrieval(): void {
        this.owsOtherSettingsService.getActivityTypeForShiftReplacedByLeave().pipe(first()).subscribe();
        this.owsOtherSettingsService.getActivityTypeForShiftReplacedBySick().pipe(first()).subscribe();
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe();
    }
}