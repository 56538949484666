/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unassigned-import */
import './create-nonce.js';

import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NavigationStart, Router } from '@angular/router';
import {
    getSingleSpaExtraProviders,
    singleSpaAngular,
} from 'single-spa-angular';
import { ReplaySubject } from 'rxjs';
import { AppProps } from 'single-spa';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production || environment.acceptance) {
    enableProdMode();
}

const singleSpaPropsSubject = new ReplaySubject<SingleSpaProps>(1);
type SingleSpaProps = AppProps & {};

const lifecycles = singleSpaAngular({
    bootstrapFunction: (singleSpaProps) => {
        singleSpaPropsSubject.next(singleSpaProps);

        return platformBrowserDynamic(getSingleSpaExtraProviders()).bootstrapModule(
            AppModule
        );
    },
    template: '<app-root />',
    Router,
    NavigationStart,
    NgZone,
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
