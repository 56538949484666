<div class="language-selector__container">
  <mat-button-toggle-group
    name="language"
    class="bol-button-toggle-group language-selector__toggle"
    [value]="currentLanguageCode$ | async">

    <mat-button-toggle
      *ngFor="let language of languages"
      (click)="selectedLanguageChange(language)"
      [value]="language.code"
    >
      {{ language.name | uppercase }}
    </mat-button-toggle>

  </mat-button-toggle-group>
</div>
