<div class="filters-header">
    <ng-content select="[filtersHeader]"></ng-content>
</div>
<div class="filters-content" [ngClass]="{ invisible: (showFilters$ | async) === false }">
    <ng-content select="[filtersContent]"></ng-content>
</div>

<div class="more-details-button-container">
    <button mat-button type="button" class="more-details-button gray" [class.expanded]="showFilters$ | async"
        (click)="toggleShowFilters()">
        {{ ((showFilters$ | async) ? collapsePanelMessage : expandPanelMessage) | translate }}
        <mat-icon [class.expanded]="showFilters$ | async" iconPositionEnd>keyboard_arrow_down</mat-icon>
    </button>
</div>