<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="changes-panel"> 
    <h3 class="changes-filter">Filters</h3>
    <div class="content">
        <div class="period-label-row">
            <p class='bol-text bol-text-regular period-actions-label'>{{'Search period actions' | translate}}</p>
            <p class='bol-text bol-text-regular period-activities-label'>{{'Search period activities' | translate}}</p>
        </div>
        <div class="period-dates">
            <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field start-time-change" appearance="fill">
                <mat-label>{{ 'Start date' | translate }}</mat-label>
                <input 
                    #actionFromDateInput 
                    matInput 
                    [matDatepicker]="actionFromDatePicker" 
                    required
                    [min]="getMinDateStartDate()"
                    [max]="currentDate"
                    [formControl]="dateTimeControls.actionFromDate"
                    data-cy="changesStartDate">
                <mat-datepicker-toggle matSuffix [for]="actionFromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #actionFromDatePicker (closed)="actionFromDateClosed()">
                </mat-datepicker>
                <mat-error *ngIf="dateTimeControls.actionFromDate.invalid">
                    {{ getDateErrorMessage(actionFromDateInput) | translate }}
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field end-time-change bol-form-field dynamic-subscript-height" appearance="fill">
                <mat-label>{{ 'End date' | translate }}</mat-label>
                <input 
                    #actionTillDateInput 
                    matInput 
                    [matDatepicker]="actionTillDatePicker" 
                    required
                    [min]="dateTimeControls.actionFromDate.value" 
                    [max]="getMaxDateForActionEndDate()"
                    [formControl]="dateTimeControls.actionTillDate"
                    data-cy="changesEndDate">
                <mat-datepicker-toggle matSuffix [for]="actionTillDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #actionTillDatePicker>
                </mat-datepicker>
                <mat-error *ngIf="dateTimeControls.actionTillDate.invalid && !dateTimeControls.actionTillDate.errors?.matDatepickerMin">
                    {{ getDateErrorMessage(actionTillDateInput) | translate }}
                </mat-error>
                <mat-error *ngIf="dateTimeControls.actionTillDate.errors?.matDatepickerMin">{{ 'The end date is before the start date' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field start-time-change" appearance="fill">
                <mat-label>{{ 'Start date' | translate }}</mat-label>
                <input 
                    #activityFromDateInput 
                    matInput 
                    [matDatepicker]="activityFromDatePicker" 
                    [min]="getMinDateStartDate()"
                    [formControl]="dateTimeControls.activityFromDate"
                    data-cy="changesActivityStartDate">
                <mat-datepicker-toggle matSuffix [for]="activityFromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #activityFromDatePicker (closed)="activityFromDateClosed()">
                </mat-datepicker>
                <mat-error *ngIf="dateTimeControls.activityFromDate.invalid">
                    {{ getDateErrorMessage(activityFromDateInput) | translate }}
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field end-time-change bol-form-field dynamic-subscript-height" appearance="fill">
                <mat-label>{{ 'End date' | translate }}</mat-label>
                <input 
                    #activityTillDateInput 
                    matInput 
                    [matDatepicker]="activityTillDatePicker" 
                    [min]="getMinDateForActivityEndDate()" 
                    [formControl]="dateTimeControls.activityTillDate"
                    data-cy="changesActivityEndDate">
                <mat-datepicker-toggle matSuffix [for]="activityTillDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #activityTillDatePicker>
                </mat-datepicker>
                <mat-error *ngIf="dateTimeControls.activityTillDate.invalid && !dateTimeControls.activityTillDate.errors?.matDatepickerMin">
                    {{ getDateErrorMessage(activityTillDateInput) | translate }}
                </mat-error>
                <mat-error *ngIf="dateTimeControls.activityTillDate.errors?.matDatepickerMin">{{ 'The end date is before the start date' | translate }}</mat-error>
            </mat-form-field>
            <button
                mat-button
                class="redirect-button bol-button-solid bol-blue"
                [disabled]="dateTimeControls.actionTillDate.invalid || dateTimeControls.actionFromDate.invalid || dateTimeControls.activityTillDate.invalid || dateTimeControls.activityFromDate.invalid"
                (click)="onShowChanges()"
                data-cy="showChangesButton">
                <span>{{ 'Show changes' | translate }}</span>
            </button>
        </div>

        <mat-divider></mat-divider>
        
        <div class="changes-table">
            <app-changes-table
                [columns]="columns"
                [entities]="entities$ | async">
            </app-changes-table>
        </div>
    </div>
</div>
