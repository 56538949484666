import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DAY_OPTIONS } from './enums';

export interface DayOptions {
    type: DAY_OPTIONS;
    value: number;
}

export interface WeekOptions {
    type: string;
    value: number;
}

@Injectable({
    providedIn: 'root'
})
export class AdtHelperService {
    private readonly translateService = inject(TranslateService);

    public getDayOptions(): Array<DayOptions> {
        const dayOptions = [
            { type: DAY_OPTIONS.MONDAY, value: 2 },
            { type: DAY_OPTIONS.TUESDAY, value: 3 },
            { type: DAY_OPTIONS.WEDNESDAY, value: 4 },
            { type: DAY_OPTIONS.THURSDAY, value: 5 },
            { type: DAY_OPTIONS.FRIDAY, value: 6 },
            { type: DAY_OPTIONS.SATURDAY, value: 7 },
            { type: DAY_OPTIONS.SUNDAY, value: 1 },
        ];

        return dayOptions;
    }

    public getWeekOptions(numberOfWeeks: number): Array<WeekOptions> {
        const weekOptions: Array<WeekOptions> = [];

        for (let week = 1; week <= numberOfWeeks; week++) {
            weekOptions.push({
                type: `${this.translateService.instant('Week')} ${week}`,
                value: week
            });
        }
        
        return weekOptions;
    }
}
