import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BolSelectMultiTextConfiguration } from '@ortec/bolster/select';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BolMultiselectLabelsService {
    public selectLabels$!: Observable<BolSelectMultiTextConfiguration>;

    constructor(
        translateService: TranslateService
    ) {
        this.selectLabels$ = translateService.onLangChange.pipe(
            startWith(1),
            map(() => {
                const selectedLabel = translateService.instant('Selected #count: ');
                const labels: BolSelectMultiTextConfiguration = {
                    noOptions: translateService.instant('No values found'),
                    selectAll: translateService.instant('Select all'),
                    someSelected:  { label: selectedLabel },
                    singleSelected:  { label: selectedLabel },
                    noSearchResults: translateService.instant('No values found'),
                };
    
                return labels;
            }),
        );
    }
}
