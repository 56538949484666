/* eslint-disable @typescript-eslint/naming-convention */
export enum COUNTERS_GROUPING_OPTIONS {
    ACTIVITY_TYPE_CATEGORY = 'Activity type category',
    ORGANIZATION_UNIT = 'Organization unit',
    RESOURCE_TYPE = 'Resource type',
    SKILL = 'Skill',
    ACTIVITY_TYPE = 'Activity type',
    DAY_PART = 'Daypart'
}

export enum COUNTERS_EXTRA_GROUPING_OPTIONS {
    RESOURCE = 'Resource'
}

export enum COUNTERS_TOTALS_OPTIONS {
    DAY = 'Day',
    WEEK = 'Week',
    MONTH = 'Month',
    QUARTERS = 'Quarters',
    HALF_YEAR = 'Half year',
    YEAR = 'Year',
    TOTAL = 'Total'
}

export enum COUNTERS_COUNTER_TYPE {
    NUMBER_ACTIVITIES_SCHEDULE = 'Number of activities (schedule)',
    NUMBER_ACTIVITIES_SCENARIO = 'Number of activities (scenario)',
    NUMBER_ACTIVITY_HOURS_SCHEDULE = 'Number of activity hours (schedule)',
    NUMBER_ACTIVITY_HOURS_SCENARIO = 'Number of activity hours (scenario)',
}

export const enum COUNTERS_STORAGE_KEYS {
    FILTER_SETTING_ID = 'countersFilterSettingId',
    FILTERS_PANEL_STATE = 'countersFiltersPanelState',
}
