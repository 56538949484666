import { Injectable } from '@angular/core';
import { EntityState, EntityStore,  EntityUIStore,  StoreConfig } from '@datorama/akita';
import { EntityUI, EntityUIState, getDefaultUIState } from '../entity-ui-models';

import { OrganizationUnit } from './organization-unit.model';

export interface OrganizationUnitState extends EntityState<OrganizationUnit> {
    ui: {
        expandedIds: Array<number>;
        selectedId: number;
        filteredIds: Array<number>;
        entitiesLoading: boolean;
        entitiesLoadingForActivityTypes: boolean;
    };
    organizationUnitsForActivityTypes: Array<OrganizationUnit>;
}

const initialState = {
    ui: {
        expandedIds: [],
        selectedId: 0,
        filteredIds: [],
        entitiesLoading: false,
        entitiesLoadingForActivityTypes: false,
    },
    organizationUnitsForActivityTypes: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit' })
export class OrganizationUnitStore extends EntityStore<OrganizationUnitState> {
    public ui: EntityUIStore<EntityUIState>;
    constructor() {
        super(initialState);
        this.createUIStore().setInitialEntityState<EntityUI>(getDefaultUIState());
    }

    public setOrganizationUnitsForActivityTypes(organizationUnits: Array<OrganizationUnit>): void {
        this.update(state => ({ ...state, organizationUnitsForActivityTypes: organizationUnits }));
    }

    public selectOrganizationUnit(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedId: id };

            return { ...state, ui };
        });
    }

    public setIdsOfVisibleOrganizations(ids: Array<number>): void {
        this.update(state => {
            const filteredIds = ids;
            const ui = { ...state.ui, filteredIds };

            return { ...state, ui };
        });
    }

    public toggleExpansionForAll(expanded: boolean): void {
        this.update(state => {
            const expandedIds = expanded ? state.ids : [];
            const ui = { ...state.ui, expandedIds };

            return { ...state, ui };
        });
    }

    public updateExpandedState(id: number): void {
        this.update(state => {
            const expandedIds = state.ui.expandedIds.includes(id) ?
                state.ui.expandedIds.filter(x => x !== id) :
                [...state.ui.expandedIds, id];
            const ui = { ...state.ui, expandedIds };

            return { ...state, ui };
        });
    }

    public updateEntitiesLoadingState(loading: boolean): void {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }

    public updateEntitiesLoadingStateForActivityTypes(loading: boolean): void {
        this.update(state => {
            const entitiesLoadingForActivityTypes = loading;
            const ui = { ...state.ui, entitiesLoadingForActivityTypes };

            return { ...state, ui };
        });
    }
}
