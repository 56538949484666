/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private readonly appInsights: ApplicationInsights;

    constructor() {
        if (environment.enableApplicationInsights) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey
                }
            });
            this.appInsights.loadAppInsights();
            this.loadCustomTelemetryProperties();
        };
    }

    public setUserId(userId: string) {
        this.appInsights.setAuthenticatedUserContext(userId);
    }

    public clearUserId() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    public logException(error: Error){
        const exception: IExceptionTelemetry = {
            exception : error
        };
        this.appInsights.trackException(exception);
    }

    private loadCustomTelemetryProperties()
    {
        this.appInsights.addTelemetryInitializer(envelope => 
        {
            const item = envelope.baseData;
            item.properties = item.properties || {};
            item.properties["ApplicationPlatform"] = "WEB";
            item.properties["ApplicationName"] = "OMRP.Web.UI";
        }
        );
    }
}