import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ManageTemplateState, ManageTemplateStore } from './manage-template.store';
import { ManageTemplate } from './manage-template.model';
import { DateTimeUtilityService } from '../../services/date-time-utility.service';
import { StatusPageQuery } from '../status-page-store/status-page.query';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplateQuery extends StatusPageQuery<ManageTemplateState> {
    
    constructor(
        protected store: ManageTemplateStore,
        protected dateTimeUtilityService: DateTimeUtilityService 
    ) {
        super(store);
    }

    public getTemplates(): Observable<Array<ManageTemplate>> {
        return this.selectAll().pipe(map(templates =>
            templates.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getLengthInWeeks(): Observable<Array<number>> {
        return this.getTemplates().pipe(
            map(templates => {
                const lengths = templates.map(template => template.lengthInWeeks);
                const uniqueSortedLengths = Array.from(new Set(lengths)).sort((a, b) => a - b);
                
                return uniqueSortedLengths;
            })
        );
    }

    public getSelectedTemplateForEdit(): Observable<ManageTemplate> {
        return this.select(state => state.selectedTemplateForEdit);
    }

    public getSelectedTemplateForEditSync(): ManageTemplate {
        return this.getValue().selectedTemplateForEdit;
    }

    public getTemplateSync(id: number): ManageTemplate {
        return this.getEntity(id);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }
}