<div class="managing-filters">
    <app-filter-setting-configuration
        [filterSettings]="filterSettings$ | async"
        [swapButtons]="true"
        [selectedFilterSettingId]="selectedFilterSettingId$ | async"
        [filterChangesInPending]="filterChangesInPending$ | async"
        (updateSelectedFilterSettingIdEvent)="onSelectedFilterSettingIdChanged($event)"
        (removeFilterSettingEvent)="onRemoveFilterSetting($event)"
        (updateFilterSettingEvent)="onUpdateFilterSetting($event)"
        (saveNewFilterSettingEvent)="onSaveNewFilterSettingEvent($event)"
        (overwriteSelectedFilterSettingEvent)="onOverwriteSelectedFilterSettingEvent()"
    ></app-filter-setting-configuration>

    <div *ngIf="(filterSettings$ | async).length === 0 && !(scheduleFiltersValidity$ | async)" translate data-cy="overviewSaveFiltersMessage">
        To save filters, please enter all required fields
    </div>
</div>