// We need access to the "data" property in the route config in our breadcrumb component
// Because the breadcrumb lives outside of the router-outlet, we need a workaround
// This guard will pass the data property to the breadcrumbService

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BreadcrumbService } from 'src/app/core/breadcrumb/breadcrumb.service';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbGuard  {
    constructor(
        private readonly breadcrumbService: BreadcrumbService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        this.breadcrumbService.setBreadcrumbs(route.data.breadcrumbs);

        return true;
    }
}
