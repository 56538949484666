import { Injectable } from '@angular/core';

export interface INestedTreeObject {
    id: number;
    parentId: number;
    children: Array<INestedTreeObject>;
}

@Injectable({
    providedIn: 'root'
})
export class NestedTreeService {
    public nestArray(arrayToNest: Array<INestedTreeObject>): Array<INestedTreeObject> {
        const nestedArray: Array<INestedTreeObject> = [];

        arrayToNest.map(object => {
            if (object.parentId !== null && arrayToNest.find(x => x.id === object.parentId)) {
                const result = arrayToNest.find(x => x.id === object.parentId);

                if (result.children) {
                    result.children.push(object);
                } else {
                    result.children = [object];
                }
            } else {
                nestedArray.push(object);
            }
        });

        return nestedArray;
    }

    public getAllChildIdsForNestedEntities(entities: Array<any>): Array<number> {
        const ids = [];

        entities?.forEach(entity => {
            ids.push(entity.id);

            if (entity.children) {
                ids.push(...this.getAllChildIdsForNestedEntities(entity.children));
            }
        });

        return ids;
    }

    public getAllVisibleChildIdsForNestedEntities(entities: Array<any>): Array<number> {
        const ids = [];

        entities?.forEach(entity => {
            if (entity.visible) {
                ids.push(entity.id);

                if (entity.children) {
                    ids.push(...this.getAllVisibleChildIdsForNestedEntities(entity.children));
                }
            }
        });

        return ids;
    }

    public getChildren(entity: INestedTreeObject, flattenedArray: Array<INestedTreeObject>): Array<INestedTreeObject> {
        (entity?.children || []).forEach(child => {
            flattenedArray.push({ ...child, children: null });

            (child.children || []).forEach(c => {
                flattenedArray.push({ ...c, children: null });
                this.getChildren(c, flattenedArray);
            });
        });

        return flattenedArray;
    }

    public getBranchOfEntity(entities: Array<INestedTreeObject>, entityId: number): INestedTreeObject {
        if (!entities) {
            return undefined;
        }
        const entityBranch = entities.find(entity => entity.id === entityId);
        if (entityBranch) {
            return entityBranch;
        }

        let i = 0;
        let match = null;
        while (!match && i < entities.length) {
            const currentEntity = entities[i];
            match = this.getBranchOfEntity(currentEntity.children, entityId);
            i++;
        }

        return match;
    }

    public setChildrenSelectedEntityState(entities: Array<any>, selectedEntityIds: Array<number>): void {
        entities.forEach(entity => {
            entity.childrenSelected = entity.children ? this.getChildrenSelectedState(entity.children, selectedEntityIds) : false;
            if (entity.children) {
                this.setChildrenSelectedEntityState(entity.children, selectedEntityIds);
            }
        });
    }

    public findChildInNestedArray<T>(array: Array<T & INestedTreeObject>, id: number): T {
        let result;
        array.some(
            (child) =>
                (child.id === id && (result = child)) ||
                (result = this.findChildInNestedArray(child.children || [], id))
        );

        return result;
    }

    public areAllChildrenUnchecked(entity: any): boolean {
        if (!entity.children || entity.children.length === 0) {
            return true; // If no children, return true
        }
    
        // Check if any child is checked, if so, return false
        if (entity.children.some(child => child.checked === true)) {
            return false;
        }
    
        // Recursively check children of children
        return entity.children.every(child => this.areAllChildrenUnchecked(child));
    }

    private getChildrenSelectedState(children: Array<any>, selectedEntityIds: Array<number>): boolean {
        let i = 0;
        let childrenSelected = false;
        while (!childrenSelected && i < children.length) {
            const currentChild = children[i];
            if (selectedEntityIds.includes(currentChild.id)) {
                return true;
            }

            if (!childrenSelected && currentChild.children) {
                childrenSelected = this.getChildrenSelectedState(currentChild.children, selectedEntityIds);
            }
            i++;
        }

        return childrenSelected;
    }
}
