<div class="adt-table" *ngIf="(initialLoadingFinished$ | async) === true">
    <div class="adt-table__header">
        <div class="adt-table__section-info">
            <div class="adt-table__header-bar">
                <button 
                    mat-button 
                    class="bol-button-solid"
                    (click)="onAddCounter()"
                    data-cy="addCounterButton"
                >
                    <mat-icon>add</mat-icon>
                    <span translate>Add</span>
                </button>
        
                <mat-form-field class="bol-form-field adt-table__search">
                    <input matInput [formControl]="searchTerm" placeholder="{{'ACTIVITY-DEMAND.SEARCH-COUNTERS' | translate}}" autocomplete="off" />
                </mat-form-field>

                <button
                    mat-button 
                    *ngIf="(showCountersRefreshButton$ | async) === true"
                    class="bol-button-text bol-accent"
                    (click)="onRefreshCounters()"
                    data-cy="refreshCountersButton"
                >
                    <mat-icon>refresh</mat-icon>
                    <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.REFRESH-COUNTERS</span>
                </button>
            </div>

            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex4">Counter</div>
            </div>
        </div>

        <div class="adt-table__section-week">
            <div class="adt-table__header-bar">

            </div>
            
            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex1">WEEK.MON</div>
                <div translate class="flex1">WEEK.TUE</div>
                <div translate class="flex1">WEEK.WED</div>
                <div translate class="flex1">WEEK.THU</div>
                <div translate class="flex1">WEEK.FRI</div>
                <div translate class="flex1">WEEK.SAT</div>
                <div translate class="flex1">WEEK.SUN</div>
                <div translate class="flex1">TOTAL.WEEK</div>
            </div>
        </div>

        <div class="adt-table__section-total">
            <div class="adt-table__header-bar"></div>
            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex1">TOTAL.PERIOD</div>
                <div class="flex1"></div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="(calculatingCountersInProgress$ | async) === false">
        <div class="adt-table__content" *ngIf="counterRows$ | async as counterRows">
            <div class="adt-table__section-info">
                <div *ngFor="let counter of (counterRows$ | async)" class="adt-table__row">
                    <div class="flex4">{{counter.activityDemandCounterInfo.displayName}}</div>
                </div>
            </div>
    
            <div class="adt-table__section-week">
                <div *ngFor="let currentWeekValue of (currentWeekValues$ | async)" class="adt-table__row">
                    <div class="flex1 adt-table__cell-input" *ngFor="let weekdayValue of currentWeekValue.weekdaysDemand">
                        {{ weekdayValue.amount }}
                    </div>
                    <div class="flex1 adt-table__cell-input">
                        {{ currentWeekValue.totalDemandPerWeek ?? 0 }}
                    </div>
                </div>
            </div>
    
            <div class="adt-table__section-total">
                <div *ngFor="let counter of (counterRows$ | async)" class="adt-table__row">
                    <div class="flex1 adt-table__cell-input">
                        {{ counter.periodDemandTotal ?? 0 }}
                    </div>
                    <div class="flex1 adt-table__cell-menu">
                        <button 
                            mat-button 
                            class="bol-button-text bol-gray icon-only small-icon"
                            [matMenuTriggerFor]="counterMenu.menu"
                            [matMenuTriggerData]="{counter}">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <app-adt-counter-table-menu 
                            #counterMenu 
                            [activityDemandCounterId]="counter.activityDemandCounterInfo.id"
                            [templateId]="currentTemplateId"
                            [counterId]="counter.id">
                        </app-adt-counter-table-menu>
                    </div>
                </div>
            </div>
        </div>
    
        <div *ngIf="(counterRows$ | async)?.length === 0" class="no-entities-message">
            <p data-cy="entityNoEntitiesFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: 'counters' | translate | lowercase } }}</p>
        </div>
    </ng-container>
</div>
<div 
    *ngIf="(calculatingCountersInProgress$ | async) === true && (initialLoadingFinished$ | async) === true" 
    class="counters-loading-spinner">
    <mat-spinner diameter="30"></mat-spinner>
</div>