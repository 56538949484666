<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column" >
            <app-entity-filter-panel>
                <app-skill-levels-filters></app-skill-levels-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                <app-entity-sortable-list-panel
                    *ngIf="initialLoadingFinished$ | async"
                    entityName="Skill levels"
                    [entities]="filteredSkillLevel$ | async"
                    [columnDefinition]="columnDefinition"
                    [loadingError]="loadingError$ | async"
                    [mode]="manageMode$ | async"
                    [selectedEntityId]="selectedSkillLevelId$ | async"
                    (selectedEntityIdChanged)="onSelectedSkillLevelChange($event)"
                    (addEntityClicked)="onAddNewSkillLevel()"
                    (reorderdEntities)="onReorderdEntitiesChanged($event)">
                </app-entity-sortable-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Skill level"
                    [formFields]="formFields"
                    [mode]="manageMode$ | async"
                    [model]="selectedSkillLevel$ | async"
                    [uiEntity]="selectedUISkillLevel$ | async"
                    (deleteEntity)="onDeleteSkillLevel($event)"
                    (editEntity)="onEditSkillLevel($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false) || (showLoadingSpinner$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>