import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestService } from '../../services/request.service';
import { ErrorDialogService } from '../../services/error-dialog.service';

import { UtilsService } from '../../services/utils.service';
import { GetResourceResponse, Resource, ResourceRequestParameters } from './resource.model';
import { ResourceStore } from './resource.store';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    private readonly maxDaysInCall = 31;

    constructor(
        protected resourceStore: ResourceStore,
        protected requestService: RequestService,
        private readonly http: HttpClient,
        private readonly utilsService: UtilsService,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getResources(parameters: ResourceRequestParameters, startDate?: string, endDate?: string): Observable<Array<Resource>> {
        this.resourceStore.updateEntitiesLoading(true);
        const params = this.utilsService.objectToHttpParam(parameters);

        if (!!startDate && !!endDate) {
            const requestList: Array<Observable<GetResourceResponse>> = [];
            const paramList = this.requestService.splitUpParamsBasedOnDates(
                params,
                startDate,
                endDate,
                'start',
                'end',
                this.maxDaysInCall
            );

            paramList.forEach(param => requestList.push(this.getRequestForParams(param)));

            return this.requestService.loadSequential(requestList).pipe(
                map(resourceResponses => {
                    let resources: Array<Resource>;
                    resourceResponses.forEach((response, index) => {
                        if (index === 0) {
                            resources = response.resources;
                        }
                        else {
                            response.resources.forEach(resource => {
                                if (!resources.find(r => r.id === resource.id)) {
                                    resources.push(resource);
                                }
                            });
                        }
                    });

                    const sortedResources = resources.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

                    this.resourceStore.set(sortedResources);
                    this.resourceStore.updateEntitiesLoading(false);

                    return sortedResources;
                })
            );
        }
        else {
            return this.getRequestForParams(params).pipe(
                map((resources) => {
                    const sortedResources = resources.resources.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
                    this.resourceStore.set(sortedResources);
                    this.resourceStore.updateEntitiesLoading(false);

                    return sortedResources;
                })
            );
        }
    }

    private getRequestForParams(params: HttpParams): Observable<GetResourceResponse> {
        return this.http.get<GetResourceResponse>('/api/v1/Resources', { params }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
        );
    }
}
