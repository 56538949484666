import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { CountersExportFilterSetting, CountersExportRequestFilters } from './counters-export-request-filters.model';
import { CountersExportState, CountersExportStore } from './counters-export.store';

@Injectable({
    providedIn: 'root',
})
export class CountersExportQuery extends QueryEntity<CountersExportState> {
    constructor(
        protected store: CountersExportStore,
        protected utilsService: UtilsService,
        protected userInfoQuery: UserInfoQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected activityTypeQuery: ActivityTypeQuery,
        protected activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        protected resourceTypeQuery: ResourceTypeQuery,
        protected filterSettingQuery: FilterSettingQuery,
    ) {
        super(store);
    }

    public getRequestFiltersValidityState(): Observable<boolean> {
        return this.select((state) => {
            const filters = state.ui.requestFilters;

            return !!filters.start && filters.start !== '' &&
                !!filters.end && filters.end !== '' &&
                !!filters.organizationUnitIds && filters.organizationUnitIds.length > 0 &&
                !!filters.activityTypeIds && filters.activityTypeIds.length > 0 &&
                !!filters.resourceTypeIds && filters.resourceTypeIds.length > 0 &&
                state.ui.validIntervalDates;
        });
    }

    public getUIState(): Observable<any> {
        return this.select(state => state.ui);
    }

    public getRequestFilters(): CountersExportRequestFilters {
        const requestFilters = this.getValue().ui.requestFilters;
        requestFilters.start = moment(requestFilters.start).format('YYYY-MM-DD 00:00:00');
        requestFilters.end = moment(requestFilters.end).format('YYYY-MM-DD 00:00:00');

        return requestFilters;
    }

    public getSelectedActivityTypeCategoryIdsSync(): Array<number> | undefined {
        return this.getValue().ui.activityTypeCategoryIds;
    }

    public getSelectedActivityTypeIdsSync(): Array<number> | undefined {
        return this.getValue().ui.requestFilters.activityTypeIds;
    }

    public getSelectedEndDateSync(): string {
        return this.getValue().ui.requestFilters.end;
    }

    public getSelectedOrganizationUnitIdsSync(): Array<number> {
        return this.getValue().ui.requestFilters.organizationUnitIds;
    }

    public getSelectedResourceTypeIdsSync(): Array<number> | undefined {
        return this.getValue().ui.requestFilters.resourceTypeIds;
    }

    public getSelectedExtraResourceTypeIdsSync(): Array<number> | undefined {
        return this.getValue().ui.requestFilters.extraResourceTypeIds;
    }

    public getSelectedStartDateSync(): string {
        return this.getValue().ui.requestFilters.start;
    }

    public getSelectedFilterSettingIdSync(): number {
        return this.getValue().ui.selectedFilterSettingId;
    }

    public getExportUnplannedLeavesStateSync(): boolean {
        return this.getValue().ui.requestFilters.exportUnplannedLeaves;
    }

    public getSelectedOrganizationUnitsSync(): Array<number> {
        return this.getValue().ui.requestFilters.organizationUnitIds;
    }

    public getSelectedResourceTypesSync(): Array<ResourceType> {
        const selectedResourceTypeIds = this.getValue().ui.requestFilters.resourceTypeIds;
        const allResourceTypes = this.resourceTypeQuery.getResourceTypesSync();

        return allResourceTypes.filter(type => selectedResourceTypeIds.includes(type.id));
    }

    public getSelectedStartDate(): Observable<string> {
        return this.select(state => state.ui.requestFilters.start);
    }

    public getSelectedEndDate(): Observable<string> {
        return this.select(state => state.ui.requestFilters.end);
    }

    public getSelectedFilterSettingId(): Observable<number> {
        return this.select(state => state.ui.selectedFilterSettingId);
    }

    public getSelectedActivityTypeCategoryIds(): Observable<Array<number>> {
        return this.select(state => state.ui.activityTypeCategoryIds);
    }

    public getSelectedOrganizationUnitForActivityIds(): Observable<Array<number>> {
        return this.select(state => state.ui.organizationUnitForActivityIds);
    }

    public getSelectedActivityTypeIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestFilters.activityTypeIds);
    }

    public getSelectedOrganizationUnitIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestFilters.organizationUnitIds);
    }

    public getSelectedResourceTypeIds(): Observable<Array<number>> {
        return this.select((state) => state.ui.requestFilters.resourceTypeIds);
    }

    public getSelectedExtraResourceTypeIds(): Observable<Array<number>> {
        return this.select((state) => state.ui.requestFilters.extraResourceTypeIds);
    }

    public getCurrentFilterSettingSync(): FilterSetting {
        return this.filterSettingQuery.getFilterSettingsSync().find(setting => setting.id === this.getSelectedFilterSettingIdSync());
    }

    public getExportUnplannedLeavesState(): Observable<boolean> {
        return this.select((state) => state.ui.requestFilters.exportUnplannedLeaves);
    }

    public getSelectedOrganizationUnits(): Observable<Array<OrganizationUnit>> {
        return combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            filter(([_, filteredIds]) => !!filteredIds),
            map(([organizationUnits, filteredIds]) => {
                return organizationUnits.filter(unit => filteredIds.includes(unit.id));
            })
        );
    }

    public getActivityTypeSelectedIds(): Observable<Array<number>> {
        return combineLatest([
            this.activityTypeQuery.getActivityTypes(),
            this.getSelectedActivityTypeIds()
        ]).pipe(
            filter(([activityTypes, _]) => activityTypes !== undefined && activityTypes.length > 0),
            map(([activityTypes, ids]) =>
                ids ? activityTypes.filter(actType => ids.includes(actType.id)).map(actType => actType.id) :
                    activityTypes.map(actType => actType.id)
            )
        );
    }

    public getCountersExportFilterSettingFromState(): CountersExportFilterSetting {
        const currentSettings: CountersExportFilterSetting = {
            activityTypeCategoryIds: this.getValue().ui.activityTypeCategoryIds,
            organizationUnitForActivityIds: this.getValue().ui.organizationUnitForActivityIds,
            requestFilters: this.getValue().ui.requestFilters,
        };

        return currentSettings;
    }

    public getNewFilterSetting(settingName: string): FilterSetting {
        const settings: CountersExportFilterSetting = this.getCountersExportFilterSettingFromState();

        return {
            userId: this.userInfoQuery.getUserIdSync(),
            creationDate: moment(new Date()).toISOString(),
            settings: JSON.stringify(settings),
            settingType: FILTER_SETTING_TYPE.EXPORT_ACTIVITIES_FILTER_SETTING,
            displayName: settingName
        };
    }

    public getUpdatedFilterSetting(): FilterSetting {
        const newSettings: CountersExportFilterSetting = this.getCountersExportFilterSettingFromState();

        const currentFilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        return ({ ...currentFilterSetting, settings: JSON.stringify(newSettings) });
    }

    public loadedCountersExportFilters(): Observable<boolean> {
        return combineLatest([
            this.filterSettingQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.activityTypeQuery.getEntitiesLoadingState(),
            this.activityTypeCategoryQuery.getEntitiesLoadingState(),
        ]).pipe(
            map(([filterSettings, units, resourceTypes, activityTypes, activityTypeCategories]) =>
                filterSettings || units || resourceTypes || activityTypes || activityTypeCategories
            )
        );
    }

    public removeSettingPropertiesFromCountersFilter(settings: string): string {
        const parsedSettings = JSON.parse(settings);

        parsedSettings.requestFilters.start = undefined;
        parsedSettings.requestFilters.end = undefined;

        return JSON.stringify(parsedSettings);
    }

    public currentFilterSettingChangesUnsavedSync(): boolean {
        const filterSetting = this.getCurrentFilterSettingSync();

        if (!filterSetting) {
            return false;
        }

        const parsedSettings: CountersExportFilterSetting = JSON.parse(filterSetting.settings);
        const currentSettings = JSON.stringify(this.getCountersExportFilterSettingFromState());

        const equalFilterOptionsSelected = this.utilsService.getSortedObjectString(this.removeSettingPropertiesFromCountersFilter(filterSetting.settings)) ===
        this.utilsService.getSortedObjectString(this.removeSettingPropertiesFromCountersFilter(currentSettings));

        const equalResourceTypeIdsSelected = JSON.stringify(parsedSettings.requestFilters.resourceTypeIds.sort((a, b) => a - b)) ===
        JSON.stringify(this.getSelectedResourceTypeIdsSync().sort((a, b) => a - b));

        return equalFilterOptionsSelected && equalResourceTypeIdsSelected;
    }
}
