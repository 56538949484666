import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Router } from '@angular/router';
import { AuthOptions } from 'auth0-js';
import { first, catchError } from 'rxjs/operators';
import { SystemService } from '@ortec/soca-web-ui';
import { AuthenticationService, AUTHENTICATION_SERVICE_OPTIONS } from '@ortec/soca-web-ui/identity';
import { InitializationService } from 'src/app/shared/services/initialization.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-authorize',
    templateUrl: './authorize.component.html',
    styleUrls: ['./authorize.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizeComponent {
    public message: string;
    public loginBusy$ = new BehaviorSubject<boolean>(true);
    public showError$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly router: Router,
        @Inject(APP_BASE_HREF) appBaseHref: string,
        @Inject(AUTHENTICATION_SERVICE_OPTIONS) authenticationServiceOptions: AuthOptions,
        authenticationService: AuthenticationService,
        private readonly systemService: SystemService,
        private readonly initializationService: InitializationService
    ) {
        this.initializationService.setAuthenticationInProgress(true);

        authenticationService.loginWithAuth0Token(
            authenticationServiceOptions.clientID,
            this.systemService.getHttpParamByName('code'),
            `${window.location.protocol}//${window.location.host}`
        ).pipe(
            first(),
            catchError((error: any) => {
                this.initializationService.setErrorInAuthentication(true);
                this.initializationService.setAuthenticationInProgress(false);
                this.message = error;
                this.showError$.next(true);
                this.loginBusy$.next(false);
                throw error;
            })
        ).subscribe(() => {
            let state = this.systemService.getHttpParamByName('state');
            state = state.replace(appBaseHref, '');
            this.initializationService.setAuthenticationInProgress(false);
            this.router.navigateByUrl(state === null ? '/' : state);
            this.loginBusy$.next(false);
        });
    }
}
