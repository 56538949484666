import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserInfo } from './user-info.model';
import { UserInfoStore } from './user-info.store';

@Injectable({
    providedIn: 'root'
})
export class UserInfoService {
    constructor(
        protected userInfoStore: UserInfoStore,
        private readonly http: HttpClient,
    ) { }

    public getCurrentUserInfo(): Observable<UserInfo> {
        return this.http.get<UserInfo>('/api/GetUserInfo').pipe(
            tap((userInfo) => {
                this.userInfoStore.setCurrentUser(userInfo);
            })
        );
    }

    public getHelpUrlPath(language: string): Observable<string> {
        return this.http.get<string>('/api/Configuration/GetHelpURL/' + language);
    }

    public getUserLanguage(): Observable<string> {
        return this.http.get<string>('/api/i18n/AvailableLanguages/UserLanguage').pipe(
            tap((languageCode) => {
                this.userInfoStore.setUserLanguageCode(languageCode);
            })
        );
    }
}
