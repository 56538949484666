<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column" >
            <div class="flex-growing-child">
                <app-entity-tree-list-panel
                    *ngIf="initialLoadingFinished$ | async"
                    entityName="User groups"
                    [entities]="userGroups$ | async"
                    [loadingError]="loadingError$ | async"
                    [mode]="manageMode$ | async"
                    [selectedEntityId]="selectedUserGroupId$ | async"
                    [searchProperties]="searchProperties"
                    (selectedEntityIdChanged)="onSelectedUserGroupChange($event)"
                    (addEntityClicked)="onAddNewUserGroup()"
                >
                </app-entity-tree-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="User group"
                    [canClone]="canCloneSelectedEntity$ | async"
                    [canDelete]="canDeleteSelectedEntity$ | async"
                    [mode]="manageMode$ | async"
                    [model]="selectedUserGroup$ | async"
                    [uiEntity]="selectedUIUserGroup$ | async"
                    [formFields]="formFields"
                    (cloneEntity)="onCloneUserGroup($event)"
                    (deleteEntity)="onDeleteUserGroup($event)"
                    (editEntity)="onEditUserGroup($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false) || (showLoadingSpinner$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
