import { Injectable } from '@angular/core';

import { STATUS, StatusPageState, StatusPageStore } from './status-page.store';

@Injectable({ providedIn: 'root' })
export abstract class StatusPageService<TState extends StatusPageState> {
    constructor(
        protected store: StatusPageStore<StatusPageState>
    ) {}

    public updatePageStatusState(state: STATUS): void {
        this.store.updatePageStatusState(state);
    }
}