import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';
import { StatusPageService } from 'src/app/shared/stores/status-page-store/status-page.service';

import { FilteringState, FilteringStore } from './filtering.store';
import { ResourcesToHide } from './filtering.model';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

@Injectable({
    providedIn: 'root'
})
export class FilteringService extends StatusPageService<FilteringState> {
    constructor(
        protected filteringStore: FilteringStore,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
    ) { 
        super(filteringStore);
    }

    public getOrganizationUnitSelection(): Observable<Array<number>> {
        this.filteringStore.updateEntitiesLoadingOrganizationUnitSelection(true);

        return this.http.get<Array<number>>('/api/Users/OrganizationUnitSelection').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((organizationUnitSelection) => {
                this.filteringStore.updateSelectedOrganizationUnitIds(organizationUnitSelection);
                this.filteringStore.updateEntitiesLoadingOrganizationUnitSelection(false);
            })
        );
    }

    public updateSelectedOrganizationUnitIds(organizationUnitSelection: Array<number>): Observable<void> {
        this.filteringStore.updateSelectedOrganizationUnitIds(organizationUnitSelection);

        return this.http.put<void>('/api/Users/OrganizationUnitSelection', organizationUnitSelection).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);
                this.filteringStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.filteringStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public getResourceSkillsSelection(): Observable<Array<number>> {
        this.filteringStore.updateEntitiesLoading(true);

        return this.http.get<Array<number>>('/api/Users/SelectedSkills').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((resourceSkillsSelection) => {
                this.filteringStore.updateSelectedResourceSkillIds(resourceSkillsSelection);
                this.filteringStore.updateEntitiesLoading(false);
            })
        );
    }

    public updateSelectedResourceSkillIds(resourceSkillsSelection: Array<number>): Observable<void> {
        this.filteringStore.updateSelectedResourceSkillIds(resourceSkillsSelection);

        return this.http.put<void>('/api/Users/SelectedSkills', resourceSkillsSelection).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);
                this.filteringStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.filteringStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public getResourcesToHideSelection(): Observable<Array<ResourcesToHide>> {
        this.filteringStore.updateEntitiesLoadingSelectedHideResources(true);

        return this.http.get<Array<ResourcesToHide>>('/api/Users/SelectedHideResources').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((resourceToHideSelection) => {
                this.filteringStore.updateSelectedResourcesToHide(resourceToHideSelection);
                this.filteringStore.updateEntitiesLoadingSelectedHideResources(false);
            })
        );
    }

    public updateSelectedResourcesToHide(resourcesToHide: Array<ResourcesToHide>): Observable<void> {
        this.filteringStore.updateSelectedResourcesToHide(resourcesToHide);

        return this.http.put<void>('/api/Users/SelectedHideResources', resourcesToHide).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);
                this.filteringStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.filteringStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public getResourcePropertySelection(): Observable<Array<number>> {
        this.filteringStore.updateEntitiesLoadingResourcePropertySelection(true);

        return this.http.get<Array<number>>('/api/Users/ResourcePropertySelection').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((resourcePropertySelection) => {
                this.filteringStore.updateSelectedVisibleColumns(resourcePropertySelection);
                this.filteringStore.updateEntitiesLoadingResourcePropertySelection(false);
            })
        );
    }

    public updateSelectedResourcePropertyIds(resourcePropertySelection: Array<number>): Observable<void> {
        this.filteringStore.updateSelectedVisibleColumns(resourcePropertySelection);

        return this.http.put<void>('/api/Users/ResourcePropertySelection', resourcePropertySelection).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);
                this.filteringStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.filteringStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public getSelectedActivityTypes(): Observable<Array<number>> {
        this.filteringStore.updateEntitiesLoadingSelectedActivityTypes(true);

        return this.http.get<Array<number>>('/api/Users/SelectedActivityTypes').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((activityTypesSelection) => {
                this.filteringStore.updateSelectedActivityTypeIds(activityTypesSelection);
                this.filteringStore.updateEntitiesLoadingSelectedActivityTypes(false);
            })
        );
    }

    public updateSelectedActivityTypes(activityTypeSelection: Array<number>): Observable<void> {
        this.filteringStore.updateSelectedActivityTypeIds(activityTypeSelection);

        return this.http.put<void>('/api/Users/SelectedActivityTypes', activityTypeSelection).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);
                this.filteringStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.filteringStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public updateEntitiesLoading(state: boolean): void {
        this.filteringStore.updateEntitiesLoading(state);
    }

    public updateEntitiesLoadingSelectedResourceTypeId(state: boolean): void {
        this.filteringStore.updateEntitiesLoadingSelectedResourceTypeId(state);
    }

    public updateSelectedResourceTypeId(id: number): void {
        this.filteringStore.updateSelectedResourceTypeId(id);
    }
}
