import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RerouteToAngularJSGuard implements CanActivate {
    public canActivate(route: ActivatedRouteSnapshot): boolean {
        if (environment.useAngularScreens === 'false' && environment.rerouteToAngularJS === 'true' && route.data.angularJSRoute) {
            window.location.href = route.data.angularJSRoute;

            return false;
        }
        
        return true;
    }
}
