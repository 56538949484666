<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <app-entity-filter-panel>
                <app-users-filters></app-users-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                <app-entity-list-panel
                    *ngIf="initialLoadingFinished$ | async"
                    entityName="Users"
                    [entities]="filteredUsers$ | async"
                    [loadingError]="loadingError$ | async"
                    [mode]="manageMode$ | async"
                    [selectedEntityId]="selectedUserId$ | async"
                    [searchProperties]="searchProperties"
                    (selectedEntityIdChanged)="onSelectedUserChange($event)"
                    (addEntityClicked)="onAddEntityClicked()"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="User"
                    [mode]="manageMode$ | async"
                    [model]="selectedUser$ | async"
                    [uiEntity]="selectedUIUser$ | async"
                    [formFields]="formFields"
                    [canDelete]="canDeleteSelectedEntity$ | async"
                    [message]="entityManageMessage$ | async"
                    [messageStyleClass]="'warning'"
                    (cancelAdd)="onCancelAdd()"
                    (deleteEntity)="onDeleteUser($event)"
                    (addNewEntity)="onAddNewUser($event)"
                    (editEntity)="onEditUser($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false) || (showLoadingSpinner$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
