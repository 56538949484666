import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { EntityUI, getDefaultUIState } from '../entity-ui-models';
import { Skill } from './skill.model';

export interface SkillState extends EntityState<Skill> {
    ui: {
        selectedId: number;
        selectedIds: Array<number>;
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        selectedId: 0,
        selectedIds: [],
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'skill' })
export class SkillStore extends EntityStore<SkillState> {
    constructor() {
        super(initialState);
        this.createUIStore().setInitialEntityState<EntityUI>(getDefaultUIState());
    }

    public selectSkill(id: number) {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedId: id
            };

            return { ...state, ui };
        });
    }

    public updateSelectedSkillIds(ids: Array<number>) {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedIds: ids
            };

            return { ...state, ui };
        });
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}
