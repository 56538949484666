import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { DepartmentMapping } from './department-mapping.model';

export interface DepartmentMappingState extends EntityState<DepartmentMapping> {
    ui: {
        selectedOrganizationUnitId: number;
        hideUnlinkedDepartments: boolean;
    };
}

const initialState = {
    ui: {
        selectedOrganizationUnitId: undefined,
        hideUnlinkedDepartments: false
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'department-mapping', idKey: 'owsDepartmentId' })
export class DepartmentMappingStore extends EntityStore<DepartmentMappingState> {

    constructor() {
        super(initialState);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOrganizationUnitId: id };
            
            return { ...state, ui };
        });
    }

    public updateHideUnlinkedOwsDepartments(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, hideUnlinkedDepartments: value };

            return { ...state, ui };
        });
    }  
}
