import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    // eslint-disable-next-line @typescript-eslint/ban-types
    public format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        } else {
            return date.toDateString();
        }
    }

    public getFirstDayOfWeek(): number {
        return 1;
    }

    public parse(value: any): Date | null {
        if (typeof value === 'string' && value.indexOf(' ') > -1 || value.indexOf('-') > -1) {
            const dateArray = value.split(/[ -]/); // split on whitespace, dash
            const year = parseInt(dateArray[2], 10);
            const month = parseInt(dateArray[1], 10) - 1; // months are 0-indexed in JS
            const day = parseInt(dateArray[0], 10);

            if (isNaN(year) || isNaN(month) || isNaN(day) || month > 11) {
                return null; // couldn't parse a valid date
            }

            const date = new Date(year, month, day);

            if (date.getDate() !== day || date.getMonth() !== month || date.getFullYear() !== year) {
                return null;
            }

            return date;
        }
        const timeStamp = typeof value === 'number' ? value : Date.parse(value);

        return isNaN(timeStamp) ? null : new Date(timeStamp);
    }
}

export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: 'input',
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};
