import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { observeProperty } from '@ortec/utilities/rxjs';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, merge, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, startWith, tap, withLatestFrom } from 'rxjs/operators';

import { LanguageService } from 'src/app/shared/language';
import { Scenario } from 'src/app/shared/stores/scenario-store/scenario.model';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';

import { adjustDateForTimezone } from '../../../../../shared/services/adjust-date-for-timezone';
import { GlobalSettingsQuery } from '../../../../../shared/stores/global-settings/global-settings.query';

import { COUNTERS_COUNTER_TYPE, COUNTERS_GROUPING_OPTIONS, COUNTERS_TOTALS_OPTIONS } from '../../counters-helpers/enums';
import {
    CountersEnumToFilterItem,
    CountersFiltersOptions,
    CountersFiltersSelectedOptions,
} from '../../counters-helpers/filters.model';
import { CountersFiltersService } from '../../counters-helpers/counters-filters.service';

export interface ScenarioItem extends Scenario {
    valid: boolean;
}

@Component({
    selector: 'app-counters-filters',
    templateUrl: './counters-filters.component.html',
    styleUrls: ['./counters-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountersFiltersComponent implements OnInit, OnDestroy {

    @Input() public countersFiltersOptions: CountersFiltersOptions;
    @Input() public countersFiltersSelectedOptions: CountersFiltersSelectedOptions;
    @Input() public isExport = false;

    @Output() public readonly organizationUnitIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly resourceTypeIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly extraResourceTypeIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly skillIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly activityTypeIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly activityTypeCategoryIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly organizationUnitForActivityIdsSelectedChanged = new EventEmitter<Array<number>>();
    @Output() public readonly groupingOptionsSelectedChanged = new EventEmitter<Array<COUNTERS_GROUPING_OPTIONS>>();
    @Output() public readonly totalsSelectedChanged = new EventEmitter<Array<COUNTERS_TOTALS_OPTIONS>>();
    @Output() public readonly counterTypesSelectedChanged = new EventEmitter<Array<COUNTERS_COUNTER_TYPE>>();
    @Output() public readonly scenarioIdSelectedChanged = new EventEmitter<number>();
    @Output() public readonly enabledActivityTypeDetailStateChanged = new EventEmitter<boolean>();
    @Output() public readonly enabledExportUnplannedLeavesStateChanged = new EventEmitter<boolean>();
    @Output() public readonly startDateChanged = new EventEmitter<string>();
    @Output() public readonly endDateChanged = new EventEmitter<string>();
    @Output() public readonly intervalDatesValidityStateChanged = new EventEmitter<boolean>();
    @ViewChild('tillDatePicker', { static: true }) private readonly tillDatePicker!: MatDatepicker<Date>;

    public readonly dateTimeControls = {
        fromDate: new UntypedFormControl(new Date(), [Validators.required]),
        tillDate: new UntypedFormControl(new Date(), [Validators.required]),
    };

    public countersFiltersOptions$!: Observable<CountersFiltersOptions>;
    public organizationsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public dateFormat$!: Observable<string>;
    public scenarioItems$!: Observable<Array<ScenarioItem>>;

    public groupingOptionsLength$!: Observable<number>;
    public selectedCounterTypes: Array<COUNTERS_COUNTER_TYPE>;
    public scenarioFilterVisibility = false;
    public scenarioControl = new UntypedFormControl('', [Validators.required]);
    public scenarioItemsSubject = new BehaviorSubject<Array<ScenarioItem>>([]);

    private groupingOptions: Array<CountersEnumToFilterItem> = [];

    private readonly subscription = new Subscription();

    constructor(
        private readonly languageService: LanguageService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly countersFiltersService: CountersFiltersService
    ) { }

    public ngOnInit(): void {
        this.setIntervalDatesFilters();
        this.setActivityTypeFilter();
        this.setActivityTypeCategoryFilter();
        this.setOrganizationUnitForActivitiesFilter();
        this.setTotalFilter();
        this.setCounterTypeFilter();
        this.setScenarioFilter();
        this.setGroupingOptionFilter();

        this.dateFormat$ = this.languageService.getDateFormatByCurrentLanguage();
        this.countersFiltersOptions$ = observeProperty(this as CountersFiltersComponent, 'countersFiltersOptions');

        this.groupingOptionsLength$ = merge(
            this.countersFiltersSelectedOptions.selectedGroupingOptions$.pipe(map(items => items.length)),
            this.groupingOptionsSelectedChanged.pipe(map(items => items.length))
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onFilteredOrganizationsChanged(ids: Array<number>): void {
        this.organizationUnitIdsSelectedChanged.emit(ids);
    }

    public onFilteredResourceTypesChanged(ids: Array<number>): void {
        this.resourceTypeIdsSelectedChanged.emit(ids);
    }

    public onFilteredExtraResourceTypesChanged(ids: Array<number>): void {
        this.extraResourceTypeIdsSelectedChanged.emit(ids);
    }

    public onFilteredResourceSkillsChanged(ids: Array<number>): void {
        this.skillIdsSelectedChanged.emit(ids);
    }

    public updateSelectedActivityTypeIds(selectedIds: Array<number>): void {
        this.activityTypeIdsSelectedChanged.emit(selectedIds);
    }

    public updateSelectedOrganizationUnitForActivityIds(selectedIds: Array<number>): void {
        this.organizationUnitForActivityIdsSelectedChanged.emit(selectedIds);
    }

    public updateSelectedActivityTypeCategoryIds(selectedIds: Array<number>): void {
        this.activityTypeCategoryIdsSelectedChanged.emit(selectedIds);
    }

    public onFilteredGroupingOptionsChanged(ids: Array<number>): void {
        const selectedGroupingOptions: Array<COUNTERS_GROUPING_OPTIONS> = [];
        ids.forEach(id => {
            const groupingOption = this.groupingOptions.find(option => option.id === id);
            selectedGroupingOptions.push(groupingOption.type as COUNTERS_GROUPING_OPTIONS);
        });
        this.groupingOptionsSelectedChanged.next(selectedGroupingOptions);
    }

    public fromDatePickerClosed(): void {
        this.tillDatePicker.open();

        // Set the till value to from's value
        if (!this.dateTimeControls.tillDate.value && moment.isMoment(this.dateTimeControls.fromDate.value)) {
            this.dateTimeControls.tillDate.setValue(this.dateTimeControls.fromDate.value.clone());
        }
    }

    public getDateErrorMessage(pickerInput: MatInput): string {
        if (!pickerInput.value || pickerInput.value === '') {
            return 'Please enter a date';
        }

        return 'Please enter a valid date';
    }

    public onUpdateEnabledActivityTypeDetailState(event: MatCheckboxChange): void {
        this.enabledActivityTypeDetailStateChanged.emit(event.checked);
    }

    public onUpdateEnabledExportUnplannedLeavesState(event: MatCheckboxChange): void {
        this.enabledExportUnplannedLeavesStateChanged.emit(event.checked);
    }

    public onFilteredTotalOptionsChanged(selectedIds: Array<number>): void {
        const selectedOptions = this.countersFiltersService.transformIdsToEnumForTotalOptions(selectedIds, COUNTERS_TOTALS_OPTIONS)
        this.totalsSelectedChanged.emit(selectedOptions);
    }

    public onScenarioSelected(event: MatSelectChange): void {
        this.scenarioIdSelectedChanged.emit(event.value);
    }

    public onCounterFiltersChanged(ids: Array<number>): void {
        const selectedOptions = this.countersFiltersService.transformIdsToEnum(ids, COUNTERS_COUNTER_TYPE);
        this.selectedCounterTypes = selectedOptions;
        this.counterTypesSelectedChanged.emit(selectedOptions);
        this.updateScenarioFilterVisibility(selectedOptions);
    }

    private setIntervalDatesFilters(): void {
        this.subscription.add(combineLatest([
            this.countersFiltersOptions.startDate$,
            this.globalSettingsQuery.getTimeZone()]
        ).subscribe(([fromDate, timeZone]) => {
            const adjustedDate = adjustDateForTimezone(new Date(fromDate), timeZone, true);
            this.dateTimeControls.fromDate.setValue(moment(adjustedDate).tz(timeZone).format(), { emitEvent: false });
        }));

        this.subscription.add(combineLatest([
            this.countersFiltersOptions.endDate$,
            this.globalSettingsQuery.getTimeZone()]
        ).subscribe(([tillDate, timeZone]) => {
            const adjustedDate = adjustDateForTimezone(new Date(tillDate), timeZone, true);
            this.dateTimeControls.tillDate.setValue(moment(adjustedDate).tz(timeZone).format(), { emitEvent: false });
        }));

        this.subscription.add(
            this.dateTimeControls.fromDate.valueChanges.pipe(
                filter(date => !!date),
                withLatestFrom(this.globalSettingsQuery.getTimeZone())
            ).subscribe(([date, timeZone]) => {
                if (this.dateTimeControls.fromDate.valid) {
                    const adjustedDate = adjustDateForTimezone(new Date(date), timeZone, false);
                    this.startDateChanged.emit(moment(adjustedDate).tz(timeZone).format());
                }
            })
        );

        this.subscription.add(
            this.dateTimeControls.tillDate.valueChanges.pipe(
                filter(date => !!date),
                withLatestFrom(this.globalSettingsQuery.getTimeZone())
            ).subscribe(([date, timeZone]) => {
                if (this.dateTimeControls.tillDate.valid) {
                    const adjustedDate = adjustDateForTimezone(new Date(date), timeZone, false);
                    this.endDateChanged.emit(moment(adjustedDate).tz(timeZone).format());
                }
            })
        );

        this.subscription.add(
            combineLatest([
                this.dateTimeControls.fromDate.valueChanges,
                this.dateTimeControls.tillDate.valueChanges
            ]).subscribe(() =>
                this.intervalDatesValidityStateChanged.emit(this.dateTimeControls.fromDate.valid && this.dateTimeControls.tillDate.valid)
            )
        );
    }

    private setCounterTypeFilter(): void {
        this.countersFiltersSelectedOptions.selectedCounterTypes$.pipe(
            first(),
            filter((selectedCounterTypes) => selectedCounterTypes.length > 0),
            map((selectedCounterTypes) => this.countersFiltersService.transformIdsToEnum(selectedCounterTypes, COUNTERS_COUNTER_TYPE)),
            tap((selectedCounterTypes) => { 
                this.selectedCounterTypes = selectedCounterTypes; 
                this.counterTypesSelectedChanged.emit(selectedCounterTypes); 
                this.updateScenarioFilterVisibility(selectedCounterTypes);
            })
        ).subscribe();
    }

    private setTotalFilter(): void {
        this.countersFiltersSelectedOptions.selectedTotals$.pipe(
            first(),
            filter((selectedTotalOptions) => selectedTotalOptions.length > 0),
            map((selectedTotalOptions) => this.countersFiltersService.transformIdsToEnumForTotalOptions(selectedTotalOptions, COUNTERS_TOTALS_OPTIONS)),
            tap((selectedTotalOptions) => { this.totalsSelectedChanged.emit(selectedTotalOptions); })
        ).subscribe();
    }

    private setActivityTypeFilter(): void {
        this.countersFiltersSelectedOptions.selectedActivityTypeIds$.pipe(
            first(),
            filter((selectedActivityTypeIds) => selectedActivityTypeIds.length > 0),
            tap((selectedActivityTypeIds) => { this.activityTypeIdsSelectedChanged.emit(selectedActivityTypeIds); })
        ).subscribe();
    }

    private setActivityTypeCategoryFilter(): void {
        this.countersFiltersSelectedOptions.selectedActivityTypeCategoryIds$.pipe(
            first(),
            filter((selectedActivityTypeCategories) => selectedActivityTypeCategories && selectedActivityTypeCategories.length > 0),
            tap((selectedActivityTypeCategories) => { this.activityTypeCategoryIdsSelectedChanged.emit(selectedActivityTypeCategories); })
        ).subscribe();
    }

    private setOrganizationUnitForActivitiesFilter(): void {
        this.countersFiltersSelectedOptions.selectedOrganizationUnitsForActivities$.pipe(
            first(),
            filter((selectedOrganizationUnitForActivity) => selectedOrganizationUnitForActivity && selectedOrganizationUnitForActivity.length > 0),
            tap((selectedOrganizationUnitForActivity) => { this.organizationUnitForActivityIdsSelectedChanged.emit(selectedOrganizationUnitForActivity); })
        ).subscribe();
    }

    private setScenarioFilter(): void {
        this.scenarioItems$ = this.scenarioItemsSubject.asObservable();

        this.subscription.add(
            combineLatest([
                this.countersFiltersOptions.scenarios$,
                this.dateTimeControls.fromDate.valueChanges.pipe(startWith(this.dateTimeControls.fromDate.value)),
                this.dateTimeControls.tillDate.valueChanges.pipe(startWith(this.dateTimeControls.tillDate.value)),
            ]).pipe(
                filter(([scenarios, _, __]) => !!scenarios),
                distinctUntilChanged(),
            ).subscribe(([scenarios, _, __]) => {
                const startDate = new Date(this.dateTimeControls.fromDate.value);
                const endDate = new Date(this.dateTimeControls.tillDate.value);

                const scenarioItems = scenarios.sort((a, b) => {
                    if (new Date(a.start) < new Date(b.start)) {
                        return -1;
                    } else if (new Date(a.start) > new Date(b.start)) {
                        return 1;
                    } else {
                        if (new Date(a.end) < new Date(b.end)) {
                            return -1;
                        } else if (new Date(a.end) > new Date(b.end)) {
                            return 1;
                        } else {
                            return a.displayName.localeCompare(b.displayName);
                        }
                    }
                }).map(scenario => {
                    const isValidScenarioInterval = new Date(scenario.end) >= startDate && new Date(scenario.start) <= endDate;

                    return { ...scenario, valid: isValidScenarioInterval };
                });

                this.scenarioItemsSubject.next(scenarioItems);
            })
        );

        this.subscription.add(
            this.scenarioItems$.subscribe(scenarios => {
                const currentScenarioId = this.scenarioControl.value;
                const selectedCounterTypes = this.selectedCounterTypes;

                if (this.isScenarioFilterVisible(selectedCounterTypes)) {
                    this.setFirstValidScenario(currentScenarioId, scenarios);
                }
            })
        );

        this.subscription.add(
            this.scenarioControl.valueChanges.subscribe(id =>
                this.scenarioIdSelectedChanged.emit(id)
            )
        );
    }

    private updateScenarioFilterVisibility(selectedCounterTypes: Array<COUNTERS_COUNTER_TYPE>): void {
        this.scenarioFilterVisibility = this.isScenarioFilterVisible(selectedCounterTypes);
        const currentScenarioId = this.scenarioControl.value;
        const scenarios = this.scenarioItemsSubject.value;

        if (this.scenarioFilterVisibility) {
            this.setFirstValidScenario(currentScenarioId, scenarios);
        } else {
            this.scenarioControl.setValue(undefined);
        }
    }

    private setGroupingOptionFilter(): void {
        this.subscription.add(
            this.countersFiltersOptions.groupingOptions$.subscribe(groupingOptions =>
                this.groupingOptions = groupingOptions
            )
        );
    }

    private isScenarioFilterVisible(selectedCounterTypes: Array<COUNTERS_COUNTER_TYPE>): boolean {
        return selectedCounterTypes?.some(item =>
            item === COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO ||
            item === COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO
        );
    }

    private setFirstValidScenario(currentScenarioId: number, scenarios: Array<ScenarioItem>): void {
        if (!currentScenarioId || !scenarios.find(scenario => scenario.id === currentScenarioId).valid) {
            const firstValidScenario = scenarios.find(scenario => scenario.valid);
            this.scenarioControl.setValue(firstValidScenario ? firstValidScenario.id : undefined);
        }
    }
}
