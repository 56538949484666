import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { StatusPageService } from '../status-page-store/status-page.service';
import { STATUS } from '../status-page-store/status-page.store';
import { ManageTimeslotState, ManageTimeslotStore } from './manage-timeslot.store';
import { ActivityTypeTimeslots } from '../manage-template-store/manage-template.model';
import { ActivityTypeTimeslotsPutPost } from './manage-timeslot.model';
import { ManageTemplateCounterService } from '../manage-template-counter-store/manage-template-counter.service';

@Injectable({
    providedIn: 'root'
})
export class ManageTimeslotService extends StatusPageService<ManageTimeslotState> {
    private ongoingRequestsCount = 0;

    constructor(
        private readonly http: HttpClient,
        protected readonly manageTimeslotStore: ManageTimeslotStore,
        private readonly errorDialogService: ErrorDialogService,
        private readonly manageTemplateCounterService: ManageTemplateCounterService
    ) {
        super(manageTimeslotStore);
    }

    public getActivityDemandTimeslots(id: number): Observable<Array<ActivityTypeTimeslots>> {
        this.manageTimeslotStore.updateEntitiesLoadingState(true);
    
        return this.http.get<Array<ActivityTypeTimeslots>>(`/api/v1/ActivityDemand/Templates/${id}/ActivityTypeTimeslots`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTimeslotStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((timeslots: Array<ActivityTypeTimeslots>) => {
                this.manageTimeslotStore.set(timeslots);
                this.manageTimeslotStore.updateEntitiesLoadingState(false);
                this.manageTemplateCounterService.updateReloadCountersState(true);
            })
        );
    }

    public updateActivityDemandTimeslots(timeslots: Array<ActivityTypeTimeslotsPutPost> , templateId: number): Observable<Array<ActivityTypeTimeslots>> {
        if (timeslots.some(timeslot => timeslot.id === 0)) {
            this.manageTimeslotStore.updateEntitiesLoadingState(true);
            this.ongoingRequestsCount++;
        }

        return this.http.put<Array<ActivityTypeTimeslots>>(`/api/v1/ActivityDemand/Templates/${templateId}/ActivityTypeTimeslots`, timeslots).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTimeslotStore.updatePageStatusState(STATUS.DEFAULT);
                this.manageTimeslotStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((updatedTimeslots: Array<ActivityTypeTimeslots>) => {
                if (timeslots.some(timeslot => timeslot.id === 0)) {
                    this.manageTimeslotStore.add(updatedTimeslots); 
                } else {
                    this.manageTimeslotStore.set(updatedTimeslots);
                }
                this.manageTimeslotStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING);
                this.manageTemplateCounterService.updateReloadCountersState(true);
            }),
            finalize(() => {
                this.ongoingRequestsCount--; 
                if (this.ongoingRequestsCount === 0) {
                    this.manageTimeslotStore.updateEntitiesLoadingState(false); 
                }
            }),
        );
    }

    public deleteActivityDemandTimeslots(timeslotId: number , templateId: number): Observable<void> {
        this.manageTimeslotStore.updateEntitiesLoadingState(true);
        this.ongoingRequestsCount++;

        return this.http.delete<void>(`/api/v1/ActivityDemand/Templates/${templateId}/ActivityTypeTimeslots/${timeslotId}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTimeslotStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((response) => {
                if (response === null) { // no error was thrown
                    this.manageTimeslotStore.remove(timeslotId);
                    this.manageTemplateCounterService.updateReloadCountersState(true);
                }
            }),
            finalize(() => {
                this.ongoingRequestsCount--; 
                if (this.ongoingRequestsCount === 0) {
                    this.manageTimeslotStore.updateEntitiesLoadingState(false);
                }
            }),
        );
    }
}
