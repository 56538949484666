import { Injectable } from '@angular/core';
import { EntityState, Store, StoreConfig } from '@datorama/akita';

import { GlobalSettings } from './global-settings.model';

export interface GlobalSettingsState extends EntityState<GlobalSettings> {
    settings: GlobalSettings;
}

const initialState = {
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global-settings' })
export class GlobalSettingsStore extends Store<GlobalSettingsState> {
    constructor() {
        super(initialState);
    }

    public setGlobalSettings(globalSettings: GlobalSettings): void {
        this.update(state => ({ ...state, settings: globalSettings }));
    }
}
