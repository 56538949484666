import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { OwsSkillStore, OwsSkillState } from './ows-skill.store';

@Injectable({
    providedIn: 'root'
})
export class OwsSkillQuery extends QueryEntity<OwsSkillState> {

    constructor(
        protected store: OwsSkillStore
    ) {
        super(store);
    }
}
