import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'app-formly-multiselect-tree',
    templateUrl: './formly-multiselect-tree.component.html',
    styleUrls: ['./formly-multiselect-tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMultiSelectTreeComponent extends FieldType implements OnInit {
    public preselected: Observable<Array<any>>;

    public ngOnInit(): void {
        const initialSelectedIds = !!this.field.props.preSelectedEntityIds ? this.field.props.preSelectedEntityIds : this.model[this.field.key as string];

        this.preselected = this.field.formControl.valueChanges.pipe(
            startWith(initialSelectedIds)
        );
    }

    public onFilteredEntitiesChanged(ids: Array<number>): void {
        this.formControl.setValue(ids);
    }
}
