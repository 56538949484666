import { Injectable } from '@angular/core';
import { DateHelper, ViewPresetConfig } from '@bryntum/scheduler';

import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';
import { SCHEDULE_DATERANGE_TYPES } from './enums';

@Injectable({
    providedIn: 'root'
})
export class ScheduleCalendarTimeHelperService {
    // We need the dayparts here internally for the function returned by
    // "getGenerateTicksFunction" to have a reference
    private dayparts: Array<Daypart>;
    constructor() { }

    public getTimeRanges(startDate: Date, endDate: Date, selectedDayparts: Array<Daypart>): any {
        this.dayparts = selectedDayparts;
        const ammountOfDays = Math.ceil(DateHelper.diff(startDate, endDate, 'd'));
        const timeRanges = [];

        selectedDayparts?.forEach((daypart, idx) => {
            for (let i = 0; i < ammountOfDays; i++) {
                const currentDay = DateHelper.add(startDate, i, 'd');
                let endMinutesToAdd = daypart.endInMinutes;

                if (daypart.endInMinutes === 0) { // ends at midnight
                    endMinutesToAdd = 1440;
                }

                timeRanges.push({
                    name: daypart.name,
                    startDate: DateHelper.add(currentDay, daypart.startInMinutes, 'm'),
                    endDate: DateHelper.add(currentDay, endMinutesToAdd, 'm'),
                    cls: 'timerange-' + idx
                });
            }
        });

        return timeRanges;
    }

    public getViewPreset(daterangeType: SCHEDULE_DATERANGE_TYPES, daypartsSelected: boolean, slotWidth?: number): Partial<ViewPresetConfig> {
        switch (daterangeType) {
            case SCHEDULE_DATERANGE_TYPES.DAY: {
                return {
                    timeResolution: {
                        unit: 'hour',
                        increment: 1,
                    },
                    headers: [
                        {
                            unit: 'week',
                            renderer: (startDate) => `Week ${DateHelper.format(startDate, 'WW')} ${DateHelper.format(startDate, 'YYYY')}`
                        },
                        {
                            unit: 'day',
                            dateFormat: 'ddd DD-MM'
                        },
                        {
                            unit: 'hour',
                            dateFormat: 'HH:mm'
                        }
                    ],
                }
            }
            case SCHEDULE_DATERANGE_TYPES.WEEK:
                if (!daypartsSelected) {
                    return {
                        timeResolution: {},
                        headers: [
                            {
                                unit: 'week',
                                renderer: (startDate) => `Week ${DateHelper.format(startDate, 'WW')} ${DateHelper.format(startDate, 'YYYY')}`
                            },
                            {
                                unit: 'day',
                                dateFormat: 'ddd DD-MM',
                            },
                        ],
                    }
                } else {

                    return {
                        timeResolution: {},
                        headers: [
                            {
                                unit: 'week',
                                renderer: (startDate) => `Week ${DateHelper.format(startDate, 'WW')} ${DateHelper.format(startDate, 'YYYY')}`
                            },
                            {
                                unit: 'day',
                                dateFormat: 'ddd DD-MM'
                            },
                            {
                                unit: 'hour',
                                increment: 6,
                                dateFormat: 'HH:mm',
                            }
                        ],
                    }
                }
            default: {
                if (!daypartsSelected) {
                    return {
                        tickWidth: slotWidth,
                        timeResolution: {},
                        headers: [
                            {
                                unit: 'week',
                                renderer: (startDate) => `Week ${DateHelper.format(startDate, 'WW')} ${DateHelper.format(startDate, 'YYYY')}`
                            },
                            {
                                unit: 'day',
                                dateFormat: 'ddd DD-MM',
                            },
                        ],
                    }
                } 
                else {
                    return {
                        tickWidth: slotWidth,
                        timeResolution: {},
                        headers: [
                            {
                                unit: 'week',
                                renderer: (startDate) => `Week ${DateHelper.format(startDate, 'WW')} ${DateHelper.format(startDate, 'YYYY')}`
                            },
                            {
                                unit: 'day',
                                dateFormat: 'ddd DD-MM'
                            },
                            {
                                unit: 'hour',
                                increment: 6,
                                dateFormat: 'HH:mm',
                            }
                        ],
                    }
                }
            }
        }
    }

    public getGenerateTicksFunction(selectedDayparts: Array<Daypart>): any {
        this.dayparts = selectedDayparts;
        
        return (startDate: Date, endDate: Date, unit: string, increment: number) => {
            const ticks = [];

            // NOTE: for some reason this doesn't work if you use a "map"
            // That's why this is pushed to an array (touch at own risk!)
            if (this.dayparts.length > 0) {
                const ammountOfDays = DateHelper.diff(startDate, endDate, 'd');

                for (let i = 0; i < ammountOfDays; i++) {
                    this.dayparts.forEach((daypart) => {
                        const currentDay = DateHelper.startOf(DateHelper.add(startDate, i, 'd'), 'd');
                        let endMinutesToAdd = daypart.endInMinutes;

                        if (daypart.endInMinutes === 0) { // ends at midnight
                            endMinutesToAdd = 1440;
                        }

                        ticks.push(
                            {
                                startDate: DateHelper.add(currentDay, daypart.startInMinutes, 'm'),
                                endDate: DateHelper.add(currentDay, endMinutesToAdd, 'm')
                            }
                        );
                    })
                };
            }
            else {
                while (startDate < endDate) {
                    ticks.push({
                        id: ticks.length + 1,
                        startDate,
                        endDate: DateHelper.add(startDate, increment, unit)
                    });
                    startDate = DateHelper.add(startDate, increment, unit);
                }

            }

            return ticks;
        }
    }
}
