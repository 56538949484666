import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { GlobalSettingsState, GlobalSettingsStore } from './global-settings.store';

@Injectable({
    providedIn: 'root'
})
export class GlobalSettingsQuery extends Query<GlobalSettingsState> {
    constructor(
        protected store: GlobalSettingsStore
    ) {
        super(store);
    }

    public getTimeZone(): Observable<string> {
        return this.select(state => state.settings).pipe(
            filter(settings => !!settings && settings.timeZone !== undefined),
            map(settings => settings.timeZone)
        );
    }

    public getSystemLanguage(): Observable<string> {
        return this.select(state => state.settings).pipe(
            filter(settings => !!settings && settings.systemLanguage !== undefined),
            map(settings => settings.systemLanguage)
        );
    }

    public getTimeZoneSync(): string {
        const settings = this.getValue().settings;

        return settings ? settings.timeZone : undefined;
    }

    public getSystemLanguageSync(): string {
        const settings = this.getValue().settings;

        return settings ? settings.systemLanguage : undefined;
    }
}
