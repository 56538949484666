<div class="bol-modal__header">
    <span class="modal-title">{{ 'Filters' | translate }}</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
        <i class="material-icons">close</i>
    </div>
</div>

<mat-dialog-content>
    <div
        class="empty-state"
        *ngIf="(dataSource$ | async).filteredData.length === 0">
        <mat-icon>settings</mat-icon>
        <div class="empty-state-description" translate data-cy="noFilterAvailableYet">
            No filters available yet
        </div>
    </div>

    <div *ngIf="(dataSource$ | async).filteredData.length > 0">
        <mat-table
            [dataSource]="dataSource$ | async"
            class="bol-table bol-table-striped-even bol-table-hovered"
            matSort
            matSortActive="displayName"
            matSortDirection="asc">
            <ng-container matColumnDef="displayName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span translate>Display name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let setting">
                    <mat-form-field class="bol-form-field form-field-white">
                        <input
                            matInput
                            #inputFilterSettingName
                            [ngModel]="setting.displayName"
                            (change)="onChangeFilterSetting($event.target.value, setting)"
                            required>
                        <mat-icon matSuffix>mode_edit</mat-icon>
                    </mat-form-field>
                    <mat-error
                        *ngIf="inputFilterSettingName?.value === ''"
                        translate>
                        Please enter a filter name
                    </mat-error>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationDate">
                <mat-header-cell *matHeaderCellDef translate mat-sort-header>
                    <span translate>Creation date</span>
                </mat-header-cell>
              <mat-cell *matCellDef="let setting">{{ setting.creationDate | date: (dateFormat$ | async)  }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="deleteSetting">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let setting">
                    <div>
                        <button
                            data-cy="deleteFilterButton"
                            mat-button
                            class="bol-button-text icon-only bol-gray"
                            (click)="onDeleteFilterSetting(setting)">
                            <mat-icon>delete</mat-icon> 
                        </button>
    
                        <button
                            mat-button
                            [disabled]="selectedFilterSetting(setting.id) | async"
                            class="bol-button-text"
                            (click)="onSelectFilterSetting(setting)">{{ ((selectedFilterSetting(setting.id) | async) ? 'Selected' : 'Select') | translate }}</button>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['displayName', 'creationDate', 'deleteSetting']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['displayName', 'creationDate', 'deleteSetting']"></mat-row>
        </mat-table>
        <mat-paginator [pageSize]="5"></mat-paginator>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button mat-button class="bol-button-text bol-gray" (click)="onCloseModal()" data-cy="closeFiltersButton">
            <span translate>Close</span>
        </button>
    </div>
</mat-dialog-actions>