import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { SkillMapping } from './skill-mapping.model';

export interface SkillMappingState extends EntityState<SkillMapping> {
    ui: {
        selectedSkillId: number;
        selectedOrganizationUnitId: number;
        showUnderlyingUnits: boolean;
        selectedOwsDepartmentId: number;
        showAllOwsSkills: boolean;
        hideUnlinkedOwsSkills: boolean;
        showLinkedOwsSkills: boolean;
    };
}

const initialState = {
    ui: {
        selectedSkillId: undefined,
        selectedOrganizationUnitId: undefined,
        showUnderlyingUnits: false,
        selectedOwsDepartmentId: undefined,
        showAllOwsSkills: true,
        hideUnlinkedOwsSkills: false,
        showLinkedOwsSkills: false
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'skill-mapping', idKey: 'owsSkillId' })
export class SkillMappingStore extends EntityStore<SkillMappingState> {

    constructor() {
        super(initialState);
    }

    public updateSelectedSkillId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedSkillId: id };

            return { ...state, ui };
        });
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOrganizationUnitId: id };
            
            return { ...state, ui };
        });
    }

    public updateShowChildUnits(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showUnderlyingUnits: value };
            
            return { ...state, ui };
        });
    }

    public updateSelectedOwsDepartmentId(id: number): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOwsDepartmentId: id, showAllOwsSkills: false };
            
            return { ...state, ui };
        });
    }

    public updateShowAllOwsSkills(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showAllOwsSkills: value, selectedOwsDepartmentId: undefined };
            
            return { ...state, ui };
        });
    }

    public updateHideUnlinkedOwsSkills(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, hideUnlinkedOwsSkills: value };

            return { ...state, ui };
        });
    }  

    public updateShowLinkedOwsSkills(value: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, showLinkedOwsSkills: value };

            return { ...state, ui };
        });
    }  
}
