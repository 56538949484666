import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { EntityUI, EntityUIState, getDefaultUIState } from '../entity-ui-models';

import { User } from './user.model';

export interface UserState extends EntityState<User> {
    ui: {
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends EntityStore<UserState> {
    public ui: EntityUIStore<EntityUIState>;

    constructor() {
        super(initialState);
        this.createUIStore().setInitialEntityState<EntityUI>(getDefaultUIState());
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };
            
            return { ...state, ui };
        });
    }
}
