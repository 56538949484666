import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { filterUndefined } from '@ortec/soca-web-ui';
import { SelectionClickEvent } from 'src/app/shared/components/entity-management/entity-list-panel/entity-list-panel.component';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { ActivityTypeCategoryService } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';

import { ActivityTypeMappingQuery } from './activity-type-mapping-store/activity-type-mapping.query';
import { OwsDepartmentService } from '../ows-department-mapping/ows-department-store/ows-department.service';
import { ActivityTypeMappingService } from './activity-type-mapping-store/activity-type-mapping.service';
import { OwsDutySet, OwsDutyTableElement } from './ows-duty-set-store/ows-duty-set.model';
import { OwsDutySetQuery } from './ows-duty-set-store/ows-duty-set.query';
import { OwsDutySetService } from './ows-duty-set-store/ows-duty-set.service';

@Component({
    selector: 'app-ows-activity-type-mapping-old',
    templateUrl: './ows-activity-type-mapping.component.html',
    styleUrls: ['./ows-activity-type-mapping.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsActivityTypeMappingOldComponent implements OnInit {
    public owsDutySets$: Observable<Array<OwsDutySet>>;
    public visibleOwsDuties$: Observable<Array<OwsDutyTableElement>>;
    public visibleActivityTypes$: Observable<Array<ActivityType>>;
    public unlinkedDutiesCount$: Observable<number>;
    public selectedActivityTypeId$: Observable<number>;
    public hideUnlinkedOwsDuties$: Observable<boolean>;
    public organizationsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public activityTypeCategories$: Observable<Array<ActivityTypeCategory>>;
    public selectedActivityTypeCategory$: Observable<number>;
    public selectedOrganizationUnit$: Observable<number>;
    public showUnderlyingUnits$: Observable<boolean>;
    public showPastActivityTypes$: Observable<boolean>;
    public showFutureActivityTypes$: Observable<boolean>;
    public selectedDutySet$: Observable<number>;
    public searchProperties = ['displayName'];

    constructor(
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly owsDutySetQuery: OwsDutySetQuery,
        private readonly owsDutySetService: OwsDutySetService,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeMappingService: ActivityTypeMappingService,
        private readonly activityTypeMappingQuery: ActivityTypeMappingQuery,
        private readonly activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly activityTypeCategoryService: ActivityTypeCategoryService,
        private readonly displaySettingQuery: DisplaySettingQuery,
        private readonly displaySettingService: DisplaySettingService,
        private readonly owsDepartmentService: OwsDepartmentService
    ) { }

    public ngOnInit(): void {
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe();
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();
        this.owsDutySetService.get().pipe(first()).subscribe();
        this.activityTypeMappingService.get().pipe(first()).subscribe();
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.owsDepartmentService.get().pipe(first()).subscribe();

        this.owsDutySets$ = this.owsDutySetQuery.getDutySetsForFiltering();
        this.visibleOwsDuties$ = this.activityTypeMappingQuery.getFilteredOwsDuties();
        this.visibleActivityTypes$ = this.activityTypeMappingQuery.getFilteredActivityTypesWithFullName();
        this.selectedOrganizationUnit$ = this.activityTypeMappingQuery.getSelectedOrganizationUnit();
        this.showUnderlyingUnits$ = this.activityTypeMappingQuery.getShowUnderlyingUnitsState();
        this.showPastActivityTypes$ = this.activityTypeMappingQuery.getShowPastActivityTypesState();
        this.showFutureActivityTypes$ = this.activityTypeMappingQuery.getShowFutureActivityTypesState();
        this.selectedDutySet$ = this.activityTypeMappingQuery.getSelectedOwsDutySet();

        this.activityTypeCategories$ = this.activityTypeCategoryQuery.getActivityTypeCategoriesForFiltering();
        this.selectedActivityTypeCategory$ = this.activityTypeMappingQuery.getSelectedActivityTypeCategory();

        this.unlinkedDutiesCount$ = this.activityTypeMappingQuery.getUnlinkedDutiesCount();
        this.selectedActivityTypeId$ = this.activityTypeMappingQuery.getSelectedActivityTypeId();
        this.hideUnlinkedOwsDuties$ = this.activityTypeMappingQuery.getUnlinkedOwsDutyVisibility();
        this.organizationsForFiltering$ = this.organizationUnitQuery.getOrganizationsForFiltering();

        this.setInitialStatesFromDisplaySettings();
    }

    public onSelectedDutySetChange(id: number): void {
        this.activityTypeMappingService.updateSelectedDutySet(id);
    }

    public onSelectOwsDutyForMapping(event: SelectionClickEvent): void {
        this.activityTypeMappingService.updateMappings(event.entity);
    }

    public onSelectActivityType(id: number): void {
        this.activityTypeMappingService.updateSelectedActivityId(id);
    }

    public onSelectActivityTypeCategoryChange(id: number): void {
        this.activityTypeMappingService.updateSelectedActivityTypeCategory(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ACTIVITY_TYPE_CATEGORY, id).pipe(first()).subscribe();
    }

    public toggleUnlinkedOwsDutyVisibility(): void {
        this.activityTypeMappingService.toggleUnlinkedOwsDutyVisibility();
    }

    public onSelectedOrganizationUnitChange(id: number): void {
        this.activityTypeMappingService.updateSelectedOrganizationUnitId(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, id).pipe(first()).subscribe();
    }

    public onUpdateShowChildUnits(event: MatCheckboxChange): void {
        this.activityTypeMappingService.updateShowChildUnits(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, event.checked).pipe(first()).subscribe();
    }

    public onUpdateShowPastActivityTypes(event: MatCheckboxChange): void {
        this.activityTypeMappingService.updateShowPastActivityTypes(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_PAST_ACTIVITY_TYPES, event.checked).pipe(first()).subscribe();
    }

    public onUpdateShowFutureActivityTypes(event: MatCheckboxChange): void {
        this.activityTypeMappingService.updateShowFutureActivityTypes(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_FUTURE_ACTIVITY_TYPES, event.checked).pipe(first()).subscribe();
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.activityTypeMappingService.updateSelectedOrganizationUnitId(id))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ACTIVITY_TYPE_CATEGORY, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.activityTypeMappingService.updateSelectedActivityTypeCategory(id))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.activityTypeMappingService.updateShowChildUnits(state))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_PAST_ACTIVITY_TYPES, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.activityTypeMappingService.updateShowPastActivityTypes(state))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_FUTURE_ACTIVITY_TYPES, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.activityTypeMappingService.updateShowFutureActivityTypes(state))
        ).subscribe();
    }
}
