import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { Permission } from 'src/app/shared/models/Enums';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { SkillLevelQuery } from 'src/app/shared/stores/skill-level-store/skill-level.query';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';

import { SkillsManagementState, SkillsManagementStore } from './skills-management.store';

@Injectable({
    providedIn: 'root'
})
export class SkillsManagementQuery extends Query<SkillsManagementState> {
    constructor(
        protected store: SkillsManagementStore,
        protected skillQuery: SkillQuery,
        protected skillLevelQuery: SkillLevelQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected resourceTypeQuery: ResourceTypeQuery,
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return combineLatest([
            this.skillQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([skillsLoading, unitsLoading]) => {
                return !skillsLoading && !unitsLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    public getFilteredSkills(): Observable<Array<Skill>> {
        return combineLatest([
            this.skillQuery.getSkillsUI(),
            this.getSelectedOrganizationUnit(),
            this.getShowUnderlyingUnitsState(),
            this.getShowAllSkillsState(),
        ]).pipe(
            map(([skills, selectedOrganizationUnitId, getShowUnderlyingUnitsState, getShowAllSkillsState]) => {
                if (getShowAllSkillsState) {
                    return skills;
                }
                
                return this.filterSkillsOnOrganizationUnit(skills, selectedOrganizationUnitId, getShowUnderlyingUnitsState);
            })
        );
    }

    public isSkillVisible(id: number): boolean {
        if (this.getValue().showAll) {
            return true;
        }

        const visibleSkills = this.filterSkillsOnOrganizationUnit(
            this.skillQuery.getSkillsSync(),
            this.getValue().selectedOrganizationUnitId,
            this.getValue().showUnderlyingUnits
        );

        return visibleSkills.find(entity => entity.id === id) !== undefined;
    }

    public getShowAllSkillsState(): Observable<boolean> {
        return this.select(state => state.showAll);
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }

    public getManageModeSync(): MANAGE_MODE {
        return this.getValue().manageMode;
    }

    public getSelectedSkill(): Observable<Skill> {
        return this.getCurrentSkillId().pipe(
            switchMap((skillId) => {
                if (skillId === -1) {
                    return of(this.getNewSkillObject([this.getSelectedOrganizationUnitSync()]));
                } else {
                    return this.skillQuery.getSkill(skillId);
                }
            })
        );
    }

    public getNewSkillObject(organizationUnitIds: Array<number> = []): Skill {
        return {
            id: -1,
            displayName: '',
            externalId: '',
            validOrganizationUnitIds: organizationUnitIds,
            validResourceTypeIds: [],
            validSkillLevelIds: []
        };
    }

    public getSelectedUISkill(): Observable<EntityUI> {
        return this.getCurrentSkillId().pipe(
            switchMap((skillId) => {
                return this.skillQuery.getUISkill(skillId);
            })
        );
    }

    public getCurrentSkillId(): Observable<number> {
        return this.select(state => state.selectedSkillId);
    }

    public getCurrentSkillIdSync(): number {
        return this.getValue().selectedSkillId;
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.selectedOrganizationUnitId);
    }

    public getSelectedOrganizationUnitSync(): number {
        return this.getValue().selectedOrganizationUnitId;
    }

    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.showUnderlyingUnits);
    }

    public getCanDeleteSelectedEntityState(): Observable<boolean> {
        return this.getSelectedSkill().pipe(
            filter(skill => !!skill),
            map(skill =>
                !(skill.hasDependingActivityTypes || skill.hasDependingResources) &&
                (!skill.validResourceTypeIds || skill.validResourceTypeIds.length === 0))
        );
    }

    public getFilteredResourceTypesForSelectedEntity(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.getSelectedSkill(),
            this.resourceTypeQuery.getResourceTypes(),
        ]).pipe(
            filter(([selectedSkill, resourceTypes]) => !!selectedSkill && resourceTypes?.length > 0),
            map(([_, resourceTypes]) => {

                return resourceTypes.filter(resourceType => {
                    const validOrganizationUnits: Array<OrganizationUnit> = this.organizationUnitQuery.getOrganizationUnitsByIdsSync(resourceType.validOrganizationUnitIds);

                    return validOrganizationUnits.some(unit => unit.maxPermissionForCurrentUser >= Permission.readWrite);
                });
            })
        );
    }

    private filterSkillsOnOrganizationUnit(skills: Array<Skill>, organizationUnitId: number, showUnderlyingUnits: boolean): Array<Skill> {
        const organizationUnit = this.organizationUnitQuery.getOrganizationUnitByIdSync(organizationUnitId);
        if (organizationUnit) {
            const validUnitIds = [organizationUnit.id];
            if (showUnderlyingUnits) {
                const childUnitIds = this.organizationUnitQuery.getAllChildUnitsForUnitSync(organizationUnitId)?.map(unit => unit.id);
                validUnitIds.push(...(childUnitIds || []));
            }

            return skills.filter(skill => (skill.validOrganizationUnitIds || []).some(id => validUnitIds.includes(id)));
        }

        return [];
    }
}
