import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { first } from 'rxjs';
import { ManageTemplateCounterService } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.service';

@Component({
    selector: 'app-adt-counter-table-menu',
    templateUrl: './adt-counter-table-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdtCounterTableMenuComponent {
    @Input() public readonly activityDemandCounterId: number;
    @Input() public readonly templateId: number;
    @Input() public readonly counterId: number;
    @ViewChild(MatMenu) public readonly menu: MatMenu;

    constructor(
        private manageTemplatesManagementService: ManageTemplateCounterService,
    ) { }

    public onDeleteCounter(): void {
        this.manageTemplatesManagementService.deleteActivityDemandCounter(this.templateId, this.activityDemandCounterId, this.counterId).pipe(first()).subscribe();
    }
}
