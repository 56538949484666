import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { OwsResourceType, OwsResourceTypeTableElement } from '../ows-resource-type-store/ows-resource-type.model';
import { OwsResourceTypeQuery } from '../ows-resource-type-store/ows-resource-type.query';

import { ResourceTypeMappingState, ResourceTypeMappingStore } from './resource-type-mapping.store';
import { ResourceTypeMapping } from './resource-type-mapping.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeMappingQuery extends Query<ResourceTypeMappingState> {

    constructor(
        protected store: ResourceTypeMappingStore,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        private readonly owsResourceTypeQuery: OwsResourceTypeQuery,
        private readonly translateService: TranslateService
    ) {
        super(store);
    }

    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.ui.showUnderlyingUnits);
    }

    public getMappingsSync(): Array<ResourceTypeMapping> {
        return this.getValue().ui.mappings;
    }

    public getMappings(): Observable<Array<ResourceTypeMapping>> {
        return this.select(state => state.ui.mappings);
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.ui.selectedOrganizationUnitId);
    }

    public getSelectedOwsDepartment(): Observable<number> {
        return this.select(state => state.ui.selectedOwsDepartmentId);
    }

    public getSelectedOwsDepartmentForMapping(): Observable<number> {
        return this.select(state => state.ui.selectedOwsDepartmentIdForMapping);
    }

    public getSelectedOwsDepartmentForMappingSync(): number {
        return this.getValue().ui.selectedOwsDepartmentIdForMapping;
    }

    public getIsGlobal(): Observable<boolean> {
        return this.select(state => state.ui.isGlobal);
    }

    public getIsGlobalSync(): boolean {
        return this.getValue().ui.isGlobal;
    }

    public getShowAllOwsResourceTypes(): Observable<boolean> {
        return this.select(state => state.ui.showAllOwsResourceTypes);
    }

    public getSelectedResourceTypeId(): Observable<number> {
        return this.select(state => state.ui.selectedResourceTypeId);
    }

    public getSelectedOwsDepartmentIdForMapping(): Observable<number> {
        return this.select(state => state.ui.selectedOwsDepartmentIdForMapping);
    }

    public getUnlinkedOwsResourceTypeVisibility(): Observable<boolean> {
        return this.select(state => state.ui.hideUnlinkedOwsResourceTypes);
    }

    public getLinkedOwsResourceTypeVisibility(): Observable<boolean> {
        return this.select(state => state.ui.showLinkedOwsResourceTypes);
    }

    public getFilteredResourceTypes(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.resourceTypeQuery.getResourceTypes(),
            this.getSelectedOrganizationUnit(),
            this.getShowUnderlyingUnitsState(),
            this.getMappings(),
            this.getIsGlobal(),
        ]).pipe(
            map(([resourceTypes, filteredOrganizationIds, showUnderlyingUnits, mappedResources, isGlobal]) => {
                if (isGlobal) {
                    resourceTypes = resourceTypes.map(resourceType => ({
                        ...resourceType,
                        icon: mappedResources.some(mappedResource => mappedResource.resourceTypeId === resourceType.id && mappedResource.owsDepartmentId !== null)
                    }));
                }
                if (!showUnderlyingUnits) {
                    return resourceTypes.filter(resourceType => resourceType.validOrganizationUnitIds.includes(filteredOrganizationIds));
                } else {
                    const childUnitIds = this.organizationUnitQuery.getAllChildUnitsForUnitSync(filteredOrganizationIds).map(unit => unit.id);
                    childUnitIds.push(filteredOrganizationIds);

                    return resourceTypes.filter(resourceType => resourceType.validOrganizationUnitIds.some(id => childUnitIds.includes(id)));
                }
            })
        );
    }

    public getEnableDisableSelectAll(): Observable<boolean> {
        return combineLatest([
          this.getSelectedResourceTypeId(),
          this.getSelectedOwsDepartmentForMapping(),
          this.getIsGlobal(),
          this.getFilteredOwsResourceTypes(),
        ]).pipe(
            map(([selectedResourceTypeId, selectedOwsDepartmentForMapping, isGlobal, filteredOwsResourceTypes]) => {
                const allPositionsChecked = filteredOwsResourceTypes.every(resourceType => resourceType.checked && resourceType.linkedOmrpResourceType?.id !== selectedResourceTypeId);

                return(selectedResourceTypeId === undefined || (selectedOwsDepartmentForMapping === undefined && !isGlobal) || allPositionsChecked);
            })
        );
    }

    public getFilteredOwsResourceTypes(): Observable<Array<OwsResourceTypeTableElement>> {
        return combineLatest([
            combineLatest([
                this.getAllMappedOwsResourceTypes(),
                this.getUnlinkedOwsResourceTypeVisibility(),
                this.getLinkedOwsResourceTypeVisibility(),
                this.getSelectedResourceTypeId()
            ]),
            combineLatest([
                this.getSelectedOwsDepartment(),
                this.getSelectedOwsDepartmentForMapping(),
                this.getShowAllOwsResourceTypes(),
                this.getIsGlobal()
            ])
        ]).pipe(map(([[owsResourceTypes, hideUnlinked, showOnlySelected, selectedResourceTypeId], [selectedOwsDepartment, selectedOwsDepartmentForMapping, showAllOwsResourceTypes, isGlobal]]) => {
            const visibleResourceTypes = owsResourceTypes.filter(resourceType => hideUnlinked ?
                (resourceType.linkedOmrpResourceType === null || resourceType.linkedOmrpResourceType?.id === selectedResourceTypeId) : true
            ).filter(resourceType => showOnlySelected ? resourceType.linkedOmrpResourceType?.id === selectedResourceTypeId : true);
            const tableElements = visibleResourceTypes.map(resourceType => {
                let displayName = resourceType.name;
                const isLinked = resourceType.linkedOmrpResourceType !== null;
                if (isLinked && resourceType.linkedOmrpResourceType?.id !== selectedResourceTypeId) {
                    const displayNameOfLinkedOmrpResourceType = resourceType.linkedOmrpResourceType?.displayName || this.translateService.instant('Unknown');
                    displayName += ' - ' + displayNameOfLinkedOmrpResourceType;
                };

                return {
                    ...resourceType,
                    checked: isLinked,
                    disabled: selectedResourceTypeId === undefined || (isLinked && resourceType.linkedOmrpResourceType?.id !== selectedResourceTypeId) || (selectedOwsDepartmentForMapping === undefined && !isGlobal),
                    displayName
                };
            });

            if (showAllOwsResourceTypes || selectedOwsDepartment === undefined) {
                return tableElements;
            }
            else {
                return tableElements.filter(resourceType => resourceType.departmentId === selectedOwsDepartment);
            }
        }));
    }

    public getAllMappedOwsResourceTypes(): Observable<Array<OwsResourceType>> {
        return combineLatest([
            this.getMappings(),
            this.owsResourceTypeQuery.selectAll(),
            this.resourceTypeQuery.selectAll(),
            this.getIsGlobal(),
            this.getSelectedOwsDepartmentForMapping()
        ]).pipe(
            filter(([mappings, _]) => !!mappings),
            map(([mappings, owsResourceTypes, ResourceTypes, isGlobal, selectedOwsDepartmentForMapping]) => {
                mappings = isGlobal
                    ? mappings.filter(entity => entity.owsDepartmentId === null && entity.owsDepartmentId === null)
                    : mappings.filter(entity => entity.owsDepartmentId !== null && entity.owsDepartmentId === selectedOwsDepartmentForMapping);

                return owsResourceTypes.map(resourceType => ({
                    ...resourceType,
                    linkedOmrpResourceType: mappings.find(x => x.owsFunctionId === resourceType.id) ?
                        ResourceTypes.find(s => s.id === mappings.find(x => x.owsFunctionId === resourceType.id).resourceTypeId) : null
                })).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);;
            })
        );
    }

    public getUnlinkedResourceTypeCount(): Observable<number> {
        return this.getAllMappedOwsResourceTypes().pipe(
            map(ResourceTypes => {
                return ResourceTypes.filter(resourceType => resourceType.linkedOmrpResourceType === null).length;
            })
        );
    }
}
