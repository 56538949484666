<div class="content-wrapper">

    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <mat-card  class="bol-card full-height"></mat-card>
            </div>
        </div>

        <div class="flex-column">
            <app-entity-filter-panel>
                <div>
                    <mat-checkbox
                        class="bol-checkbox"
                        [checked]="hideUnlinkedOwsDepartments$ | async"
                        (change)="onHideUnlinkedOwsDepartments($event)"
                        data-cy="checkboxHideLinkedDepartments"
                    >{{ 'Hide linked Departments' | translate }}</mat-checkbox>
                </div>

                <br/>
    
                <span class="unlinked__title">{{'Unlinked Departments' | translate}}:</span>
                <div class="bol-badge-solid"
                    data-cy="numberUnlinkedDepartments"
                    [ngClass]="{'bol-warning': (unlinkedDepartmentCount$ | async) > 0, 'bol-primary': (unlinkedDepartmentCount$ | async) === 0}">
                    {{unlinkedDepartmentCount$ | async}}
                </div>

                <p *ngIf="(selectedOrganizationUnitId$ | async) === undefined && (owsDepartments$ | async)?.length > 0" data-cy="noSelectionWarningMessage">
                    <mat-icon class="inline-icon">warning</mat-icon>
                    <span translate>Please select an OMRP Organization Unit from the left panel to map to!</span>
                </p>

            </app-entity-filter-panel>
        </div>
    </div>


    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-tree-list-panel                 
                    entityName="OMRP Organization Units"
                    [entities]="organizationUnits$ | async"
                    [searchProperties]="['displayName']"
                    [selectedEntityId]="selectedOrganizationUnitId$ | async"
                    (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                    [hideAddButton]="true">
                </app-entity-tree-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-tree-list-panel                 
                    entityName="OWS Departments"
                    [entities]="owsDepartments$ | async"
                    (selectionClickChanged)="onSelectOwsDepartmentForMapping($event)"
                    [disableSelection]="true"
                    [showCheckboxes]="true"
                    [searchProperties]="['displayName']"
                    [hideAddButton]="true">
                </app-entity-tree-list-panel>
            </div>
        </div>
    </div>
</div>