import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface TemplatesManagementState {
    initialLoadingStateForGrid: boolean;
}

const initialState = {
    initialLoadingStateForGrid: true
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'templates-management' })
export class ManageTemplatesManagementStore extends Store<TemplatesManagementState> {
    constructor() {
        super(initialState);
    }
    public updateInitialLoadingStateForGrid( initialLoadingStateForGrid: boolean): void {
        this.update(state => ({ ...state,  initialLoadingStateForGrid }));
    }
}
