import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { OwsSkill } from './ows-skill.model';

export interface OwsSkillState extends EntityState<OwsSkill> {
    ui: {
    };
}

const initialState = {
    ui: {
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-skill' })
export class OwsSkillStore extends EntityStore<OwsSkillState> {

    constructor() {
        super(initialState);
    }
}
