<mat-card class="bol-card" class="content-wrapper">
    <mat-card-content>
        <div *ngIf="true">
            <div>
                <h3 translate>Dayparts</h3>
            </div>
            
            <div class="add-dayparts">
                <button
                    mat-button
                    class="bol-button-solid bol-positive"
                    (click)="showAddDaypartContainer()"
                    [disabled]="addDaypart$ | async">
                    <div translate>Add daypart</div>
                </button>
            </div>

            <div
                class="dayparts-list-container"
                *ngIf="((dayparts$ | async).length > 0) || (addDaypart$ | async)"
                [ngClass]="{'error-dayparts-list-container': errorAddDaypart}">
                <div class="dayparts-list-header daypart-row">
                    <div class="daypart-name" translate>
                        Daypart
                    </div>
                    <div class="daypart-start" translate>
                        Start time
                    </div>
                </div>

                <div *ngFor="let daypart of (dayparts$ | async)">
                    <app-settings-daypart-item
                        [ngModel]="daypart"
                        [errorOnUpdate]="errorOnUpdate$ | async"
                        [dayparts]="dayparts$ | async"
                        (removeDaypartEvent)="onDeleteDaypart($event)"
                        (updateDaypartEvent)="onUpdateDaypart($event)"
                        [daypartItemErrorClass]="errorAddDaypart"
                    ></app-settings-daypart-item>
                </div>
                
                <div *ngIf="addDaypart$ | async">
                    <app-settings-daypart-item
                        [ngModel]="newDaypart"
                        [dayparts]="dayparts$ | async"
                        [addDaypart]="true"
                        (removeDaypartEvent)="resetAddDaypartContainer()"
                        (addDaypartEvent)="onAddDaypart($event)"
                        [daypartItemErrorClass]="errorAddDaypart"
                    ></app-settings-daypart-item>
                </div>
            </div>
            <div *ngIf="errorAddDaypart" class="error-delete-daypart">
                <mat-error translate>Please enter at least two dayparts.</mat-error>
                <mat-error translate>The daypart will be saved when there are at least two.</mat-error>
            </div>

            <div
                class="empty-state"
                *ngIf="showEmptyState$ | async">
                <mat-icon>timelapse</mat-icon>
                <div class="empty-state-description" translate>
                    No dayparts available yet
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>