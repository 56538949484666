import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Memoized } from '@ortec/utilities/core';
import { Observable, Subscription, combineLatest, filter, first, map, of, startWith, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { cache } from '@ortec/utilities/rxjs';
import { FormControl } from '@angular/forms';

import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { ManageCounterService } from 'src/app/shared/stores/manage-counter-store/manage-counter.service';
import { ManageTemplateCounterService } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.service';

import { EditTemplatesManagementService } from '../../store/edit-template-management.service';
import { EditTemplatesManagementQuery } from '../../store/edit-template-management.query';
import { WeekValues } from '../../store/models';
import { AdtCountersDialogComponent, ICountersDialogData } from '../adt-counters-dialog/adt-counters-dialog.component';
import { AdtCounter } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTemplateCounterQuery } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.query';

@Component({
    selector: 'app-adt-counter-table',
    templateUrl: './adt-counter-table.component.html',
    styleUrls: ['./adt-counter-table.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdtCounterTableComponent implements OnInit, OnDestroy {
    public searchTerm = new FormControl('');
    public counters: Array<AdtCounter> = [];
    public currentTemplateId: number;

    private readonly subscriptions = new Subscription();
    
    private readonly editTemplatesManagementService = inject(EditTemplatesManagementService);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly manageTemplateQuery = inject(ManageTemplateQuery);
    private readonly manageTemplateCounterService = inject(ManageTemplateCounterService);
    private readonly manageTemplateCounterQuery = inject(ManageTemplateCounterQuery);
    private readonly manageCounterService = inject(ManageCounterService);
    private readonly dialogService = inject(MatDialog);

    public ngOnInit(): void {
        this.manageCounterService.getCounters().pipe(first()).subscribe();
        this.currentTemplateId = this.editTemplatesManagementQuery.getCurrentTemplateIdSync();

        this.subscriptions.add(
            this.getFilteredCountersValuesBasedOnSearching()
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    
    public onAddCounter(): void {
        const dialogRef = this.dialogService.open(AdtCountersDialogComponent, { width: '600px', autoFocus: false });

        this.subscriptions.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: ICountersDialogData }) => {
                    if (dialogData) {
                        this.manageTemplateCounterService.updateActivityDemandCounters(dialogData.data.selectedCounterIds, this.currentTemplateId).pipe(first()).subscribe();  
                    }
                })
            ).subscribe()
        );
    }

    public onRefreshCounters(): void {
        this.manageTemplateCounterService.getCounters(this.currentTemplateId).pipe(first()).subscribe();
    }

    @Memoized public get counterRows$(): Observable<Array<AdtCounter>> {
        return this.editTemplatesManagementQuery.getCountersEntities().pipe(
            cache()
        );
    }

    @Memoized public get currentWeekValues$(): Observable<Array<WeekValues>> {
        return combineLatest({
            currentWeek: this.editTemplatesManagementQuery.getCurrentWeekNumber(),
            counters: this.counterRows$,
        }).pipe(
            map(({currentWeek, counters}) => {
                return counters.map(counter => {
                    if (counter.weeksDemand === undefined || counter.weeksDemand === null) {
                        return null;
                    }
                    return counter?.weeksDemand[currentWeek - 1];
                })
            }),
            cache()
        );
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest({
            loading: this.manageTemplateQuery.getEntitiesLoadingState(),
            countersLoading: this.manageTemplateCounterQuery.getEntitiesLoadingState(),
        }).pipe(
            filter(({loading, countersLoading}) => {
                return !loading && !countersLoading;
            }),
            map(() => true),
            first(),
            startWith(false),
            cache()
        );
    }

    @Memoized public get countersLoading$(): Observable<boolean> {
        return this.manageTemplateCounterQuery.getEntitiesLoadingState().pipe(
            cache()
        );
    }

    @Memoized public get calculatingCountersInProgress$(): Observable<boolean> {
        return this.manageTemplateCounterQuery.getCalculatingCountersInProgressState().pipe(
            cache()
        );
    }

    @Memoized public get showCountersRefreshButton$(): Observable<boolean> {
        // We need to show the refresh button when:
        // - A new timeslot is added
        // - A timeslot is removed
        // - A timeslot is edited (the count per days)
        // Set to false after reloading
        return this.manageTemplateCounterQuery.getReloadCountersState();
    }

    private getFilteredCountersValuesBasedOnSearching(): Subscription {
        return this.searchTerm.valueChanges.pipe(
            startWith(''),
            map((searchTerm) => {
                if (this.searchTerm.valid) {
                    this.editTemplatesManagementService.updateSearchCountersValue(searchTerm);
                }
            })
        ).subscribe()
    }
}