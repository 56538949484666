import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityUIState } from '../entity-ui-models';
import { ManageCounter } from './manage-counter.model';
import { ManageCounterState, ManageCounterStore } from './manage-counter.store';
import { CounterInfoForTable } from '../manage-template-store/manage-template.model';

@Injectable({
    providedIn: 'root'
})
export class ManageCounterQuery extends QueryEntity<ManageCounterState> {
    public ui: EntityUIQuery<EntityUIState>;
    
    constructor(
        protected store: ManageCounterStore
    ) {
        super(store);
        this.createUIQuery();
    }

    public getCounters(): Observable<Array<ManageCounter>> {
        return this.selectAll().pipe(map(counters =>
            counters.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getCounterSync(id: number): ManageCounter {
        return this.getEntity(id);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getCountersForTemplate(selectedCounterIds: Array<number>): Observable<Array<CounterInfoForTable>> {
        const selectedCounterIdsSet = new Set(selectedCounterIds); 
        return this.getCounters().pipe(
            map(counters => counters.map(counter => {
                const isSelected = selectedCounterIdsSet.has(counter.id); 

                return {
                    ...counter,
                    addToTemplate: isSelected,
                    disabledInAddMode: isSelected
                };
            }))
        );
    }
}
