import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportUri } from './report-uri.model';
import { ReportUriState, ReportUriStore } from './report-uri.store';

@Injectable({
    providedIn: 'root'
})
export class ReportUriQuery extends QueryEntity<ReportUriState> {
    constructor(
        protected store: ReportUriStore
    ) {
        super(store);
    }

    public getReportUris(): Observable<Array<ReportUri>> {
        return this.selectAll().pipe(map(reportUris =>
            reportUris.sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1)
        ));
    }
}
