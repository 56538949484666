import { FlatTreeControl } from "@angular/cdk/tree";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { map } from "rxjs/operators";

import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ColumnEntity, EntityListColumnDefinition, NestedTreeService } from "@ortec/soca-web-ui";

export interface SelectionCheckBoxClickEvent {
    selected?: boolean;
    selectedAll?: boolean;
    entityIds: Array<number | string>;
}

export interface MultiselectTableElement extends ColumnEntity {
    checked?: boolean;
    disabled?: boolean;
    children?: Array<any>;
    expandable?: boolean;
    level?: number;
    hideCheckbox?: boolean;
    childrenSelected?: boolean;
}

@Component({
    selector: 'app-multiselect-table',
    templateUrl: './multiselect-table.component.html',
    styleUrls: ['./multiselect-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MultiselectTableComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild("tableContainer", { static: false }) public tableContainer: ElementRef;
    
    @Input() public hideSearch: boolean = false;
    @Input() public columnDefinition: Array<EntityListColumnDefinition>;
    @Input() public searchProperties: Array<string>;
    @Input() public searchFieldPlaceholder: string = 'Search';
    @Input() public stripedTable: boolean = false;
    @Input() public narrowRows: boolean = false;
    @Input() public set entities(entities: Array<MultiselectTableElement>) {
        this.setEntities(entities);
    };

    @Output() public readonly selectedEntityIdsChanged = new EventEmitter<SelectionCheckBoxClickEvent>();
    
    private isAllSelectedSubject = new BehaviorSubject<boolean>(false);
    public tableRendered$ = new BehaviorSubject<boolean>(false);

    public displayedColumns = [];
    public loadDataComplete = false;
    public initialEntities = [];
    public filteredEntities = [];
    public isAllSelected$: Observable<boolean> = this.isAllSelectedSubject.asObservable();
    public selectedEntityIds: Array<number | string> = [];
    public firstColumnAfterCheckbox: string;
    public lastExpendedEntities = new Set<any>();
    public searchControl = new UntypedFormControl('');
    public scrollPositionY: number = 0;

    private _transformer = (node: MultiselectTableElement, level: number) => {
        return {
            expandable: !!node?.children && node?.children.length > 0,
            level: level,
            ...this.findEntityById(node.id)
        };
    }

    public treeControl = new FlatTreeControl<MultiselectTableElement>(
        node => node.level, node => node.expandable
    );

    public treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children
    );

    public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    private readonly subscription = new Subscription();

    constructor(
        private readonly nestedTreeService: NestedTreeService,
    ) {
    }

    public ngOnInit(): void {
        const selectedAll = this.isAllSelected();
        this.isAllSelectedSubject.next(selectedAll);
        this.displayedColumns = ['select', ...this.columnDefinition.map(column => column.entityProperty)];
        this.firstColumnAfterCheckbox = this.displayedColumns[1];

        this.subscription.add(
            this.searchControl.valueChanges.subscribe(entityFilterValue => {
                this.applySearch(entityFilterValue.trim().toLowerCase());
            })
        );

        this.subscription.add(
            this.tableRendered$.pipe().subscribe()
        );

        this.subscription.add(
            this.treeControl.expansionModel.changed.pipe(
                map(event => { this.lastExpendedEntities = new Set<any>((this.treeControl.expansionModel.selected || []).map(entity => entity?.id));})
            ).subscribe()
        );
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.entities) {
            setTimeout(() => {
                this.tableContainer.nativeElement.scrollTop = this.scrollPositionY;
            }, 0);  
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onResetValue(): void {
        this.searchControl.setValue('');
    }

    public hasChild = (_: number, node: MultiselectTableElement) => node.expandable;

    public hideLoader() {
        setTimeout(() => {
            this.tableRendered$.next(true);
        }, 100);
    }

    //selects all rows if they are not all selected; otherwise clear selection. 
    public onSelectAll(event: MatCheckboxChange) {
        this.isAllSelectedSubject.next(event.checked);
        if (event.checked) {
            this.selectedEntityIds = this.extractSelectedIds(this.dataSource.data, event.checked);
            this.updateDataSourceOnSelectAll(event.checked);
            this.selectedEntityIdsChanged.emit({ selectedAll: event.checked, entityIds: this.selectedEntityIds });
        } else {
            this.selectedEntityIds = this.extractSelectedIds(this.dataSource.data, event.checked);
            this.updateDataSourceOnSelectAll(event.checked)
            this.selectedEntityIdsChanged.emit({ selectedAll: event.checked, entityIds: this.selectedEntityIds });
        }

        this.expandDataNodes(this.lastExpendedEntities, this.treeControl.dataNodes);
    }

    //updates the data source when we click on select all, also when not all the entities are visible in the table due to the search property
    private updateDataSourceOnSelectAll(checked: boolean): void {
        this.nestedTreeService.setChildrenSelectedEntityState(this.treeControl.dataNodes, this.selectedEntityIds);
        this.treeControl.dataNodes.map(entity => { entity.checked = entity.disabled ? entity.checked : checked });
        this.dataSource.data.map(entity => {
            this.updateEntityAndChildren(entity, checked);
        });
    }

    //extract the entities ids based on the disabled and hideCheckbox property for the onSelectAll feature
    public extractSelectedIds(entities: Array<MultiselectTableElement>, selectAll: boolean) {
        entities.forEach(entity => {
            const shouldInclude = selectAll ? (!entity.disabled || (entity.disabled && entity.checked)) : (entity.disabled && entity.checked);
            const isPresent = this.selectedEntityIds.includes(entity.id);
    
            if (shouldInclude && entity?.hideCheckbox !== true && !isPresent) {
                this.selectedEntityIds.push(entity.id);
            } else if (!shouldInclude && isPresent) {
                const index = this.selectedEntityIds.indexOf(entity.id);
                if (index !== -1) {
                    this.selectedEntityIds.splice(index, 1);
                }
            }
    
            if (entity.children) {
                this.extractSelectedIds(entity.children, selectAll);
            }
        });
    
        return this.selectedEntityIds;
    }

    //handles the action of clicking on an entity checkbox from the table
    public onCheckboxClick(event: MatCheckboxChange, entity: MultiselectTableElement): void {
        const index = this.selectedEntityIds.indexOf(entity.id);
        if (index === -1) {
            // Add the entity's ID if it's not already present
            this.selectedEntityIds.push(entity.id);
        } else {
            // Remove the entity's ID if if already present
            this.selectedEntityIds.splice(index, 1);
        }
        this.updateDataSourceForAnEntity(event, entity);
        this.expandDataNodes(this.lastExpendedEntities, this.treeControl.dataNodes);
        this.isAllSelectedSubject.next(this.isAllSelected());
        this.selectedEntityIdsChanged.emit({ selected: event.checked, entityIds: this.selectedEntityIds });
    }

    //updates the data source in the table after selecting an entity checkbox
    private updateDataSourceForAnEntity(event: MatCheckboxChange, updatedEntity: MultiselectTableElement): void {
        this.nestedTreeService.setChildrenSelectedEntityState(this.treeControl.dataNodes, this.selectedEntityIds);
        this.treeControl.dataNodes.map(element => {
            if (element.id === updatedEntity.id) {
                element.checked = event.checked;
            }
        });
        this.dataSource.data.map(entity => {
            this.updateEntityState(entity, updatedEntity, event.checked);
        });
    }

    //updates entity state for entity or the children
    private updateEntityState(entity: MultiselectTableElement, updatedEntity: MultiselectTableElement, checked: boolean) {
        if (entity.id === updatedEntity.id) {
            entity.checked = checked;
        }
        if (entity.children) {
            entity.children.forEach(child => {
                this.updateEntityState(child, updatedEntity, checked);
            });
        }
    }

    //updated the data in the table based on the search field
    private applySearch(value: string) {
        const filterFn = (entity: MultiselectTableElement) => {
            const searchValue = value.toLowerCase();
            if (this.findTreeEntityBasedOnSearchValue(entity, searchValue)) {
                return true;
            }
            return false;
        }

        const filteredData = this.initialEntities.slice().filter(filterFn);
        this.filteredEntities = filteredData;
        this.nestedTreeService.setChildrenSelectedEntityState(this.filteredEntities, this.selectedEntityIds);
        this.dataSource.data = filteredData;
        this.expandDataNodes(this.lastExpendedEntities, this.treeControl.dataNodes);
        this.isAllSelectedSubject.next(this.isAllSelected());
    }

    //updates entities and the children for the onSelectAll function
    public updateEntityAndChildren(entity: MultiselectTableElement, checked: boolean) {
        entity.checked = entity.disabled ? entity.checked : checked;
        if (entity.children) {
            entity.children.forEach(child => {
                this.updateEntityAndChildren(child, checked);
            });
        }
    }

    private expandDataNodes(expandedEntityIds: Set<number | string>, dataNodes: Array<any>): void {
        expandedEntityIds.forEach(id => {
            const expandableNode = dataNodes.find(node => node.id === id);

            if (expandableNode) {
                this.treeControl.expand(expandableNode);

                if (expandableNode.children) {
                    this.expandDataNodes(expandedEntityIds, expandableNode.children);
                }
            }
        });
    }

    private findTreeEntityBasedOnSearchValue(obj: MultiselectTableElement, searchValue: string): boolean {
        for (let key in obj) {
            const value = obj[key];

            //if it has children we search again
            if (value && typeof value === 'object') {
                if (this.findTreeEntityBasedOnSearchValue(value, searchValue)) {
                    return true;
                }
            } else if (this.searchProperties.includes(key)) {
                if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
                    return true;
                } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
                    return true;
                }
            }
        }

        return false;
    }

    //initialize the selectedEntityIds after we load the data
    private getSelectedEntitiesIds(entities: Array<MultiselectTableElement>): Array<string | number> {
        entities.forEach(entity => {
            if (entity.checked && entity?.hideCheckbox !== true && !this.selectedEntityIds.includes(entity.id)) {
                this.selectedEntityIds.push(entity.id);
            }
        });

        return this.selectedEntityIds;
    }

    //find the entity by id and uses in the _transformer function, to have all the properties of an entity
    private findEntityById(id: string | number, entities = this.dataSource.data): MultiselectTableElement {
        for (const entity of entities) {
            if (entity.id === id) {
                return entity;
            } else if (entity.children) {
                const result = this.findEntityById(id, entity.children);
                if (result) {
                    return result;
                }
            }
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    private isAllSelected(): boolean {
        const idsOfTheRows = this.nestedTreeService.getAllChildIdsForNestedEntities(this.dataSource.data);
        const includedIdsLenth = idsOfTheRows.filter(id => this.selectedEntityIds.includes(id)).length;
        const numRows = this.nestedTreeService.getAllChildIdsForNestedEntities(this.dataSource.data).length;

        return includedIdsLenth === numRows && numRows > 0;
    }

    private storeScrollPosition(): void {
        this.scrollPositionY = this.tableContainer?.nativeElement?.scrollTop ?? 0;
    }

    private setEntities(entities: Array<MultiselectTableElement>): void {
        if (!!entities) {
            this.storeScrollPosition();
            this.dataSource.data = entities;
            this.getSelectedEntitiesIds(this.treeControl.dataNodes);

            // remove entities from selectedIds that no longer part of the data
            if (this.selectedEntityIds?.length > 0) {
                const allIds = this.extractAllIds(entities);
                this.selectedEntityIds = this.selectedEntityIds.filter(id => allIds.includes(id));
            }

            this.nestedTreeService.setChildrenSelectedEntityState(this.treeControl.dataNodes, this.selectedEntityIds);
            this.filteredEntities = this.dataSource.data;
            this.loadDataComplete = true;
            this.initialEntities = [...this.dataSource.data];
            if (this.searchControl.value.length > 0) {
                this.applySearch(this.searchControl.value);
            } else {
                this.isAllSelectedSubject.next(this.isAllSelected());
            }
            if (this.lastExpendedEntities.size > 0) {
                this.expandDataNodes(this.lastExpendedEntities, this.treeControl.dataNodes);
            }
        }
    }

    private extractAllIds(entities: Array<MultiselectTableElement>, ids = []) {
        entities.forEach(entity => {
            ids.push(entity.id);
        
            if (entity.children && entity.children.length > 0) {
                this.extractAllIds(entity.children, ids);
            }
        });
        
        return ids;
    }
}
