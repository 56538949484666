<div class="bol-modal__header">
  <span class="modal-title">{{ 'Select activity types' | translate }}</span>
  <div class="bol-modal__close" (click)="onCloseModal()">
    <i class="material-icons">close</i>
  </div>
</div>

<mat-dialog-content>
  <div class="filters-activity-types">
    <app-multiselect-tree
      class="activity-type-categories-filter-activity-type"
      [entities]="activityTypeCategories$ | async"
      [preSelectedEntities]="selectedActivityTypeCategoryIds"
      [showSelectAll]="true"
      [required]="true"
      [showSearch]="false"
      multiselectTreePlaceholder="Activity type categories"
      (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnActivityTypeCategories($event)"
    ></app-multiselect-tree>

    <app-multiselect-tree
      class="organization-unit-filter-activity-type"
      [entities]="organizationUnits$ | async"
      [preSelectedEntities]="filteredOrganizationUnits$ | async"
      [showSelectAll]="true"
      searchFieldPlaceholder="Search organization unit"
      multiselectTreePlaceholder="Organization units"
      (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
    ></app-multiselect-tree>
  </div>
 
    <div *ngIf="numberOfActivityTypes" class="activities-length-info-message">
        <div class="bol-text bol-text-regular"> {{ numberOfActivityTypes }} {{ '' +  ACTIVITY_LENGTH_MESSAGE | translate}} </div>
    </div>
    <app-multiselect-table
        searchFieldPlaceholder="Activity type filter"
        [entities]="filteredActivityTypes$ | async"
        [columnDefinition]="columnDefinition"
        [searchProperties]="searchProperties"
        [hideSearch]="false"
        (selectedEntityIdsChanged)="updateActivityTypeStates($event)"
    ></app-multiselect-table>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
          mat-button 
          class="bol-button-solid bol-positive"
          (click)="onConfirm()"
          [disabled]="selectedActivityTypeCategoryIds?.length === 0 || selectedOrganizationIds?.length === 0">
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>