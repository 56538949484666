<mat-card class="bol-card full-height" [style.display]="((tableRendered$ | async) === false) ? 'none' : ''">
    <mat-card-content>
        <mat-form-field class="bol-form-field entity-search" *ngIf="!hideSearch && loadDataComplete">
            <mat-label>{{searchFieldPlaceholder | translate}}</mat-label>
            <input 
                #input 
                matInput 
                data-cy="searchSingleSelectTable" 
                [formControl]="searchControl"
                autocomplete="off"
            />

            <button 
                mat-button 
                matSuffix 
                disabled 
                class="bol-button-text"
                *ngIf="!searchControl || !searchControl.value || searchControl.value.length === 0">
                <mat-icon class="search-icon">search</mat-icon>
            </button>

            <button 
                mat-button 
                matSuffix 
                class="cancel-button bol-button-text icon-only bol-gray"
                *ngIf="searchControl && searchControl.value && searchControl.value.length > 0" 
                (click)="onResetValue()">
                <mat-icon class="search-icon">cancel</mat-icon>
            </button>
        </mat-form-field>
        <section class="multiselect-table-container" #tableContainer>
            <mat-table 
            data-cy="multiselectTable"
            [dataSource]="dataSource" 
            class="multiselect-table bol-table bol-table-bordered-rows" 
            (contentChanged)="hideLoader()"
            [ngClass]="{'striped-table': stripedTable, 'narrow-rows': narrowRows}">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox 
                            class="bol-checkbox first-column"
                            (change)="$event ? onSelectAll($event) : null" 
                            [checked]="isAllSelected$ | async">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell class="cell-wrapper" *matCellDef="let row">
                        <div class="cell-select">
                            <button 
                                mat-icon-button 
                                *ngIf="row.expandable"
                                class="button-expandable"
                                [class.button-children-selected]="row.childrenSelected"
                                [style.visibility]="!row.expandable ? 'hidden' : ''"
                                [style.marginLeft.px]="row.level * 18"
                                (click)="treeControl.toggle(row)">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(row) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <mat-checkbox 
                                *ngIf="!row.hideCheckbox"
                                [style.marginLeft.px]=" !row.expandable ? 40 + row.level * 18 : '' "
                                [style.width.px]=" !row.expandable ? 40 + row.level * 18 : '' "
                                class="bol-checkbox"
                                (click)="$event.stopPropagation()"
                                (change)="onCheckboxClick($event, row)"
                                [disabled]="row.disabled"
                                [checked]="row.checked">
                            </mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Dynamic Columns -->
                <ng-container *ngFor="let column of columnDefinition" [matColumnDef]="column.entityProperty">
                    <mat-header-cell *matHeaderCellDef [attr.data-cy]="column.header">
                        <div [bolPopoverOverflow]="popoverHoverHeader" class="header-popover-container">
                            <ng-template #popoverHoverHeader> {{ column.columnDisplayName | translate }} </ng-template>
                            {{ column.columnDisplayName | translate }}
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <ng-template #popoverHover> {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                        </ng-template>
                    
                        <div class="table-cell popover-multi-line" 
                            [bolPopoverOverflow]="popoverHover"
                            [ngStyle]="column.stylingProperty ? row[column.stylingProperty]: ''"  
                            [style.marginLeft.px]="column.entityProperty === firstColumnAfterCheckbox ? row.level * 18 : ''">
                            {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Header Row -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <!-- Data Row -->
                <mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0"></mat-row>
                <tr *matNoDataRow class="bol-table-empty-state">
                    <td [attr.colspan]="displayedColumns.length" translate data-cy="noEntityFound">No results found.</td>
                </tr>

                <tr *matNoDataRow class="bol-table-empty-state">
                    <td *ngIf="!loadDataComplete" translate data-cy="noDataAvailable">No data available.</td>
                </tr>
            </mat-table>
        </section>
    </mat-card-content>
</mat-card>
<div class="overlay-spinner" *ngIf="!(tableRendered$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>