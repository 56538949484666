import { constants } from 'src/app/constants';

export interface EntityToEdit {
    entity: any;
    valid: boolean;
}

export class AutosaveService {
    private autosaveInProgress = false;
    private timeout: any;
    private entityToSave: any;
    private saveFunction: (entityToSave) => void;

    public autoSave(entityToSave: any, saveFunction: (entityToSave) => void): void {
        this.entityToSave = entityToSave;
        this.saveFunction = saveFunction;

        if (this.autosaveInProgress) {
            clearInterval(this.timeout);
        }
        this.startTimer();
    }

    public saveUnsavedChanges(): void {
        if (this.autosaveInProgress) {
            clearInterval(this.timeout);
            this.autosaveInProgress = false;
            this.saveFunction(this.entityToSave);
        }
    }

    private startTimer(): void {
        this.autosaveInProgress = true;

        this.timeout = setInterval(() => {
            this.saveFunction(this.entityToSave); 
    
            clearInterval(this.timeout);
            this.autosaveInProgress = false;
        }, constants.autosaveDelay);
    }

    // Implements autosave with added valid property
    public autoSaveWithValidState(entityToSave: any, saveFunction: (entityToSave) => void): void {
        this.entityToSave = entityToSave;
        this.saveFunction = saveFunction;

        if (this.autosaveInProgress) {
            clearInterval(this.timeout);
        }
        this.startTimerWithValidState();
    }

    public saveUnsavedChangesWithValidState(): void {
        if (this.autosaveInProgress) {
            clearInterval(this.timeout);
            this.autosaveInProgress = false;
            this.saveFunction(this.entityToSave.entity);
        }
    }

    private startTimerWithValidState(): void {
        this.autosaveInProgress = true;

        this.timeout = setInterval(() => {
            if (this.entityToSave.valid) {   
                this.saveFunction(this.entityToSave.entity); 
            }

            clearInterval(this.timeout);
            this.autosaveInProgress = false;
        }, constants.autosaveDelay);
    }
}
