import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTimeUtility } from '@ortec/soca-web-ui';
import { UserInfoService } from '../stores/user-info-store/user-info.service';

import { defaultLanguage, registeredLanguages } from './language.config';
import { Language } from './language.model';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    public readonly currentLanguage$: Observable<Language>;
    private readonly currentLanguage = new ReplaySubject<Language>(1);

    constructor(
        private readonly dateTimeUtility: DateTimeUtility,
        private readonly translateService: TranslateService,
        private readonly userInfoService: UserInfoService,
    ) {
        this.currentLanguage$ = this.currentLanguage.asObservable();
    }

    public initialize(): void {
        this.setLanguage(this.defaultLanguage);
    }

    public setUserLanguage(): void {
        this.userInfoService.getUserLanguage().pipe(first())
            .subscribe({
                next: this.setLanguageForUser.bind(this),
                error: this.setDefaultLanguage.bind(this)
            });
    }

    public getCurrentLanguage(): string {
        return this.translateService.currentLang;
    }

    public getCurrentLanguageWithLocaleId(): string {
        const languageCode = this.translateService.currentLang;
        const currentLang: Language = registeredLanguages.find(lang => lang.code === languageCode);

        return currentLang ? currentLang.codeDto : languageCode;
    }

    public getDateFormatByCurrentLanguage(): Observable<string> {
        return of(this.dateTimeUtility.getDateFormat('nl-nl'));
    }

    public getDateFormatMomentByCurrentLanguage(): string {
        return this.dateTimeUtility.getDateFormatMoment('nl-nl');
    }

    public getDateTimeFormatMomentByCurrentLanguage(): string {
        const langCode = this.getCurrentLanguageWithLocaleId();
        return this.dateTimeUtility.getDateTimeFormatMoment('nl-nl');
    }

    public getDateTimeFormatByCurrentLangSync(): string {
        // TODO: check desired notation
        const langCode = this.getCurrentLanguageWithLocaleId();

        return this.dateTimeUtility.getDateTimeFormat('nl-nl');
    }

    public getLanguages(): Array<Language> {
        return registeredLanguages;
    }

    public setLanguage(newLanguage: Language): void {
        this.translateService.use(newLanguage.code);
        this.translateService.setDefaultLang(defaultLanguage.code);

        this.currentLanguage.next(newLanguage);
    }

    private get defaultLanguage(): Language {
        const browserLanguage = this.translateService.getBrowserLang();

        const matchedLanguage = registeredLanguages.find((language) => language.code === browserLanguage) || defaultLanguage;

        if (!matchedLanguage) {
            const browserLang = this.translateService.getBrowserLang();
            const prefixMatchedLanguage = registeredLanguages.find((language) => language.code === browserLang);

            if (!prefixMatchedLanguage) {
                return defaultLanguage;
            }

            return prefixMatchedLanguage;
        }

        return matchedLanguage;
    }

    private setLanguageForUser(lang: string) {
        this.setLanguage(this.getLanguageByCodeDto(lang));
    }

    private setDefaultLanguage() {
        this.setLanguage(defaultLanguage);
    }

    private getLanguageByCodeDto(codeDto: string): Language {
        const language = this.getLanguages().find((lang) => lang.codeDto === codeDto);

        return language || this.defaultLanguage;
    }
}
