import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RequestService } from 'src/app/shared/services/request.service';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { ScheduleActivityStore } from './schedule-activity.store';
import { ScheduleActivity, GetScheduleActivityResponse, ScheduleActivityRequestParameters, GetActivityResponse } from './schedule-activity.model';

@Injectable({
    providedIn: 'root'
})
export class ScheduleActivityService {
    private readonly maxDaysInCall = 31;

    constructor(
        protected scheduleActivityStore: ScheduleActivityStore,
        protected requestService: RequestService,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getActivities(parameters: ScheduleActivityRequestParameters, startDate?: string, endDate?: string): Observable<GetScheduleActivityResponse> {
        const parametersForRequest = this.transformArrayPropertiesToStrings(parameters);
        if (!!startDate && !!endDate) {
            const requestList: Array<Observable<GetScheduleActivityResponse>> = [];
            const paramList = this.requestService.splitUpRequestBasedOnDates(
                parametersForRequest,
                startDate,
                endDate,
                'fromDate',
                'toDate',
                this.maxDaysInCall
            );
    
            paramList.forEach(param => requestList.push(this.getRequestForParams(param)));
    
            return this.requestService.loadSequential(requestList).pipe(
                map(activityResponses => {
                    let activitiesForActivityView: Array<ScheduleActivity> = [];
                    let activitiesForResourceView: Array<ScheduleActivity> = [];
    
                    activityResponses.forEach(response => activitiesForActivityView = activitiesForActivityView.concat(response.activitiesTreeForActivity));
                    activityResponses.forEach(response => activitiesForResourceView = activitiesForResourceView.concat(response.activitiesTreeForResource));
    
                    this.scheduleActivityStore.updateActivitiesTreeForActivity(activitiesForActivityView);
                    this.scheduleActivityStore.updateActivitiesTreeForResource(activitiesForResourceView);
    
                    return { activitiesTreeForActivity: activitiesForActivityView, activitiesTreeForResource: activitiesForResourceView };
                })
            );
        }
        else {
            return this.getRequestForParams(parametersForRequest).pipe(
                map((response) => {
                    this.scheduleActivityStore.updateActivitiesTreeForActivity(response.activitiesTreeForActivity);
                    this.scheduleActivityStore.updateActivitiesTreeForResource(response.activitiesTreeForResource);
    
                    return { activitiesTreeForActivity: response.activitiesTreeForActivity, activitiesTreeForResource: response.activitiesTreeForResource };
                })
            );
        }
    }

    private getRequestForParams(params: ScheduleActivityRequestParameters): Observable<GetScheduleActivityResponse> {
        return this.http.post<GetScheduleActivityResponse>('/api/v1/Activities/ScheduleOverview', { ...params }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
        );
    }

    private transformArrayPropertiesToStrings(params: ScheduleActivityRequestParameters): any {
        return {
            ...params,
            activityTypeIdsForActivity: params.activityTypeIdsForActivity?.toString(),
            activityTypeIdsForResource: params.activityTypeIdsForResource?.toString(),
            organizationUnitIds: params.organizationUnitIds?.toString(),
        };
    }
}
