import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DialogService } from '@ortec/soca-web-ui/dialogs';
import { first, map } from 'rxjs';

import { EditTemplatesManagementQuery } from '../../store/edit-template-management.query';

export interface IWeekLengthDialogData {
    weekLength: number;
}

@Component({
    standalone: true,
    selector: 'app-edit-week-length-dialog',
    templateUrl: './edit-week-length-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule
    ],
})
export class EditWeekLengthDialogComponent {
    public weekLengthForm;

    constructor(
        public readonly dialogRef: MatDialogRef<number>,
        private readonly translateService: TranslateService,
        private readonly dialogService: DialogService,
        private readonly editTemplatesManagementQuery: EditTemplatesManagementQuery,
        @Inject(MAT_DIALOG_DATA) dialogData: IWeekLengthDialogData,
    ) { 
        this.weekLengthForm = new UntypedFormControl(dialogData.weekLength, [ Validators.required, Validators.min(1), Validators.max(53)]);
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onSave(): void {
        const previousWeekNumberValue = this.editTemplatesManagementQuery.getMaxWeekNumberSync();
        const decreaseWeekNumberMessage = this.translateService.instant('ACTIVITY-DEMAND.EDIT-TEMPLATES.CONFIRM-MESSAGE-DECREASE-WEEKS-NUMBER');
        const increaseWeekNumberMessage = this.translateService.instant('ACTIVITY-DEMAND.EDIT-TEMPLATES.CONFIRM-MESSAGE-INCREASE-WEEKS-NUMBER');
        
        const message = this.weekLengthForm.value < previousWeekNumberValue ? decreaseWeekNumberMessage : increaseWeekNumberMessage;
        const title = this.translateService.instant('ACTIVITY-DEMAND.EDIT-TEMPLATES.CONFIRM-EDIT-WEEK-LENGTH');
        const cancelButton = this.translateService.instant('Cancel');
        const confirmButton = this.translateService.instant('Confirm');

        this.dialogService.showConfirmDialog(title, message, confirmButton, cancelButton, 'bol-button-solid bol-positive').pipe(
            first(),
            map(confirm => {
                if (confirm) {
                    this.dialogRef.close(
                        {
                            weekLength: this.weekLengthForm.value
                        } as IWeekLengthDialogData
                    ); 
                }
            })
        ).subscribe();
    }
}