import { Injectable } from '@angular/core';
import { HashMap, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResourcePropertyLanguage } from './resource-property-language.model';
import { ResourcePropertyLanguageState, ResourcePropertyLanguageStore } from './resource-property-language.store';

@Injectable({
    providedIn: 'root'
})
export class ResourcePropertyLanguageQuery extends QueryEntity<ResourcePropertyLanguageState> {
    constructor(
        protected store: ResourcePropertyLanguageStore
    ) {
        super(store);
    }

    public getResourcePropertiesLanguage(): Observable<Array<ResourcePropertyLanguage>> {
        return this.selectAll();
    }

    public getResourcePropertiesLanguageSorted(): Observable<Array<ResourcePropertyLanguage>> {
        return this.selectAll().pipe(map(resourceProp =>
            resourceProp.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
        ));;
    }

    public getResourcePropertiesLanguageSync(): Array<ResourcePropertyLanguage> {
        const types: HashMap<ResourcePropertyLanguage> = this.getValue().entities;

        return Object.values(types);
    }

    public getResourcePropertiesLanguageIdsSync(): Array<number> {
        return this.getResourcePropertiesLanguageSync().map(prop => prop.resourcePropertyId);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getResourcePropertiesByIdsSync(ids: Array<number>): Array<ResourcePropertyLanguage> {
        const properties = Object.values(this.getValue().entities);

        return properties.filter(u => ids.includes(u.resourcePropertyId));
    }
}
