import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, Router, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './core/error/error.component';

import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { applicationSettingsRoutes } from './features/application-settings/application-settings-routing';
import { AuthorizeComponent } from './features/login/authorize.component';
import { overviewsRoutes } from './features/overviews/overviews-routing.module';
import { owsInterfaceRoutes } from './features/ows-interface/ows-interface-routing';
import { programManagementRoutes } from './features/program-management/program-management-routing.module';
import { NoPermissionsComponent } from './shared/components/no-permissions/no-permissions.component';
import { BreadcrumbGuard } from './shared/guards/breadcrumb.guard';
import { planboardRoutes } from './features/planboard/planboard-routing.module';
import { activityDemandRoutes } from './features/activity-demand/activity-demand-routing';

const routes: Routes = [
    { // This one is a temporary solution to make the "schedule-overview" route the home page
        path: '',
        pathMatch: 'full',
        redirectTo: '/overviews/schedule-overview'
    },
    { // This one is a temporary solution to make the old "reports" route redirect to the new url
        path: 'reports',
        pathMatch: 'full',
        redirectTo: '/overviews/schedule-overview'
    },
    {
        path: 'authorize',
        component: AuthorizeComponent
    },
    {
        path: 'noPermissions',
        component: NoPermissionsComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    ...activityDemandRoutes,
    // loadChildren loads module exactly from given path in the shell. No distinction in routing possible.
    // Temporary disable lazy loading and load routes directly:
    // {
    //   path: 'ows-interface',
    //   loadChildren: () => import('./features/ows-interface/ows-interface.module').then(m => m.OwsInterfaceModule)
    // },
    ...owsInterfaceRoutes,
    // {
    //   path: 'reports',
    //   loadChildren: () => import('./features/overviews/overviews.module').then(m => m.OverviewsModule)
    // },
    ...overviewsRoutes,
    // {
    //   path: 'settings-interface',
    //   loadChildren: () => import('./features/settings-interface/settings-interface.module').then(m => m.SettingsInterfaceModule)
    // },
    ...planboardRoutes,
    ...applicationSettingsRoutes,
    ...programManagementRoutes,
    { path: '**', component: EmptyRouteComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/angularomrp' },
    ],
})
export class AppRoutingModule {
    constructor(router: Router) {
        router.config
            .forEach(route => this.addBreadcrumbGuard(route));
    }

    // We need access to the "data" property in the route config in our breadcrumb component
    // Because the breadcrumb lives outside of the router-outlet, we need a workaround
    // This guard will pass the data property to the breadcrumbService
    private addBreadcrumbGuard(route: Route): void {
        if (!route.canActivate) {
            route.canActivate = [];
        }
        route.canActivate.push(BreadcrumbGuard);
        if (route.children) {
            route.children.forEach(childRoute => {
                this.addBreadcrumbGuard(childRoute);
            });
        }
    }
}
