import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-overviews',
    templateUrl: './overviews.component.html',
    styleUrls: ['./overviews.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewsComponent {
}
