<mat-form-field #matFormField class="bol-form-field multiselect" floatLabel="{{floatLabel ? 'always' : 'auto'}}">
    <mat-label translate>{{multiselectTreePlaceholder}}</mat-label>
    <mat-select
        #matSelect
        matInput
        multiple
        [required]="required"
        [formControl]="entitiesSelectControl"
        ngDefaultControl
        panelClass="multiselect-panel"
        class="select-element"
        placeholder="{{placeholderText}}">
        
        <div *ngIf="showSelectAll" class="select-all-checkbox">
            <mat-checkbox 
                class="bol-checkbox"
                *ngIf="showSelectAll" 
                [checked]="IsAllSelected()"
                (change)="$event ? onToggleAll($event) : null"
                [attr.data-cy]="multiselectTreePlaceholder + 'SelectAll'">
            {{ 'Select all' | translate }}
            </mat-checkbox>
        </div>

        <mat-form-field class="bol-form-field multiselect-search" *ngIf="internalEntities.length > 0 && showSearch" bolSearchField>
            <mat-label>{{searchFieldPlaceholder | translate}}</mat-label>
            <input
                #input
                matInput
                [formControl]="searchControl"
                [attr.data-cy]="multiselectTreePlaceholder + 'Search'"
                autocomplete="off"/>
            <button
                mat-button
                matSuffix
                disabled
                class="bol-button-text"
                *ngIf="!searchControl || !searchControl.value || searchControl.value.length === 0">
                <mat-icon class="search-icon">search</mat-icon>
            </button>

            <button
                mat-button
                matSuffix
                class="cancel-button bol-button-text icon-only bol-gray"
                *ngIf="searchControl && searchControl.value && searchControl.value.length > 0"
                (click)="onResetValue()">
                <mat-icon class="search-icon">cancel</mat-icon>
            </button>
        </mat-form-field>

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
            <!-- tree nodes without children -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [class.tree-invisible]="!node.visible">
                <li class="mat-tree-node">
                    <button mat-icon-button disabled></button>
                    <mat-checkbox class="bol-checkbox bol-primary"
                        [checked]="node.checked"
                        [disabled]="node.disabled"
                        (change)="onSelectEntity(node)"
                        [attr.data-cy]="'select' + node[entityProperty]">
                        <div class="childless-tree-node" *ngIf="translationNeeded">{{ node[entityProperty] | translate }}</div>
                        <div class="childless-tree-node" *ngIf="!translationNeeded">{{ node[entityProperty] }}</div>
                    </mat-checkbox>
                </li>
            </mat-tree-node>

            <!-- tree nodes with children -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [class.tree-invisible]="!node.visible">
                <li>
                    <div class="mat-tree-node">
                        <button
                            mat-icon-button
                            [class.button-children-selected]="node.childrenSelected"
                            matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node[entityProperty] ">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
                            </mat-icon>
                        </button>
                        <mat-checkbox
                            class="bol-checkbox bol-primary" 
                            [checked]="node.checked"
                            (change)="onSelectEntity(node)"
                            [attr.data-cy]="'select' + node[entityProperty]">
                            <div *ngIf="translationNeeded">{{ node[entityProperty] | translate }}</div>
                            <div *ngIf="!translationNeeded">{{ node[entityProperty] }}</div>
                        </mat-checkbox>
                    </div>
                    <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>

        <!-- We need a hidden option here so the tree can render -->
        <mat-option class="hide-option" disabled></mat-option>
        <div class="no-results-founds" *ngIf="noVisibleEntities || dataSource?.data?.length === 0" translate>No results found.</div>
    </mat-select>

    <mat-error *ngIf="entitiesSelectControl.invalid" translate>{{errorMessage}}</mat-error>
</mat-form-field>
