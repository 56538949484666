import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, of } from 'rxjs';
import { NestedTreeService } from 'src/app/shared/services/nested-tree.service';
import { MultiselectTreePropagationDialogComponent } from '../multiselect-tree-propagation-dialog/multiselect-tree-propagation-dialog.component';

export interface MultiselectTreePropagationOptions {
    rememberDialogAction: boolean;
    shouldPropagate: boolean;
}

@Injectable()
export class MultiselectTreePropagationService {
    public multiselectTreePropagationOptions: MultiselectTreePropagationOptions = { rememberDialogAction: false, shouldPropagate: false };

    constructor(
        private readonly dialogService: MatDialog,
        private readonly nestedTreeService: NestedTreeService
    ) { }

    public getMultiselectItemsOnStatusChange(entity: any, newStatus: boolean): Observable<MultiselectTreePropagationOptions> {        
       // Check if the entity's status is false and none of its children are checked
        if (!newStatus && this.nestedTreeService.areAllChildrenUnchecked(entity)) {
            return of(this.multiselectTreePropagationOptions);
        }

        // Check if the dialog should be shown based on conditions
        if (!this.multiselectTreePropagationOptions.rememberDialogAction && entity.children?.length > 0) {
            return this.onMultiselectTreePropagationDialog(entity, newStatus);
        }

        // If none of the above conditions are met, return the default options
        return of(this.multiselectTreePropagationOptions);
    }   

    private onMultiselectTreePropagationDialog(entity: any, newStatus: boolean): Observable<MultiselectTreePropagationOptions> {
        const dialogRef = this.dialogService.open(MultiselectTreePropagationDialogComponent, {
            data: {},
            autoFocus: false,
            disableClose: true,
            width: '600px',
            height: '154px'
        });

        return dialogRef.componentInstance.changeSelectionTreePropagationOptionsEvent.pipe(
            map((propOpt) => {
                this.multiselectTreePropagationOptions = propOpt;
                
                return this.multiselectTreePropagationOptions; 
            })
        );
    }
}