import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { OwsSkill, OwsSkillTableElement } from '../ows-skill-store/ows-skill.model';
import { OwsSkillQuery } from '../ows-skill-store/ows-skill.query';

import { SkillMappingState, SkillMappingStore } from './skill-mapping.store';

@Injectable({
    providedIn: 'root'
})
export class SkillMappingQuery extends QueryEntity<SkillMappingState> {

    constructor(
        protected store: SkillMappingStore,
        private readonly skillQuery: SkillQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        private readonly owsSkillQuery: OwsSkillQuery,
    ) {
        super(store);
    }
    
    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.ui.showUnderlyingUnits);
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.ui.selectedOrganizationUnitId);
    }

    public getSelectedOwsDepartment(): Observable<number> {
        return this.select(state => state.ui.selectedOwsDepartmentId);
    }

    public getShowAllOwsSkills(): Observable<boolean> {
        return this.select(state => state.ui.showAllOwsSkills);
    }

    public getSelectedSkillId(): Observable<number> {
        return this.select(state => state.ui.selectedSkillId);
    }

    public getUnlinkedOwsSkillVisibility(): Observable<boolean> {
        return this.select(state => state.ui.hideUnlinkedOwsSkills);
    }

    public getLinkedOwsSkillVisibility(): Observable<boolean> {
        return this.select(state => state.ui.showLinkedOwsSkills);
    }

    public getFilteredSkills(): Observable<Array<Skill>> {
        return combineLatest([
            this.skillQuery.getSkills(), 
            this.getSelectedOrganizationUnit(),
            this.getShowUnderlyingUnitsState()
        ]).pipe(
            map(([skills, filteredOrganizationIds, showUnderlyingUnits]) => {
                if (!showUnderlyingUnits) {
                    return skills.filter(skill => skill.validOrganizationUnitIds.includes(filteredOrganizationIds));
                }
                else {
                    const childUnitIds = this.organizationUnitQuery.getAllChildUnitsForUnitSync(filteredOrganizationIds).map(unit => unit.id);
                    childUnitIds.push(filteredOrganizationIds);

                    return skills.filter(skill => skill.validOrganizationUnitIds.some(id => childUnitIds.includes(id)));
                }
            })
        );
    }

    public getFilteredOwsSkills(): Observable<Array<OwsSkillTableElement>> {
        return combineLatest([
            this.getAllMappedOwsSkills(),
            this.getUnlinkedOwsSkillVisibility(),
            this.getLinkedOwsSkillVisibility(),
            this.getSelectedSkillId(),
            this.getSelectedOwsDepartment(),
            this.getShowAllOwsSkills(),
        ]).pipe(map(([owsSkills, hideUnlinked, showOnlySelected, selectedSkillId, selectedOwsDepartment, showAllOwsSkills]) => {
            const visibleSkills = owsSkills.filter(skill => hideUnlinked ?
                (skill.linkedOmrpSkill === null || skill.linkedOmrpSkill?.id === selectedSkillId) : true
            ).filter(skill => showOnlySelected ? skill.linkedOmrpSkill?.id === selectedSkillId : true);

            const tableElements = visibleSkills.map(resourceType => {
                const isLinked = resourceType.linkedOmrpSkill !== null;
                let displayName = resourceType.name;

                if (isLinked && resourceType.linkedOmrpSkill?.id !== selectedSkillId) {
                    displayName += ' - ' + resourceType.linkedOmrpSkill?.displayName;
                };

                return {
                    ...resourceType, 
                    checked: isLinked,
                    disabled: visibleSkills === undefined || (isLinked && resourceType.linkedOmrpSkill?.id !== selectedSkillId),
                    displayName
                };
            });

            if (showAllOwsSkills || selectedOwsDepartment === undefined) {
                return tableElements;
            }
            else {
                return tableElements.filter(skill => skill.departmentId === selectedOwsDepartment);
            }
        }));
    }

    public getAllMappedOwsSkills(): Observable<Array<OwsSkill>> {
        return combineLatest([
            this.selectAll(),
            this.owsSkillQuery.selectAll(),
            this.skillQuery.selectAll()
        ]).pipe(
            map(([mappings, owsSkills, skills]) => {
                return owsSkills.map(skill => ({
                    ...skill,
                    linkedOmrpSkill: mappings.find(x => x.owsSkillId === skill.id) ?
                        skills.find(s => s.id === mappings.find(x => x.owsSkillId === skill.id).skillId) : null
                })).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);;
            })
        );
    }

    public getUnlinkedSkillCount(): Observable<number> {
        return this.getAllMappedOwsSkills().pipe(
            map(skills => {
                return skills.filter(skill => skill.linkedOmrpSkill === null).length;
            })
        );
    }
}
