import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectionCheckBoxClickEvent } from '@ortec/soca-web-ui';
import { Memoized } from '@ortec/utilities/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, skip, tap } from 'rxjs/operators';

import { ResourcesToHide } from 'src/app/features/planboard/filtering/store/filtering.model';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';

export interface IResourceModalData {
    resources$: Observable<Array<Resource>>;
    selectedResourcesToHide: Array<ResourcesToHide>;
    resourceTypes$: Observable<Array<ResourceType>>;
    selectedResourceTypeId: number;
}

export interface ResourceSelection extends Resource {
    checked: boolean;
}

@Component({
    selector: 'app-resource-dialog',
    templateUrl: './resource-dialog.component.html',
    styleUrls: ['./resource-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceDialogComponent implements OnInit, OnDestroy {
    public resources: Array<ResourceSelection>;
    public filteredResourcesByResourceTypes: Array<ResourceSelection>;
    public selectedResourceTypeId: number;
    public showOnlySelectedStateSubject = new BehaviorSubject<boolean>(false);
    public resourceTypes: Array<ResourceType>;
    public selectedResourceTypeIdSubject = new BehaviorSubject<number>(undefined);
    public filteredResourcesSubject = new BehaviorSubject<Array<ResourceSelection>>([]);
    public selectedResourcesToHide: Array<ResourcesToHide> = [];
    public RESOURCE_SELECTED_MESSAGE = 'resources of resource type';
    public SELECTED_TO_HIDE = 'selected to hide';
    public columnDefinition = [];
    public displayNameOfSelectedResourceType: string;
    public searchProperties: Array<string> = ['displayName'];
    public resourceTypeControl = new UntypedFormControl('', [Validators.required]);
    public selectedResourceIds: number = 0;

    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<IResourceModalData>,
        public organizationUnitQuery: OrganizationUnitQuery,
        public activityTypeQuery: ActivityTypeQuery,
        public activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        @Inject(MAT_DIALOG_DATA) public dialogData: IResourceModalData,
    ) {
        this.selectedResourceTypeId = dialogData.selectedResourceTypeId;

        this.subscription.add(
            combineLatest([
                dialogData.resources$,
                dialogData.resourceTypes$
            ]).pipe(
                filter(([resources, resourceTypes]) => resources !== undefined && resources.length > 0 &&
                    resourceTypes !== undefined && resourceTypes.length > 0),
                tap(([resources, resourceTypes]) => {
                    this.resources = resources.map(resource => ({...resource, checked: false}));
                    this.resourceTypes = resourceTypes;
                    this.resourceTypeControl.setValue(this.selectedResourceTypeId, { emitEvent: false });
                    if (dialogData.selectedResourcesToHide.length > 0) {
                        this.selectedResourcesToHide = dialogData.selectedResourcesToHide;
                    }
                    const updatedResources = this.checkResourcesByResourceType(resources as any, dialogData.selectedResourcesToHide);
                    const filteredResourcesByResourceTypes = this.getFilteredResourcesByResourceType(updatedResources, this.selectedResourceTypeId, this.selectedResourcesToHide);
                    this.selectedResourceTypeIdSubject.next(this.selectedResourceTypeId);
                
                    this.filteredResourcesByResourceTypes = filteredResourcesByResourceTypes;
                    this.filteredResourcesSubject.next(this.filteredResourcesByResourceTypes);
                })
            ).subscribe()
        );
    }

    public ngOnInit(): void {
        this.columnDefinition.push( 
            {
                columnDisplayName: 'Display name',
                entityProperty: 'displayName',
            }
        )
    
        this.setFilteredResources();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                selectedResourcesToHide: this.selectedResourcesToHide,
            } as IResourceModalData
        });
    }

    public showOnlyResourcesSelected(event: MatCheckboxChange): void {
        if (event.checked) {
            this.resourceTypeControl.disable();
        } else {
            this.resourceTypeControl.enable();
        }
        this.showOnlySelectedStateSubject.next(event.checked);
    }

    public updateResourcesStates(event: SelectionCheckBoxClickEvent): void {
        const resourceIds = event.entityIds;
        if(this.showOnlySelectedStateSubject.value) {
            this.filteredResourcesByResourceTypes = this.updateResourceChecked(this.filteredResourcesByResourceTypes, resourceIds).filter(res => res.checked);
            this.filteredResourcesSubject.next(this.filteredResourcesByResourceTypes);
        } else {
            this.filteredResourcesByResourceTypes = this.updateResourceChecked(this.filteredResourcesByResourceTypes, resourceIds);
        }
    }

    public openedResourceTypeSelect(state: boolean): void {
        if (state) {
            return;
        }

        const id = this.resourceTypeControl.value;
        this.selectedResourceTypeId = id;
        this.selectedResourceTypeIdSubject.next(id);
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    @Memoized public get resourceTypes$(): Observable<Array<ResourceType>> {
        return this.dialogData.resourceTypes$;
    }

    @Memoized public get filteredResources$(): Observable<Array<ResourceSelection>> {
        return this.filteredResourcesSubject.asObservable();
    }

    @Memoized public get showOnlySelectedState$(): Observable<boolean> {
        return this.showOnlySelectedStateSubject.asObservable();
    }
    
    private checkResourcesByResourceType(resources: Array<ResourceSelection>, selectedResourcesToHide: Array<ResourcesToHide>): Array<ResourceSelection> {
        const updatedResources = resources.map(resource => {
            const isMatchingResource = selectedResourcesToHide.some(selection =>
                selection.resourceTypeId === resource.id &&
                selection.resourceIds.includes(resource.id)
            );
    
            return {
                ...resource,
                checked: isMatchingResource,
            };
        });
    
        return updatedResources;
    }

    private updateResourceChecked(resources: Array<ResourceSelection>, resourceIds: Array<number | string>): Array<ResourceSelection> {
        const updatedResources = resources.map(resource => ({
            ...resource,
            checked: resourceIds.includes(resource.id),
        }));
    
        updatedResources.forEach((resource) => {
            if (resource.checked) {
                const existingResourceType = this.selectedResourcesToHide.find(
                    (item) => item.resourceTypeId === this.selectedResourceTypeId
                );
    
                if (existingResourceType) {
                    if (!existingResourceType.resourceIds.includes(resource.id)) {
                        existingResourceType.resourceIds.push(resource.id);
                    }
                } else {
                    this.selectedResourcesToHide.push({
                        resourceTypeId: this.selectedResourceTypeId,
                        resourceIds: [resource.id],
                    });
                }
            } else {
                const existingResourceType = this.selectedResourcesToHide.find(
                    (item) => item.resourceTypeId === this.selectedResourceTypeId
                );
    
                if (existingResourceType) {
                    existingResourceType.resourceIds = existingResourceType.resourceIds.filter(
                        (id) => id !== resource.id
                    );
    
                    if (existingResourceType.resourceIds.length === 0) {
                        const index = this.selectedResourcesToHide.findIndex(
                            (item) => item.resourceTypeId === this.selectedResourceTypeId
                        );
                        if (index !== -1) {
                            this.selectedResourcesToHide[index].resourceIds = [];
                        }
                    }
                }
            }
        }); 

        this.selectedResourceIds = this.selectedResourcesToHide.find(res => res.resourceTypeId === this.selectedResourceTypeId)?.resourceIds?.length ?? 0;
    
        return updatedResources;
    }

    private setFilteredResources(): void {
        this.subscription.add(
            combineLatest([
                this.selectedResourceTypeIdSubject,
                this.showOnlySelectedState$,
            ]).pipe(
                skip(1),
                tap(([selectedResourceTypeId, showOnlySelectedState]) => {
                    if (showOnlySelectedState) {
                        this.filteredResourcesByResourceTypes = this.showOnlySelectedResources(this.resources, this.selectedResourcesToHide);
                    } else {
                        this.filteredResourcesByResourceTypes = this.getFilteredResourcesByResourceType(this.resources, selectedResourceTypeId, this.selectedResourcesToHide);
                    }
    
                    this.filteredResourcesSubject.next(this.filteredResourcesByResourceTypes);
                })
            ).subscribe()
        );
    }

    private getFilteredResourcesByResourceType(resources: Array<ResourceSelection>, selectedResourceTypeId: number, selectedResourcesToHide: Array<ResourcesToHide>): Array<ResourceSelection> {

        const filteredResources = resources.filter((resource) =>
            resource.resourceTypeMemberships?.some((membership) =>
                selectedResourceTypeId === membership.resourceTypeId
            )
        );
        this.displayNameOfSelectedResourceType = this.resourceTypes.find(res => res.id === selectedResourceTypeId)?.displayName;
        this.selectedResourceIds = selectedResourcesToHide.find(res => res.resourceTypeId === selectedResourceTypeId)?.resourceIds?.length ?? 0;

        return filteredResources.map((resource) => {
            const isChecked = selectedResourcesToHide.some(
                (selection) =>
                    selection.resourceTypeId === selectedResourceTypeId &&
                    selection.resourceIds.includes(resource.id)
            );

            return {
                ...resource,
                checked: isChecked,
            };
        });
    }

    private showOnlySelectedResources(resources: Array<ResourceSelection>, selectedResourcesToHide: Array<ResourcesToHide>): Array<ResourceSelection> {
        this.selectedResourceIds = selectedResourcesToHide.find(res => res.resourceTypeId === this.selectedResourceTypeId)?.resourceIds?.length ?? 0;

        return resources.filter((resource) => {
            return selectedResourcesToHide.some(selection =>
                selection.resourceTypeId === this.selectedResourceTypeId &&
                selection.resourceIds.includes(resource.id)
            );
        }).map(resource => ({
            ...resource,
            checked: true,
        }));
    }
}
