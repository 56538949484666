<div class="edit-template-wrapper">
    <mat-card class="bol-card">
        <mat-card-content>
            <div class="edit-row">
                <div class="edit-template-buttons">
                    <mat-form-field 
                        class="bol-form-field"
                        data-cy="editTemplateDisplayName">
                        <mat-label translate>Display name</mat-label>
                        <input 
                            [formControl]="displayNameControl"
                            matInput 
                            autocomplete="off" />
                    </mat-form-field>

                    <button 
                        (click)="onEditweekLength()"
                        mat-button
                        class="bol-button-outlined edit-week-button">
                        <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.EDIT-WEEK-LENGTH</span> ({{maxWeeks$ | async}})
                    </button>
                </div>
                <div class="loading-status-indication">
                    <app-loading-status-indication
                        [statusPageState]="statusPageState$ | async"
                    ></app-loading-status-indication>
                </div>
            </div>
            <app-adt-activity-type-table></app-adt-activity-type-table>
            <app-adt-counter-table></app-adt-counter-table>
        </mat-card-content>
    </mat-card>
</div>

<div class="overlay-spinner" *ngIf="(updateTimeslotsLoadingFinished$ | async) === true || (initialLoadingFinished$ | async) === false || (updateCountersLoadingFinished$ | async) === true">
    <mat-spinner diameter="100"></mat-spinner>
</div>
