import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NestedTreeService } from 'src/app/shared/services/nested-tree.service';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';

import { OwsDepartment, OwsDepartmentTableElement } from '../ows-department-store/ows-department.model';
import { OwsDepartmentQuery } from '../ows-department-store/ows-department.query';
import { DepartmentMappingState, DepartmentMappingStore } from './department-mapping.store';

@Injectable({
    providedIn: 'root'
})
export class DepartmentMappingQuery extends QueryEntity<DepartmentMappingState> {

    constructor(
        protected store: DepartmentMappingStore,
        private readonly owsDepartmentQuery: OwsDepartmentQuery,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly nestedTreeService: NestedTreeService,
    ) {
        super(store);
    }

    public getSelectedOrganizationUnitId(): Observable<number> {
        return this.select(state => state.ui.selectedOrganizationUnitId);
    }

    public getUnlinkedOwsDepartmentsVisibility(): Observable<boolean> {
        return this.select(state => state.ui.hideUnlinkedDepartments);
    }

    public getAllMappedOwsDepartments(): Observable<Array<OwsDepartment>> {
        return combineLatest([
            this.selectAll(),
            this.owsDepartmentQuery.selectAll(),
            this.organizationUnitQuery.selectAll()
        ]).pipe(
            map(([mappings, owsDepartments, organizationUnits]) => {
                return owsDepartments
                    .map(department => (
                        {
                            ...department,
                            linkedOrganizationUnit: mappings.find(x => x.owsDepartmentId === department.id) ?
                                organizationUnits.find(s => s.id === mappings.find(x => x.owsDepartmentId === department.id).organizationUnitId) : null
                        }
                    ))
                    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            })
        );
    }

    public getFilteredOwsDepartments(): Observable<Array<OwsDepartmentTableElement>> {
        return combineLatest([
            this.getAllMappedOwsDepartments(),
            this.getUnlinkedOwsDepartmentsVisibility(),
            this.getSelectedOrganizationUnitId()
        ]).pipe(
            map(([owsDepartments, hideUnlinked, activeOrganizationUnit]) => {
                const visibleDepartments = owsDepartments.filter(department => hideUnlinked ?
                    (department.linkedOrganizationUnit === null || department.linkedOrganizationUnit?.id === activeOrganizationUnit) : true
                );

                const tableElements: Array<OwsDepartmentTableElement> = visibleDepartments.map(department => {
                    const isLinked = department.linkedOrganizationUnit !== null;
                    let displayName = department.name;

                    if (isLinked && department.linkedOrganizationUnit?.id !== activeOrganizationUnit) {
                        displayName += ' - ' + department.linkedOrganizationUnit?.displayName;
                    };

                    return {
                        id: department.id,
                        displayName,
                        parentId: department.parentDepartmentId,
                        linkedOrganizationUnit: department.linkedOrganizationUnit,
                        children: [],
                        checked: isLinked,
                        disabled: activeOrganizationUnit === undefined || (isLinked && department.linkedOrganizationUnit?.id !== activeOrganizationUnit)
                    };
                });

                return this.nestedTreeService.nestArray(tableElements) as Array<OwsDepartmentTableElement>;
            })
        );
    }

    public getUnlinkedDepartmentsCount(): Observable<number> {
        return this.getAllMappedOwsDepartments().pipe(
            map(departments => {
                return departments.filter(department => department.linkedOrganizationUnit === null).length;
            })
        );
    }
}
