import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { filterUndefined } from '@ortec/soca-web-ui';

import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';

import { ResourceTypesManagementQuery } from '../store/resource-types-management.query';
import { ResourceTypesManagementService } from '../store/resource-types-management.service';

@Component({
    selector: 'app-resource-types-filters',
    templateUrl: './resource-types-filters.component.html',
    styleUrls: ['./resource-types-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTypesFiltersComponent implements OnInit {
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    public selectedOrganizationUnit$: Observable<number>;
    public showUnderlyingUnits$: Observable<boolean>;

    constructor(
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly displaySettingQuery: DisplaySettingQuery,
        private readonly displaySettingService: DisplaySettingService,
        private readonly resourceTypesManagementService: ResourceTypesManagementService,
        private readonly resourceTypesManagementQuery: ResourceTypesManagementQuery
    ) { }

    public ngOnInit(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();

        this.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.selectedOrganizationUnit$ = this.resourceTypesManagementQuery.getSelectedOrganizationUnit();
        this.showUnderlyingUnits$ = this.resourceTypesManagementQuery.getShowUnderlyingUnitsState();

        this.setInitialStatesFromDisplaySettings();
    }

    public onSelectedOrganizationUnitChange(id: number): void {
        this.resourceTypesManagementService.updateSelectedOrganizationUnitId(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, id).pipe(first()).subscribe();
    }

    public onUpdateShowChildUnits(event: MatCheckboxChange): void {
        this.resourceTypesManagementService.updateShowChildUnits(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, event.checked).pipe(first()).subscribe();
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.resourceTypesManagementService.updateSelectedOrganizationUnitId(id))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.resourceTypesManagementService.updateShowChildUnits(state))
        ).subscribe();
    }
}
