import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { StatusPageService } from 'src/app/shared/stores/status-page-store/status-page.service';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

import { OrganizationUnitConfigurationsState, OrganizationUnitsConfigurations, OrganizationUnitsConfigurationsStore } from './organization-units-configurations.store';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUnitsConfigurationsService extends StatusPageService<OrganizationUnitConfigurationsState>{
    constructor(
        protected organizationUnitsConfigurationsStore: OrganizationUnitsConfigurationsStore,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
    ) { 
        super(organizationUnitsConfigurationsStore);
    }

    public getActivityTypeForParttimeSchedules(selectedOrganizationUnitId: number): Observable<OrganizationUnitsConfigurations> {
        this.organizationUnitsConfigurationsStore.updateLoadingState(true);

        return this.http.get<OrganizationUnitsConfigurations>('/api/OrganizationUnitSettings/ActivityTypeForParttimeSchedules/' + selectedOrganizationUnitId).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap((activityTypeForParttimeSchedules) => {
                this.organizationUnitsConfigurationsStore.updateSelectedActivityTypeId(activityTypeForParttimeSchedules?.activityTypeId);
                this.organizationUnitsConfigurationsStore.updateLoadingState(false);
            })
        );
    }

    public saveActivityTypeForParttimeSchedules(actTypeForParttimeSchedule: OrganizationUnitsConfigurations): Observable<void> {
        return this.http.put<void>('/api/OrganizationUnitSettings/ActivityTypeForParttimeSchedules', actTypeForParttimeSchedule).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialog('', error.error.statusText);

                return throwError(() => error);
            }),
            tap(() => {
                this.organizationUnitsConfigurationsStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING)
            })
        );
    }

    public updateSelectedActivityTypeId(id: number): void {
        this.organizationUnitsConfigurationsStore.updateSelectedActivityTypeId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.organizationUnitsConfigurationsStore.updateSelectedOrganizationUnitId(id);
    }
}