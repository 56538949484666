<bol-toolbar id="topbar">
    
    <div start>
        <div class="topbar__logocontainer">
            <a href="{{ rootUrl$ | async }}">
                <img class="topbar__icon" src="{{logoSrc}}" alt="Ortec Logo">
            </a>
        </div>
        <app-breadcrumb rootUrl="{{rootUrl$ | async}}"></app-breadcrumb>
    </div>
    
    <div end>
        <button mat-button [matMenuTriggerFor]="menu" data-cy="UserMenuButton">
            {{ displayName$ | async }}
            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <app-language-selector *ngIf="showLanguageSelector"></app-language-selector>
            <button mat-menu-item (click)="onLogout()" data-cy="LogoutButton">
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
            </button>

            <button *ngIf="((isFullUser$ | async) === true) && ((featurePlanboardAPlanningBoard$ | async) === false)" mat-menu-item (click)="onNavigateToUserPreferences()">
                <mat-icon>settings</mat-icon>
                <span translate>Preferences</span>
            </button>
        </mat-menu>

        <ng-container *ngIf="helpPath$ | async">
            <button         
                mat-button
                class="btn bol-button-text icon-only"
                (click)="onNavigateToHelp()">
                <mat-icon>help_outline</mat-icon>
            </button>
        </ng-container>
    </div>
</bol-toolbar>
<div class="stripe"></div>