import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { BolSelectItem, BolSelectMultiTextConfiguration } from '@ortec/bolster/select';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { LanguageService } from 'src/app/shared/language';
import { BolMultiselectLabelsService } from 'src/app/shared/services/bol-multiselect-labels.service';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';

export interface IActivityTypeModalData {
    activityTypes$: Observable<Array<ActivityType>>;
    selectedActivityTypeIds: Array<number>;
    selectedActivityTypeCategoryIds: Array<number>;
    activityTypeCategories$?: Observable<Array<BolSelectItem<any>>>;
}

export interface ActivityTypeSelection extends ActivityType {
    state: boolean;
}

@Component({
    selector: 'app-activity-type-dialog-old',
    templateUrl: './activity-type-dialog.component.html',
    styleUrls: ['./activity-type-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeDialogOldComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public activityTypes: Array<ActivityTypeSelection>;
    public filteredActivityTypesByCategories: Array<ActivityTypeSelection>;
    public filteredActivityTypes$: Observable<Array<ActivityTypeSelection>>;
    public selectLabels$!: Observable<BolSelectMultiTextConfiguration>;
    public activityTypeCategories$!: Observable<Array<BolSelectItem<any>>>;
    public filteredActivityTypesSubject = new BehaviorSubject<Array<ActivityTypeSelection>>([]);

    public filterInputControl = new UntypedFormControl('');
    public activityTypeCategoryFormControl = new UntypedFormControl([], [Validators.required]);
    public selectAllState = true;

    public startIndex = 0;
    public endIndex = 10;

    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<IActivityTypeModalData>,
        @Inject(MAT_DIALOG_DATA) dialogData: IActivityTypeModalData,
        private readonly translateService: TranslateService,
        private readonly languageService: LanguageService,
        private readonly bolMultiselectLabelsService: BolMultiselectLabelsService,
    ) {
        this.filteredActivityTypes$ = this.filteredActivityTypesSubject.asObservable();
        this.activityTypeCategories$ = dialogData.activityTypeCategories$.pipe(
            map((categories) => {
                return categories?.map(category => ({
                    label: this.translateService.instant(category.label as any),
                    value: category.value,
                }));
            })
        );
        this.activityTypeCategoryFormControl.setValue(dialogData.selectedActivityTypeCategoryIds, { emitEvent: false });

        this.subscription.add(
            combineLatest([
                dialogData.activityTypes$,
                dialogData.activityTypeCategories$
            ]).pipe(
                filter(([activityTypes, activityTypeCategories]) => activityTypes !== undefined && activityTypes.length > 0 &&
                    activityTypeCategories !== undefined && activityTypeCategories.length > 0),
                tap(([activityTypes, _]) => {
                    const updatedActivityTypes = activityTypes.map(activityType =>
                        dialogData.selectedActivityTypeIds !== undefined &&
                        dialogData.selectedActivityTypeIds !== null &&
                        !dialogData.selectedActivityTypeIds.includes(activityType.id) ?
                            ({...activityType, state: false}) :
                            ({...activityType, state: true})
                    );

                    const selectedActivityTypeCategoryIds = dialogData.selectedActivityTypeCategoryIds;
                    const filteredActivityTypesByCategories = this.getFilteredActivityTypesByCategories(updatedActivityTypes, selectedActivityTypeCategoryIds);

                    this.activityTypes = updatedActivityTypes;
                    this.filteredActivityTypesByCategories = filteredActivityTypesByCategories;

                    this.updateSelectAllState(filteredActivityTypesByCategories);
                    this.filteredActivityTypesSubject.next(filteredActivityTypesByCategories);
                })
            ).subscribe()
        );

        this.subscription.add(
            this.filterInputControl.valueChanges.pipe(
                startWith(''),
                debounceTime(200),
                map((value: string) => {
                    const filteredActivityTypes = value !== '' ?
                        this.filteredActivityTypesByCategories.filter(actTypeSelection => {
                            const actTypeFullDescription = actTypeSelection.displayName + actTypeSelection.shortName;

                            return actTypeFullDescription.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                        }) : this.filteredActivityTypesByCategories;

                    this.filteredActivityTypesSubject.next(filteredActivityTypes);

                    this.resetPaginator();
                })
            ).subscribe()
        );
    }

    public ngAfterViewInit(): void {
        this.subscription.add(
            this.languageService.currentLanguage$.subscribe(() => {
                if (this.paginator) {
                    this.paginator._intl.itemsPerPageLabel = this.translateService.instant('Items per page:');
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.setActivityTypeCategoryFilter();

        this.selectLabels$ = this.bolMultiselectLabelsService.selectLabels$;
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                activityTypes$: of(this.activityTypes),
                selectedActivityTypeIds: this.getSelectedActivityTypeIds(this.filteredActivityTypesByCategories),
                selectedActivityTypeCategoryIds: this.getSelectedActivityTypeCategoryIds()
            } as IActivityTypeModalData
        });
    }

    public getPaginatorData(event: PageEvent): PageEvent {
        this.startIndex = event.pageIndex * event.pageSize;
        this.endIndex = this.startIndex + event.pageSize;

        return event;
    }

    public resetPaginator(): void {
        this.paginator.firstPage();
    }

    public updateActivityTypeStates(activityTypes: Array<ActivityTypeSelection>, state: boolean, updateSelectAllState: boolean): void {
        this.filteredActivityTypesByCategories = this.filteredActivityTypesByCategories.map(actType =>
            activityTypes.includes(actType) ? ({...actType, state}) : actType
        );
        if (updateSelectAllState) {
            this.updateSelectAllState(this.filteredActivityTypesByCategories);
        }

        this.filteredActivityTypesSubject.next(this.filteredActivityTypesByCategories.filter(activityType =>
            this.filteredActivityTypesSubject.value.map(type => type.id).includes(activityType.id))
        );
    }

    public getSelectedActivityTypeIds(activityTypes: Array<ActivityTypeSelection>): Array<number> {
        return activityTypes ? activityTypes.filter(actType => !!actType.state).map(actType => actType.id) : undefined;
    }

    public getSelectedActivityTypeCategoryIds(): Array<number> {
        return this.activityTypeCategoryFormControl.value;
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    private updateSelectAllState(activityTypes: Array<ActivityTypeSelection>): void {
        const selectedIds = activityTypes.filter(actType => !!actType.state).map(actType => actType.id);

        this.selectAllState = selectedIds.length === activityTypes.length;
    }
    
    private setActivityTypeCategoryFilter(): void {
        this.subscription.add(
            this.activityTypeCategoryFormControl.valueChanges.pipe(
                tap(selectedItems => {
                    const selectedActivityCategoryIds = selectedItems;
                    this.filteredActivityTypesByCategories = this.getFilteredActivityTypesByCategories(this.activityTypes, selectedActivityCategoryIds);

                    this.filteredActivityTypesSubject.next(this.filteredActivityTypesByCategories);
                    this.filterInputControl.setValue('');
                })
            ).subscribe()
        );
    }

    private getFilteredActivityTypesByCategories(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeCategoryIds: Array<number>): Array<ActivityTypeSelection> {
        const activityTypesFilteredByCategories = activityTypes.filter(activityType => selectedActivityTypeCategoryIds ? selectedActivityTypeCategoryIds.includes(activityType.categoryId) : true);

        return activityTypesFilteredByCategories;
    }
}
