import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ColumnDefinition } from '../stores/counters-store/counters-matrix.models';
import { GlobalSettingsQuery } from '../../../../shared/stores/global-settings/global-settings.query';
import { CountersQuery } from '../stores/counters-store/counters.query';
import { COUNTERS_TOTALS_OPTIONS } from './enums';
import { CountersColumnHelperService } from './counters-column-helper.service';

@Injectable({
    providedIn: 'root',
})
export class CountersColumnService {
    constructor(
        protected countersQuery: CountersQuery,
        private readonly helper: CountersColumnHelperService,
        private readonly translateService: TranslateService,
        private readonly globalSettingsQuery: GlobalSettingsQuery
    ) { }

    public getColumnDefinitions(): Array<ColumnDefinition> {
        const totals = this.countersQuery.getTotalsOptionsSync();
        const timeZone = this.globalSettingsQuery.getTimeZoneSync();
        const columnDefinitions: Array<ColumnDefinition> = [];

        const startEnd = this.getStartAndEndDates(timeZone);
        const startDate = startEnd.startDate;
        const endDate = startEnd.endDate;

        const years = this.helper.getYears(startDate, endDate);
        const startMonth = startDate.month() + 1;
        const endMonth = endDate.month() + 1;
        const addedWeekNumbers = [];

        if (this.helper.includeTotalCount(totals)) {
            columnDefinitions.push(this.getTotalColumn(startDate, endDate));
        }

        years.forEach((year) => {
            const isLastYear = year === years[years.length - 1];
            const isFirstYear = year === years[0];

            if (this.helper.includeYearCount(totals)) {
                columnDefinitions.push(this.getYearColumn(year, startDate, endDate));
            }

            for (let halfYearCount = 1; halfYearCount < 3; halfYearCount++) {
                if (this.helper.includeHalfYearCount(totals, startMonth, endMonth, isFirstYear, isLastYear, halfYearCount)) {
                    columnDefinitions.push(this.getHalfYearColumn(totals, isFirstYear, year, startMonth, halfYearCount, startDate, endDate));
                }

                for (let q = 1; q < 3; q++) {
                    const quartCount = (halfYearCount - 1) * 2 + q;

                    if (this.helper.includeQuarterCount(totals, startMonth, endMonth, isFirstYear, isLastYear, quartCount)) {
                        columnDefinitions.push(this.getQuarterYearColumn(totals, isFirstYear, year, startMonth, quartCount, startDate, endDate));
                    }

                    for (let m = 1; m < 4; m++) {
                        const monthCount = (quartCount - 1) * 3 + m;
                        if (this.helper.includeMonthCount(totals, startMonth, endMonth, isFirstYear, isLastYear, monthCount)) {
                            columnDefinitions.push(this.getMonthColumn(year, monthCount, startDate, endDate));
                        }

                        if (this.helper.includeWeekCount(totals, startMonth, endMonth, isFirstYear, isLastYear, monthCount)) {
                            const weeks = this.helper.getWeeksForMonth(monthCount, year, startDate, endDate, timeZone);
                            weeks.forEach(week => {
                                const weeknr = this.weekStringToWeekNumber(week);

                                if (this.helper.includeWeek(totals, addedWeekNumbers, week)) {
                                    addedWeekNumbers.push(week);
                                    columnDefinitions.push(this.getWeekColumn(year, weeknr, startDate, endDate));
                                }

                                if (this.helper.includeDayForWeekCount(totals)) {
                                    const days = this.helper.getDaysForWeek(weeknr, monthCount, year, isFirstYear, isLastYear, startDate, endDate, timeZone);
                                    days.forEach(day => {
                                        columnDefinitions.push(this.getDayColumn(year, day));
                                    });
                                }
                            });
                        }

                        if (this.helper.includeDayForMonthCount(totals)) {
                            const days = this.helper.getDaysForMonth(monthCount, year, isFirstYear, isLastYear, startDate, endDate, timeZone);
                            days.forEach(day => {
                                columnDefinitions.push(this.getDayColumn(year, day));
                            });
                        }
                    }
                }
            }
        });

        return columnDefinitions;
    }

    private getStartAndEndDates(timeZone): {startDate: moment.Moment; endDate: moment.Moment} {
        let startDate = moment(this.countersQuery.getSelectedStartDateSync()).tz(timeZone);
        let endDate = moment(this.countersQuery.getSelectedEndDateSync()).tz(timeZone);

        const scenarioOnlyCounters = this.countersQuery.getOnlyScenarioCounterTypesSelected();

        if (scenarioOnlyCounters) {
            const scenario = this.countersQuery.getSelectedScenarioSync();
            const scenarioStart = moment(scenario.start).tz(timeZone);
            const scenarioEnd = moment(scenario.end).tz(timeZone);

            if (scenarioStart.isAfter(startDate)) {
                startDate = scenarioStart;
            }
            if (scenarioEnd.isBefore(endDate)) {
                endDate = scenarioEnd;
            }
        }

        return {startDate, endDate};
    }

    private getTotalColumn(startDate: moment.Moment, endDate: moment.Moment): ColumnDefinition {
        return {
            columnName: 'total',
            columnLabel: this.translateService.instant('total'),
            cssClassName: 'total-column',
            startDay: startDate,
            endDay: endDate
        };
    }

    private getYearColumn(year: number, startDate: moment.Moment, endDate: moment.Moment): ColumnDefinition {
        let startDay = moment().year(year).startOf('year');
        let endDay = moment().year(year).endOf('year');
        if (startDay.isBefore(startDate)) {
            startDay = startDate;
        }

        if (endDay.isAfter(endDate)) {
            endDay = endDate;
        }

        return {
            columnName: year.toString(),
            columnLabel: year.toString(),
            cssClassName: 'year-total-column',
            startDay,
            endDay
        };
    }

    private getHalfYearColumn(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        isFirstYear: boolean,
        year: number,
        startMonth: number,
        currentHalfYear: number,
        startDate: moment.Moment,
        endDate: moment.Moment
    ): ColumnDefinition {
        let startDay = moment().year(year).month((currentHalfYear * 6) - 6).startOf('month');
        let endDay = moment().year(year).month((currentHalfYear * 6) - 1).endOf('month');

        if (startDay.isBefore(startDate)) {
            startDay = startDate;
        }

        if (endDay.isAfter(endDate)) {
            endDay = endDate;
        }

        return {
            columnName: year + 'H' + currentHalfYear,
            columnLabel: this.getHalfYearLabel(totals, isFirstYear, year, startMonth, currentHalfYear),
            cssClassName: 'half-year-total-column',
            startDay,
            endDay
        };
    }

    private getQuarterYearColumn(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        isFirstYear: boolean,
        year: number,
        startMonth: number,
        currentQuarterYear: number,
        startDate: moment.Moment,
        endDate: moment.Moment
    ): ColumnDefinition {
        let startDay = moment().year(year).month((currentQuarterYear * 3) - 3).startOf('month');
        let endDay = moment().year(year).month((currentQuarterYear * 3) - 1).endOf('month');

        if (startDay.isBefore(startDate)) {
            startDay = startDate;
        }

        if (endDay.isAfter(endDate)) {
            endDay = endDate;
        }

        return {
            columnName: year + 'Q' + currentQuarterYear,
            columnLabel: this.getQuarterLabel(totals, isFirstYear, year, startMonth, currentQuarterYear),
            cssClassName: 'quarter-total-column',
            startDay,
            endDay
        };
    }

    private getMonthColumn(
        year: number,
        month: number,
        startDate: moment.Moment,
        endDate: moment.Moment
    ): ColumnDefinition{
        let startDay = moment().year(year).month(month - 1).startOf('month');
        let endDay = moment().year(year).month(month - 1).endOf('month');

        if (startDay.isBefore(startDate)) {
            startDay = startDate;
        }

        if (endDay.isAfter(endDate)) {
            endDay = endDate;
        }

        return {
            columnName: year + 'M' + month,
            columnLabel: this.getMonthLabel(month),
            cssClassName: 'month-total-column',
            startDay,
            endDay
        };
    }

    private getWeekColumn(
        year: number,
        week: number,
        startDate: moment.Moment,
        endDate: moment.Moment
    ): ColumnDefinition{
        // NOTE there is some weird behaviour in moment.js, where "moment().tz(timeZone).year(year).isoWeek(currentWeek)" 
        // on a current date of 3-1-2022, with as params "year: 2021" and "currentWeek: 1" would set the date back in 2019.
        // Because of this, we start out with a mocked date of 1-6 to negate this issue. 
        let startDay = moment('1-6-' + year, 'DDMMYYYY').isoWeek(week).startOf('isoWeek');
        let endDay = moment('1-6-' + year, 'DDMMYYYY').isoWeek(week).endOf('isoWeek');

        if (startDay.isBefore(startDate)) {
            startDay = startDate;
        }

        if (endDay.isAfter(endDate)) {
            endDay = endDate;
        }

        return {
            columnName: year  + 'W' +  week,
            columnLabel: 'W' + week,
            cssClassName: 'week-total-column no-background',
            startDay,
            endDay
        };
    }

    private getDayColumn(
        year: number,
        day: moment.Moment
    ): ColumnDefinition{
        const dayString = day.format('DD-MM');
        const startDay = day.clone().startOf('day');
        const endDay = day.clone().endOf('day');

        return {
            columnName: year  + 'D' +  dayString,
            columnLabel: dayString,
            cssClassName: '',
            startDay,
            endDay
        };
    }

    private getHalfYearLabel(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        isFirstYear: boolean,
        year: number,
        startMonth: number,
        currentHalfYear: number
    ): string {
        if (!totals.includes(COUNTERS_TOTALS_OPTIONS.YEAR) &&
        ((isFirstYear &&
        (startMonth <= 6 && currentHalfYear === 1) ||
        (startMonth > 6 && currentHalfYear === 2)) ||
        (!isFirstYear && currentHalfYear === 1))) {
            return year + ' H' + currentHalfYear;
        }

        return 'H' + currentHalfYear;
    }

    private getQuarterLabel(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        isFirstYear: boolean,
        year: number,
        startMonth: number,
        currentQuarter: number
    ): string {
        if (!(totals.includes(COUNTERS_TOTALS_OPTIONS.YEAR) || totals.includes(COUNTERS_TOTALS_OPTIONS.HALF_YEAR)) &&
        ((isFirstYear && (startMonth <= (currentQuarter * 3)) && !(startMonth <= ((currentQuarter - 1) * 3))) ||
        (!isFirstYear && currentQuarter === 1))) {
            return year + ' Q' + currentQuarter;
        }

        return 'Q' + currentQuarter;
    }

    private getMonthLabel(month: number): string {
        const currentLocale = this.translateService.currentLang;

        return moment().locale(currentLocale).set('month', month - 1).format('MMM');
    }

    private weekStringToWeekNumber(weekstring: string): number {
        return Number(weekstring.split('-')[1]);
    }
}
