/* eslint-disable radix */
import { Injectable } from '@angular/core';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';

import { CHANGE_ACTION_TYPES, ORIGIN_TYPES } from '../store/activity-change.model';

export interface ChangeTableFilteringItem {
    id: number;
    displayName: string;
}

@Injectable({
    providedIn: 'root'
})
export class ChangesHelperService {
    public actionType = {
        1: CHANGE_ACTION_TYPES.ADD,
        2: CHANGE_ACTION_TYPES.ASSIGN,
        3: CHANGE_ACTION_TYPES.CHANGE,
        4: CHANGE_ACTION_TYPES.DELETE,
        5: CHANGE_ACTION_TYPES.UNASSIGN,
        6: CHANGE_ACTION_TYPES.LOCKED,
        7: CHANGE_ACTION_TYPES.UNLOCKED,
        8: CHANGE_ACTION_TYPES.NOT_REQUIRED,
        9: CHANGE_ACTION_TYPES.CANCEL
    };

    public originType = {
        0: ORIGIN_TYPES.USER,
        // 1: ORIGIN_TYPES.OWS,
        2: ORIGIN_TYPES.MULTISELECT
    };

    public getActionTypeByName(name: string): number {
        const entry = Object.entries(this.actionType).find(([key, value]) => value === name);
        
        return entry ? parseInt(entry[0]) : undefined;
    }

    public addStylingForActivityColumn(actType: ActivityType): any {
        return {
            backgroundColor: '#' + actType.backColor,
            color: '#' + actType.textColor
        };
    }

    public getActionTypeByNumber(value: number): string {
        return this.actionType[value];
    }
    
    public getOriginTypeByNumber(value: number): string {
        return this.originType[value];
    }
}
