import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { FileManagerService } from './file-manager.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelFileManagerService implements FileManagerService {
    public exportFile(buffer: BlobPart, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
