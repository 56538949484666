import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ReportUri } from './report-uri.model';
import { ReportUriStore } from './report-uri.store';

@Injectable({
    providedIn: 'root'
})
export class ReportUriService {
    constructor(
        protected reportUriStore: ReportUriStore,
        private readonly http: HttpClient,
    ) { }

    public getReportUris(): Observable<Array<ReportUri>> {
        return this.http.get<Array<ReportUri>>('/api/Reports/Uris/All').pipe(
            tap((reportUris) => {
                this.reportUriStore.set(reportUris);
            })
        );
    }
}
