import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OwsResourceTypeStore } from './ows-resource-type.store';
import { OwsResourceType } from './ows-resource-type.model';

@Injectable({
    providedIn: 'root'
})
export class OwsResourceTypeService {
    constructor(
        protected owsResourceTypeStore: OwsResourceTypeStore,
        private readonly http: HttpClient,
    ) { }

    public get(): Observable<void> {
        return this.http.get<Array<OwsResourceType>>('/api/OwsInterface/OwsResourceTypes').pipe(
            map((resourceTypes) => {
                this.owsResourceTypeStore.set(resourceTypes);
            })
        );
    }
}
