import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function cache<T>(): MonoTypeOperatorFunction<T> {
    return (input$: Observable<T>) => input$.pipe(
        publishReplay(1),
        refCount()
    );
}
