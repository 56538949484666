import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { User } from 'src/app/shared/stores/user-store/user.model';

export interface UserGroupsManagementState {
    selectedUserGroupId: number;
    selectedUsers: Array<User>;
    manageMode: MANAGE_MODE;
}

const initialState = {
    selectedUserGroupId: undefined,
    selectedUsers: [],
    manageMode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-groups-management' })
export class UserGroupsManagementStore extends Store<UserGroupsManagementState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedUserGroupId(id: number): void {
        this.update(state => ({ ...state, selectedUserGroupId: id }));
    }

    public updateManageMode(manageMode: MANAGE_MODE): void {
        this.update(state => ({ ...state, manageMode }));
    }

    public updateSelectedUsers(users: Array<User>): void {
        this.update(state => ({ ...state, selectedUsers: users }));
    }

    public setStateForNewUserGroup(id: number): void {
        this.update(state => (
            { 
                ...state, 
                selectedUserGroupId: id,
                manageMode: MANAGE_MODE.EDIT
            }));
    }
}
