<div class="bol-modal__header">
    <span *ngIf="mode === manageMode.EDIT">{{ "EDIT-ENTITY-TITLE" | translate: { entityName: prefixedEntityName | translate } }}</span>
    <span *ngIf="mode === manageMode.ADD">{{ "ADD-ENTITY-TITLE" | translate: { entityName: prefixedEntityName | translate } }}</span>
    <span *ngIf="mode === manageMode.CLONE">{{ "CLONE-ENTITY-TITLE" | translate: { entityName: prefixedEntityName | translate } }}</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content>
    <h4 *ngIf="mode === manageMode.CLONE" class="bol-text bol-text-regular">Clone template "{{ entity.displayName }}" to:</h4>
    <form [formGroup]="form" (ngSubmit)="onAddEntity()">
        <formly-form 
            [form]="form"
            [model]="internalModel" 
            [fields]="internalFormFields" 
        ></formly-form>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <ng-container *ngIf="mode === manageMode.ADD || mode === manageMode.EDIT">
            <button 
                type="button"
                (click)="onCancelAdd()"
                mat-button 
                class="bol-button-outlined"
                data-cy="cancelEntityButton">
                <span translate>Cancel</span>
            </button>
        
            <button 
                type="button"
                mat-button 
                (click)="onAddEntity()"
                class="bol-button-solid bol-positive"
                [disabled]= "form.invalid"
                data-cy="saveEntityButton">
                <span translate>Save</span>
            </button>
        </ng-container>
        <ng-container *ngIf="mode === manageMode.CLONE">
            <button 
                type="button"
                (click)="onCancelAdd()"
                mat-button 
                class="bol-button-outlined"
                data-cy="cancelEntityButton">
                <span translate>Cancel</span>
            </button>

            <button 
                type="button"
                (click)="onClone()"
                mat-button 
                class="bol-button-solid bol-positive"
                data-cy="cloneEntityButton">
                <span translate>Clone</span>
            </button>
        </ng-container>
    </div>
</mat-dialog-actions>
  