/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { fieldNonEmptyValidatorKey } from '@ortec/soca-web-ui/validators';
import { EntityListColumnDefinition } from 'src/app/shared/components/entity-management/entity-list-panel/entity-list-panel.component';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';

export function getSkillLevelFormFields(
    organizationUnitsTree: Array<IOrganizationUnitTree>,
): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        }, 
        {
            key: 'organizationUnitId',
            type: 'tree-single-select',
            props: {
                entities: organizationUnitsTree,
                placeholderText: 'Coupled organization units'
            }
        }
             
    ];
}

export function getSkillLevelColumnDefinition(): Array<EntityListColumnDefinition> {
    return [
        {
            columnDisplayName: 'Display name',
            entityProperty: 'displayName'
        }
    ];
}