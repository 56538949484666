/* eslint-disable radix */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription, of } from 'rxjs';
import { fieldNonEmptyValidatorKey } from '@ortec/soca-web-ui/validators';

import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';
import { ManageCounter } from 'src/app/shared/stores/manage-counter-store/manage-counter.model';
import { FILTER_TYPE, FilterEntityManageGridColumn } from 'src/app/shared/components/entity-management/entity-manage-grid/entity-manage-grid.model';
import { UtilsService } from 'src/app/shared/services/utils.service';

export function getManageCountersFormFields(resourceTypes: Array<ResourceType>, skills: Array<Skill>, dayparts: Array<Daypart>): Array<FormlyFieldConfig> {
    let skillsBasedOnSelectedResourceTypeId: Array<Skill> = [];
    let previousSelectedSkillIds: Array<number> = [];

    let resourceTypeSubscription = new Subscription();

    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'resourceTypeId',
            type: 'tree-single-select',
            props: {
                entities: resourceTypes,
                placeholderText: 'Resource type',
                required: true
            },
            hooks: {
                onInit: (field) => {
                    resourceTypeSubscription.add(
                        field.form.get('resourceTypeId').valueChanges.subscribe((resourceTypeId) => {
                            if (resourceTypeId) {
                                const selectedResourceType = resourceTypes.find(resourceType => resourceType.id === resourceTypeId);
                                skillsBasedOnSelectedResourceTypeId = skills.filter(skill => skill.validResourceTypeIds.some(id => id === selectedResourceType.id));
                                const skillField = field.parent.fieldGroup.find((item) => item.key === 'skillIds');
                                previousSelectedSkillIds = skillField.formControl.value;
                                
                                if (skillField) {
                                    const retainedSelectedSkillIds = previousSelectedSkillIds?.filter(id => skillsBasedOnSelectedResourceTypeId.some(skill => skill.id === id));
                                    skillField.props.entities = skillsBasedOnSelectedResourceTypeId;
                                    skillField.formControl.setValue(retainedSelectedSkillIds);
                                    skillField.formControl.markAsTouched();
                                }
                            }
                        })
                    )
                },
                onDestroy: (_formGroup) => {
                    resourceTypeSubscription.unsubscribe();
                    resourceTypeSubscription = new Subscription();
                }
            }
        },
        {
            key: 'skillIds',
            type: 'multiselect-tree',
            props: {
                entities: skills,
                placeholderText: 'Skills',
                searchProperties: ['displayName'],
                searchFieldPlaceholder: 'Search',
            },
        },
        {
            key: 'daypartId',
            type: 'tree-single-select',
            props: {
                entities: dayparts,
                placeholderText: 'Daypart',
            }
        },
        {
            key: 'usedInActivityDemandTemplate',
            type: 'soca-info-block',
            props: {
                label: 'Used in template',
            },
            hideExpression: (model) => { return model.id === -1 || !model.usedInActivityDemandTemplate}
        },
        {
            key: 'usedInActivityPlan',
            type: 'soca-info-block',
            props: {
                label: 'Used in plan',
            },
            hideExpression: (model) => { return model.id === -1 || !model.usedInActivityPlan; }
        }
    ];
}

export function getManageCounterGridColumns(resourceTypes: Array<ResourceType>, skills: Array<Skill>, dayparts: Array<Daypart>, utilsService: UtilsService): Array<FilterEntityManageGridColumn> {
    return [
        {
            columnDef: 'displayName',
            header: 'Display name',
            filterType: FILTER_TYPE.SEARCH,
            cell: (counter: ManageCounter) => `${counter.displayName}`,
        },
        {
            columnDef: 'resourceTypeId',
            header: 'Resource type',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: { name: 'Resource type', entities: resourceTypes, selectedIds$: of([])},
            cell: (counter: ManageCounter) => `${counter.resourceType.displayName}`,
            sortValue: (counter: ManageCounter) => `${counter.resourceType.displayName}`,
        },
        {
            columnDef: 'skillIds',
            header: 'Skills',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: { name: 'Skill', entities: skills, selectedIds$: of([]) },
            cell: (counter: ManageCounter) => {
                const sortedSkills = counter?.skills?.map(skill => skill.displayName).sort((a, b) => a.localeCompare(b)); // Sort skills alphabetically

                return sortedSkills?.join(', ');
            },
            sortValue: (counter: ManageCounter) => {
                const sortedSkills = counter?.skills?.map(skill => skill.displayName).sort((a, b) => a.localeCompare(b)); // Sort skills alphabetically

                return sortedSkills?.join(', ');
            }
        },
        {
            columnDef: 'daypartId',
            header: 'Daypart',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: { name: 'Daypart', entities: dayparts, selectedIds$: of([]) },
            cell: (counter: any) =>  counter?.daypart?.name,
            sortValue: (counter: any) => {                
                return counter?.daypart?.name;
            }
        },
        {
            columnDef: 'usedInActivityDemandTemplate',
            header: 'Used in template',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: {name: 'Used in template', entities: utilsService.getYesNoSearchValues(), selectedIds$: of([]), translationNeeded: true},
            cell: () => '',
            icon: (counter: ManageCounter) => { 
                if (counter?.usedInActivityDemandTemplate) {
                    return 'check';
                }    
            },
            sortValue: (counter: ManageCounter) => counter?.usedInActivityDemandTemplate
        },
        {
            columnDef: 'usedInActivityPlan',
            header: 'Used in plan',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: {name: 'Used in plan', entities: utilsService.getYesNoSearchValues(), selectedIds$: of([]), translationNeeded: true},
            cell: () => '',
            icon: (counter: ManageCounter) => { 
                if (counter?.usedInActivityPlan) {
                    return 'check';
                }  
            },
            sortValue: (counter: ManageCounter) => counter?.usedInActivityPlan
        }
    ];
}

