import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

import { SortableActivityType } from '../../sorting-palette/store/sorting-palette.model';
import { FilteringPaletteState, FilteringPaletteStore } from './filtering-palette.store';
import { StatusPageService } from 'src/app/shared/stores/status-page-store/status-page.service';

@Injectable({
    providedIn: 'root'
})
export class FilteringPaletteService extends StatusPageService<FilteringPaletteState> {
    constructor(
        protected filteringPaletteStore: FilteringPaletteStore,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly errorDialogService: ErrorDialogService,
    ) {
        super(filteringPaletteStore);
     }

    public getSelectedActivityTypes(): Observable<Array<number>> {
        this.filteringPaletteStore.updateEntitiesLoading(true);

        return this.http.get<Array<SortableActivityType>>('/api/Users/SelectedActivityTypesFilterAndSorting').pipe(
            map((activityTypesSelection) => {
                const selectedActivityTypeIds = activityTypesSelection.map(actTypeSel => actTypeSel.activityTypeId);
                this.filteringPaletteStore.updateSelectedActivityTypeIds(selectedActivityTypeIds);
                this.filteringPaletteStore.updateEntitiesLoading(false);
                
                return selectedActivityTypeIds;
            })
        );
    }

    public updateSelectedActivityTypes(activityTypeSelection: Array<number>): Observable<void> {
        this.filteringPaletteStore.updateSelectedActivityTypeIds(activityTypeSelection);
        this.filteringPaletteStore.updatePageStatusState(STATUS.IS_LOADING);

        return this.http.put<void>('/api/Users/SelectedActivityTypesFilter', activityTypeSelection).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('Error');
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);
                this.filteringPaletteStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => error);
            }),
            tap(() => this.filteringPaletteStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        );
    }

    public updateEntitiesLoading(state: boolean): void {
        this.filteringPaletteStore.updateEntitiesLoading(state);
    }

    public updateSelectedOrganizationUnitIds(ids: Array<number>): void {
        this.filteringPaletteStore.updateSelectedOrganizationUnitIds(ids);
    }

    public updateSelectedActivityTypeCategoryIds(ids: Array<number>): void {
        this.filteringPaletteStore.updateSelectedActivityTypeCategoryIds(ids);
    }

    public updateShowOnlySelectedState(state: boolean): void {
        this.filteringPaletteStore.updateShowOnlySelectedState(state);
    }

    public updateApplyFilterState(state: boolean): void {
        this.filteringPaletteStore.updateApplyFilterState(state);
    }
}
