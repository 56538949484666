import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { SHOW_OPTIONS } from '../../../schedule-helpers/enums';
import { ScheduleShowOption } from '../../../stores/schedule-store/schedule.model';
import { ScheduleService } from '../../../stores/schedule-store/schedule.service';

export interface DisplayOptionsData {
    showOptions$: Observable<Array<ScheduleShowOption>>;
    activityShowOptions$: Observable<Array<ScheduleShowOption>>;
}
@Component({
    selector: 'app-show-options-configuration-dialog',
    templateUrl: './show-options-configuration-dialog.component.html',
    styleUrls: ['./show-options-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowOptionsConfigurationDialogComponent implements OnDestroy {
    public showOptions: Array<ScheduleShowOption>;
    public actShowOptions: Array<ScheduleShowOption>;
   
    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<DisplayOptionsData>,
        private readonly scheduleService: ScheduleService,
        @Inject(MAT_DIALOG_DATA) dialogData: DisplayOptionsData,
    ) {
        this.subscription.add(
            combineLatest([
                dialogData.showOptions$,
                dialogData.activityShowOptions$
            ]).pipe(
                filter(([showOptions, activityShowOptions]) => showOptions !== undefined && showOptions.length > 0 &&
                    activityShowOptions !== undefined && activityShowOptions.length > 0),
                tap(([showOptions, activityShowOptions]) => {
                    this.showOptions = showOptions;
                    this.actShowOptions = activityShowOptions;
                })
            ).subscribe()
        );
    }
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                showOptions$: of(this.showOptions),
                activityShowOptions$: of(this.actShowOptions)
            } as DisplayOptionsData
        });
        this.scheduleService.updateShowOptions(this.showOptions);
        this.scheduleService.updateActivityShowOptions(this.actShowOptions);
    }

    public isAResourceScheduleOptionOnly(showOpt: ScheduleShowOption): boolean {
        return this.actShowOptions.some(actShowOpt => actShowOpt.value === showOpt.value);
    }

    public findActShowOptionState(showOpt: ScheduleShowOption): boolean {
        return this.actShowOptions.find(actShowOpt => actShowOpt.value === showOpt.value).state;
    }

    public findActShowOption(showOpt: ScheduleShowOption): ScheduleShowOption {
        return this.actShowOptions.find(actShowOpt => actShowOpt.value === showOpt.value);
    }

    public updateShowOptionsStates(event: MatCheckboxChange, showOpt: ScheduleShowOption): void {
        this.showOptions.map(element => {
            if (element.value === showOpt.value) {
                element.state = event.checked;
            }
        });

        if (showOpt.value === SHOW_OPTIONS.SHOW_SAT_SUN) {
            this.actShowOptions.map(element => {
                if (element.value === showOpt.value) {
                    element.state = event.checked;
                }
            });
        }
    }

    public updateActivityShowOptionsStates(event: MatCheckboxChange, actShowOpt: ScheduleShowOption): void {
        this.actShowOptions.map(element => {
            if (element.value === actShowOpt.value) {
                element.state = event.checked;
            }
        });
        if (actShowOpt.value === SHOW_OPTIONS.SHOW_SAT_SUN) {
            this.showOptions.map(element => {
                if (element.value === actShowOpt.value) {
                    element.state = event.checked;
                }
            });
        }
    }

    public shouldShowWarning(): boolean {
        const showActivePanelOptionInShowOptions = this.showOptions.find(option => option.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL);
        const showActivePanelOptionInActShowOptions = this.actShowOptions.find(option => option.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL);
    
        return !(showActivePanelOptionInShowOptions?.state || showActivePanelOptionInActShowOptions?.state);
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }
}