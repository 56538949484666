import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ActivityTypeCategory } from '../../stores/activity-type-category-store/activity-type-category.model';

import { ActivityType, IActivityTypeTree } from '../../stores/activity-type-store/activity-type.model';
import { IOrganizationUnitTree } from '../../stores/organization-unit-store/organization-unit.model';
import { ActivityTypeDialogNewComponent, IActivityTypeModalData } from './components/activity-type-dialog.component';

@Component({
    selector: 'app-activity-type-selection-new',
    templateUrl: './activity-type-selection.component.html',
    styleUrls: ['./activity-type-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeSelectionNewComponent implements OnDestroy, OnInit {
    @Output() public readonly updateSelectedActivityTypeIdsEvent = new EventEmitter<Array<number>>();
    @Output() public readonly updateApplyFilterStateEvent = new EventEmitter<boolean>();

    @Input() public selectedActivityTypeIds: Array<number>;
    @Input() public applyFilterState: boolean;
    @Input() public showApplyFilter: boolean = true;
    @Input() public dialogTitle: string;
    @Input() public noActivityTypesSelectedByDefault: boolean = false;
    @Input() private set buttonTitle(title: string) {
        this.buttonTitleSubject.next(title);
    };
    @Input() private set activityTypes(actTypes: Array<ActivityType | IActivityTypeTree>) {
        this.activityTypesSubject.next(actTypes);
    };
    @Input() private set activityTypeCategories(actTypeCategories: Array<ActivityTypeCategory>) {
        this.activityTypeCategoriesSubject.next(actTypeCategories);
    };

    @Input() private set organizationUnits(orgUnits: Array<IOrganizationUnitTree>) {
        this.organizationUnitsSubject.next(orgUnits);
    };

    public activityTypes$: Observable<Array<ActivityType | IActivityTypeTree>>;
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    public buttonTitle$: Observable<string>;

    private readonly activityTypesSubject = new BehaviorSubject<Array<ActivityType | IActivityTypeTree>>(undefined);
    private readonly buttonTitleSubject = new BehaviorSubject<string>(undefined);
    private readonly organizationUnitsSubject = new BehaviorSubject<Array<IOrganizationUnitTree>>(undefined);
    private activityTypeCategories$: Observable<Array<ActivityTypeCategory>>;
    private readonly activityTypeCategoriesSubject = new BehaviorSubject<Array<ActivityTypeCategory>>(undefined);

    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: MatDialog,
    ) { }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.buttonTitle$ = this.buttonTitleSubject.asObservable();
        this.activityTypes$ = this.activityTypesSubject.asObservable();
        this.activityTypeCategories$ = this.activityTypeCategoriesSubject.asObservable();
        this.organizationUnits$ = this.organizationUnitsSubject.asObservable();
    }
    
    public onDisplaySelectionDialog(): void {
        const dialogRef = this.dialogService.open(ActivityTypeDialogNewComponent, {
            data: {
                activityTypes$: this.activityTypes$,
                activityTypeCategories$: this.activityTypeCategories$,
                selectedActivityTypeIds: this.selectedActivityTypeIds,
                organizationUnits$: this.organizationUnits$,
                noActivityTypesSelectedByDefault: this.noActivityTypesSelectedByDefault,
                applyFilterState: this.applyFilterState,
                dialogTitle: this.dialogTitle,
                showApplyFilter: this.showApplyFilter
            } as IActivityTypeModalData,
            panelClass: 'dialog-large',
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IActivityTypeModalData }) => {
                    if (dialogData) {
                        this.updateSelectedActivityTypeIdsEvent.next(dialogData.data.selectedActivityTypeIds);
                        this.updateApplyFilterStateEvent.next(dialogData.data.applyFilterState);
                    }
                })
            ).subscribe()
        );
    }
}
