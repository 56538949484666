import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoggingService } from './logging.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    private error: string;

    constructor(
        private readonly loggingService: LoggingService
    ) { }

    public setError(error: string): void {
        this.error = error;
    }

    public getError(): string {
        return this.error;
    }

    public handleError(error: any): void {
        if (environment.enableApplicationInsights) {
            this.loggingService.logException(error);
        }
        console.log(error);
    }
}
