import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { filterUndefined } from '@ortec/soca-web-ui';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { SkillLevelsManagementQuery } from '../store/skill-levels-management.query';
import { SkillLevelsManagementService } from '../store/skill-levels-management.service';
@Component({
    selector: 'app-skill-levels-filters',
    templateUrl: './skill-levels-filters.component.html',
    styleUrls: ['./skill-levels-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillLevelsFiltersComponent implements OnInit {
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    public selectedOrganizationUnit$: Observable<number>;

    constructor(
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly displaySettingQuery: DisplaySettingQuery,
        private readonly displaySettingService: DisplaySettingService,
        private readonly skillLevelsManagementService: SkillLevelsManagementService,
        private readonly skillLevelsManagementQuery: SkillLevelsManagementQuery
    ){}
    public ngOnInit(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.displaySettingService.getDisplaySettings().pipe(first()).subscribe();
        this.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.selectedOrganizationUnit$ = this.skillLevelsManagementQuery.getSelectedOrganizationUnitId();
        
        this.setInitialStatesFromDisplaySettings();
    }
    
    public onSelectedOrganizationUnitChange(id: number): void {
        this.skillLevelsManagementService.updateSelectedOrganizationUnitId(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, id).pipe(first()).subscribe();
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.skillLevelsManagementService.updateSelectedOrganizationUnitId(id))
        ).subscribe();
    }
}