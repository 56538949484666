export interface Scenario {
    id: number;
    displayName: string;
    start: string;
    end: string;
    scenarioType: ScenarioType;
}

export enum ScenarioType {
    Planning = 1,
    Draft = 2,
    SolverResult = 3
}
