import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, Store } from '@datorama/akita';
import { ActivityChange } from './activity-change.model';

export interface ActivityChangeState extends EntityState<ActivityChange> {
    ui: {
        selectedActionTypes: Array<number>;
        selectedOrgins: Array<number>;
        entitiesLoading: boolean;
    };
    activityChanges: Array<ActivityChange>;
}

const initialState = {
    ui: {
        selectedActionTypes: [],
        selectedOrgins: [],
        entitiesLoading: false
    },
    activityChanges: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'change' })
export class ActivityChangeStore extends Store<ActivityChangeState> {

    constructor() {
        super(initialState);
    }

    public setActivityChanges(activityChanges: Array<ActivityChange>): void {
        this.update(state => ({ ...state, activityChanges }));
    }

    public updateEntitiesLoadingState(entitiesLoading: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }

    public updateSelectedOrginsState(selectedOrgins: Array<number>): void {
        this.update(state => {
            const ui = { ...state.ui, selectedOrgins };

            return { ...state, ui };
        });
    }

    public updateSelectedActionTypes(selectedActionTypes: Array<number>): void {
        this.update(state => {
            const ui = { ...state.ui, selectedActionTypes };

            return { ...state, ui };
        });
    }
}
