import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BolToolbarModule } from '@ortec/bolster/toolbar';
import { SharedModule } from '../shared/shared.module';

import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { TopbarComponent } from './topbar/topbar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
    declarations: [
        TopbarComponent,
        LanguageSelectorComponent,
        BreadcrumbComponent,
        ErrorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BolToolbarModule,
        SharedModule
    ],
    exports: [
        RouterModule,
        TopbarComponent,
        ErrorComponent
    ]
})
export class CoreModule { }
