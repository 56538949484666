import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, merge, Observable, Subscription } from 'rxjs';
import { filter, first, map, skip, startWith, take, tap, withLatestFrom } from 'rxjs/operators';

import { cache } from 'src/app/core/rxjs-utils/cache.operator';
import { IFilterChipListObject } from 'src/app/shared/components/inputs/filter-chip-list/filter-chip-list.component';
import { LanguageService } from 'src/app/shared/language';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityTypeCategoryService } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { FILTER_SETTING_TYPE } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { IOrganizationUnitTree} from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { ResourcePropertyLanguageService } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.service';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { GROUPING_OPTIONS } from '../../schedule-helpers/enums';
import { GroupingOption, ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleRequestParameters } from '../../stores/schedule-store/schedule-request-parameters.model';
import { ScheduleShowOption } from '../../stores/schedule-store/schedule.model';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';
import { BolSelectItem } from '@ortec/bolster/select';

@Component({
    selector: 'app-schedule-filters-old',
    templateUrl: './schedule-filters.component.html',
    styleUrls: ['./schedule-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleFiltersOldComponent implements OnInit, OnDestroy {
    @ViewChild('groupingOptionSelect') public groupingOptionSelect!: MatSelect;

    public scheduleShowOptions$!: Observable<Array<ScheduleShowOption>>;
    public organizationsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public preselectedOrganizationUnits$: Observable<Array<number>>;
    public activityTypes$!: Observable<Array<ActivityType>>;
    public activityTypeCategories$!: Observable< Array<BolSelectItem<any>>>;
    public selectedActivityTypeIds$: Observable<Array<number>>;
    public selectedActivityTypeCategoryIds$: Observable<Array<number>>;
    public baseResourceTypesForFiltering$: Observable<Array<ResourceType>>;
    public extraResourceTypesForFiltering$: Observable<Array<ResourceType>>;
    public selectedBaseResourceTypes$: Observable<Array<ResourceType>>;
    public selectedExtraResourceTypes$: Observable<Array<ResourceType>>;
    public allResourceProperties$: Observable<Array<IFilterChipListObject>>;
    public selectedResourceProperties$: Observable<Array<IFilterChipListObject>>;
    public selectedResourcePropertiesLength$: Observable<number>;
    public forceErrorStateOnBaseResourceTypes$: Observable<boolean>;

    public groupingOptions: Array<GroupingOption>;
    public numberOfDays: Array<number> = [1, 2, 3, 4, 5, 6, 7, 14, 28];

    public readonly dateTimeControls = {
        fromDate: new UntypedFormControl(new Date(), [Validators.required]),
    };

    public readonly resourceControls = {
        groupingOption: new UntypedFormControl(GROUPING_OPTIONS.RESOURCE_TYPE),
        numberOfDays: new UntypedFormControl(7),
    };

    public readonly formControls = {
        dateTimeGroup: new UntypedFormGroup(this.dateTimeControls, [Validators.required]),
        resourceTypesGroup: new UntypedFormGroup(this.resourceControls, [Validators.required])
    };

    private isFullUser: boolean;

    private readonly selectedOrganizationUnitIds$ = new BehaviorSubject<Array<number>>(undefined);
    private readonly subscription: Subscription = new Subscription();

    constructor(
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly scheduleService: ScheduleService,
        private readonly daypartQuery: DaypartQuery,
        private readonly languageService: LanguageService,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly resourcePropertyLanguageService: ResourcePropertyLanguageService,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeCategoryService: ActivityTypeCategoryService,
        private readonly activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly filterSettingQuery: FilterSettingQuery,
    ) { }

    public ngOnInit(): void {
        this.isFullUser = this.userInfoQuery.getIsFullUserSync();
        this.baseResourceTypesForFiltering$ = this.scheduleQuery.getBaseResourceTypesForFiltering();
        this.extraResourceTypesForFiltering$ = this.scheduleQuery.getExtraResourceTypesForFiltering();
        this.allResourceProperties$ = this.resourcePropertyLanguageQuery.getResourcePropertiesLanguage().pipe(
            map(resourceProperties => {
                return resourceProperties.map(rp => ({id: rp.resourcePropertyId, displayName: rp.text }));
            })
        );
        this.groupingOptions = this.scheduleHelperService.getInitialGroupingOptions();
        this.scheduleShowOptions$ = this.scheduleQuery.getShowOptions();

        this.initializeFilters();

        this.subscription.add(
            this.languageService.currentLanguage$.subscribe((lang) => {
                const standardResporceProperties = this.scheduleHelperService.getStandardResourceProperties();
                this.resourcePropertyLanguageService.getResourcePropertiesByLanguageCode(lang.codeDto, standardResporceProperties).pipe(first()).subscribe();
                this.scheduleService.updateLanguageCodeParameter(lang.codeDto);
            })
        );

        this.subscription.add(
            this.dateTimeControls.fromDate.valueChanges.pipe(
                startWith(new Date())
            ).subscribe(date => {
                if (this.dateTimeControls.fromDate.valid) {
                    const newDate = date.getTime() - date.getTimezoneOffset() * 60 * 1000;
                    this.scheduleService.updateDatesParameters(moment(newDate).toISOString());
                }
            })
        );

        this.initGroupingOptionFilter();
        this.initNumberOfDaysFilter();
        this.initOrganizationUnitsFilter();
        this.initBaseResourceTypesFilter();
        this.initExtraResourceTypesFilter();
        this.initResourcePropertiesFilter();
        this.initActivityTypesFilter();
        this.initActivityTypeCategoriesFilter();
        this.setupRemoveSelectedIfNotAvailable();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openedGroupingOptionSelect(state: boolean): void {
        if (!state) {
            this.scheduleService.updateGroupingOptionType(this.groupingOptionSelect.value);
        }
    }

    public onFilteredOrganizationsChanged(ids: Array<number>): void {
        this.scheduleService.updateOrganizationUnitIdsParameter(ids);

        this.selectedOrganizationUnitIds$.next(ids);
    }

    public onFilteredBaseResourceTypesChanged(ids: Array<number>): void {
        this.scheduleService.updateBaseResourceTypeIdsParameter(ids);
    }

    public onFilteredExtraResourceTypesChanged(ids: Array<number>): void {
        this.scheduleService.updateExtraResourceTypeIdsParameter(ids);
    }

    public onFilteredResourcePropertiesChanged(ids: Array<number>): void {
        this.scheduleService.updateResourcePropertyIdsParameter(ids);
    }

    public updateShowOptions(option, event): void {
        this.scheduleService.updateShowOption(option, event.checked);
    }

    public initializeFilters(): void {
        combineLatest([
            this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.filterSettingQuery.getEntitiesLoadingState()
        ]).pipe(
            skip(1),
            filter(([_, ouLoading, rtLoading, fLoading]) => !ouLoading && !rtLoading && !fLoading),
            take(1)
        ).subscribe(([filterSettings, _]) => {
            const parameters: ScheduleRequestParameters = this.scheduleQuery.getRequestParametersSync();

            if (filterSettings.length === 0 && !this.isFullUser) {
                const organizationUnitIds$ = this.organizationUnitQuery.getAllIds().pipe(
                    filter(ids => ids.length > 0)
                );
                const validResourceIds$ = this.resourceTypeService.getValidResourceTypeIdsForCurrentResource();

                combineLatest([organizationUnitIds$, validResourceIds$]).pipe(
                    first(),
                    tap(([unitIds, validResourceIds]) => {
                        const updatedParameters: ScheduleRequestParameters = {
                            startDate: parameters.startDate,
                            endDate: parameters.endDate,
                            languageCode: parameters.languageCode,
                            organizationUnitIds: unitIds,
                            baseResourceTypeIds: validResourceIds,
                            extraResourceTypeIds: [],
                            resourcePropertyIds: parameters.resourcePropertyIds,
                            rootActivityIds: parameters.rootActivityIds,
                        };

                        this.initFiltersBasedOnSelectedOrganization(updatedParameters);
                    })
                ).subscribe();
            }
        });

        const showOptions = this.scheduleHelperService.getInitialShowOptions();
        this.scheduleService.updateShowOptions(showOptions);
    }

    public updateSelectedActivityTypeIds(selectedIds: Array<number>): void {
        this.scheduleService.updateActivityTypeIds(selectedIds);
    }

    public updateSelectedActivityTypeCategoryIds(selectedIds: Array<number>): void {
        this.scheduleService.updateActivityTypeCategoryIds(selectedIds);
    }

    private initOrganizationUnitsFilter(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();

        this.preselectedOrganizationUnits$ = combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.scheduleQuery.getSelectedOrganizations()
        ]).pipe(
            map(([_, units]) => units.map(unit => unit.id))
        );

        this.organizationsForFiltering$ = this.organizationUnitQuery.getOrganizationsForFiltering();

        this.subscription.add(
            this.scheduleQuery.getSelectedOrganizationUnitIds()
                .pipe(filter(ids => ids?.length > 0))
                .subscribe(() => {
                    const idsToRetrieveResourceTypes = this.scheduleQuery.getOrganizationIdsToRetrieveResourceTypesSync();
                    this.resourceTypeService.getReportResourceTypes(idsToRetrieveResourceTypes).pipe(first()).subscribe();
                })
        );
    }

    private initActivityTypesFilter(): void {
        this.activityTypeService.getMainActivityTypes().pipe(first()).subscribe();

        this.activityTypes$ = combineLatest([
            this.scheduleQuery.getSelectedFilterSettingId(),
            this.activityTypeQuery.getActivityTypes()
        ]).pipe(
            map(([_, actTypes]) => actTypes)
        );

        this.selectedActivityTypeIds$ = this.scheduleQuery.getActivityTypeSelectedIds();

        const selectedActivityTypeIdsOnFilterChanged$ = this.scheduleQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.selectedActivityTypeIds$),
            filter(([_, selectedActivityTypeIds]) => selectedActivityTypeIds.length > 0),
            map(([_, selectedActivityTypeIds]) => selectedActivityTypeIds)
        );

        merge(
            selectedActivityTypeIdsOnFilterChanged$,
            this.selectedActivityTypeIds$.pipe(filter(selectedActivityTypeIds => selectedActivityTypeIds.length > 0), first())
        ).subscribe(selectedActivityTypeIds => this.scheduleService.updateActivityTypeIds(selectedActivityTypeIds));
    }

    private initActivityTypeCategoriesFilter(): void {
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();

        this.activityTypeCategories$ = this.activityTypeCategoryQuery.getActivityTypeCategories().pipe(skip(1)).pipe(
            map((categories) => {
                return categories?.map(category => ({
                    label: category.displayName,
                    value: category.id,
                }));
            })
        );
        this.selectedActivityTypeCategoryIds$ = this.scheduleQuery.getSelectedActivityTypeCategoryIds();

        const activityTypeCategoryIdsOnFilterChanged$ = this.scheduleQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.activityTypeCategories$),
            filter(([_, selectedActivityTypeCategories]) => selectedActivityTypeCategories.length > 0),
            map(([_, selectedActivityTypeCategories]) => selectedActivityTypeCategories)
        );

        merge(
            activityTypeCategoryIdsOnFilterChanged$,
            this.activityTypeCategories$.pipe(filter(activityTypeCategories => activityTypeCategories.length > 0), first())
        ).subscribe(activityTypeCategories => {
            let selectedActTypeCategoryIds = this.scheduleQuery.getSelectedActivityTypeCategoryIdsSync();
            if (!selectedActTypeCategoryIds) {
                selectedActTypeCategoryIds = activityTypeCategories.map(cat => cat.value);
            }

            const selectedActivityTypeCategories = activityTypeCategories.filter(cat => selectedActTypeCategoryIds?.includes(cat.value)).map(cat => cat.value);

            this.scheduleService.updateActivityTypeCategoryIds(selectedActivityTypeCategories);
        });
    }

    private initGroupingOptionFilter(): void {
        this.subscription.add(
            this.daypartQuery.getDayparts().subscribe(dayparts => {
                if (dayparts.length > 0) {
                    this.groupingOptions = this.scheduleHelperService.getInitialGroupingOptions(true);
                }
            })
        );

        this.subscription.add(
            this.scheduleQuery.getGroupingOptionType().subscribe(groupingOptionType => {
                this.resourceControls.groupingOption.setValue(groupingOptionType);
            })
        );
    }

    private initNumberOfDaysFilter(): void {
        this.subscription.add(
            this.scheduleQuery.getNumberOfDays().pipe(filter(days => !!days)).subscribe(days => {
                this.resourceControls.numberOfDays.setValue(days);
                this.scheduleService.updateDatesParameters(this.scheduleQuery.getRequestParametersStartDateSync());
            })
        );

        this.subscription.add(
            this.resourceControls.numberOfDays.valueChanges.subscribe((days: number) => {
                this.scheduleService.updateNumberOfDays(days);
            })
        );
    }

    private initBaseResourceTypesFilter(): void {
        this.selectedBaseResourceTypes$ = this.scheduleQuery.getSelectedBaseResourceTypes().pipe(
            filter((resourceTypes) => !!resourceTypes),
            cache()
        );

        this.forceErrorStateOnBaseResourceTypes$ = combineLatest([
            this.scheduleQuery.getSelectedFilterSettingId(),
            this.scheduleQuery.getScheduleFiltersLoadingState(),
            this.selectedOrganizationUnitIds$,
            this.selectedBaseResourceTypes$
        ]).pipe(
            map(([selectedFilterSettingId, filtersLoading, selectedUnitIds, selectedBaseResourceTypes]) => {
                return (!!selectedUnitIds || selectedFilterSettingId) && selectedBaseResourceTypes?.length === 0 && !filtersLoading;
            })
        );
    }

    private initExtraResourceTypesFilter(): void {
        this.selectedExtraResourceTypes$ = this.scheduleQuery.getSelectedExtraResourceTypes().pipe(cache());
    }

    private initResourcePropertiesFilter(): void {
        this.selectedResourcePropertiesLength$ = this.scheduleQuery.getSelectedResourceProperties().pipe(map(rp => rp ? rp.length : undefined));

        this.selectedResourceProperties$ = this.scheduleQuery.getSelectedResourceProperties().pipe(
            map(resourceProperties => {
                return resourceProperties.map(rp => ({id: rp.resourcePropertyId, displayName: rp.text }));
            })
        );
    }

    private initFiltersBasedOnSelectedOrganization(parameters: ScheduleRequestParameters): void {
        this.resourceTypeService.getReportResourceTypes(parameters.organizationUnitIds).pipe(first()).subscribe();
        this.organizationUnitService.filterVisibleOrganizationsByIds(parameters.organizationUnitIds);
        this.scheduleService.updateOrganizationUnitIdsParameter(parameters.organizationUnitIds);
        this.scheduleService.updateBaseResourceTypeIdsParameter(parameters.baseResourceTypeIds);
        this.scheduleService.updateExtraResourceTypeIdsParameter(parameters.extraResourceTypeIds);
    }

    private setupRemoveSelectedIfNotAvailable(): void {
        this.subscription.add(
            this.baseResourceTypesForFiltering$.pipe(
                withLatestFrom(this.selectedBaseResourceTypes$),
                filter(([_, selectedResourceTypes]) => selectedResourceTypes?.length > 0),
                tap(([availableResourceTypes, selectedResourceTypes]) => {
                    const availableSelectedResourceTypes = selectedResourceTypes.filter(rt => availableResourceTypes?.find(art => art.id === rt.id)).map(rt => rt.id);
                    if (availableSelectedResourceTypes.length !== selectedResourceTypes?.length) {
                        this.onFilteredBaseResourceTypesChanged(availableSelectedResourceTypes);
                    }
                })
            ).subscribe()
        );

        this.subscription.add(
            this.extraResourceTypesForFiltering$.pipe(
                withLatestFrom(this.selectedExtraResourceTypes$),
                filter(([_, selectedResourceTypes]) => selectedResourceTypes?.length > 0),
                tap(([availableResourceTypes, selectedResourceTypes]) => {
                    const availableSelectedResourceTypes = selectedResourceTypes.filter(rt => availableResourceTypes?.find(art => art.id === rt.id)).map(rt => rt.id);
                    if (availableSelectedResourceTypes.length !== selectedResourceTypes?.length) {
                        this.onFilteredExtraResourceTypesChanged(availableSelectedResourceTypes);
                    }
                })
            ).subscribe()
        );
    }
}
