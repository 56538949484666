<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-table 
    matSort 
    [dataSource]="dataSource" 
    class="activity-changes-table"
    data-cy="changesTable">
    <!-- Define the columns -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-cy]="column.header">
            {{ translateService.get(column.header)| async}}
        </mat-header-cell>
    
        <mat-cell 
            *matCellDef="let row" 
            [class.custom-column-class]="column.columnDef === specificStylingColumn"
            [bolPopoverOverflow]="popoverHover" 
            [ngStyle]="column.stylingProperty ? row[column.stylingProperty]: ''"
            [attr.data-cy]="column.cell(row)">
            <ng-template #popoverHover> {{ column.cell(row) }} </ng-template>
            {{ column.cell(row) }}
        </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of columns" [matColumnDef]="getFilterFieldPerColumn(column)">
        <mat-header-cell *matHeaderCellDef>
            <div class="filter-container">
                <div *ngIf="column.filterType === changeFilterTypes.SEARCH">
                    <mat-form-field class="filter-container-field bol-form-field">
                        <input 
                            matInput 
                            placeholder="{{ 'Search' | translate }}..."
                            (input)="applyFilter($event.target.value, column)" />
                    </mat-form-field>
                </div>
                <div *ngIf="column.filterType === changeFilterTypes.MULTISELECT" class="filter-container-field">
                    <app-multiselect-tree
                        [entities]="column.filterOptions.entities"
                        [preSelectedEntities]="column.filterOptions.selectedIds$ | async"
                        (selectedEntityIdsChanged)="onUpdateMultiselectFilter($event, column)"
                        multiselectTreePlaceholder="{{column.filterOptions.name}}"
                        [required]="false"
                        [showSelectAll]="false"
                        [translationNeeded]="true"
                        [attr.data-cy]="column.filterOptions.name">
                    </app-multiselect-tree>
                </div>
            </div>
        </mat-header-cell>
    </ng-container>

    <!-- Add the rows -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-header-row class="filter-header" *matHeaderRowDef="getFilterFieldPerColumns() sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <tr *matNoDataRow class="bol-table-empty-state">
        <td [attr.colspan]="displayedColumns.length "*ngIf="(entitiesLoadingState$ | async) === false" translate data-cy="noEntityFound">No results found.</td>
    </tr>
    <tr *matNoDataRow class="bol-table-empty-state">
        <td *ngIf="(entitiesLoadingState$ | async) === false && entities?.length === 0" translate data-cy="noDataAvailable">{{ emptyStateMessage }}</td>
    </tr>
</mat-table>

<div class="overlay-spinner" *ngIf="(entitiesLoadingState$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
