import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { OwsResourceType } from '../ows-resource-type-store/ows-resource-type.model';
import { OwsResourceTypeQuery } from '../ows-resource-type-store/ows-resource-type.query';
import { ResourceTypeMappingStore } from './resource-type-mapping.store';
import { ResourceTypeMapping } from './resource-type-mapping.model';
import { ResourceTypeMappingQuery } from './resource-type-mapping.query';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeMappingService {
    constructor(
        protected resourceTypeMappingStore: ResourceTypeMappingStore,
        protected resourceTypeMappingQuery: ResourceTypeMappingQuery,
        private readonly owsResourceTypeQuery: OwsResourceTypeQuery,
        private readonly http: HttpClient,
    ) { }

    public get(): Observable<Array<ResourceTypeMapping>> {
        return this.http.get<Array<ResourceTypeMapping>>('/api/OwsInterface/OwsResourceTypeMapping').pipe(
            map((mappings) => {
                this.resourceTypeMappingStore.updateMappings(mappings);

                return mappings;
            }));
    }

    public updateMappings(owsResourceType: OwsResourceType): void {
        let resourceTypeMappings = this.resourceTypeMappingQuery.getMappingsSync();
        const resourceTypeId = this.resourceTypeMappingQuery.getValue().ui.selectedResourceTypeId;
        const isGlobal: boolean = this.resourceTypeMappingQuery.getIsGlobalSync();
        const departmentId = isGlobal ? null : this.resourceTypeMappingQuery.getSelectedOwsDepartmentForMappingSync();
        const updatedMapping: ResourceTypeMapping = {
            resourceTypeId,
            owsDepartmentId: isGlobal ? null : departmentId,
            owsFunctionId: owsResourceType.id
        };

        if (owsResourceType.linkedOmrpResourceType !== null && isGlobal) {
            resourceTypeMappings = resourceTypeMappings.filter(mapping =>
                !(mapping.resourceTypeId === resourceTypeId && mapping.owsFunctionId === owsResourceType.id && mapping.owsDepartmentId === null)
            );
        }
        else if (owsResourceType.linkedOmrpResourceType !== null && !isGlobal) {
            resourceTypeMappings = resourceTypeMappings.filter(mapping =>
                !(mapping.resourceTypeId === resourceTypeId && mapping.owsFunctionId === owsResourceType.id && mapping.owsDepartmentId !== null)
            );
        }
        else if (!resourceTypeMappings.includes(updatedMapping)) {
            resourceTypeMappings.push(updatedMapping);
        }

        // TODO: set in server with timeout to collect calls
        this.http.post<void>('/api/OwsInterface/OwsResourceTypeMapping', resourceTypeMappings).subscribe();
        this.resourceTypeMappingStore.updateMappings(resourceTypeMappings);
    }

    public updateMappingsSelectAll(selectAll: boolean): void {
        let owsPositions: Array<OwsResourceType> = [];
        owsPositions = this.owsResourceTypeQuery.getOwsResourceTypeSync();
       
        let resourceTypeMappings = this.resourceTypeMappingQuery.getMappingsSync();
        const resourceTypeId = this.resourceTypeMappingQuery.getValue().ui.selectedResourceTypeId;
        const isGlobal: boolean = this.resourceTypeMappingQuery.getIsGlobalSync();
        const departmentId = isGlobal ? null : this.resourceTypeMappingQuery.getSelectedOwsDepartmentForMappingSync();
    
        if (selectAll) {
            const unselectedPositions = owsPositions.filter(position => {
                const hasMapping = resourceTypeMappings.some(
                    mapping => mapping.owsDepartmentId === departmentId && mapping.owsFunctionId === position.id
                );
    
                return !hasMapping;
            });
    
            if (unselectedPositions.length > 0) {
                const updatedMappings: Array<ResourceTypeMapping> = unselectedPositions.map(position => 
                    ({
                        resourceTypeId,
                        owsDepartmentId: departmentId,
                        owsFunctionId: position.id
                    })
                );
        
                resourceTypeMappings.push(...updatedMappings);
            }
            
        } else {
            resourceTypeMappings = resourceTypeMappings.filter(
              mapping => !(mapping.resourceTypeId === resourceTypeId && mapping.owsDepartmentId === departmentId)
            );
        }

        this.http.post<void>('/api/OwsInterface/OwsResourceTypeMapping', resourceTypeMappings).subscribe();
        this.resourceTypeMappingStore.updateMappings(resourceTypeMappings);
    }

    public updateSelectedResourceTypeId(id: number): void {
        this.resourceTypeMappingStore.updateSelectedResourceTypeId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.resourceTypeMappingStore.updateSelectedOrganizationUnitId(id);
    }

    public updateShowChildUnits(value: boolean): void {
        this.resourceTypeMappingStore.updateShowChildUnits(value);
    }

    public updateIsGlobal(value: boolean): void {
        this.resourceTypeMappingStore.updateIsGlobal(value);
    }

    public updateSelectedOwsDepartmentId(id: number): void {
        this.resourceTypeMappingStore.updateSelectedOwsDepartmentId(id);
    }

    public updateSelectedOwsDepartmentIdForMapping(id: number): void {
        this.resourceTypeMappingStore.updateSelectedOwsDepartmentIdForMapping(id);
    }

    public updateShowAllOwsResourceTypes(value: boolean): void {
        this.resourceTypeMappingStore.updateShowAllOwsResourceTypes(value);
    }

    public updateHideUnlinkedOwsResourceTypes(value: boolean): void {
        this.resourceTypeMappingStore.updateHideUnlinkedOwsResourceTypes(value);
    }

    public updateShowLinkedOwsResourceTypes(value: boolean): void {
        this.resourceTypeMappingStore.updateShowLinkedOwsResourceTypes(value);
    }
}
