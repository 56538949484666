import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { STATUS, StatusPageState, StatusPageStore } from './status-page.store';

@Injectable({ providedIn: 'root' })
export class StatusPageQuery<TState extends StatusPageState> extends QueryEntity<TState> {
    constructor(
        protected store: StatusPageStore<TState> 
    ) {
        super(store);
    }

    public getStatusPageState(): Observable<STATUS> {
        return this.select(state => state.status);
    }
}