/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NestedTreeService } from '@ortec/soca-web-ui';

import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { ResourcePropertyLanguage } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.model';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';

import { ScheduleQuery } from '../stores/schedule-store/schedule.query';
import { ExtendedScheduleActivity } from '../stores/schedule-store/schedule.model';
import { ScheduleActivity } from '../stores/schedule-activity-store/schedule-activity.model';
import { GROUPING_OPTIONS } from './enums';

export const EVENT_HEIGHT = 20;

export interface processActivitiesForResourceReturn {
    tallestHeight: number;
    activitiesToAddToStore: Array<ExtendedScheduleActivity>;
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleCalendarHelperService {

    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly nestedTreeService: NestedTreeService,
    ) { }

    public getNameForActivity(
        activity: ExtendedScheduleActivity, 
        showActivityName: boolean, 
        showActivityNameLeaf: boolean, 
        showActivityShortName: boolean, 
        showActivityShortNameLeaf: boolean, 
        showActivityTime: boolean, 
        showActivityTimeLeaf: boolean, 
        isRoot = false,
        hideResourceNameForLeaf = false
    ): string {
        let name = '';
        const timeZone = this.globalSettingsQuery.getTimeZoneSync();

        if (isRoot) {
            if (showActivityTime) {
                name = moment.utc(activity?.start).tz(timeZone).format('HH:mm') + '-' + moment.utc(activity?.end).tz(timeZone).format('HH:mm') + ' ';
            }

            if (!!showActivityShortName) {
                name += activity?.activityType?.shortName;
        
                if (!!showActivityName) {
                    name += ' - ';
                }
            }
        
            if (!!showActivityName) {
                name += activity?.activityType?.displayName;
            }
        }
        else {
            if (showActivityTimeLeaf) {
                name = moment.utc(activity?.start).tz(timeZone).format('HH:mm') + '-' + moment.utc(activity?.end).tz(timeZone).format('HH:mm') + ' ';
            }

            if (!!showActivityShortNameLeaf) {
                name += activity?.activityType?.shortName;
        
                if (!!showActivityNameLeaf) {
                    name += ' - ';
                }
            }
        
            if (!!showActivityNameLeaf) {
                name += activity?.activityType?.displayName;
            }

            if (activity?.resource && !hideResourceNameForLeaf) {
                if (name === '') {
                    name = activity.resource;
                } else {
                    name += ': ' + activity.resource;
                }
            }
        }

        return name;
    }

    public getNameForResourceActivity(
        activity: ScheduleActivity, 
        showActivityTime: boolean, 
        showActivityTimeLeaf: boolean, 
        isRoot = false,
        isSingleConfigured = false
    ): string {
        let name = '';
        const timeZone = this.globalSettingsQuery.getTimeZoneSync();

        if (isRoot) {
            if (showActivityTime) {
                name = moment.utc(activity?.start).tz(timeZone).format('HH:mm') + '-' + moment.utc(activity?.end).tz(timeZone).format('HH:mm');
                if (activity.resource) {
                    name += ' - ';
                }
            }

            if (isSingleConfigured && activity.resource) {
                name += activity.resource ?? '';
            }
        }
        else {
            if (showActivityTimeLeaf) {
                name = moment.utc(activity?.start).tz(timeZone).format('HH:mm') + '-' + moment.utc(activity?.end).tz(timeZone).format('HH:mm') + ' - ';
            }

            name += activity.resource ?? '';
        }

        return name;
    }

    public addExtraPropertiesToParentNodeEntity(entity: ResourceType | OrganizationUnit, extraResourceProps: Array<ResourcePropertyLanguage>): any {
        const writeableEntity = {
            name: entity.displayName,
            id: entity.id,
            rowHeight: 40
        };

        extraResourceProps?.forEach(prop => {
            writeableEntity[prop.resourcePropertyId.toString()] = ''
        });

        return writeableEntity;
    }

    public addExtraPropertiesToResource(entity: Resource, extraResourceProps: Array<ResourcePropertyLanguage>, parentId: any): any {
        const writeableEntity = {
            name: entity.displayName,
            id: parentId !== undefined ? parentId + '-' + entity.id : entity.id,
            originalId: entity.id
        };

        extraResourceProps?.forEach(prop => {
            writeableEntity[prop.resourcePropertyId.toString()] = entity.resourceProperties?.find(p => p.resourcePropertyId === prop.resourcePropertyId)?.value ?? '';
        });

        return writeableEntity;
    }

    public processActivitiesForResource(
        activities: Array<ExtendedScheduleActivity>, 
        resourceId: number | string,
        originalResourceId: number,
        showFullTree: boolean,
        extraResources: Array<number>
    ): processActivitiesForResourceReturn {
        let tallestHeight = EVENT_HEIGHT;
        const activitiesToAddToStore: Array<ExtendedScheduleActivity> = [];

        activities.forEach(activity => {
            // filter out activities that don't belong to the resource
            if (!(activity.childrenRecursedResourceIds?.includes(originalResourceId) || activity.resourceId === originalResourceId)) {
                return;
            }
            
            const { adjustedStart, adjustedEnd, id } = activity;
            const name = activity.searchString;
            
            const newEvent = {
                ...activity,
                id: `${resourceId}-${id}`,
                name,
                startDate: this.subtractTimeZoneHoursFromDateString(adjustedStart),
                endDate: this.subtractTimeZoneHoursFromDateString(adjustedEnd),
                originalId: id,
                resourceId,
                originalResourceId
            };
    
            // Remove children, since Bryntum gets confused by those and tries to add those as well (not what we want)
            delete newEvent.children;

            activitiesToAddToStore.push(newEvent);
    
            if (showFullTree) {
                const height = this.getHeightOfActivityEvent(activity, extraResources);
                if (height > tallestHeight) {
                    tallestHeight = height;
                }
            }
        });
    
        return {
            tallestHeight,
            activitiesToAddToStore
        };
    }
      
    public getSortedResourcesAndResourceProperties(a: any, b: any): number {
        const selectedGroupingOption = this.scheduleQuery.getGroupingOptionTypeSync();
    
        if (selectedGroupingOption === GROUPING_OPTIONS.RESOURCE_TYPE && a.name !== undefined && b.name !== undefined) {
            return this.sortOrderForResourceTypeGroupingOption(a,b);
        } else if(selectedGroupingOption !== GROUPING_OPTIONS.RESOURCE_TYPE && a.name !== undefined && b.name !== undefined) {
            return this.getSortedResources(a.name, b.name);
        } else {
            return this.getSortedResources(a,b);
        }
    }

    public getSortedResourcesAndResourcePropertiesForResourceOnlyUser(a: any, b: any, userResourceId: number): number {
        const selectedGroupingOption = this.scheduleQuery.getGroupingOptionTypeSync();
    
        if (selectedGroupingOption === GROUPING_OPTIONS.RESOURCE_TYPE && a.name !== undefined && b.name !== undefined) {
            return this.sortOrderForResourceTypeGroupingOption(a,b);
        } else if(selectedGroupingOption !== GROUPING_OPTIONS.RESOURCE_TYPE && a.name !== undefined && b.name !== undefined) {
            return this.sortOrderForOtherGroupingOption(a, b, userResourceId);
        } else {
            return this.getSortedResources(a,b);
        }
    }

    public getTallestEventHeight(activities: Array<ExtendedScheduleActivity>, extraResources: Array<number>): number {
        // If we don't show any extra leaves, there is no need to calculate the height
        const showFullTree = this.scheduleQuery.getExtraResourceTypeIdsSync()?.length > 0;
        if (!showFullTree) {
            return EVENT_HEIGHT;
        }

        let tallestHeight = EVENT_HEIGHT;
    
        for (const activity of activities) {
            const height = this.getHeightOfActivityEvent(activity, extraResources);
            if (height > tallestHeight) {
                tallestHeight = height;
            }
        }
    
        return tallestHeight;
    }
    
    public subtractTimeZoneHoursFromDateString(date: string): string {
        const newDate = date.split('+');
        if (newDate.length > 1) {
          // Take the part before the '+' sign
          return newDate[0];
        }

        // If there's no '+', return the original string
        return date;
    }

    public getHeightOfActivityEvent(activity: ExtendedScheduleActivity, extraResources: Array<number>): number {
        if (activity.children) {
            const allLeaves = this.nestedTreeService.flattenEntityTree(activity);

            // Start with root height
            let height = EVENT_HEIGHT; 

            // Add height for each of the leaves included in the extra resources
            const allSelectedLeaves = allLeaves.filter(leaf => extraResources.includes(leaf.resourceTypeId));
            height += allSelectedLeaves?.length * EVENT_HEIGHT;
            
            return height;
        }

        return EVENT_HEIGHT;
    }

    private sortOrderForResourceTypeGroupingOption(a: any, b: any) : number {
        const ascending = a.firstStore?.sorters.find(opt => opt.field === 'name').ascending;
        const baseResourceTypeIdsOrder = this.scheduleQuery.getBaseResourceTypeIdsSync();
        const isChildA = a.parentId !== null;
        const isChildB = b.parentId !== null;
        const indexA = baseResourceTypeIdsOrder.indexOf(a.id);
        const indexB = baseResourceTypeIdsOrder.indexOf(b.id);

        if (ascending) {
            if (!isChildA && !isChildB) {
                return indexA - indexB; 
            }

            if (!isChildA && isChildB) {
                return -1;
            }
    
            if (isChildA && !isChildB) {
                return 1;
            }
    
            return a.name.localeCompare(b.name);
    
        } else {
            if (!isChildA && !isChildB) {
                return indexB - indexA; 
            }

            if (!isChildA && isChildB) {
                return 1; 
            }
        
            if (isChildA && !isChildB) {
                return -1; 
            }
        
            return a.name.localeCompare(b.name);
        }
    }
    
    private sortOrderForOtherGroupingOption(a: any, b: any, userResourceId: number): number {
        if ((a.originalId === userResourceId && a?.parentId === null) || (b.originalId === userResourceId && b?.parentId === null)) {
            return 0;
        }

        return this.getSortedResources(a.name, b.name);
    }

    private getSortedResources(a: any, b: any): number {
        if (a === undefined) {
            return 1;
        }

        if (b === undefined) {
            return -1;
        }
    
        const aIsNumber = !isNaN(Number(a));
        const bIsNumber = !isNaN(Number(b));
    
        if (aIsNumber && bIsNumber) {
            if (Number(a) < Number(b)) {
                return -1;
            }
        
            if (Number(a) > Number(b)) {
                return 1;
            }
        }
    
        if (a.toString().toLowerCase() < b.toString().toLowerCase()) {
            return -1;
        }
    
        if (a.toString().toLowerCase() > b.toString().toLowerCase()) {
            return 1;
        }
    
        return 0;
    }
}
