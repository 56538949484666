import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { UserInfoState, UserInfoStore } from './user-info.store';

@Injectable({
    providedIn: 'root'
})
export class UserInfoQuery extends Query<UserInfoState> {
    constructor(
        protected store: UserInfoStore
    ) {
        super(store);
    }

    public getIsFullUser(): Observable<boolean> {
        return this.select(state => state.isFullUser);
    }

    public getUserId(): Observable<number> {
        return this.select(state => state.userId);
    }

    public getIsFullUserSync(): boolean {
        return this.getValue().isFullUser;
    }

    public getUserResourceId(): Observable<number> {
        return this.select(state => state.resourceId);
    }

    public getUserResourceIdSync(): number {
        return this.getValue().resourceId;
    }

    public getUserIdSync(): number {
        return this.getValue().userId;
    }

    public getDisplayName(): Observable<string> {
        return this.select(state => state.displayName);
    }

    public getUserLanguageCode(): Observable<string> {
        return this.select(state => state.languageCode);
    }

    public getUserLanguageCodeSync(): string {
        return this.getValue().languageCode;
    }
}
