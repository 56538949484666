import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';

import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';

import { CountersQuery } from '../stores/counters-store/counters.query';
import { ColumnDefinition, CounterData } from '../stores/counters-store/counters-matrix.models';
import { Counter } from '../stores/counters-store/counters.model';
import { CountersScenarioQuery } from '../stores/counters-scenario-store/counters-scenario.query';
import { COUNTERS_COUNTER_TYPE, COUNTERS_EXTRA_GROUPING_OPTIONS, COUNTERS_GROUPING_OPTIONS } from './enums';

@Injectable({
    providedIn: 'root'
})
export class CountersCalculationService {

    constructor(
        protected countersQuery: CountersQuery,
        protected countersScenarioQuery: CountersScenarioQuery,
        protected activityTypeQuery: ActivityTypeQuery,
        protected resourceQuery: ResourceQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected decimalPipe: DecimalPipe,
        protected daypartQuery: DaypartQuery,
        protected userInfoQuery: UserInfoQuery,
    ) { }

    public fillResourceTreeWithData(resourceTree: Array<CounterData>, columnDefinitions: Array<ColumnDefinition>): Array<CounterData> {
        const counters = this.countersQuery.getCountersSync();
        const scenarioCounters = this.countersScenarioQuery.getCountersScenariosSync();
        const groupingOptions = this.countersQuery.getGroupingOptionsSync();
        const counterTypes = this.countersQuery.getCounterTypeOptionsSync();
        const userLanguageCode = this.userInfoQuery.getUserLanguageCodeSync();

        this.fillTree(resourceTree, counters, scenarioCounters, columnDefinitions, counterTypes, groupingOptions, userLanguageCode);

        return resourceTree;
    }

    private fillTree(
        resourceTree: Array<CounterData>,
        counters: Array<Counter>,
        scenarioCounters: Array<Counter>,
        columnDefinitions: Array<ColumnDefinition>,
        counterTypes: Array<COUNTERS_COUNTER_TYPE>,
        groupingOptions: Array<COUNTERS_GROUPING_OPTIONS>,
        userLanguageCode: string
    ): void {
        resourceTree.forEach(branch => {
            let filteredCounters;
            let filteredScenarioCounters;

            const branchGroupingOptionIsAfterOrganiztionUnitGrouping =
                groupingOptions.includes(COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT) &&
                (branch.groupingOptionType === COUNTERS_EXTRA_GROUPING_OPTIONS.RESOURCE ||
                    groupingOptions.indexOf(COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT) < groupingOptions.indexOf(branch.groupingOptionType));

            switch (branch.groupingOptionType) {
                case COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY:
                    filteredCounters = this.filterForActivityTypeCategory(counters, branch.activityTypeCategoryId);
                    filteredScenarioCounters = this.filterForActivityTypeCategory(scenarioCounters, branch.activityTypeCategoryId);
                    break;
                case COUNTERS_GROUPING_OPTIONS.SKILL:
                    filteredCounters = this.filterForSkills(counters, branch.skillId);
                    filteredScenarioCounters = this.filterForSkills(scenarioCounters, branch.skillId);
                    break;
                case COUNTERS_GROUPING_OPTIONS.DAY_PART:
                    filteredCounters = this.filterForDayparts(counters, branch.daypartStart, branch.daypartEnd);
                    filteredScenarioCounters = this.filterForDayparts(scenarioCounters, branch.daypartStart, branch.daypartEnd);
                    break;
                case COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT:
                    filteredCounters = this.filterForOrganizationUnit(counters, branch.organizationUnitId);
                    filteredScenarioCounters = this.filterForOrganizationUnit(scenarioCounters, branch.organizationUnitId);
                    break;
                case COUNTERS_GROUPING_OPTIONS.RESOURCE_TYPE:
                    filteredCounters = this.filterForResourceType(counters, branch.resourceTypeId);
                    filteredScenarioCounters = this.filterForResourceType(scenarioCounters, branch.resourceTypeId);
                    break;
                case COUNTERS_EXTRA_GROUPING_OPTIONS.RESOURCE:
                    filteredCounters = this.filterForResource(counters, branch.resourceId);
                    filteredScenarioCounters = this.filterForResource(scenarioCounters, branch.resourceId);
                    break;
                case COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE:
                    filteredCounters = this.filterForActivityType(counters, branch.activityTypeId);
                    filteredScenarioCounters = this.filterForActivityType(scenarioCounters, branch.activityTypeId);
                    break;
                default:
                    filteredCounters = counters;
                    filteredScenarioCounters = scenarioCounters;
                    break;
            }

            if (branchGroupingOptionIsAfterOrganiztionUnitGrouping) {
                filteredCounters = this.filterForOrganizationUnit(filteredCounters, branch.organizationUnitId);
                filteredScenarioCounters = this.filterForOrganizationUnit(filteredScenarioCounters, branch.organizationUnitId);
            }

            this.fillBranchWithData(branch, filteredCounters, filteredScenarioCounters, columnDefinitions, counterTypes, userLanguageCode);

            if (branch.children && branch.children.length > 0) {
                if (branch.groupingOptionType === COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT) {
                    this.fillTree(branch.children, counters, filteredScenarioCounters, columnDefinitions, counterTypes, groupingOptions, userLanguageCode);
                }
                else {
                    this.fillTree(branch.children, filteredCounters, filteredScenarioCounters, columnDefinitions, counterTypes, groupingOptions, userLanguageCode);
                }
            }
        });
    }

    private fillBranchWithData(
        branch: CounterData,
        counters: Array<Counter>,
        scenarioCounters: Array<Counter>,
        columnDefinitions: Array<ColumnDefinition>,
        counterTypes: Array<COUNTERS_COUNTER_TYPE>,
        userLanguageCode: string
    ) {
        columnDefinitions.forEach(column => {
            let counterNumberSchedule: string;
            let counterNumberScenario: string;
            let counterHourSchedule: string;
            let counterHourScenario: string;

            const timeFrameCounters = counters.filter(counter => this.filterCounterWithTimeFrame(column, counter));

            const timeFrameScenarioCounters = scenarioCounters.filter(counter => this.filterCounterWithTimeFrame(column, counter));
            const digitsInfo = '1.0-2';

            if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE)) {
                counterNumberSchedule = this.decimalPipe.transform(timeFrameCounters.length.toString(), digitsInfo, userLanguageCode);
            }

            if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE)) {
                counterHourSchedule = this.decimalPipe.transform(this.getWorkloadHoursInCounters(timeFrameCounters), digitsInfo, userLanguageCode);
            }

            if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO)) {
                counterNumberScenario = this.decimalPipe.transform(timeFrameScenarioCounters.length.toString(), digitsInfo, userLanguageCode);
            }

            if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO)) {
                counterHourScenario = this.decimalPipe.transform(this.getWorkloadHoursInCounters(timeFrameScenarioCounters), digitsInfo, userLanguageCode);
            }

            const formattedCounter = this.getCounterFormat(counterNumberSchedule, counterNumberScenario, counterHourSchedule, counterHourScenario);
            branch.data.push(formattedCounter);
        });
    }

    private filterForActivityTypeCategory(counters: Array<Counter>, categoryId: number): Array<Counter> {
        const validActivityTypeIds = this.activityTypeQuery.getValidActivityTypeIdsForCategorySync(categoryId);

        return counters.filter(counter => validActivityTypeIds.includes(counter.rootActivityType.id));
    }

    private filterForSkills(counters: Array<Counter>, skillId: number): Array<Counter> {
        const validResourceIds = this.resourceQuery.getValidResourceIdsForSkill(skillId);

        return counters.filter(counter => validResourceIds.includes(counter.resourceId));
    }

    private filterForDayparts(counters: Array<Counter>, daypartStart: string, daypartEnd: string): Array<Counter> {
        return counters.filter(counter => {
            const counterStartTime = moment(counter.start).format('HH:mm');

            return counterStartTime >= daypartStart && counterStartTime < daypartEnd;
        });
    }

    private filterForResourceType(counters: Array<Counter>, resourceTypeId: number): Array<Counter> {
        const validResourceIds = this.resourceQuery.getValidResourceIdsForResourceType(resourceTypeId);

        return counters.filter(counter => validResourceIds.includes(counter.resourceId));
    }

    private filterForOrganizationUnit(counters: Array<Counter>, organizationUnitId: number): Array<Counter> {
        const childUnitIds = this.organizationUnitQuery.getAllChildIdsForUnitSync(organizationUnitId);
        const selectedUnitIds = this.countersQuery.getSelectedOrganizationUnitIdsSync();
        const branchIds = (childUnitIds).filter(id => selectedUnitIds.includes(id));
        branchIds.push(organizationUnitId);
        const validResourceIds = [];

        branchIds.forEach(id => {
            const validResourceIdsForBranch = this.resourceQuery.getValidResourceIdsForOrganizationUnit(id);

            validResourceIdsForBranch.forEach(resourceId => {
                if (!validResourceIds.includes(resourceId)) {
                    validResourceIds.push(resourceId);
                }
            });
        });

        return counters.filter(counter => validResourceIds.includes(counter.resourceId));
    }

    private filterForResource(counters: Array<Counter>, resourceId: number): Array<Counter> {
        return counters.filter(counter => counter.resourceId === resourceId);
    }

    private filterForActivityType(counters: Array<Counter>, activityTypeId: number): Array<Counter> {
        return counters.filter(counter => counter.rootActivityType.id === activityTypeId);
    }

    private filterCounterWithTimeFrame(column: ColumnDefinition, counter: Counter): boolean {
        const counterStart = moment(counter.start);

        // only count activities on the start day, even if it spans multiple days
        return counterStart.isSameOrAfter(column.startDay) && counterStart.isSameOrBefore(column.endDay.endOf('day'));
    }

    private getWorkloadHoursInCounters(counters: Array<Counter>): string {
        return counters.reduce((hours, timeframeCounter) => hours + timeframeCounter.workloadHours, 0).toString();
    }

    private getCounterFormat(
        counterNumberSchedule: string,
        counterNumberScenario: string,
        counterHourSchedule: string,
        counterHourScenario: string
    ): string {
        let formatted = '';
        formatted += counterNumberSchedule ?? '';

        if (counterNumberSchedule && counterNumberScenario) {
            formatted += ' - ';
        }
        formatted += counterNumberScenario ?? '';

        if ((counterNumberSchedule || counterNumberScenario) && (counterHourSchedule || counterHourScenario)) {
            formatted += ' / ';
        }
        formatted += counterHourSchedule ?? '';

        if (counterHourSchedule && counterHourScenario) {
            formatted += ' - ';
        }
        formatted += counterHourScenario ?? '';

        return formatted;
    }
}
