import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CloneEntityService {
    private readonly cloningSuffixRegexIdentifier = /\s\(\d+\)$/;

    public getClonedEntityName(entityName: string, entityNames: Array<string>): string {
        const isEntityNamePreviouslyCloned = entityName.match(this.cloningSuffixRegexIdentifier);

        if (!isEntityNamePreviouslyCloned && !entityNames.includes(`${entityName} (1)`)) {
            return `${entityName} (1)`;
        }

        const splittedEntityName = entityName.split(this.cloningSuffixRegexIdentifier);
        let clonedEntityName: string;
        let i = 1;

        while (!clonedEntityName) {
            if (!entityNames.includes(`${splittedEntityName[0]} (${i})`)) {
                clonedEntityName = `${splittedEntityName[0]} (${i})`;
            }

            i++;
        }

        return clonedEntityName;
    }
}
