import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LanguageService } from 'src/app/shared/language';

import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';

import { OwsDuty, OwsDutyTableElement, OwsDutySet, OwsDutySetForFiltering } from '../ows-duty-set-store/ows-duty-set.model';
import { OwsDutySetQuery } from '../ows-duty-set-store/ows-duty-set.query';
import { ActivityTypeMappingState, ActivityTypeMappingStore } from './activity-type-mapping.store';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeMappingQuery extends QueryEntity<ActivityTypeMappingState> {
    constructor(
        protected store: ActivityTypeMappingStore,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly owsDutySetQuery: OwsDutySetQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected languageService: LanguageService
    ) {
        super(store);
    }

    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.ui.showUnderlyingUnits);
    }

    public getShowPastActivityTypesState(): Observable<boolean> {
        return this.select(state => state.ui.showPastActivityTypes);
    }

    public getShowFutureActivityTypesState(): Observable<boolean> {
        return this.select(state => state.ui.showFutureActivityTypes);
    }

    public getShowPastDutiesState(): Observable<boolean> {
        return this.select(state => state.ui.showPastDuties);
    }

    public getShowFutureDutiesState(): Observable<boolean> {
        return this.select(state => state.ui.showFutureDuties);
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.ui.selectedOrganizationUnitId);
    }

    public getSelectedActivityTypeId(): Observable<number> {
        return this.select(state => state.ui.selectedActivityTypeId);
    }

    public getUnlinkedOwsDutyVisibility(): Observable<boolean> {
        return this.select(state => state.ui.hideUnlinkedOwsDuties);
    }

    public getSelectedOwsDutySet(): Observable<string> {
        return this.select(state => state.ui.selectedDutySet);
    }

    public getSelectedOwsDutySetFiltredByDepartmentId(): Observable<string> {
        return this.select(state => state.ui.selectedDutySet);
    }

    public getSelectedActivityTypeCategory(): Observable<number> {
        return this.select(state => state.ui.selectedActivityTypeCategory);
    }

    public getSelectedDepartmentId(): Observable<number> {
        return this.select(state => state.ui.selectedDepartmentId);
    }

    public getAllMappedOwsDutiesForSelectedDutySet(): Observable<Array<OwsDuty>> {
        return combineLatest([
            this.selectAll(),
            this.getSelectedOwsDutySet(),
            this.activityTypeQuery.selectAll(),
            this.owsDutySetQuery.selectAll()
        ]).pipe(
            filter(([_, selectedDutySetId, __, ___]) => selectedDutySetId !== undefined),
            map(([mappings, selectedDutySetId, activityTypes, allDutySets]) => {
                const selectedDutySet = allDutySets.find(set => set.id === selectedDutySetId);

                return selectedDutySet.duties.map(duty => ({
                    ...duty,
                    linkedOmrpActivityType: mappings.find(x => x.owsDutyId === duty.id) ?
                        activityTypes.find(s => s.id === mappings.find(x => x.owsDutyId === duty.id).activityTypeId) : null
                })).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            })
        );
    }

    public getFilteredOwsDuties(): Observable<Array<OwsDutyTableElement>> {
        return combineLatest([
            combineLatest([
                this.getSelectedOwsDutySet(),
                this.owsDutySetQuery.selectAll(),
                this.getSelectedDepartmentId()
            ]),
            combineLatest([
                this.getAllMappedOwsDutiesForSelectedDutySet(),
                this.getUnlinkedOwsDutyVisibility(),
                this.getSelectedActivityTypeId(),
                this.getShowPastDutiesState(),
                this.getShowFutureDutiesState()
            ])
        ]).pipe(
            map(([[selectedDutySetId, allDutySets, selectedDepartmentId], [owsDuties, hideLinked, selectedActivityTypeId, showPastDuties, showFutureDuties]]) => {
                if (selectedDutySetId === undefined) {
                    return [];
                }
                let filteredDutySets = [];
                
                if (selectedDepartmentId !== undefined) {
                    filteredDutySets = allDutySets.filter(ds => ds.departmentId === selectedDepartmentId);
                } else {
                    filteredDutySets =  allDutySets;
                }

                const selectedDutySet = filteredDutySets.find(set => selectedDutySetId !== undefined && set.id === selectedDutySetId);
                const validUnlinkedDuties = owsDuties.filter(duty => duty.linkedOmrpActivityType === null && this.isDutyValid(duty, showPastDuties, showFutureDuties, selectedDutySet));
                const linkedDuties = owsDuties.filter(duty => duty.linkedOmrpActivityType !== null)
                const linkedDutiesWithSelectedActivityTypeId = linkedDuties.filter(duty => duty.linkedOmrpActivityType?.id === selectedActivityTypeId)

                const visibleDuties = hideLinked ? linkedDutiesWithSelectedActivityTypeId.concat(validUnlinkedDuties) :  linkedDuties.concat(validUnlinkedDuties);

                return visibleDuties.map(duty => {
                    const isLinked = duty.linkedOmrpActivityType !== null;
                    let displayName = duty.name;
                    const dateFormat = this.languageService.getDateFormatMomentByCurrentLanguage();

                    if (isLinked && duty.linkedOmrpActivityType?.id !== selectedActivityTypeId) {
                        displayName += ' - ' + duty.linkedOmrpActivityType?.displayName;
                    };

                    const validFrom = this.formatNullableDate(duty.validFrom, dateFormat);
                    const validUntil = this.formatNullableDate(duty.validUntil, dateFormat);
                    displayName += '     |     ' + validFrom + " - " + validUntil;

                    return {
                        ...duty, 
                        checked: isLinked,
                        disabled: selectedActivityTypeId === undefined || (isLinked && duty.linkedOmrpActivityType?.id !== selectedActivityTypeId),
                        displayName
                    };  
                });
            })
        );
    }

    public getFilteredOwsDutySets(): Observable<Array<OwsDutySetForFiltering>> {
        return combineLatest([
            this.owsDutySetQuery.getDutySetsForFiltering(),
            this.getShowPastDutiesState(),
            this.getShowFutureDutiesState(),
            this.getSelectedDepartmentId()
        ]).pipe(map(([owsDutySets,  showPastDutySets, showFutureDutySets, selectedDepartmentId]) => {
            let visibleOWSDutySets: OwsDutySetForFiltering[] = [];
            if (selectedDepartmentId !== undefined) {
                visibleOWSDutySets = owsDutySets.filter(ds => ds.departmentId === selectedDepartmentId);
            } else {
                visibleOWSDutySets = owsDutySets;
            }
            
            const dutySetsWithoudValidityPeriod = visibleOWSDutySets.filter(ds => ds.validFrom === null && ds.validUntil === null).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            const validDutySets = visibleOWSDutySets.filter(dutySet => 
                dutySet.validFrom !== null && dutySet.validUntil !== null
                &&
                this.owsDutySetQuery.isDutySetValid(dutySet, showPastDutySets, showFutureDutySets));
         
            const visibleDutySets = dutySetsWithoudValidityPeriod.concat(validDutySets);
            
            return visibleDutySets.map(dutySet => {
                const  displayName = dutySet.displayName;

                return {
                    ...dutySet, 
                    displayName
                };

            }).sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
        }));
    }

    public getFilteredActivityTypesWithFullName(): Observable<Array<ActivityType>> {
        return combineLatest([
            this.activityTypeQuery.getSingleConfiguredActivityTypes(),
            this.getSelectedOrganizationUnit(),
            this.getSelectedActivityTypeCategory(),
            this.getShowUnderlyingUnitsState(),
            this.getShowPastActivityTypesState(),
            this.getShowFutureActivityTypesState()
        ]).pipe(
            map(([activityTypes, filteredOrganizationIds, filteredCategoryId, showUnderlyingUnits, showPastActivityTypes, showFutureActivityTypes]) => {
                
                if (!showUnderlyingUnits) {
                    return activityTypes.filter(activityType => activityType.ownerOrganizationUnitId === filteredOrganizationIds && activityType.categoryId === filteredCategoryId && this.activityTypeQuery.isActivityTypeValid(activityType, showPastActivityTypes, showFutureActivityTypes));
                }
                else {
                    const childUnitIds = this.organizationUnitQuery.getAllChildUnitsForUnitSync(filteredOrganizationIds).map(unit => unit.id);
                    childUnitIds.push(filteredOrganizationIds);

                    return activityTypes.filter(activityType => childUnitIds.includes(activityType.ownerOrganizationUnitId) && activityType.categoryId === filteredCategoryId && this.activityTypeQuery.isActivityTypeValid(activityType, showPastActivityTypes, showFutureActivityTypes));
                }
            }),
            map((actTypes) => {
                const mutableActTypes = JSON.parse(JSON.stringify(actTypes));

                const actTypesWithFullName = mutableActTypes.map(actType => ({ ...actType, displayName: actType.shortName ? actType.displayName += ` (${actType.shortName})` : actType.displayName }));
                const sortedActTypes = actTypesWithFullName.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

                return sortedActTypes;
            })
        );
    }

    public getUnlinkedDutiesCount(): Observable<number> {
        return combineLatest([
            this.getSelectedOwsDutySet(),
            this.owsDutySetQuery.selectAll(),
            this.getAllMappedOwsDutiesForSelectedDutySet(),
            this.getShowPastActivityTypesState(),
            this.getShowFutureActivityTypesState()
    ]).pipe(
            map(([selectedDutySetId, allDutySets, duties, showPastDuties, showFutureDuties]) => {
                if (selectedDutySetId === undefined) {
                    return 0;
                }
                const selectedDutySet = allDutySets.find(set => selectedDutySetId !== undefined && set.id === selectedDutySetId);
                const unlinkedValidDuties =  duties.filter(duty => duty.linkedOmrpActivityType === null 
                    && this.isDutyValid(duty, showPastDuties, showFutureDuties, selectedDutySet));
                
                return unlinkedValidDuties.length;
            })
        );
    }

    public isDutyValid(duty : OwsDuty, showPastDuties: boolean, showFutureDuties: boolean, selectedDutySet : OwsDutySet): boolean {
        const validOnlyInThePast = showPastDuties && moment(duty.validUntil).isSameOrBefore(moment());
        const validOnlyInTheFuture = showFutureDuties && moment(duty.validFrom).isSameOrAfter(moment());
        const validInPresent = (duty.validFrom === null && moment(duty.validUntil).isSameOrAfter(moment()))
            ||  (moment(duty.validFrom).isSameOrBefore(moment()) && duty.validUntil === null)
            ||  (moment(duty.validFrom).isSameOrBefore(moment()) && moment(duty.validUntil).isSameOrAfter(moment()));

        return this.isDutyOverlappingDutySetInterval(duty, selectedDutySet) 
            && (validOnlyInThePast || validOnlyInTheFuture || validInPresent);
    }

    public isDutyOverlappingDutySetInterval(duty : OwsDuty, selectedDutySet : OwsDutySet): boolean {
        if (selectedDutySet.validFrom === null && selectedDutySet.validUntil === null ) {
            return true;
        }
        
        // startDuty <= endDutySet && endDuty <= startDutySet
        return  moment(duty.validFrom).isSameOrBefore(moment(selectedDutySet.validUntil)) 
            && moment(duty.validUntil).isSameOrAfter(moment(selectedDutySet.validFrom));
    }

    private formatNullableDate(date: string | null, dateFormat: string): string {
        return date !== null ? moment.utc(date).format(dateFormat) : '';
    }
}
