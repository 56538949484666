import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';

import { SCHEDULE_STORAGE_KEYS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleFilterSettings } from '../../stores/schedule-store/schedule.model';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';

@Component({
    selector: 'app-schedule-filters-settings-old',
    templateUrl: './schedule-filters-settings.component.html',
    styleUrls: ['./schedule-filters-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleFiltersSettingsOldComponent implements OnInit, OnDestroy {
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public filterChangesInPending$: Observable<boolean> = of(true);
    public scheduleFiltersValidity$!: Observable<boolean>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly scheduleService: ScheduleService,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
    ) { }

    public ngOnInit(): void {
        this.filterSettingService.getFilterSettings().pipe(first()).subscribe();

        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING);
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();
        this.scheduleFiltersValidity$ = this.scheduleQuery.getScheduleFiltersValidity();

        this.filterChangesInPending$ = combineLatest([
            this.scheduleQuery.getScheduleFiltersValidity(),
            this.scheduleQuery.getUIState(),
            this.filterSettingQuery.filterSettingsUpdated()
        ]).pipe(map(([valid, _]) => valid && this.scheduleQuery.currentFilterSettingChangesUnsavedSync()));

        this.subscription.add(
            this.filterSettingQuery.getFilterSettingBySelectedId().pipe(
                tap(filterSetting => {
                    if (!filterSetting) {
                        this.scheduleService.updateSelectedFilterSettingId(undefined);
                    } else {
                        const settings: ScheduleFilterSettings = JSON.parse(filterSetting.settings);

                        const scheduleStartDate = this.scheduleQuery.getScheduleStartDateNavigationSync();
                        const scheduleEndDate = this.scheduleQuery.getScheduleEndDateNavigationSync();
                        const noOfDaysToNavigate = this.scheduleQuery.getScheduleNoOfDaysToNavigateSync();
                        const loadingState = this.scheduleQuery.getScheduleLoadingStateSync();
                        const loadingStateActivityDetailsDialog= this.scheduleQuery.getScheduleLoadingStateActivityDetailsDialogSync();
                        const resourcesDialog = this.scheduleQuery.getResourcesDialogSync();
                        const selectedActivityId = this.scheduleQuery.getSelectedActivityIdSync();

                        this.scheduleService.updateScheduleStateOnFilterSettingChanged({
                            ui: {
                                selectedFilterSettingId: filterSetting.id,
                                requestParameters: settings.requestParameters,
                                showOptions: settings.showOptions,
                                activityTypeIds: settings.activityTypeIds,
                                activityTypeCategoryIds: settings.activityTypeCategoryIds,
                                groupingOptionType: settings.groupingOptionType,
                                numberOfDays: settings.numberOfDays,
                                scheduleDateNavigation: { noOfDaysToNavigate, scheduleStartDate, scheduleEndDate },
                                loadingState,
                                resourcesDialog,
                                selectedActivityId,
                                loadingStateActivityDetailsDialog,
                                savedBaseResourceTypes: settings.savedBaseResourceTypes,
                                savedExtraResourceTypes: settings.savedExtraResourceTypes,
                                savedOrganizationUnits: settings.savedOrganizationUnits,
                                savedResourceProperties: settings.savedResourceProperties
                            },
                            calendarOptions: {
                                slotMinWidth: settings.slotMinWidth,
                                activityDialogState: settings.activityDialogState,
                                resourceAreaWidth: settings.resourceAreaWidth
                            }
                        });
                    }
                })
            ).subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSelectedFilterSettingIdChanged(id: number): void {
        this.filterSettingService.updateSelectedFilterSettingsId(id);
        this.scheduleService.updateSelectedFilterSettingId(id);

        if (id) {
            this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTER_SETTING_ID, id);
        }
    }

    public onSaveNewFilterSettingEvent(settingName: string): void {
        const newFilterSetting = this.scheduleQuery.getNewScheduleFilterSetting(settingName);

        this.filterSettingService.createFilterSetting(newFilterSetting).pipe(first()).subscribe(setting => {
            this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTER_SETTING_ID, setting.id);
        });
    }

    public onRemoveFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.deleteFilterSetting(setting).pipe(first()).subscribe();
    }

    public onUpdateFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.updateFilterSetting(setting).pipe(first()).subscribe();
    }

    public onOverwriteSelectedFilterSettingEvent(): void {
        const updatedFilterSetting = this.scheduleQuery.getUpdatedScheduleFilterSetting();

        this.filterSettingService.updateFilterSetting(updatedFilterSetting).pipe(first()).subscribe();
    }
}
