/* eslint-disable radix */
import { Injectable } from '@angular/core';
import { ManageCounter } from 'src/app/shared/stores/manage-counter-store/manage-counter.model';

@Injectable({
    providedIn: 'root'
})
export class ManageCountersHelperService {
    public getNewManageCounterObject(): ManageCounter {
        return {
            id: undefined,
            displayName: '',
            skillIds: undefined,
            daypartId: undefined,
            resourceTypeId: undefined,
            usedInActivityDemandTemplate: undefined,
            usedInActivityPlan: undefined
        };
    }
}
