import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';

export interface OwsWorkTreatmentState {
    workTreatmentTexts: Array<string>;
    workActivityTypes: Array<number>;
}

const initialState = {
    workTreatmentTexts: [],
    workActivityTypes: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-work-treatment' })
export class OwsWorkTreatmentStore extends Store<OwsWorkTreatmentState> {

    constructor() {
        super(initialState);
    }

    public updateWorkTreatment(workTreatmentTexts: Array<string>) {
        this.update({workTreatmentTexts});
    }

    public updateWorkActivityType(workActivityTypes: Array<number>) {
        this.update({workActivityTypes});
    }
}
