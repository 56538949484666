<div class="event-container">
    <ng-container *ngFor="let act of allLeaves">
        <div class="event-element">
            <div *ngIf="(act.parentId === null && showActivityShortName) || (act.parentId !== null && showActivityShortNameLeaf)"
                class="activity-code-container"
                [class.root]="act.parentId === null"
                [style.background]="'#' + act?.backColor"
                [style.color]="'#' + act?.textColor">
                {{ act.shortName }}
            </div>
            <div *ngIf="(act.parentId === null && showActivityName) || (act.parentId !== null && showActivityNameLeaf)"
                class="activity-displayname"
                [class.root]="act.parentId === null">
                {{ act.displayName }}
            </div>
        </div>
    </ng-container>
</div>