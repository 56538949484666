import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { ActivityType } from '../../stores/activity-type-store/activity-type.model';
import {
    ActivityTypeDialogOldComponent,
    IActivityTypeModalData,
} from './components/activity-type-dialog/activity-type-dialog.component';
import { BolSelectItem } from '@ortec/bolster/select';

@Component({
    selector: 'app-activity-type-selection-old',
    templateUrl: './activity-type-selection.component.html',
    styleUrls: ['./activity-type-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeSelectionOldComponent implements OnDestroy, OnInit {
    @Input() private set activityTypes(actTypes: Array<ActivityType>) {
        this.activityTypesSubject.next(actTypes);
    };
    @Input() private set activityTypeCategories(actTypeCategories: Array<BolSelectItem<any>>) {
        this.activityTypeCategoriesSubject.next(actTypeCategories);
    };
    @Input() public selectedActivityTypeIds: Array<number>;
    @Input() public selectedActivityTypeCategoryIds: Array<number>;
    @Input() public required: boolean;

    @Output() public readonly updateSelectedActivityTypeIdsEvent = new EventEmitter<Array<number>>();
    @Output() public readonly updateSelectedActivityTypeCategoryIdsEvent = new EventEmitter<Array<number>>();

    public activityTypes$: Observable<Array<ActivityType>>;
    private readonly activityTypesSubject = new BehaviorSubject<Array<ActivityType>>(undefined);

    private activityTypeCategories$: Observable<Array<BolSelectItem<any>>>;
    private readonly activityTypeCategoriesSubject = new BehaviorSubject<Array<BolSelectItem<any>>>(undefined);

    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: MatDialog
    ) { }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.activityTypes$ = this.activityTypesSubject.asObservable();
        this.activityTypeCategories$ = this.activityTypeCategoriesSubject.asObservable();
    }

    public onDisplaySelectionDialog(): void {
        const dialogRef = this.dialogService.open(ActivityTypeDialogOldComponent, {
            data: {
                activityTypes$: this.activityTypes$,
                activityTypeCategories$: this.activityTypeCategories$,
                selectedActivityTypeIds: this.selectedActivityTypeIds,
                selectedActivityTypeCategoryIds: this.selectedActivityTypeCategoryIds
            } as IActivityTypeModalData
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IActivityTypeModalData }) => {
                    if (dialogData) {
                        this.updateSelectedActivityTypeIdsEvent.next(dialogData.data.selectedActivityTypeIds);
                        this.updateSelectedActivityTypeCategoryIdsEvent.next(dialogData.data.selectedActivityTypeCategoryIds);
                    }
                })
            ).subscribe()
        );
    }
}
