<mat-card  class="bol-card full-height sorting-table" [style.display]="((tableRendered$ | async) === false) ? 'none' : ''">
    <mat-card-content>
        <div class="sorting-buttons">
            <button 
                mat-button 
                class="bol-button-text icon-only"
                (click)="onMoveToTop(); $event.stopPropagation()" 
                data-cy="moveToTopButton"
                [disabled]="selectedEntityIds.length === 0">
                <mat-icon>arrow_drop_up</mat-icon>
            </button>
            <button 
                mat-button 
                class="bol-button-text icon-only"
                (click)="onMoveUp(); $event.stopPropagation()" 
                data-cy="moveUpButton"
                [disabled]="selectedEntityIds.length === 0">
                <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
            <button 
                mat-button 
                class="bol-button-text icon-only"
                (click)="onMoveDown(); $event.stopPropagation()" 
                data-cy="moveDownButton"
                [disabled]="selectedEntityIds.length === 0">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <button 
                mat-button 
                class="bol-button-text icon-only"
                (click)="onMoveToBottom(); $event.stopPropagation()" 
                data-cy="moveToBottomButton"
                [disabled]="selectedEntityIds.length === 0">
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <button 
                mat-button 
                class="bol-button-text icon-only"
                (click)="onSortAlphabetically(); $event.stopPropagation()" 
                data-cy="sortAlphabeticallyButton"
                [disabled]="selectedEntityIds.length === 0">
                <mat-icon>sort_by_alpha</mat-icon>
            </button>
        </div>
        <section class="sorting-table-container">
            <mat-table 
            [dataSource]="dataSource" 
            data-cy="multiselectSortingTable"
            class="multiselect-sorting-table bol-table bol-table-bordered-rows" 
            (contentChanged)="hideLoader()"
            [ngClass]="{'striped-table': stripedTable, 'narrow-rows': narrowRows}">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="checkbox-column">
                            <mat-checkbox 
                                class="bol-checkbox"
                                (change)="$event ? onSelectAll($event) : null" 
                                [checked]="isAllSelected$ | async">
                            </mat-checkbox>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="checkbox-column">
                            <mat-checkbox 
                                *ngIf="!row.hideCheckbox"
                                class="bol-checkbox"
                                (click)="$event.stopPropagation()"
                                (change)="onCheckboxClick($event, row)"
                                [disabled]="row.disabled"
                                [checked]="row.checked">
                            </mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Dynamic Columns -->
                <ng-container *ngFor="let column of columnDefinition" [matColumnDef]="column.entityProperty">
                    <mat-header-cell *matHeaderCellDef [attr.data-cy]="column.header">
                        <div>
                            {{ column.columnDisplayName | translate }}
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="table-cell"
                            [ngStyle]="column.stylingProperty ? row[column.stylingProperty]: ''">
                            {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                            <div class="info-icon-sign" *ngIf="column.showInfoIcon && row.showInfoIcon" [bolPopoverHover]="popoverMessageHover" data-cy="sortTableInfoIcon">
                                <mat-icon>info</mat-icon>
                            </div>
                        </div>
                        <ng-template #popoverMessageHover>
                            <div class="popover-message" data-cy="popoverMessage" translate>{{ popoverMessage }}</div>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <!-- Header Row -->
                <mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></mat-header-row>

                <!-- Data Row -->
                <mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0"></mat-row>
        
                <tr *matNoDataRow class="bol-table-empty-state">
                    <td *ngIf="dataSource.data.length === 0" data-cy="noEntityFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase } }}</td>
                </tr>

                <tr *matNoDataRow class="bol-table-empty-state">
                    <td *ngIf="!loadDataComplete" translate data-cy="noDataAvailable">No data available.</td>
                </tr>
            </mat-table>
        </section>
    </mat-card-content>
</mat-card>
<div class="overlay-spinner" *ngIf="(tableRendered$ | async) === false">
    <mat-spinner diameter="100"></mat-spinner>
</div>