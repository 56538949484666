import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';

import { OwsSkill } from '../ows-skill-store/ows-skill.model';
import { SkillMappingStore } from './skill-mapping.store';
import { SkillMapping } from './skill-mapping.model';
import { SkillMappingQuery } from './skill-mapping.query';

@Injectable({
    providedIn: 'root'
})
export class SkillMappingService {
    constructor(
        protected skillMappingStore: SkillMappingStore,
        protected skillMappingQuery: SkillMappingQuery,
        protected skillQuery: SkillQuery,
        private readonly http: HttpClient,
    ) { }

    public get() {
        return this.http.get<Array<SkillMapping>>('/api/OwsInterface/OwsSkillMapping').pipe(
            map((mappings) => {
                this.skillMappingStore.set(mappings);
            }));
    }

    public updateMappings(owsSkill: OwsSkill) {
        let skillMappings = this.skillMappingQuery.getAll();
        const skillId = this.skillMappingQuery.getValue().ui.selectedSkillId;
        
        const updatedMapping: SkillMapping = {
            skillId,
            owsSkillId: owsSkill.id
        };

        if (owsSkill.linkedOmrpSkill !== null) {
            skillMappings = skillMappings.filter(mapping =>
                !(mapping.skillId === skillId && mapping.owsSkillId === owsSkill.id)
            );
        }
        else if (!skillMappings.includes(updatedMapping)) {
            skillMappings.push(updatedMapping);
        }

        // TODO: set in server with timeout to collect calls
        this.http.post<void>('/api/OwsInterface/OwsSkillMapping', skillMappings).subscribe();
        this.skillMappingStore.set(skillMappings);
    }

    public updateSelectedSkillId(id: number): void {
        this.skillMappingStore.updateSelectedSkillId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.skillMappingStore.updateSelectedOrganizationUnitId(id);
    }

    public updateShowChildUnits(value: boolean): void {
        this.skillMappingStore.updateShowChildUnits(value);
    }

    public updateSelectedOwsDepartmentId(id: number): void {
        this.skillMappingStore.updateSelectedOwsDepartmentId(id);
    }

    public updateShowAllOwsSkills(value: boolean): void {
        this.skillMappingStore.updateShowAllOwsSkills(value);
    }

    public updateHideUnlinkedOwsSkills(value: boolean): void {
        this.skillMappingStore.updateHideUnlinkedOwsSkills(value);
    }

    public updateShowLinkedOwsSkills(value: boolean): void {
        this.skillMappingStore.updateShowLinkedOwsSkills(value);
    }
}
