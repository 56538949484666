import { Injectable } from '@angular/core';
import { ManageTemplatesManagementStore } from './manage-templates-management.store';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplatesManagementService {
    constructor(
        protected countersManagementStore: ManageTemplatesManagementStore
    ) { }

    public updateInitialLoadingStateForGrid(state: boolean): void {
        this.countersManagementStore.updateInitialLoadingStateForGrid(state);
    }
}
