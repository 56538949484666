import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { OwsResourceTypeStore, OwsResourceTypeState } from './ows-resource-type.store';
import { OwsResourceType } from './ows-resource-type.model';

@Injectable({
    providedIn: 'root'
})
export class OwsResourceTypeQuery extends QueryEntity<OwsResourceTypeState> {

    constructor(
        protected store: OwsResourceTypeStore,
    ) {
        super(store);
    }

    public getOwsResourceTypeSync(): Array<OwsResourceType> {
        return Object.values(this.getValue().entities);
    }
}
