import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { ManageCounterQuery } from 'src/app/shared/stores/manage-counter-store/manage-counter.query';
import { CountersManagementState, ManageCountersManagementStore } from './manage-counters-management.store';

@Injectable({
    providedIn: 'root'
})
export class ManageCountersManagementQuery extends Query<CountersManagementState> {
    constructor(
        protected store: ManageCountersManagementStore,
        protected manageCounterQuery: ManageCounterQuery,
        protected globalSettingsQuery: GlobalSettingsQuery,
    ) {
        super(store);
    }

    public getInitialLoadingStateForGrid(): Observable<boolean> {
        return this.select(value => value.initialLoadingStateForGrid);
    }
}
