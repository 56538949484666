import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, first, mergeMap, tap } from 'rxjs/operators';

import { CloneEntityService } from '../../services/clone-entity.service';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { getDefaultUIState } from '../entity-ui-models';
import { UserInfoQuery } from '../user-info-store/user-info.query';
import { ResourceType, ResourceTypeResponse } from './resource-type.model';
import { ResourceTypeStore } from './resource-type.store';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeService {
    constructor(
        protected resourceTypeStore: ResourceTypeStore,
        protected userInfoQuery: UserInfoQuery,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
        private readonly cloneEntityService: CloneEntityService,
        private readonly translateService: TranslateService,

    ) { }

    public getResourceTypes(): Observable<Array<ResourceType>> {
        return this.http.get<Array<ResourceType>>('/api/ResourceTypes').pipe(
            tap((resourceTypes) => {
                this.resourceTypeStore.set(resourceTypes);
            })
        );
    }

    public getResourceTypesNewApi(): Observable<ResourceTypeResponse> {
        return this.http.get<ResourceTypeResponse>('/api/v1/ResourceTypes').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response: ResourceTypeResponse) => {
                this.resourceTypeStore.set(response.resourceTypes);
            })
        );
    }

    public getReportResourceTypes(organizationUnitIds: Array<number>): Observable<Array<ResourceType>> {
        this.resourceTypeStore.updateEntitiesLoadingState(true);

        return this.http.post<Array<ResourceType>>('/api/ResourceTypes/GetReportResourceTypes', organizationUnitIds).pipe(
            tap((resourceTypes) => {
                this.resourceTypeStore.set(resourceTypes);
            }),
            tap(() => this.resourceTypeStore.updateEntitiesLoadingState(false))
        );
    }

    public getValidResourceTypeIdsForResource(resourceId: number): Observable<Array<number>> {
        return this.http.get<Array<number>>(`/api/Resources/${resourceId}/ValidResourceTypes`);
    }

    public getValidResourceTypeIdsForCurrentResource(): Observable<Array<number>> {
        return this.userInfoQuery.getUserResourceId()
            .pipe(
                filter(id => !!id),
                first(),
                mergeMap(resourceId => {
                    return this.getValidResourceTypeIdsForResource(resourceId);
                })
            );
    }

    public setResourceTypeToCleanUIState(resourceTypeId: number): void {
        if (resourceTypeId !== undefined && resourceTypeId !== -1) {
            this.resourceTypeStore.ui.update(resourceTypeId, getDefaultUIState());
        }
    }

    public saveResourceType(resourceType: ResourceType, lastCorrectVersionOfResourceType: ResourceType): Observable<ResourceType> {
        if (resourceType.id !== -1) {
            this.resourceTypeStore.ui.update(resourceType.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<ResourceType>('/api/ResourceTypes', resourceType).pipe(
            catchError((error) => {
                if (resourceType.id !== -1) {
                    this.resourceTypeStore.ui.update(resourceType.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING',
                    { 
                        entityName: this.translateService.instant('resource type'),
                        entityDisplayName: resourceType.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(lastCorrectVersionOfResourceType);
            }),
            tap((updatedResourceType: ResourceType) => {
                // Note: an object is returned from the "put" request only for organization units saved with id -1 
                // for updating organization units we will use the organization unit object that we stored client-side
                if (resourceType.id !== -1) {
                    this.resourceTypeStore.upsert(resourceType.id, resourceType); // TODO: Check if we really need this?
                    this.resourceTypeStore.ui.update(resourceType.id, { isLoading: false, isFinishedSaving: true });
                } else {
                    this.resourceTypeStore.upsert(updatedResourceType.id, resourceType);
                }

                return resourceType;
            }),
        );
    }

    public cloneResourceType(resourceType: ResourceType, resourceTypeNames: Array<string>): Observable<ResourceType> {
        const clonedResourceTypeName = this.cloneEntityService.getClonedEntityName(resourceType.displayName, resourceTypeNames);

        return this.http.post<ResourceType>('/api/ResourceTypes/Clone', {
            id: resourceType.id,
            displayName: clonedResourceTypeName
        }).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.CLONING',
                    {
                        entityName: this.translateService.instant('resource type'),
                        entityDisplayName: clonedResourceTypeName
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => resourceType);
            }),
            tap((clonedResourceType: ResourceType) => {
                this.resourceTypeStore.upsert(clonedResourceType.id, clonedResourceType);

                return clonedResourceType;
            }),
        );
    }


    public deleteResourceType(resourceTypeId: number): Observable<void> {
        this.resourceTypeStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/ResourceTypes/' + resourceTypeId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('resource type') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.resourceTypeStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.resourceTypeStore.remove(resourceTypeId);
                }
            }),
        ); 
    }

    public setResourceTypeToPendingChanges(resourceTypeId: number): void {
        this.resourceTypeStore.ui.update(resourceTypeId, { hasPendingChanges: true, isFinishedSaving: false });
    }
}
