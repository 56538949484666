import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SortingPaletteState, SortingPaletteStore } from './sorting-palette.store';
import { SortableActivityType } from './sorting-palette.model';
import { StatusPageQuery } from 'src/app/shared/stores/status-page-store/status-page.query';

@Injectable({
    providedIn: 'root'
})
export class SortingPaletteQuery extends StatusPageQuery<SortingPaletteState> {
    constructor(
        protected store: SortingPaletteStore,
    ) {
        super(store);
    }

    public getSelectedActivityTypeIds(): Observable<Array<SortableActivityType>> {
        return this.select(value => value.ui.selectedActivityTypeIds);
    }

    public getSelectedActivityTypeIdsSync(): Array<SortableActivityType> {
        return this.getValue().ui.selectedActivityTypeIds;
    }

    public getSelectedActivityTypeCategoryId(): Observable<number> {
        return this.select(value => value.ui.selectedActivityTypeCategoryId);
    }

    public getSelectedActivityTypeCategoryIdSync(): number {
        return this.getValue().ui.selectedActivityTypeCategoryId;
    }

    public getEntitiesLoading(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoading);
    }

    public getApplySortState(): Observable<boolean> {
        return this.select(value => value.ui.applySortState);
    }
}
