<mat-card class="bol-card content-wrapper">
    <mat-card-content>
        <h3 translate>Overviews</h3>

        <mat-list>
            <mat-list-item>
                <a routerLink="/overviews/schedule-overview" translate>Schedule Overview</a>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>