import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { StatusPageState, StatusPageStore, initialStatusPageState } from 'src/app/shared/stores/status-page-store/status-page.store';

export interface OtherSettingsState extends StatusPageState {
    selectedDaymarkIdForLeaveShift: number;
    selectedDaymarkIdForSickShift: number;
    loadingStateForActivityTypeForShiftReplacedByLeave: boolean;
    loadingStateForActivityTypeForShiftReplacedBySick: boolean;
}

const initialState = {
    selectedDaymarkIdForLeaveShift: undefined,
    selectedDaymarkIdForSickShift: undefined,
    loadingStateForActivityTypeForShiftReplacedByLeave: false,
    loadingStateForActivityTypeForShiftReplacedBySick: false
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-other-settings' })
export class OwsOtherSettingsStore extends StatusPageStore<OtherSettingsState> {
    constructor() {
        super({...initialState,  ...initialStatusPageState});
    }

    public updateSelectedDaymarkIdForLeaveShift(selectedDaymarkIdForLeaveShift: number): void {
        this.update(state => ({ ...state, selectedDaymarkIdForLeaveShift }));
    }

    public updateSelectedDaymarkIdForSickShift(selectedDaymarkIdForSickShift: number): void {
        this.update(state => ({ ...state, selectedDaymarkIdForSickShift}));
    }

    public updateLoadingStateForActivityTypeForShiftReplacedByLeave(loadingStateForActivityTypeForShiftReplacedByLeave: boolean): void {
        this.update(state => ({ ...state, loadingStateForActivityTypeForShiftReplacedByLeave}));
    }

    public updateLoadingStateForActivityTypeForShiftReplacedBySick(loadingStateForActivityTypeForShiftReplacedBySick: boolean): void {
        this.update(state => ({ ...state, loadingStateForActivityTypeForShiftReplacedBySick}));
    }
}
