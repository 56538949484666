<div class="daypart-item" [ngClass]="{'error-daypart-item': daypartItemErrorClass, 'field-error': formGroup.invalid, 'no-error-first-daypart': showFirstDaypartInfo && formGroup.valid}">
    <div class="daypart-name">
        <mat-form-field class="bol-form-field" appearance="fill">
            <mat-label translate>Daypart name</mat-label>
            <input
                #nameInput
                matInput
                [formControl]="formControls.name"
                required>
            <mat-error *ngIf="formControls.name.errors?.required || formControls.name.errors?.['no-empty-spaces']" translate>{{ requiredErrorMessage }}</mat-error>
            <mat-error *ngIf="formControls.name.errors?.notUnique" translate>{{ uniqueNameErrorLabel }}</mat-error>
        </mat-form-field>
    </div>
    <div class="daypart-start">
        <mat-form-field class="bol-form-field date-input" appearance="fill">
            <mat-label translate>Start time</mat-label>
            <input
                matInput
                bolTimeInput
                [formControl]="formControls.start"
                [bolTimeAutocomplete]="autocompleteFrom"
                autocomplete="off"
                required/>
            <mat-icon matSuffix>access_time</mat-icon>
            <mat-error
                *ngIf="formControls.start.errors?.required && !formControls.start.errors?.bolTimeInputParse"
                translate>
                Please enter a start time
            </mat-error>
            <mat-error
                *ngIf="formControls.start.errors?.bolTimeInputParse"
                translate>
                Please enter a valid start time
            </mat-error>
            <mat-error
                *ngIf="formControls.start.errors?.notUnique"
                translate>
                Please choose a unique daypart start
            </mat-error>
            <bol-time-autocomplete #autocompleteFrom="bolTimeAutocomplete" [timeValues]="timeRange$ | async"></bol-time-autocomplete>
        </mat-form-field>
    </div>
    
    <div *ngIf="enableDelete$ | async" class="delete-daypart-button">
        <button
            mat-button
            [disabled]="disableDelete$ | async"
            class="bol-button-text icon-only bol-negative"
            (click)="onDeleteDaypart(daypart)">
            <mat-icon>delete</mat-icon> 
        </button>
    </div>
</div>

<div *ngIf="showFirstDaypartInfo" class="first-daypart-info">
    <div class="info" translate>
        The first daypart should always have the start time set to midnight (00:00).
    </div>
</div>