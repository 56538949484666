import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { OwsInterfaceScheduling } from './ows-interface-scheduling.model';

import { OwsInterfaceSchedulingStore, OwsInterfaceSchedulingState } from './ows-interface-scheduling.store';

@Injectable({
    providedIn: 'root'
})
export class OwsInterfaceSchedulingQuery extends Query<OwsInterfaceSchedulingState> {

    constructor(
        protected store: OwsInterfaceSchedulingStore
    ) {
        super(store);
    }

    public getInterfaceConfiguration(): Observable<OwsInterfaceScheduling> {
        return this.select(state => state.ui.interfaceConfiguration);
    }

    public getDataLoadedState(): Observable<boolean> {
        return this.select(state => state.ui.dataLoaded);
    }

    public getInterfaceConfigurationSync(): OwsInterfaceScheduling {
        return this.getValue().ui.interfaceConfiguration;
    }
}
