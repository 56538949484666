import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { OwsResourceType } from './ows-resource-type.model';

export interface OwsResourceTypeState extends EntityState<OwsResourceType> {
    ui: {
    };
}

const initialState = {
    ui: {
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-resourceType' })
export class OwsResourceTypeStore extends EntityStore<OwsResourceTypeState> {

    constructor() {
        super(initialState);
    }
}
