<mat-card  class="bol-card" class="content-wrapper">
    <mat-card-content>
        <div class="status-title">
            <h3 class="configurations-title" translate data-cy="organizationUnitsConfigurationsTitle">Organization units configurations</h3>
            <app-loading-status-indication
                [statusPageState]="statusPageState$ | async"
            ></app-loading-status-indication>
        </div>
        <div class="configurations-container">
            <p class="bol-text label-filter-organization-units" translate>Organization unit:</p>
            <app-filter-tree-single-select
                data-cy="organizationUnitsFilter"
                [entities]="organizationUnits$ | async"
                labelText="Organization unit"
                [disabled]="false"
                [selectedEntityId] ="selectedOrganizationUnits$ | async"
                (selectedEntityIdChanged)="onFilteredOrganizationsChanged($event)">
            </app-filter-tree-single-select>
            <p class="bol-text label-filter-activity-types" translate>Activity type for part-time schedule:</p>
            <app-filter-tree-single-select
                data-cy="activityTypeFilter"
                [entities]="activityTypes$ | async"
                labelText="Activity type"
                [disabled]="false"
                [selectedEntityId] ="selectedActivityTypeId$ | async"
                (selectedEntityIdChanged)="onSelectedActivityTypeChange($event)">
            </app-filter-tree-single-select>
        </div>
    </mat-card-content>
</mat-card>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
