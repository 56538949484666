import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@ortec/soca-web-ui/identity';

import { environment } from 'src/environments/environment';

import { AuthenticationMode } from './authentication.enum';

@Injectable({
    providedIn: 'root'
})
export class OmrpAuthenticationService {
    public readonly authMode: AuthenticationMode = environment.authMode as AuthenticationMode;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly http: HttpClient
    ) { }

    public get isAuthenticated(): boolean {
        // remove the local login logic when the Angular migration is finished.
        switch (this.authMode) {
            case AuthenticationMode.Auth0: {
                return this.authenticationService.isAuthenticated();
            }
            case AuthenticationMode.LocalLogin: {
                return !!this.authenticationService.currentUserInStorage?.token;
            }
            case AuthenticationMode.Saml2: {
                return !!this.authenticationService.currentUserInStorage?.token;
            }
            default: return false;
        }
    }

    public redirectToLocalLogin(): void {
        window.location.href = '/omrp#!/login';
    }

    public redirectToSaml2Login(): void {
        window.location.href = environment.omrpApiUrl + '/api/Challenge';
    }

    public logout(): void {
        if (this.authMode !== AuthenticationMode.Saml2) {
            this.authenticationService.logout();

            return;
        }

        this.http.post(environment.omrpApiUrl + '/Auth/Logout', null).subscribe({
            next: () => { 
                localStorage.removeItem(this.authenticationService.currentUserStorageKey);
                window.location.href = window.location.origin;
            },
            error: (error) => { console.log(error); }
        });
    }
}
