import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InitializationService {
    public authenticationInProgress$ = new BehaviorSubject<boolean>(false);
    public errorInAuthentication$ = new BehaviorSubject<boolean>(false);

    public setAuthenticationInProgress(value: boolean): void {
        this.authenticationInProgress$.next(value);
    }

    public setErrorInAuthentication(value: boolean): void {
        this.errorInAuthentication$.next(value);
    }
}
