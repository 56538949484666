<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-card class="bol-card full-height">
    <mat-card-header class="bol-card-header mat-card-header-padding">
        <mat-card-title
            class="bol-card-title entity-title"
            translate
            [attr.data-cy]="dataCyLabel ? dataCyLabel : 'entityListTitle'">
            {{entityName}}
        </mat-card-title>
        <button 
            *ngIf="!hideAddButton"
            [disabled]="!canAdd"
            (click)="onAddEntityClicked()"
            mat-button 
            class="bol-button-solid bol-default"
            data-cy="addEntityButton">
            <span translate>Add</span>
        </button>
    </mat-card-header>
    <mat-card-content class="bol-card-content">
        <ng-container *ngIf="!loadingError">

            <mat-form-field 
                *ngIf="dataSource?.data?.length > 0 && searchProperties?.length > 0"
                class=" bol-form-field entity-search"
            >
                <input 
                    matInput 
                    (keyup)="searchEntities($event.target.value)" 
                    placeholder="{{'Search' | translate}}" 
                    data-cy="searchEntityField"
                    autocomplete="off"
                >
            </mat-form-field>

            <table 
                data-cy="entityTable"    
                *ngIf="dataSource?.data?.length > 0 && dataSource?.filteredData?.length !== 0"
                mat-table
                [dataSource]="dataSource" 
                class="bol-table bol-table-striped-even bol-table-row-hover">

                <ng-container *ngFor="let column of columnDefinition; first as isFirst" [matColumnDef]="column.entityProperty">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox 
                            *ngIf="enableSelectAll"
                            class="bol-checkbox first-column"
                            (change)="onSelectAll($event)" 
                            [disabled]="selectAllDisabled"
                            [checked]="isAllSelected()">
                        </mat-checkbox>
                        <span translate>{{column.columnDisplayName}}</span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="table-cell-with-icon">
                        <ng-container *ngIf="isFirst">

                            <mat-spinner 
                                *ngIf="row.isLoading && row.id !== selectedEntityId" 
                                class="row-loading-spinner" 
                                diameter="16">
                            </mat-spinner>

                            <mat-checkbox
                                *ngIf="showCheckboxes"
                                [checked]="row.checked"
                                [disabled]="row.disabled"
                                (change)="onCheckboxClick($event, row)"
                                class="bol-checkbox">
                            </mat-checkbox>

                            <mat-radio-group *ngIf="showRadioButtons" [ngModel]="row.checked ? row : undefined">
                                <mat-radio-button
                                    [value]="row"
                                    [disabled]="row.disabled"
                                    (click)="onRadioButtonClick($event, row)"
                                    class="bol-checkbox">
                                </mat-radio-button>
                            </mat-radio-group>

                        </ng-container>

                        {{row[column.entityProperty]}}

                        <div class="icon" *ngIf="row.icon">
                            <mat-icon [bolPopoverHover]="popoverMessageHover">{{ icon }}</mat-icon>
                        </div>
                        <ng-template #popoverMessageHover>
                            <div class="popover-message" data-cy="popoverMessage" translate>{{ popoverMessage }}</div>
                        </ng-template>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr 
                    mat-row 
                    (click)="onEntitySelected(row.id)" 
                    [class.selected]="row.id === selectedEntityId"
                    *matRowDef="let row;
                    columns: displayedColumns;">
                </tr>
            </table>

            <mat-paginator 
                [ngClass]="{'hidden': dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0}"
                [pageSize]="10" 
                [hidePageSize]="true" 
                showFirstLastButtons 
                aria-label="Select page">
            </mat-paginator>
        </ng-container>

        <ng-container *ngIf="!loadingError && (dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0)">
            <p data-cy="entityNoEntitiesFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase } }}</p>
        </ng-container>

        <ng-container *ngIf="loadingError">
            <h4 translate data-cy="entityLoadingError">ERROR.LOADING-ENTITIES-ERROR</h4>
        </ng-container>

    </mat-card-content>
</mat-card>