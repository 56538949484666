/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ConnectableObservable, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { publishReplay } from 'rxjs/operators';

// This operator ensures that an Observable is subscribed to an executed directly
// There is not need to subscribe to it
// Also unsubscribing to the resulting Observable will not cancel the action
// VERY useful for PUT, POST, DELETE requests!
export function commit<T>(): MonoTypeOperatorFunction<T> {
    return (source$: Observable<T>) => {
        const output$ = source$.pipe(publishReplay(1));

        (output$ as ConnectableObservable<T>).connect();

        return output$;
    };
}
