import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { EntityUI, getDefaultUIState } from '../entity-ui-models';
import { UserGroup } from './user-group.model';

export interface UserGroupState extends EntityState<UserGroup> {
    ui: {
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-group' })
export class UserGroupStore extends EntityStore<UserGroupState> {
    constructor() {
        super(initialState);
        this.createUIStore().setInitialEntityState<EntityUI>(getDefaultUIState());
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}
