import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { getDefaultUIState } from '../entity-ui-models';
import { SkillLevel } from './skill-level.model';
import { SkillLevelStore } from './skill-level.store';

@Injectable({
    providedIn: 'root'
})
export class SkillLevelService {

    constructor(
        protected skillLevelStore: SkillLevelStore,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
        private readonly translateService: TranslateService
    ) { }

    public getSkillLevels() {
        this.skillLevelStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<SkillLevel>>('/api/SkillLevels').pipe(
            catchError((error) => {
                this.skillLevelStore.setError(error);

                return of([]);
            }),
            tap((skillLevels) => {
                this.skillLevelStore.set(skillLevels);

                this.skillLevelStore.updateEntitiesLoadingState(false);
            }),
            
        );
    }

    public deleteSkillLevel(skillLevelId: number): Observable<void> {
        this.skillLevelStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/SkillLevels/' + skillLevelId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('skill') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.skillLevelStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.skillLevelStore.remove(skillLevelId);
                }
            }),
        ); 
    }
    
    public saveSkillLevel(skillLevel: SkillLevel, lastCorrectVersionOfSkill: SkillLevel): Observable<SkillLevel> {
        if (skillLevel.id !== -1) {
            this.skillLevelStore.ui.update(skillLevel.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<SkillLevel>('/api/SkillLevels', skillLevel).pipe(
            catchError((error) => {
                if (skillLevel.id !== -1) {
                    this.skillLevelStore.ui.update(skillLevel.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING',
                    { 
                        entityName: this.translateService.instant('skillLevel'),
                        entityDisplayName: skillLevel.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => lastCorrectVersionOfSkill);
            }),
            tap((updatedSkillLevel: SkillLevel) => {
                // Note: an object is returned from the "put" request only for skill levels saved with id -1 
                // for updating skill levels we will use the skill level object that we stored client-side
                if (skillLevel.id !== -1) {
                    this.skillLevelStore.upsert(skillLevel.id, skillLevel); // TODO: Check if we really need this?
                    this.skillLevelStore.ui.update(skillLevel.id, { isLoading: false, isFinishedSaving: true });
                } else {
                    this.skillLevelStore.upsert(updatedSkillLevel.id, skillLevel);
                }

                return skillLevel;
            }),
        );
    }

    public setSkillLevelToPendingChanges(skillLevelId: number) {
        this.skillLevelStore.ui.update(skillLevelId, { hasPendingChanges: true, isFinishedSaving: false });
    }

    public setSkillLevelToCleanUIState(skillLevelId: number) {
        if (skillLevelId !== undefined && skillLevelId !== -1) {
            this.skillLevelStore.ui.update(skillLevelId, getDefaultUIState());
        }
    }
}
