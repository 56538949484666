/* eslint-disable radix */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/language';
import { RESOURCE_PROPERTY_TYPE, ResourcePropertyLanguage } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.model';

@Injectable({
    providedIn: 'root'
})
export class FilteringHelperService {
    constructor(
        private readonly translateService: TranslateService,
        private readonly languageService: LanguageService,
    ) { }

    public getStandardResourceProperties(): Array<ResourcePropertyLanguage> {
        // 'Display name' and 'Organization Unit' are standard properties of a resource,
        //      but are added in the list of resource properties to be able to filter them along with the extra resource properties.
        //      added in the same list of resource properties with the extra resource properties
        // The resourcePropertyIds for the standard properties are -1, -3,
        //       so the ids won't overlap with the ones of the extra properties.
        const standardResourceProperties: Array<ResourcePropertyLanguage> = [
            {
                languageCode: this.languageService.getCurrentLanguage(),
                resourcePropertyId: -1,
                resourcePropertyType: RESOURCE_PROPERTY_TYPE.DISPLAY_NAME,
                text: this.translateService.instant('Display name')
            },
            {
                languageCode: this.languageService.getCurrentLanguage(),
                resourcePropertyId: -3,
                resourcePropertyType: RESOURCE_PROPERTY_TYPE.ORGANIZATION_UNIT,
                text: this.translateService.instant('Organization unit')
            }
        ];

        return standardResourceProperties;
    }
}
