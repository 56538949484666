<app-multiselect-tree 
    [formlyAttributes]="field"
    [entities]="props.entities"
    [preSelectedEntities]="preselected | async"
    [required]="props.required"
    [disable]="props.disable"
    [disableOptions]="props.disableOptions"
    [searchProperties]="props.searchProperties"
    [disableOptionsFunction]="props.disableOptionsFunction"
    searchFieldPlaceholder="{{props.searchFieldPlaceholder}}"
    multiselectTreePlaceholder="{{props.placeholderText}} {{props.required ? '*' : ''}}"
    errorMessage="{{'Field is required'}}"
    (selectedEntityIdsChanged)="onFilteredEntitiesChanged($event)">
</app-multiselect-tree>