<mat-card  class="bol-card" class="content-wrapper">
    <mat-card-content>
        <div class="status-title">
            <h3 class="filtering-title" translate data-cy="planboardFiltersTitle">FILTERING.PAGE-TITLE</h3>
            <app-loading-status-indication
                [statusPageState]="statusPageState$ | async"
            ></app-loading-status-indication>
        </div>
        <div class="resources-container">
            <app-multiselect-tree
                [entities]="organizationUnits$ | async"
                [preSelectedEntities]="selectedOrganizationUnits$ | async"
                [required]="false"
                [showSelectAll]="true"
                multiselectTreePlaceholder="FILTERING.RESOURCE-ORGANIZATION"
                data-cy="filteringOrganizationUnitsFilter"
                (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)">
            </app-multiselect-tree>

            <app-multiselect-tree
                [entities]="skills$ | async"
                [preSelectedEntities]="selectedSkillIds$ | async"
                [required]="false"
                [showSelectAll]="true"
                multiselectTreePlaceholder="FILTERING.RESOURCE-SKILLS"
                data-cy="filteringResourceSkillsFilter"
                (selectedEntityIdsChanged)="onFilteredResourceSkillsChanged($event)">
            </app-multiselect-tree>
        </div>

        <app-resource-selection
            [resources]="resources$ | async"
            [resourceTypes]="resourceTypes$ | async"
            [selectedResourcesToHide]="selectedResourceIds$ | async"
            [selectedResourceTypeId]="selectedResourceTypeId$ | async"
            buttonText="FILTERING.HIDE-RESOURCES-LABEL"
            [buttonIcon]="'supervised_user_circle'"
            (updateSelectedResourcesToHideEvent)="updateSelectedResourceIdsEvent($event)"
        ></app-resource-selection>

        <hr class="section-divider" />

        <app-activity-type-selection-new
            dialogTitle="FILTERING.VISIBLE-ACTIVITIES-LABEL"
            buttonTitle="FILTERING.VISIBLE-ACTIVITIES-LABEL"
            [showApplyFilter]="false"
            [activityTypes]="activityTypes$ | async"
            [activityTypeCategories]="activityTypeCategories$ | async"
            [selectedActivityTypeIds]="selectedActivityTypeIds$ | async"
            [organizationUnits]="organizationUnits$ | async"
            (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
        ></app-activity-type-selection-new>

        <hr class="section-divider" />

        <div class="description-field_resource-properties" translate>Select visible columns for resource properties</div>
        <app-filter-chip-list
            data-cy="selectVisibleColumnsField"
            [entities]="allResourceProperties$ | async"
            [preselectedEntities]="selectedResourceProperties$ | async"
            noResultsFoundText="{{'No resource properties found' | translate}}"
            placeholderText="{{'Select visible columns' | translate}}"
            (selectedEntityIdsChanged)="onFilteredResourcePropertiesChanged($event)"
        ></app-filter-chip-list>
    </mat-card-content>
</mat-card>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
