import { Injectable } from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LanguageService } from 'src/app/shared/language';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import {
    RESOURCE_PROPERTY_TYPE,
    ResourcePropertyLanguage,
} from 'src/app/shared/stores/resource-property-language-store/resource-property-language.model';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';

import {
    ScheduleActivity,
    ScheduleExtraResource,
    ScheduleOverviewResourceProperty,
    ScheduleResource,
    ScheduleShowOption,
} from '../stores/schedule-store/schedule.model';
import { GROUPING_OPTIONS, SCHEDULE_STORAGE_KEYS, SHOW_OPTIONS } from './enums';

export interface GroupingOption {
    type: GROUPING_OPTIONS;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleHelperService {
    constructor(
        private readonly translateService: TranslateService,
        private readonly languageService: LanguageService,
        private readonly daypartQuery: DaypartQuery,
        private readonly dateTimeUtilityService: DateTimeUtilityService
    ) { }

    public getInitialShowOptions(): Array<ScheduleShowOption> {
        return [
            {
                value: SHOW_OPTIONS.SHOW_ME_ON_TOP,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_START_AND_END_TIME,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_NAME,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_SAT_SUN,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_START_AND_END_TIME_EXTRA_RESOURCE,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_NAME_EXTRA_RESOURCE,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_MEMO,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_ORGANIZATION_UNIT,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME,
                state: true
            },
            {
                value: SHOW_OPTIONS.SHOW_MEMO_EXTRA_RESOURCE,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ORGANIZATION_UNIT_EXTRA_RESOURCE,
                state: false
            },
            {
                value: SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_EXTRA_RESOURCE,
                state: true
            },
        ];
    }

    public getInitialGroupingOptions(withDayParts?: boolean): Array<GroupingOption> {
        const groupingOptions = [
            { type: GROUPING_OPTIONS.NO_GROUPING, value: 'No grouping' },
            { type: GROUPING_OPTIONS.RESOURCE_TYPE, value: 'Resource type' },
            { type: GROUPING_OPTIONS.ORGANIZATION_UNIT, value: 'Organization unit' }
        ];

        if (withDayParts) {
            groupingOptions.push({ type: GROUPING_OPTIONS.DAY_PART, value: 'Daypart' });
        }

        return groupingOptions;
    }

    public getStandardResourceProperties(): Array<ResourcePropertyLanguage> {
        // 'Display name' and 'Email address' are standard properties of a resource,
        //      but are added in the list of resource properties to be able to filter them along with the extra resource properties.
        //      added in the same list of resource properties with the extra resource properties
        // The resourcePropertyIds for the standard properties are -1, -2,
        //       so the ids won't overlap with the ones of the extra properties.
        const standardResourceProperties: Array<ResourcePropertyLanguage> = [
            {
                languageCode: this.languageService.getCurrentLanguage(),
                resourcePropertyId: -1,
                resourcePropertyType: RESOURCE_PROPERTY_TYPE.DISPLAY_NAME,
                text: this.translateService.instant('Display name')
            },
            {
                languageCode: this.languageService.getCurrentLanguage(),
                resourcePropertyId: -2,
                resourcePropertyType: RESOURCE_PROPERTY_TYPE.EMAIL_ADDRESS,
                text: this.translateService.instant('Email address')
            }
        ];

        return standardResourceProperties;
    }

    public getResourcesWithDefaultProperties(resources: Array<ScheduleResource>): Array<ScheduleResource> {
        return resources.map(resource => {
            const standardResourceProperties: Array<ScheduleOverviewResourceProperty> = this.getStandardResourceProperties().map(resourceProperty => {
                switch (resourceProperty.resourcePropertyType) {
                    case RESOURCE_PROPERTY_TYPE.DISPLAY_NAME: {
                        return ({
                            resourcePropertyId: resourceProperty.resourcePropertyId,
                            value: resource.resourceName
                        });
                    }
                    case RESOURCE_PROPERTY_TYPE.EMAIL_ADDRESS: {
                        return ({
                            resourcePropertyId: resourceProperty.resourcePropertyId,
                            value: resource.resourceEmailAddress
                        });
                    }
                }
            });

            const fullResourceProperties = [...resource.resourceProperties, ...standardResourceProperties];
            const updatedResource: ScheduleResource = { ...resource, resourceProperties: fullResourceProperties };

            return updatedResource;
        });
    }

    public getResourcesForOrganizationUnitGrouping(
        resources: Array<ScheduleResource>,
        filteredOrganizationUnits: Array<OrganizationUnit>
    ): Array<ScheduleResource> {
        resources
            .filter(resource => resource.resourceOrganizationUnits.length > 1)
            .map(resource => {
                resource.resourceOrganizationUnits.forEach(organizationUnit => {
                    const indexOU = filteredOrganizationUnits.findIndex(ou => ou.displayName === organizationUnit);
                    if (indexOU === -1) {
                        return;
                    }
                    const activitiesOfDuplicatedResource = resource.activities.filter(activity =>
                        activity.organizationUnitName === organizationUnit
                    );
                    const duplicatedResource: ScheduleResource = {
                        ...resource, activities: activitiesOfDuplicatedResource,
                        resourceOrganizationUnits: [organizationUnit]
                    };

                    return resources.push(duplicatedResource);
                });
            });

        const filteredResources = resources.filter(resource => resource.resourceOrganizationUnits.length === 1);

        return filteredResources;
    }

    public getResourcesForDaypartGrouping(resources: Array<ScheduleResource>, extraResourceTypes: Array<ResourceType>): Array<ScheduleResource> {
        const dayparts = this.daypartQuery.getDaypartsSync();
        resources.map(resource => {
            const filteredActivitiesByDayparts = this.filterActivitiesByDayparts(resource.activities, extraResourceTypes);

            if (resource.activities.length > 0) {
                const activityDaypartNames = this.getActivityDaypartNames(resource.activities[0]);

                resource.daypart = activityDaypartNames[0];
                resource.activities = filteredActivitiesByDayparts.has(activityDaypartNames[0]) ?
                    filteredActivitiesByDayparts.get(activityDaypartNames[0]) : [];

                dayparts.map(daypart => {
                    if (filteredActivitiesByDayparts.has(daypart.name) && activityDaypartNames[0] !== daypart.name) {
                        resources.push({ ...resource, daypart: daypart.name, activities: filteredActivitiesByDayparts.get(daypart.name) });
                    }
                });
            }

            return resource;
        });

        return resources;
    }

    public getSortedResourcesByProperties(resources: Array<ScheduleResource>, filteredIds: Array<number>): Array<ScheduleResource> {
        return resources.sort((obj1, obj2) => {
            for (const filteredId of filteredIds) {
                const obj1ResourceProperty = obj1.resourceProperties.find(res => res.resourcePropertyId === filteredId);
                const obj2ResourceProperty = obj2.resourceProperties.find(res => res.resourcePropertyId === filteredId);

                if (obj1ResourceProperty === undefined) {
                    return 1;
                }
                if (obj2ResourceProperty === undefined) {
                    return -1;
                }
                const obj1ResourcePropertyValue = obj1ResourceProperty.value !== null ? obj1ResourceProperty.value : '';
                const obj2ResourcePropertyValue = obj2ResourceProperty.value !== null ? obj2ResourceProperty.value : '';

                const obj1ResourcePropertyValueIsNumber = !isNaN(Number(obj1ResourcePropertyValue));
                const obj2ResourcePropertyValueIsNumber = !isNaN(Number(obj2ResourcePropertyValue));

                if (obj1ResourcePropertyValueIsNumber && obj2ResourcePropertyValueIsNumber) {
                    if (Number(obj1ResourcePropertyValue) < Number(obj2ResourcePropertyValue)) {
                        return -1;
                    }

                    if (Number(obj1ResourcePropertyValue) > Number(obj2ResourcePropertyValue)) {
                        return 1;
                    }
                }
                if (obj1ResourcePropertyValue.toLowerCase() < obj2ResourcePropertyValue.toLowerCase()) {
                    return -1;
                }

                if (obj1ResourcePropertyValue.toLowerCase() > obj2ResourcePropertyValue.toLowerCase()) {
                    return 1;
                }
            }

            return 0;
        });
    }

    public setCurrentUserResourcesOnTop(resources: Array<ScheduleResource>): Array<ScheduleResource> {
        const newResources = [...resources];
        const userResources = resources.filter(r => r.isCurrentUser);

        let position = 0;
        userResources.forEach(resource => {
            const index = resources.findIndex(r => r.resourceName === resource.resourceName && r.resourceType === resource.resourceType);
            newResources.splice(index, 1);
            newResources.splice(position, 0, resource);
            position++;
        });

        return newResources;
    }

    public getResourceDescription(resource: ScheduleResource, resourceProperties: Array<ResourcePropertyLanguage>): string {
        let resourceDescription = '';

        if (resourceProperties.length > 0) {
            resourceProperties.forEach(rp => {
                const resourcePropertyValue = resource.resourceProperties.find(prop => prop.resourcePropertyId === rp.resourcePropertyId);
                if (resourcePropertyValue !== undefined && resourcePropertyValue.value !== null && resourcePropertyValue.value !== '') {
                    resourceDescription += resourcePropertyValue.value + '\n';
                }
            });
        }

        return resourceDescription;
    }

    // eslint-disable-next-line complexity
    public getResourcesOrExtraResourcesEvents(
        resource: ScheduleExtraResource | ScheduleActivity,
        resourceTitle: string,
        startDate: Date,
        activityDialogState: boolean,
        eventSort: number,
        scheduleResourceId: string,
        showStartAndEndTime: boolean,
        showMemo: boolean,
        isExtraResourceEvent: boolean,
        rootMemo?: string,
        customHtmlTitle?: string,
    ): Array<EventInput> {
        const events: Array<EventInput> = [];

        const startDateResource = moment(resource.startTime, 'MM/DD/YYYY HH:mm:ss');
        const endDateResource = moment(resource.endTime, 'MM/DD/YYYY HH:mm:ss');
        const endResource = new Date(
            endDateResource.toDate().getFullYear(),
            endDateResource.toDate().getMonth(),
            endDateResource.toDate().getDate(), 23, 59, 59);

        const rootActivityClasses = activityDialogState ? ['root-activity', 'clickable-activity'] : ['root-activity'];
        const resourceEvent: EventInput = {
            resourceId: scheduleResourceId,
            start: startDate,
            end: endResource,
            title: resourceTitle,
            customHtml: customHtmlTitle ? resourceTitle : undefined,
            borderColor: '#' + resource.backgroundColor,
            backgroundColor: '#' + resource.backgroundColor,
            textColor: '#' + resource.textColor,
            classNames: isExtraResourceEvent ? ['leaf-activity'] : rootActivityClasses,
            sort: eventSort += 1,
            activityDialogEnabled: !isExtraResourceEvent && activityDialogState,
            activityDialogId: !isExtraResourceEvent && resource.id ? resource.id.toString() : null,
        };
        events.push(resourceEvent);

        const rootActivityClass = activityDialogState ? 'clickable-activity' : null;
        if (showStartAndEndTime) {
            const timeResourceTitle = startDateResource.format('HH:mm') + ' - ' + endDateResource.format('HH:mm');
            const resourcePeriodEvent: EventInput = {
                resourceId: scheduleResourceId,
                start: startDate,
                end: endResource,
                backgroundColor: 'white',
                borderColor: 'white',
                textColor: 'black',
                title: timeResourceTitle,
                sort: eventSort += 1,
                className: isExtraResourceEvent ? 'leaf-activity' : rootActivityClass,
                activityDialogEnabled: !isExtraResourceEvent && activityDialogState,
                activityDialogId: !isExtraResourceEvent && resource.id ? resource.id.toString() : null
            };

            events.push(resourcePeriodEvent);
        }

        if (rootMemo && showMemo) {
            const resourceRootMemoEvent: EventInput = {
                resourceId: scheduleResourceId,
                start: startDate,
                end: endResource,
                backgroundColor: 'white',
                borderColor: 'white',
                textColor: 'black',
                title: rootMemo,
                sort: eventSort += 1,
                className: isExtraResourceEvent ? 'leaf-activity' : rootActivityClass,
                activityDialogEnabled: !isExtraResourceEvent && activityDialogState,
                activityDialogId: !isExtraResourceEvent && resource.id ? resource.id.toString() : null
            };

            events.push(resourceRootMemoEvent);
        }

        if (resource.memo && showMemo) {
            const resourceMemoEvent: EventInput = {
                resourceId: scheduleResourceId,
                start: startDate,
                end: endResource,
                backgroundColor: 'white',
                borderColor: 'white',
                textColor: 'black',
                title: resource.memo,
                sort: eventSort += 1,
                className: isExtraResourceEvent ? 'leaf-activity' : rootActivityClass,
                activityDialogEnabled: !isExtraResourceEvent && activityDialogState,
                activityDialogId: !isExtraResourceEvent && resource.id ? resource.id.toString() : null
            };

            events.push(resourceMemoEvent);
        }

        return events;
    }

    public getResourceOrExtraResourceTitle(
        organizationUnitName: string,
        activityName: string,
        shortActivityName: string,
        showActivityName: boolean,
        showActivityShortName: boolean,
        showOrganizationUnitType: boolean,
        extraResourceName?: string,
    ): string {
        let description = showActivityName ? activityName : '';
        if (showActivityShortName && shortActivityName) {
            description = showActivityName ? description + ' - ' + shortActivityName : shortActivityName;
        }

        if (extraResourceName) {
            description += description !== '' ? ' - ' + extraResourceName : extraResourceName;
        }

        const unitAndActivityNameSeparator = description !== '' ? ' - ' : '';
        const resourceTitle = (showOrganizationUnitType && organizationUnitName)
            ? description + unitAndActivityNameSeparator + organizationUnitName : description;

        return resourceTitle;
    }

    public sortLeafActivities(activities: Array<ScheduleExtraResource>, activityId: number): Array<ScheduleExtraResource> {
        return activities.sort((a, b) => {
            const sortOrderA = activityId === a.parentId ? a.parentSortOrder : a.sortOrder;
            const sortOrderB = activityId === b.parentId ? b.parentSortOrder: b.sortOrder;
            if (sortOrderA !== null && sortOrderB !== null) {
                return sortOrderA !== sortOrderB ? sortOrderA - sortOrderB : -1;
            }

            if (sortOrderA === null && sortOrderB === null && a.resourceType === b.resourceType) {
                const startTimeA = new Date(a.startTime).getTime();
                const startTimeB = new Date(b.startTime).getTime();
 
                return startTimeA - startTimeB;
            }

            if (sortOrderA === null && sortOrderB === null && a.resourceType !== b.resourceType) {
                return a.resourceType.toLowerCase() > b.resourceType.toLowerCase() ? 1 : -1;
            }
        });
    }

    public setInStorage(storageKey: SCHEDULE_STORAGE_KEYS, value: any): void {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }

    public getScheduleItemFromStorage<T>(storageKey: SCHEDULE_STORAGE_KEYS): T {
        return JSON.parse(localStorage.getItem(storageKey));
    }

    private filterActivitiesByDayparts(activities: Array<ScheduleActivity>, extraResourceTypes: Array<ResourceType>): Map<string, Array<ScheduleActivity>> {
        const filteredActivitiesByDayparts = new Map<string, Array<ScheduleActivity>>();

        activities.map(activity => {
            let filteredExtraResourcesByDayparts = new Map<string, Array<ScheduleExtraResource>>();

            if (extraResourceTypes.length > 0) {
                filteredExtraResourcesByDayparts = this.filterExtraResourcesByDayparts(activity.extraResources);
            }
            this.daypartQuery.getDaypartsSync().map(daypart => {
                if (filteredExtraResourcesByDayparts.has(daypart.name)) {
                    const extraResourcesByDayPart = filteredExtraResourcesByDayparts.get(daypart.name);

                    const newActivity: ScheduleActivity = { ...activity, extraResources: extraResourcesByDayPart };

                    if (filteredActivitiesByDayparts.has(daypart.name)) {
                        filteredActivitiesByDayparts.get(daypart.name).push(newActivity);
                    } else {
                        filteredActivitiesByDayparts.set(daypart.name, [newActivity]);
                    }
                }

            });

            const activityDaypartNames = this.getActivityDaypartNames(activity);
            activityDaypartNames.map(dpName => {
                if (!filteredExtraResourcesByDayparts.has(dpName)) {
                    if (filteredActivitiesByDayparts.has(dpName)) {
                        filteredActivitiesByDayparts.get(dpName).push({ ...activity, extraResources: [] });
                    } else {
                        filteredActivitiesByDayparts.set(dpName, [{ ...activity, extraResources: [] }]);
                    }
                }
            });
        });

        return filteredActivitiesByDayparts;
    }

    private filterExtraResourcesByDayparts(extraResources: Array<ScheduleExtraResource>): Map<string, Array<ScheduleExtraResource>> {
        const filteredExtraResources = new Map<string, Array<ScheduleExtraResource>>();
        const dayparts = this.daypartQuery.getDaypartsSync();

        extraResources.map(extraResource => {
            const extraResourceStart = moment(extraResource.startTime, 'MM/DD/YYYY HH:mm:ss').toDate();
            const extraResourceEnd = moment(extraResource.endTime, 'MM/DD/YYYY HH:mm:ss').toDate();

            dayparts.filter(dayPart =>
                this.daypartQuery.isActivityInDaypartInterval(extraResourceStart, extraResourceEnd, dayPart)
            ).map(dp => {
                if (filteredExtraResources.has(dp.name)) {
                    filteredExtraResources.get(dp.name).push(extraResource);
                } else {
                    filteredExtraResources.set(dp.name, [extraResource]);
                }
            });
        });

        return filteredExtraResources;
    }

    private getActivityDaypartNames(activity: ScheduleActivity): Array<string> {
        const activityStart = moment(activity.startTime, 'MM/DD/YYYY HH:mm:ss').toDate();
        const activityEnd = moment(activity.endTime, 'MM/DD/YYYY HH:mm:ss').toDate();
        const dayparts = this.daypartQuery.getDaypartsSync();

        return dayparts.filter(dayPart =>
            this.daypartQuery.isActivityInDaypartInterval(activityStart, activityEnd, dayPart)
        ).map(dp => dp.name);
    }
}
