<mat-form-field class="bol-form-field default-mat-form-field" appearance="fill" [formlyAttributes]="field">
    <mat-label>{{ props.placeholderText | translate }}</mat-label>
    <bol-select 
        [selectionMode]="'multiple'"
        [control]="bolMultiSelectFormControl"
        [items]="multiselectOptions"
        [isSearchable]="true"
        [textConfiguration]="selectLabels$ | async">
    </bol-select>
    <mat-error *ngIf="bolMultiSelectFormControl.invalid">{{ 'Field is required' | translate }}</mat-error>
</mat-form-field>