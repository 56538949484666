/* eslint-disable radix */
import { Injectable } from '@angular/core';
import { ManageTemplate } from 'src/app/shared/stores/manage-template-store/manage-template.model';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplatesHelperService {
    public getNewManageTemplateObject(): ManageTemplate {
        return {
            id: undefined,
            displayName: '',
            lengthInWeeks: 1,
            isUsedOnActivityPlan: false
        };
    }
}
