import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { filterUndefined } from '@ortec/soca-web-ui';
import { SelectionClickEvent } from 'src/app/shared/components/entity-management/entity-list-panel/entity-list-panel.component';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { SkillService } from 'src/app/shared/stores/skill-store/skill.service';
import { OwsDepartmentQuery } from '../ows-department-mapping/ows-department-store/ows-department.query';
import { OwsDepartmentService } from '../ows-department-mapping/ows-department-store/ows-department.service';

import { OwsSkillTableElement } from './ows-skill-store/ows-skill.model';
import { OwsSkillService } from './ows-skill-store/ows-skill.service';
import { SkillMappingQuery } from './skill-mapping-store/skill-mapping.query';
import { SkillMappingService } from './skill-mapping-store/skill-mapping.service';

@Component({
    selector: 'app-ows-skill-mapping',
    templateUrl: './ows-skill-mapping.component.html',
    styleUrls: ['./ows-skill-mapping.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsSkillMappingComponent implements OnInit {
    public visibleOwsSkills$: Observable<Array<OwsSkillTableElement>>;
    public visibleSkills$: Observable<Array<Skill>>;
    public unlinkedSkillCount$: Observable<number>;
    public selectedSkillId$: Observable<number>;
    public hideUnlinkedOwsSkills$: Observable<boolean>;
    public showLinkedOwsSkills$: Observable<boolean>;
    public organizationsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public selectedOrganizationUnit$: Observable<number>;
    public owsDepartmentsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public selectedOwsDepartment$: Observable<number>;
    public showUnderlyingUnits$: Observable<boolean>;
    public showAllOwsSkills$: Observable<boolean>;

    constructor(
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly owsSkillService: OwsSkillService,
        private readonly skillService: SkillService,
        private readonly skillMappingService: SkillMappingService,
        private readonly skillMappingQuery: SkillMappingQuery,
        private readonly displaySettingQuery: DisplaySettingQuery,
        private readonly displaySettingService: DisplaySettingService,
        private readonly owsDepartmentQuery: OwsDepartmentQuery,
        private readonly owsDepartmentService: OwsDepartmentService,
    ) { }

    public ngOnInit() {
        this.owsSkillService.get().pipe(first()).subscribe();
        this.skillService.getSkills().pipe(first()).subscribe();
        this.skillMappingService.get().pipe(first()).subscribe();
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.owsDepartmentService.get().pipe(first()).subscribe();

        this.visibleOwsSkills$ = this.skillMappingQuery.getFilteredOwsSkills();
        this.visibleSkills$ = this.skillMappingQuery.getFilteredSkills();
        this.unlinkedSkillCount$ = this.skillMappingQuery.getUnlinkedSkillCount();
        this.selectedSkillId$ = this.skillMappingQuery.getSelectedSkillId();
        this.hideUnlinkedOwsSkills$ = this.skillMappingQuery.getUnlinkedOwsSkillVisibility();
        this.showLinkedOwsSkills$ = this.skillMappingQuery.getLinkedOwsSkillVisibility();
        this.organizationsForFiltering$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.selectedOrganizationUnit$ = this.skillMappingQuery.getSelectedOrganizationUnit();
        this.owsDepartmentsForFiltering$ = this.owsDepartmentQuery.getOwsDepartmentsForFiltering();
        this.selectedOwsDepartment$ = this.skillMappingQuery.getSelectedOwsDepartment();
        this.showUnderlyingUnits$ = this.skillMappingQuery.getShowUnderlyingUnitsState();
        this.showAllOwsSkills$ = this.skillMappingQuery.getShowAllOwsSkills();

        this.setInitialStatesFromDisplaySettings();
    }

    public onSelectOwsSkillForMapping(event: SelectionClickEvent): void {
        this.skillMappingService.updateMappings(event.entity);
    }

    public onSelectSkill(id: number): void {
        this.skillMappingService.updateSelectedSkillId(id);
    }

    public onHideUnlinkedOwsSkillsChange(event: MatCheckboxChange): void {
        this.skillMappingService.updateHideUnlinkedOwsSkills(event.checked);
    }

    public onShowLinkedOwsSkillsChange(event: MatCheckboxChange): void {
        this.skillMappingService.updateShowLinkedOwsSkills(event.checked);
    }

    public onSelectedOrganizationUnitChange(id: number): void {
        this.skillMappingService.updateSelectedOrganizationUnitId(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, id).pipe(first()).subscribe();
    }

    public onUpdateShowChildUnits(event: MatCheckboxChange): void {
        this.skillMappingService.updateShowChildUnits(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, event.checked).pipe(first()).subscribe();
    }

    public onSelectedOwsDepartmentChange(id: number): void {
        this.skillMappingService.updateSelectedOwsDepartmentId(id);
    }

    public onShowAllOwsSkillsChange(event: MatCheckboxChange): void {
        this.skillMappingService.updateShowAllOwsSkills(event.checked);
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.skillMappingService.updateSelectedOrganizationUnitId(id))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.skillMappingService.updateShowChildUnits(state))
        ).subscribe();
    }
}
