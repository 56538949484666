import { Injectable } from '@angular/core';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { OrganizationUnitsManagementStore } from './organization-units-management.store';
@Injectable({
    providedIn: 'root'
})
export class OrganizationUnitsManagementService {
    constructor(
        protected organizationUnitsManagementStore: OrganizationUnitsManagementStore,
    ) {}

    public updateManageMode(mode: MANAGE_MODE): void {
        this.organizationUnitsManagementStore.updateManageMode(mode);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.organizationUnitsManagementStore.updateSelectedOrganizationUnitId(id);
    }

    public setStateForNewOrganizationUnit(orgUnitId: number): void {
        this.organizationUnitsManagementStore.setStateForNewOrganizationUnit(orgUnitId);
    }
}
