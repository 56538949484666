import { Injectable } from '@angular/core';
import { EntityState, EntityStore as Store, StoreConfig } from '@datorama/akita';
import * as moment from 'moment';

import { Resource } from 'src/app/shared/stores/resource-store/resource.model';

import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES, SCHEDULE_NAVIGATION_OPTIONS, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';
import { MultisortingOption, PreviousRequestParameters, ResourcesAndActivities, SavedEntityListForSchedule, Schedule, ScheduleResourceEventDialog, ScheduleShowOption } from './schedule.model';
import { Activity } from '../schedule-activity-store/schedule-activity.model';

export interface ScheduleState extends EntityState<Schedule> {
    ui: {
        selectedFilterSettingId: number;
        requestParameters: ScheduleRequestParameters;
        showOptions: Array<ScheduleShowOption>;
        activityShowOptions: Array<ScheduleShowOption>;
        activityTypeIds: Array<number>;
        allOrganizationUnitsSelectedState: boolean;
        applyFilterOnResourceScheduleState: boolean;
        activityTypeIdsForActivities: Array<number>;
        sortedActivityTypeRootIdsForActivities: Array<number>;
        applyFilterOnActivityScheduleState: boolean;
        groupingOptionType: GROUPING_OPTIONS;
        daterangeType: SCHEDULE_DATERANGE_TYPES;
        daypartIds: Array<number>;
        loadingState: boolean;
        savedMultisortingOrder: Array<MultisortingOption>;
        savedBaseResourceTypes: Array<SavedEntityListForSchedule>;
        savedExtraResourceTypes: Array<SavedEntityListForSchedule>;
        savedOrganizationUnits: Array<SavedEntityListForSchedule>;
        savedResourceProperties: Array<SavedEntityListForSchedule>;
        filterSettingsPanelOpenState: boolean;
        loadingStateActivityDetailsDialog: boolean;
        slotWidth: number;
        hideEmptyRows: boolean;
        splitterPosition: number;
    };
    resourcesAndActivities: ResourcesAndActivities;
    activityDialogDetails: Array<ScheduleResourceEventDialog>;
    currentActivityIdSelected: number;
    previousRequestParameters: PreviousRequestParameters;
}

const initialState = {
    ui: {
        selectedFilterSettingId: undefined,
        requestParameters: {
            startDate: moment().utc().format('YYYY-MM-DD 00:00:00'),
            endDate: moment().utc().add(6, 'days').format('YYYY-MM-DD 23:59:59'),
            organizationUnitIds: [],
            baseResourceTypeIds: [],
            extraResourceTypeIds: [],
        },
        showOptions: [],
        activityShowOptions: [],
        activityTypeIds: [],
        allOrganizationUnitsSelectedState: false,
        applyFilterOnResourceScheduleState: false,
        applyFilterOnActivityScheduleState: false,
        activityTypeIdsForActivities: [],
        sortedActivityTypeRootIdsForActivities: [],
        groupingOptionType: GROUPING_OPTIONS.RESOURCE_TYPE,
        daterangeType: SCHEDULE_DATERANGE_TYPES.WEEK,
        daypartIds: [],
        loadingState: false,
        loadingStateActivityDetailsDialog: false,
        savedMultisortingOrder: [],
        savedBaseResourceTypes: [],
        savedExtraResourceTypes: [],
        savedOrganizationUnits: [],
        savedResourceProperties: [],
        filterSettingsPanelOpenState: false,
        slotWidth: 100,
        hideEmptyRows: false,
        splitterPosition: 300
    },
    resourcesAndActivities: undefined,
    activityDialogDetails: [],
    currentActivityIdSelected: undefined,
    previousRequestParameters: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'schedule' })
export class ScheduleStore extends Store<ScheduleState> {
    constructor() {
        super(initialState);
    }

    public updatePreviousRequestParameters(previousRequestParameters: PreviousRequestParameters): void {
        this.update(state => ({ ...state, previousRequestParameters}));
    }

    public setResourcesAndActivities(resources: Array<Resource>, activitiesForActivityPanel: Array<Activity>, activitiesForResourcePanel: Array<Activity>): void {
        this.update(state => {
            const mappedActivitiesForActivityPanel: Map<number, Activity> = activitiesForActivityPanel.reduce(
                (map, act) => map.set(act.id, act),
                new Map<number, Activity>()
            );
    
            const mappedActivitiesForResourcePanel: Map<number, Activity> = activitiesForResourcePanel.reduce(
                (map, act) => map.set(act.id, act),
                new Map<number, Activity>()
            );
    
            return { 
                ...state, 
                resourcesAndActivities: {
                    activitiesForActivityPanel: mappedActivitiesForActivityPanel,
                    activitiesForResourcePanel: mappedActivitiesForResourcePanel,
                    resources
                }
            };
        });
    }

    public updateActivityDialogDetails(activityDialogDetails: ScheduleResourceEventDialog): void {
        this.update(state => {
            if (state.activityDialogDetails.find(id => id.activityId === activityDialogDetails.activityId)) {
                return;
            }

            return { ...state, activityDialogDetails: [...state.activityDialogDetails, activityDialogDetails] };
        });
    }

    public updateCurrentActivityIdSelected(currentActivityIdSelected: number): void {
        this.update(state => ({ ...state, currentActivityIdSelected}));
    }

    public updateDatesParameters(numberOfDays: SCHEDULE_NAVIGATION_OPTIONS, add: boolean): void {
        this.update(state => {
            const currentStartDate = moment(state.ui.requestParameters.startDate);
            const currentEndDate = moment(state.ui.requestParameters.endDate);
            const daterangeType = state.ui.daterangeType;
            let newStartDate;
            let newEndDate;

            switch (numberOfDays) {
                case SCHEDULE_NAVIGATION_OPTIONS.TODAY: {
                    if (daterangeType === SCHEDULE_DATERANGE_TYPES.DAY) {
                        newStartDate = moment().utc();
                    }
                    else {
                        newStartDate = moment().utc().startOf('isoWeek');
                        if (daterangeType === SCHEDULE_DATERANGE_TYPES.CONFIGURABLE) {
                            const weekDifference = currentEndDate.diff(currentStartDate, 'weeks');
                            newEndDate = newStartDate.clone().add(weekDifference, 'weeks');
                        }
                    }
                    break;
                }
                case SCHEDULE_NAVIGATION_OPTIONS.DAY: {
                    newStartDate = currentStartDate.clone().add(add ? 1 : -1, 'days');
                    break;
                }
                case SCHEDULE_NAVIGATION_OPTIONS.WEEK: {
                    newStartDate = currentStartDate.clone().add(add ? 7 : -7, 'days');
                    if (daterangeType === SCHEDULE_DATERANGE_TYPES.CONFIGURABLE) {
                        newEndDate = currentEndDate.clone().add(add ? 7 : -7, 'days');
                    }
                    break;
                }
                case SCHEDULE_NAVIGATION_OPTIONS.MONTH: {
                    newStartDate = currentStartDate.clone().add(add ? 1 : -1, 'months');

                    if (daterangeType !== SCHEDULE_DATERANGE_TYPES.DAY) {
                        newStartDate.startOf('isoWeek');
                    }
                    if (daterangeType === SCHEDULE_DATERANGE_TYPES.CONFIGURABLE) {
                        const weekDifference = currentEndDate.diff(currentStartDate, 'weeks');
                        newEndDate = newStartDate.clone().add(weekDifference, 'weeks').endOf('isoWeek');
                    }

                    break;
                }
            }

            if (daterangeType !== SCHEDULE_DATERANGE_TYPES.CONFIGURABLE) {
                newEndDate = this.getEndDate(newStartDate, daterangeType);
            }

            const requestParameters = { 
                ...state.ui.requestParameters, 
                startDate: newStartDate.format('YYYY-MM-DD 00:00:00'), 
                endDate: newEndDate
            };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateScheduleEndDate(endDate: Date): void {
        this.update(state => {
            const requestParameters = { 
                ...state.ui.requestParameters, 
                endDate: moment(endDate).endOf('isoWeek').format('YYYY-MM-DD 23:59:59')
            };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateLoadingStateActivityDetailsDialog(loadingStateActivityDetailsDialog: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, loadingStateActivityDetailsDialog };

            return { ...state, ui };
        });
    }

    public updateHideEmptyRows(hideEmptyRows: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, hideEmptyRows };

            return { ...state, ui };
        });
    }

    public updateSplitterPosition(splitterPosition: number): void {
        this.update(state => {
            const ui = { ...state.ui, splitterPosition };

            return { ...state, ui };
        });
    }

    public updateSlotWidth(slotWidth: number): void {
        this.update(state => {
            const ui = { ...state.ui, slotWidth };

            return { ...state, ui };
        });
    }
    
    public updateOrganizationUnitIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const organizationUnitIds = ids;
            const requestParameters = { ...state.ui.requestParameters, organizationUnitIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateFilterSettingsPanelStatus(filterSettingsPanelOpenState: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, filterSettingsPanelOpenState };
    
            return { ...state, ui };
        });
    }

    public updateBaseResourceTypeIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const baseResourceTypeIds = ids;
            const requestParameters = { ...state.ui.requestParameters, baseResourceTypeIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateExtraResourceTypeIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const extraResourceTypeIds = ids;
            const requestParameters = { ...state.ui.requestParameters, extraResourceTypeIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateAllOrganizationUnitsSelectedState(all: boolean): void {
        this.update(state => {
            const allOrganizationUnitsSelectedState = all;
            const ui = { ...state.ui, allOrganizationUnitsSelectedState };

            return { ...state, ui };
        });
    }

    public updateMultiselectOrderState(values: Array<MultisortingOption>): void {
        this.update(state => {
            const savedMultisortingOrder = values;
            const ui = { ...state.ui, savedMultisortingOrder };

            return { ...state, ui };
        });
    }

    public updateShowOption(option: ScheduleShowOption, newState: boolean): void {
        this.update(state => {
            const showOptions = state.ui.showOptions.map(x =>
                x.value === option.value ? { value: option.value, state: newState } : x
            );
            const ui = { ...state.ui, showOptions };

            return { ...state, ui };
        });
    }

    public updateActivityShowOption(option: ScheduleShowOption, newState: boolean): void {
        this.update(state => {
            const activityShowOptions = state.ui.activityShowOptions.map(x =>
                x.value === option.value ? { value: option.value, state: newState } : x
            );
            const ui = { ...state.ui, activityShowOptions };

            return { ...state, ui };
        });
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.update(state => {
            const activityTypeIds = ids;
            const ui = { ...state.ui, activityTypeIds };

            return { ...state, ui };
        });
    }

    public updateActivityTypeIdsForActivities(ids: Array<number>): void {
        this.update(state => {
            const activityTypeIdsForActivities = ids;
            const ui = { ...state.ui, activityTypeIdsForActivities };
            
            return { ...state, ui };
        });
    }

    public updateSortedActivityTypeRootIdsForActivities(sortedActivityTypeRootIdsForActivities: Array<number>): void {
        this.update(state => {
            const ui = { ...state.ui, sortedActivityTypeRootIdsForActivities };
            
            return { ...state, ui };
        });
    }
    
    public updateGroupingOptionType(groupingOptionType: GROUPING_OPTIONS): void {
        this.update(state => {
            const ui = { ...state.ui, groupingOptionType };

            return { ...state, ui };
        });
    }

    public updateDaterangeType(daterangeTypeSelected: SCHEDULE_DATERANGE_TYPES): void {
        this.update(state => {
            const currentStartDate = moment(state.ui.requestParameters.startDate);

            // For all non-day settings, we want to use the full week
            if (daterangeTypeSelected !== SCHEDULE_DATERANGE_TYPES.DAY) {
                currentStartDate.startOf('isoWeek');
            }
            
            const endDate = this.getEndDate(currentStartDate, daterangeTypeSelected);

            const requestParameters = { 
                ...state.ui.requestParameters, 
                startDate: currentStartDate.format('YYYY-MM-DD 00:00:00'),
                endDate
            };
          
            const ui = { ...state.ui, daterangeType: daterangeTypeSelected, requestParameters };

            return { ...state, ui };
        });
    }

    public updateDaypartIds(daypartIds: Array<number>): void {
        this.update(state => {
            const ui = { ...state.ui, daypartIds };

            return { ...state, ui };
        });
    }

    public updateLoadingState(loadingState: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, loadingState };

            return { ...state, ui };
        });
    }

    public updateApplyFilterOnResourceScheduleState(applyFilterOnResourceScheduleState: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, applyFilterOnResourceScheduleState };

            return { ...state, ui };
        });
    }

    public updateApplyFilterOnActivityScheduleState(applyFilterOnActivityScheduleState: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, applyFilterOnActivityScheduleState };

            return { ...state, ui };
        });
    }

    public updateRequestParameters(requestParameters: ScheduleRequestParameters): void {
        this.update(state => {
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateShowOptions(showOptions: Array<ScheduleShowOption>): void {
        this.update(state => {
            const ui = { ...state.ui, showOptions };

            return { ...state, ui  };
        });
    }

    public updateActivityShowOptions(activityShowOptions: Array<ScheduleShowOption>): void {
        this.update(state => {
            const ui = { ...state.ui, activityShowOptions };

            return { ...state, ui  };
        });
    }

    public updateScheduleStartDate(startDate: Date): void {
        // NOTE: startdate needs to depend on monday
        this.update(state => {
            const startDateMoment = moment(startDate);
            const daterangeType = state.ui.daterangeType;

            if (daterangeType !== SCHEDULE_DATERANGE_TYPES.DAY) {
                startDateMoment.startOf('isoWeek');
            }

            const requestParameters = { 
                ...state.ui.requestParameters, 
                startDate: startDateMoment.format('YYYY-MM-DD 00:00:00')
            };

            if (daterangeType !== SCHEDULE_DATERANGE_TYPES.CONFIGURABLE) {
                requestParameters.endDate = this.getEndDate(startDateMoment, daterangeType);
            }

            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedFilterSettingId: id
            };

            return { ...state, ui };
        });
    }

    public updateSavedResourceProperties(resourceProperties: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedResourceProperties: resourceProperties
            };

            return { ...state, ui };
        });
    }

    public updateSavedOrganizationUnits(organizationUnits: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedOrganizationUnits: organizationUnits
            };

            return { ...state, ui };
        });
    }

    public updateSavedBaseResourceTypes(resourceTypes: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedBaseResourceTypes: resourceTypes
            };

            return { ...state, ui };
        });
    }

    public updateSavedExtraResourceTypes(resourceTypes: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedExtraResourceTypes: resourceTypes
            };

            return { ...state, ui };
        });
    }

    public updateScheduleState(updatedState: ScheduleState['ui']): void {
        this.update(state => {
            const currentStartDate = moment(state.ui.requestParameters.startDate);

            // For all non-day settings, we want to use the full week
            if (updatedState.daterangeType !== SCHEDULE_DATERANGE_TYPES.DAY) {
                currentStartDate.startOf('isoWeek');
            }
            const endDate = this.getEndDate(currentStartDate, updatedState.daterangeType);

            const ui: ScheduleState['ui'] = {
                selectedFilterSettingId: updatedState.selectedFilterSettingId,
                requestParameters: {
                    ...updatedState.requestParameters,
                    startDate: currentStartDate.format('YYYY-MM-DD 00:00:00'),
                    endDate
                },
                activityTypeIds: updatedState.activityTypeIds,
                activityTypeIdsForActivities: updatedState.activityTypeIdsForActivities,
                sortedActivityTypeRootIdsForActivities: updatedState.sortedActivityTypeRootIdsForActivities,
                applyFilterOnResourceScheduleState: updatedState.applyFilterOnResourceScheduleState,
                applyFilterOnActivityScheduleState: updatedState.applyFilterOnActivityScheduleState,
                showOptions: updatedState.showOptions,
                activityShowOptions: updatedState.activityShowOptions,
                groupingOptionType: updatedState.groupingOptionType,
                daterangeType: updatedState.daterangeType,
                daypartIds: updatedState.daypartIds,
                allOrganizationUnitsSelectedState: updatedState.allOrganizationUnitsSelectedState,
                loadingState: state.ui.loadingState,
                loadingStateActivityDetailsDialog: state.ui.loadingStateActivityDetailsDialog,
                savedMultisortingOrder: updatedState.savedMultisortingOrder,
                savedBaseResourceTypes: updatedState.savedBaseResourceTypes,
                savedExtraResourceTypes: updatedState.savedExtraResourceTypes,
                savedOrganizationUnits: updatedState.savedOrganizationUnits,
                savedResourceProperties: updatedState.savedResourceProperties,
                filterSettingsPanelOpenState: state.ui.filterSettingsPanelOpenState,
                slotWidth: updatedState.slotWidth,
                hideEmptyRows: updatedState.hideEmptyRows,
                splitterPosition: updatedState.splitterPosition
            };

            return { ...state, ui };
        });
    }

    private getEndDate(startDate: moment.Moment, daterangeType: SCHEDULE_DATERANGE_TYPES): string {
        let newEndDate: moment.Moment;

        switch (daterangeType) {
            case SCHEDULE_DATERANGE_TYPES.DAY: {
                newEndDate = startDate.clone();
                break;
            }
            case SCHEDULE_DATERANGE_TYPES.WEEK: {
                newEndDate = startDate.clone().add(6, 'days');
                break;
            }
            case SCHEDULE_DATERANGE_TYPES.MONTH: {
                newEndDate = startDate.clone().add(1, 'months');
                break;
            }
            case SCHEDULE_DATERANGE_TYPES.CONFIGURABLE: {
                newEndDate = startDate.clone().add(2, 'months').endOf('isoWeek');
                break;
            }
            default: { // default to week setting when no daterangeType is defined
                newEndDate = startDate.clone().add(6, 'days');
            }
        }

        return newEndDate.format('YYYY-MM-DD 23:59:59');
    }
}
