import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription, first } from 'rxjs';
import { CommonModule } from '@angular/common';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { TreeSearchService } from 'src/app/shared/services/tree-search.service';
import { NestedTreeService } from 'src/app/shared/services/nested-tree.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { DEFAULT_COLUMN_OPTIONS, EntityListColumnDefinition } from '@ortec/soca-web-ui';
import { MultiselectTreePropagationService } from './services/multiselect-tree-propagation.service';
import { MultiselectTreePropagationDialogComponent } from './multiselect-tree-propagation-dialog/multiselect-tree-propagation-dialog.component';

@Component({
    standalone: true,
    selector: 'app-multiselect-tree',
    templateUrl: './multiselect-tree.component.html',
    styleUrls: ['./multiselect-tree.component.scss'],
    providers: [TreeSearchService, MultiselectTreePropagationService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatCheckboxModule,
        MatButtonModule,
        TranslateModule,
        MatOptionModule,
        MatIconModule,
        MatTreeModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,

        MultiselectTreePropagationDialogComponent
    ],
})
export class MultiselectTreeComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit  {
    @ViewChild('matSelect') public matSelect: MatSelect;
    
    @Input() public entityProperty: string;
    @Input() public showSearch: boolean = true;
    @Input() public searchFieldPlaceholder: string = 'Search';
    @Input() public multiselectTreePlaceholder: string;
    @Input() public set entities(entities: Array<any>) {
        this.internalEntities = entities != null ? entities.map(e => ({ ...e })) : [];
        if (entities?.length > 0) {
            this.entitiesAreLoadedAtLeastOnce = true;
        }
    };
    @Input() public translationNeeded: boolean = false;
    @Input() public required: boolean = true;
    @Input() public errorMessage: string = 'Field is required';
    @Input() public readonly searchProperties: Array<string> = ['displayName'];
    @Input() public disableOptions: any;
    @Input() public disableOptionsFunction: (selectedIds: any, entities: Array<any>, disableOptions: any) => Array<any>;
    @Input() private set disable(value: boolean) {
        if (value) {
            this.entitiesSelectControl.disable();
        } else {
            this.entitiesSelectControl.enable();
        }
    }
    @Input() public showSelectAll: boolean = false;
    @Input() private set preSelectedEntities(ids: Array<number>) {
        this.lastSelectedEntityIdsEmitted = (ids || []).map(id => id);
        this.selectedEntityIds = ids || [];
    };
    @Output() public readonly selectedEntityIdsChanged = new EventEmitter<Array<number>>();

    public dataSource = new MatTreeNestedDataSource<any>();

    public searchControl = new UntypedFormControl('');
    public entitiesSelectControl = new UntypedFormControl('');
    public treeControl = new NestedTreeControl<any>(node => node.children);
    public floatLabel = false;

    public internalEntities: Array<any> = [];
    public selectedEntityIds: Array<number> = [];
    public enabledEntityIds: Array<number> = [];
    public selectedEntityNames: Array<string> = [];
    public lastSelectedEntityIdsEmitted: Array<any>;
    public placeholderText: string;
    public noVisibleEntities: boolean;
    private searchValues: Array<string>;
    private entitiesAreLoadedAtLeastOnce: boolean = false;

    private readonly subscription: Subscription = new Subscription();

    constructor(
        private readonly utilsService: UtilsService,
        private readonly treeSearchService: TreeSearchService,
        private readonly nestedTreeService: NestedTreeService,
        private readonly translateService: TranslateService,
        private readonly multiselectTreePropagationService: MultiselectTreePropagationService,
        @Inject(DEFAULT_COLUMN_OPTIONS) columnDefinitionOptions: EntityListColumnDefinition
    ) { 
        if (!this.entityProperty && columnDefinitionOptions.entityProperty) {
            this.entityProperty = columnDefinitionOptions.entityProperty;
        }

        if (!this.searchProperties && columnDefinitionOptions.entityProperty) {
            this.searchValues = [columnDefinitionOptions.entityProperty];
        } else {
            this.searchValues = this.searchProperties;
        }
    }

    public ngOnInit() {
        this.subscription.add(
            this.searchControl.valueChanges.subscribe(entityFilterValue => {
                const filterValue = entityFilterValue.toLowerCase();
                this.treeSearchService.setEntityVisiblityState(filterValue, this.dataSource.data, this.searchProperties);
                this.noVisibleEntities = this.treeSearchService.noVisibleEntities;
                if (entityFilterValue === '') {
                    this.noVisibleEntities = false;
                }
            })
        );

        if (this.disableOptionsFunction) {
            this.enabledEntityIds = this.disableOptionsFunction(this.selectedEntityIds, this.internalEntities, this.disableOptions)?.map(node => node.id);
            this.setDisabledEntityState(this.internalEntities);
        }
    }

    public ngAfterViewInit(): void {
        this.subscription.add(
            this.matSelect.openedChange.subscribe((isOpened) => {
                if (isOpened) {
                    this.adjustDropdownHeight();
                } else {
                    // emit change in IDs when selection panel is closed
                    this.emitSelectedEntityIds();
                    this.onResetValue();
                }
            })
        );
    }

    public ngOnChanges(): void {
        // We have this conditions to address the scenarios where:
        // preselectedEntities come before entities
        // and when, during the process, the values of entities are changed, and they arrive as [].
        if (this.internalEntities.length > 0 || this.entitiesAreLoadedAtLeastOnce) {
            this.dataSource.data = this.internalEntities;
            this.treeControl.dataNodes = this.internalEntities;
            const allEntityIds = this.nestedTreeService.getAllChildIdsForNestedEntities(this.internalEntities);
            this.selectedEntityIds = this.selectedEntityIds.filter(id => allEntityIds.includes(id));

            this.selectedEntityNames = [];
            this.setSelectedEntityState(this.internalEntities);
            this.nestedTreeService.setChildrenSelectedEntityState(this.internalEntities, this.selectedEntityIds);
            this.setPlaceholderText();
            this.treeSearchService.setEntityVisiblityState('', this.dataSource.data, this.searchValues);
            this.noVisibleEntities = this.treeSearchService.noVisibleEntities;
            this.treeControl.expandAll();
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public adjustDropdownHeight(): void {
        const selectElement = this.matSelect._elementRef.nativeElement;
        const selectRect = selectElement.getBoundingClientRect();
        const selectTop = selectRect.top;
        // The height of the field is 48px.
        // We can't access it through ViewChild because it's a DOM element added by the Material Design library.
        const selectHeight = 48; 
        const windowHeight = window.innerHeight;
        const spaceRemaining = windowHeight - selectTop - selectHeight;
    
        const dropdownElement = this.matSelect.panel.nativeElement;
        dropdownElement.style.maxHeight = spaceRemaining + 'px';
    }

    public onSelectEntity(entity: any): void {
        entity.checked = !entity.checked;

        this.multiselectTreePropagationService.getMultiselectItemsOnStatusChange(entity, entity.checked).pipe(
            first()
        ).subscribe( multiselectTreePropagationOptions => {
            this.updateMultiselectTreeState(entity, entity.checked, multiselectTreePropagationOptions.shouldPropagate);
            if (this.disableOptionsFunction) {
                this.enabledEntityIds = this.disableOptionsFunction(this.selectedEntityIds, this.internalEntities, this.disableOptions)?.map(node => node.id);
                this.setDisabledEntityState(this.internalEntities);
            }
    
            this.setPlaceholderText();
            this.nestedTreeService.setChildrenSelectedEntityState(this.internalEntities, this.selectedEntityIds);
        });
    }

    private updateMultiselectTreeState(entity: any, state: boolean, shouldPropagate: boolean): void {
        const entityPropertyNameValue = entity[this.entityProperty];
    
        // Update the state of the current entity
        entity.checked = state;
    
        // Update the selected entity IDs and names
        if (state) {
            if (!this.selectedEntityIds.includes(entity.id)) {
                this.selectedEntityIds.push(entity.id);
            }
            if (!this.selectedEntityNames.includes(entityPropertyNameValue)) {
                this.selectedEntityNames.push(entityPropertyNameValue);
            }
        } else {
            this.selectedEntityIds = this.selectedEntityIds.filter(id => id !== entity.id);
            this.selectedEntityNames = this.selectedEntityNames.filter(name => name !== entityPropertyNameValue);
        }
    
        // Propagate the state to children if shouldPropagate is true
        if (shouldPropagate && (entity?.children?.length > 0 || entity?.children !== undefined)) {
            entity.children.forEach(child => {
                this.updateMultiselectTreeState(child, state, shouldPropagate);
            });
        }
    }

    public onResetValue(): void {
        this.searchControl.setValue('');
    }

    public hasChild(_: number, node: any): boolean {
        return !!node.children && node.children.length > 0;
    }

    public IsAllSelected(): boolean {
        const entitiesSelected = this.selectedEntityIds.length;
        const allVisibleEntities = this.nestedTreeService.getAllVisibleChildIdsForNestedEntities(this.dataSource.data).length;
  
        return entitiesSelected === allVisibleEntities && allVisibleEntities > 0;
    }

    public onToggleAll(event: MatCheckboxChange): void {
        const visibleEntities = this.dataSource.data.filter(entity => entity.visible);
        const visibleEntityIds = this.collectAllVisibleEntityIds(visibleEntities);

        if (event.checked) {
            this.setAllSelectedEntityState(visibleEntities, true);
            const newIdsToAdd = visibleEntityIds.filter(id => !this.selectedEntityIds.includes(id));
            this.selectedEntityIds.push(...newIdsToAdd);
        }
        else {
            this.setAllSelectedEntityState(visibleEntities, false);
            this.selectedEntityIds = this.selectedEntityIds.filter(id => !visibleEntityIds.includes(id));
        }

        this.setPlaceholderText();
        this.nestedTreeService.setChildrenSelectedEntityState(visibleEntities, this.selectedEntityIds);
    }

    private collectAllVisibleEntityIds(entities: Array<any>): Array<number> {
        let ids: Array<number> = [];
    
        entities.forEach(entity => {
            if (entity.visible) {
                ids.push(entity.id);
                if (entity.children) {
                    ids.push(...this.collectAllVisibleEntityIds(entity.children));
                }
            }
        });
        
        return ids;
    }

    private setPlaceholderText(): void {
        if (!this.selectedEntityIds || this.selectedEntityIds.length === 0) {
            this.placeholderText = undefined;
            this.entitiesSelectControl.setValue(undefined);
            this.floatLabel = false;

            return;
        }

        this.placeholderText = this.translateService.instant('Selected') + ' ' + this.selectedEntityIds.length + ':';
        this.entitiesSelectControl.setValue(this.selectedEntityIds);
        this.floatLabel = true;

        this.selectedEntityNames.forEach(name => {
            const translatedName = this.translateService.instant(name);
            const lastCharOfText = this.placeholderText.slice(-1);
            if (lastCharOfText === ':') {
                this.placeholderText += ' ';
            }
            else {
                this.placeholderText += ', ';
            }
            if(this.translationNeeded) {
                this.placeholderText += translatedName
            } else {
                this.placeholderText += name;
            }
            
        });
    }

    private setDisabledEntityState(entities: Array<any>): void {
        entities.forEach(entity => {
            entity.disabled = !this.enabledEntityIds.includes(entity.id);

            if (entity.children) {
                this.setDisabledEntityState(entity.children);
            }
        });
    }

    private setSelectedEntityState(entities: Array<any>): void {
        entities.forEach(entity => {
            if (this.selectedEntityIds.includes(entity.id)) {
                entity.checked = true;
                this.selectedEntityNames.push(entity[this.entityProperty]);
            } else {
                entity.checked = false;
            }

            if (entity.children) {
                this.setSelectedEntityState(entity.children);
            }
        });
    }

    private setAllSelectedEntityState(entities: Array<any>, selected: boolean): void {
        const namesSet = new Set<string>(this.selectedEntityNames);
    
        entities.forEach(entity => {
            if (!entity.visible) {
                return; // Skip if entity is not visible
            }
    
            const propertyNameValue = entity[this.entityProperty];
    
            entity.checked = selected;
    
            if (selected) {
                namesSet.add(propertyNameValue);
            } else {
                namesSet.delete(propertyNameValue);
            }
    
            if (entity.children) {
                this.setAllSelectedEntityState(entity.children, selected);
            }
        });
    
        this.selectedEntityNames = [...namesSet]; // Convert set back to array
    }

    private emitSelectedEntityIds(): void {
        if (this.utilsService.arraysEqual(this.lastSelectedEntityIdsEmitted, this.selectedEntityIds)) {
            return;
        }

        this.selectedEntityIdsChanged.emit(this.selectedEntityIds);
        this.lastSelectedEntityIdsEmitted = this.selectedEntityIds.map(id => id);
    }
}
