import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DialogService } from '@ortec/soca-web-ui/dialogs';
import { LanguageService } from 'src/app/shared/language';
import { FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';

export interface IFilterSettingConfigurationModalData {
    filterSettings$: Observable<Array<FilterSetting>>;
    selectedFilterSettingId$: Observable<number>;
}

@Component({
    selector: 'app-filter-setting-configuration-dialog',
    templateUrl: './filter-setting-configuration-dialog.component.html',
    styleUrls: ['./filter-setting-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSettingConfigurationDialogComponent implements OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @ViewChild(MatSort) public sort: MatSort;

    @Output() public readonly removeFilterSettingEvent = new EventEmitter<FilterSetting>();
    @Output() public readonly updateFilterSettingEvent = new EventEmitter<FilterSetting>();
    @Output() public readonly selectFilterSettingIdEvent = new EventEmitter<number>();

    public selectedFilterSettingId$: Observable<number>;

    public dataSource$: Observable<MatTableDataSource<FilterSetting>>;
    public dateFormat$!: Observable<string>;

    private readonly dataSourceSubject = new BehaviorSubject<MatTableDataSource<FilterSetting>>(undefined);
    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<IFilterSettingConfigurationModalData>,
        private readonly languageService: LanguageService,
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) dialogData: IFilterSettingConfigurationModalData
    ) {
        this.dataSource$ = this.dataSourceSubject.asObservable();

        this.subscription.add(
            dialogData.filterSettings$.subscribe(filterSettings => {
                const dataSource = this.updateDataSource(new MatTableDataSource(filterSettings));
                this.dataSourceSubject.next(dataSource);
            })
        );
        this.selectedFilterSettingId$ = dialogData.selectedFilterSettingId$;

        this.dateFormat$ = this.languageService.getDateFormatByCurrentLanguage();
    }

    public ngAfterViewInit() {
        const updatedDataSource = this.updateDataSource(this.dataSourceSubject.value);

        this.dataSourceSubject.next(updatedDataSource);

        this.paginator._intl.itemsPerPageLabel = this.translateService.instant('Items per page:');
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onDeleteFilterSetting(filterSetting: FilterSetting): void {
        const title = this.translateService.instant('Delete filter');
        const message = this.translateService.instant('Are you sure that you want to delete filter?', { filterSetting: filterSetting.displayName });
        const cancelButton = this.translateService.instant('Cancel');
        const deleteButton = this.translateService.instant('Delete');

        this.dialogService.showConfirmDialog(title, message, deleteButton, cancelButton).pipe(
            first(),
            map(confirm => {
                if (confirm) {
                    this.removeFilterSettingEvent.next(filterSetting);
                }
            })
        ).subscribe();
    }

    public onSelectFilterSetting(filterSetting: FilterSetting): void {
        this.selectFilterSettingIdEvent.next(filterSetting.id);
        this.onCloseModal();
    }

    public selectedFilterSetting(filterSettingId: number): Observable<boolean> {
        return this.selectedFilterSettingId$.pipe(
            map(id => filterSettingId === id)
        );
    }

    public onChangeFilterSetting(value: string, filterSetting: FilterSetting): void {
        if (value && value !== '') {
            this.updateFilterSettingEvent.emit(({ ...filterSetting, displayName: value }));
        }
    }

    private updateDataSource(dataSource: MatTableDataSource<FilterSetting>): MatTableDataSource<FilterSetting> {
        dataSource.paginator = this.paginator;
        dataSource.sort = this.sort;

        return dataSource;
    }
}
