import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UsersManagementService } from 'src/app/features/program-management/users/store/users-management.service';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { UtilsService } from '../../services/utils.service';
import { getDefaultUIState } from '../entity-ui-models';

import { User } from './user.model';
import { UserQuery } from './user.query';
import { UserStore } from './user.store';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private readonly http: HttpClient,
        protected readonly userStore: UserStore,
        protected readonly userQuery: UserQuery,
        protected readonly usersManagementService: UsersManagementService,
        private readonly utilsService: UtilsService,
        private readonly translateService: TranslateService,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getUsers(): Observable<Array<User>> {
        this.userStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<User>>('/api/Users').pipe(
            catchError((error) => {
                this.userStore.setError(error);

                return of([]);
            }),
            tap((users) => {
                this.userStore.set(users);
                this.userStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public updateUserWithUserGroupsChanged(user: User, newUser?: boolean): Observable<User> {
        if (newUser) {
            return this.saveUser({...user, selectedUserGroupsChanged: true});
        } else {
            const oldUser = this.userQuery.getUserSync(user.id);
            // Note: API uses "selectedUserGroupsChanged" to determine if it needs to save the userGroupIds
            user.selectedUserGroupsChanged = !this.utilsService.arraysEqual(oldUser.userGroupIds, user.userGroupIds);
    
            return this.saveUser(user);
        }
    }

    public saveUser(user: User): Observable<User> {
        if (user.id !== -1) {
            this.userStore.ui.update(user.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<User>('/api/Users', user).pipe(
            catchError((error) => {
                if (user.id !== -1) {
                    this.userStore.ui.update(user.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING', 
                    { 
                        entityName: this.translateService.instant('user'),
                        entityDisplayName: user.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);
                const lastCorrectVersionOfUser = this.userQuery.getUserSync(user.id);

                return throwError(() => lastCorrectVersionOfUser);
            }),
            tap((updatedUser: User) => {
                // Note: the object returned from the "put" request does 
                // not contain the userGroupIds or language, so attach it here
                updatedUser.userGroupIds = user.userGroupIds;
                updatedUser.language = user.language;
                this.userStore.upsert(updatedUser.id, updatedUser);
                if (user.id !== -1) {
                    this.userStore.ui.update(user.id, { isLoading: false, isFinishedSaving: true });
                }

                return user;
            }),
        );
    }

    public deleteUser(userId: number): Observable<void> {
        this.userStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/Users/' + userId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('user') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.userStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.userStore.remove(userId);
                }
            }),
        ); 
    }

    public setUserToPendingChanges(userId: number) {
        this.userStore.ui.update(userId, { hasPendingChanges: true, isFinishedSaving: false });
    }

    public setUserToCleanUIState(userId: number) {
        if (userId !== undefined && userId !== -1) {
            this.userStore.ui.update(userId, getDefaultUIState());
        }
    }
}
