import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import * as moment from 'moment';

import { GROUPING_OPTIONS, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';
import { SavedEntityListForSchedule, Schedule, ScheduleResourceDialog, ScheduleShowOption } from './schedule.model';

export interface ScheduleState extends EntityState<Schedule> {
    ui: {
        selectedFilterSettingId: number;
        requestParameters: ScheduleRequestParameters;
        showOptions: Array<ScheduleShowOption>;
        activityTypeIds: Array<number>;
        activityTypeCategoryIds: Array<number>;
        groupingOptionType: GROUPING_OPTIONS;
        numberOfDays: number;
        scheduleDateNavigation: {
            noOfDaysToNavigate: number;
            scheduleStartDate: Date;
            scheduleEndDate: Date;
        };
        loadingState: boolean;
        loadingStateActivityDetailsDialog: boolean;
        resourcesDialog: ScheduleResourceDialog;
        selectedActivityId: number;
        savedBaseResourceTypes: Array<SavedEntityListForSchedule>;
        savedExtraResourceTypes: Array<SavedEntityListForSchedule>;
        savedOrganizationUnits: Array<SavedEntityListForSchedule>;
        savedResourceProperties: Array<SavedEntityListForSchedule>;
    };
    calendarOptions: {
        activityDialogState: boolean;
        slotMinWidth: number;
        resourceAreaWidth: number;
    };
}

const initialState = {
    ui: {
        selectedFilterSettingId: undefined,
        requestParameters: {
            startDate: moment().utc().format('YYYY-MM-DD 00:00:00'),
            endDate: moment().utc().add(6, 'days').format('YYYY-MM-DD 23:59:59'),
            languageCode: '',
            organizationUnitIds: [],
            baseResourceTypeIds: [],
            extraResourceTypeIds: [],
            resourcePropertyIds: [-1]
        },
        showOptions: [],
        activityTypeIds: undefined,
        activityTypeCategoryIds: undefined,
        groupingOptionType: GROUPING_OPTIONS.RESOURCE_TYPE,
        numberOfDays: 7,
        scheduleDateNavigation: {
            noOfDaysToNavigate: 0,
            scheduleStartDate: new Date(),
            scheduleEndDate: new Date(),
        },
        loadingState: false,
        loadingStateActivityDetailsDialog: false,
        resourcesDialog: undefined,
        selectedActivityId: null,
        savedBaseResourceTypes: [],
        savedExtraResourceTypes: [],
        savedOrganizationUnits: [],
        savedResourceProperties: []
    },
    calendarOptions: {
        activityDialogState: false,
        slotMinWidth: 170,
        resourceAreaWidth: 220
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'schedule' })
export class ScheduleStore extends EntityStore<ScheduleState> {
    constructor() {
        super(initialState);
    }

    public updateDatesParameters(date: string): void {
        this.update(state => {
            let extraNoOfDays = 1;
            if (state.ui.numberOfDays === 14) {
                extraNoOfDays += 4;
            }

            if (state.ui.numberOfDays === 28) {
                extraNoOfDays += 10;
            }

            const dayOfTheWeekNo = new Date(date).getDay();
            if (state.ui.scheduleDateNavigation.noOfDaysToNavigate === -1 &&
                (dayOfTheWeekNo === 0 || dayOfTheWeekNo === 6) &&
                    !state.ui.showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_SAT_SUN).state) {
                const daysToIncrement = new Date(date).getDay() === 0 ? -2 : -1;
                date = moment(date).add(daysToIncrement, 'days').toString();
            }

            const noOfDays = state.ui.numberOfDays + extraNoOfDays;
            const startDate = moment.utc(date).format('YYYY-MM-DD 00:00:00').toString();
            const endDate = moment.utc(date).add(noOfDays, 'days').format('YYYY-MM-DD 23:59:59').toString();

            const requestParameters = { ...state.ui.requestParameters, startDate, endDate };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateLoadingStateActivityDetailsDialog(loadingStateActivityDetailsDialog: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, loadingStateActivityDetailsDialog };

            return { ...state, ui };
        });
    }

    public updateLanguageCodeParameter(code: string): void {
        this.update(state => {
            const languageCode = code;
            const requestParameters = { ...state.ui.requestParameters, languageCode };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateOrganizationUnitIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const organizationUnitIds = ids;
            const requestParameters = { ...state.ui.requestParameters, organizationUnitIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateBaseResourceTypeIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const baseResourceTypeIds = ids;
            const requestParameters = { ...state.ui.requestParameters, baseResourceTypeIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateExtraResourceTypeIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const extraResourceTypeIds = ids;
            const requestParameters = { ...state.ui.requestParameters, extraResourceTypeIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateResourcePropertyIdsParameter(ids: Array<number>): void {
        this.update(state => {
            const resourcePropertyIds = ids;
            const requestParameters = { ...state.ui.requestParameters, resourcePropertyIds };
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateShowOption(option: ScheduleShowOption, newState: boolean): void {
        this.update(state => {
            const showOptions = state.ui.showOptions.map(x =>
                x.value === option.value ? { value: option.value, state: newState } : x
            );
            const ui = { ...state.ui, showOptions };

            return { ...state, ui };
        });
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.update(state => {
            const activityTypeIds = ids;
            const ui = { ...state.ui, activityTypeIds };

            return { ...state, ui };
        });
    }

    public updateActivityTypeCategoryIds(ids: Array<number>): void {
        this.update(state => {
            const activityTypeCategoryIds = ids;
            const ui = { ...state.ui, activityTypeCategoryIds };

            return { ...state, ui };
        });
    }

    public updateGroupingOptionType(type: GROUPING_OPTIONS): void {
        this.update(state => {
            const groupingOptionType = type;
            const ui = { ...state.ui, groupingOptionType };

            return { ...state, ui };
        });
    }

    public updateLoadingState(loading: boolean): void {
        this.update(state => {
            const loadingState = loading;
            const ui = { ...state.ui, loadingState };

            return { ...state, ui };
        });
    }

    public updateRequestParameters(parameters: ScheduleRequestParameters): void {
        this.update(state => {
            const requestParameters = parameters;
            const ui = { ...state.ui, requestParameters };

            return { ...state, ui };
        });
    }

    public updateShowOptions(options: Array<ScheduleShowOption>): void {
        this.update(state => {
            const showOptions = options;
            const ui = { ...state.ui, showOptions };

            return { ...state, ui };
        });
    }

    public updateNumberOfDays(days: number): void {
        this.update(state => {
            const numberOfDays = days;
            const ui = { ...state.ui, numberOfDays };

            return { ...state, ui };
        });
    }

    public updateSlotMinWidth(minWidth: number): void {
        this.update(state => {
            const slotMinWidth = minWidth;
            const calendarOptions = { ...state.calendarOptions, slotMinWidth };

            return { ...state, calendarOptions };
        });
    }

    public updateResourceAreaWidth(width: number): void {
        this.update(state => {
            const resourceAreaWidth = width;
            const calendarOptions = { ...state.calendarOptions, resourceAreaWidth };

            return { ...state, calendarOptions };
        });
    }

    public updateScheduleStartDate(startDate: Date): void {
        this.update(state => {
            const scheduleDateNavigation = { ...state.ui.scheduleDateNavigation, scheduleStartDate: startDate};
            const ui = { ...state.ui, scheduleDateNavigation };

            return { ...state, ui };
        });
    }

    public updateScheduleEndDate(endDate: Date): void {
        this.update(state => {
            const scheduleDateNavigation = { ...state.ui.scheduleDateNavigation, scheduleEndDate: endDate};
            const ui = { ...state.ui, scheduleDateNavigation };

            return { ...state, ui };
        });
    }

    public updateScheduleNoOfDaysToNavigate(noOfDays: number): void {
        this.update(state => {
            const scheduleDateNavigation = { ...state.ui.scheduleDateNavigation, noOfDaysToNavigate: noOfDays};
            const ui = { ...state.ui, scheduleDateNavigation };

            return { ...state, ui };
        });
    }

    public updateResourcesDialog(resourcesDialog: ScheduleResourceDialog): void {
        this.update(state => {
            const ui = { ...state.ui, resourcesDialog };

            return { ...state, ui };
        });
    }

    public updateSelectedActivityId(activityId: number): void {
        this.update(state => {
            const selectedActivityId = +activityId;
            const ui = { ...state.ui, selectedActivityId };

            return { ...state, ui };
        });
    }

    public updateActivityDialogState(activityDialogState: boolean): void {
        this.update(state => {
            const calendarOptions = { ...state.calendarOptions, activityDialogState };

            return { ...state, calendarOptions };
        });
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedFilterSettingId: id
            };

            return { ...state, ui };
        });
    }

    public updateSavedResourceProperties(resourceProperties: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedResourceProperties: resourceProperties
            };

            return { ...state, ui };
        });
    }

    public updateSavedOrganizationUnits(organizationUnits: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedOrganizationUnits: organizationUnits
            };

            return { ...state, ui };
        });
    }

    public updateSavedBaseResourceTypes(resourceTypes: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedBaseResourceTypes: resourceTypes
            };

            return { ...state, ui };
        });
    }

    public updateSavedExtraResourceTypes(resourceTypes: Array<SavedEntityListForSchedule>): void {
        this.update(state => {
            const ui = {
                ...state.ui,
                savedExtraResourceTypes: resourceTypes
            };

            return { ...state, ui };
        });
    }

    public updateScheduleState(updatedState: ScheduleState): void {
        this.update(state => {
            const ui = {
                selectedFilterSettingId: updatedState.ui.selectedFilterSettingId,
                requestParameters: {
                    ...updatedState.ui.requestParameters,
                    startDate: state.ui.requestParameters.startDate,
                    endDate: state.ui.requestParameters.endDate
                },
                activityTypeIds: updatedState.ui.activityTypeIds,
                activityTypeCategoryIds: updatedState.ui.activityTypeCategoryIds,
                showOptions: updatedState.ui.showOptions,
                groupingOptionType: updatedState.ui.groupingOptionType,
                numberOfDays: updatedState.ui.numberOfDays,
                scheduleDateNavigation: state.ui.scheduleDateNavigation,
                loadingState: state.ui.loadingState,
                loadingStateActivityDetailsDialog: state.ui.loadingStateActivityDetailsDialog,
                resourcesDialog: state.ui.resourcesDialog,
                selectedActivityId: state.ui.selectedActivityId,
                savedBaseResourceTypes: updatedState.ui.savedBaseResourceTypes,
                savedExtraResourceTypes: updatedState.ui.savedExtraResourceTypes,
                savedOrganizationUnits: updatedState.ui.savedOrganizationUnits,
                savedResourceProperties: updatedState.ui.savedResourceProperties
            };

            const calendarOptions = {
                slotMinWidth: updatedState.calendarOptions.slotMinWidth,
                activityDialogState: updatedState.calendarOptions.activityDialogState,
                resourceAreaWidth: updatedState.calendarOptions.resourceAreaWidth
            };

            return { ...state, ui, calendarOptions };
        });
    }
}
