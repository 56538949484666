import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent, MatChipListbox } from '@angular/material/chips';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { cache } from 'src/app/core/rxjs-utils/cache.operator';
import { observeProperty } from 'src/app/core/rxjs-utils/observe-property';

export interface IFilterChipListObject {
    id: number;
    displayName: string;
    removable?: boolean;
}

@Component({
    selector: 'app-filter-chip-list',
    templateUrl: './filter-chip-list.component.html',
    styleUrls: ['./filter-chip-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterChipListComponent implements OnInit, OnDestroy {
    @ViewChild('chipListInput', { static: true }) public chipListInput: ElementRef;
    @ViewChild('chipList', { static: true }) public chipList: MatChipGrid;

    @Input() public entities: Array<any>;
    @Input() public placeholderText: string;
    @Input() public noResultsFoundText;
    @Input() public errorMessage: string;
    @Input() public required: boolean = false;
    @Input() public preselectedEntities: Array<any>;
    @Input() private set forceErrorState(state: boolean) {
        this.chipList.errorState = state;

        if (state) {
            this.chipFormControl.setValue(undefined);
        }
    }

    @Output() public readonly selectedEntityIdsChanged = new EventEmitter<Array<number>>();

    public addOnBlur = false;
    public separatorKeysCodes = [ENTER, COMMA];
    public showNoResultsFoundText = false;
    public chipFormControl = new UntypedFormControl(undefined);

    public selectedEntities$: Observable<Array<any>>;
    public preselectedEntities$: Observable<Array<any>>;
    public allEntities$: Observable<Array<any>>;
    public unselectedEntities$: Observable<Array<any>>;
    public filteredEntities$: Observable<Array<any>>;

    private readonly selectedEntitiesSubject = new BehaviorSubject<Array<any>>([]);
    private readonly subscription: Subscription = new Subscription();

    public ngOnInit(): void {
        this.selectedEntities$ = this.selectedEntitiesSubject.asObservable();
        this.allEntities$ = observeProperty(this as FilterChipListComponent, 'entities').pipe(
            cache()
        );

        this.preselectedEntities$ = observeProperty(this as FilterChipListComponent, 'preselectedEntities').pipe(
            cache()
        );

        this.subscription.add(
            this.preselectedEntities$.pipe(
                filter(preselectedEntities => !!preselectedEntities),
                map(preselectedEntities => {
                    if (preselectedEntities.length > 0) {
                        const selectedList = this.selectedEntitiesSubject.getValue();
                        preselectedEntities.sort((a, b) => selectedList.indexOf(a) - selectedList.indexOf(b));
                    }

                    this.selectedEntitiesSubject.next(preselectedEntities);
                })
            ).subscribe()
        );

        this.unselectedEntities$ = combineLatest([this.allEntities$, this.selectedEntities$]).pipe(
            map(([allEntities, selectedEntities]) => {
                return allEntities.filter(entity => !selectedEntities.some(x => x.id === entity.id));
            })
        );

        this.filteredEntities$ = combineLatest([
            this.chipFormControl.valueChanges,
            this.unselectedEntities$
        ]).pipe(
            map(([option, unselectedEntities]) => {
                let searchedEntity = '';
                if (typeof(option) === 'string') {
                    searchedEntity = option;
                }
                const filteredEntityByInput = unselectedEntities.filter(entity =>
                    entity.displayName.toLowerCase().indexOf(searchedEntity.toLowerCase()) === 0);

                this.showNoResultsFoundText = !filteredEntityByInput || filteredEntityByInput.length === 0;

                if (this.errorMessage) {
                    this.chipList.errorState = this.selectedEntitiesSubject.getValue().length === 0;
                }

                return option ? filteredEntityByInput : unselectedEntities;
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public addEntity(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            let filteredEntityByInput;
            this.unselectedEntities$.pipe(first()).subscribe(unselectedEntities => filteredEntityByInput = unselectedEntities.filter(entity =>
                entity.displayName.toLowerCase().indexOf(value.toLowerCase()) === 0));

            if (!!filteredEntityByInput && filteredEntityByInput.length > 0) {
                this.selectedEntitiesSubject.next([...this.selectedEntitiesSubject.getValue(), filteredEntityByInput[0]]);
                this.selectedEntityIdsChanged.emit(this.selectedEntitiesSubject.getValue().map(e => e.id));
            }
        }

        if (input) {
            input.value = '';
        }

        this.chipFormControl.setValue(null);
    }

    public selectedEntity(event: MatAutocompleteSelectedEvent): void {
        let selectedEntity;
        const id = event.option.value.id;

        this.unselectedEntities$.pipe(first()).subscribe(unselectedEntities =>
            selectedEntity = unselectedEntities.find(entity => entity.id === id)
        );

        this.selectedEntitiesSubject.next([...this.selectedEntitiesSubject.getValue(), selectedEntity]);
        this.selectedEntityIdsChanged.emit(this.selectedEntitiesSubject.getValue().map(e => e.id));

        this.chipListInput.nativeElement.value = '';
        this.chipFormControl.setValue(null);
    }

    public removeEntity(entity: any): void {
        const index = this.selectedEntitiesSubject.getValue().indexOf(entity);

        if (index >= 0) {
            const updatedSelectedEntities = this.selectedEntitiesSubject.getValue().filter(x => x.id !== entity.id);
            this.selectedEntitiesSubject.next(updatedSelectedEntities);
            this.selectedEntityIdsChanged.emit(this.selectedEntitiesSubject.getValue().map(e => e.id));
        }
    }

    public focusEntitiesInput(): void {
        this.chipListInput.nativeElement.blur();
        this.chipListInput.nativeElement.focus();

        this.chipFormControl.patchValue(undefined);
    }
}
