import { Injectable } from '@angular/core';
import { LocaleManager } from '@bryntum/scheduler';
// eslint-disable-next-line import/no-unassigned-import
import '../../../../../../node_modules/@bryntum/scheduler/locales/scheduler.locale.Nl.js';
import { LanguageService } from 'src/app/shared/language';

@Injectable({
    providedIn: 'root'
})
export class ScheduleLanguageService {
    constructor(
        private readonly languageService: LanguageService,
    ) { }

    public setLanguage(): void {
        if (this.languageService.getCurrentLanguage() === 'nl') {
            LocaleManager.registerLocale('Nl', {
                desc: 'Dutch',
                locale: {
                    DateHelper: {
                        locale: 'nl-NL'
                    }
                }
            });
            LocaleManager.locale = 'Nl';
        }
    }
}
