import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceRequestParameters } from 'src/app/shared/stores/resource-store/resource.model';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { Scenario, ScenarioType } from 'src/app/shared/stores/scenario-store/scenario.model';
import { ScenarioQuery } from 'src/app/shared/stores/scenario-store/scenario.query';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { CountersExportConfiguration } from '../../../counters-export/counters-export.component';
import { CountersFiltersService } from '../../counters-helpers/counters-filters.service';
import { COUNTERS_COUNTER_TYPE, COUNTERS_GROUPING_OPTIONS, COUNTERS_TOTALS_OPTIONS } from '../../counters-helpers/enums';
import { CountersFilterSetting, CountersRequestFilters } from './counters-request-parameters.model';
import { Counter } from './counters.model';
import { CountersState, CountersStore } from './counters.store';

@Injectable({
    providedIn: 'root',
})
export class CountersQuery extends QueryEntity<CountersState> {
    constructor(
        protected store: CountersStore,
        protected utilsService: UtilsService,
        protected userInfoQuery: UserInfoQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected activityTypeQuery: ActivityTypeQuery,
        protected activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        protected resourceTypeQuery: ResourceTypeQuery,
        protected skillQuery: SkillQuery,
        protected scenarioQuery: ScenarioQuery,
        protected filterSettingQuery: FilterSettingQuery,
        protected resourceQuery: ResourceQuery,
        protected counterFilterService: CountersFiltersService
    ) {
        super(store);
    }

    public getRequestParametersSync(): HttpParams {
        const filterSettings = this.getValue().ui.requestFilters;
        let params = this.utilsService.objectToHttpParam(filterSettings);
        params = params.append('includeRootActivityTypeDetails', 'true');
        params = params.append('publishedOnly', 'false');

        return params;
    }

    public getRequestParametersForScheduleSync(): HttpParams {
        let params = this.getRequestParametersSync();
        const counterTypeOptions = this.getCounterTypeOptionsSync();
        if (counterTypeOptions.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE)) {
            params = params.append('includeWorkloadHours', 'true');
        }

        return params;
    }

    public getRequestParametersForScenarioSync(): HttpParams {
        let params = this.getRequestParametersSync();
        const counterTypeOptions = this.getCounterTypeOptionsSync();
        if (counterTypeOptions.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO)) {
            params = params.append('includeWorkloadHours', 'true');
        }

        return params;
    }

    public getRequestParametersForResourceSync(): ResourceRequestParameters {
        const filters = this.getRequestFiltersSync();
        const requestFilters: ResourceRequestParameters = {
            organizationUnitIds: filters.resourceOrganizationUnitIds,
            resourceTypeIds: filters.resourceTypeIds,
            includeSkillMemberships: true,
            includeOrganizationUnitMemberships: true,
            includeResourceTypeMemberships: true
        };

        return requestFilters;
    }

    public getCountersSync(): Array<Counter> {
        const counters = Object.values(this.getValue().entities);
        const selectedActivityTypeIds = this.getSelectedActivityTypeIdsSync();

        return counters.filter(counter => selectedActivityTypeIds.includes(counter.rootActivityType.id));
    }

    public getTotalsOptionsSync(): Array<COUNTERS_TOTALS_OPTIONS> {
        return this.getValue().ui.totalsOptions;
    }

    public getGroupingOptionsSync(): Array<COUNTERS_GROUPING_OPTIONS> {
        return this.getValue().ui.groupingOptions;
    }

    public getRequestFiltersSync(): CountersRequestFilters {
        return this.getValue().ui.requestFilters;
    }

    public getCounterTypeOptionsSync(): Array<COUNTERS_COUNTER_TYPE> {
        return this.getValue().ui.counterTypeOptions;
    }

    public getOnlyScheduleCounterTypesSelected(): boolean {
        const counterTypes = this.getCounterTypeOptionsSync();

        return !(counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO) ||
        counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO)) &&
        (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE) ||
        counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE));
    }

    public getOnlyScenarioCounterTypesSelected(): boolean {
        const counterTypes = this.getCounterTypeOptionsSync();

        return (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO) ||
        counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO)) &&
        !(counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE) ||
        counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE));
    }

    public getSelectedFilterSettingIdSync(): number {
        return this.getValue().ui.selectedFilterSettingId;
    }

    public getSelectedActivityTypeCategoriesSync(): Array<ActivityTypeCategory> {
        const activityTypeCategories = this.activityTypeCategoryQuery.getActivityTypeCategoriesSync();
        const selectedActivityTypeCategories = this.getSelectedActivityTypeCategoryIdsSync();

        return activityTypeCategories.filter(atc => (selectedActivityTypeCategories || []).includes(atc.id));
    }

    public getUIState(): Observable<any> {
        return this.select(state => state.ui);
    }

    public getSelectedActivityTypeCategoryIdsSync(): Array<number> | undefined {
        return this.getValue().ui.activityTypeCategoryIds;
    }

    public getSelectedActivityTypeIdsSync(): Array<number> | undefined {
        return this.getValue().ui.activityTypeIds;
    }

    public getSelectedOrganizationUnitsIdsForActivityTypesSync(): Array<number> | undefined {
        return this.getValue().ui.organizationUnitForActivityIds;
    }

    public getSelectedEndDateSync(): string {
        return this.getValue().ui.end;
    }

    public getSelectedStartDateSync(): string {
        return this.getValue().ui.start;
    }

    public getSelectedOrganizationUnitIdsSync(): Array<number> {
        return this.getValue().ui.requestFilters.resourceOrganizationUnitIds;
    }

    public getSelectedOrganizationUnitsSync(): Array<OrganizationUnit> {
        const organizationUnits = this.organizationUnitQuery.getOrganizationUnitsSync();
        const selectedOrganizationUnitIds = this.getSelectedOrganizationUnitIdsSync();

        return organizationUnits.filter(ou => (selectedOrganizationUnitIds || []).includes(ou.id));
    }

    public getSelectedResourceTypeIdsSync(): Array<number> | undefined {
        return this.getValue().ui.requestFilters.resourceTypeIds;
    }

    public getSelectedSkillIdsSync(): Array<number> | undefined {
        return this.getValue().ui.requestFilters.skillIds;
    }

    public getSelectedSkillsSync(): Array<Skill> {
        const skills = this.skillQuery.getSkillsSync();
        const selectedSkills = this.getSelectedSkillIdsSync();

        return skills.filter(skill => (selectedSkills || []).includes(skill.id));
    }

    public getFilteredSkillsSync(): Array<Skill> {
        const skills = this.skillQuery.getSkillsSync();
        const selectedOrganizationUnitIds = this.getSelectedOrganizationUnitIdsSync();
        const selecedResourceTypeIds = this.getSelectedResourceTypeIdsSync();

        return skills.filter(skill =>
            skill.validOrganizationUnitIds.some(id => selectedOrganizationUnitIds?.includes(id)) &&
            skill.validResourceTypeIds.some(id => selecedResourceTypeIds?.includes(id))
        );
    }

    public getSelectedResourceTypesSync(): Array<ResourceType> {
        const selectedResourceTypeIds = this.getSelectedResourceTypeIdsSync();
        const allResourceTypes = this.resourceTypeQuery.getResourceTypesSync();

        return allResourceTypes.filter(type => (selectedResourceTypeIds || []).includes(type.id));
    }

    public getSelectedActivityTypesSync(): Array<ActivityType> {
        const activityTypes = this.activityTypeQuery.getActivityTypesSync();
        const selectedActivityTypeIds = this.getSelectedActivityTypeIdsSync();

        return activityTypes.filter(at => (selectedActivityTypeIds || []).includes(at.id));
    }

    public getSelectedStartDate(): Observable<string> {
        return this.select(state => state.ui.start);
    }

    public getSelectedEndDate(): Observable<string> {
        return this.select(state => state.ui.end);
    }

    public getSelectedFilterSettingId(): Observable<number> {
        return this.select(state => state.ui.selectedFilterSettingId);
    }

    public getSelectedActivityTypeCategoryIds(): Observable<Array<number>> {
        return this.select(state => state.ui.activityTypeCategoryIds);
    }

    public getSelectedActivityTypeIds(): Observable<Array<number>> {
        return this.select(state => state.ui.activityTypeIds);
    }

    public getSelectedTotals(): Observable<Array<COUNTERS_TOTALS_OPTIONS>> {
        return this.select(state => state.ui.totalsOptions);
    }

    public getSelectedTotalsByIds(): Observable<Array<number>> {
        return this.select(state => this.counterFilterService.transformEnumToIds(state.ui.totalsOptions, COUNTERS_TOTALS_OPTIONS));
    }

    public getSelectedTotalsSync(): Array<COUNTERS_TOTALS_OPTIONS> {
        return this.getValue().ui.totalsOptions;
    }

    public getSelectedCounterTypes(): Observable<Array<COUNTERS_COUNTER_TYPE>> {
        return this.select(state => state.ui.counterTypeOptions);
    }

    public getSelectedCounterTypesByIds(): Observable<Array<number>> {
        return this.select(state => this.counterFilterService.transformEnumToIds(state.ui.counterTypeOptions, COUNTERS_COUNTER_TYPE));
    }

    public getActivityTypeDetailsState(): Observable<boolean> {
        return this.select(state => state.ui.includeActivityTypeDetails);
    }

    public getActivityTypeDetailsStateSync(): boolean {
        return this.getValue().ui.includeActivityTypeDetails;
    }

    public getSelectedGroupingOptions(): Observable<Array<COUNTERS_GROUPING_OPTIONS>> {
        return this.select(state => state.ui.groupingOptions);
    }

    public getSelectedGroupingOptionsSync(): Array<COUNTERS_GROUPING_OPTIONS> {
        return this.getValue().ui.groupingOptions;
    }

    public getSelectedScenarioId(): Observable<number> {
        return this.select(state => state.ui.scenarioId);
    }

    public getAllOrganizationUnitsSelectedState(): Observable<boolean> {
        return this.select(state => state.ui.allOrganizationUnitsSelectedState);
    }

    public getAllResourceOrganizationUnitsSelectedState(): Observable<boolean> {
        return this.select(state => state.ui.requestFilters.allResourceOrganizationUnitsSelectedState);
    }

    public getAllActivityTypeIdsSelectedState(): Observable<boolean> {
        return this.select(state => state.ui.allActivityTypeIdsSelectedState);
    }

    public getAllOrganizationUnitsSelectedStateSync(): boolean {
        return this.getValue().ui.allOrganizationUnitsSelectedState;
    }

    public getAllResourceOrganizationUnitsSelectedStateSync(): boolean {
        return this.getValue().ui.requestFilters.allResourceOrganizationUnitsSelectedState;
    }

    public getSelectedOrganizationUnitForActivityIdsSync(): Array<number> {
        return this.getValue().ui.organizationUnitForActivityIds;
    }

    public getSelectedResourceOrganizationUnitIdsIdsSync(): Array<number> {
        return this.getValue().ui.requestFilters.resourceOrganizationUnitIds;
    }

    public allOrganizationUnitsSelectedState(): boolean {
        return this.organizationUnitQuery.getOrganizationUnitsSync().length === this.getSelectedOrganizationUnitForActivityIdsSync().length;
    }

    public allResourceOrganizationUnitsSelectedState(): boolean {
        return this.organizationUnitQuery.getOrganizationUnitsSync().length === this.getSelectedResourceOrganizationUnitIdsIdsSync().length;
    }

    public getSelectedOrganizationUnitIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestFilters.resourceOrganizationUnitIds);
    }

    public getSelectedOrganizationUnitIdsForActivity(): Observable<Array<number>> {
        return this.select(state => state.ui.organizationUnitForActivityIds);
    }

    public allActivityTypeIdsSelectedState(): boolean {
        return this.activityTypeQuery.getActivityTypesSync().length === this.getSelectedActivityTypeIdsSync().length;
    }

    public getSelectedScenarioIdSync(): number {
        return this.getValue().ui.scenarioId;
    }

    public getSelectedResourceTypeIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestFilters.resourceTypeIds);
    }

    public getSelectedSkillIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestFilters.skillIds);
    }

    public getSelectedOrganizationUnits(): Observable<Array<OrganizationUnit>> {
        return combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.getSelectedOrganizationUnitIds(),
            this.getAllResourceOrganizationUnitsSelectedState()
        ]).pipe(
            filter(([orgUnits, ids, _]) => orgUnits !== undefined && orgUnits.length > 0 && !!ids ),
            map(([orgUnits, ids, isAllSelected]) => {
                if (isAllSelected) {

                    return orgUnits;
                }

                return orgUnits.filter(unit => ids.includes(unit.id));
            })
        );
    }

    public getSelectedOrganizationUnitsForActivity(): Observable<Array<OrganizationUnit>> {
        return combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.getSelectedOrganizationUnitIdsForActivity(),
            this.getAllOrganizationUnitsSelectedState()
        ]).pipe(
            filter(([orgUnits, ids, _]) => orgUnits !== undefined && orgUnits.length > 0 && !!ids ),
            map(([orgUnits, ids, isAllSelected]) => {
                if (isAllSelected) {

                    return orgUnits;
                }

                return orgUnits.filter(unit => ids.includes(unit.id));
            })
        );
    }

    public getActivityTypeSelectedIds(): Observable<Array<number>> {
        return combineLatest([
            this.activityTypeQuery.getActivityTypes(),
            this.getSelectedActivityTypeIds(),
            this.getAllActivityTypeIdsSelectedState()
        ]).pipe(
            filter(([activityTypes, _]) => activityTypes !== undefined && activityTypes.length > 0),
            map(([activityTypes, ids, isAllSelected]) => {
                if (isAllSelected) {

                    return activityTypes.map(actType => actType.id);
                }
    
                return ids ? activityTypes.filter(actType => ids.includes(actType.id)).map(actType => actType.id) : activityTypes.map(actType => actType.id);
            })
        );
    }

    public getSelectedSkills(): Observable<Array<Skill>> {
        return combineLatest([
            this.skillQuery.getSkills(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            filter(([_, filteredOrganizationIds]) => !!filteredOrganizationIds),
            map(([skills, filteredOrganizationIds]) => {
                return skills.filter(skill => skill.validOrganizationUnitIds.some(id => filteredOrganizationIds.includes(id)));
            })
        );
    }

    public getSelectedScenario(): Observable<Scenario> {
        return combineLatest([
            this.scenarioQuery.getScenariosByType(ScenarioType.Draft),
            this.getSelectedScenarioId()
        ]).pipe(
            map(([scenarios, scenarioId]) => scenarios.find(scenario => scenario.id === scenarioId))
        );
    }

    public getSelectedScenarioSync(): Scenario {
        const selectedScenarioId = this.getSelectedScenarioIdSync();

        return this.scenarioQuery.getScenarioByIdSync(selectedScenarioId);
    }

    public getCountersFilterSettingFromStateSync(): CountersFilterSetting {
        const currentSettings: CountersFilterSetting = {
            activityTypeCategoryIds: this.getValue().ui.activityTypeCategoryIds,
            activityTypeIds: this.getValue().ui.activityTypeIds,
            organizationUnitForActivityIds: this.getValue().ui.organizationUnitForActivityIds,
            groupingOptions: this.getValue().ui.groupingOptions,
            totalsOptions: this.getValue().ui.totalsOptions,
            counterTypeOptions: this.getValue().ui.counterTypeOptions,
            requestFilters: this.getValue().ui.requestFilters,
            allOrganizationUnitsSelectedState: this.getValue().ui.allOrganizationUnitsSelectedState,
            allActivityTypeIdsSelectedState: this.getValue().ui.allActivityTypeIdsSelectedState,
            includeActivityTypeDetails: this.getValue().ui.includeActivityTypeDetails
        };

        if (this.getValue().sliderValues.nameColumnWidth) {
            currentSettings.nameColumnWidth = this.getValue().sliderValues.nameColumnWidth;
        }
        if (this.getValue().sliderValues.dataColumnWidth) {
            currentSettings.dataColumnWidth = this.getValue().sliderValues.dataColumnWidth;
        }
        if (this.getValue().sliderValues.totalTableWidth) {
            currentSettings.totalTableWidth = this.getValue().sliderValues.totalTableWidth;
        }

        return currentSettings;
    }

    public getNewFilterSetting(settingName: string): FilterSetting {
        const settings: CountersFilterSetting = this.getCountersFilterSettingFromStateSync();

        return {
            userId: this.userInfoQuery.getUserIdSync(),
            creationDate: moment(new Date()).toISOString(),
            settings: JSON.stringify(settings),
            settingType: FILTER_SETTING_TYPE.COUNTER_OVERVIEW_FILTER_SETTING,
            displayName: settingName
        };
    }

    public getUpdatedFilterSetting(): FilterSetting {
        const newSettings: CountersFilterSetting = this.getCountersFilterSettingFromStateSync();
        const currentFilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        return ({ ...currentFilterSetting, settings: JSON.stringify(newSettings) });
    }

    public getCountersFiltersValidity(): Observable<boolean> {
        return this.select(state => {
            const ui = state.ui;

            return !!ui.activityTypeCategoryIds && ui.activityTypeCategoryIds.length > 0 &&
                !!ui.totalsOptions && ui.totalsOptions.length > 0 &&
                !!ui.counterTypeOptions && ui.counterTypeOptions.length > 0 &&
                !!ui.groupingOptions && ui.groupingOptions.length > 0 &&
                !!ui.start &&
                !!ui.end &&
                ui.validIntervalDates &&
                !!ui.activityTypeIds && ui.activityTypeIds.length &&
                !!ui.requestFilters.resourceOrganizationUnitIds && ui.requestFilters.resourceOrganizationUnitIds.length > 0 &&
                !!ui.requestFilters.resourceTypeIds && ui.requestFilters.resourceTypeIds.length > 0 &&
                !!ui.counterTypeOptions && !ui.counterTypeOptions.some(counter =>
                (counter === COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO ||
                    counter === COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO) &&
                ui.scenarioId === undefined);
        });
    }

    public getScenarioValidity(): Observable<boolean> {
        return this.select(state => {
            const ui = state.ui;

            return this.countersScenarioValidity(ui.scenarioId, ui.counterTypeOptions);
        });
    }

    public getFilterEntitiesLoadingState(): Observable<boolean> {
        return combineLatest([
            this.filterSettingQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.scenarioQuery.getEntitiesLoadingState(),
            this.skillQuery.getEntitiesLoadingState(),
            this.activityTypeQuery.getEntitiesLoadingState(),
            this.activityTypeCategoryQuery.getEntitiesLoadingState(),
        ]).pipe(
            map(([filterSettings, units, resourceTypes, scenarios, skills, activityTypes, activityTypeCategories]) =>
                filterSettings || units || resourceTypes || scenarios ||
                skills || activityTypes || activityTypeCategories
            )
        );
    }

    public getFilterEntitiesLoadingStateSync(): boolean {
        const filterSettingsLoaded = this.filterSettingQuery.getEntitiesLoadingStateSync();
        const organizationUnitsLoaded = this.organizationUnitQuery.getEntitiesLoadingStateSync();
        const resourceTypeLoaded = this.resourceTypeQuery.getEntitiesLoadingStateSync();
        const scenarioLoaded = this.scenarioQuery.getEntitiesLoadingStateSync();
        const skillsLoaded = this.skillQuery.getEntitiesLoadingStateSync();
        const activityTypesLoaded = this.activityTypeQuery.getEntitiesLoadingStateSync();
        const activityTypeCategoriesLoaded = this.activityTypeCategoryQuery.getEntitiesLoadingStateSync();
    
        return filterSettingsLoaded && organizationUnitsLoaded && resourceTypeLoaded && scenarioLoaded &&
        skillsLoaded && activityTypesLoaded && activityTypeCategoriesLoaded;
    }

    public countersScenarioValidity(scenarioId: number, counterTypeOptions: Array<COUNTERS_COUNTER_TYPE>): boolean {
        return counterTypeOptions && !counterTypeOptions.some(counter =>
            (counter === COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO ||
                counter === COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO) &&
                scenarioId === undefined);
    }

    public getCountersExportConfiguration(): CountersExportConfiguration {
        const config: CountersExportConfiguration = {
            start: this.getSelectedStartDateSync(),
            end: this.getSelectedEndDateSync(),
            resourceOrganizationUnitIds: this.getSelectedOrganizationUnitIdsSync(),
            organizationUnitForActivityIds: this.getSelectedOrganizationUnitForActivityIdsSync(),
            resourceTypeIds: this.getSelectedResourceTypeIdsSync(),
            activityTypeIds: this.getSelectedActivityTypeIdsSync(),
            activityTypeCategoryIds: this.getSelectedActivityTypeCategoryIdsSync(),
        };

        return config;
    }

    public getErrorState(): Observable<boolean> {
        return this.select(state => state.ui.error);
    }

    public currentFilterSettingChangesUnsavedSync(): boolean {
        const selectedFilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        if (!selectedFilterSetting) {
            return true;
        }

        const selectedSettings: CountersFilterSetting = JSON.parse(selectedFilterSetting.settings);
        const settingsFromState = this.getCountersFilterSettingFromStateSync();

        // to determine if there are pending changes, we don't check for column width values, as they are autosaved
        return !this.utilsService.arraysEqual(selectedSettings.activityTypeIds, settingsFromState.activityTypeIds) ||
        !this.utilsService.arraysEqual(selectedSettings.organizationUnitForActivityIds, settingsFromState.organizationUnitForActivityIds) ||
        !this.utilsService.arraysEqual(selectedSettings.activityTypeCategoryIds, settingsFromState.activityTypeCategoryIds) ||
        !this.utilsService.arraysEqual(selectedSettings.counterTypeOptions, settingsFromState.counterTypeOptions) ||
        !this.utilsService.arraysEqual(selectedSettings.totalsOptions, settingsFromState.totalsOptions) ||
        selectedSettings.includeActivityTypeDetails !== settingsFromState.includeActivityTypeDetails ||
        !this.utilsService.arraysEqual(selectedSettings.requestFilters.resourceTypeIds, settingsFromState.requestFilters.resourceTypeIds) ||
        !this.utilsService.arraysEqual(selectedSettings.requestFilters.skillIds, settingsFromState.requestFilters.skillIds) ||
        !this.utilsService.arraysEqual(selectedSettings.requestFilters.resourceOrganizationUnitIds,
            settingsFromState.requestFilters.resourceOrganizationUnitIds) ||
        !(selectedSettings.groupingOptions?.length === settingsFromState.groupingOptions?.length &&
        Object.keys(selectedSettings.groupingOptions).every(option =>
            selectedSettings.groupingOptions[option] === settingsFromState.groupingOptions[option]
        ));
    }
}
