/* eslint-disable @typescript-eslint/naming-convention */
export enum SHOW_OPTIONS {
    SHOW_ACTIVE_PANEL = 'SHOW_ACTIVE_PANEL',
    SHOW_ONLY_ME = 'SHOW_ONLY_ME',
    SHOW_SAT_SUN = 'SHOW_SAT_SUN',
    SHOW_FILL = 'SHOW_FILL',
    SHOW_ROOT = 'SHOW_ROOT',
    SHOW_ACTIVITY_TIME = 'SHOW_ACTIVITY_TIME',
    SHOW_ACTIVITY_NAME = 'SHOW_ACTIVITY_NAME',
    SHOW_ACTIVITY_SHORT_NAME = 'SHOW_ACTIVITY_SHORT_NAME',
    SHOW_ACTIVITY_TIME_LEAF = 'SHOW_ACTIVITY_TIME_LEAF',
    SHOW_ACTIVITY_NAME_LEAF = 'SHOW_ACTIVITY_NAME_LEAF',
    SHOW_ACTIVITY_SHORT_NAME_LEAF = 'SHOW_ACTIVITY_SHORT_NAME_LEAF',
}

export enum GROUPING_OPTIONS {
    RESOURCE_TYPE = 'Resource type',
    ORGANIZATION_UNIT = 'Organization unit',
    NO_GROUPING = 'No grouping',
    DAY_PART = 'Daypart'
}

export const enum SCHEDULE_STORAGE_KEYS {
    FILTERS_PANEL_STATE = 'scheduleFiltersPanelState',
    FILTER_SETTING_ID = 'scheduleFilterSettingId',
}

export enum SCHEDULE_DATERANGE_TYPES {
    DAY,
    WEEK,
    MONTH,
    CONFIGURABLE
}

export enum SCHEDULE_NAVIGATION_OPTIONS {
    TODAY,
    DAY,
    WEEK,
    MONTH,
}