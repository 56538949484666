<div class="bol-modal__header">
    <span class="modal-title">{{ 'Select resources to hide' | translate }}</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <i class="material-icons">close</i>
    </div>
</div>
  
<mat-dialog-content>  
    <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
        <mat-label>{{ 'Resource types' | translate }}</mat-label>
        <mat-select
            required
            (openedChange)="openedResourceTypeSelect($event)"
            data-cy="resourceTypesField"
            [formControl]="resourceTypeControl">
            <mat-option
                *ngFor="let resourceType of resourceTypes$ | async"
                [value]="resourceType.id">
                {{ resourceType.displayName }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="resourceTypeControl.invalid" translate>Field is required</mat-error>
    </mat-form-field>

    <div class="resources-checkbox">
        <mat-checkbox
            class="bol-checkbox"
            [checked]='showOnlySelectedState$ | async'
            data-cy="showOnlySelectedResourceDialog"
            (change)="showOnlyResourcesSelected($event)">
            <span>{{ 'Show only selected' | translate }}</span>
        </mat-checkbox>
    </div>

    <app-multiselect-table
        searchFieldPlaceholder="Resource filter"
        [entities]="filteredResources$ | async"
        [columnDefinition]="columnDefinition"
        [searchProperties]="searchProperties"
        [hideSearch]="false"
        (selectedEntityIdsChanged)="updateResourcesStates($event)"
    ></app-multiselect-table>

    <div class="resource-length-info-message" data-cy="resourceLengthInfoMessage">
        {{ selectedResourceIds }} {{ RESOURCE_SELECTED_MESSAGE | translate }} {{ displayNameOfSelectedResourceType }} {{ SELECTED_TO_HIDE | translate }}
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        class="bol-button-solid bol-positive"
        data-cy="confirmResourceDialogButton"
        (click)="onConfirm()">
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>