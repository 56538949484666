import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Holiday } from './holiday.model';
import { HolidayQuery } from './holiday.query';
import { HolidayStore } from './holiday.store';
import { ErrorDialogService } from '../../services/error-dialog.service';

@Injectable({
    providedIn: 'root'
})
export class HolidayService {

    constructor(
        protected readonly holidayStore: HolidayStore,
        protected readonly holidayQuery: HolidayQuery,
        private readonly errorDialogService: ErrorDialogService,
        private readonly http: HttpClient,
    ) { }

    public getHolidaysForYear(year: number): Observable<Array<Holiday>> {
        this.holidayStore.updateCurrentYear(year);

        if (this.holidayQuery.isYearLoaded(year)) { 
            return of(this.holidayQuery.getHolidaysForYearSync(year));
        }

        this.holidayStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<Holiday>>(`/api/Holidays/${year}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((holidays) => {
                this.holidayStore.updateLoadedYear({yearLoad: year, holidays: holidays, loaded: true});
                this.holidayStore.updateEntitiesLoadingState(false);

                return holidays;
            }),
        );
    }

    public updateCurrentYear(year: number): void {
        this.holidayStore.updateCurrentYear(year);
    }

    public saveHoliday(holiday: Holiday): Observable<Holiday> {
        return this.http.put<Holiday>('/api/Holidays', holiday).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((updatedHoliday: Holiday) => {
                if (holiday.id !== -1) {
                    this.holidayStore.updateHolidayInYear(holiday);
                } else {
                    this.holidayStore.saveHolidayInYear({...holiday, id: updatedHoliday.id});
                }

                return updatedHoliday;
            }),
        );
    }

    public deleteHoliday(holidayId: number): Observable<void> {
        return this.http.delete<void>('/api/Holidays'  + `/${holidayId}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            map((response) => {
                if (response === null) { // no error was thrown
                    this.holidayStore.deleteHolidayInYear(holidayId);
                }
            }),
        );
    }
}