import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { Configuration } from './configuration.model';

export interface ConfigurationState {
    configuration: Configuration;
}

const initialState = {};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'configuration' })
export class ConfigurationStore extends Store<ConfigurationState> {
    constructor() {
        super(initialState);
    }

    public setConfiguration(configuration: Configuration): void {
        this.update({ configuration });
    }
}
