/* eslint-disable radix */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { TranslateService } from '@ngx-translate/core';
import { ActivityChangeTypeForTable, CHANGE_ACTION_TYPES, CHANGE_FILTER_TYPES, ORIGIN_TYPES } from 'src/app/features/planboard/changes/store/activity-change.model';

import { FilterGridColumn } from './components/changes-table/changes-table.component';
import { ChangeTableFilteringItem } from './changes-helpers/changes-helpers.service';
import { ActivityChangeQuery } from './store/activity-change.query';

const actionType = {
    1: CHANGE_ACTION_TYPES.ADD,
    2: CHANGE_ACTION_TYPES.ASSIGN,
    3: CHANGE_ACTION_TYPES.CHANGE,
    4: CHANGE_ACTION_TYPES.DELETE,
    5: CHANGE_ACTION_TYPES.UNASSIGN,
    6: CHANGE_ACTION_TYPES.LOCKED,
    7: CHANGE_ACTION_TYPES.UNLOCKED,
    8: CHANGE_ACTION_TYPES.NOT_REQUIRED,
    9: CHANGE_ACTION_TYPES.CANCEL,
};

const originType = {
    0: ORIGIN_TYPES.USER,
    //1: ORIGIN_TYPES.OWS,
    2: ORIGIN_TYPES.MULTISELECT
};

export function getActivityChangesGridColumns(translateService: TranslateService, activityChangeQuery: ActivityChangeQuery): Array<FilterGridColumn> {
    return [
        {
            columnDef: 'timestamp',
            header: 'Action date and time',
            filterType: CHANGE_FILTER_TYPES.SEARCH,
            cell: (change: ActivityChangeTypeForTable) => `${change.timestamp}`,
        },
        {
            columnDef: 'changeType',
            header: 'Action type',
            filterType: CHANGE_FILTER_TYPES.MULTISELECT,
            filterOptions: {name: 'Action type filter', entities: transformActionTypeObject(), selectedIds$: activityChangeQuery.getSelectedActionTypes()},
            cell: (change: ActivityChangeTypeForTable) => translateService.instant(change.changeType)
        },
        {
            columnDef: 'activityChangeUserType',
            header: 'Origin',
            filterType: CHANGE_FILTER_TYPES.MULTISELECT,
            filterOptions: {name: 'Origin filter', entities: transformOriginTypeObject(), selectedIds$: activityChangeQuery.getSelectedOrginsState()},
            cell: (change: ActivityChangeTypeForTable) => translateService.instant(change.activityChangeUserType),
        },
        {
            columnDef: 'userDisplayName',
            header: 'User',
            filterType: CHANGE_FILTER_TYPES.SEARCH,
            cell: (change: ActivityChangeTypeForTable) => `${change.userDisplayName}`
        },
        {
            columnDef: 'activityTypeDisplayName',
            header: 'Activity type',
            stylingProperty: 'activityStyling',
            filterType: CHANGE_FILTER_TYPES.SEARCH,
            cell: (change: ActivityChangeTypeForTable) => `${change.activityTypeDisplayName}`
        },
        {
            columnDef: 'activityStart',
            header: 'Activity date and start time',
            filterType: CHANGE_FILTER_TYPES.SEARCH,
            cell: (change: ActivityChangeTypeForTable) => `${change.activityStart}`
        },
        {
            columnDef: 'changeProperty',
            header: 'Property',
            filterType: CHANGE_FILTER_TYPES.SEARCH,
            cell: (change: ActivityChangeTypeForTable) => `${change.changeProperty}`
        },
        {
            columnDef: 'changeValue',
            header: 'Value',
            filterType: CHANGE_FILTER_TYPES.SEARCH,
            cell: (change: ActivityChangeTypeForTable) => `${change.changeValue}`
        }
    ];
}

function transformActionTypeObject(): Array<ChangeTableFilteringItem> {
    return Object.entries(actionType).map(([id, displayName]) => {
        return { id: parseInt(id), displayName };
    });
}

function transformOriginTypeObject(): Array<ChangeTableFilteringItem> {
    return Object.entries(originType).map(([id, displayName]) => {
        return { id: parseInt(id), displayName };
    });
}