import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-formly-entity-list-dropdown',
    templateUrl: './formly-entity-list-dropdown.component.html',
    styleUrls: ['./formly-entity-list-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyEntityListDropdownComponent extends FieldType implements OnInit {
    public searchControl = new UntypedFormControl('');
    public filteredEntities$!: Observable<any>;

    public ngOnInit(): void {
        this.filteredEntities$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            map(entityFilterValue => {
                const filterValue = entityFilterValue.toLowerCase();

                return(this.field.props.entities || [])
                    .filter(option => option.displayName.toLowerCase().includes(filterValue))
                    .sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
            })
        );
    }

    public onResetValue(): void {
        this.searchControl.setValue('');
    }
}