import { Injectable } from '@angular/core';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';

import { ResourcePropertiesManagementStore } from './resource-properties-management.store';

@Injectable({
    providedIn: 'root'
})
export class ResourcePropertiesManagementService {
    constructor(
        protected resourcepropertiesManagementStore: ResourcePropertiesManagementStore
    ) { }

    public updateSelectedResourcePropertyId(id: number): void {
        this.resourcepropertiesManagementStore.updateSelectedResourcePropertyId(id);
    }

    public updateManageMode(mode: MANAGE_MODE): void {
        this.resourcepropertiesManagementStore.updateManageMode(mode);
    }

    public setStateForNewResourceProperty(resourcePropertyId: number): void {
        this.resourcepropertiesManagementStore.setStateForNewResourceProperty(resourcePropertyId);
    }
}