<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column" >
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true">
                    <app-filter-tree-single-select 
                        [entities]="organizationsForFiltering$ | async"
                        [labelText]="'Organization unit'"
                        [selectedEntityId] = "selectedOrganizationUnit$ | async"
                        (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                        [dataCyLabel]="'organizationUnitFilter'">
                    </app-filter-tree-single-select>

                    <app-filter-tree-single-select 
                        [entities]="activityTypeCategories$ | async"
                        [labelText]="'Activity type categories'"
                        [selectedEntityId] = "selectedActivityTypeCategory$ | async"
                        (selectedEntityIdChanged)="onSelectActivityTypeCategoryChange($event)"
                        [dataCyLabel]="'activityTypeCategoryFilter'">
                    </app-filter-tree-single-select>
    
                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showUnderlyingUnits$ | async"
                            (change)="onUpdateShowChildUnits($event)"
                            data-cy="showActivityTypesForUnderlyingOrganizationUnits"
                        >{{ 'Also show activity types for underlying organization units' | translate }}</mat-checkbox>
                    </div>

                    <br/>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showPastActivityTypes$ | async"
                            (change)="onUpdateShowPastActivityTypes($event)"
                            data-cy="showActivityTypesValidInPast"
                        >{{ 'Show activity types that were valid in the past' | translate }}</mat-checkbox>
                    </div>

                    <br/>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showFutureActivityTypes$ | async"
                            (change)="onUpdateShowFutureActivityTypes($event)"
                            data-cy="showActivityTypesValidInFuture"
                        >{{ 'Show activity types that will become valid in the future' | translate }}</mat-checkbox>
                    </div>
                </app-entity-filter-panel>
            </div>
        </div>

        <div class="flex-column" >
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true">
                    <app-filter-tree-single-select 
                        [entities]="owsDutySets$ | async"
                        [labelText]="'Duty sets'"
                        [selectedEntityId] = "selectedDutySet$ | async"
                        (selectedEntityIdChanged)="onSelectedDutySetChange($event)"
                        [dataCyLabel]="'dutySetFilter'">
                    </app-filter-tree-single-select>
    
                    <div *ngIf="visibleOwsDuties$ | async">
                        <div>
                            <mat-checkbox
                                class="bol-checkbox"
                                [checked]="hideUnlinkedOwsDuties$ | async"
                                (change)="toggleUnlinkedOwsDutyVisibility()"
                                data-cy = "hideLinkedDuties"
                            >{{ 'Hide linked Duties' | translate }}</mat-checkbox>
                        </div>
    
                        <br/>
    
                        <span class="unlinked__title">{{'Unlinked Duties' | translate}}:</span>
                        <div class="bol-badge-solid"
                            [ngClass]="{'bol-warning': (unlinkedDutiesCount$ | async) > 0, 'bol-primary': (unlinkedDutiesCount$ | async) === 0}" 
                            data-cy="numberUnlinkedDuties">
                            {{unlinkedDutiesCount$ | async}}
                        </div>
                    </div>

                    <p *ngIf="(selectedActivityTypeId$ | async) === undefined && (visibleOwsDuties$ | async)?.length > 0" data-cy="noSelectionWarningMessage">
                        <mat-icon class="inline-icon">warning</mat-icon>
                        <span translate>Please select an OMRP Activity Type from the left panel to map to!</span>
                    </p>
                </app-entity-filter-panel>
            </div>
        </div>

    </div>
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OMRP Activity Types"
                    [entities]="visibleActivityTypes$ | async"
                    [selectedEntityId]="selectedActivityTypeId$ | async"
                    [searchProperties]="searchProperties"
                    hideAddButton="true"
                    (selectedEntityIdChanged)="onSelectActivityType($event)"
                    [dataCyLabel]="'activityTypesOMRP'"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OWS Duties"
                    [entities]="visibleOwsDuties$ | async"
                    (selectionClickChanged)="onSelectOwsDutyForMapping($event)"
                    showCheckboxes="true"
                    hideAddButton="true"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'dutiesOWS'"
                >
                </app-entity-list-panel>
            </div>
        </div>
    </div>
</div>


