import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';

export interface ISortSelectionModalData {
    moveToTop: boolean,
    moveToBottom: boolean
}

@Component({
    selector: 'app-sort-selection-dialog',
    templateUrl: './sort-selection-dialog.component.html',
    styleUrls: ['./sort-selection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortSelectionDialogComponent {
    public moveToTop: boolean = false;
    public moveToBottom: boolean = false;

    constructor(public dialogRef: MatDialogRef<SortSelectionDialogComponent>) {}

    public onMoveToTopClick(event: MatCheckboxChange): void {
        this.moveToTop = event.checked;
        this.dialogRef.close({
            data: {
            moveToTop: this.moveToTop,
            moveToBottom: this.moveToBottom
            } as ISortSelectionModalData
        });
    }

    public onMoveToBottomClick(event: MatCheckboxChange): void {
        this.moveToBottom = event.checked;
        this.dialogRef.close({
            data: {
                moveToTop: this.moveToTop,
                moveToBottom: this.moveToBottom
            } as ISortSelectionModalData
        });
    }
}