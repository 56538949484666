<div class="filters__panel">
  <div class="date_organizations_container">
    <div
      class="date__input">
        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
            <mat-label translate>Start date</mat-label>
            <input 
                #fromDateInput
                matInput
                [matDatepicker]="fromDatePicker"
                [formControl]="dateTimeControls.fromDate"
                data-cy="OverviewStartDate">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <mat-error
                *ngIf="dateTimeControls.fromDate.invalid"
                data-cy="OverviewStartDateError">

                <span *ngIf="!fromDateInput.value || fromDateInput.value === ''; else validDateError" translate>
                  Please enter a date
                </span>
                <ng-template #validDateError>
                  <span translate>Please enter a valid date</span>
                </ng-template>
                <span></span>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="organization-units_container">
      <app-multiselect-tree
        [entities]="organizationsForFiltering$ | async"
        [preSelectedEntities]="preselectedOrganizationUnits$ | async"
        multiselectTreePlaceholder="{{'Organization Units'}}"
        errorMessage="{{'Please select an organization unit'}}"
        (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
        data-cy="OverviewOrganizationUnitsFilter">
      </app-multiselect-tree>
    </div>
  </div>

  <div class="resources__container">
    <app-filter-chip-list
        data-cy="ScheduleOverviewBaseResourceTypes"
        class="resources__container__base-resources"
        required="true"
        [entities]="baseResourceTypesForFiltering$ | async"
        [preselectedEntities]="selectedBaseResourceTypes$ | async"
        [forceErrorState]="forceErrorStateOnBaseResourceTypes$ | async"
        errorMessage="{{'Please select a base resource type' | translate}}"
        noResultsFoundText="{{'No base resource types found' | translate}}"
        placeholderText="{{'Base Resource Types...' | translate}}"
        (selectedEntityIdsChanged)="onFilteredBaseResourceTypesChanged($event)"
    ></app-filter-chip-list>

    <app-filter-chip-list
        data-cy="ScheduleOverviewExtraResourceTypes"
        class="resources__container__extra-resources"
        [entities]="extraResourceTypesForFiltering$ | async"
        [preselectedEntities]="selectedExtraResourceTypes$ | async"
        noResultsFoundText="{{'No extra resource types found' | translate}}"
        placeholderText="{{'Extra Resource Types...' | translate}}"
        (selectedEntityIdsChanged)="onFilteredExtraResourceTypesChanged($event)"
    ></app-filter-chip-list>
  </div>

  <div class="resource-options__container">
    <div
      [ngClass]="{'extended-container': (selectedResourcePropertiesLength$ | async) > 0}"
      class="resource-options__container__activity-types">
      <app-activity-type-selection-old
        [activityTypes]="activityTypes$ | async"
        [activityTypeCategories]="activityTypeCategories$ | async"
        [selectedActivityTypeIds]="selectedActivityTypeIds$ | async"
        [selectedActivityTypeCategoryIds]="selectedActivityTypeCategoryIds$ | async"
        [required]="true"
        (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
        (updateSelectedActivityTypeCategoryIdsEvent)="updateSelectedActivityTypeCategoryIds($event)"
      ></app-activity-type-selection-old>
    </div>

    <div class="resource-options__container__sort-options">
        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
            <mat-label translate>Grouping option</mat-label>
            <mat-select
                #groupingOptionSelect
                data-cy="OverviewGroupingOptionsSelect"
                (openedChange)="openedGroupingOptionSelect($event)"
                [formControl]="resourceControls.groupingOption">
                <mat-option *ngFor="let groupingOption of groupingOptions" [value]="groupingOption.type">{{ groupingOption.value | translate }}</mat-option>
            </mat-select>
      </mat-form-field>
    </div>

    <div class="resource-options__container__number-of-days">
        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
            <mat-label translate>No. of days</mat-label>
            <mat-select
                [formControl]="resourceControls.numberOfDays">
                <mat-option *ngFor="let numberOfDaysOption of numberOfDays" [value]="numberOfDaysOption">{{ numberOfDaysOption }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <app-filter-chip-list
        data-cy="ScheduleOverviewResourceProperties"
        class="resource-options__container__extra-properties"
        [entities]="allResourceProperties$ | async"
        [preselectedEntities]="selectedResourceProperties$ | async"
        errorMessage="{{'Please select a resource property' | translate}}"
        noResultsFoundText="{{'No resource properties found' | translate}}"
        placeholderText="{{'Resource Properties...' | translate}}"
        (selectedEntityIdsChanged)="onFilteredResourcePropertiesChanged($event)"
    ></app-filter-chip-list>
  </div>

  <p class="bol-text bol-text-medium" translate>Show...</p>
  <div class="show_options_container">
    <div class="show_option" *ngFor="let option of scheduleShowOptions$ | async">
        <mat-checkbox class="bol-checkbox"
            (change)="updateShowOptions(option, $event)"
            [value]="option.value"
            [checked]="option.state">{{ option.value | translate }}
        </mat-checkbox>
    </div>
  </div>
</div>
