/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ScheduleActivity } from './schedule-activity.model';

export interface ScheduleActivityState extends EntityState<ScheduleActivity> {
    activitiesTreeForActivity: Array<ScheduleActivity>;
    activitiesTreeForResource: Array<ScheduleActivity>;
}

const initialState: ScheduleActivityState = {
    activitiesTreeForActivity: [],
    activitiesTreeForResource: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'schedule-activity' })
export class ScheduleActivityStore extends EntityStore<ScheduleActivityState> {
    constructor() {
        super(initialState);
    }

    public updateActivitiesTreeForActivity(activitiesTreeForActivity: Array<ScheduleActivity>): void {
        this.update(state => ({ ...state, activitiesTreeForActivity }));
    }

    public updateActivitiesTreeForResource(activitiesTreeForResource: Array<ScheduleActivity>): void {
        this.update(state => ({ ...state, activitiesTreeForResource }));
    }
}
