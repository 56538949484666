import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { OwsWorkTreatmentStore } from './ows-work-treatment.store';
import { OwsWorkTreatmentQuery } from './ows-work-treatment.query';

@Injectable({
    providedIn: 'root'
})
export class OwsWorkTreatmentService {
    constructor(
        protected owsWorkTreatmentStore: OwsWorkTreatmentStore,
        private readonly http: HttpClient,
        private readonly owsWorkTreatmentQuery: OwsWorkTreatmentQuery
    ) {}

    public get() {
        return forkJoin([
            this.http.get<Array<string>>('/api/OwsInterface/GetOwsWorkTreatmentText'),
            this.http.get<Array<number>>('/api/OwsInterface/GetOwsWorkActivityTypes')
        ]).pipe(
            map(([workTreatmentTexts, workActivityIds]) => {
                this.owsWorkTreatmentStore.updateWorkTreatment(workTreatmentTexts);
                this.owsWorkTreatmentStore.updateWorkActivityType(workActivityIds);
            })
        );
    }

    public updateWorkTreatment(workTreatmentText: string) {
        let workTreatments = this.owsWorkTreatmentQuery.getWorkTreatmentTextsSync();
        if (workTreatments.includes(workTreatmentText)) {
            workTreatments = workTreatments.filter(text => text !== workTreatmentText);
        }
        else {
            workTreatments.push(workTreatmentText);
        }
        // TODO: set in server with timeout to collect calls
        this.http.post<void>('/api/OwsInterface/UpdateOwsWorkTreatmentText', workTreatments).subscribe();

        this.owsWorkTreatmentStore.updateWorkTreatment(workTreatments);
    }

    public updateWorkActivityType(owsActivityTypeId: number) {
        let workActivityTypes = this.owsWorkTreatmentQuery.getWorkActivityTypesSync();
        if (workActivityTypes.includes(owsActivityTypeId)) {
            workActivityTypes = workActivityTypes.filter(id => id !== owsActivityTypeId);
        }
        else {
            workActivityTypes.push(owsActivityTypeId);
        }
        // TODO: set in server with timeout to collect calls
        this.http.post<void>('/api/OwsInterface/UpdateOwsWorkActivityTypes', workActivityTypes).subscribe();
        this.owsWorkTreatmentStore.updateWorkActivityType(workActivityTypes);
    }
}
