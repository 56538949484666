<div class="overlay-spinner" *ngIf="loadingSubject | async">
    <mat-spinner diameter="100"></mat-spinner>
</div>

<ng-container *ngIf="!(error$ | async)">
    <div *ngIf="!(emptyMatrixSubject | async)" class="slider__container">
        <span translate>Name column width</span>
        <mat-slider
            class="column-width-slider"
            [min]="150"
            [max]="300" #ngSlider><input matSliderThumb [formControl]="nameColumnSliderControl" (input)="onNameColumnWidthChange(ngSliderThumbName)" #ngSliderThumbName="matSliderThumb" />
        </mat-slider>
    
        <span translate>Data column width</span>
        <mat-slider
            class="column-width-slider"
            [min]="dataColumnMin"
            [max]="200" #ngSlider><input matSliderThumb [formControl]="dataColumnSliderControl" (input)="onDataColumnWidthChange(ngSliderThumbData)" #ngSliderThumbData="matSliderThumb" />
        </mat-slider>

        <span translate>Total table width</span>
        <mat-slider
            class="column-width-slider"
            [min]="25"
            [max]="100" #ngSlider><input matSliderThumb [formControl]="totalTableSliderControl" (input)="onTotalTableWidthChange(ngSliderThumbTotal)" #ngSliderThumbTotal="matSliderThumb" />
        </mat-slider>

        <mat-checkbox
            class="bol-checkbox"
            [checked]='hideZeroValues'
            (change)="onUpdateHideZeroValues($event)">
            <span>{{ 'Hide 0 values' | translate }}</span>
        </mat-checkbox>
    </div>
    
    <div *ngIf="emptyMatrixSubject | async" class="empty__state__container">
        <div class="empty__state__container__content">
            <mat-icon>assessment</mat-icon>
            <div class="empty__state__container__message__huge">
                {{ "No counters to show." | translate }}
            </div>
            <div>
                {{ "Use the filters panel to create a counter overview." | translate }}
            </div>
            </div>
    </div>
    
    <div
        class="counters__table__container"
        *ngIf="!(emptyMatrixSubject | async)"
        >
        <mat-table 
            [dataSource]="dataSource" 
            class="counters__table__content"
            [ngStyle]="{'min-width': totalTableWidth, 'width': totalTableWidth, 'max-width': totalTableWidth}">
            <ng-container matColumnDef="name">
                <mat-header-cell 
                *matHeaderCellDef 
                [ngStyle]="{'min-width': nameColumnWidth}">
                </mat-header-cell>
       
                <mat-cell 
                *matCellDef="let counter"
                class="name-column-cell {{counter.cssClassName}}"
                [ngStyle]="{'min-width': nameColumnWidth}"> 
                <button mat-icon-button 
                    [style.visibility]="!counter.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="counter.level * 16"
                    (click)="treeControl.toggle(counter)">
                    <mat-icon>
                    {{treeControl.isExpanded(counter) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
        
                {{counter.name}}
                </mat-cell>
            </ng-container>
    
            <ng-container *ngFor="let column of columnDefinitions; let i = index" [matColumnDef]="column.columnName">
                <mat-header-cell 
                *matHeaderCellDef
                [ngStyle]="{'min-width': dataColumnWidth, 'width': dataColumnWidth}"> 
                    {{column.columnLabel}} 
                </mat-header-cell>
        
                <mat-cell 
                    *matCellDef="let element" 
                    class="{{column.cssClassName === '' ? 'no-background' : column.cssClassName}}"
                    [ngStyle]="{'min-width': dataColumnWidth, 'width': dataColumnWidth}"> 
                        {{element.data[i]}} 
                </mat-cell>
            </ng-container>
    
            <mat-header-row 
                *matHeaderRowDef="columns; 
                sticky: true">
            </mat-header-row>
            <mat-row 
                *matRowDef="let row; 
                columns: columns;"
                [class.hidden]="hideZeroValues && row.hasZeroValue">
            </mat-row>
            <tr *matNoDataRow class="bol-table-empty-state empty-table">
                <td *ngIf="dataSource.data.length === 0"> {{ "No counters to show." | translate }}</td>
            </tr>
        </mat-table>
    </div>
</ng-container>

<div *ngIf="error$ | async" class="error-container">
    <div class="error-icon">
        <mat-icon>error</mat-icon>
    </div>
    <span translate>An error occurred.</span>
</div>

