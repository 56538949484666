import { Injectable } from '@angular/core';

import { ActivityType } from './activity-type.model';

@Injectable({
    providedIn: 'root'
})

export class ActivityTypeHelperService {

    public getFullActivityStructureAndSort(rootId: number, activityTypes: Array<ActivityType>): Array<ActivityType> {
        const entityMap = {};
        const sortedActivityTypes = [];
    
        activityTypes.forEach(entity => {
            const parentId = entity.parentId;
            if (!entityMap[parentId]) {
                entityMap[parentId] = [];
            }
            entityMap[parentId].push(entity);
        });

        const root = activityTypes.find(entity => entity.id === rootId);
        if (root) {
            sortedActivityTypes.push({...root, level: 0});
        }
    
        const buildAndSortStructure = (parentId: number | null, level: number = 0): void => {
            if (entityMap[parentId]) {
                entityMap[parentId].sort((a, b) => a.sortOrder - b.sortOrder);
                entityMap[parentId].forEach(entity => {
                    sortedActivityTypes.push({...entity, level});
                    buildAndSortStructure(entity.id, level + 1);
                });
            }
        };
    
        buildAndSortStructure(rootId);

        return sortedActivityTypes;
    }
}
