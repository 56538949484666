import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, startWith, tap } from 'rxjs/operators';

import { EmptyFiltersPanelComponent } from 'src/app/shared/components/empty-filters-panel/empty-filters-panel.component';
import { DaypartService } from 'src/app/shared/stores/day-part-store/day-part.service';
import { FILTER_SETTING_TYPE } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { ConfigurationQuery } from 'src/app/shared/stores/configuration-store/configuration.query';
import { SCHEDULE_STORAGE_KEYS } from './schedule-helpers/enums';
import { ScheduleHelperService } from './schedule-helpers/schedule-helper.service';
import { ScheduleQuery } from './stores/schedule-store/schedule.query';
import { ScheduleService } from './stores/schedule-store/schedule.service';

@Component({
    selector: 'app-schedule-overview',
    templateUrl: './schedule-overview.component.html',
    styleUrls: ['./schedule-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleOverviewComponent implements OnInit, OnDestroy {
    @ViewChild(EmptyFiltersPanelComponent) public filterPanel: EmptyFiltersPanelComponent;

    public initialLoadingFinished$!: Observable<boolean>;
    public loading$!: Observable<boolean>;
    public filterSettingsAvailable$!: Observable<boolean>;
    public daypartsReadySubject = new BehaviorSubject<boolean>(false);
    public filtersPanelState: boolean;
    public filtersPanelState$: Observable<boolean>;

    private readonly subscription: Subscription = new Subscription();

    constructor(
        private readonly router: Router,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly scheduleService: ScheduleService,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly daypartService: DaypartService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly configurationQuery: ConfigurationQuery,
    ) {
        this.daypartService.getDayparteNew().pipe(first()).subscribe(() => this.daypartsReadySubject.next(true));
    }

    public ngOnInit(): void {
        this.loading$ = combineLatest([
            this.scheduleQuery.getScheduleLoadingState(),
            this.scheduleQuery.getScheduleFiltersLoadingState()
        ]).pipe(
            map(([scheduleLoading, filtersLoading]) => scheduleLoading || filtersLoading),
        );
        this.filtersPanelState = this.scheduleQuery.getFilterSettingsPanelStatusSync();
        this.filtersPanelState$ = this.scheduleQuery.getFilterSettingsPanelStatus();

        this.initialLoadingFinished$ = combineLatest([
            this.scheduleQuery.getScheduleLoadingState(),
            this.scheduleQuery.getScheduleFiltersLoadingState()
        ]).pipe(
            filter(([scheduleLoading, filtersLoading]) => !scheduleLoading && !filtersLoading),
            map(() => true),
            first(),
            startWith(false)
        );
        this.filterSettingsAvailable$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_V2_FILTER_SETTING)
            .pipe(
                map(fs => fs.length > 0),
                tap(fsAvailable => {
                    // If there is no filter yet available, open the panel
                    if (!fsAvailable) {
                        this.filterPanel?.setFilterPanel(true);
                        this.scheduleService.updateFilterSettingsPanelStatus(true);
                    }
                })
            );

        this.scheduleService.updateScheduleStartDate(this.scheduleQuery.getStartDateSync());
        this.scheduleService.updateScheduleEndDate(this.scheduleQuery.getEndDateSync());
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public updateShowFiltersStateAndGetScheduleData(state: boolean): void {
        this.scheduleService.startRefreshTimer(state);
        this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTERS_PANEL_STATE, state);
        const filtersValid = this.scheduleQuery.getScheduleFiltersValiditySync();
        
        if (!state && filtersValid) {
            this.scheduleService.getScheduleData().pipe(first()).subscribe();
        }

        // Note: we want to set the loading first and then close the panel, to trigger events in the correct order
        // We reload the schedule component when the filter panel is closed and the call is finished
        this.scheduleService.updateFilterSettingsPanelStatus(state);
    }

    public hidePanelOnSelectedFilterSetting(): void {
        if (this.filterPanel.filtersPanelState) {
            this.filterPanel.setFilterPanel(false);
        }
    }
}
