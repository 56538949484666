import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { STATUS, StatusPageState, StatusPageStore, initialStatusPageState } from 'src/app/shared/stores/status-page-store/status-page.store';

export interface FilteringPaletteState extends StatusPageState {
    ui: {
        selectedOrganizationUnitIds: Array<number>;
        selectedActivityTypeCategoryIds: Array<number>;
        entitiesLoading: boolean;
        selectedActivityTypeIds: Array<number>;
        showOnlySelectedState: boolean,
        applyFilterState: boolean
    };
}

const initialStateFilterPalette = {
    ui: {
        selectedOrganizationUnitIds: [],
        selectedActivityTypeCategoryIds: [],
        entitiesLoading: false,
        selectedActivityTypeIds: undefined,
        showOnlySelectedState: false,
        applyFilterState: false
    },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filtering-palette' })
export class FilteringPaletteStore extends StatusPageStore<FilteringPaletteState> {
    constructor() {
        super({...initialStateFilterPalette, ...initialStatusPageState});
    }

    public updateSelectedOrganizationUnitIds(selectedOrganizationUnitIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedOrganizationUnitIds}
        }));
    }
    
    public updateSelectedActivityTypeCategoryIds(selectedActivityTypeCategoryIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedActivityTypeCategoryIds}
        }));
    }
    
    public updateEntitiesLoading(entitiesLoading: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoading}
        }));
    }
    
    public updateSelectedActivityTypeIds(selectedActivityTypeIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedActivityTypeIds}
        }));
    }
    
    public updateShowOnlySelectedState(showOnlySelectedState: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, showOnlySelectedState}
        }));
    }

    public updateApplyFilterState(applyFilterState: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, applyFilterState}
        }));
    }
}