import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { Holiday, HolidayYear } from './holiday.model';

export interface HolidayState {
    ui: {
        currentYear: number;
        years: Array<HolidayYear>;
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        currentYear: undefined,
        years: [],
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'holiday' })
export class HolidayStore extends Store<HolidayState> {
    constructor() {
        super(initialState);
    }

    public updateEntitiesLoadingState(entitiesLoading: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }

    public updateCurrentYear(currentYear: number): void {
        this.update(state => {
            const ui = { ...state.ui, currentYear };

            return { ...state, ui };
        });
    }

    public updateLoadedYear(holidayYear: HolidayYear): void {
        this.update(state => {
            if (state.ui.years.find(year => year.yearLoad === holidayYear.yearLoad)) {

                return;
            }

            const ui = { ...state.ui, years: [...state.ui.years, holidayYear] };

            return { ...state, ui };
        });
    }

    public saveHolidayInYear(holiday: Holiday): void {
        this.update(state => {
            const actualHolidays = state.ui.years.find(holidayYear => holidayYear.yearLoad === state.ui.currentYear)?.holidays;
            const updatedYear: HolidayYear = { yearLoad: state.ui.currentYear, holidays: [...actualHolidays, holiday], loaded: true};
            const updatedYearIndex = state.ui.years.findIndex(holidayYear => holidayYear.yearLoad === updatedYear.yearLoad);
            const storeYears = [...state.ui.years];
            storeYears[updatedYearIndex] = updatedYear;
            const ui = { ...state.ui, years: storeYears};

            return { ...state, ui };
        });
    }

    public updateHolidayInYear(holiday: Holiday): void {
        this.update(state => {
            const actualHolidays = state.ui.years.find(holidayYear => holidayYear.yearLoad === state.ui.currentYear)?.holidays;
            const updatedHolidays = [...actualHolidays];
            const updatedHolidayIndex = updatedHolidays.findIndex(h => h.id === holiday.id);
            updatedHolidays[updatedHolidayIndex] = holiday;
            const updatedYear: HolidayYear = { yearLoad: state.ui.currentYear, holidays: updatedHolidays, loaded: true};
            const updatedYearIndex = state.ui.years.findIndex(holidayYear => holidayYear.yearLoad === updatedYear.yearLoad);
            const storeYears = [...state.ui.years];
            storeYears[updatedYearIndex] = updatedYear;
            const ui = { ...state.ui, years: storeYears};

            return { ...state, ui };
        });
    }

    public deleteHolidayInYear(id: number): void {
        this.update(state => {
            const actualHolidays = state.ui.years.find(holidayYear => holidayYear.yearLoad === state.ui.currentYear)?.holidays;
            const updatedHolidays = [...actualHolidays];
            const updatedHolidayIndex = updatedHolidays.findIndex(h => h.id === id);
            updatedHolidays.splice(updatedHolidayIndex, 1);
            const updatedYear: HolidayYear = { yearLoad: state.ui.currentYear, holidays: updatedHolidays, loaded: true};
            const updatedYearIndex = state.ui.years.findIndex(holidayYear => holidayYear.yearLoad === updatedYear.yearLoad);
            const storeYears = [...state.ui.years];
            storeYears[updatedYearIndex] = updatedYear;
            const ui = { ...state.ui, years:storeYears};

            return { ...state, ui };
        });
    }
}