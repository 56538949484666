import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, skip } from 'rxjs/operators';

import { PermissionQuery } from '../stores/permission-store/permission.query';
import { PermissionService } from '../stores/permission-store/permission.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsGuard  {
    private readonly noPermissionsRoute = '/noPermissions';

    constructor(
        private readonly router: Router,
        private readonly permissionService: PermissionService,
        private readonly permissionQuery: PermissionQuery,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const permissionType = route.data.permission;

        this.permissionService.getPermissions().pipe(first()).subscribe();

        return this.permissionQuery.getPermission(permissionType).pipe(
            skip(1),
            map(permission => {
                if (permission !== undefined && permission.numberUserUserGroupsWithThisPermission >= 1) {
                    return true;
                } else {
                    this.router.navigate([this.noPermissionsRoute]);

                    return false;
                }
            })
        );
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
