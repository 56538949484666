import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { concat, Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RequestService {
    public loadSequential<T>(observables: Array<Observable<T>>): Observable<Array<T>> {
        return concat(...observables).pipe(toArray());
    }

    public splitUpParamsBasedOnDates(
        params: HttpParams,
        start: string,
        end: string,
        nameOfStartParam: string,
        nameOfEndParam: string,
        maxDaysInCall: number
    ): Array<HttpParams> {
        const endDate = moment(end);
        const startDate = moment(start);
        let date = startDate.clone();

        const daysInCall = endDate.diff(startDate, 'days') + 1;
        let daysCovered = 0;
        const paramList: Array<HttpParams> = [];

        while (daysCovered < daysInCall) {
            let dateParams = params.append(nameOfStartParam, moment(date).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS'));

            date.add(maxDaysInCall - 1, 'days');
            if (date.isAfter(endDate)) {
                date = endDate;
            }
            dateParams = dateParams.append(nameOfEndParam, moment(date).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS'));
            paramList.push(dateParams);
            date.add(1, 'days'); // will be the next start date
            daysCovered += maxDaysInCall;
        }

        return paramList;
    }

    public splitUpRequestBasedOnDates(
        params: any,
        start: string,
        end: string,
        nameOfStartParam: string,
        nameOfEndParam: string,
        maxDaysInCall: number
    ): Array<any> {
        const endDate = moment(end);
        const startDate = moment(start);
        let date = startDate.clone();

        const daysInCall = endDate.diff(startDate, 'days') + 1;
        let daysCovered = 0;
        const paramList: Array<any> = [];

        while (daysCovered < daysInCall) {
            const dateParams = { ...params };
            dateParams[nameOfStartParam] = moment(date).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS');

            date.add(maxDaysInCall - 1, 'days');
            if (date.isAfter(endDate)) {
                date = endDate;
            }
            dateParams[nameOfEndParam] = moment(date).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS');
            paramList.push(dateParams);
            date.add(1, 'days'); // will be the next start date
            daysCovered += maxDaysInCall;
        }

        return paramList;
    }
}
