import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';

export interface UsersManagementState {
    selectedUserId: number;
    selectedOrganizationUnitId: number;
    showUnderlyingUnits: boolean;
    showWithoutUserGroups: boolean;
    showAll: boolean;
    manageMode: MANAGE_MODE;
}

const initialState = {
    selectedUserId: undefined,
    selectedOrganizationUnitId: undefined,
    showUnderlyingUnits: false,
    showWithoutUserGroups: false,
    showAll: false,
    manageMode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'users-management' })
export class UsersManagementStore extends Store<UsersManagementState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedUserId(id: number): void {
        this.update(state => ({ ...state, selectedUserId: id }));
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => ({ ...state, selectedOrganizationUnitId: id }));
    }

    public updateShowChildUnits(value: boolean): void {
        this.update(state => ({ ...state, showUnderlyingUnits: value }));
    }

    public updateShowWithoutUserGroups(value: boolean): void {
        this.update(state => ({ ...state, showWithoutUserGroups: value }));
    }

    public updateShowAllUsers(value: boolean): void {
        this.update(state => ({ ...state, showAll: value }));
    }

    public updateManageMode(manageMode: MANAGE_MODE): void {
        this.update(state => ({ ...state, manageMode }));
    }

    public setStateForNewUser(id: number): void {
        this.update(state => (
            { 
                ...state, 
                selectedUserId: id,
                manageMode: MANAGE_MODE.EDIT,
                showAll: true
            }));
    }
}
