import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { StatusPageState, StatusPageStore, initialStatusPageState } from 'src/app/shared/stores/status-page-store/status-page.store';

import { SortableActivityType } from './sorting-palette.model';

export interface SortingPaletteState extends StatusPageState {
    ui: {
        selectedActivityTypeCategoryId: number;
        entitiesLoading: boolean;
        selectedActivityTypeIds: Array<SortableActivityType>;
        applySortState: boolean
    };
}

const initialStateSortPalette = {
    ui: {
        selectedActivityTypeCategoryId: 2,
        entitiesLoading: false,
        selectedActivityTypeIds: undefined,
        applySortState: false
    },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sorting-palette' })
export class SortingPaletteStore extends StatusPageStore<SortingPaletteState> {
    constructor() {
        super({...initialStateSortPalette, ...initialStatusPageState});
    }
    
    public updateSelectedActivityTypeCategoryId(selectedActivityTypeCategoryId: number): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedActivityTypeCategoryId}
        }));
    }
    
    public updateEntitiesLoading(entitiesLoading: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesLoading}
        }));
    }
    
    public updateSortedActivityTypeIds(selectedActivityTypeIds: Array<SortableActivityType>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedActivityTypeIds}
        }));
    }

    public updateApplySortState(applySortState: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, applySortState}
        }));
    }
}
