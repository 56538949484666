import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TreeSearchService {
    public noVisibleEntities: boolean;
    private internalNoVisibleEntities: boolean;

    public setEntityVisiblityState(filterValue: string, entities: Array<any>, searchProperties: Array<string>): void {
        this.internalNoVisibleEntities = true;
        const filterToLowerCase = filterValue.toLowerCase();
        this.setVisiblityOfEntities(filterToLowerCase, entities, searchProperties);
        this.setForChildrenVisiblity(filterToLowerCase, entities);

        this.noVisibleEntities = this.internalNoVisibleEntities;
    }

    private setVisiblityOfEntities(filterValue: string, entities: Array<any>, searchProperties: Array<string>) {
        entities.forEach(entity => {
            if (this.checkEntityVisibilityBySearchProperties(filterValue, entity, searchProperties)) {
                this.internalNoVisibleEntities = false;
                entity.visible = true;
            }
            else {
                entity.visible = false;
            }

            if (entity.children) {
                this.setVisiblityOfEntities(filterValue, entity.children, searchProperties);
            }
        });
    }

    private setForChildrenVisiblity(filterValue: string, entities: Array<any>) {
        entities.forEach(entity => {
            if (!entity.visible && entity.children) {
                entity.visible = this.checkChildrenForVisiblity(entity.children);
            }
            if (entity.children) {
                this.setForChildrenVisiblity(filterValue, entity.children);
            }
        });
    }

    private checkChildrenForVisiblity(children: Array<any>): boolean {
        let i = 0;
        let visibleChild = false;
        while (!visibleChild && i < children.length) {
            const currentChild = children[i];
            visibleChild = currentChild.visible;
            if (!visibleChild && currentChild.children) {
                visibleChild = this.checkChildrenForVisiblity(currentChild.children);
            }
            i++;
        }

        return visibleChild;
    }

    private checkEntityVisibilityBySearchProperties(filterValue: string, entity: any, searchProperties: Array<string>) {
        return searchProperties.some(col => 
            entity[col]?.toLowerCase().includes(filterValue)
        );
    }
}
