import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Subscription, first, tap } from 'rxjs';

import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { ManageTimeslotService } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.service';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { AdtActivityTypeTimeslotsDialogComponent, IActivityTypeTimeslotsDialogData } from '../adt-activity-type-timeslots-dialog/adt-activity-type-timeslots-dialog.component';
import { AdtPrefferedResourceDialogComponent, IPreferredResourceDialogData } from '../adt-preferred-resource-dialog/adt-preferred-resource-dialog.component';
import { EditTemplatesManagementService } from '../../store/edit-template-management.service';

@Component({
    selector: 'app-adt-activity-type-table-menu',
    templateUrl: './adt-activity-type-table-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdtActivityTypeTableMenuComponent implements OnInit {
    @Input() public readonly activityId: number;
    @ViewChild(MatMenu) public readonly menu: MatMenu;

    private currentTemplateId: number;
    private readonly subscriptions = new Subscription();
    
    constructor(
        private readonly manageTimeslotService: ManageTimeslotService,
        private readonly manageTemplateQuery: ManageTemplateQuery,
        private readonly manageTimeslotQuery: ManageTimeslotQuery,
        private readonly editTemplatesManagementService: EditTemplatesManagementService,
        private readonly dialogService: MatDialog,
    ) { }

    public ngOnInit(): void {
        this.currentTemplateId = this.manageTemplateQuery.getSelectedTemplateForEditSync().id;
    }

    public onEditTimeslots(): void {
        this.editTemplatesManagementService.updateSelectedActivityTimeslotId(this.activityId);
        const dialogRef = this.dialogService.open(AdtActivityTypeTimeslotsDialogComponent, {
            data: {
                selectedActivityTimeslotId: this.activityId,
                manageMode: MANAGE_MODE.EDIT
            } as IActivityTypeTimeslotsDialogData,
            width: '600px',
            autoFocus: false,
        });

        this.subscriptions.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IActivityTypeTimeslotsDialogData }) => {
                    if (dialogData) {
                        const updatedTimeslots = this.manageTimeslotQuery.getUpdatedActivityTypeTimeslotsSync(dialogData.data.selectedTimeslotIds, dialogData.data.selectedActivityTypeId);
        
                        if (updatedTimeslots.addedTimeslots.length > 0) {
                            this.manageTimeslotService.updateActivityDemandTimeslots(updatedTimeslots.addedTimeslots, this.currentTemplateId).pipe(first()).subscribe();
                        }
        
                        if (updatedTimeslots.deletedTimeslotIds.length > 0) {
                            updatedTimeslots.deletedTimeslotIds.forEach(timeslotId => {
                                this.manageTimeslotService.deleteActivityDemandTimeslots(timeslotId, this.currentTemplateId).pipe(first()).subscribe();
                            });
                        }
                    }
                })
            ).subscribe()
        );
    }

    public onEditDistribution(): void {
        console.log(this.activityId);
    }

    public onCopyDistribution(): void {
        console.log(this.activityId);
    }

    public onSelectPreferredResources(): void {
        this.editTemplatesManagementService.updateSelectedActivityTimeslotId(this.activityId);
        const dialogRef = this.dialogService.open(AdtPrefferedResourceDialogComponent, {
            data: {
                selectedActivityTimeslotId: this.activityId,
            } as IPreferredResourceDialogData,
            width: '1000px',
            autoFocus: false,
        });

        this.subscriptions.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IPreferredResourceDialogData}) => {
                    if (dialogData) {
                        //the logic for updating preferred resources
                    }
                })
            ).subscribe()
        );
    }

    public onDeleteActivity(): void {
        this.manageTimeslotService.deleteActivityDemandTimeslots(this.activityId, this.currentTemplateId).pipe(first()).subscribe();
    }
}
