import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SelectionCheckBoxClickEvent } from '@ortec/soca-web-ui';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, skip, tap } from 'rxjs/operators';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityType, IActivityTypeTree } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';

export interface IActivityTypeModalData {
    activityTypes$: Observable<Array<ActivityType | IActivityTypeTree>>;
    selectedActivityTypeIds: Array<number>;
    selectedActivityTypeCategoryIds: Array<number>;
    activityTypeCategories$?: Observable<Array<ActivityTypeCategory>>;
    organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    selectedActivitiesOrganizationUnitsIds: Array<number>;
    totalActivityTypesCount?: number;
    noActivityTypesSelectedByDefault?: boolean;
}

export interface ActivityTypeSelection extends IActivityTypeTree {
    checked: boolean;
}

@Component({
    selector: 'app-activity-type-dialog',
    templateUrl: './activity-type-dialog.component.html',
    styleUrls: ['./activity-type-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeDialogComponent implements OnInit, OnDestroy {
    public activityTypes: Array<ActivityTypeSelection>;
    public filteredActivityTypesByCategories: Array<ActivityTypeSelection>;
    public filteredActvityTypesByOrganizationUnits: Array<ActivityTypeSelection>;
    public selectedOrganizationIds: Array<number>;
    public filteredActivityTypes$: Observable<Array<ActivityTypeSelection>>;
    public activityTypeCategories$!: Observable<Array<ActivityTypeCategory>>;
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    public organizationUnits: Array<IOrganizationUnitTree>;
    public numberOfActivityTypes: number;
    public selectedActivityTypeCategoryIds: Array<number>;
    public filteredOrganizationUnits$: Observable<Array<number>>;
    public selectedActivityTypeOrganizationUnitsIds: Array<number>;
    public filteredActivityTypesSubject = new BehaviorSubject<Array<ActivityTypeSelection>>([]);
    public selectedOrganizationUnitIdsSubject = new BehaviorSubject<Array<number>>([]);
    public selectedActivityTypeCategoryIdsSubject = new BehaviorSubject<Array<number>>([]);

    public selectAllState = true;
    public ACTIVITY_LENGTH_MESSAGE = 'activity types found for the selected activity type categories';
    public columnDefinition = [];

    public searchProperties: Array<string> = ['displayName', 'shortName'];

    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<IActivityTypeModalData>,
        public organizationUnitQuery: OrganizationUnitQuery,
        public activityTypeQuery: ActivityTypeQuery,
        public activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) dialogData: IActivityTypeModalData,
    ) {
        this.filteredActivityTypes$ = this.filteredActivityTypesSubject.asObservable();
        this.activityTypeCategories$ = dialogData.activityTypeCategories$.pipe(
            map((categories) => {
                return categories?.map(category => ({
                    ...category,
                    displayName: this.translateService.instant(category.displayName),
                }));
            })
        );
       
        if(dialogData.selectedActivityTypeCategoryIds === undefined || dialogData.selectedActivityTypeCategoryIds === null || dialogData.selectedActivityTypeCategoryIds.length === 0){
            const allActivityTypeCategoriesIds = this.activityTypeCategoryQuery.getActivityTypeCategoriesSync().map((category) => category.id);
            this.selectedActivityTypeCategoryIdsSubject.next(allActivityTypeCategoriesIds);
        } else {
            this.selectedActivityTypeCategoryIdsSubject.next(dialogData.selectedActivityTypeCategoryIds);
        }
        this.organizationUnits$ = dialogData.organizationUnits$;
        this.selectedOrganizationUnitIdsSubject.next(dialogData.selectedActivitiesOrganizationUnitsIds);
        this.selectedOrganizationIds = dialogData.selectedActivitiesOrganizationUnitsIds;
        this.selectedActivityTypeCategoryIds = this.selectedActivityTypeCategoryIdsSubject.value;

        this.subscription.add(
            combineLatest([
                dialogData.activityTypes$,
                dialogData.activityTypeCategories$,
                dialogData.organizationUnits$
            ]).pipe(
                filter(([activityTypes, activityTypeCategories, organizationUnits]) => activityTypes !== undefined && activityTypes.length > 0 &&
                    activityTypeCategories !== undefined && activityTypeCategories.length > 0 && organizationUnits !== undefined && organizationUnits.length > 0),
                tap(([activityTypes, _, organizationUnits]) => {
                    
                    const updatedActivityTypes = this.updateActivityTypeAndChildren(activityTypes as any, dialogData.selectedActivityTypeIds);
                    this.organizationUnits = organizationUnits;
                    const selectedActivityTypeCategoryIds = dialogData.selectedActivityTypeCategoryIds;
                    const filteredActivityTypesByCategories = this.getFilteredActivityTypesByCategories(updatedActivityTypes, selectedActivityTypeCategoryIds);

                    if (!dialogData.selectedActivitiesOrganizationUnitsIds || dialogData.selectedActivitiesOrganizationUnitsIds.length === 0) {
                        this.filteredOrganizationUnits$ = of(this.organizationUnitQuery.getAllOrganizationUnitsIdsSync(organizationUnits));
                        this.selectedOrganizationIds = this.organizationUnitQuery.getAllOrganizationUnitsIdsSync(organizationUnits);
                        this.selectedActivityTypeOrganizationUnitsIds = this.organizationUnitQuery.getAllOrganizationUnitsIdsSync(organizationUnits);
                    } else {
                        this.filteredOrganizationUnits$ = of(dialogData.selectedActivitiesOrganizationUnitsIds);
                        this.selectedActivityTypeOrganizationUnitsIds = dialogData.selectedActivitiesOrganizationUnitsIds;
                    }
                    const filteredActivityTypeByOrganizationUnitsIds = this.getFilteredActivityTypesByOrganizationUnits(filteredActivityTypesByCategories, this.selectedActivityTypeOrganizationUnitsIds);

                    this.activityTypes = updatedActivityTypes;
                    this.filteredActivityTypesByCategories = filteredActivityTypesByCategories;
                    this.filteredActvityTypesByOrganizationUnits = filteredActivityTypeByOrganizationUnitsIds;
                    
                    if (dialogData.noActivityTypesSelectedByDefault && (!dialogData.selectedActivityTypeIds || dialogData.selectedActivityTypeIds.length === 0)) {
                        this.filteredActivityTypesSubject.next([])
                    } else {
                        this.filteredActivityTypesSubject.next(filteredActivityTypeByOrganizationUnitsIds);
                    }
                    
                    this.numberOfActivityTypes = this.activityTypeQuery.getTotalActivityTypesCount(filteredActivityTypeByOrganizationUnitsIds)
                })
            ).subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.columnDefinition.push( 
            {
                columnDisplayName: 'Short name',
                entityProperty: 'shortName',
                stylingProperty: 'nameStyling'
            },
            {
                columnDisplayName: 'Display name',
                entityProperty: 'displayName',
            }
        );
        this.setFilteredActivityTypes();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                activityTypes$: of(this.activityTypes),
                selectedActivityTypeIds: this.getSelectedActivityTypeIds(this.filteredActivityTypesByCategories),
                selectedActivityTypeCategoryIds: this.selectedActivityTypeCategoryIds,
                organizationUnits$: of(this.organizationUnits),
                selectedActivitiesOrganizationUnitsIds: this.selectedOrganizationIds
            } as IActivityTypeModalData
        });
    }

    public updateActivityTypeStates(event: SelectionCheckBoxClickEvent): void {
        const activityTypeIds = event.entityIds;
        this.filteredActivityTypesByCategories = this.updateActivityTypeAndChildrenChecked(this.filteredActivityTypesByCategories, activityTypeIds);
    }

    public getSelectedActivityTypeIds(activityTypes: Array<ActivityTypeSelection>): Array<number> {
        if (!activityTypes) {
          return undefined;
        }
      
        const selectedIds: Array<number> = [];
      
        activityTypes.forEach(activityType => {
            if (activityType.checked) {
                selectedIds.push(activityType.id);
            }
        
            if (activityType?.children) {
                const childIds = this.getSelectedActivityTypeIds(activityType.children as any);
                selectedIds.push(...childIds);
            }
        });
      
        return selectedIds;
    }

    public updateActivityTypeTableBasedOnOrganizationUnits(orgUnitsIds: Array<number>): void {
        this.selectedOrganizationIds = orgUnitsIds;
        this.selectedOrganizationUnitIdsSubject.next(orgUnitsIds);
    }

    public updateActivityTypeTableBasedOnActivityTypeCategories(actTypeCategoryIds: Array<number>): void {
        this.selectedActivityTypeCategoryIds = actTypeCategoryIds;
        this.selectedActivityTypeCategoryIdsSubject.next(actTypeCategoryIds);
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    private updateActivityTypeAndChildrenChecked(activityTypes: Array<ActivityTypeSelection>, activityTypeIds: Array<number | string>): Array<ActivityTypeSelection> {
        return activityTypes.map(activityType => {
          const checked = activityTypeIds.includes(activityType.id);
          const children = activityType?.children ? this.updateActivityTypeAndChildrenChecked(activityType.children as any, activityTypeIds) : [];
      
            return {
                ...activityType,
                checked,
                children
            };
        });
    }

    private updateActivityTypeAndChildren(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeIds: Array<number>): Array<ActivityTypeSelection> {
        return activityTypes.map(activityType => {
          const activitySelected = selectedActivityTypeIds.includes(activityType.id);
          const updatedChildren = activityType?.children  ? this.updateActivityTypeAndChildren(activityType.children as any, selectedActivityTypeIds) : [];
          
            return {
                ...activityType,
                checked: activitySelected,
                children: updatedChildren,
                nameStyling: {
                    backgroundColor: '#' + activityType.backColor,
                    color: '#' + activityType.textColor
                }
            };
        });
    }

    private setFilteredActivityTypes(): void {
        this.subscription.add(
            combineLatest([
                this.selectedActivityTypeCategoryIdsSubject,
                this.selectedOrganizationUnitIdsSubject
            ]).pipe(
                skip(1),
                tap(([selectedActivityCategoryIds, selectedOrganizationUnits]) => {
                    this.filteredActivityTypesByCategories = this.getFilteredActivityTypesByCategories(this.activityTypes, selectedActivityCategoryIds);
                    this.filteredActvityTypesByOrganizationUnits = this.getFilteredActivityTypesByOrganizationUnits(this.filteredActivityTypesByCategories, selectedOrganizationUnits);
                    this.filteredActivityTypesSubject.next(this.filteredActvityTypesByOrganizationUnits);
                    this.numberOfActivityTypes = this.activityTypeQuery.getTotalActivityTypesCount(this.filteredActvityTypesByOrganizationUnits);
                })
            ).subscribe()
        );
    }

    private getFilteredActivityTypesByCategories(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeCategoryIds: Array<number>): Array<ActivityTypeSelection> {
        const activityTypesFilteredByCategories = activityTypes.filter(activityType => selectedActivityTypeCategoryIds ? selectedActivityTypeCategoryIds.includes(activityType.categoryId) : true);

        return activityTypesFilteredByCategories;
    }

    private getFilteredActivityTypesByOrganizationUnits(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeOrganizationUnitsIds: Array<number>): Array<ActivityTypeSelection> {
        if (selectedActivityTypeOrganizationUnitsIds === null || selectedActivityTypeOrganizationUnitsIds === undefined || selectedActivityTypeOrganizationUnitsIds.length === 0) {
            return activityTypes;
        }

        const activityTypesFilteredByOrganizationUnits = activityTypes.filter(activityType => selectedActivityTypeOrganizationUnitsIds ? selectedActivityTypeOrganizationUnitsIds.includes(activityType.ownerOrganizationUnitId) : true);

        return activityTypesFilteredByOrganizationUnits;
    }
}
