import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ReportUri } from './report-uri.model';

export interface ReportUriState extends EntityState<ReportUri> {
    ui: {
    };
}

const initialState = {
    ui: {
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'report-uri' })
export class ReportUriStore extends EntityStore<ReportUriState> {
    constructor() {
        super(initialState);
    }
}
