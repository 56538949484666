import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LanguageService } from 'src/app/shared/language';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';
import { ExcelFileManagerService } from 'src/app/shared/services/file-manager';

import { CountersExportQuery } from './counters-export.query';
import { CountersExportState, CountersExportStore } from './counters-export.store';

@Injectable({
    providedIn: 'root'
})
export class CountersExportService {
    constructor(
        private readonly http: HttpClient,
        private readonly store: CountersExportStore,
        private readonly query: CountersExportQuery,
        private readonly fileManagerService: ExcelFileManagerService,
        private readonly languageService: LanguageService,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getCountersReport(): Observable<boolean> {
        const requestFilters = this.query.getRequestFilters();
        const languageCode = this.languageService.getCurrentLanguageWithLocaleId();

        const request = this.http.post('/api/v1/Reports', { ...requestFilters, languageCode }, { responseType: 'arraybuffer' as 'arraybuffer' }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(error);
            }),
            tap((response) => {
                this.fileManagerService.exportFile(response, 'counters-report');
            }),
            map((result) => !!result)
        );

        return request;
    }

    public updateStartDate(date: string): void {
        this.store.updateStartDate(date);
    }

    public updateEndDate(date: string): void {
        this.store.updateEndDate(date);
    }

    public updateOrganizationUnitIds(ids: Array<number>): void {
        this.store.updateOrganizationUnitIds(ids);
    }

    public updateExtraResourceTypeIds(ids: Array<number>): void {
        this.store.updateExtraResourceTypeIds(ids);
    }

    public updateResourceTypeIds(ids: Array<number>): void {
        this.store.updateResourceTypeIds(ids);
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.store.updateActivityTypeIds(ids);
    }

    public updateActivityTypeCategoryIds(ids: Array<number>): void {
        this.store.updateActivityTypeCategoryIds(ids);
    }

    public updateOrganizationUnitsIdsForActivityTypes(ids: Array<number>): void {
        this.store.updateOrganizationUnitsIdsForActivityTypes(ids);
    }

    public updateExportUnplannedLeavesState(newState: boolean): void {
        this.store.updateExportUnplannedLeaves(newState);
    }

    public updateCountersState(state: CountersExportState): void {
        this.store.updateCountersState(state);
    }

    public updateValidIntervalDates(state: boolean): void {
        this.store.updateValidIntervalDates(state);
    }

    public updateSelectedFilterSettingId(id: number): void {
        this.store.updateSelectedFilterSettingId(id);
    }

    public resetState(): void {
        this.store.resetState();
    }
}
