import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SocaWebUiCommonModule } from '@ortec/soca-web-ui';
import { BehaviorSubject, Observable, Subscription, first, map, startWith } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { combineSubscriptions } from '@ortec/utilities/rxjs';

import { SharedModule } from 'src/app/shared/shared.module';
import { CounterInfoForTable } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTemplateCounterQuery } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.query';
import { ManageCounterQuery } from 'src/app/shared/stores/manage-counter-store/manage-counter.query';

export interface ICountersDialogData {
    selectedCounterIds?: Array<number>;
}

@Component({
    standalone: true,
    selector: 'app-adt-counters-dialog',
    templateUrl: './adt-counters-dialog.component.html',
    styleUrls: ['./adt-counters-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        SocaWebUiCommonModule,
        SharedModule,
    ],
})
export class AdtCountersDialogComponent implements OnInit, OnDestroy{
    public searchTerm = new FormControl('');
    public counters: Array<CounterInfoForTable> = [];
    public displayedColumns: Array<string> = ['displayName', 'addToTemplate'];
    public dataSource = new MatTableDataSource<any>([]);

    private countersSubject = new BehaviorSubject<Array<CounterInfoForTable>>([]);
    private readonly subscription = new Subscription();

    private readonly dialogRef = inject(MatDialogRef<number>);
    private readonly manageTimeslotCounterQuery = inject(ManageTemplateCounterQuery);
    private readonly manageCounterQuery = inject(ManageCounterQuery);

    public ngOnInit(): void {
        this.manageTimeslotCounterQuery.getSelectedCounterIdsForTemplate().pipe(first()).subscribe(selectedCounters => {
            this.manageCounterQuery.getCountersForTemplate(selectedCounters).pipe(first()).subscribe(checkedCounters => {
                this.countersSubject.next(checkedCounters);
                this.counters = checkedCounters;
            });
        });

        this.subscription.add(
            combineSubscriptions(
                this.getFilteredCountersBasedOnSearching()
            )
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                selectedCounterIds: this.countersSubject.getValue().filter(counter => counter.addToTemplate && !counter.disabledInAddMode).map(counter => counter.id)
            } as ICountersDialogData
        });
    }

    public onUpdateCounter(state: MatCheckboxChange, counter: CounterInfoForTable): void {
        const currentCounters = this.countersSubject.getValue();
        const index = currentCounters.findIndex(c => c.id === counter.id);
  
        if (index !== -1) {
            currentCounters[index].addToTemplate = state.checked;
            this.countersSubject.next(currentCounters);
        }
    }

    @Memoized public get counters$(): Observable<Array<CounterInfoForTable>> {
        return this.countersSubject.asObservable();
    }

    private getFilteredCountersBasedOnSearching(): Subscription {
        return this.searchTerm.valueChanges.pipe(
            startWith(''),
            map((searchTerm) => {
                const filteredCounters = this.counters
                    .filter(counter => 
                        counter.displayName.toLowerCase().includes(searchTerm.toLowerCase())
                    );
    
                this.countersSubject.next(filteredCounters);
            })
        ).subscribe();
    }
}