import { Language } from './language.model';

export const registeredLanguages: Array<Language> = [
    { code: 'en', name: 'en', codeDto: 'en-us' },
    { code: 'nl', name: 'nl', codeDto: 'nl-nl' }
];

export const defaultLanguage: Language = { code: 'en', name: 'en', codeDto: 'en-us' };

export const languageOptions = [
    { displayName: 'English (US)', id: 'en-us' },
    { displayName: 'Nederlands (Nederland)', id: 'nl-nl' },
];


export const getLanguageLabel = ((value: string) => {
    return languageOptions.find(lang => lang.id === value).displayName;
});