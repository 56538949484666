<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="holiday-item" [ngClass]="{'field-error': formGroup.invalid }" [attr.data-cy]="'HolidayItem' + index">
    <div class="holiday-date" data-cy="HolidayDate">
        <mat-form-field class="bol-form-field bol-datepicker">
            <mat-label translate>Date</mat-label>
            <input 
                matInput
                [matDatepicker]="dp"
                [formControl]="formControls.holidayDate" 
                [min]="minDate"
                [max]="maxDate"
                required 
                [attr.data-cy]="formControls.holidayDate.value"/>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            <mat-error *ngIf="formControls.holidayDate.invalid" translate>
                {{ formControls.holidayDate.errors?.required ? requiredErrorMessage : validErrorMessage }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="holiday-name" data-cy="HolidayDescription">
        <mat-form-field class="bol-form-field">
            <mat-label translate>Description</mat-label>
            <input
                #nameInput
                matInput
                [formControl]="formControls.text"
                required
                [attr.data-cy]="formControls.text.value">
            <mat-error *ngIf="formControls.text.errors?.required || formControls.text.errors?.[noEmptySpacesValidator]" translate>{{ requiredErrorMessage }}</mat-error>
        </mat-form-field>
    </div>
    
    <div class="delete-holiday-button">
        <button
            mat-button
            class="bol-button-text icon-only bol-negative"
            (click)="onDeleteHoliday(holiday)"
            [attr.data-cy]="'DeleteEntityButton' + index">
            <mat-icon>delete</mat-icon> 
        </button>
    </div>
</div>
