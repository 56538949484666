import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { filterUndefined } from '@ortec/soca-web-ui';

import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';
import { environment } from 'src/environments/environment';

import { UserInfoService } from 'src/app/shared/stores/user-info-store/user-info.service';
import { OmrpAuthenticationService } from '../authentication/omrp-authentication.service';
import { ConfigurationQuery } from 'src/app/shared/stores/configuration-store/configuration.query';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit {
    public displayName$: Observable<string>;
    public isFullUser$: Observable<boolean>;
    public rootUrl$: Observable<string>;
    public helpPath$: Observable<string>;
    public logoSrc = 'src-angularomrp/assets/ORTEC_Plus_40.svg';
    public showLanguageSelector: boolean = environment.local; 
    public featurePlanboardAPlanningBoard$: Observable<boolean>;

    private helpUrl: string;

    constructor(
        protected omrpAuthenticationService: OmrpAuthenticationService,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly userInfoService: UserInfoService,
        private readonly configurationQuery: ConfigurationQuery,
    ) { }

    public ngOnInit(): void {
        if (environment.local) {
            this.logoSrc = 'assets/ORTEC_Plus_40.svg';
        }

        this.helpPath$ = this.userInfoQuery.getUserLanguageCode().pipe(
            filterUndefined(),
            switchMap((language: string) => this.userInfoService.getHelpUrlPath(language)),
            first(),
            tap(url => this.helpUrl = url)
        );
        
        this.featurePlanboardAPlanningBoard$ = this.configurationQuery.getFeaturePlanboardAPlanningBoard();
        this.isFullUser$ = this.userInfoQuery.getIsFullUser();
        this.displayName$ = this.userInfoQuery.getDisplayName();
        this.rootUrl$ = this.userInfoQuery.getIsFullUser()
            .pipe(map((isFullUser) =>
                isFullUser ? '/omrp' : '/angularomrp/reports')
            );
    }

    public onLogout(): void {
        this.omrpAuthenticationService.logout();
    }

    public onNavigateToHelp(): void {
        window.open(this.helpUrl, "_blank");
    }

    public onNavigateToUserPreferences(): void {
        window.location.href = '/omrp#!/userPreferences';
    }
}
