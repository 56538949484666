import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { OmrpAuthGuard } from 'src/app/core/authentication/omrp-authentication.guard';
import { PermissionsGuard } from 'src/app/shared/guards/permissions.guard';
import { PERMISSIONS } from 'src/app/shared/models/Enums';
import { RerouteToAngularJSGuard } from 'src/app/shared/guards/reroute-to-angular-js.guard';

import { UsersComponent } from './users/users.component';
import { SkillsComponent } from './skills/skills.component';
import { SkillLevelsComponent } from './skill-levels/skill-levels.component';
import { ResourcePropertiesComponent } from './resource-properties/resource-properties.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { OrganizationUnitsComponent } from './organization-units/organization-units.component';
import { ResourceTypesComponent } from './resource-types/resource-types.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { OrganizationUnitsConfigurationsComponent } from './organization-units-configurations/organization-units-configurations.component';

export const programManagementRoutes: Routes = [
    {
        path: 'program-management',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.USERS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Users'
                        },
                    ],
                },
            },
            {
                path: 'user-groups',
                component: UserGroupsComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.USER_GROUPS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'User groups'
                        },
                    ],
                },
            },
            {
                path: 'skills',
                component: SkillsComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.SKILLS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Skills'
                        },
                    ],
                },
            },{
                path: 'skill-levels',
                component: SkillLevelsComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.SKILL_LEVELS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Skill levels'
                        },
                    ],
                }
            },
            {
                path: 'organization-units',
                component: OrganizationUnitsComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.ORGANIZATION_UNITS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Organization Units'
                        },
                    ],
                },
            },
            {
                path: 'resource-properties',
                component: ResourcePropertiesComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.RESOURCE_PROPERTIES,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Resource Properties'
                        },
                    ],
                },
            },
            {
                path: 'resource-types',
                component: ResourceTypesComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: PERMISSIONS.RESOURCE_TYPES,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Resource types'
                        },
                    ],
                },
            },
            {
                path: 'holidays',
                component: HolidaysComponent,
                canActivate: [PermissionsGuard, RerouteToAngularJSGuard],
                data: {
                    angularJSRoute: '/omrp#!/holidayManagement',
                    permission: PERMISSIONS.HOLIDAYS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Holidays'
                        },
                    ],
                },
            },
            {
                path: 'organization-units-configurations',
                component: OrganizationUnitsConfigurationsComponent,
                canActivate: [PermissionsGuard, RerouteToAngularJSGuard],
                data: {
                    angularJSRoute: '/omrp#!/organizationUnitSettings',
                    permission: PERMISSIONS.ORGANIZATION_UNITS,
                    breadcrumbs: [
                        {
                            route: '/omrp#!/programManagement',
                            caption: 'Program Management',
                        },
                        {
                            caption: 'Organization units configurations'
                        },
                    ],
                },
            }
        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(programManagementRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class ProgramManagementRoutingModule { }
