/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DisplaySetting } from './display-setting.model';

export interface DisplaySettingState extends EntityState<DisplaySetting> {
}

const initialState = {
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'display-setting', idKey: 'settingName' })
export class DisplaySettingStore extends EntityStore<DisplaySettingState> {
    constructor() {
        super(initialState);
    }
}
