import { Injectable } from '@angular/core';
import { EntityStore, Store } from '@datorama/akita';

export enum STATUS {
    IS_LOADING = "isLoading",
    HAS_PENDING_CHANGES = "hasPendingChanges",
    IS_FINISHED_SAVING = "isFinishedSaving",
    DEFAULT = "default"
}

export interface StatusPageState {
    status: STATUS;
}

export const initialStatusPageState: StatusPageState = {
    status: STATUS.DEFAULT
};

@Injectable({ providedIn: 'root' })
export abstract class StatusPageStore<TState extends StatusPageState> extends EntityStore<TState> {
    constructor(initialStatusPageState: TState) {
      super(initialStatusPageState);
    }

    public updatePageStatusState(status: STATUS): void {
        this.update(state => ({
            ...state,
            status: status
        }));
    }
}
