import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OmrpAuthGuard } from 'src/app/core/authentication/omrp-authentication.guard';
import { ChangesComponent } from './changes/changes.component';
import { SortingComponent } from './sorting/sorting.component';
import { FilteringComponent } from './filtering/filtering.component';
import { FilteringPaletteComponent } from './filtering-palette/filtering-palette.component';
import { SortingPaletteComponent } from './sorting-palette/sorting-palette.component';

export const planboardRoutes: Routes = [
    {
        path: 'planboard/changes',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: '',
                component: ChangesComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/omrp#!/planboard',
                            caption: 'Planboard',
                        },
                        {
                            caption: 'Changes'
                        },
                    ],
                },
            }
        ]
    },
    {
        path: 'planboard/sorting',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: '',
                component: SortingComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/omrp#!/planboard',
                            caption: 'Planboard',
                        },
                        {
                            caption: 'Sorting resources'
                        },
                    ],
                },
            }
        ]
    },
    {
        path: 'planboard/filtering',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: '',
                component: FilteringComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/omrp#!/planboard',
                            caption: 'Planboard',
                        },
                        {
                            caption: 'Filtering'
                        },
                    ],
                },
            }
        ]
    },
    {
        path: 'planboard/filter-palette',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: '',
                component: FilteringPaletteComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/omrp#!/planboard',
                            caption: 'Planboard',
                        },
                        {
                            caption: 'Filter palette'
                        },
                    ],
                },
            }
        ]
    },
    {
        path: 'planboard/sort-palette',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: '',
                component: SortingPaletteComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/omrp#!/planboard',
                            caption: 'Planboard',
                        },
                        {
                            caption: 'Sort palette'
                        },
                    ],
                },
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(planboardRoutes)],
    exports: [RouterModule]
})
export class PlanboardRoutingModule { }
