import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { OwsIntervalScheduling } from './ows-interval-scheduling.model';
import { OwsIntervalSchedulingState, OwsIntervalSchedulingStore } from './ows-interval-scheduling.store';

@Injectable({
    providedIn: 'root'
})
export class OwsIntervalSchedulingQuery extends QueryEntity<OwsIntervalSchedulingState> {

    constructor(
        protected store: OwsIntervalSchedulingStore,
    ) {
        super(store);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getIntervals(): Observable<Array<OwsIntervalScheduling>> {
        return this.selectAll();
    }

    public getIntervalsSync(): Array<OwsIntervalScheduling> {
        return Object.values(this.getValue().entities);
    }

    public getIntervalsOrderByStartTime(): Array<OwsIntervalScheduling> {
        const intervals = this.getIntervalsSync();

        return intervals.sort((a, b) => a.startMinutes - b.startMinutes);
    }
}
