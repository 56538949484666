import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Language, LanguageService } from 'src/app/shared/language';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent implements OnDestroy, OnInit {
    public languages: Array<Language> = [];
    public currentLanguageCode$: Observable<string>;

    private readonly subscription = new Subscription();

    constructor(
        public readonly languageService: LanguageService,
    ) {
        this.languages = this.languageService.getLanguages();
    }

    public ngOnInit(): void {
        this.currentLanguageCode$ = this.languageService.currentLanguage$.pipe(map(lang => lang.code));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public selectedLanguageChange(newLanguage: Language): void {
        this.languageService.setLanguage(newLanguage);
    }
}
