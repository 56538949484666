import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { RequestService } from '../../../../../shared/services/request.service';
import { GlobalSettingsQuery } from '../../../../../shared/stores/global-settings/global-settings.query';
import { Counter } from '../counters-store/counters.model';
import { CountersQuery } from '../counters-store/counters.query';
import { ActivitiesDto, convertFromActivitiesDto } from '../shared/dto-converter';
import { CountersScenarioStore } from './counters-scenario.store';

@Injectable({
    providedIn: 'root'
})
export class CountersScenarioService {
    private readonly maxDaysInCall = 14;

    constructor(
        private readonly http: HttpClient,
        protected readonly countersScenarioStore: CountersScenarioStore,
        protected readonly countersQuery: CountersQuery,
        protected readonly requestService: RequestService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly errorDialogService: ErrorDialogService,
        private readonly translateService: TranslateService,
    ) { }

    public getActivitiesForCounters(): Observable<Array<Counter>> {
        const params = this.countersQuery.getRequestParametersForScenarioSync();
        const scenarioId = this.countersQuery.getSelectedScenarioIdSync();
        const startDate = this.countersQuery.getSelectedStartDateSync();
        const endDate = this.countersQuery.getSelectedEndDateSync();
        const requestList: Array<Observable<Array<Counter>>> = [];

        const paramList = this.requestService.splitUpParamsBasedOnDates(
            params,
            startDate,
            endDate,
            'start',
            'end',
            this.maxDaysInCall
        );

        paramList.forEach(param => requestList.push(this.getRequestForParams(param, scenarioId.toString())));

        return this.requestService.loadSequential(requestList).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.LOADING-LARGE-DATA-ERROR');
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(error);
            }),
            map(counterResponses => {
                let activities: Array<Counter> = [];
                counterResponses.forEach(counters => activities = activities.concat(counters));
                this.countersScenarioStore.set(activities);

                return activities;
            })
        );
    }

    public emptyStore(): void {
        this.countersScenarioStore.set([]);
    }

    private getRequestForParams(params: HttpParams, scenarioId: string): Observable<Array<Counter>> {
        return this.http.get<ActivitiesDto>('/api/v1/Scenarios/' + scenarioId + '/Activities', { params }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(error);
            }),
            withLatestFrom(this.globalSettingsQuery.getTimeZone()),
            map(([dto, timeZone]) => convertFromActivitiesDto(dto, timeZone))
        );
    }
}
