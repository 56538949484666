import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { OwsDutySet } from './ows-duty-set.model';
import { OwsDutySetStore } from './ows-duty-set.store';

@Injectable({ providedIn: 'root' })
export class OwsDutySetService {
    constructor(
        private readonly http: HttpClient,
        protected owsDutySetStore: OwsDutySetStore
    ) { }

    public get(): Observable<void> {
        return this.http.get<Array<OwsDutySet>>('/api/OwsInterface/OwsDutySets').pipe(
            map((dutySets) => {
                const sortedDutySets = dutySets.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) && (new Date(a.validFrom) > new Date(b.validFrom)) ? 1 : -1);
                this.owsDutySetStore.set(sortedDutySets);
            }));
    }
}
