import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements OnInit {
    public error: string;
    public isFullUser$: Observable<boolean>;

    constructor(
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly userInfoQuery: UserInfoQuery
    ) { }


    public ngOnInit(): void {
        this.error = this.errorHandlerService.getError();
        this.isFullUser$ = this.userInfoQuery.getIsFullUser();
    }
}
