import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PERMISSIONS } from '../../models/Enums';
import { Permission } from './permission.model';
import { PermissionState, PermissionStore } from './permission.store';

@Injectable({
    providedIn: 'root'
})
export class PermissionQuery extends QueryEntity<PermissionState> {
    constructor(
        protected store: PermissionStore,
    ) {
        super(store);
    }

    public getPermission(permissionName: PERMISSIONS): Observable<Permission> {
        return this.selectAll().pipe(
            map((permissions: Array<Permission>) => {
                return permissions.find(permission => permission.permissionName === permissionName);
            })
        );
    }

    public getPermissionSync(permissionName: PERMISSIONS): Permission {
        const permissions = Object.values(this.getValue().entities);
        
        return permissions.find(permission => permission.permissionName === permissionName);
    }

    public getPermissions(): Observable<Array<Permission>>  {
        return this.selectAll();
    }

    public getPermissionsSync(): Array<Permission> {
        return Object.values(this.getValue().entities);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }
}
