import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { Permission } from 'src/app/shared/models/Enums';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ConfigurationQuery } from 'src/app/shared/stores/configuration-store/configuration.query';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { PermissionQuery } from 'src/app/shared/stores/permission-store/permission.query';
import { PermissionService } from 'src/app/shared/stores/permission-store/permission.service';
import { ReportUri } from 'src/app/shared/stores/report-uri-store/report-uri.model';
import { ReportUriQuery } from 'src/app/shared/stores/report-uri-store/report-uri.query';
import { ReportUriService } from 'src/app/shared/stores/report-uri-store/report-uri.service';
import { UserGroup } from 'src/app/shared/stores/user-group-store/user-group.model';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';
import { UserGroupService } from 'src/app/shared/stores/user-group-store/user-group.service';
import { UserService } from 'src/app/shared/stores/user-store/user.service';
import { PermissionsTreeStateService } from 'src/app/shared/components/inputs/permissions-tree/services/permissions-tree-state.service';

import { UserGroupsManagementQuery } from './store/user-groups-management.query';
import { UserGroupsManagementService } from './store/user-groups-management.service';
import { getUserGroupFormFields, getUserGroupSearchProperties } from './user-group-form-definition';

@Component({
    selector: 'app-user-groups',
    templateUrl: './user-groups.component.html',
    styleUrls: ['./user-groups.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupsComponent implements OnInit, OnDestroy {
    public userGroups$: Observable<Array<UserGroup>>;
    public loadingError$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public selectedUIUserGroup$: Observable<EntityUI>;
    public selectedUserGroupId$: Observable<number>;
    public selectedUserGroup$ = new BehaviorSubject<UserGroup>(undefined);
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public initialLoadingFinished$: Observable<boolean>;
    public formFields: Array<FormlyFieldConfig>;
    public searchProperties: Array<string>;
    public canDeleteSelectedEntity$: Observable<boolean>;
    public canCloneSelectedEntity$: Observable<boolean>;
    public reportUris$: Observable<Array<ReportUri>>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly userGroupService: UserGroupService,
        private readonly userGroupQuery: UserGroupQuery,
        private readonly userGroupsManagementQuery: UserGroupsManagementQuery,
        private readonly userGroupsManagementService: UserGroupsManagementService,
        private readonly userService: UserService,
        private readonly utilsService: UtilsService,
        private readonly configurationQuery: ConfigurationQuery,
        private readonly reportUriService: ReportUriService,
        private readonly reportUriQuery: ReportUriQuery,
        private readonly permissionService: PermissionService,
        private readonly permissionQuery: PermissionQuery,
        private readonly autosaveService: AutosaveService,
        private readonly translateService: TranslateService,
        private readonly permissionsTreeStateService: PermissionsTreeStateService
    ) { }

    public ngOnInit(): void {
        this.searchProperties = getUserGroupSearchProperties();

        this.userGroupService.getUserGroups().pipe(first()).subscribe();
        this.permissionService.getPermissions().pipe(first()).subscribe();
        this.userService.getUsers().pipe(first()).subscribe();

        if (this.configurationQuery.getSSRSReportsConfigurationEnabledSync()) {
            this.reportUriService.getReportUris().pipe(first()).subscribe();
        }

        this.loadingError$ = this.userGroupQuery.selectError();
        this.selectedUIUserGroup$ = this.userGroupsManagementQuery.getselectedUIUserGroup();
        this.selectedUserGroupId$ = this.userGroupsManagementQuery.getCurrentUserGroupId();
        this.manageMode$ = this.userGroupsManagementQuery.getManageMode();
        this.userGroups$ = this.userGroupQuery.getUserGroupsForFiltering();
        this.initialLoadingFinished$ = this.userGroupsManagementQuery.allEntitiesLoaded();
        this.canDeleteSelectedEntity$ = this.userGroupsManagementQuery.getCanDeleteSelectedEntityState();
        this.canCloneSelectedEntity$ = this.userGroupsManagementQuery.getCanCloneSelectedEntityState();
        this.reportUris$ = this.reportUriQuery.getReportUris().pipe(
            map(reportUris => reportUris?.map(reportUri => ({...reportUri, children: undefined})))
        );

        this.subscription.add(
            this.userGroupsManagementQuery.getSelectedUserGroup().pipe(
                tap(user => this.selectedUserGroup$.next(user))
            ).subscribe()
        );

        this.setUserGroupFormFields();
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
        this.permissionsTreeStateService.clearExpandedNodeIds();
    }

    public onSelectedUserGroupChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.userGroupService.setUserGroupToCleanUIState(id);

        if (this.userGroupsManagementQuery.getCurrentUserGroupIdSync() !== id) {
            this.userGroupsManagementService.updateManageMode(undefined);
            this.showLoadingSpinner$.next(true);
            this.userGroupsManagementService.updateSelectedUserGroupId(id);
        }
    }

    public onAddNewUserGroup(): void {
        const newUserGroup = this.userGroupsManagementQuery.getNewUserGroupObject();

        this.showLoadingSpinner$.next(true);
        this.userGroupService.saveUserGroup(newUserGroup).pipe(
            tap((userGroupAdded: UserGroup) => {
                this.showLoadingSpinner$.next(false);
                this.userGroupsManagementService.setStateForNewUserGroup(userGroupAdded.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    }

    public onDeleteUserGroup(userGroupId: number): void {
        this.showLoadingSpinner$.next(true);
        this.userGroupService.deleteUserGroup(userGroupId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.userGroupsManagementService.updateSelectedUserGroupId(undefined);
                this.userGroupsManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onCloneUserGroup(userGroupId: number): void {
        this.showLoadingSpinner$.next(true);

        const lastCorrectVersionOfUserGroup = this.userGroupQuery.getUserGroupSync(userGroupId);
        this.userGroupService.cloneUserGroup(
            lastCorrectVersionOfUserGroup,
            this.userGroupQuery.getUserGroupsSync().map(group => group.displayName)
        ).pipe(
            tap((clonedUserGroup: UserGroup) => {
                this.showLoadingSpinner$.next(false);
                this.userGroupsManagementService.updateSelectedUserGroupId(clonedUserGroup.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    }

    public onEditUserGroup(userGroup: UserGroup): void {
        this.userGroupService.setUserGroupToPendingChanges(userGroup.id);
        this.autosaveService.autoSave(userGroup, this.saveUserGroup.bind(this));
    }

    private saveUserGroup(userGroup: UserGroup): void {
        this.userGroupService.updateUserGroup(userGroup).pipe(
            first(),
        ).subscribe({
            error: this.handleSaveError.bind(this)
        });
    }

    private handleSaveError(userGroup: UserGroup): void {
        if (this.userGroupsManagementQuery.getCurrentUserGroupIdSync() === userGroup.id) {
            this.selectedUserGroup$.next({...userGroup});
        }
    }

    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }

    private getPermissionLabels(): Observable<Array<{ id: number; displayName: string }>> {
        return this.permissionQuery.getPermissions().pipe(
            map(permissions => permissions.map(permission => ({
                id: permission.id,
                displayName: this.translateService.instant('PERM_' + permission.permissionName),
            })))
        );
    }

    private setUserGroupFormFields(): void {
        this.subscription.add(
            this.userGroupsManagementQuery.getCurrentUserGroupId().pipe(
                filter((currentUserGroupId) => !!currentUserGroupId),
                switchMap((currentUserGroupId) => combineLatest([
                    this.userGroupQuery.getUserGroupsForFilteringWithEmpty(),
                    this.userGroupService.getUsersForUserGroup(currentUserGroupId),
                    this.getPermissionLabels(),
                    this.reportUris$
                ]).pipe(
                    map(([userGroupTrees, usersForSelectedUserGroup, permissions, reportUris]) => {
                        this.userGroupsManagementService.updateSelectedUsers(usersForSelectedUserGroup);

                        return {
                            currentUserGroupId,
                            userGroupTrees,
                            usersForSelectedUserGroup,
                            permissions,
                            reportUris
                        };
                    }),
                    first()
                )),
                filter(({ userGroupTrees, permissions}) => userGroupTrees?.length > 1 && permissions?.length > 0),
            ).subscribe(({ currentUserGroupId, userGroupTrees, usersForSelectedUserGroup, permissions, reportUris }) => {
                const userGroups = this.userGroupQuery.getUserGroupsSync();
                const isSelectedItemWritable = this.userGroupsManagementQuery.getSelectedUserGroupSync()?.maxPermissionForCurrentUser >= Permission.owner;
                this.userGroupsManagementService.updateManageMode(MANAGE_MODE.EDIT);
                this.showLoadingSpinner$.next(false);

                this.formFields = getUserGroupFormFields(
                    userGroups,
                    userGroupTrees,
                    isSelectedItemWritable,
                    usersForSelectedUserGroup,
                    permissions,
                    currentUserGroupId,
                    !this.configurationQuery.getSSRSReportsConfigurationEnabledSync(),
                    reportUris
                );
            })
        );
    }
}
