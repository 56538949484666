 <!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div class="filters-header">
    <div *ngIf="(filterSettings$ | async) && (filterSettings$ | async).length > 0" class="filters-field">
        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
            <mat-label>{{ 'Filters' | translate }}</mat-label>
            <mat-select
                #filterSettingSelect
                (openedChange)="openedFilterSettingSelect($event)"
                [formControl]="filterSettingControl">
                <mat-option
                    *ngFor="let filterSetting of filterSettings$ | async"
                    [value]="filterSetting.id">
                    {{ filterSetting.displayName }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="filterSettingControl.invalid" translate>Field is required</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="!(filterSettings$ | async).length" class="highlight-summary">
        <div class="no-filter-selected">{{ 'No filter selected' | translate }}</div>
    </div>

    <div class="counter-details">
        <div class="counter-details-fields">
            <div *ngIf="selectedStartDate$ | async as start" class="selectedStartDate">
                <div class="highlight-summary">{{ 'Start' | translate }}</div>&nbsp;{{ start | date: (dateFormat$ | async) }}
            </div>
        
            <div *ngIf="selectedEndDate$ | async as end" class="selectedEndDate">
                <div class="highlight-summary">{{ 'End' | translate }}</div>&nbsp;{{ end | date: (dateFormat$ | async) }}
            </div>
        
            <div *ngIf="selectedScenario$ | async as scenario" class="selectedScenario">
                <div class="highlight-summary">{{ 'Scenario' | translate }}</div>&nbsp;
                {{ scenario.displayName }}
                {{ scenario.start | date: (dateFormat$ | async) }} -
                {{ scenario.end | date: (dateFormat$ | async) }}
            </div>
        
            <div *ngIf="!(scenarioValidity$ | async)" class="scenarioValidity">
                <div class="highlight-summary">{{ 'No scenario selected' | translate }}</div>
            </div>
        </div>

        <div *ngIf="countersVisibleSubject | async" class="counters-explanation">
            <span>{{countersExplanation}}</span>
        </div>
    </div>

    <div class="counters-button">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            (click)="onGetCounters()"
            [disabled]="!(countersFiltersValidity$ | async)">
            <span translate>Calculate Counters</span>
        </button>
    </div>
</div>
