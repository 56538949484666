import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SocaWebUiCommonModule } from '@ortec/soca-web-ui';

import { SharedModule } from 'src/app/shared/shared.module';
import { LoadingStatusIndicationComponent } from 'src/app/shared/components/loading-status-indication/loading-status-indication.component';

import { ProgramManagementRoutingModule } from './program-management-routing.module';
import { UsersComponent } from './users/users.component';
import { UsersFiltersComponent } from './users/components/users-filters/users-filters.component';
import { SkillsComponent } from './skills/skills.component';
import { SkillsFiltersComponent } from './skills/components/skills-filters/skills-filters.component';
import { ResourcePropertiesComponent } from './resource-properties/resource-properties.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { OrganizationUnitsComponent } from './organization-units/organization-units.component';
import { SkillLevelsComponent } from './skill-levels/skill-levels.component';
import { SkillLevelsFiltersComponent } from './skill-levels/components/skill-levels-filters.component';
import { ResourceTypesFiltersComponent } from './resource-types/components/resource-types-filters.component';
import { ResourceTypesComponent } from './resource-types/resource-types.component';
import { HolidayItemComponent } from './holidays/holiday-item/holiday-item.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { OrganizationUnitsConfigurationsComponent } from './organization-units-configurations/organization-units-configurations.component';

@NgModule({
    declarations: [
        UsersComponent,
        UsersFiltersComponent,
        SkillsComponent,
        SkillsFiltersComponent,
        SkillLevelsComponent,
        SkillLevelsFiltersComponent,
        ResourcePropertiesComponent,
        ResourceTypesFiltersComponent,
        ResourceTypesComponent,
        UserGroupsComponent,
        OrganizationUnitsComponent,
        HolidayItemComponent,
        HolidaysComponent,
        OrganizationUnitsConfigurationsComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        SharedModule,
        ProgramManagementRoutingModule,
        SocaWebUiCommonModule,
        LoadingStatusIndicationComponent
    ]
})
export class ProgramManagementModule { }
