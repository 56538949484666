import { Injectable } from '@angular/core';
import { EntityState, Store, StoreConfig } from '@datorama/akita';
import { SortableResource } from './sorting.model';
import { STATUS, StatusPageState, StatusPageStore, initialStatusPageState } from 'src/app/shared/stores/status-page-store/status-page.store';

export interface SortingState extends EntityState<SortableResource>, StatusPageState {
    ui: {
        selectedResourceTypeId: number;
        selectedResourcePropertiesIds: Array<number>;
        entitiesResourcesLoading: boolean;
        entitiesSelectedResourcesLoading: boolean;
        selectedSortableResourceIdsList: Array<number>;
        selectedUnsortedResourcesIdsList: Array<number>;
        sortDescending: boolean;
    };
    resourcesForSelectedResourceType: Array<SortableResource>;
    selectedSortableResourcesIds: Array<number>;
    sortableResources: Array<SortableResource>;
    unsortableResources: Array<SortableResource>;
}

const initialStateSorting = {
    ui: {
        selectedResourceTypeId: undefined,
        selectedResourcePropertiesIds: [-1,-2],
        entitiesResourcesLoading: undefined,
        entitiesSelectedResourcesLoading: undefined,
        selectedSortableResourceIdsList: [],
        selectedUnsortedResourcesIdsList: [],
        sortDescending: false
    },
    resourcesForSelectedResourceType: [],
    selectedSortableResourcesIds: [],
    sortableResources: [],
    unsortableResources: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sorting' })
export class SortingStore extends StatusPageStore<SortingState> {
    constructor() {
        super({...initialStateSorting, ...initialStatusPageState});
    }

    public setResourcesForSelectedResourceType(resourcesForSelectedResourceType: Array<SortableResource>): void {
        this.update(state => ({ ...state, resourcesForSelectedResourceType }));
    }

    public setSelectedSortableResourcesIds(selectedSortableResourcesIds: Array<number>, addIds: boolean, replaceIds: boolean = false): void {
        this.update(state => {
            let updatedIds: Array<number> = [...state.selectedSortableResourcesIds];
    
            if (addIds) {
                updatedIds = [
                    ...updatedIds,
                    ...selectedSortableResourcesIds.filter(id => !updatedIds.includes(id))
                ];
            } else {
                updatedIds = updatedIds.filter(id => !selectedSortableResourcesIds.includes(id));
            }
    
            if (replaceIds) {
                updatedIds = selectedSortableResourcesIds;
            }

            return {
                ...state,
                selectedSortableResourcesIds: updatedIds
            };
        });
    }

    public updateSelectedResourceTypeId(selectedResourceTypeId: number): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedResourceTypeId }
        }));
    }
    
    public updateSelectedResourcePropertiesIds(selectedResourcePropertiesIds: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: { ...state.ui, selectedResourcePropertiesIds }
        }));
    }
    
    public updateEntitiesResourcesLoading(entitiesResourcesLoading: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesResourcesLoading }
        }));
    }

    public updateEntitiesSelectedResourcesLoading(entitiesSelectedResourcesLoading: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, entitiesSelectedResourcesLoading }
        }));
    }

    public updateSortDescending(sortDescending: boolean): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, sortDescending }
        }));
    }
    
    public updateSelectedSortableResourceIdsList(selectedSortableResourceIdsList: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedSortableResourceIdsList }
        }));
    }

    public updateSelectedUnsortedResourcesIdsList(selectedUnsortedResourcesIdsList: Array<number>): void {
        this.update(state => ({
            ...state,
            ui: {...state.ui, selectedUnsortedResourcesIdsList }
        }));
    }
}
