import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { IUserGroupTree } from 'src/app/shared/stores/user-group-store/user-group.model';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';
import { UserGroupService } from 'src/app/shared/stores/user-group-store/user-group.service';
import { User } from 'src/app/shared/stores/user-store/user.model';
import { UserQuery } from 'src/app/shared/stores/user-store/user.query';
import { UserService } from 'src/app/shared/stores/user-store/user.service';

import { UsersManagementQuery } from './store/users-management.query';
import { UsersManagementService } from './store/users-management.service';
import { getUserFormFields, getUserSearchProperties, isUserEditable  } from './users-form-definition';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit, OnDestroy {

    public filteredUsers$: Observable<Array<User>>;
    public loadingError$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public selectedUIUser$: Observable<EntityUI>;
    public selectedUserId$: Observable<number>;
    public selectedUser$ = new BehaviorSubject<User>(undefined);
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public initialLoadingFinished$: Observable<boolean>;
    public formFields: Array<FormlyFieldConfig>;
    public searchProperties: Array<string>;
    public entityManageMessage$!: Observable<string>;
    public canDeleteSelectedEntity$: Observable<boolean>;

    private readonly userNotEditableMessage = 'No write permissions for the selected user.';
    private readonly subscription = new Subscription();

    constructor(
        private readonly userService: UserService,
        private readonly userQuery: UserQuery,
        private readonly userGroupService: UserGroupService,
        private readonly userGroupQuery: UserGroupQuery,
        private readonly usersManagementQuery: UsersManagementQuery,
        private readonly usersManagementService: UsersManagementService,
        private readonly autosaveService: AutosaveService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService
    ) { }

    public ngOnInit(): void {
        this.userService.getUsers().pipe(first()).subscribe();

        this.searchProperties = getUserSearchProperties();

        this.loadingError$ = this.userQuery.selectError();
        this.selectedUIUser$ = this.usersManagementQuery.getselectedUIUser();
        this.selectedUserId$ = this.usersManagementQuery.getCurrentUserId();
        this.manageMode$ = this.usersManagementQuery.getManageMode();
        this.filteredUsers$ = this.usersManagementQuery.getFilteredUsers();
        this.initialLoadingFinished$ = this.usersManagementQuery.allEntitiesLoaded();
        this.canDeleteSelectedEntity$ = this.usersManagementQuery.getCanDeleteSelectedEntity();

        this.subscription.add(
            this.usersManagementQuery.getSelectedUser().pipe(
                tap(user => this.selectedUser$.next(user))
            ).subscribe()
        );

        merge(
            this.userGroupService.getUserGroupNames().pipe(filter(userGroups => userGroups.length === 0), map(() => [])),
            this.userGroupQuery.getUserGroupsForFiltering()
        ).pipe(
            first(),
            tap(userGroupsTrees => {
                const userGroups = this.userGroupQuery.getUserGroupsSync();

                this.formFields = getUserFormFields(userGroups, userGroupsTrees, this.globalSettingsQuery.getSystemLanguageSync(), this.translateService, this.disableUserGroups);
            })
        ).subscribe();

        this.entityManageMessage$ = this.usersManagementQuery.getCurrentUserId().pipe(
            map(id => {
                if (id && id !== -1) {
                    const isUserEditableStatus = isUserEditable(this.userQuery.getUserSync(id), this.userGroupQuery.getUserGroupsSync());

                    return isUserEditableStatus ? undefined : this.userNotEditableMessage; 
                }

                return undefined;
            })
        );
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
    }

    public onSelectedUserChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.userService.setUserToCleanUIState(id);

        let manageMode: MANAGE_MODE;
        if (id) {
            const isUserEditableStatus = isUserEditable(this.userQuery.getUserSync(id), this.userGroupQuery.getUserGroupsSync());
            manageMode = isUserEditableStatus ? MANAGE_MODE.EDIT : MANAGE_MODE.VIEW; 
        }

        this.usersManagementService.updateManageMode(manageMode);
        this.usersManagementService.updateSelectedUserId(id);
    }

    public onAddEntityClicked(): void {
        this.autosaveService.saveUnsavedChanges();
        this.usersManagementService.updateManageMode(MANAGE_MODE.ADD);
        this.usersManagementService.updateSelectedUserId(this.usersManagementQuery.getNewUserObject().id);
    }

    public onCancelAdd(): void {
        this.usersManagementService.updateManageMode(undefined);
        this.usersManagementService.updateSelectedUserId(undefined);
    };

    public onDeleteUser(userId: number): void {
        this.showLoadingSpinner$.next(true);
        this.userService.deleteUser(userId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.usersManagementService.updateSelectedUserId(undefined);
                this.usersManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onAddNewUser(user: User): void {
        this.showLoadingSpinner$.next(true);
        this.userService.updateUserWithUserGroupsChanged(user, true).pipe(
            tap((addedUser: User) => {
                this.showLoadingSpinner$.next(false);
                this.usersManagementService.setStateForNewUser(addedUser.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onEditUser(user: User): void {
        this.userService.setUserToPendingChanges(user.id);
        this.autosaveService.autoSave(user, this.saveUser.bind(this));
    }

    private readonly disableUserGroups = (selectedIds: any, entities: Array<any>): Array<any> => {
        return this.filterUserGroupsByPermissions(entities);
    };

    private filterUserGroupsByPermissions(userGroups: Array<IUserGroupTree>): Array<IUserGroupTree> {
        const filteredUserGroups: Array<IUserGroupTree> = [];

        userGroups?.forEach(userGroup => {
            if (userGroup.maxPermissionForCurrentUser > 1) {
                filteredUserGroups.push(userGroup);
            }
    
            if (userGroup.children) {
                filteredUserGroups.push(...this.filterUserGroupsByPermissions(userGroup.children));
            }
        });

        return filteredUserGroups;
    };

    private saveUser(user: User): void {
        this.userService.updateUserWithUserGroupsChanged(user).pipe(
            first(),
        ).subscribe({
            next: () => {
                const userVisible = this.usersManagementQuery.isUserVisible(user.id);
                if (user.id === this.usersManagementQuery.getCurrentUserIdSync() && !userVisible) {
                    this.notificationService.showEntityDisabledMessage('users');
                }
            },
            error: this.handleSaveError.bind(this)
        });
    }

    private handleSaveError(user: User): void {
        if (this.usersManagementQuery.getCurrentUserIdSync() === user.id) {
            this.selectedUser$.next({...user});
        }
    }

    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }
}
