import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { RESOURCE_PROPERTY_TYPE, ResourcePropertyLanguage } from './resource-property-language.model';
import { ResourcePropertyLanguageStore } from './resource-property-language.store';

@Injectable({
    providedIn: 'root'
})
export class ResourcePropertyLanguageService {
    constructor(
        protected resourcePropertyLanguageStore: ResourcePropertyLanguageStore,
        private readonly http: HttpClient,
    ) { }

    public getResourcePropertiesByLanguageCode(languageCode: string, standardResporceProperties?: Array<ResourcePropertyLanguage>) {
        this.resourcePropertyLanguageStore.updateEntitiesLoadingState(true);
        const standardProperties = !!standardResporceProperties ? standardResporceProperties : [];

        return this.http.get<Array<ResourcePropertyLanguage>>(`/api/ResourceProperties/${languageCode}`).pipe(
            tap(resourceExtraProperties => {
                const resourcePropertiesLanguage: Array<ResourcePropertyLanguage> = [
                    ...resourceExtraProperties.map(rp => ({ ...rp, resourcePropertyType: RESOURCE_PROPERTY_TYPE.EXTRA_PROPERTY })),
                    ...standardProperties
                ];

                this.resourcePropertyLanguageStore.set(resourcePropertiesLanguage);
            }),
            tap(() => this.resourcePropertyLanguageStore.updateEntitiesLoadingState(false))
        );
    }
}
