import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Permission } from './permission.model';
import { PermissionStore } from './permission.store';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(
        protected permissionStore: PermissionStore,
        private readonly http: HttpClient,
    ) { }

    public getPermissions() {
        this.permissionStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<Permission>>('/api/Permissions').pipe(
            map((permissions) => {
                this.permissionStore.set(permissions);

                this.permissionStore.updateEntitiesLoadingState(false);
            })
        );
    }
}
