import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GlobalSettings } from './global-settings.model';
import { GlobalSettingsStore } from './global-settings.store';

interface GlobalSettingsDto {
    timeZone: string;
    systemLanguage: string;
}

@Injectable({
    providedIn: 'root'
})
export class GlobalSettingsService {
    constructor(
        private readonly http: HttpClient,
        protected globalSettingsStore: GlobalSettingsStore,
    ) { }

    // error handling it s already done in app.component
    public getGlobalSettings(): Observable<GlobalSettings> {
        return this.http.get<GlobalSettingsDto>('/api/v1/GlobalSettings').pipe(
            map((dto) => this.convertFromDto(dto)),
            tap((globalSettings) => this.globalSettingsStore.setGlobalSettings(globalSettings))
        );
    }

    private convertFromDto(globalSettingsDto: GlobalSettingsDto): GlobalSettings {
        const result: GlobalSettings = {
            timeZone: globalSettingsDto.timeZone,
            systemLanguage: globalSettingsDto.systemLanguage
        };
    
        return result;
    }    
}
