/* eslint-disable complexity */
import { Injectable } from '@angular/core';

import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CountersQuery } from '../stores/counters-store/counters.query';
import { ColumnDefinition, CounterData } from '../stores/counters-store/counters-matrix.models';
import { COUNTERS_EXTRA_GROUPING_OPTIONS, COUNTERS_GROUPING_OPTIONS } from './enums';

import { CountersCalculationService } from './counters-calculation.service';
import { CountersResourceTreeHelperService } from './counters-resource-tree-helper.service';

@Injectable({
    providedIn: 'root'
})
export class CountersResourceTreeService {

    constructor(
        protected countersQuery: CountersQuery,
        protected resourceQuery: ResourceQuery,
        private readonly calculationService: CountersCalculationService,
        private readonly helperService: CountersResourceTreeHelperService,
        private readonly utilsService: UtilsService
    ) { }

    public getResourceTree(columnDefinitions: Array<ColumnDefinition>): Array<CounterData> {
        const groupingOptions = this.countersQuery.getGroupingOptionsSync();
        const indexOfOUGroupingOption = groupingOptions.findIndex(go => go === COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT);
        let resourceTree: Array<CounterData> = [];

        groupingOptions.forEach((groupingOption) => {
            const counters = this.helperService.getGroupingOptionCounters(groupingOption);
            let addToOrganizationUnits = false;

            if (indexOfOUGroupingOption !== -1 && indexOfOUGroupingOption !== groupingOptions.length - 1) { // OU is not the last grouping option
                const groupingOptionFollowingOU = groupingOptions[indexOfOUGroupingOption + 1];
                addToOrganizationUnits = groupingOption === groupingOptionFollowingOU;
            }

            resourceTree = this.addToEndOfBranches(resourceTree, counters, groupingOption, addToOrganizationUnits);
        });

        const resourceCounters = this.helperService.getResourceCounters();
        const lastGroupingOption = groupingOptions[groupingOptions.length - 1];
        resourceTree = this.addOrganizationUnitIdsToChildren(resourceTree);
        resourceTree = this.addResourcesToBranches(resourceTree, resourceCounters, lastGroupingOption);

        const activityTypeDetailEnabled = this.countersQuery.getActivityTypeDetailsStateSync();
        if (activityTypeDetailEnabled && !groupingOptions.includes(COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE)) {
            const activityCounters = this.helperService.getActivityCounters();
            resourceTree = this.addActivitiesToResources(resourceTree, activityCounters);
        }

        if (resourceTree.length > 0) {
            resourceTree = this.calculationService.fillResourceTreeWithData(resourceTree, columnDefinitions);
        }

        resourceTree = resourceTree.map((resource) =>
            this.calculateIfOnlyZeroDataValues(resource)
        );

        return resourceTree;
    }

    private calculateIfOnlyZeroDataValues(resource: CounterData): CounterData {
        const nonZeroRegex = /[^0\s\/-]/;
        const hasNonZeroCharacters = resource.data.some(value => {
            return nonZeroRegex.test(value);
        });

        const updatedResource = {
            ...resource,
            hasZeroValue: !hasNonZeroCharacters,
        };

        if (updatedResource.children && updatedResource.children.length > 0) {
            updatedResource.children = updatedResource.children.map(child => {
                return this.calculateIfOnlyZeroDataValues(child);
            });
        }

        return updatedResource;
    }

    private addToEndOfBranches(
        tree: Array<CounterData>,
        childrenToAdd: Array<CounterData>,
        childrenGroupingOption: COUNTERS_GROUPING_OPTIONS,
        addToOrganizationUnits: boolean
    ): Array<CounterData> {
        // note: we have to make deep copies of the children, so they don't refer to the same object
        if (tree.length === 0) {
            return childrenToAdd;
        }
        else if (childrenToAdd.length > 0) {
            tree.forEach(branch => {

                if (branch.groupingOptionType === COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT
                    && addToOrganizationUnits && childrenToAdd[0].groupingOptionType === childrenGroupingOption) {

                    if (branch.children) {
                        let copiedChildrenToAdd = this.utilsService.deepCopy(childrenToAdd);
                        copiedChildrenToAdd = this.filterChildrenToAdd(branch.activityTypeCategoryId, copiedChildrenToAdd, childrenGroupingOption);
                        copiedChildrenToAdd.forEach(child => {
                            branch.children.push(child);
                        });

                    } else if (!branch.children) {
                        let copiedChildrenToAdd = this.utilsService.deepCopy(childrenToAdd);
                        copiedChildrenToAdd = this.filterChildrenToAdd(branch.activityTypeCategoryId, copiedChildrenToAdd, childrenGroupingOption);
                        branch.children = copiedChildrenToAdd;
                    }
                }

                if (branch.children && childrenToAdd) {
                    this.addToEndOfBranches(branch.children, childrenToAdd, childrenGroupingOption, addToOrganizationUnits);
                }

                else if (branch.groupingOptionType !== COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT && branch.groupingOptionType !== childrenGroupingOption) {

                    let copiedChildrenToAdd = this.utilsService.deepCopy(childrenToAdd);
                    copiedChildrenToAdd = this.filterChildrenToAdd(branch.activityTypeCategoryId, copiedChildrenToAdd, childrenGroupingOption);

                    branch.children = copiedChildrenToAdd;
                }
            });
        }

        return tree;
    }

    private addOrganizationUnitIdsToChildren(tree: Array<CounterData>): Array<CounterData> {
        tree.forEach(branch => {
            if (branch.children && branch.children.length) {
                if (branch.groupingOptionType === COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT) {
                    this.addOrganizationUnitIds(branch.children, branch.organizationUnitId);
                }

                this.addOrganizationUnitIdsToChildren(branch.children);
            }
        });

        return tree;
    }

    private addOrganizationUnitIds(tree: Array<CounterData>, id: number) {
        tree.forEach(branch => {
            if (branch.organizationUnitId === undefined) {
                branch.organizationUnitId = id;
                if (branch.children && branch.children.length) {
                    this.addOrganizationUnitIds(branch.children, id);
                }
            }
        });
    }

    private filterChildrenToAdd(
        branchActivityTypeCategoryId: number,
        childrenToAdd: Array<CounterData>,
        childrenGroupingOption: COUNTERS_GROUPING_OPTIONS
    ): Array<CounterData> {
        if (branchActivityTypeCategoryId &&
            childrenGroupingOption !== COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY &&
            childrenGroupingOption !== COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE
        ) {
            childrenToAdd.forEach(child => child.activityTypeCategoryId = branchActivityTypeCategoryId);
        }

        if ((childrenGroupingOption === COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE ||
            childrenGroupingOption === COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY) &&
            branchActivityTypeCategoryId
        ) {
            childrenToAdd = childrenToAdd.filter(child => child.activityTypeCategoryId === branchActivityTypeCategoryId);
        }

        return childrenToAdd;
    }

    private addResourcesToBranches(
        tree: Array<CounterData>,
        resources: Array<CounterData>,
        lastGroupingOption: COUNTERS_GROUPING_OPTIONS
    ): Array<CounterData> {
        // note: we have to make deep copies of the resources, so they don't refer to the same object
        tree.forEach(branch => {
            const copiedResources = this.utilsService.deepCopy(resources);

            switch (branch.groupingOptionType) {
                case COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT:
                    if (lastGroupingOption === COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT) {
                        const filteredResourcesForOrgUnit = this.filterResourcesForOrganizationUnit(copiedResources, branch.organizationUnitId);

                        if (branch.children) {
                            filteredResourcesForOrgUnit.forEach(child => {
                                branch.children.push(child);
                            });
                            this.addResourcesToBranches(branch.children, resources, lastGroupingOption);
                        } else {
                            branch.children = filteredResourcesForOrgUnit;
                        }
                    } else if (branch.children && branch.children.length > 0) {
                        this.addResourcesToBranches(branch.children, resources, lastGroupingOption);
                    }
                    break;

                case COUNTERS_GROUPING_OPTIONS.RESOURCE_TYPE:
                    const validResourceIdsForResourceTypes = this.resourceQuery.getValidResourceIdsForResourceType(branch.resourceTypeId);
                    let filteredResourcesForResourceTypes = copiedResources.filter(r => validResourceIdsForResourceTypes.includes(r.resourceId));

                    if (lastGroupingOption === COUNTERS_GROUPING_OPTIONS.RESOURCE_TYPE) {
                        filteredResourcesForResourceTypes = this.filterResourcesForOrganizationUnit(filteredResourcesForResourceTypes, branch.organizationUnitId);
                        branch.children = filteredResourcesForResourceTypes;
                    } else if (branch.children && branch.children.length > 0) {
                        this.addResourcesToBranches(branch.children, filteredResourcesForResourceTypes, lastGroupingOption);
                    }
                    break;

                case COUNTERS_GROUPING_OPTIONS.SKILL:
                    const validResourceIds = this.resourceQuery.getValidResourceIdsForSkill(branch.skillId);
                    let filteredResourcesForSkills = copiedResources.filter(r => validResourceIds.includes(r.resourceId));

                    if (lastGroupingOption === COUNTERS_GROUPING_OPTIONS.SKILL) {
                        filteredResourcesForSkills = this.filterResourcesForOrganizationUnit(filteredResourcesForSkills, branch.organizationUnitId);
                        branch.children = filteredResourcesForSkills;
                    } else if (branch.children && branch.children.length > 0) {
                        this.addResourcesToBranches(branch.children, filteredResourcesForSkills, lastGroupingOption);
                    }
                    break;

                case COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY:
                case COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE:
                    let filteredResourcesForActivities = copiedResources;
                    if (lastGroupingOption === branch.groupingOptionType) {
                        filteredResourcesForActivities = this.filterResourcesForOrganizationUnit(filteredResourcesForActivities, branch.organizationUnitId);
                        branch.children = filteredResourcesForActivities;
                    } else if (branch.children && branch.children.length > 0) {
                        this.addResourcesToBranches(branch.children, copiedResources, lastGroupingOption);
                    }
                    break;

                case COUNTERS_GROUPING_OPTIONS.DAY_PART:
                    let filteredResourcesDayparts = copiedResources;
                    if (lastGroupingOption === branch.groupingOptionType) {
                        filteredResourcesDayparts = this.filterResourcesForOrganizationUnit(filteredResourcesDayparts, branch.organizationUnitId);
                        branch.children = filteredResourcesDayparts;
                    } else if (branch.children && branch.children.length > 0) {
                        this.addResourcesToBranches(branch.children, filteredResourcesDayparts, lastGroupingOption);
                    }
                    break;


                default:
                    break;
            }
        });

        return tree;
    }

    private filterResourcesForOrganizationUnit(resources: Array<CounterData>, organizationUnitId: number): Array<CounterData> {
        if (organizationUnitId !== undefined) {
            const validResourceIdsByOrganizationUnit = this.resourceQuery.getValidResourceIdsForOrganizationUnit(organizationUnitId);
            const filteredResources = resources.filter(r => validResourceIdsByOrganizationUnit.includes(r.resourceId));
            filteredResources.forEach(resource => {
                resource.organizationUnitId = organizationUnitId;
            });

            return filteredResources;
        }

        return resources;
    }

    private addActivitiesToResources(tree: Array<CounterData>, activities: Array<CounterData>): Array<CounterData> {
        // note: we have to make deep copies of the activities, so they don't refer to the same object
        tree.forEach(branch => {
            if (branch.groupingOptionType === COUNTERS_EXTRA_GROUPING_OPTIONS.RESOURCE) {
                const copiedActivities = this.utilsService.deepCopy(activities);
                branch.children = copiedActivities;
            }

            if (branch.children && branch.children.length > 0) {
                if (branch.groupingOptionType === COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY) {
                    const filteredActivities = activities.filter(a => a.activityTypeCategoryId === branch.activityTypeCategoryId);
                    this.addActivitiesToResources(branch.children, filteredActivities);
                }
                else {
                    this.addActivitiesToResources(branch.children, activities);
                }
            }
        });

        return tree;
    }
}
