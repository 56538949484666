import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwsDepartmentQuery } from '../../ows-department-mapping/ows-department-store/ows-department.query';

import { OwsDutySet, OwsDutySetForFiltering } from './ows-duty-set.model';
import { OwsDutySetState, OwsDutySetStore } from './ows-duty-set.store';
import { LanguageService } from 'src/app/shared/language';

@Injectable({
    providedIn: 'root'
})
export class OwsDutySetQuery extends QueryEntity<OwsDutySetState> {

    constructor(
        protected store: OwsDutySetStore,
        private readonly owsDepartmentQuery: OwsDepartmentQuery,
        protected languageService: LanguageService
    ) {
        super(store);
    }

    public getDutySetsForFiltering(): Observable<Array<OwsDutySetForFiltering>> {
        return combineLatest([
            this.selectAll(),
            this.owsDepartmentQuery.selectAll()
        ]).pipe(
            map(([dutySets, departments]) => {
                return dutySets.map(dutySet => {
                    let displayName = dutySet.name;
                    const dateFormat = 'DD/MM/yyyy'; // this is exactly the expected format. It can't be used the language service, since it displayes also the time //this.languageService.getDateTimeFormatMomentByCurrentLanguage();

                    const matchingDepartment = departments.find(d => d.id === dutySet.departmentId);
                    if (matchingDepartment) {
                        displayName += ' (' + matchingDepartment.name + ')';
                    }  

                    const validFrom = this.formatNullableDate(dutySet.validFrom, dateFormat);
                    const validUntil = this.formatNullableDate(dutySet.validUntil, dateFormat);
                    displayName += '     |     ' +  validFrom  + " - " + validUntil

                    return {
                        ...dutySet, 
                        displayName
                    };
            })
            }));
    }

    public isDutySetValid(dutySet : OwsDutySet, showPastDutySets: boolean, showFutureDutySets: boolean): boolean {
        const validOnlyInThePast = showPastDutySets && moment(dutySet.validUntil).isSameOrBefore(moment());
        const validOnlyInTheFuture = showFutureDutySets && moment(dutySet.validFrom).isSameOrAfter(moment());
        const validInPresent = (dutySet.validFrom === null && moment(dutySet.validUntil).isSameOrAfter(moment()))
            ||  (moment(dutySet.validFrom).isSameOrBefore(moment()) && dutySet.validUntil === null)
            ||  (dutySet.validFrom !== null && dutySet.validUntil !== null && moment(dutySet.validFrom).isSameOrBefore(moment()) && moment(dutySet.validUntil).isSameOrAfter(moment()));

        return validOnlyInThePast || validOnlyInTheFuture || validInPresent;
    }

    private formatNullableDate(date: string | null, dateFormat: string): string {
        return date !== null ? moment.utc(date).format(dateFormat) : '';
    }
}
