import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Scenario, ScenarioType } from './scenario.model';
import { ScenarioState, ScenarioStore } from './scenario.store';

@Injectable({
    providedIn: 'root'
})
export class ScenarioQuery extends QueryEntity<ScenarioState> {

    constructor(
        protected store: ScenarioStore,
    ) {
        super(store);
    }

    public getScenariosByType(scenarioType: ScenarioType): Observable<Array<Scenario>> {
        return this.selectAll().pipe(
            map(scenarios => scenarios.filter(scenario => scenario.scenarioType === scenarioType))
        );
    }

    public getScenariosByTypeSync(scenarioType: ScenarioType): Array<Scenario> {
        const scenarios = Object.values(this.getValue().entities);
        const filteredScenariosByType = scenarios?.filter(scenario => scenario.scenarioType === scenarioType);

        return filteredScenariosByType;
    }

    public getScenariosSync(): Array<Scenario> {
        return Object.values(this.getValue().entities);
    }

    public getScenarioByIdSync(id: number) {
        return this.getScenariosSync().find(scenario => scenario.id === id);
    }

    public getSelectedScenarioId(): Observable<number> {
        return this.select(state => state.ui.selectedId);
    }

    public getSelectedScenarioIdSync(): number {
        return this.getValue().ui.selectedId;
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }
}
