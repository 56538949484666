import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NestedTreeService } from 'src/app/shared/services/nested-tree.service';

import { OwsDepartment, OwsDepartmentTree } from './ows-department.model';
import { OwsDepartmentStore, OwsDepartmentState } from './ows-department.store';

@Injectable({
    providedIn: 'root'
})
export class OwsDepartmentQuery extends QueryEntity<OwsDepartmentState> {

    constructor(
        protected store: OwsDepartmentStore,
        private readonly nestedTreeService: NestedTreeService,
    ) {
        super(store);
    }

    public getSortedOwsDepartments(): Observable<Array<OwsDepartment>> {
        return this.selectAll().pipe(map(organizationUnits =>
            organizationUnits.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        ));
    }

    public getOwsDepartmentsForFiltering(): Observable<Array<OwsDepartmentTree>> {
        return this.getSortedOwsDepartments().pipe(
            map((units: Array<OwsDepartment>) => {
                const mutableUnits: Array<OwsDepartmentTree> = units.map(unit => {
                    return {
                        id: unit.id,
                        displayName: unit.name,
                        parentId: unit.parentDepartmentId,
                        children: []
                    };
                });

                return this.nestedTreeService.nestArray(mutableUnits) as Array<OwsDepartmentTree>;
            })
        );
    }
}
