<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true">
                    <app-filter-tree-single-select 
                        [entities]="organizationsForFiltering$ | async"
                        [labelText]="'Organization unit'"
                        [selectedEntityId] = "selectedOrganizationUnit$ | async"
                        (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                        [dataCyLabel]="'organizationUnitFilter'">
                    </app-filter-tree-single-select>
    
                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showUnderlyingUnits$ | async"
                            (change)="onUpdateShowChildUnits($event)"
                            data-cy="showSkillsForUnderlyingOrganizationUnits"
                        >{{ 'Also show skills for underlying organization units' | translate }}</mat-checkbox>
                    </div>
                </app-entity-filter-panel>
            </div>
        </div>
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true">
                    <app-filter-tree-single-select 
                        [entities]="owsDepartmentsForFiltering$ | async"
                        [labelText]="'OWS Departments'"
                        [selectedEntityId] = "selectedOwsDepartment$ | async"
                        (selectedEntityIdChanged)="onSelectedOwsDepartmentChange($event)"
                        [dataCyLabel]="'departmentFilterOWS'">
                    </app-filter-tree-single-select>
    
                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showAllOwsSkills$ | async"
                            (change)="onShowAllOwsSkillsChange($event)"
                            data-cy = "showOwsSkillsFromAllDepartments"
                        >{{ 'Show OWS Skills from all Departments' | translate }}</mat-checkbox>
                    </div>
                    
                    <br/>
    
                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="hideUnlinkedOwsSkills$ | async"
                            (change)="onHideUnlinkedOwsSkillsChange($event)"
                            data-cy = "hideLinkedSkills"
                        >{{ 'Hide linked Skills' | translate }}</mat-checkbox>
                    </div>
                    
                    <br/>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showLinkedOwsSkills$ | async"
                            (change)="onShowLinkedOwsSkillsChange($event)"
                            data-cy = "onlySelectedSkills"
                        >{{ 'Show linked Skills' | translate }}</mat-checkbox>
                    </div>
                    
                    <br/>
                    
                    <span class="unlinked__title">{{'Unlinked Skills' | translate}}:</span>
                    <div 
                        class="bol-badge-solid"
                        [ngClass]="{'bol-warning': (unlinkedSkillCount$ | async) > 0, 'bol-primary': (unlinkedSkillCount$ | async) === 0}" 
						data-cy="numberUnlinkedSkills">
                        {{unlinkedSkillCount$ | async}}
                    </div>

                    <p *ngIf="(selectedSkillId$ | async) === undefined && (visibleOwsSkills$ | async)?.length > 0" data-cy="noSelectionWarningMessage">
                        <mat-icon class="inline-icon">warning</mat-icon>
                        <span translate>Please select an OMRP Skill from the left panel to map to!</span>
                    </p>
                    
                </app-entity-filter-panel>
            </div>
        </div>
    </div>

    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OMRP Skills"
                    [entities]="visibleSkills$ | async"
                    [selectedEntityId]="selectedSkillId$ | async"
                    hideAddButton="true"
                    (selectedEntityIdChanged)="onSelectSkill($event)"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'skillsOMRP'"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OWS Skills"
                    [entities]="visibleOwsSkills$ | async"
                    (selectionClickChanged)="onSelectOwsSkillForMapping($event)"
                    showCheckboxes="true"
                    hideAddButton="true"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'skillsOWS'"
                >
                </app-entity-list-panel>
            </div>
        </div>
    </div>
</div>