import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { createTimeRange, TimeAdapter } from '@ortec/bolster/time-select';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { filter, first, map, skip } from 'rxjs/operators';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { DaypartService } from 'src/app/shared/stores/day-part-store/day-part.service';

import { UpdatedDaypart } from './settings-daypart-item/settings-daypart-item.component';

@Component({
    selector: 'app-settings-dayparts',
    templateUrl: './settings-dayparts.component.html',
    styleUrls: ['./settings-dayparts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsDaypartsComponent implements OnDestroy, OnInit {
    public timeRange$!: Observable<any>;
    public errorAddDaypart = false;
    public newDaypart: Daypart;
    public numberOfErrorsOnUpdate = 0;

    private readonly subscription = new Subscription();
    private readonly currentDaypartsSubject = new BehaviorSubject<Array<Daypart>>([]);
    private readonly addDaypartSubject = new BehaviorSubject<boolean>(false);
    private readonly updateDaypartsSubject = new BehaviorSubject<Array<Daypart>>(undefined);
    private readonly errorOnUpdateSubject = new BehaviorSubject<number>(0);

    constructor(
        private readonly timeAdapter: TimeAdapter<moment.Moment>,
        private readonly daypartService: DaypartService,
        private readonly daypartQuery: DaypartQuery,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
    ) {
        this.timeAdapter.locale = 'nl-NL';
        this.timeRange$ = of(createTimeRange(timeAdapter, undefined, this.timeAdapter.create(23, 59, 0, 0), 30));
    }

    public ngOnInit(): void {
        this.daypartService.getDayparteNew().pipe(first()).subscribe();
      
        this.daypartQuery.getDayparts().pipe(
            skip(1),
            first(),
            map(dayparts => this.currentDaypartsSubject.next(dayparts))
        ).subscribe();

        this.subscription.add(
            this.updateDayparts()
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();

        if (this.currentDaypartsSubject.value.length === 1) {
            this.updateDaypartsSubject.next([]);
        }
    }

    public onDeleteDaypart(daypart: Daypart): void {
        const updatedDayparts = this.currentDaypartsSubject.value.filter(dp => dp.name !== daypart.name && dp.start !== daypart.start);
       
        if (updatedDayparts.length !== 1) {
            this.updateDaypartsSubject.next(updatedDayparts);
        }
    }

    public resetAddDaypartContainer(): void {
        this.newDaypart = this.getNewDefaultDaypart(this.currentDaypartsSubject.value);

        this.addDaypartSubject.next(false);
    }

    public showAddDaypartContainer(): void {
        this.newDaypart = this.getNewDefaultDaypart(this.currentDaypartsSubject.value);

        this.addDaypartSubject.next(true);
    }

    public onUpdateDaypart(updatedDaypart: UpdatedDaypart): void {
        const dayparts = [...this.currentDaypartsSubject.value];

        const index = dayparts.findIndex(daypart => daypart.name === updatedDaypart.old.name && daypart.start === updatedDaypart.old.start);
        dayparts[index] = { ...updatedDaypart.new,  startInMinutes: this.dateTimeUtilityService.transformTimeInMinutes(updatedDaypart.new.start) };

        this.updateDaypartsSubject.next(dayparts);
    }

    public onAddDaypart(daypart: Daypart): void {
        const updatedDayparts = [...this.currentDaypartsSubject.value];
        updatedDayparts.push({ 
            id: null,
            start: daypart.start, 
            name: daypart.name, 
            startInMinutes: this.dateTimeUtilityService.transformTimeInMinutes(daypart.start) 
        });

        if (updatedDayparts.length !== 1) {
            this.errorAddDaypart = false;
            this.updateDaypartsSubject.next(updatedDayparts);
        } else {
            this.errorAddDaypart = true;
            this.currentDaypartsSubject.next(updatedDayparts);
            this.addDaypartSubject.next(false);
        }
    }

    @Memoized public get addDaypart$(): Observable<boolean> {
        return this.addDaypartSubject.asObservable();
    }

    @Memoized public get dayparts$(): Observable<Array<Daypart>> {
        return this.currentDaypartsSubject.asObservable().pipe(
            map(dayparts => dayparts.sort((a, b) => a.startInMinutes - b.startInMinutes))
        );
    }
    
    @Memoized public get showEmptyState$(): Observable<boolean> {
        return combineLatest([
            this.dayparts$,
            this.addDaypart$,
            this.currentDaypartsSubject
        ]).pipe(
            skip(1),
            map(([dayparts, addDayPart, currentDayparts]) => {
                const shouldShowEmptyState = dayparts.length === 0 && !addDayPart && currentDayparts.length === 0;

                return shouldShowEmptyState;
            }),
        );
    }

    @Memoized public get errorOnUpdate$(): Observable<number> {
        return this.errorOnUpdateSubject.asObservable();
    }

    private updateDayparts(): Subscription{
        return this.updateDaypartsSubject.pipe(
            filter(dayparts => !!dayparts),
            map(dayparts => this.daypartService.updateDayparts(dayparts).pipe(first()).subscribe(() => {
                // Reset the form when update is finished
                this.resetAddDaypartContainer();
                const currentDayparts = this.daypartQuery.getDaypartsOrderByStartTime();
                this.currentDaypartsSubject.next(currentDayparts);
                this.numberOfErrorsOnUpdate = 0;
                this.errorOnUpdateSubject.next(this.numberOfErrorsOnUpdate);
            },
            (error) => {
                this.numberOfErrorsOnUpdate += 1;
                this.errorOnUpdateSubject.next(this.numberOfErrorsOnUpdate);
            }))
        ).subscribe()
    }

    private getNewDefaultDaypart(dayparts: Array<Daypart>): Daypart {
        const newDaypart = {
            id: null,
            name: '',
            start: undefined
        };

        // We always require 1 daypart to start at midnight
        const noDaypartAtMidnight = !dayparts?.some(dp => dp.startInMinutes === 0);
        if (noDaypartAtMidnight) {
            newDaypart.start = '0:0';
        }

        return newDaypart;
    }
}
