import { EntityState } from '@datorama/akita';

export interface EntityUI {
    id?: number;
    isLoading: boolean;
    hasPendingChanges: boolean;
    isFinishedSaving: boolean;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getDefaultUIState(): EntityUI {
    return {
        isLoading: false,
        hasPendingChanges: false,
        isFinishedSaving: false
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EntityUIState extends EntityState<EntityUI> {}