import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { FilterSetting } from './filter-setting.model';
import { FilterSettingQuery } from './filter-setting.query';
import { FilterSettingStore } from './filter-setting.store';

@Injectable({
    providedIn: 'root'
})
export class FilterSettingService {
    constructor(
        protected filterSettingStore: FilterSettingStore,
        protected filterSettingQuery: FilterSettingQuery,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getFilterSettings(): Observable<Array<FilterSetting>> {
        this.filterSettingStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<FilterSetting>>('/api/v1/ReportFilterSettings').pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((filterSettings) => {
                const sortedFilterSettings = filterSettings.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

                this.filterSettingStore.set(sortedFilterSettings);
            }),
            tap(() => this.filterSettingStore.updateEntitiesLoadingState(false))
        );
    }

    public createFilterSetting(filterSetting: FilterSetting): Observable<FilterSetting> {
        return this.http.post<FilterSetting>('/api/v1/ReportFilterSettings', filterSetting).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((setting) => {
                this.filterSettingStore.set([ ...this.filterSettingQuery.getFilterSettingsSync(), setting ]);
                this.updateSelectedFilterSettingsId(setting.id);

                return(setting);
            })
        );
    }

    public deleteFilterSetting(filterSetting: FilterSetting): Observable<FilterSetting> {
        return this.http.delete(`/api/v1/ReportFilterSettings/${filterSetting.id}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            map(() => {
                this.filterSettingStore.set([ ...this.filterSettingQuery.getFilterSettingsSync().filter(setting => setting.id !== filterSetting.id) ]);
                if (this.filterSettingQuery.getSelectedFilterSettingIdSync() === filterSetting.id) {
                    this.updateSelectedFilterSettingsId(this.filterSettingQuery.getFirstFilterSettingIdAvailableByType(filterSetting.settingType));
                }

                return(filterSetting);
            })
        );
    }

    public updateFilterSetting(filterSetting: FilterSetting): Observable<FilterSetting> {
        return this.http.put(`/api/v1/ReportFilterSettings/${filterSetting.id}`, filterSetting).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            map(() => {
                this.filterSettingStore.set([
                    ...this.filterSettingQuery.getFilterSettingsSync().map(setting =>
                        setting.id !== filterSetting.id ? setting : filterSetting
                    )
                ]);

                return(filterSetting);
            })
        );
    }

    public updateSelectedFilterSettingsId(id: number): void {
        this.filterSettingStore.updateSelectedFilterSettingId(id);
    }
}
