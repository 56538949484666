<div class="container">
    <div class="title">{{ 'Oh no!' | translate }}</div>
    <div class="error-icon">
        <mat-icon>block</mat-icon>
    </div>
    <div class="error-description">{{ 'It seems you do not have permissions for this page...' | translate }}</div>
    <button
        mat-button
        class="redirect-button bol-button-solid bol-blue"
        (click)="onRedirectToMainPage()">
        <span *ngIf="isFullUser">{{ 'Take me to the portal page' | translate }}</span>
        <span *ngIf="!isFullUser">{{ 'Take me to the overviews page' | translate }}</span>
    </button>
</div>