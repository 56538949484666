import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatSliderThumb } from '@angular/material/slider';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, first, startWith, tap } from 'rxjs/operators';
import { FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { ResourceService } from 'src/app/shared/stores/resource-store/resource.service';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { CountersColumnService } from '../../counters-helpers/counters-column.service';
import { GetCountersService } from '../../counters-helpers/get-counters.service';
import { CountersResourceTreeService } from '../../counters-helpers/counters-resource-tree.service';
import { CountersScenarioService } from '../../stores/counters-scenario-store/counters-scenario.service';
import { ColumnDefinition, CounterData } from '../../stores/counters-store/counters-matrix.models';
import { CountersQuery } from '../../stores/counters-store/counters.query';
import { CountersService } from '../../stores/counters-store/counters.service';
import { CountersFilterSetting } from '../../stores/counters-store/counters-request-parameters.model';

interface CounterMatrixElement extends CounterData {
    expandable: boolean;
    level: number;
}
@Component({
    selector: 'app-counters-matrix',
    templateUrl: './counters-matrix.component.html',
    styleUrls: ['./counters-matrix.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountersMatrixComponent implements OnDestroy, OnInit {
    public columns: Array<string>;
    public columnDefinitions: Array<ColumnDefinition>;
    public dataSource: MatTreeFlatDataSource<CounterData, CounterMatrixElement, CounterMatrixElement>;
    public treeControl: FlatTreeControl<CounterMatrixElement, CounterMatrixElement>;
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public emptyMatrixSubject = new BehaviorSubject<boolean>(false);
    public nameColumnSliderControl = new UntypedFormControl();
    public dataColumnSliderControl = new UntypedFormControl();
    public totalTableSliderControl = new UntypedFormControl();
    public nameColumnWidth: string;
    public dataColumnWidth: string;
    public totalTableWidth: string;
    public dataColumnMin: number;
    public hideZeroValues: boolean = true;
    public error$: Observable<boolean>;
    public filterSettings$!: Observable<Array<FilterSetting>>;

    private treeFlattener: MatTreeFlattener<CounterData, CounterMatrixElement, CounterMatrixElement>;
    private readonly subscription = new Subscription();
    private slidersSubscription = new Subscription();
    private readonly defaultTotalTableWidth = 100;
    private readonly defaultNameColumWidth = 175;

    constructor(
        protected countersService: CountersService,
        protected countersScenarioService: CountersScenarioService,
        protected countersQuery: CountersQuery,
        protected resourceQuery: ResourceQuery,
        protected countersResourceTreeService: CountersResourceTreeService,
        private readonly countersColumnSerivce: CountersColumnService,
        private readonly resourceService: ResourceService,
        private readonly getCountersService: GetCountersService,
        private readonly utilsService: UtilsService,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery
    ) {
    }

    public ngOnInit(): void {
        this.setupMatrixConfiguration();
        this.setupDataCallOnButtonClick();
        this.setupEmptyMatrixOnFilterChange();

        this.error$ = this.countersQuery.getErrorState();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onNameColumnWidthChange(event: MatSliderThumb): void {
        this.nameColumnWidth = event.value + 'px';
    }

    public onDataColumnWidthChange(event: MatSliderThumb): void {
        this.dataColumnWidth = event.value + 'px';
    }

    public onUpdateHideZeroValues(event: MatCheckboxChange) {
        this.hideZeroValues = event.checked;
    }

    public onTotalTableWidthChange(event: MatSliderThumb): void {
        this.totalTableWidth = event.value + '%';
    }

    private setupDataCallOnButtonClick(): void {
        this.subscription.add(
            this.getCountersService.getCountersSignal$.pipe(
                tap(() => {
                    const startDate = this.countersQuery.getSelectedStartDateSync();
                    const endDate = this.countersQuery.getSelectedEndDateSync();
                    this.emptyMatrixSubject.next(true);
                    this.loadingSubject.next(true);
                    const resourceParams = this.countersQuery.getRequestParametersForResourceSync();
                    this.resourceService.getResources(resourceParams, startDate, endDate).pipe(
                        first()
                    ).subscribe(
                        () => this.getActivities(),
                        this.setError.bind(this)
                    );
                })).subscribe()
        );
    }

    private setupEmptyMatrixOnFilterChange(): void {
        this.subscription.add(
            this.countersQuery.getUIState().pipe(
                tap(() => {
                    this.emptyMatrixSubject.next(true);
                    this.slidersSubscription.unsubscribe();
                })
            ).subscribe()
        );
    }

    private setupMatrixConfiguration(): void {
        this.treeFlattener = new MatTreeFlattener(
            this.transformToTableElement, node => node.level,
            node => node.expandable, node => node.children
        );

        this.treeControl = new FlatTreeControl<CounterMatrixElement>(
            node => node.level, node => node.expandable
        );

        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    private getActivities() {
        if (this.countersQuery.getOnlyScenarioCounterTypesSelected()) {
            this.countersService.emptyStore();
            this.countersScenarioService.getActivitiesForCounters()
                .pipe(first())
                .subscribe(() => this.renderMatrix(), this.setError.bind(this));
        }
        else if (this.countersQuery.getOnlyScheduleCounterTypesSelected()) {
            this.countersScenarioService.emptyStore();
            this.countersService.getActivitiesForCounters()
                .pipe(first())
                .subscribe(() => this.renderMatrix(), this.setError.bind(this));
        }
        else {
            combineLatest([
                this.countersScenarioService.getActivitiesForCounters(),
                this.countersService.getActivitiesForCounters()
            ])
                .pipe(first())
                .subscribe(() => this.renderMatrix(), this.setError.bind(this));
        }
    }

    private setError() {
        this.countersService.updateErrorState(true);
        this.loadingSubject.next(false);
    }

    private renderMatrix(): void {
        this.setupSliderListeners();
        this.setupSlidersWidth();
        this.columnDefinitions = this.countersColumnSerivce.getColumnDefinitions();
        this.columns = ['name'].concat(this.columnDefinitions.map(cd => cd.columnName));
        this.dataSource.data = this.countersResourceTreeService.getResourceTree(this.columnDefinitions);
        this.loadingSubject.next(false);
        this.emptyMatrixSubject.next(false);
    }

    private setupSlidersWidth(): void {
        const counterTypes = this.countersQuery.getCounterTypeOptionsSync();
        this.dataColumnMin = 40 + (10 * counterTypes.length);
        const filterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();
        let nameColumnWidth = this.defaultNameColumWidth;
        let dataColumnWidth = 60 + (15 * counterTypes.length);
        let totalTableWidth = this.defaultTotalTableWidth;

        if (filterSetting !== undefined) {
            const settings: CountersFilterSetting = JSON.parse(filterSetting.settings);
            nameColumnWidth = settings.nameColumnWidth ?? nameColumnWidth;
            dataColumnWidth = settings.dataColumnWidth ?? dataColumnWidth;
            totalTableWidth = settings.totalTableWidth ?? totalTableWidth;
        }

        this.nameColumnSliderControl.setValue(nameColumnWidth);
        this.dataColumnSliderControl.setValue(dataColumnWidth);
        this.totalTableSliderControl.setValue(totalTableWidth);
        this.nameColumnWidth = nameColumnWidth + 'px';
        this.dataColumnWidth = dataColumnWidth + 'px';
        this.totalTableWidth = totalTableWidth + '%';
    }

    private setupSliderListeners(): void {
        this.slidersSubscription = new Subscription();
        this.slidersSubscription.add(
            combineLatest([
                this.countersQuery.getSelectedFilterSettingId(),
                this.nameColumnSliderControl.valueChanges.pipe(startWith(undefined)),
                this.dataColumnSliderControl.valueChanges.pipe(startWith(undefined)),
                this.totalTableSliderControl.valueChanges.pipe(startWith(undefined))
            ]).pipe(
                filter(([filterSettingId, _]) => filterSettingId !== undefined),
                debounceTime(1000),
            ).subscribe(([_, nameColumnWidth, dataColumnWidth, totalTableWidth]) => {
                const filterSetting = this.utilsService.deepCopy(this.filterSettingQuery.getSelectedFilterSettingSync());
                const settings: CountersFilterSetting = JSON.parse(filterSetting.settings);

                this.countersService.updateSliderWidthValues(nameColumnWidth, dataColumnWidth, totalTableWidth);
                // only save if there is an actual change
                if (settings.nameColumnWidth !== nameColumnWidth ||
                    settings.dataColumnWidth !== dataColumnWidth ||
                    settings.totalTableWidth !== totalTableWidth) 
                {
                    settings.nameColumnWidth = nameColumnWidth ?? settings.nameColumnWidth;
                    settings.dataColumnWidth = dataColumnWidth ?? settings.dataColumnWidth;
                    settings.totalTableWidth = totalTableWidth ?? settings.totalTableWidth;
                    filterSetting.settings = JSON.stringify(settings);
                    this.filterSettingService.updateFilterSetting(filterSetting).pipe(first()).subscribe();
                }
            })
        );
    }

    private readonly transformToTableElement = (node: CounterData, level: number): CounterMatrixElement => {
        return {
            ...node,
            expandable: !!node.children && node.children.length > 0,
            level,
        };
    };
}
