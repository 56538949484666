/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MANAGE_MODE } from '../entity-manage-panel/entity-manage-panel.component';

export interface IEntityManageModalData<T> {
    isReadOnly?: boolean;
    model: T;
    formFields: FormlyFieldConfig;
    manageMode: MANAGE_MODE,
    entityName: string
}

@Component({
    selector: 'app-entity-manage-dialog',
    templateUrl: './entity-manage-dialog.component.html',
    styleUrls: ['./entity-manage-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityManageDialogComponent implements OnInit {
    public readonly mode: MANAGE_MODE;
    public readonly entityName: string;
    public form = new UntypedFormGroup({});
    public internalModel: any;
    public entity: any;
    public manageMode = MANAGE_MODE;
    public prefixedEntityName: string;
    public internalFormFields: FormlyFieldConfig;

    constructor(
        private readonly utilsService: UtilsService,
        public dialogRef: MatDialogRef<IEntityManageModalData<any>>,
        @Inject(MAT_DIALOG_DATA) dialogData: IEntityManageModalData<any>,
    ) { 
        this.entityName = dialogData.entityName;
        this.mode = dialogData.manageMode;
        this.onInputModelChanged(dialogData.model, dialogData.formFields);
    }

    public ngOnInit(): void {
        this.prefixedEntityName = 'MANAGE-NAME.' + this.entityName.toUpperCase();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onAddEntity(): void {
        this.dialogRef.close({
            data: {
                model: this.internalModel,
                manageMode: this.mode,
                entityName: this.entityName
            } as IEntityManageModalData<any>
        });
    }

    public onCancelAdd(): void {
        this.dialogRef.close();
    }

    public onClone(): void {
        this.dialogRef.close({
            data: {
                model: this.internalModel,
                manageMode: this.mode,
                entityName: this.entityName
            } as IEntityManageModalData<any>
        });
    }

    private onInputModelChanged(model: any, value: any): void {
        this.internalFormFields = value;
        // we need to unfreeze the model so formly can alter it
        this.entity = this.utilsService.deepCopy(model);
        
        if (this.manageMode.CLONE) {
            this.entity.displayName += " (1)";
        }
        this.internalModel = this.utilsService.deepCopy(model);
        this.form = new UntypedFormGroup({});
        this.onFormlyFieldChanged();
    }

    private onFormlyFieldChanged(): void {
        if (this.internalFormFields && Array.isArray(this.internalFormFields)) {
            this.internalFormFields.forEach((field: FormlyFieldConfig) => {
                if (field.formControl) {
                    field.formControl.markAsUntouched();
                }
            });
        }

        const firstField = this.internalFormFields?.[0];
        if (firstField !== undefined && !firstField.focus) {
            firstField.focus = true;
        }
    }
}
