import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GetCountersService {
    public getCountersSignal$!: Observable<any>;

    private readonly getCountersSignal = new Subject<void>();

    constructor() {
        this.getCountersSignal$ = this.getCountersSignal.asObservable();
    }

    public getCounters(): void {
        this.getCountersSignal.next();
    }
}
