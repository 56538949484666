import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';

import { CountersExportFilterSetting } from '../../stores/counters-export-request-filters.model';
import { CountersExportQuery } from '../../stores/counters-export.query';
import { CountersExportService } from '../../stores/counters-export.service';

@Component({
    selector: 'app-counters-export-filters-settings',
    templateUrl: './counters-export-filters-settings.component.html',
    styleUrls: ['./counters-export-filters-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountersExportFiltersSettingsComponent implements OnInit, OnDestroy {
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public filterChangesInPending$!: Observable<boolean>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly countersExportService: CountersExportService,
        private readonly countersExportQuery: CountersExportQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
    ) { }

    public ngOnInit(): void {
        this.filterSettingService.getFilterSettings().pipe(first()).subscribe();

        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.EXPORT_ACTIVITIES_FILTER_SETTING);
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();
        this.filterChangesInPending$ = combineLatest([
            this.countersExportQuery.getRequestFiltersValidityState(),
            this.countersExportQuery.getUIState(),
            this.filterSettingQuery.filterSettingsUpdated()
        ]).pipe(map(([valid, _]) => !(valid && !this.countersExportQuery.currentFilterSettingChangesUnsavedSync())));

        this.subscription.add(
            this.filterSettingQuery.getFilterSettingBySelectedId().pipe(
                tap(filterSetting => {
                    if (!filterSetting) {
                        this.countersExportService.updateSelectedFilterSettingId(undefined);

                        return;
                    }

                    const settings: CountersExportFilterSetting = JSON.parse(filterSetting.settings);
                    settings.requestFilters.start = this.countersExportQuery.getSelectedStartDateSync();
                    settings.requestFilters.end = this.countersExportQuery.getSelectedEndDateSync();

                    this.countersExportService.updateCountersState({
                        ui: {
                            selectedFilterSettingId: filterSetting.id,
                            validIntervalDates: true,
                            ...settings
                        }
                    });
                })
            ).subscribe()
        );
    }

    public onSelectedFilterSettingIdChanged(id: number): void {
        this.filterSettingService.updateSelectedFilterSettingsId(id);
    }

    public onRemoveFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.deleteFilterSetting(setting).pipe(first()).subscribe();
    }

    public onUpdateFilterSetting(setting: FilterSetting): void {
        this.filterSettingService.updateFilterSetting(setting).pipe(first()).subscribe();
    }

    public onSaveNewFilterSettingEvent(settingName: string): void {
        const newFilterSetting = this.countersExportQuery.getNewFilterSetting(settingName);

        this.filterSettingService.createFilterSetting(newFilterSetting).pipe(first()).subscribe();
    }

    public onOverwriteSelectedFilterSettingEvent(): void {
        const updatedFilterSetting = this.countersExportQuery.getUpdatedFilterSetting();

        this.filterSettingService.updateFilterSetting(updatedFilterSetting).pipe(first()).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
