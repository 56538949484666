import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { StatusPageQuery } from 'src/app/shared/stores/status-page-store/status-page.query';

import { SortingState, SortingStore } from './sorting.store';
import { SortableResource } from './sorting.model';

@Injectable({
    providedIn: 'root'
})
export class SortingQuery extends StatusPageQuery<SortingState> {
    constructor(
        protected store: SortingStore,
    ) {
        super(store);
    }

    public getResourcesForSelectedResourceTypeSync(): Array<SortableResource> {
        return this.getValue().resourcesForSelectedResourceType;
    }

    public getSortableResources(): Observable<Array<SortableResource>> {
        return combineLatest([
            this.getResourcesForSelectedResourceType(),
            this.getSelectedSortableResourcesIds(),
        ]).pipe(
            map(([resources, selectedResourcesIds]) => {
                const sortableResources: Array<SortableResource> = [];

                selectedResourcesIds.forEach(id => {
                    const resource = resources.find(res => res.id === id);
                    if (resource) {
                        sortableResources.push({
                            ...resource
                        });
                    }
                });

                return sortableResources;
            })
        );
    }

    public getSortableResourcesSync(): Array<SortableResource> {
        const resources = this.getResourcesForSelectedResourceTypeSync();
        const selectedResourcesIds = this.getSelectedSortableResourcesIdsSync();
    
        const sortableResources: Array<SortableResource> = [];
    
        selectedResourcesIds.forEach(id => {
            const resource = resources.find(res => res.id === id);
            if (resource) {
                sortableResources.push({
                    ...resource
                });
            }
        });
    
        return sortableResources;
    }

    public getUnsortableResources(): Observable<Array<SortableResource>> {
        return combineLatest([
            this.getResourcesForSelectedResourceType(),
            this.getSelectedSortableResourcesIds(),
        ]).pipe(
            map(([resources, selectedResourcesIds]) => {
                return resources
                .filter(res => !selectedResourcesIds.includes(res.id))
                .map(resource => {
                    const updatedResource = { ...resource, check: false };

                    return updatedResource;
                });
            })
        );
    }

    public getSelectedUnsortedResourcesIdsListState(): Observable<boolean> {
        return this.select(state => state.ui.selectedUnsortedResourcesIdsList.length > 0);
    }

    public getSelectedSortedResourcesIdsListState(): Observable<boolean> {
        return this.select(state => state.ui.selectedSortableResourceIdsList.length > 0);
    }

    public getResourcesForSelectedResourceType(): Observable<Array<SortableResource>> {
        return this.select(value => value.resourcesForSelectedResourceType);
    }

    public getSelectedSortableResourcesIdsSync(): Array<number> {
        return this.getValue().selectedSortableResourcesIds;
    }

    public getSelectedSortableResourcesIds(): Observable<Array<number>> {
        return this.select(value => value.selectedSortableResourcesIds);
    }

    public getSortDescending(): Observable<boolean> {
        return this.select(value => value.ui.sortDescending);
    }

    public getSelectedResourceTypeIdSync(): number {
        return this.getValue().ui.selectedResourceTypeId;
    }

    public getSelectedResourceTypeId(): Observable<number> {
        return this.select(value => value.ui.selectedResourceTypeId);
    }

    public getSelectedResourcePropertiesIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedResourcePropertiesIds);
    }

    public getEntitiesResourcesLoadingSync(): boolean {
        return this.getValue().ui.entitiesResourcesLoading;
    }

    public getEntitiesResourcesLoading(): Observable<boolean> {
        return this.select(value => value.ui.entitiesResourcesLoading);
    }

    public getEntitiesSelectedResourcesLoading(): Observable<boolean> {
        return this.select(value => value.ui.entitiesSelectedResourcesLoading);
    }

    public getSelectedSortableResourceIdsList(): Observable<Array<number>> {
        return this.select(state => state.ui.selectedSortableResourceIdsList);
    }

    public getSelectedSortableResourceIdsListSync(): Array<number> {
        return this.getValue().ui.selectedSortableResourceIdsList;
    }

    public getSelectedUnsortedResourcesIdsList(): Observable<Array<number>> {
        return this.select(state => state.ui.selectedUnsortedResourcesIdsList);
    }

    public getSelectedUnsortedResourcesIdsListSync(): Array<number> {
        return this.getValue().ui.selectedUnsortedResourcesIdsList;
    }
}
