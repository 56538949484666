import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuard } from '@ortec/soca-web-ui/identity';

import { AuthenticationMode } from './authentication.enum';

import { OmrpAuthenticationService } from './omrp-authentication.service';

@Injectable({
    providedIn: 'root'
})
export class OmrpAuthGuard  {
    constructor(
        private readonly authGuard: AuthGuard,
        private readonly omrpAuthenticationService: OmrpAuthenticationService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.omrpAuthenticationService.isAuthenticated) {
            return true;
        }

        if (this.omrpAuthenticationService.authMode === AuthenticationMode.LocalLogin) {
            this.omrpAuthenticationService.redirectToLocalLogin();

            return false;
        }

        if (this.omrpAuthenticationService.authMode === AuthenticationMode.Saml2) {
            this.omrpAuthenticationService.redirectToSaml2Login();

            return false;
        }

        return this.authGuard.canActivate(route, state);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
