<mat-card  class="bol-card full-height">
    <mat-card-header class="bol-card-header mat-card-header-padding">
        <mat-card-title
            class="bol-card-title entity-title"
            translate
            [attr.data-cy]="dataCyLabel ? dataCyLabel : 'entityListTitle'">    
            {{entityName}}
        </mat-card-title>
        <button 
            *ngIf="!hideAddButton"
            (click)="onAddEntityClicked()"
            mat-button 
            class="bol-button-solid bol-default"
            data-cy="addEntityButton">
            <span translate>Add</span>
        </button>
    </mat-card-header>
    <mat-card-content class="bol-card-content">
        <ng-container *ngIf="!loadingError">

            <mat-form-field 
                *ngIf="dataSource?.data?.length > 0 && searchProperties?.length > 0"
                class="bol-form-field entity-search">
                <input 
                    matInput 
                    (keyup)="searchEntities($event.target.value)" 
                    placeholder="{{'Search' | translate}}" 
                    data-cy="searchEntityField"
                    autocomplete="off">
            </mat-form-field>

            <div *ngIf="dataSource?.data?.length > 0 && !noVisibleEntities" class="list-header" translate>Display name</div>

            <mat-tree
                data-cy="entityTable"
                class="tree"
                [dataSource]="dataSource"
                [treeControl]="treeControl"
                *ngIf="dataSource?.data?.length > 0 && !noVisibleEntities">
                <!-- tree nodes without children -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [class.tree-invisible]="!node.visible">
                    <li
                        class="mat-tree-node"
                        (click)="onEntitySelected(node)"
                        [ngClass]="{'selected': selectedEntityId === node.id}"
                        [class.node-gray-background]="node.index % 2 === 0">

                        <mat-spinner 
                            *ngIf="node.isLoading && node.id !== selectedEntityId" 
                            class="row-loading-spinner" 
                            diameter="16">
                        </mat-spinner>
    
                        <button mat-icon-button disabled></button>

                        <mat-checkbox
                            *ngIf="showCheckboxes"
                            [checked]="node.checked"
                            [disabled]="node.disabled"
                            (change)="onCheckboxClick($event, node)"
                            class="bol-checkbox">
                        </mat-checkbox>

                        <div class="node-label"> {{ node.displayName }} </div>
                    </li>
                </mat-tree-node>

                <!-- tree nodes with children -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [class.tree-invisible]="!node.visible">
                    <li>
                        <div class="mat-tree-node"
                            [class.node-gray-background]="node.index % 2 === 0"
                            (click)="onEntitySelected(node)"
                            [ngClass]="{'selected': selectedEntityId === node.id}">
                            <mat-spinner
                                *ngIf="node.isLoading && node.id !== selectedEntityId" 
                                class="row-loading-spinner" 
                                diameter="16">
                            </mat-spinner>

                            <button
                                mat-icon-button
                                matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.displayName">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                                    {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
                                </mat-icon>
                            </button>

                            <mat-checkbox
                                class="mat-tree-node__checkbox"
                                *ngIf="showCheckboxes"
                                [checked]="node.checked"
                                [disabled]="node.disabled"
                                (change)="onCheckboxClick($event, node)"
                                class="bol-checkbox">
                            </mat-checkbox>
                            
                            <div class="node-label"> {{ node.displayName }} </div>
                        </div>
                        <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                        <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                    </li>
                </mat-nested-tree-node>
            </mat-tree>

        </ng-container>

        <ng-container *ngIf="!loadingError && (this.dataSource?.data?.length === 0 || noVisibleEntities)">
            <p data-cy="entityNoEntitiesFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase }  }}</p>
        </ng-container>

        <ng-container *ngIf="loadingError">
            <h4 translate data-cy="entityLoadingError">ERROR.LOADING-ENTITIES-ERROR</h4>
        </ng-container>

    </mat-card-content>
</mat-card>
