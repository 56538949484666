import { Injectable } from '@angular/core';
import { EntityState, StoreConfig } from '@datorama/akita';
import { ManageTemplate } from './manage-template.model';
import { StatusPageState, StatusPageStore, initialStatusPageState } from '../status-page-store/status-page.store';

export interface ManageTemplateState extends EntityState, StatusPageState {
    ui: {
        entitiesLoading: boolean;
    },
    selectedTemplateForEdit: ManageTemplate;
}

const initialState = {
    ui: {
        entitiesLoading: false,
    },
    selectedTemplateForEdit: undefined,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'manage-template' })
export class ManageTemplateStore extends StatusPageStore<ManageTemplateState> {
    
    constructor() {
        super({...initialState, ...initialStatusPageState});
    }

    public updateEntitiesLoadingState(entitiesLoading: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, entitiesLoading };
            
            return { ...state, ui };
        });
    }

    public updateSelectedTemplateForEdit(selectedTemplateForEdit: ManageTemplate): void {
        this.update(state => {
            return { ...state, selectedTemplateForEdit };
        });
    }
}
