<div class="bol-modal__header">
    <span class="modal-title">{{ "CHANGE-ENTITY-PERMISSION" | translate: { entityName: prefixedEntityName | translate } }}</span>
</div>

<mat-dialog-content>
    <div *ngIf="permissionStatus; else removePermissionsMessage">
        {{ "SET-PERMISSIONS-UNDERLYING-ENTITIES" | translate: { entityName: prefixedEntityNamePlural | translate, permissionType: permissionType | translate } }}
    </div>
    
    <ng-template #removePermissionsMessage>
        {{ "REMOVE-PERMISSIONS-UNDERLYING-ENTITIES" | translate: { entityName: prefixedEntityNamePlural | translate } }}
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray oversized-button"
            (click)="onChangeEntityPermission(true, false)"
            data-cy="PermissionYes">
            <span>{{ 'Yes' | translate | uppercase }}</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray"
            (click)="onChangeEntityPermission(true, true)"
            data-cy="PermissionYesRemember">
            <span>{{ 'Yes & remember' | translate | uppercase }}</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray oversized-button"
            (click)="onChangeEntityPermission(false, false)"
            data-cy="PermissionNo">
            <span>{{ 'No' | translate | uppercase }}</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray"
            (click)="onChangeEntityPermission(false, true)"
            data-cy="PermissionNoRemember">
            <span>{{ 'No & remember' | translate | uppercase }}</span>
        </button>
    </div>
</mat-dialog-actions>