import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { OwsDepartmentStore } from './ows-department.store';
import { OwsDepartment } from './ows-department.model';

@Injectable({
    providedIn: 'root'
})
export class OwsDepartmentService {
    constructor(
        protected owsDepartmentStore: OwsDepartmentStore,
        private readonly http: HttpClient
    ) { }

    public get() {
        return this.http.get<Array<OwsDepartment>>('/api/OwsInterface/OwsDepartments').pipe(
            map((departments) => {
                departments = departments.map(department => {
                    if (department.parentDepartmentId === department.id || !departments.some(d => d.id === department.parentDepartmentId)) {
                        department.parentDepartmentId = null;
                    }

                    return department;
                });
                this.owsDepartmentStore.set(departments);
            }));
    }
}
