import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';

import { OwsDuty, OwsDutyTableElement } from '../ows-duty-set-store/ows-duty-set.model';
import { OwsDutySetQuery } from '../ows-duty-set-store/ows-duty-set.query';
import { ActivityTypeMappingState, ActivityTypeMappingStore } from './activity-type-mapping.store';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeMappingQuery extends QueryEntity<ActivityTypeMappingState> {

    constructor(
        protected store: ActivityTypeMappingStore,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly owsDutySetQuery: OwsDutySetQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
    ) {
        super(store);
    }

    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.ui.showUnderlyingUnits);
    }

    public getShowPastActivityTypesState(): Observable<boolean> {
        return this.select(state => state.ui.showPastActivityTypes);
    }

    public getShowFutureActivityTypesState(): Observable<boolean> {
        return this.select(state => state.ui.showFutureActivityTypes);
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.ui.selectedOrganizationUnitId);
    }

    public getSelectedActivityTypeId(): Observable<number> {
        return this.select(state => state.ui.selectedActivityTypeId);
    }

    public getUnlinkedOwsDutyVisibility(): Observable<boolean> {
        return this.select(state => state.ui.hideUnlinkedOwsDuties);
    }

    public getSelectedOwsDutySet(): Observable<number> {
        return this.select(state => state.ui.selectedDutySet);
    }

    public getSelectedActivityTypeCategory(): Observable<number> {
        return this.select(state => state.ui.selectedActivityTypeCategory);
    }

    public getAllMappedOwsDutiesForSelectedDutySet(): Observable<Array<OwsDuty>> {
        return combineLatest([
            this.selectAll(),
            this.getSelectedOwsDutySet(),
            this.activityTypeQuery.selectAll(),
            this.owsDutySetQuery.selectAll()
        ]).pipe(
            filter(([_, selectedDutySetId, __, ___]) => selectedDutySetId !== undefined),
            map(([mappings, selectedDutySetId, activityTypes, allDutySets]) => {
                const selectedDutySet = allDutySets.find(set => set.id === selectedDutySetId);

                return selectedDutySet.duties.map(duty => ({
                    ...duty,
                    linkedOmrpActivityType: mappings.find(x => x.owsDutyId === duty.id) ?
                        activityTypes.find(s => s.id === mappings.find(x => x.owsDutyId === duty.id).activityTypeId) : null
                })).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            })
        );
    }

    public getFilteredOwsDuties(): Observable<Array<OwsDutyTableElement>> {
        return combineLatest([
            this.getAllMappedOwsDutiesForSelectedDutySet(),
            this.getUnlinkedOwsDutyVisibility(),
            this.getSelectedActivityTypeId(),
        ]).pipe(map(([owsResourceTypes, hideUnlinked, selectedActivityTypeId]) => {
            const uniqueIds = new Set<number>();
            const visibleResourceTypes = owsResourceTypes.filter(resourceType => hideUnlinked ?
                (resourceType.linkedOmrpActivityType === null || resourceType.linkedOmrpActivityType?.id === selectedActivityTypeId) : true
            );

            return visibleResourceTypes.map(resourceType => {
                const isLinked = resourceType.linkedOmrpActivityType !== null;
                let displayName = resourceType.name;

                if (isLinked && resourceType.linkedOmrpActivityType?.id !== selectedActivityTypeId) {
                    displayName += ' - ' + resourceType.linkedOmrpActivityType?.displayName;
                };

                if (!uniqueIds.has(resourceType.id)) {
                    uniqueIds.add(resourceType.id);
    
                    return {
                        ...resourceType, 
                        checked: isLinked,
                        disabled: selectedActivityTypeId === undefined || (isLinked && resourceType.linkedOmrpActivityType?.id !== selectedActivityTypeId),
                        displayName
                    };
                }
            }).filter(Boolean);
        }));
    }

    public getFilteredActivityTypesWithFullName(): Observable<Array<ActivityType>> {
        return combineLatest([
            this.activityTypeQuery.getSingleConfiguredActivityTypes(),
            this.getSelectedOrganizationUnit(),
            this.getSelectedActivityTypeCategory(),
            this.getShowUnderlyingUnitsState(),
            this.getShowPastActivityTypesState(),
            this.getShowFutureActivityTypesState()
        ]).pipe(
            map(([activityTypes, filteredOrganizationIds, filteredCategoryId, showUnderlyingUnits, showPastActivityTypes, showFutureActivityTypes]) => {
                
                if (!showUnderlyingUnits) {
                    return activityTypes.filter(activityType => activityType.ownerOrganizationUnitId === filteredOrganizationIds && activityType.categoryId === filteredCategoryId && this.activityTypeQuery.isActivityTypeValid(activityType, showPastActivityTypes, showFutureActivityTypes));
                }
                else {
                    const childUnitIds = this.organizationUnitQuery.getAllChildUnitsForUnitSync(filteredOrganizationIds).map(unit => unit.id);
                    childUnitIds.push(filteredOrganizationIds);

                    return activityTypes.filter(activityType => childUnitIds.includes(activityType.ownerOrganizationUnitId) && activityType.categoryId === filteredCategoryId && this.activityTypeQuery.isActivityTypeValid(activityType, showPastActivityTypes, showFutureActivityTypes));
                }
            }),
            map((actTypes) => {
                const mutableActTypes = JSON.parse(JSON.stringify(actTypes));

                const actTypesWithFullName = mutableActTypes.map(actType => ({ ...actType, displayName: actType.shortName ? actType.displayName += ` (${actType.shortName})` : actType.displayName }));
                const sortedActTypes = actTypesWithFullName.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

                return sortedActTypes;
            })
        );
    }

    public getUnlinkedDutiesCount(): Observable<number> {
        return this.getAllMappedOwsDutiesForSelectedDutySet().pipe(
            map((duties) => {
                return duties.filter(duty => duty.linkedOmrpActivityType === null).length;
            })
        );
    }
}
