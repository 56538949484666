import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { OrganizationUnitsManagementQuery } from '../../organization-units/store/organization-units-management.query';
import { ResourceTypesManagementState, ResourceTypesManagementStore } from './resource-types-management.store';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypesManagementQuery extends Query<ResourceTypesManagementState> {
    constructor(
        protected store: ResourceTypesManagementStore,
        protected resourceTypeQuery: ResourceTypeQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected organizationUnitManagementQuery: OrganizationUnitsManagementQuery,
        private readonly translateService: TranslateService,
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return combineLatest([
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([resourceTypesLoading, organizationUnitsLoading]) => {
                return !resourceTypesLoading && !organizationUnitsLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    public getFilteredResourceTypes(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.resourceTypeQuery.getResourceTypesUI(),
            this.getSelectedOrganizationUnit(),
            this.getShowUnderlyingUnitsState(),
        ]).pipe(
            map(([resourceTypes, selectedOrganizationUnitId, getShowUnderlyingUnitsState]) => {
               
                return this.filterResourceTypesOnOrganizationUnit(resourceTypes, selectedOrganizationUnitId, getShowUnderlyingUnitsState);
            })
        );
    }

    public isResourceTypeVisible(id: number): boolean {
        const visibleTypes = this.filterResourceTypesOnOrganizationUnit(
            this.resourceTypeQuery.getResourceTypesSync(),
            this.getValue().selectedOrganizationUnitId,
            this.getValue().showUnderlyingUnits
        );

        return visibleTypes.find(entity => entity.id === id) !== undefined;
    }

    public getCanDeleteSelectedEntityState(): Observable<boolean> {
        return this.getSelectedResourceType().pipe(
            filter(resourceType => !!resourceType),
            map(resourceType =>
                !(resourceType.hasDependingActivityTypes || resourceType.hasDependingResources) &&
                (!resourceType.validSkillIds || resourceType.validSkillIds.length === 0))
        );
    }

    public getSelectedUIResourceType(): Observable<EntityUI> {
        return this.getCurrentResourceTypeId().pipe(
            switchMap((resourceTypeId) => {
                return this.resourceTypeQuery.getUIResourceType(resourceTypeId);
            })
        );
    }

    public getCurrentResourceTypeId(): Observable<number> {
        return this.select(state => state.selectedResourceTypeId);
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }
    
    public getCurrentResourceTypeIdSync(): number {
        return this.getValue().selectedResourceTypeId;
    }

    public getSelectedResourceType(): Observable<ResourceType> {
        return this.getCurrentResourceTypeId().pipe(
            switchMap((resourceTypeId) => {
                if (resourceTypeId === -1) {
                    return of(this.getNewResourceTypeObject());
                } else {
                    return this.resourceTypeQuery.getResourceType(resourceTypeId);
                }
            })
        );
    }

    public getNewResourceTypeObject(): ResourceType {
        const selectedOrganizationUnit = this.getSelectedOrganizationSync();
        const validOrgUnitIds: Array<number> = [];

        if (selectedOrganizationUnit && selectedOrganizationUnit.maxPermissionForCurrentUser > 1)
        {
            validOrgUnitIds.push(selectedOrganizationUnit.id);
        }

        return {
            id: -1,
            displayName: this.translateService.instant('New resource type'),
            validOrganizationUnitIds: validOrgUnitIds,
            receivedOrder: 0,
            maximumOccupation: 1,
            validSkillIds:[],
            restrictedResourceTypeIds: []
        };
        
    }

    public getCurrentOrganizationUnitIdSync(): number {
        return this.getValue().selectedOrganizationUnitId;
    }
    
    public getSelectedOrganizationSync(): OrganizationUnit {
        const selectedId = this.getCurrentOrganizationUnitIdSync();
        
        return this.organizationUnitQuery.getOrganizationUnitSync(selectedId);
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.selectedOrganizationUnitId);
    }

    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.showUnderlyingUnits);
    }

    private filterResourceTypesOnOrganizationUnit(resourceTypes: Array<ResourceType>, organizationUnitId: number, showUnderlyingUnits: boolean): Array<ResourceType> {
        const organizationUnit = this.organizationUnitQuery.getOrganizationUnitByIdSync(organizationUnitId);
        if (organizationUnit) {
            const validUnitIds = [organizationUnit.id];
            if (showUnderlyingUnits) {
                const childUnitIds = this.organizationUnitQuery.getAllChildUnitsForUnitSync(organizationUnitId)?.map(unit => unit.id);
                validUnitIds.push(...(childUnitIds || []));
            }

            return resourceTypes.filter(resourceType => (resourceType.validOrganizationUnitIds || []).some(id => validUnitIds.includes(id)));
        }

        return [];
    }
}