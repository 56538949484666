<mat-form-field class="bol-form-field" appearance="fill">
    <mat-label>{{ props.label }}</mat-label>
    <input
        *ngIf="props.type !== 'number'; else numberTmp"
        matInput
        [id]="key"
        [name]="field.name"
        [type]="type || 'text'"
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="formControl"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
        [formlyAttributes]="field"
    />
    <ng-template #numberTmp>
        <input
            matInput
            [id]="key"
            [name]="field.name"
            type="number"
            [readonly]="props.readonly"
            [required]="props.required"
            [formControl]="formControl"
            [tabIndex]="props.tabindex"
            [placeholder]="props.placeholder"
            [formlyAttributes]="field"
        />
    </ng-template>
    <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
</mat-form-field>