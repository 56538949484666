import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface EditTemplatesManagementState {
    currentWeek: number;
    maxWeeks: number;
    searchActivityTypesValue: string;
    searchCountersValue: string;
    currentTemplateId: number;
    selectedActivityTimeslotId: number;
}

const initialState = {
    currentWeek: 1,
    maxWeeks: 1,
    searchActivityTypesValue: '',
    searchCountersValue: '',
    currentTemplateId: 0,
    selectedActivityTimeslotId: 0
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'counters-management' })
export class EditTemplatesManagementStore extends Store<EditTemplatesManagementState> {
    constructor() {
        super(initialState);
    }

    public increaseWeekNumber(): void {
        // Don't allow to increase week number if it's already the last week
        if (this.getValue().currentWeek === this.getValue().maxWeeks) {
            return;
        }
        this.update(state => ({ ...state,  currentWeek: state.currentWeek + 1 }));
    }

    public decreaseWeekNumber(): void {
        // Don't allow to decrease week number if it's already the first week
        if (this.getValue().currentWeek === 1) {
            return;
        }
        this.update(state => ({ ...state,  currentWeek: state.currentWeek - 1 }));
    }

    
    public updateSearchActivityTypesValue(searchActivityTypesValue: string): void {
        this.update(state => ({ ...state,  searchActivityTypesValue }));
    }

    public updateMaxWeeksValue(maxWeeks: number): void {
        this.update(state => ({ ...state,  maxWeeks }));
    }

    public updateSearchCountersValue(searchCountersValue: string): void {
        this.update(state => ({ ...state,  searchCountersValue }));
    }

    public updateCurrentTemplateId(currentTemplateId: number): void {
        this.update(state => ({ ...state,  currentTemplateId }));
    }

    public updateSelectedActivityTimeslotId(selectedActivityTimeslotId: number): void {
        this.update(state => ({ ...state,  selectedActivityTimeslotId }));
    }
}
