import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { NestedTreeService } from 'src/app/shared/services/nested-tree.service';

@Component({
    selector: 'app-filter-tree-single-select',
    templateUrl: './filter-tree-single-select.component.html',
    styleUrls: ['./filter-tree-single-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTreeSingleSelectComponent implements OnChanges, OnDestroy {
    @ViewChild('matSelect') public matSelect: MatSelect;
    @Output() public readonly selectedEntityIdChanged = new EventEmitter<number>();
    @Input() public errorMessage: string = 'VALIDATORS.REQUIRED';
    @Input() public readonly labelText: string;
    @Input() public readonly disabled: boolean;
    @Input() public readonly required: boolean;
    @Input() public readonly hiddenEntityId: number;
    @Input() public readonly dataCyLabel: string;
    @Input() private readonly entities: Array<any>;
    @Input() private readonly selectedEntityId: number;
  
    public selectInputControl = new UntypedFormControl();
    public dataSource = new MatTreeNestedDataSource<any>();
    public treeControl = new NestedTreeControl<any>(node => node.children);
    public noResultsFoundText = 'No results found.';
    public noVisibleEntities = true;
    public selectedEntity: any;
    private readonly subscription: Subscription = new Subscription();

    // commented for now, will be usefull ben adding seach
    //   this.filterInputControl.valueChanges.pipe(debounceTime(300)).subscribe(entityFilterValue => {
    //     const filterValue = entityFilterValue.toLowerCase();
    //     this.noVisibleEntities = true;
    //     this._recursiveEntityFilter(filterValue, this.dataSource.data);
    //   })

    constructor(
        private readonly nestedTreeService: NestedTreeService,
    ) {}

    public ngOnChanges(): void {
        this.nestedTreeService.setChildrenSelectedEntityState(this.entities, [this.selectedEntityId]);

        this.dataSource.data = this.entities;
        this._recursiveEntityFilter('', this.dataSource.data); // ensures visiblity of entities is set
        if (this.selectedEntityId === undefined) {
            this.selectedEntity = undefined;
        }
        else {
            this.selectDeepEntity(this.entities);
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onTreeNodeSelect(entity: any): void {
        this.selectedEntity = entity;
        this.selectedEntityIdChanged.emit(entity.id);
        this.matSelect.close();
    }

    public hasChild(_: number, node: any): boolean {
        return !!node.children && node.children.length > 0;
    }

    private selectDeepEntity(entities: Array<any>): void {
        if (!entities || entities.length === 0) {
            return;
        }
        const matchingEntity = entities.find(entity => entity.id === this.selectedEntityId);
        if (matchingEntity) {
            this.selectedEntity = matchingEntity;

            return;
        }
        entities.forEach(entity => {
            this.selectDeepEntity(entity.children);
        });
    }

    private _recursiveEntityFilter(filterValue: string, entities: Array<any>, parentEntity?: any): void {
        let oneEntityVisible = false;
        entities.forEach(entity => {
            if (entity.displayName.toLowerCase().indexOf(filterValue) >= 0) {
                entity.visible = true;
                oneEntityVisible = true;
                this.noVisibleEntities = false;
                this.noResultsFoundText = '';
            }
            else {
                entity.visible = false;
            }
            if (entity.children) {
                this._recursiveEntityFilter(filterValue, entity.children, entity);
            }
        });
        if (parentEntity && oneEntityVisible) {
            parentEntity.visible = true;
        }
    }
}
