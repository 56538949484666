/* eslint-disable @typescript-eslint/naming-convention */
export enum SHOW_OPTIONS {
    SHOW_START_AND_END_TIME = 'Show start time and end time',
    SHOW_ORGANIZATION_UNIT = 'Show organization unit',
    SHOW_START_AND_END_TIME_EXTRA_RESOURCE = 'Show start time and end time of extra resource',
    SHOW_SAT_SUN = 'Show sat/sun',
    SHOW_ORGANIZATION_UNIT_EXTRA_RESOURCE = 'Show organization unit of extra resource',
    SHOW_MEMO = 'Show memo',
    SHOW_MEMO_EXTRA_RESOURCE = 'Show memo of extra resource',
    SHOW_ACTIVITY_NAME = 'Show activity name',
    SHOW_ACTIVITY_SHORT_NAME = 'Show activity short name',
    SHOW_ACTIVITY_NAME_EXTRA_RESOURCE = 'Show activity name of extra resource',
    SHOW_ACTIVITY_SHORT_NAME_EXTRA_RESOURCE = 'Show activity short name of extra resource',
    SHOW_ME_ON_TOP = 'Show me on top'
}

export enum GROUPING_OPTIONS {
    RESOURCE_TYPE = 'Resource type',
    ORGANIZATION_UNIT = 'Organization unit',
    NO_GROUPING = 'No grouping',
    DAY_PART = 'Daypart'
}

export const enum SCHEDULE_STORAGE_KEYS {
    FILTERS_PANEL_STATE = 'scheduleFiltersPanelState',
    FILTER_SETTING_ID = 'scheduleFilterSettingId',
}
