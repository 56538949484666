<div class="filter-settings-managing">
    <button 
        *ngIf="(filterSettings$ | async).length > 0" 
        mat-button class="bol-button-outlined default-button manage-filters" 
        (click)="onDisplayFilterSettingConfigurationDialog()"
        data-cy="manageFiltersButton">
        <mat-icon>settings</mat-icon>
        <span translate>Manage filters</span>
    </button>
    
    <button *ngIf="filterChangesInPending$ | async" mat-button class="bol-button-outlined default-button" (click)="onDisplayFilterSettingSaveDialog()">
        <mat-icon>add_circle_outline</mat-icon>
        <span *ngIf="!(selectedFilterSettingId$ | async)" translate data-cy="saveNewFilterButton">Save new filter</span>
        <span *ngIf="selectedFilterSettingId$ | async" translate data-cy="saveFilterChangesButton">Save filter changes</span>
    </button>
</div>