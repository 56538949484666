import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';

import { ActivityChangeStore } from './activity-change.store';
import { ActivityChange, ActivityChangeRequestParameters } from './activity-change.model';

@Injectable({
    providedIn: 'root'
})
export class ActivityChangeService {
    constructor(
        protected activityChangeStore: ActivityChangeStore,
        private readonly errorDialogService: ErrorDialogService,
        private readonly translateService: TranslateService,
        private readonly http: HttpClient,
    ) { }

    public getActivityChanges(parameters: ActivityChangeRequestParameters): Observable<Array<ActivityChange>> {
        this.activityChangeStore.updateEntitiesLoadingState(true);

        return this.http.post<Array<ActivityChange>>('/api/Activities/Changes', parameters).pipe(
            catchError((error) => {
                this.activityChangeStore.updateEntitiesLoadingState(false);
                const modalTitle = this.translateService.instant('ERROR.LOADING-LARGE-DATA-ERROR');
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(error);
            }),
            tap((activityChanges) => {
                this.activityChangeStore.setActivityChanges(activityChanges);
                this.activityChangeStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public updateSelectedOrginsState(state: Array<number>): void {
        this.activityChangeStore.updateSelectedOrginsState(state);
    }

    public updateSelectedActionTypes(options: Array<number>): void {
        this.activityChangeStore.updateSelectedActionTypes(options);
    }
}
