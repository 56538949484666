import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResetScheduleService {
    public resetScheduleSignal$!: Observable<boolean>;

    private readonly resetScheduleSignal = new BehaviorSubject<boolean>(true);

    constructor() {
        this.resetScheduleSignal$ = this.resetScheduleSignal.asObservable();
    }

    public get resetScheduleState(): boolean {
        return this.resetScheduleSignal.value;
    }

    public resetSchedule(state: boolean): void {
        this.resetScheduleSignal.next(state);
    }
}
