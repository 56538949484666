<mat-form-field class="bol-form-field chip-list">
    <mat-chip-grid #chipList>
    <mat-chip-row
        *ngFor="let entity of selectedEntities$ | async"
        [removable]="entity?.removable !== false"
        (removed)="removeEntity(entity)">
        {{ entity.displayName }}
        <mat-icon matChipRemove *ngIf="entity?.removable !== false">cancel</mat-icon>
    </mat-chip-row>
    <input
        placeholder="{{ placeholderText }}{{ required ? '*' : '' }}"
        #chipListInput
        (click)="focusEntitiesInput()"
        [formControl]="chipFormControl"
        [matAutocomplete]="chipListAuto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addEntity($event)"
    />
    </mat-chip-grid>
    <mat-autocomplete #chipListAuto="matAutocomplete" (optionSelected)="selectedEntity($event)">
    <mat-option *ngFor="let filteredEntity of filteredEntities$ | async" [value]="filteredEntity">
        {{ filteredEntity.displayName }}
    </mat-option>
    <mat-option *ngIf="showNoResultsFoundText" disabled>{{ noResultsFoundText }}</mat-option>
    </mat-autocomplete>
    <mat-error>{{errorMessage}}</mat-error>
</mat-form-field>