import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-empty-filters-panel',
    templateUrl: './empty-filters-panel.component.html',
    styleUrls: ['./empty-filters-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyFiltersPanelComponent implements OnInit, OnDestroy {
    @Input() public filtersPanelState: boolean;
    @Input() public readonly collapsePanelMessage: string = 'Hide filters';
    @Input() public readonly expandPanelMessage: string = 'Show filters';

    @Output() public readonly updateShowFiltersState = new EventEmitter<boolean>();

    public showFilters$!: Observable<boolean>;

    private readonly showFiltersSubject = new BehaviorSubject<boolean>(true);
    private readonly subscription = new Subscription();

    public ngOnInit(): void {
        this.showFilters$ = this.showFiltersSubject.asObservable();
        this.showFiltersSubject.next(this.filtersPanelState);

        this.subscription.add(
            this.showFilters$.subscribe(
                state => {
                    if (this.filtersPanelState !== state) {
                        this.updateShowFiltersState.next(state);
                        this.filtersPanelState = state;
                    }
                }
            )
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public toggleShowFilters(): void {
        this.showFiltersSubject.next(!this.showFiltersSubject.value);
    }

    public setFilterPanel(state: boolean): void {
        this.filtersPanelState = state;
        this.showFiltersSubject.next(state);
    }
}
