<app-topbar></app-topbar>

<ng-container *ngIf="(setupInProgress$ | async) === false">
    <router-outlet></router-outlet>
</ng-container>

<div class="overlay-spinner" *ngIf="
    (setupInProgress$ | async) && 
    (authenticationInProgress$ | async) === false && 
    (errorInAuthentication$ | async) === false">
    <mat-spinner diameter="100"></mat-spinner>
</div>

<soca-test-border *ngIf="showRedBorder"></soca-test-border>
