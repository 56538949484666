import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, skip, startWith, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { EmptyFiltersPanelComponent } from 'src/app/shared/components/empty-filters-panel/empty-filters-panel.component';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import {
    ActivityTypeCategoryService,
} from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { ScenarioQuery } from 'src/app/shared/stores/scenario-store/scenario.query';
import { ScenarioService } from 'src/app/shared/stores/scenario-store/scenario.service';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { SkillService } from 'src/app/shared/stores/skill-store/skill.service';
import { ScenarioType } from 'src/app/shared/stores/scenario-store/scenario.model';
import { DaypartService } from 'src/app/shared/stores/day-part-store/day-part.service';

import { GlobalSettingsQuery } from '../../../shared/stores/global-settings/global-settings.query';
import { CountersFiltersService } from './counters-helpers/counters-filters.service';
import {
    COUNTERS_COUNTER_TYPE,
    COUNTERS_GROUPING_OPTIONS,
    COUNTERS_STORAGE_KEYS,
    COUNTERS_TOTALS_OPTIONS,
} from './counters-helpers/enums';
import { CountersEnumToFilterItem, CountersFiltersOptions, CountersFiltersSelectedOptions } from './counters-helpers/filters.model';
import { CountersQuery } from './stores/counters-store/counters.query';
import { CountersService } from './stores/counters-store/counters.service';

@Component({
    selector: 'app-counters-overview',
    templateUrl: './counters-overview.component.html',
    styleUrls: ['./counters-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountersOverviewComponent implements OnInit, OnDestroy {
    @ViewChild(EmptyFiltersPanelComponent) public filterPanel: EmptyFiltersPanelComponent;

    public countersFiltersOptions: CountersFiltersOptions = {
        organizationUnits$: of([]),
        resourceTypes$: of([]),
        extraResourceTypes$: of([]),
        skills$: of([]),
        activityTypes$: of([]),
        activityTypeCategories$: of([]),
        totals$: of([]),
        counterTypes$: of([]),
        scenarios$: of([]),
        groupingOptions$: of([]),
        enableActivityTypeDetailState$: of(false),
        startDate$: of(''),
        endDate$: of(''),
        exportUnplannedLeavesState$: of(false)
    };
    public countersFiltersSelectedOptions: CountersFiltersSelectedOptions = {
        selectedOrganizationUnits$: of([]),
        selectedOrganizationUnitsForActivities$: of([]),
        selectedActivityTypeIds$: of([]),
        selectedGroupingOptions$: of([]),
        selectedScenario$: of(),
        selectedActivityTypeCategoryIds$: of([]),
        selectedResourceTypeIds$: of([]),
        selectedExtraResourceTypeIds$: of([]),
        selectedSkillIds$: of([]),
        selectedCounterTypes$: of([]),
        selectedTotals$: of([]),
    };
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public isLoadingFilters$!: Observable<boolean>;
    public filtersPanelState = true;

    private readonly subscription = new Subscription();

    constructor(
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly countersService: CountersService,
        private readonly countersQuery: CountersQuery,
        private readonly countersFiltersService: CountersFiltersService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly activityTypeCategoryService: ActivityTypeCategoryService,
        private readonly activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly skillService: SkillService,
        private readonly skillQuery: SkillQuery,
        private readonly scenarioService: ScenarioService,
        private readonly scenarioQuery: ScenarioQuery,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly utilsService: UtilsService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly daypartService: DaypartService,
    ) { }

    public ngOnInit(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.setStartAndEndDates();
        this.setResourceTypesFilter();
        this.setSkillFilter();
        this.setActivityTypeFilter();
        this.setActivityTypeCategoryFilter();
        this.setGroupingOptionsFilter();
        this.setTotalFilter();
        this.setCounterTypeFilter();
        this.setScenarioFilter();
        this.setOrganizationUnitFilter();
        this.setEnableActivityTypeState();
        this.setFiltersPanelState();
        this.setOrganizationUnitFilterForActivity();

        this.isLoadingFilters$ = this.countersQuery.getFilterEntitiesLoadingState();
        this.filterSettings$ = this.filterSettingQuery.getFilterSettings();
        this.daypartService.getDayparteNew().pipe(first()).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.countersService.resetState();
        this.filterSettingService.updateSelectedFilterSettingsId(undefined);
    }

    public onGoToExport(): void {
        const config = this.countersQuery.getCountersExportConfiguration();
        this.filterSettingService.updateSelectedFilterSettingsId(undefined);

        this.router.navigate(['/overviews/counters-overview/export'], { state: config });
    }

    public onSelectedResourceTypeIdsChanged(ids: Array<number>): void {
        const storeIds = this.countersQuery.getSelectedResourceTypeIdsSync();
        ids = ids.length > 0  ? ids : undefined;

        if (!this.utilsService.arraysEqual(storeIds, ids) && !this.resourceTypeQuery.getEntitiesLoadingStateSync()) {
            this.countersService.updateResourceTypeIds(ids);
        }
    }

    public onSelectedSkillIdsChanged(ids: Array<number>): void {
        this.countersService.updateSkillIds(ids);
    }

    public onSelectedActivityTypeIdsChanged(ids: Array<number>): void {
        this.countersService.updateActivityTypeIds(ids);
        const allActivityTypeIdsSelectedState = this.countersQuery.allActivityTypeIdsSelectedState();
        this.countersService.updateAllActivityTypeIdsSelectedState(allActivityTypeIdsSelectedState);
    }

    public onSelectedActivityTypeCategoryIdsChanged(ids: Array<number>): void {
        this.countersService.updateActivityTypeCategoryIds(ids);
    }

    public onSelectedOrganizationUnitForActivitiesChanged(ids: Array<number>): void {
        this.countersService.updateOrganizationUnitForActivityIds(ids);
        const allOrganizationUnitsSelectedState = this.countersQuery.allOrganizationUnitsSelectedState();
        this.countersService.updateAllOrganizationUnitsSelectedStateChanged(allOrganizationUnitsSelectedState);
    }

    public onSelectedGroupingOptionsChanged(options: Array<COUNTERS_GROUPING_OPTIONS>): void {
        this.countersService.updateGroupingOptions(options);
    }

    public onSelectedTotalsChanged(totals: Array<COUNTERS_TOTALS_OPTIONS>): void {
        const storeTotals = this.countersQuery.getTotalsOptionsSync();

        if (!this.utilsService.arraysEqual(storeTotals, totals)) {
            this.countersService.updateTotalsOptions(totals);
        }
    }

    public onSelectedCounterTypesChanged(types: Array<COUNTERS_COUNTER_TYPE>): void {
        const storeCounterTypes = this.countersQuery.getCounterTypeOptionsSync();

        if (!this.utilsService.arraysEqual(storeCounterTypes, types)) {
            this.countersService.updateCounterTypeOptions(types);
        }
    }

    public onSelectedScenarioIdChanged(id: number): void {
        this.countersService.updateScenarioId(id);
    }

    public onUpdateActivityTypeDetailsState(state: boolean): void {
        this.countersService.updateActivityTypeDetailsState(state);
    }

    public onStartDateChanged(date: string): void {
        this.countersService.updateStartDate(date);
    }

    public onEndDateChanged(date: string): void {
        this.countersService.updateEndDate(date);
    }

    public onSelectedOrganizationUnitIdsChanged(ids: Array<number>): void {
        this.countersService.updateResourceOrganizationUnitIds(ids);
        const allResourceOrganizationUnitsSelectedState = this.countersQuery.allResourceOrganizationUnitsSelectedState();
        this.countersService.updateAllResourceOrganizationUnitsSelectedState(allResourceOrganizationUnitsSelectedState);
    }

    public onIntervalDatesValidityStateChanged(state: boolean): void {
        this.countersService.updateValidIntervalDates(state);
    }

    public updateShowFiltersState(state: boolean): void {
        this.countersFiltersService.setCountersItemInStorage(COUNTERS_STORAGE_KEYS.FILTERS_PANEL_STATE, state);
    }

    public setFilterPanel(state: boolean): void {
        this.filterPanel.setFilterPanel(state);
    }

    private setOrganizationUnitFilter(): void {
        this.countersFiltersSelectedOptions.selectedOrganizationUnits$ = combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.countersQuery.getSelectedOrganizationUnits()
        ]).pipe(
            map(([_, units]) => units.map(unit => unit.id))
        );

        this.countersFiltersOptions.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();

        this.subscription.add(
            this.countersQuery.getSelectedOrganizationUnitIds()
                .pipe(
                    filter(ids => !!ids)
                )
                .subscribe((ids) => {
                    this.resourceTypeService.getReportResourceTypes(ids).pipe(first()).subscribe();
                })
        );
    }

    private setOrganizationUnitFilterForActivity(): void {
        this.countersFiltersSelectedOptions.selectedOrganizationUnitsForActivities$ = combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.countersQuery.getSelectedOrganizationUnitsForActivity()
        ]).pipe(
            map(([_, units]) => units.map(unit => unit.id))
        );
    }

    private setResourceTypesFilter(): void {
        this.countersFiltersOptions.resourceTypes$ = this.resourceTypeQuery.getResourceTypes().pipe(
            filter((resourceTypes) => !!resourceTypes),
            map((resourceTypes) => {
                const mutableResTypes = resourceTypes.map(rt => {
                    return { ...rt };
                });

                return mutableResTypes;
            })
        );

        this.countersFiltersSelectedOptions.selectedResourceTypeIds$ = this.countersFiltersOptions.resourceTypes$.pipe(
            filter(rts => rts && rts.length > 0),
            withLatestFrom(this.countersQuery.getSelectedResourceTypeIds()),
            map(([_, ids]) => ids)
        );
    }

    private setSkillFilter(): void {
        this.skillService.getSkills().pipe(first()).subscribe();

        this.countersFiltersSelectedOptions.selectedSkillIds$ = this.countersQuery.getSelectedSkillIds().pipe(
            filter(skills => !!skills),
            withLatestFrom(this.skillQuery.getSkills()),
            map(([ids, _]) => ids)
        );

        this.countersFiltersOptions.skills$ = combineLatest([
            this.skillQuery.getEntitiesLoadingState(),
            this.countersQuery.getSelectedOrganizationUnitIds(),
            this.countersQuery.getSelectedResourceTypeIds(),
            this.countersQuery.getSelectedFilterSettingId(),
        ]).pipe(
            filter(([state]) =>
                !state 
            ),
            map(() => {
                const skills = this.countersQuery.getFilteredSkillsSync() ?? [];
                const mutableSkills = skills.map(skill => {
                    return { ...skill };
                });

                return mutableSkills;
            })
        );
    }

    private setActivityTypeCategoryFilter(): void {
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();

        this.countersFiltersSelectedOptions.selectedActivityTypeCategoryIds$ = this.countersQuery.getSelectedActivityTypeCategoryIds();

        const getActivityTypeCategories$ = this.activityTypeCategoryQuery.getActivityTypeCategories().pipe(skip(1));
        this.countersFiltersOptions.activityTypeCategories$ = combineLatest([
            getActivityTypeCategories$,
            this.countersQuery.getSelectedActivityTypeCategoryIds(),
            this.countersQuery.getSelectedFilterSettingId(),
        ]).pipe(
            filter(([categories, _, __]) => !!categories),
            map(([categories, _, __]) => {
                return categories
            })
        );

        const activityTypeCategoryIdsOnFilterChanged$ = this.countersQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.countersFiltersOptions.activityTypeCategories$),
            filter(([_, selectedActivityTypeCategories]) => selectedActivityTypeCategories.length > 0),
            map(([_, selectedActivityTypeCategories]) => selectedActivityTypeCategories)
        );

        merge(
            activityTypeCategoryIdsOnFilterChanged$,
            this.countersFiltersOptions.activityTypeCategories$.pipe(filter(activityTypeCategories => activityTypeCategories.length > 0), first())
        ).subscribe(activityTypeCategories => {
            let selectedActTypeCategoryIds = this.countersQuery.getSelectedActivityTypeCategoryIdsSync();
            if (!selectedActTypeCategoryIds) {
                selectedActTypeCategoryIds = activityTypeCategories.map(cat => cat.id);
            }

            const selectedActivityTypeCategories = activityTypeCategories.filter(cat => selectedActTypeCategoryIds?.includes(cat.id)).map(cat => cat.id);

            this.countersService.updateActivityTypeCategoryIds(selectedActivityTypeCategories);
        });
    }

    private setActivityTypeFilter(): void {
        this.activityTypeService.getMainActivityTypes().pipe(first()).subscribe();

        this.countersFiltersOptions.activityTypes$ = combineLatest([
            this.countersQuery.getSelectedFilterSettingId(),
            this.activityTypeQuery.getActivityTypes()
        ]).pipe(
            map(([_, actTypes]) => actTypes)
        );

        this.countersFiltersSelectedOptions.selectedActivityTypeIds$ = this.countersQuery.getActivityTypeSelectedIds();
    }

    private setGroupingOptionsFilter(): void {
        this.countersFiltersOptions.groupingOptions$ = combineLatest([
            this.countersQuery.getActivityTypeDetailsState(),
            this.translateService.onLangChange.pipe(startWith(1))
        ]).pipe(
            map(([activityTypeDetailEnabled, _]) => {
                const initialGroupingOptions = this.countersFiltersService.getInitialGroupingOptions();
                let options = initialGroupingOptions.map(item =>
                    ({ ...item, displayName: this.translateService.instant(item.displayName) })
                );
                if (!activityTypeDetailEnabled) {
                    options = options.filter(option => option.type !== COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE);
                }

                return options;
            }),
            startWith([])
        );

        this.countersFiltersSelectedOptions.selectedGroupingOptions$ = combineLatest([
            this.countersQuery.getSelectedFilterSettingId(),
            this.translateService.onLangChange.pipe(startWith(1)),
            this.countersQuery.getActivityTypeDetailsState(),
        ]).pipe(
            map(() => {
                const selectedGroupingOptions = this.countersQuery.getSelectedGroupingOptionsSync();
                const allGroupingOptions = this.countersFiltersService.getInitialGroupingOptions();

                const filteredGroupingOptions = selectedGroupingOptions.map(groupingOption =>
                    allGroupingOptions.find(option => groupingOption.toString() === option.type)
                );

                return filteredGroupingOptions.map(item => ({ ...item, displayName: this.translateService.instant(item.displayName) }));
            })
        );
    }

    private setTotalFilter(): void {
        const initialTotalItems: Array<CountersEnumToFilterItem> = this.countersFiltersService.getInitialTotals().map(total => ({
            id: total.id,
            displayName: total.displayName,
            type: total.type
        }));

        this.countersFiltersSelectedOptions.selectedTotals$ = this.countersQuery.getSelectedTotalsByIds();
        this.countersFiltersOptions.totals$ = combineLatest([
            this.countersQuery.getSelectedFilterSettingId(),
            this.translateService.onLangChange.pipe(startWith(1))
        ]).pipe(
            map(() => initialTotalItems)
        );
    }

    private setCounterTypeFilter(): void {
        const initialCounterTypeItems = this.countersFiltersService.getInitialCounterTypes().map(couterType => ({
            id: couterType.id,
            displayName: couterType.displayName,
            type: couterType.type
        }));

        this.countersFiltersSelectedOptions.selectedCounterTypes$ = this.countersQuery.getSelectedCounterTypesByIds();
        this.countersFiltersOptions.counterTypes$ = combineLatest(([
            this.countersQuery.getSelectedFilterSettingId(),
            this.translateService.onLangChange.pipe(startWith(1))
        ])).pipe(map(() =>
            initialCounterTypeItems)
        );
    }

    private setScenarioFilter(): void {
        this.scenarioService.getScenarios().pipe(first()).subscribe();

        this.countersFiltersOptions.scenarios$ = this.scenarioQuery.getScenariosByType(ScenarioType.Draft);
        this.countersFiltersSelectedOptions.selectedScenario$ = this.countersQuery.getSelectedScenario();
    }

    private setEnableActivityTypeState(): void {
        this.countersFiltersOptions.enableActivityTypeDetailState$ = this.countersQuery.getSelectedFilterSettingId().pipe(
            withLatestFrom(this.countersQuery.getActivityTypeDetailsState()),
            map(([_, state]) => state)
        );
    }

    private setStartAndEndDates(): void {
        this.countersFiltersOptions.startDate$ = combineLatest([
            this.countersQuery.getSelectedFilterSettingId(),
            this.globalSettingsQuery.getTimeZone()
        ]).pipe(
            withLatestFrom(this.countersQuery.getSelectedStartDate()),
            map(([[_, timeZone], start]) => {
                if (!start) {
                    const firstDayOfCurrentMonth = this.dateTimeUtilityService.getFirstDayOfTheCurrentMonth(false);
                    this.countersService.updateStartDate(moment(firstDayOfCurrentMonth).tz(timeZone).format());

                    return moment(firstDayOfCurrentMonth).tz(timeZone).format();
                }

                return start;
            }),
            filter(start => !!start),
            first()
        );

        this.countersFiltersOptions.endDate$ = combineLatest([
            this.countersQuery.getSelectedFilterSettingId(),
            this.globalSettingsQuery.getTimeZone()
        ]).pipe(
            withLatestFrom(this.countersQuery.getSelectedEndDate()),
            map(([[_, timeZone], end]) => {
                if (!end) {
                    const lastDayOfCurrentMonth = this.dateTimeUtilityService.getLastDayOfTheCurrentMonth(false);
                    this.countersService.updateEndDate(moment(lastDayOfCurrentMonth).tz(timeZone).format());

                    return moment(lastDayOfCurrentMonth).tz(timeZone).format();
                }

                return end;
            }),
            filter(end => !!end),
            first()
        );
    }

    private setFiltersPanelState(): void {
        const filtersPanelState = this.countersFiltersService.getCountersItemFromStorage<boolean>(COUNTERS_STORAGE_KEYS.FILTERS_PANEL_STATE);

        if (filtersPanelState !== null) {
            this.filtersPanelState = filtersPanelState;
        }
    }
}
