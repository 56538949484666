<div class="adt-table" *ngIf="(initialLoadingFinished$ | async) === true">
    <div class="adt-table__header">
        <div class="adt-table__section-info">
            <div class="adt-table__header-bar">
                <button 
                    (click)="onAddActivity()"
                    mat-button 
                    class="bol-button-solid">
                    <mat-icon>add</mat-icon>
                    <span translate>Add</span>
                </button>
        
                <mat-form-field class="bol-form-field adt-table__search">
                    <input matInput [formControl]="searchTerm" placeholder="{{'ACTIVITY-DEMAND.SEARCH-ACTIVITIES' | translate}}" autocomplete="off" />
                </mat-form-field>
            </div>

            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex2">Activity type</div>
                <div translate class="flex1">Start time</div>
                <div translate class="flex1">End time</div>
                <div translate class="flex1 distribtion-cell">ACTIVITY-DEMAND.DISTRIBUTION-TOTAL</div>
            </div>
        </div>

        <div class="adt-table__section-week">
            <div class="adt-table__header-bar">
                <div *ngIf="(maxWeeks$ | async) > 1" class="adt-table__week-wrapper">
                    <div class="adt-table__week-navigation">
                        <button 
                            (click)="onNavigateToPastWeek()"
                            mat-button 
                            class="bol-button-text bol-gray icon-only"
                            [disabled]="(currentWeek$ | async) === 1">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
    
                        {{ "WEEK.WEEK" | translate}} {{currentWeek$ | async}} / {{maxWeeks$ | async}}
                        
                        <button 
                            (click)="onNavigateToNextWeek()"
                            mat-button 
                            class="bol-button-text bol-gray icon-only"
                            [disabled]="(currentWeek$ | async) === (maxWeeks$ | async)">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
    
                    <button 
                        (click)="onCopyWeeks()"
                        mat-button 
                        class="bol-button-solid">
                        <mat-icon>content_copy</mat-icon>
                        <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.COPY-WEEKS</span>
                    </button>
                </div>
            </div>
            
            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex1">WEEK.MON</div>
                <div translate class="flex1">WEEK.TUE</div>
                <div translate class="flex1">WEEK.WED</div>
                <div translate class="flex1">WEEK.THU</div>
                <div translate class="flex1">WEEK.FRI</div>
                <div translate class="flex1">WEEK.SAT</div>
                <div translate class="flex1">WEEK.SUN</div>
                <div translate class="flex1">TOTAL.WEEK</div>
            </div>
        </div>

        <div class="adt-table__section-total">
            <div class="adt-table__header-bar"></div>
            <div class="adt-table__header-bar bol-text-medium">
                <div translate class="flex1">TOTAL.PERIOD</div>
                <div class="flex1"></div>
            </div>
        </div>
    </div>
    <form [formGroup]="formArray">
        <div class="adt-table__content" *ngIf="activityTypeTimeslotsRows$ | async as activityTypeTimeslotsRows">
            <div class="adt-table__section-info">
                <div *ngFor="let group of formArray.controls; let i = index" [formGroupName]="i" class="adt-table__row">
                    <div class="flex2">
                        {{ activityTypeTimeslotsRows[i].displayName }}
                    </div>
                    <div class="flex1">
                        {{ activityTypeTimeslotsRows[i].startTime }}
                    </div>
                    <div class="flex1">
                        {{ activityTypeTimeslotsRows[i].endTime }}
                    </div>
                    <div class="flex1 distribtion-cell">
                        <input formControlName="distributionTotal" type="number" min="0" class="adt-input" />
                    </div>
                </div>
            </div>
        
            <div class="adt-table__section-week">
                <div *ngFor="let group of formArray.controls; let i = index" class="adt-table__row" [formGroupName]="i">
                    <div formArrayName="weekDemand" class="flex1 adt-table__cell-input" *ngFor="let control of group.get('weekDemand').controls; let j = index">
                        <input [formControlName]="j" type="number" min="0" max="99" class="adt-input" [class.invalid-input]="!control.valid"/>
                    </div>
                    <div class="flex1 adt-table__cell-input">
                        {{ weeklyTotals[i] }}
                    </div>
                </div>
            </div>

            <div class="adt-table__section-total">
                <div *ngFor="let group of formArray.controls; let i = index" class="adt-table__row">
                    <div class="flex1 adt-table__cell-input">
                        {{ periodTotal[i] }}
                    </div>
                    <div class="flex1 adt-table__cell-menu">
                        <button 
                            mat-button 
                            class="bol-button-text bol-gray icon-only small-icon"
                            [matMenuTriggerFor]="activityMenu.menu"
                            [matMenuTriggerData]="{activity: activityTypeTimeslotsRows[i]}">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <app-adt-activity-type-table-menu #activityMenu [activityId]="activityTypeTimeslotsRows[i].id"></app-adt-activity-type-table-menu>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="(activityTypeTimeslotsRows$ | async)?.length === 0" class="no-entities-message">
        <p data-cy="entityNoEntitiesFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: 'activity types' | translate | lowercase } }}</p>
    </div>
</div>

<div class="overlay-spinner" *ngIf="(autoSaveLoadingStateSubject | async) === true && (navigateToWeekLoadingStateSubject | async) === true">
    <mat-spinner diameter="100"></mat-spinner>
</div>