<mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true" class="expansion-panel mat-elevation-z">
    <mat-expansion-panel-header class="header-container" data-cy="PermissionTreeHeader">
        <mat-panel-title>
            <div class="header-title">{{props.title | translate}}</div>
        </mat-panel-title>

        <mat-panel-description>
            <mat-icon *ngIf="!panelOpenState">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="panelOpenState">arrow_drop_down</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <app-permissions-tree
        *ngIf="(entities$ | async) as entities"
        [formlyAttributes]="field"
        [entityName]="props.entityName"
        [entities]="entities"
        [disabledPermissions]="props.disabled"
        [initialEntitiesWithPermissions]="initialEntitiesWithPermissions"
        [columnDefinition]="props.columnDefinition"
        (permissionItemsChanged)="onPermissionItemsChanged($event)">
    </app-permissions-tree>
</mat-expansion-panel>
