<div class="bol-modal__header">
    <span class="modal-title" data-cy="partsSelectionTitle">{{ 'Parts selection' | translate }}</span>
</div>

<mat-dialog-content>
    <div data-cy="partsSelectionMessage">
        {{ 'Also (de)select underlying parts?' | translate }}
    </div>
</mat-dialog-content>

<mat-dialog-actions class="bol-modal__actions">
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray oversized-button"
            (click)="onChangeEntityStatus(true, false)"
            data-cy="SelectionYes">
            <span>{{ 'Yes' | translate | uppercase }}</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray"
            (click)="onChangeEntityStatus(true, true)"
            data-cy="SelectionYesRemember">
            <span>{{ 'Yes & remember' | translate | uppercase }}</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray oversized-button"
            (click)="onChangeEntityStatus(false, false)"
            data-cy="SelectionNo">
            <span>{{ 'No' | translate | uppercase }}</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button 
            mat-button
            class="bol-button-text bol-gray"
            (click)="onChangeEntityStatus(false, true)"
            data-cy="SelectionRemember">
            <span>{{ 'No & remember' | translate | uppercase }}</span>
        </button>
    </div>
</mat-dialog-actions>