import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StatusPageQuery } from 'src/app/shared/stores/status-page-store/status-page.query';

import { FilteringPaletteState, FilteringPaletteStore } from './filtering-palette.store';

@Injectable({
    providedIn: 'root'
})
export class FilteringPaletteQuery extends StatusPageQuery<FilteringPaletteState> {
    constructor(
        protected readonly store: FilteringPaletteStore,
    ) {
        super(store);
    }

    public getSelectedOrganizationUnitIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedOrganizationUnitIds);
    }

    public getSelectedActivityTypeIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedActivityTypeIds);
    }

    public getSelectedActivityTypeIdsSync(): Array<number> {
        return this.getValue().ui.selectedActivityTypeIds;
    }

    public getSelectedActivityTypeCategoryIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedActivityTypeCategoryIds);
    }

    public getEntitiesLoading(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoading);
    }

    public getShowOnlySelectedState(): Observable<boolean> {
        return this.select(value => value.ui.showOnlySelectedState);
    }

    public getApplyFilterState(): Observable<boolean> {
        return this.select(value => value.ui.applyFilterState);
    }

    public getShowOnlySelectedStateSync(): boolean {
        return this.getValue().ui.showOnlySelectedState;
    }
}