import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { ResourceProperty} from 'src/app/shared/stores/resource-property-store/resource-property.model';
import { ResourcePropertyQuery } from 'src/app/shared/stores/resource-property-store/resource-property.query';

import { ResourcePropertiesManagementState, ResourcePropertiesManagementStore } from './resource-properties-management.store';

@Injectable({
    providedIn: 'root'
})
export class ResourcePropertiesManagementQuery extends Query<ResourcePropertiesManagementState> {
    constructor(
        protected store: ResourcePropertiesManagementStore,
        protected resourcePropertyQuery: ResourcePropertyQuery,
        private readonly translateService: TranslateService
    ) {
        super(store);
    }

    public getEntitiesLoading(): Observable<boolean> {
        return this.resourcePropertyQuery.getEntitiesLoadingState();
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }

    public getSelectedUIResourceProperty(): Observable<EntityUI> {
        return this.getCurrentResourcePropertyId().pipe(
            switchMap((resourcePropertyId) => {
                return this.resourcePropertyQuery.getUIResourceProperty(resourcePropertyId);
            })
        );
    }

    public getCurrentResourcePropertyIdSync(): number {
        return this.getValue().selectedResourcePropertyId;
    }

    public getSelectedResourceProperty(): Observable<ResourceProperty> {
        return this.getCurrentResourcePropertyId().pipe(
            switchMap((resourcePropertyId) => {
                return this.resourcePropertyQuery.getResourceProperty(resourcePropertyId);
            })
        );
    }

    public getNewResourcePropertyObject(): ResourceProperty {
        return {
            id: -1,
            resourcePropertyNames: [],
            displayName: this.translateService.instant('New resource property'),
        };
    }

    public getCurrentResourcePropertyId(): Observable<number> {
        return this.select(state => state.selectedResourcePropertyId);
    }
}
  
