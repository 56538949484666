import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';

import { FilteringState, FilteringStore } from './filtering.store';
import { ResourcesToHide } from './filtering.model';
import { StatusPageQuery } from 'src/app/shared/stores/status-page-store/status-page.query';

@Injectable({
    providedIn: 'root'
})
export class FilteringQuery extends StatusPageQuery<FilteringState> {
    constructor(
        protected store: FilteringStore,
        private readonly resourceQuery: ResourceQuery,
    ) {
        super(store);
    }

    public getSelectedOrganizationUnitIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedOrganizationUnitIds);
    }

    public getSelectedResourceSkillIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedResourceSkillIds);
    }

    public getSelectedActivityTypeIds(): Observable<Array<number>> {
        return this.select(value => value.ui.selectedActivityTypeIds);
    }

    public getSelectedResourceTypeId(): Observable<number> {
        return this.select(value => value.ui.selectedResourceTypeId);
    }

    public getEntitiesLoading(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoading);
    }

    public updateEntitiesLoadingSelectedResourceTypeId(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoadingSelectedResourceTypeId);
    }

    public getEntitiesLoadingSelectedHideResources(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoadingSelectedHideResources);
    }

    public getEntitiesLoadingOrganizationUnitSelection(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoadingOrganizationUnitSelection);
    }

    public getEntitiesLoadingSelectedActivityTypes(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoadingSelectedActivityTypes);
    }

    public getEntitiesLoadingResourcePropertySelection(): Observable<boolean> {
        return this.select(value => value.ui.entitiesLoadingResourcePropertySelection);
    }

    public getSelectedResourcesToHide(): Observable<Array<ResourcesToHide>> {
        return this.select(value => value.ui.selectedResourcesToHide);
    }

    public getSelectedVisibleColumns(): Observable<Array<number>> {
        return this.select(state => state.ui.selectedVisibleColumns);
    }

    public getResourceForFiltering(): Observable<Array<Resource>> {
        return combineLatest([
            this.resourceQuery.getResources(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            filter(([allResources, filteredOrganizationIds]) => allResources !== undefined && filteredOrganizationIds !== undefined),
            map(([allResources, filteredOrganizationIds]) => {
                return allResources.filter(resource => resource.organizationUnitMemberships
                    .some(memberShip => filteredOrganizationIds?.includes(memberShip.organizationUnitId))
                )
            })
        );
    }
}
