import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { ManageTemplateState, ManageTemplateStore } from './manage-template.store';
import { ManageTemplate } from './manage-template.model';
import { ManageTemplateQuery } from './manage-template.query';
import { StatusPageService } from '../status-page-store/status-page.service';
import { STATUS } from '../status-page-store/status-page.store';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplateService extends StatusPageService<ManageTemplateState> {
    constructor(
        private readonly http: HttpClient,
        protected readonly manageTemplateStore: ManageTemplateStore,
        protected readonly manageTemplateQuery: ManageTemplateQuery,
        private readonly errorDialogService: ErrorDialogService,
        private readonly router: Router,
    ) {
        super(manageTemplateStore);
     }

    public getTemplates(fullProperties: boolean): Observable<Array<ManageTemplate>> {
        this.manageTemplateStore.updateEntitiesLoadingState(true);
    
        return this.http.get<Array<ManageTemplate>>(`/api/v1/ActivityDemand/Templates?fullProperties=${fullProperties}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
    
                return throwError(() => error);
            }),
            tap((templates: Array<ManageTemplate>) => {
                this.manageTemplateStore.set(templates);
                this.manageTemplateStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public getSingleTemplate(id: number): Observable<ManageTemplate> {
        this.manageTemplateStore.updateEntitiesLoadingState(true);
    
        return this.http.get<ManageTemplate>(`/api/v1/ActivityDemand/Templates/${id}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                if (error.status === 403) {
                    this.router.navigate(['/activity-demand/manage-templates']);
                }
    
                this.manageTemplateStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((template: ManageTemplate) => {
                this.manageTemplateStore.updateSelectedTemplateForEdit(template);
                this.manageTemplateStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public saveTemplate(template: ManageTemplate): Observable<ManageTemplate> {
        return this.http.post<ManageTemplate>('/api/v1/ActivityDemand/Templates', template).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                const lastCorrectVersionOfCounter = this.manageTemplateQuery.getTemplateSync(template.id);

                return throwError(() => lastCorrectVersionOfCounter);
            }),
            tap((createdActivityDemandTemplate: ManageTemplate) => {
                this.manageTemplateStore.upsert(createdActivityDemandTemplate.id, createdActivityDemandTemplate);
            }),
        );
    }

    public updateTemplate(template: ManageTemplate): Observable<ManageTemplate> {
        return this.http.put<ManageTemplate>(`/api/v1/ActivityDemand/Templates/${template.id}`, template).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                const lastCorrectVersionOfCounter = this.manageTemplateQuery.getTemplateSync(template.id);
                this.manageTemplateStore.updatePageStatusState(STATUS.DEFAULT);

                return throwError(() => lastCorrectVersionOfCounter);
            }),
            tap((createdActivityDemandTemplate: ManageTemplate) => {
                this.manageTemplateStore.upsert(createdActivityDemandTemplate.id, createdActivityDemandTemplate);
                this.manageTemplateStore.updatePageStatusState(STATUS.IS_FINISHED_SAVING);
            }),
        );
    }

    public cloneTemplate(template: ManageTemplate): Observable<ManageTemplate> {
        return this.http.post<ManageTemplate>(`/api/v1/ActivityDemand/Templates/${template.id}/Copy`, template).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
    
                return throwError(() => error);
            }),
            tap((clonedActivityDemandTemplate: ManageTemplate) => {
                this.manageTemplateStore.upsert(clonedActivityDemandTemplate.id, clonedActivityDemandTemplate);
            }),
        );
    }

    public deleteTemplate(templateId: number): Observable<void> {
        this.manageTemplateStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/v1/ActivityDemand/Templates/' + templateId).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.manageTemplateStore.updateEntitiesLoadingState(false);
                
                return throwError(() => error);
            }),
            tap((response) => {
                this.manageTemplateStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.manageTemplateStore.remove(templateId);
                }
            }),
        ); 
    }
}
