import { Injectable } from '@angular/core';
import { combineQueries, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityUI, EntityUIState } from '../entity-ui-models';

import { ResourceType } from './resource-type.model';
import { ResourceTypeState, ResourceTypeStore } from './resource-type.store';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeQuery extends QueryEntity<ResourceTypeState> {
    public ui: EntityUIQuery<EntityUIState>;
    
    constructor(
        protected store: ResourceTypeStore,
    ) {
        super(store);
        this.createUIQuery();
    }

    public getUnsortedResourceTypesSync(): Array<ResourceType> {
        return Object.values(this.getValue().entities);
    }

    public getResourceTypeSync(id: number): ResourceType {
        return this.getEntity(id);
    }

    public getResourceType(id: number): Observable<ResourceType> {
        return this.selectEntity(id);
    }

    public getUIResourceType(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }
    
    public getResourceTypes(): Observable<Array<ResourceType>> {
        return this.selectAll().pipe(map(resourceTypes =>
            resourceTypes.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getMutableResources(): Observable<Array<ResourceType>> {
        return this.getResourceTypes().pipe(
            map((resourceTypes) => {
                const mutableResourceTypes = resourceTypes.map(resourceType => {
                    return { ...resourceType};
                });

                return mutableResourceTypes;
            })
        );
    }

    public getResourceTypesUI(): Observable<Array<ResourceType>> {
        return combineQueries([
            this.selectAll(),
            this.ui.selectAll()
        ]).pipe(map(([resourceTypes, resourceTypesUI]) => resourceTypes
            .map(user => ({...user, ...resourceTypesUI.find(u => u.id === user.id)}))
            .sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getResourceTypesSync(): Array<ResourceType> {
        const types = this.getUnsortedResourceTypesSync();

        return types.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getResourceTypesByIdsSync(ids: Array<number>): Array<ResourceType> {
        const types = this.getUnsortedResourceTypesSync();

        return types.filter(u => ids.includes(u.id));
    }
}
