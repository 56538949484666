<mat-card  class="bol-card" class="content-wrapper">
    <mat-card-content>
        <div class="title-status">
            <h3 class="sorting-title" translate data-cy="sortPaletteTitle">SORT-PALETTE.PAGE-TITLE</h3>

            <app-loading-status-indication
                [statusPageState]="statusPageState$ | async"
            ></app-loading-status-indication>
        </div>
      
        <div class="activities-checkbox">
            <mat-checkbox
                class="bol-checkbox"
                data-cy="applySortPalette"
                [checked]='applySortingState$ | async'
                (change)="onApplySorting($event)">
                <span translate="">SORT-PALETTE.APPLY-SORT</span>
            </mat-checkbox>
        </div>

        <div>
            <div class="filters-activity-types">
                <soca-filter-tree-single-select
                    data-cy="activityTypeCategoryFilter"
                    [entities]="activityTypeCategories$ | async"
                    labelText="Activity type categories"
                    [disabled]="false"
                    [selectedEntityId] ="selectedActivityTypeCategoryId$ | async"
                    (selectedEntityIdChanged)="onSelectedActivityTypeCategoryChange($event)">
                </soca-filter-tree-single-select>
            </div>
            <div>
                <app-multiselect-sorting-table
                    [entities]="filteredSortableActivityTypes$ | async"
                    [columnDefinition]="columnDefinition"
                    [narrowRows]="true"
                    [entityName]="'activity types'"
                    (entitiesSortedIdsChanged)="onReorderdEntitiesChanged($event)"
                ></app-multiselect-sorting-table>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
