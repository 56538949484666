import { Injectable } from '@angular/core';
import { Query} from '@datorama/akita';
import { combineLatest, Observable} from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';

import { Holiday, HolidayYear } from './holiday.model';
import { HolidayState, HolidayStore } from './holiday.store';

@Injectable({
    providedIn: 'root'
})
export class HolidayQuery extends Query<HolidayState> {

    constructor(
        protected store: HolidayStore
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return this.getEntitiesLoadingState().pipe(
            filter((holidaysLoaded) => {
                return !holidaysLoaded;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }
    
    public getHolidaysForYear(): Observable<HolidayYear> {
        return combineLatest([
            this.select(state => state.ui.years),
            this.select(state => state.ui.currentYear)
        ]).pipe(
            filter(([_, currentYear]) => !!currentYear),
            map(([years, currentYear]) => years?.find(holidayYear => holidayYear?.yearLoad === currentYear))
        );
    }

    public getHolidaysForYearSync(year: number): Array<Holiday> {
        const holidayYear = this.getValue().ui.years?.find(hY => hY.yearLoad === year);
     
        return holidayYear?.holidays;
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getCurrentYearSync(): number {
        return this.getValue().ui.currentYear;
    }

    public isYearLoaded(year: number): boolean {
        const holidayYear = this.getValue().ui.years.find(holidays => holidays.yearLoad === year);

        return holidayYear ? holidayYear.loaded : false;
    }
}
