/* eslint-disable @typescript-eslint/naming-convention */
export enum DISPLAY_SETTING_NAMES {
    ORGANIZATION_UNIT = 'organizationUnitId',
    INCLUDE_CHILD_ORGANIZATION_UNIT = 'includeChildOrganizationUnit',
    INCLUDE_PAST_ACTIVITY_TYPES = 'includePastActivityTypes',
    INCLUDE_FUTURE_ACTIVITY_TYPES = 'includeFutureActivityTypes',
    INCLUDE_PAST_DUTIES = 'includePastDuties',
    INCLUDE_FUTURE_DUTIES = 'includeFutureDuties',
    EVENT_TYPE = 'eventTypeIds',
    ORIGIN_CHANGES = 'originIds',
    ACTIVITY_TYPE_CATEGORY = 'activityTypeCategoryId',
    PLANBOARD_RESOURCE_SELECTION_ID = 'planboard.resourceSelectionId',
    PLANBOARD_RESOURCE_PROPERTY_ID = 'planboard.sortByResourcePropertyId',
    SORT_DESCENDING = 'planboard.sortDescending',
    APPLY_ACTIVITY_TYPE_FILTER_TO_OPEN = 'planboard.applyActivityFilterToOpen',
    APPLY_ACTIVITY_TYPE_FILTER = 'planboard.applyActivityTypeFilter',
    APPLY_ACTIVITY_TYPE_SORT = 'planboard.applyActivityTypeSort'
}
