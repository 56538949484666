<div class="counters__overview__container">    
    <div class="overlay-spinner" *ngIf="isLoadingFilters$ | async">
        <mat-spinner diameter="100"></mat-spinner>
    </div>

    <div class="filters__container">
        <app-empty-filters-panel
            [filtersPanelState]="filtersPanelState"
            (updateShowFiltersState)="updateShowFiltersState($event)">
            <app-counters-filters-header
                (setPanelState)="setFilterPanel($event)"
                filtersHeader>
            </app-counters-filters-header>

            <div class="filters__content" filtersContent>
                <app-counters-filters
                    [countersFiltersOptions]="countersFiltersOptions"
                    [countersFiltersSelectedOptions]="countersFiltersSelectedOptions"
                    (organizationUnitIdsSelectedChanged)="onSelectedOrganizationUnitIdsChanged($event)"
                    (startDateChanged)="onStartDateChanged($event)"
                    (endDateChanged)="onEndDateChanged($event)"
                    (totalsSelectedChanged)="onSelectedTotalsChanged($event)"
                    (enabledActivityTypeDetailStateChanged)="onUpdateActivityTypeDetailsState($event)"
                    (counterTypesSelectedChanged)="onSelectedCounterTypesChanged($event)"
                    (groupingOptionsSelectedChanged)="onSelectedGroupingOptionsChanged($event)"
                    (activityTypeCategoryIdsSelectedChanged)="onSelectedActivityTypeCategoryIdsChanged($event)"
                    (activityTypeIdsSelectedChanged)="onSelectedActivityTypeIdsChanged($event)"
                    (organizationUnitForActivityIdsSelectedChanged)="onSelectedOrganizationUnitForActivitiesChanged($event)"
                    (skillIdsSelectedChanged)="onSelectedSkillIdsChanged($event)"
                    (scenarioIdSelectedChanged)="onSelectedScenarioIdChanged($event)"
                    (resourceTypeIdsSelectedChanged)="onSelectedResourceTypeIdsChanged($event)"
                    (intervalDatesValidityStateChanged)="onIntervalDatesValidityStateChanged($event)"
                ></app-counters-filters>

                <div class="filter-settings">
                    <app-counters-filter-settings></app-counters-filter-settings>
                </div>
            </div>
        </app-empty-filters-panel>
    </div>
    <div class="matrix__container">
        <app-counters-matrix></app-counters-matrix>
    </div>
    <div>
        <button mat-button class="bol-button-text" (click)="onGoToExport()">{{ 'Not enough info? Click here to make your own overview with Excel with raw data' | translate }}</button>
    </div>
</div>
