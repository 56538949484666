import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { StatusPageQuery } from 'src/app/shared/stores/status-page-store/status-page.query';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { OtherSettingsState, OwsOtherSettingsStore } from './ows-other-settings.store';

@Injectable({
    providedIn: 'root'
})
export class OwsOtherSettingsQuery extends StatusPageQuery<OtherSettingsState> {
    constructor(
        protected store: OwsOtherSettingsStore,
        protected activityTypeQuery: ActivityTypeQuery,
    ) {
        super(store);
    }

    public getCurrentDaymarkIdForLeaveShiftId(): Observable<number> {
        return this.select(state => state.selectedDaymarkIdForLeaveShift);
    }

    public getCurrentDaymarkIdForSickShiftId(): Observable<number> {
        return this.select(state => state.selectedDaymarkIdForSickShift);
    }

    public getLoadingStateForActivityTypeForShiftReplacedByLeave(): Observable<boolean> {
        return this.select(state => state.loadingStateForActivityTypeForShiftReplacedByLeave);
    }

    public getLoadingStateForActivityTypeForShiftReplacedBySick(): Observable<boolean> {
        return this.select(state => state.loadingStateForActivityTypeForShiftReplacedBySick);
    }

    public getMutableActivityTypesFilteredByDaymark(): Observable<Array<ActivityType>> {
        return this.activityTypeQuery.getActivityTypes().pipe(
            map((activityTypes) => {
                const filteredActivityTypes = activityTypes.filter(activityType => activityType.categoryId === 4);
                const mutableActivityTypes = filteredActivityTypes.map(actType => {
                    return { ...actType };
                });
    
                const emptyOption = { displayName: '', id: 0 } as ActivityType;

                mutableActivityTypes.unshift(emptyOption);
    
                return mutableActivityTypes;        
            })
        );
    }
}
  
