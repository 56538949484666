import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PermissionsTreeStateService {
    private expandedNodeIds: Array<number> = [];

    public setExpandedNodeIds(nodeIds: Array<number>): void {
        this.expandedNodeIds = nodeIds;
    }

    public getExpandedNodeIds(): Array<number> {
        return this.expandedNodeIds;
    }

    public clearExpandedNodeIds(): void {
        this.expandedNodeIds = [];
    }
}
