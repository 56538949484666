import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
    public OF_LABEL = 'of';
    private readonly subscription = new Subscription();

    constructor(
        private readonly translate: TranslateService
    ) {
        super();

        this.subscription.add(
            this.translate.onLangChange.subscribe(() => {
                this.getAndInitTranslations();
            })
        );
        this.getAndInitTranslations();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
            
        return `${startIndex + 1} - ${endIndex} ${
            this.OF_LABEL
        } ${length}`;
    };
    
    private getAndInitTranslations(): void {
        this.subscription.add(
            this.translate.get(
                [
                    'PAGINATOR.ITEMS_PER_PAGE',
                    'PAGINATOR.NEXT_PAGE',
                    'PAGINATOR.PREVIOUS_PAGE',
                    'PAGINATOR.OF_LABEL',
                ]
            ).subscribe(translation => {
                this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
                this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
                this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
                this.OF_LABEL = translation['PAGINATOR.OF_LABEL'];
                this.changes.next();
            })
        );
    }
}
