import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { OwsDepartment } from '../ows-department-store/ows-department.model';
import { DepartmentMappingStore } from './department-mapping.store';
import { DepartmentMapping } from './department-mapping.model';
import { DepartmentMappingQuery } from './department-mapping.query';

@Injectable({ providedIn: 'root' })
export class DepartmentMappingService {
    constructor(
        protected departmentMappingStore: DepartmentMappingStore,
        private readonly http: HttpClient,
        private readonly departmentMappingQuery: DepartmentMappingQuery
    ) { }

    public get() {
        return this.http.get<Array<DepartmentMapping>>('/api/OwsInterface/OwsDepartmentMapping').pipe(
            map((mappings) => {
                this.departmentMappingStore.set(mappings);
            }));
    }

    public updateMappings(owsDepartment: OwsDepartment) {
        let mappings = this.departmentMappingQuery.getAll();
        const organizationUnitId = this.departmentMappingQuery.getValue().ui.selectedOrganizationUnitId;
        const updatedMapping: DepartmentMapping = {
            organizationUnitId,
            owsDepartmentId: owsDepartment.id
        };

        if (owsDepartment.linkedOrganizationUnit !== null) {
            mappings = mappings.filter(mapping =>
                !(mapping.organizationUnitId === organizationUnitId && mapping.owsDepartmentId === owsDepartment.id)
            );
        }
        else if (!mappings.includes(updatedMapping)) {
            mappings.push(updatedMapping);
        }
        // TODO: set in server with timeout to collect calls
        this.http.post<void>('/api/OwsInterface/OwsDepartmentMapping', mappings).subscribe();
        this.departmentMappingStore.set(mappings);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.departmentMappingStore.updateSelectedOrganizationUnitId(id);
    }

    public updateHideUnlinkedOwsDepartments(value: boolean): void {
        this.departmentMappingStore.updateHideUnlinkedOwsDepartments(value);
    }
}
