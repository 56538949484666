import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CloneEntityService } from '../../services/clone-entity.service';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { UtilsService } from '../../services/utils.service';
import { getDefaultUIState } from '../entity-ui-models';
import { User } from '../user-store/user.model';
import { UserGroup } from './user-group.model';
import { UserGroupQuery } from './user-group.query';
import { UserGroupStore } from './user-group.store';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService {
    constructor(
        protected readonly userGroupStore: UserGroupStore,
        protected readonly userGroupQuery: UserGroupQuery,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
        private readonly translateService: TranslateService,
        private readonly cloneEntityService: CloneEntityService,
        private readonly utilsService: UtilsService,
    ) { }

    public getUserGroupNames(): Observable<Array<UserGroup>> {
        this.userGroupStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<UserGroup>>('/api/UserGroups/Names').pipe(
            catchError((error) => {
                this.userGroupStore.setError(error);

                return of([]);
            }),
            tap((userGroups) => {
                this.userGroupStore.set(userGroups);
                this.userGroupStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public getUserGroups(): Observable<Array<UserGroup>> {
        this.userGroupStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<UserGroup>>('/api/UserGroups').pipe(
            catchError((error) => {
                this.userGroupStore.setError(error);

                return of([]);
            }),
            tap((users) => {
                this.userGroupStore.set(users);
                this.userGroupStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public getUsersForUserGroup(userGroupId: number): Observable<Array<User>> {
        this.userGroupStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<User>>(`/api/UserGroups/${userGroupId}/Users`).pipe(
            catchError((error) => {
                this.userGroupStore.setError(error);

                return of([]);
            }),
            tap((users) => {
                const updatedUserGroup = this.userGroupQuery.getUserGroupSync(userGroupId);

                this.userGroupStore.upsert(userGroupId, { ...updatedUserGroup, selectedUserIds: users.map(user => user.id) });

                return users;
            })
        );
    }

    public deleteUserGroup(userGroupId: number): Observable<void> {
        this.userGroupStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/UserGroups/' + userGroupId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('user group') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.userGroupStore.updateEntitiesLoadingState(false);

                if (response === null) { // no error was thrown
                    this.userGroupStore.remove(userGroupId);
                }
            })
        );
    }

    public updateUserGroup(editedUserGroup: UserGroup): Observable<UserGroup> {
        const oldUserGroup = this.userGroupQuery.getUserGroupSync(editedUserGroup.id);
        // Note: API uses "userGroupPermissionListChanged" to determine if it needs to save the permission list
        editedUserGroup.userGroupPermissionListChanged = !this.utilsService.arraysEqual(oldUserGroup.userGroupPermissionList, editedUserGroup.userGroupPermissionList);
        editedUserGroup.selectedPermissionsChanged = !this.utilsService.arraysEqual(oldUserGroup.selectedPermissionIds, editedUserGroup.selectedPermissionIds);
        editedUserGroup.selectedReportUriIdsChanged = !this.utilsService.arraysEqual(oldUserGroup.selectedReportUriIds, editedUserGroup.selectedReportUriIds);

        return this.saveUserGroup(editedUserGroup);
    }

    public saveUserGroup(userGroup: UserGroup): Observable<UserGroup> {
        if (userGroup.id !== -1) {
            this.userGroupStore.ui.update(userGroup.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<UserGroup>('/api/UserGroups', userGroup).pipe(
            catchError((error) => {
                if (userGroup.id !== -1) {
                    this.userGroupStore.ui.update(userGroup.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING',
                    { 
                        entityName: this.translateService.instant('user group'),
                        entityDisplayName: userGroup.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);
                const lastCorrectVersionOfUserGroup = this.userGroupQuery.getUserGroupSync(userGroup.id);

                return throwError(() => lastCorrectVersionOfUserGroup);
            }),
            tap((updatedUserGroup: UserGroup) => {
                this.userGroupStore.upsert(updatedUserGroup.id, updatedUserGroup);
                if (userGroup.id !== -1) {
                    this.userGroupStore.ui.update(userGroup.id, { isLoading: false, isFinishedSaving: true });
                }

                return userGroup;
            }),
        );
    }

    public cloneUserGroup(userGroup: UserGroup, userGroupNames: Array<string>): Observable<UserGroup> {
        const clonedUserGroupName = this.cloneEntityService.getClonedEntityName(userGroup.displayName, userGroupNames);

        return this.http.post<UserGroup>('/api/UserGroups/Clone', {
            id: userGroup.id,
            displayName: clonedUserGroupName
        }).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.CLONING',
                    {
                        entityName: this.translateService.instant('user group'),
                        entityDisplayName: clonedUserGroupName
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => userGroup);
            }),
            tap((clonedUserGroup: UserGroup) => {
                this.userGroupStore.upsert(clonedUserGroup.id, clonedUserGroup);

                return clonedUserGroup;
            }),
        );
    }

    public setUserGroupToCleanUIState(userGroupId: number) {
        if (userGroupId !== undefined && userGroupId !== -1) {
            this.userGroupStore.ui.update(userGroupId, getDefaultUIState());
        }
    }

    public setUserGroupToPendingChanges(userGroupId: number) {
        this.userGroupStore.ui.update(userGroupId, { hasPendingChanges: true, isFinishedSaving: false });
    }
}
