<div class="filters-header">
    <div *ngIf="(filterSettings$ | async) && (filterSettings$ | async).length > 0" class="filters-field">
        <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
            <mat-label>{{ 'Filters' | translate }}</mat-label>
            <mat-select
                #filterSettingSelect
                required
                (openedChange)="openedFilterSettingSelect($event)"
                [formControl]="filterSettingControl">
                <mat-option
                    *ngFor="let filterSetting of filterSettings$ | async"
                    [value]="filterSetting.id">
                    {{ filterSetting.displayName }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="filterSettingControl.invalid" translate>Field is required</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="!(filterSettings$ | async).length" translate class="highlight-summary">
        No filter selected
    </div>

    <div *ngIf="selectedGroupingOptionType$ | async as selectedOption" class="grouping-option">
        <div class="highlight-summary">{{ 'Grouping option' | translate }}</div>&nbsp;&nbsp;&nbsp;{{ selectedOption | translate }}
    </div>
</div>
