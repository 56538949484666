import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BreadcrumbItem, BreadcrumbService } from './breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {
    @Input() public readonly rootUrl: string;
    public breadcrumbs$: Observable<Array<BreadcrumbItem>>;

    constructor(
        private readonly breadcrumbService: BreadcrumbService,
        private readonly router: Router
    ) { }

    public ngOnInit(): void {
        this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    }

    public onNavigate(route: string): void {
        if (route.startsWith('/omrp#')) {
            window.location.href = route;
        }
        else {
            this.router.navigate([route]);
        }
    }

    public onNavigateToRoot() {
        window.location.href = this.rootUrl;
    }
}
