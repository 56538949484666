import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, skip, tap} from 'rxjs/operators';
import { SlimDropdownItem } from '@ortec/soca-web-ui';
import { Memoized } from '@ortec/utilities/core';

import { Holiday } from 'src/app/shared/stores/holiday-store/holiday.model';
import { HolidayQuery } from 'src/app/shared/stores/holiday-store/holiday.query';
import { HolidayService } from 'src/app/shared/stores/holiday-store/holiday.service';
import { UpdatedHoliday } from './holiday-item/holiday-item.component';

@Component({
    selector: 'app-holidays',
    templateUrl: './holidays.component.html',
    styleUrls: ['./holidays.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HolidaysComponent implements OnInit {
    public currentYear: number = new Date().getFullYear();
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public selectedItem: SlimDropdownItem<number>;
    private itemsSubject = new BehaviorSubject<Array<SlimDropdownItem<number>>>([]);
  
    private readonly currentHolidaysSubject = new BehaviorSubject<Array<Holiday>>([]);

    constructor(
        private readonly holidayService: HolidayService,
        private readonly holidayQuery: HolidayQuery,
    ) {}

    public ngOnInit(): void {
        this.setYearsFilter();
       
        this.holidayService.getHolidaysForYear(this.currentYear).pipe(first()).subscribe();
   
        this.holidayQuery.getHolidaysForYear().pipe(
            map(holidayYear => this.currentHolidaysSubject.next(holidayYear?.holidays))
        ).subscribe();
    }

    public onDeleteHoliday(holidayId: number): void {
        const updatedHolidays = this.currentHolidaysSubject.value.filter(h => h.id !== holidayId);
        this.showLoadingSpinner$.next(true);
        this.holidayService.deleteHoliday(holidayId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
            }),
            first()
        ).subscribe(() => this.currentHolidaysSubject.next(updatedHolidays));
    }

    public onSelectedYear(item: SlimDropdownItem<number>): void {
        const year: number = +item.label;
        this.showLoadingSpinner$.next(true);
        this.holidayService.getHolidaysForYear(year).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
            }),
            first()
        ).subscribe();
    }

    public onUpdateHoliday(updatedHoliday: UpdatedHoliday): void {
        const holidays = this.currentHolidaysSubject.value;

        const index = holidays.findIndex(holiday => holiday.id === updatedHoliday.old.id);

        holidays[index] = { ...updatedHoliday.new };
        this.currentHolidaysSubject.next([...holidays]);
        this.showLoadingSpinner$.next(true);
        this.holidayService.saveHoliday(updatedHoliday.new).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
            }),
            first()
        ).subscribe();
    }

    public onAddHoliday(): void {
        const newHoliday: Holiday = {
            id: -1,
            text: 'New holiday',
            holidayDate: DateTime.utc(this.holidayQuery.getCurrentYearSync(),1,1).toString(),
        };
        this.showLoadingSpinner$.next(true);
        this.currentHolidaysSubject.next([...this.currentHolidaysSubject.value, newHoliday]);
        this.holidayService.saveHoliday(newHoliday).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
            }),
            first()
        ).subscribe();
    }

    @Memoized public get holidays$(): Observable<Array<Holiday>> {
        return this.currentHolidaysSubject.asObservable().pipe(
            map(( holidays) => holidays?.sort((a, b) => +new Date(a.holidayDate) - +new Date(b.holidayDate)))
        ); 
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return this.holidayQuery.allEntitiesLoaded();
    }

    @Memoized public get items$(): Observable<Array<SlimDropdownItem<number>>> {
        return this.itemsSubject.asObservable();
    }

    @Memoized public get showEmptyState$(): Observable<boolean> {
        return combineLatest([
            this.holidays$,
        ]).pipe(
            skip(1),
            map(([holidays]) => !(holidays?.length > 0)),
        );
    }

    private setYearsFilter(): void {
        const minYear = this.currentYear - 10;
        const maxYear = this.currentYear + 5;

        const years = Array.from({ length: maxYear - minYear + 1 }, (_, index) => minYear + index);

        const items = years.map((year, index) => ({ label: year.toString(), value: index + 1 }));
        const selectedItem = items.find(item => item.label === this.currentYear.toString());
        this.selectedItem = selectedItem;
        this.itemsSubject.next(items);
    }
}
