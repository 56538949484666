import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Configuration } from './configuration.model';
import { ConfigurationStore } from './configuration.store';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    constructor(
        protected userInfoStore: ConfigurationStore,
        private readonly http: HttpClient,
    ) { }

    public getConfiguration(): Observable<Configuration> {
        return this.http.get<Configuration>('/api/Configuration').pipe(
            tap((configuration) => {
                this.userInfoStore.setConfiguration(configuration);
            })
        );
    }
}
