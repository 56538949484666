import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { commit } from '../../operators/commit';
import { DateTimeUtilityService } from '../../services/date-time-utility.service';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { Daypart, GetDaypartsResponse } from './day-part.model';
import { DaypartStore } from './day-part.store';

@Injectable({
    providedIn: 'root'
})
export class DaypartService {
    private readonly apiDaypartsUrl = '/api/v1/Dayparts';

    constructor(
        protected daypartStore: DaypartStore,
        private readonly http: HttpClient,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
        private readonly errorDialogService: ErrorDialogService,
    ) { }

    public getDayparteNew(): Observable<GetDaypartsResponse> {
        this.daypartStore.updateEntitiesLoadingState(true);

        return this.http.get<GetDaypartsResponse>(this.apiDaypartsUrl).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                const transformedDayparts = this.getTransformedDayparts(response.dayparts);

                this.daypartStore.set(transformedDayparts);
            }),
            tap(() => this.daypartStore.updateEntitiesLoadingState(false))
        );
    }

    public updateDayparts(dayparts: Array<Daypart>): Observable<void> {
        return this.http.post<GetDaypartsResponse>(this.apiDaypartsUrl, dayparts).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                return throwError(() => error);
            }),
            map((response) => {
                const transformedDayparts = this.getTransformedDayparts(response.dayparts);

                this.daypartStore.set(transformedDayparts);
            }),
            commit()
        );
    }

    private getTransformedDayparts(dayparts: Array<Daypart>): Array<Daypart> {
        const sortedDayPartswithStartMinutes = dayparts
            // First, add the start in minutes
            .map(dp => ({
                ...dp,
                startInMinutes: this.dateTimeUtilityService.transformTimeInMinutes(dp.start),
            }))
            // Then, sort based on these start minutes
            .sort((a, b) => a.startInMinutes - b.startInMinutes);

        // With the new order, add the end minutes
        return sortedDayPartswithStartMinutes
            .map(dp => {
                const currentIndex = sortedDayPartswithStartMinutes.indexOf(dp);
                const nextIndex = (currentIndex + 1) % sortedDayPartswithStartMinutes.length;
                const nextDaypartStart = sortedDayPartswithStartMinutes[nextIndex];

                return ({
                    ...dp,
                    endInMinutes: this.dateTimeUtilityService.transformTimeInMinutes(nextDaypartStart.start)
                });
            });
    }
}
