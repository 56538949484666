<div class="filters__container">
    <div class="counter-type_interval-date">
        <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field" appearance="fill">
            <mat-label>{{ 'Start date' | translate }}</mat-label>
            <input #fromDateInput matInput [matDatepicker]="fromDatePicker" required
                [formControl]="dateTimeControls.fromDate">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker
                (closed)="fromDatePickerClosed()">
            </mat-datepicker>
            <mat-error *ngIf="dateTimeControls.fromDate.invalid" translate>
                <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                {{ getDateErrorMessage(fromDateInput) | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field" appearance="fill">
            <mat-label>{{ 'End date' | translate }}</mat-label>
            <input #tillDateInput matInput [matDatepicker]="tillDatePicker" required
                [min]="dateTimeControls.fromDate.value" [formControl]="dateTimeControls.tillDate">
            <mat-datepicker-toggle matSuffix [for]="tillDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #tillDatePicker>
            </mat-datepicker>
            <mat-error *ngIf="dateTimeControls.tillDate.invalid && !dateTimeControls.tillDate.errors?.matDatepickerMin" translate>
                <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                {{ getDateErrorMessage(tillDateInput) | translate }}
            </mat-error>
            <mat-error *ngIf="dateTimeControls.tillDate.errors?.matDatepickerMin">{{ 'The end date is before the start date' | translate }}</mat-error>
        </mat-form-field>

        <div *ngIf="!isExport" class="multiselect-tree-filters">
            <app-multiselect-tree
                class="default-mat-form-field"
                [entities]="countersFiltersOptions.counterTypes$ | async"
                [preSelectedEntities]="countersFiltersSelectedOptions.selectedCounterTypes$ | async"
                [showSelectAll]="true"
                [showSearch]="false"
                multiselectTreePlaceholder="Counter types"
                errorMessage="This field is required"
                data-cy="CountersCounterType"
                (selectedEntityIdsChanged)="onCounterFiltersChanged($event)">
            </app-multiselect-tree>
        </div>

        <div *ngIf="scenarioFilterVisibility && !isExport">
            <mat-form-field class="bol-form-field default-mat-form-field" appearance="fill">
                <mat-label>{{ 'Scenario' | translate }}</mat-label>
                <mat-select
                    required
                    [formControl]="scenarioControl"
                    panelClass="scenario-selector">
                    <div *ngIf="(scenarioItems$ | async).length === 0" class="selector-empty-item">{{ 'No values found' | translate }}</div>
                    <mat-option
                        *ngFor="let scenario of (scenarioItems$ | async)"
                        [value]="scenario.id"
                        [disabled]="!scenario.valid">
                        {{ scenario.displayName }}
                        {{ scenario.start | date: (dateFormat$ | async) }} - {{ scenario.end | date: (dateFormat$ | async) }} 
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="scenarioControl.invalid">{{ 'Field is required' | translate }}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="multiselect-tree-filters">
        <app-multiselect-tree
            class="organization-unit_input"
            [entities]="countersFiltersOptions.organizationUnits$ | async"
            [preSelectedEntities]="countersFiltersSelectedOptions.selectedOrganizationUnits$ | async"
            [showSelectAll]="true"
            multiselectTreePlaceholder="Organization Units"
            errorMessage="This field is required"
            data-cy="CountersOrganizationUnitsFilter"
            (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)">
        </app-multiselect-tree>

        <app-multiselect-tree
            class="default-mat-form-field"
            [entities]="countersFiltersOptions.resourceTypes$ | async"
            [preSelectedEntities]="countersFiltersSelectedOptions.selectedResourceTypeIds$ | async"
            [showSelectAll]="true"
            multiselectTreePlaceholder="Resource types"
            errorMessage="This field is required"
            data-cy="CountersResourceTypesFilter"
            (selectedEntityIdsChanged)="onFilteredResourceTypesChanged($event)">
        </app-multiselect-tree>

        <ng-container *ngIf="!isExport">
            <app-multiselect-tree
                class="default-mat-form-field"
                [entities]="countersFiltersOptions.skills$ | async"
                [preSelectedEntities]="countersFiltersSelectedOptions.selectedSkillIds$ | async"
                [required]="false"
                [showSelectAll]="true"
                multiselectTreePlaceholder="Resource skills"
                data-cy="CountersResourceSkillsFilter"
                (selectedEntityIdsChanged)="onFilteredResourceSkillsChanged($event)">
            </app-multiselect-tree>
        </ng-container>

        <ng-container *ngIf="isExport">
            <app-multiselect-tree
                class="default-mat-form-field"
                [entities]="countersFiltersOptions.extraResourceTypes$ | async"
                [preSelectedEntities]="countersFiltersSelectedOptions.selectedExtraResourceTypeIds$ | async"
                [required]="false"
                [showSelectAll]="true"
                multiselectTreePlaceholder="Extra resource types"
                data-cy="CountersExtraResourceTypesFilter"
                (selectedEntityIdsChanged)="onFilteredExtraResourceTypesChanged($event)">
            </app-multiselect-tree>
        </ng-container>
    </div>

    <div class="activity-type"> 
        <div class="activity-type-selection">
            <app-activity-type-selection
                [activityTypes]="countersFiltersOptions.activityTypes$ | async"
                [activityTypeCategories]="countersFiltersOptions.activityTypeCategories$ | async"
                [selectedActivityTypeIds]="countersFiltersSelectedOptions.selectedActivityTypeIds$ | async"
                [selectedActivityTypeCategoryIds]="countersFiltersSelectedOptions.selectedActivityTypeCategoryIds$ | async"
                [organizationUnits]="countersFiltersOptions.organizationUnits$ | async"
                [selectedOrganizationUnitsIds]="countersFiltersSelectedOptions.selectedOrganizationUnitsForActivities$ | async"
                [required]="true"
                (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
                (updateSelectedActivityTypeCategoryIdsEvent)="updateSelectedActivityTypeCategoryIds($event)"
                (updateSelectedActivitiesOrganizationUnitIdsEvent)="updateSelectedOrganizationUnitForActivityIds($event)"
            ></app-activity-type-selection>
        </div>

        <mat-checkbox
            *ngIf="isExport"
            class="bol-checkbox unplanned_leaves__checkbox"
            [checked]='countersFiltersOptions.exportUnplannedLeavesState$ | async'
            (change)="onUpdateEnabledExportUnplannedLeavesState($event)"
        >{{ 'Export unplanned leaves' | translate }}</mat-checkbox>

        
        <mat-checkbox
            *ngIf="!isExport"
            class="bol-checkbox"
            [checked]='countersFiltersOptions.enableActivityTypeDetailState$ | async'
            (change)="onUpdateEnabledActivityTypeDetailState($event)">
            <span>{{ 'Show counters for activity types' | translate }}</span>
        </mat-checkbox>
    </div>
    
    <div *ngIf="!isExport" class="grouping-option_total">
        <app-filter-chip-list
            class="grouping-options__container"
            [entities]="countersFiltersOptions.groupingOptions$ | async"
            [preselectedEntities]="countersFiltersSelectedOptions.selectedGroupingOptions$ | async"
            errorMessage="{{'Field is required' | translate}}"
            noResultsFoundText="{{'No grouping options found' | translate}}"
            placeholderText="{{'Grouping options...' | translate}}&nbsp;*"
            (selectedEntityIdsChanged)="onFilteredGroupingOptionsChanged($event)"
        ></app-filter-chip-list>

        <div
            class="total"
            [ngClass]="{'extended-container': (groupingOptionsLength$ | async) > 0}">
            <app-multiselect-tree
                class="default-mat-form-field"
                [entities]="countersFiltersOptions.totals$ | async"
                [preSelectedEntities]="countersFiltersSelectedOptions.selectedTotals$ | async"
                [showSelectAll]="true"
                multiselectTreePlaceholder="Totals"
                data-cy="CountersTotalOptionsFilter"
                (selectedEntityIdsChanged)="onFilteredTotalOptionsChanged($event)">
            </app-multiselect-tree>
        </div>
    </div>
</div>