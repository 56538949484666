<div class="counters_export__container">
    <div class="filter-setting-selector">
        <div *ngIf="(filterSettings$ | async) && (filterSettings$ | async).length > 0">
            <mat-form-field class="bol-form-field" appearance="fill">
                <mat-label>{{ 'Filters' | translate }}</mat-label>
                <mat-select
                    #filterSettingSelect
                    required
                    (openedChange)="openedFilterSettingSelect($event)"
                    [formControl]="filterSettingControl">
                    <mat-option
                        *ngFor="let filterSetting of filterSettings$ | async"
                        [value]="filterSetting.id">
                        {{ filterSetting.displayName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="filterSettingControl.invalid" translate>Field is required</mat-error>
            </mat-form-field>
        </div>
    
        <div *ngIf="!(filterSettings$ | async).length" translate class="highlight-summary">
            No filter selected
        </div>
    </div>

    <div class="placeholder__header"></div>
    <app-counters-filters filtersContent [isExport]="true" [countersFiltersOptions]="countersFiltersOptions"
        [countersFiltersSelectedOptions]="countersFiltersSelectedOptions"
        (organizationUnitIdsSelectedChanged)="onSelectedOrganizationUnitIdsChanged($event)"
        (startDateChanged)="onStartDateChanged($event)" (endDateChanged)="onEndDateChanged($event)"
        (activityTypeCategoryIdsSelectedChanged)="onSelectedActivityTypeCategoryIdsChanged($event)"
        (activityTypeIdsSelectedChanged)="onSelectedActivityTypeIdsChanged($event)"
        (organizationUnitForActivityIdsSelectedChanged)="onOrganizationUnitsForActivityIdsChanges($event)"
        (resourceTypeIdsSelectedChanged)="onSelectedResourceTypeIdsChanged($event)"
        (extraResourceTypeIdsSelectedChanged)="onSelectedExtraResourceTypeIdsChanged($event)"
        (enabledExportUnplannedLeavesStateChanged)="onEnabledExportUnplannedLeavesStateChanged($event)"
        (intervalDatesValidityStateChanged)="onIntervalDatesValidityStateChanged($event)">
    </app-counters-filters>
    <div class="counters_export__footer">
        <button mat-button class="bol-button-solid" (click)="exportReports()"
            [disabled]="!(isExportEnabled$ | async)" translate>{{ 'Export' | translate }}</button>
    </div>
    
    <div class="filter-settings">
        <app-counters-export-filters-settings></app-counters-export-filters-settings>
    </div>
</div>

<div class="overlay-spinner" *ngIf="(isLoadingExportFileSubject | async) || (isLoadingFilters$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
