<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column" >
            <app-entity-filter-panel>
                <app-skills-filters></app-skills-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                <app-entity-list-panel
                    *ngIf="initialLoadingFinished$ | async"
                    entityName="Skills"
                    [canAdd]="canAddNewEntity$ | async"
                    [entities]="filteredSkills$ | async"
                    [loadingError]="loadingError$ | async"
                    [mode]="manageMode$ | async"
                    [selectedEntityId]="selectedSkillId$ | async"
                    [searchProperties]="searchProperties"
                    (selectedEntityIdChanged)="onSelectedSkillChange($event)"
                    (addEntityClicked)="onAddEntityClicked()"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Skill"
                    canClone="true"
                    [mode]="manageMode$ | async"
                    [model]="selectedSkill$ | async"
                    [uiEntity]="selectedUISkill$ | async"
                    [formFields]="formFields"
                    [canDelete]="canDeleteSelectedEntity$ | async"
                    (cancelAdd)="onCancelAdd()"
                    (deleteEntity)="onDeleteSkill($event)"
                    (cloneEntity)="onCloneSkill($event)"
                    (addNewEntity)="onAddNewSkill($event)"
                    (editEntity)="onEditSkill($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false) || (showLoadingSpinner$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>
