import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { getDefaultUIState } from '../entity-ui-models';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { UserInfoQuery } from '../user-info-store/user-info.query';
import { ResourceProperty } from './resource-property.model';
import { ResourcePropertyStore } from './resource-property.store';
import { ResourcePropertyQuery } from './resource-property.query';

@Injectable({
    providedIn: 'root'
})
export class ResourcePropertyService {
    constructor(
        protected resourcePropertyStore: ResourcePropertyStore,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly errorDialogService: ErrorDialogService,
        private readonly userInfoQuery: UserInfoQuery,
        protected readonly resourcePropertyQuery: ResourcePropertyQuery,
    ) { }

    public getResourceProperties(languageCode: string) {
        this.resourcePropertyStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<ResourceProperty>>(`/api/ResourceProperties`).pipe(
            catchError((error) => {
                this.resourcePropertyStore.setError(error);

                return of([]);
            }),
            tap(resourceProperties => {
                const modifiedResourceProperties = resourceProperties.map(resourceProperty => this.setDisplayNameOnResourceProperty(resourceProperty, languageCode));
                
                this.resourcePropertyStore.set(modifiedResourceProperties);
                this.resourcePropertyStore.updateEntitiesLoadingState(false);
            }),
        );
    }

    public saveResourceProperty(resourceproperty: ResourceProperty): Observable<ResourceProperty> {
        const resourcePropertyNameByUserLanguage = this.setResourcePropertyByUserLanguage(resourceproperty);

        if (resourceproperty.id !== -1) {
            this.resourcePropertyStore.ui.update(resourceproperty.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<ResourceProperty>('/api/ResourceProperties', resourcePropertyNameByUserLanguage).pipe(
            catchError((error) => {
                if (resourceproperty.id !== -1) {
                    this.resourcePropertyStore.ui.update(resourceproperty.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING', 
                    { 
                        entityName: this.translateService.instant('resource property'),
                        entityDisplayName: resourceproperty.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);
                const lastCorrectVersionOfResourceProperty = this.resourcePropertyQuery.getResourcePropertySync(resourceproperty.id);

                return throwError(() => lastCorrectVersionOfResourceProperty);
            }),
            tap((updatedResourceProperty: ResourceProperty) => {
                const modifiedResourceProperty = this.setDisplayNameOnResourceProperty(updatedResourceProperty, this.userInfoQuery.getUserLanguageCodeSync());
              
                this.resourcePropertyStore.upsert(modifiedResourceProperty.id, modifiedResourceProperty);
                if (resourceproperty.id !== -1) {
                    this.resourcePropertyStore.ui.update(resourceproperty.id, { isLoading: false, isFinishedSaving: true });
                }

                return resourceproperty;
            }),
        );
    }

    public setResourcePropertyToCleanUIState(resourcepropertyId: number) {
        if (resourcepropertyId !== undefined && resourcepropertyId !== -1) {
            this.resourcePropertyStore.ui.update(resourcepropertyId, getDefaultUIState());
        }
    }
    
    public setResourcePropertyToPendingChanges(resourcepropertyId: number) {
        this.resourcePropertyStore.ui.update(resourcepropertyId, { hasPendingChanges: true, isFinishedSaving: false });
    }
    
    public deleteResourceProperty(resourcepropertyId: number): Observable<void> {
        this.resourcePropertyStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/ResourceProperties/' + resourcepropertyId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('resource property') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.resourcePropertyStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.resourcePropertyStore.remove(resourcepropertyId);
                }
            }),
        ); 
    }

    private setResourcePropertyByUserLanguage(resourceproperty: ResourceProperty) {
        const resourcePropertyNameByUserLanguage = resourceproperty.resourcePropertyNames.find(resourcePropertyName => resourcePropertyName.languageCode === this.userInfoQuery.getUserLanguageCodeSync());

        if (resourcePropertyNameByUserLanguage) {
            resourcePropertyNameByUserLanguage.text = resourceproperty.displayName;
        } else {
            resourceproperty.resourcePropertyNames.push({
                resourcePropertyId: -1,
                languageCode: this.userInfoQuery.getUserLanguageCodeSync(),
                text: resourceproperty.displayName
            });
        }

        return resourceproperty;
    }

    private setDisplayNameOnResourceProperty(resourceProperty: ResourceProperty, languageCode: string): ResourceProperty {
        const resourcePropertyNameByUserLanguage = resourceProperty.resourcePropertyNames.find(resourcePropertyName => resourcePropertyName.languageCode === languageCode);

        if (resourcePropertyNameByUserLanguage !== undefined) {
            resourceProperty.displayName = resourcePropertyNameByUserLanguage.text;
        } else {
            const availableResourcePropertyName = resourceProperty.resourcePropertyNames[0];
            resourceProperty.displayName = availableResourcePropertyName ? resourceProperty.resourcePropertyNames[0].text + ' (' + this.translateService.instant('translation needed') + ')' : '';
        }

        return resourceProperty;
    }
}
