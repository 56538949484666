/* eslint-disable complexity */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { globalSettings } from 'src/app/shared/models/global-settings-constants';
import * as moment from 'moment';

import { GlobalSetting } from './global-setting.dto';
import { OwsInterfaceScheduling } from './ows-interface-scheduling.model';
import { OwsInterfaceSchedulingQuery } from './ows-interface-scheduling.query';
import { OwsInterfaceSchedulingStore } from './ows-interface-scheduling.store';

@Injectable({
    providedIn: 'root'
})
export class OwsInterfaceSchedulingService {
    constructor(
        protected owsInterfaceSchedulingStore: OwsInterfaceSchedulingStore,
        private readonly http: HttpClient,
        private readonly owsInterfaceSchedulingQuery: OwsInterfaceSchedulingQuery
    ) { }

    public get() {
        const params = new HttpParams()
            .append('prefixName', 'ows');

        return this.http.get<Array<GlobalSetting>>('/api/GlobalSettings/GetGlobalSettingsByPrefix', { params }).pipe(
            map((settings) => {
                const parsedSchedulingProperties = this.parseSchedulingProperties(settings);
                this.owsInterfaceSchedulingStore.updateInterfaceConfiguration(parsedSchedulingProperties);

                this.updateLoadingState(true);
            }));
    }

    public onConfigurationChange(configurationName: string, value: any): void {
        let state = this.owsInterfaceSchedulingQuery.getInterfaceConfigurationSync();

        const configurationNameParts = configurationName.split('.');

        let valueToSave;

        if (value === true) {
            valueToSave = '1';
        }
        else if (value === false) {
            valueToSave = '0';
        }

        state = { ...state };

        if (configurationNameParts.length === 2) {
            if (configurationNameParts[1] === 'interval') {
                state[configurationNameParts[0]][configurationNameParts[1]] = this.parseTimeSpanToDuration(value);
            } else {
                state[configurationNameParts[0]][configurationNameParts[1]] = value;
            }
        } else {
            state[configurationName] = value;
        }

        // TODO: set in server with timeout to collect calls
        const setting: GlobalSetting = {
            settingName: 'ows.' + configurationName,
            settingValue: valueToSave ?? value
        };
        this.http.post<void>('/api/GlobalSettings/SetGlobalSetting', setting).pipe(first()).subscribe();
        this.owsInterfaceSchedulingStore.updateInterfaceConfiguration(state);
    }

    public updateLoadingState(loaded: boolean): void {
        this.owsInterfaceSchedulingStore.updateDataLoadedState(loaded);
    }

    public parseDurationToTimeSpan(minutes: number): string {
        const duration = moment.duration(minutes, 'minutes');
        let durationAsTimeSpanFormat = duration.days() > 0 ? `${duration.days()}.` : '';

        const durationHoursAndMinutes = `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}`;
        durationAsTimeSpanFormat += durationHoursAndMinutes;

        return durationAsTimeSpanFormat;
    }

    private parseTimeSpanToDuration(timespan: string): number {
        const duration = moment.duration(timespan);
        const durationInMinutes = duration.days() * 24 * 60 + duration.hours() * 60 + duration.minutes();

        return durationInMinutes;
    }

    private parseSchedulingProperties(settings: Array<GlobalSetting>): OwsInterfaceScheduling {
        return {
            miscSettings: {
                daysInPast: settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.daysInPast) ?
                    Number(settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.daysInPast).settingValue) : undefined,
                daysInFuture: settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.daysInFuture) ?
                    Number(settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.daysInFuture).settingValue) : undefined,
                weekend: settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.weekend) ?
                    settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.weekend).settingValue === '1' : false,
                deleteOld: settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.deleteOld) ?
                    settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.deleteOld).settingValue === '1' : false,
                daysOld: settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.daysOld) ?
                    Number(settings.find(setting => setting.settingName === globalSettings.ows.miscSettings.daysOld).settingValue) : undefined,
            },
            instanceName: settings.find(setting => setting.settingName === globalSettings.ows.instanceName) ?
                settings.find(setting => setting.settingName === globalSettings.ows.instanceName).settingValue : undefined
        };
    }
}
