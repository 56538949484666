<app-filter-tree-single-select 
    [entities]="organizationUnits$ | async"
    [labelText]="'Organization unit'"
    [selectedEntityId] = "selectedOrganizationUnit$ | async"
    [disabled]="(onlyShowWithoutUserGroups$ | async) || (showAllUsers$ | async)"
    (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
    data-cy="OrganizationUnitFilter">
</app-filter-tree-single-select>

<div>
    <mat-checkbox
        class="bol-checkbox"
        [checked]="showUnderlyingUnits$ | async"
        [disabled]="(onlyShowWithoutUserGroups$ | async) || (showAllUsers$ | async)"
        (change)="onUpdateShowChildUnits($event)"
        data-cy="ShowForUnderlyingOrganizationUnits"
    >{{ 'Also show users for underlying organization units' | translate }}</mat-checkbox>
</div>

<br/>

<div>
    <mat-checkbox
        class="bol-checkbox"
        [checked]="onlyShowWithoutUserGroups$ | async"
        [disabled]="showAllUsers$ | async"
        (change)="onUpdateShowWithoutUserGroups($event)"
        data-cy="ShowUsersWithoutUserGroup"
    >{{ 'Only show users without user groups' | translate }}</mat-checkbox>
</div>

<br/>

<div>
    <mat-checkbox
        class="bol-checkbox"
        [checked]="showAllUsers$ | async"
        (change)="onUpdateShowAllUsers($event)"
        data-cy="ShowAll"
    >{{ 'Show all users' | translate }}</mat-checkbox>
</div>