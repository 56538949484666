import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NestedTreeService } from '@ortec/soca-web-ui';

import { ActivityHelperService } from 'src/app/shared/services/activity-helper.service';

import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { STATUS_NOT_REQUIRED, ExtendedScheduleActivity } from '../../stores/schedule-store/schedule.model';
import { ScheduleCalendarHelperService } from '../../schedule-helpers/schedule-calendar-helper.service';
import { ScheduleDetailDialogComponent, ScheduleDetailDialogData } from '../schedule-detail-dialog/schedule-detail-dialog.component';

export const SCHEDULE_EVENT_TAG = 'app-schedule-event';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    selector: SCHEDULE_EVENT_TAG,
    templateUrl: './schedule-event.component.html',
    styleUrls: ['./schedule-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleEventComponent {
    @Input() public set id(eventId: string){
        // eslint-disable-next-line radix
        const parsedId = Number(eventId);
        if (!Number.isNaN(parsedId)) {
            this.eventId = parsedId;

            // wait until both are set before rendering
            if (this.resourceId !== undefined) {
                this.setData(parsedId);
            }
        }
    }

    @Input() public set resourceid(resourceId: string){
        // eslint-disable-next-line radix
        const parsedId = parseInt(resourceId);
        if (!Number.isNaN(parsedId)) {
            this.resourceId = parsedId;

            // wait until both are set before rendering
            if (this.eventId !== undefined) {
                this.setData(this.eventId);
            }
        }
    }

    public allLeaves: Array<ExtendedScheduleActivity> = [];
    public allLeavesAvailableAndRoot: Array<ExtendedScheduleActivity> = [];
    public activitiesWithMemoAndDescription: Array<ExtendedScheduleActivity> = [];
    public hideMainActivity: boolean;
    public hasMemo: boolean;
    public hasDescription: boolean;
    public statusNotRequired: number = STATUS_NOT_REQUIRED;

    private eventId: number;
    private resourceId: number;

    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly scheduleCalendarHelperService: ScheduleCalendarHelperService,
        private readonly activityHelperService : ActivityHelperService,
        private readonly dialogService: MatDialog,
        private readonly nestedTreeService: NestedTreeService,
    ) { }

    public onOpenDetailDialog(): void {
        this.dialogService.open(ScheduleDetailDialogComponent, {
            data: {
                eventId: this.eventId
            } as ScheduleDetailDialogData
        });
    }
    
    private setData(eventId: number): void {
        const showOptions = this.scheduleQuery.getShowOptionsSync();
        const showActivityName = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_NAME)?.state;
        const showActivityNameLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_NAME_LEAF)?.state;
        const showActivityShortName = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME)?.state;
        const showActivityShortNameLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_LEAF)?.state;
        const showActivityTime = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_TIME)?.state;
        const showActivityTimeLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_TIME_LEAF)?.state;
        const rootInsteadOfLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ROOT)?.state;

        // NOTE: this is already the root event and includes all the leaves!
        const activity = this.scheduleQuery.getScheduleActivitySync(eventId);
        
        if (activity?.children) {
            this.allLeavesAvailableAndRoot = this.nestedTreeService.flattenEntityTree(activity);
            this.hasMemo = this.allLeavesAvailableAndRoot[0]?.hasRecursedMemo || !!this.allLeavesAvailableAndRoot[0]?.memo;
            this.hasDescription = this.allLeavesAvailableAndRoot[0]?.hasRecursedDescription || !!this.allLeavesAvailableAndRoot[0]?.description;
            this.activitiesWithMemoAndDescription = this.allLeavesAvailableAndRoot.filter(act => !!act.memo || !!act.description);
            
            const extraResources = this.scheduleQuery.getExtraResourceTypeIdsSync();
 
            if (extraResources?.length > 0) {
                this.allLeaves = this.activityHelperService.sortActivities(this.allLeavesAvailableAndRoot);
                
                // Filter out leaves that don't match selected extraResources
                this.allLeaves = this.allLeaves.filter(a => extraResources.includes(a.resourceTypeId) || a.rootId === null);
            }
            else {
                // Show only the root
                if (rootInsteadOfLeaf) {
                    this.allLeaves.push(activity);
                }
                // Show only the leaf
                else {
                    const activityLeaf = this.allLeavesAvailableAndRoot.find(leaf => leaf.resourceId === this.resourceId);
                    this.allLeaves.push(activityLeaf);
                }
            }

            const hideResourceNameForLeaf = this.allLeaves.length === 1;

            // Get the proper name
            this.allLeaves = this.allLeaves.map(al => ({
                ...al,
                name: this.scheduleCalendarHelperService.getNameForActivity(
                    al, 
                    showActivityName, 
                    showActivityNameLeaf,
                    showActivityShortName, 
                    showActivityShortNameLeaf,
                    showActivityTime,
                    showActivityTimeLeaf,
                    al.rootId === null,
                    hideResourceNameForLeaf
                )
            }));
        }
        else {
            this.allLeaves = [{
                ...activity, 
                name: this.scheduleCalendarHelperService.getNameForActivity(
                    activity, 
                    showActivityName, 
                    showActivityNameLeaf,
                    showActivityShortName, 
                    showActivityShortNameLeaf,
                    showActivityTime,
                    showActivityTimeLeaf,
                    true
                )
            }];
            this.hasMemo = !!activity?.memo;
            this.hasDescription = !!activity?.description;
            if (this.hasMemo || this.hasDescription) {
                this.activitiesWithMemoAndDescription = this.allLeaves;
            }
        }
    }
}
