import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, skip, take, tap } from 'rxjs/operators';
import { LanguageService } from 'src/app/shared/language';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';
import { Scenario } from 'src/app/shared/stores/scenario-store/scenario.model';
import { TranslateService } from '@ngx-translate/core';

import { CountersFiltersService } from '../../counters-helpers/counters-filters.service';
import { GetCountersService } from '../../counters-helpers/get-counters.service';
import { COUNTERS_COUNTER_TYPE, COUNTERS_STORAGE_KEYS } from '../../counters-helpers/enums';
import { CountersQuery } from '../../stores/counters-store/counters.query';
import { GlobalSettingsQuery } from '../../../../../shared/stores/global-settings/global-settings.query';
import { adjustDateForTimezone } from '../../../../../shared/services/adjust-date-for-timezone';

@Component({
    selector: 'app-counters-filters-header',
    templateUrl: './counters-filters-header.component.html',
    styleUrls: ['./counters-filters-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountersFiltersHeaderComponent implements OnInit, OnDestroy {
    @ViewChild('filterSettingSelect') public filterSettingSelect!: MatSelect;
    @Output() public readonly setPanelState = new EventEmitter<boolean>();

    public dateFormat$!: Observable<string>;
    public selectedStartDate$!: Observable<string>;
    public selectedEndDate$!: Observable<string>;
    public selectedScenario$!: Observable<Scenario>;
    public scenarioValidity$!: Observable<boolean>;
    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public countersFiltersValidity$!: Observable<boolean>;
    public countersVisibleSubject = new BehaviorSubject<boolean>(false);
    public countersExplanation: string;

    public filterSettingControl = new UntypedFormControl('', [Validators.required]);

    private readonly subscription = new Subscription();

    constructor(
        private readonly translateService: TranslateService,
        private readonly countersQuery: CountersQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly languageService: LanguageService,
        private readonly countersFiltersService: CountersFiltersService,
        private readonly getCountersService: GetCountersService,
        private readonly globalSettingsQuery: GlobalSettingsQuery
    ) { }

    public ngOnInit(): void {
        this.dateFormat$ = this.languageService.getDateFormatByCurrentLanguage();
        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.COUNTER_OVERVIEW_FILTER_SETTING).pipe(
            map(filters => filters.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
        );
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();

        this.setCurrentFilterSetting();
        this.selectedStartDate$ = combineLatest([
            this.countersQuery.getSelectedStartDate(),
            this.globalSettingsQuery.getTimeZone()]
        ).pipe(
            map(([startDate, timeZone]) => {
                const adjustedDate = adjustDateForTimezone(new Date(startDate), timeZone, true);

                return adjustedDate.toString();
            })
        );
        this.selectedEndDate$ = combineLatest([
            this.countersQuery.getSelectedEndDate(),
            this.globalSettingsQuery.getTimeZone()]
        ).pipe(
            map(([endDate, timeZone]) => {
                const adjustedDate = adjustDateForTimezone(new Date(endDate), timeZone, true);

                return adjustedDate.toString();
            })
        );
        this.selectedScenario$ = this.countersQuery.getSelectedScenario();
        this.scenarioValidity$ = this.countersQuery.getScenarioValidity();
        this.countersFiltersValidity$ = this.countersQuery.getCountersFiltersValidity();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openedFilterSettingSelect(state: boolean): void {
        if (state) {
            return;
        }

        const id = this.filterSettingControl.value;
        this.filterSettingService.updateSelectedFilterSettingsId(id);

        if (id) {
            this.countersFiltersService.setCountersItemInStorage(COUNTERS_STORAGE_KEYS.FILTER_SETTING_ID, id);
        }
    }

    public setCurrentFilterSetting(): void {
        this.subscription.add(
            combineLatest([
                this.selectedFilterSettingId$,
                this.filterSettingQuery.getFilterSettings()
            ])
                .subscribe(([id, filterSettings]) => {
                    if (filterSettings && filterSettings.length > 0 && !id) {
                        const firstFilterId = this.filterSettingQuery.getFirstFilterSettingIdAvailableByType(FILTER_SETTING_TYPE.COUNTER_OVERVIEW_FILTER_SETTING);

                        this.filterSettingControl.setValue(firstFilterId, { emitEvent: false });
                        this.filterSettingService.updateSelectedFilterSettingsId(firstFilterId);
                    }

                    if (id) {
                        this.filterSettingControl.setValue(id, { emitEvent: false });
                    }
                })
        );
    }

    public onGetCounters(): void {
        this.countersVisibleSubject.next(true);
        this.getCountersService.getCounters();
        this.setPanelState.emit(false);
        this.setCountersExplanationText();
        this.countersQuery.getUIState()
            .pipe(
                skip(1),
                take(1),
                tap(() => this.countersVisibleSubject.next(false))
            ).subscribe();
    }

    private setCountersExplanationText(): void {
        const counterTypes = this.countersQuery.getCounterTypeOptionsSync();
        this.countersExplanation = this.translateService.instant('Counters: ');

        if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE)) {
            this.countersExplanation += this.translateService.instant('Number of activities (schedule)');
        }

        if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE) &&
            counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO)) {
            this.countersExplanation += ' - ';
        }

        if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO)) {
            this.countersExplanation += this.translateService.instant('Number of activities (scenario)');
        }

        if ((counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCHEDULE) ||
            counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITIES_SCENARIO)) &&
            (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE) ||
            counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO))) {
            this.countersExplanation += ' / ';
        }

        if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE)) {
            this.countersExplanation += this.translateService.instant('Number of activity hours (schedule)');
        }

        if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCHEDULE) &&
            counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO)) {
            this.countersExplanation += ' - ';
        }

        if (counterTypes.includes(COUNTERS_COUNTER_TYPE.NUMBER_ACTIVITY_HOURS_SCENARIO)) {
            this.countersExplanation += this.translateService.instant('Number of activity hours (scenario)');
        }
    }
}
