import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { OwsDutySet } from './ows-duty-set.model';

export interface OwsDutySetState extends EntityState<OwsDutySet> {
    ui: {
    };
}

const initialState = {
    ui: {
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-duty-set' })
export class OwsDutySetStore extends EntityStore<OwsDutySetState> {

    constructor() {
        super(initialState);
    }
}
