import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CloneEntityService } from '../../services/clone-entity.service';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { getDefaultUIState } from '../entity-ui-models';
import { Skill } from './skill.model';
import { SkillStore } from './skill.store';

@Injectable({
    providedIn: 'root'
})
export class SkillService {
    constructor(
        protected readonly skillStore: SkillStore,
        private readonly http: HttpClient,
        private readonly errorDialogService: ErrorDialogService,
        private readonly cloneEntityService: CloneEntityService,
        private readonly translateService: TranslateService,
    ) { }

    public getSkills() {
        this.skillStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<Skill>>('/api/Skills').pipe(
            catchError((error) => {
                this.skillStore.setError(error);

                return of([]);
            }),
            tap((skills) => {
                this.skillStore.set(skills);

                if (skills.length > 0) {
                    this.skillStore.selectSkill(skills[0].id);
                }
            }),
            tap(() => this.skillStore.updateEntitiesLoadingState(false))
        );
    }

    public deleteSkill(skillId: number): Observable<void> {
        this.skillStore.updateEntitiesLoadingState(true);

        return this.http.delete<void>('/api/Skills/' + skillId).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.DELETING', { entityName: this.translateService.instant('skill') });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => error);
            }),
            tap((response) => {
                this.skillStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.skillStore.remove(skillId);
                }
            }),
        ); 
    }
    
    public saveSkill(skill: Skill, lastCorrectVersionOfSkill: Skill): Observable<Skill> {
        if (skill.id !== -1) {
            this.skillStore.ui.update(skill.id, { isLoading: true, hasPendingChanges: false });
        }

        return this.http.put<Skill>('/api/Skills', skill).pipe(
            catchError((error) => {
                if (skill.id !== -1) {
                    this.skillStore.ui.update(skill.id, { isLoading: false });
                }
                const modalTitle = this.translateService.instant('ERROR.SAVING',
                    { 
                        entityName: this.translateService.instant('skill'),
                        entityDisplayName: skill.displayName 
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => lastCorrectVersionOfSkill);
            }),
            tap((updatedSkill: Skill) => {
                // Note: an object is returned from the "put" request only for skills saved with id -1 
                // for updating skills we will use the skill object that we stored client-side
                if (skill.id !== -1) {
                    this.skillStore.upsert(skill.id, skill); 
                    this.skillStore.ui.update(skill.id, { isLoading: false, isFinishedSaving: true });
                } else {
                    this.skillStore.upsert(updatedSkill.id, updatedSkill);
                }

                return skill;
            }),
        );
    }

    public cloneSkill(skill: Skill, skillNames: Array<string>): Observable<Skill> {
        const clonedSkillName = this.cloneEntityService.getClonedEntityName(skill.displayName, skillNames);

        return this.http.post<Skill>('/api/Skills/Clone', {
            id: skill.id,
            displayName: clonedSkillName
        }).pipe(
            catchError((error) => {
                const modalTitle = this.translateService.instant('ERROR.CLONING',
                    {
                        entityName: this.translateService.instant('skill'),
                        entityDisplayName: clonedSkillName
                    });
                this.errorDialogService.showErrorDialog(modalTitle, error.error.statusText);

                return throwError(() => skill);
            }),
            tap((clonedSkill: Skill) => {
                this.skillStore.upsert(clonedSkill.id, clonedSkill);

                return clonedSkill;
            }),
        );
    }

    public selectSkill(id: number) {
        this.skillStore.selectSkill(id);
    }

    public updateSelectedSkillIds(ids: Array<number>) {
        this.skillStore.updateSelectedSkillIds(ids);
    }

    public setSkillToPendingChanges(skillId: number) {
        this.skillStore.ui.update(skillId, { hasPendingChanges: true, isFinishedSaving: false });
    }

    public setSkillToCleanUIState(skillId: number) {
        if (skillId !== undefined && skillId !== -1) {
            this.skillStore.ui.update(skillId, getDefaultUIState());
        }
    }
}
