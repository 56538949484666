<div class="breadcrumbs__container">
  <div>
    <button
      mat-button
      class="bol-button-text breadcrumbs__button"
      (click)="onNavigateToRoot()"
    >
      <span translate>OMRP</span>
    </button>
  </div>

  <span class="breadcrumbs__divider">/</span>

  <span *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast">
    <ng-container *ngIf="!isLast">
      <button
        mat-button
        class="bol-button-text breadcrumbs__button"
        (click)="onNavigate(breadcrumb.route)"
      >
        <span translate>{{ breadcrumb.caption }}</span>
        <ng-container *ngIf="breadcrumb?.dynamicPart">
          {{ breadcrumb?.dynamicPart }}
        </ng-container>
      </button>

      <span class="breadcrumbs__divider">/</span>
    </ng-container>

    <button *ngIf="isLast"
      mat-button
      disabled
      class="bol-button-text breadcrumbs__button"
    >
      <span translate>{{ breadcrumb.caption }}</span>
      <ng-container *ngIf="breadcrumb?.dynamicPart">
        {{ breadcrumb?.dynamicPart }}
      </ng-container>
    </button>
    
  </span>
</div>
