import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { YES_NO_TYPES } from '../models/Enums';

const yesNoFilterObject = {
    true: YES_NO_TYPES.YES,
    false: YES_NO_TYPES.NO,
};
export interface YesNoFilterItems {
    id: string;
    displayName: string;
}

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    public deepCopy<T>(object: T): T {
        if (!object) {
            return undefined;
        }
        
        return JSON.parse(JSON.stringify(object));
    }

    public arrayToCommaSeparatedString(array: Array<number> | Array<string>): string {
        if (!array) {
            return '';
        }
        
        return array.join(',');
    }

    public getSortedObjectString(object: string): string {
        return object.split('').sort().join('');
    }

    public objectToHttpParam(object: any): HttpParams {
        let params = new HttpParams();
        const paramProperties = Object.keys(object);
        paramProperties.forEach((prop: any) => {
            const property = object[prop];
            if (property === undefined) {
                params = params.append(prop, '');
            }
            else if (Array.isArray(property) && property.length > 0 && (typeof property[0] === 'string' || typeof property[0] === 'number')) {
                params = params.append(prop, this.arrayToCommaSeparatedString(property));
            }
            else if (typeof property === 'string' || typeof property === 'boolean' || typeof property === 'number') {
                params = params.append(prop, property.toString());
            }
            else {
                params = params.append(prop, ''); // can be expanded when needed
            }
        });

        return params;
    }

    // eslint-disable-next-line complexity
    public objectsValuesEqual(obj1: any, obj2: any): boolean {
        if ((obj1 === null && obj2 === null) || (typeof obj1 !== 'object' && typeof obj2 !== 'object')) {
            return obj1 === obj2;
        }
        if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const value1 = obj1[key];
            const value2 = obj2[key];
        
            if (Array.isArray(value1) && Array.isArray(value2)) {
                if (!this.arraysEqual(value1, value2)) {
                    return false;
                }
            } 
            else if (typeof value1 === 'string' && typeof value2 === 'string' || 
                typeof value1 === 'number' && typeof value2 === 'number' ||
                typeof value1 === 'boolean' && typeof value2 === 'boolean') {
                if (value1 !== value2) {
                    return false;
                }
            } 
            else {
                if (!this.objectsValuesEqual(value1, value2)) {
                    return false;
                }
            }
        }
        
        return true;
    }

    public arraysEqual<T>(a: Array<T>, b: Array<T>): boolean {
        if (a === b) { return true; }
        if (a === null || b === null) { return false; }
        if (a === undefined || b === undefined) { return false; }
        if (a.length !== b.length) { return false; }

        const distinctItemsInFirstArray = a.filter((item) => !b.find((item2) => JSON.stringify(item) === JSON.stringify(item2)));
        const distinctItemsInSecondArray = b.filter((item) => !a.find((item2) => JSON.stringify(item) === JSON.stringify(item2)));

        if (distinctItemsInFirstArray.length === 0 && distinctItemsInSecondArray.length === 0) {
            return true;
        }

        return false;
    }

    public mergeArraysSkipDuplicates<T>(arr1: Array<T>, arr2: Array<T>): Array<T> {
        if (arr1?.length > 0 && arr2?.length > 0) {
            const mergedSet = new Set([...arr1, ...arr2]);
    
            return Array.from(mergedSet);
        }
        else if (arr1 === null || arr1 === undefined || arr1.length === 0) {
            return arr2;
        }
        else if (arr2 === null || arr2 === undefined || arr2.length === 0) {
            return arr1;
        }
        else {
            return [];
        }
    }

    public arraysHaveTheSameSortOrder(arr1: Array<any>, arr2: Array<any>): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }
    
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
    
        return true;
    }

    public mapToArray<T>(map: Map<number, T>): Array<T> {
        return map !== undefined ? Array.from(map.values()) : [];
    }

    public getYesNoSearchValues(): Array<YesNoFilterItems> {
        return Object.entries(yesNoFilterObject).map(([id, displayName]) => {
            return { id, displayName };
        });
    }
}
