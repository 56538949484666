<mat-card  class="bol-card full-height">
    <mat-card-header class="bol-card-header mat-card-header-padding">
        <mat-card-title class="bol-card-title entity-title" translate data-cy="entityListTitle">{{entityName}}</mat-card-title>
        <button 
            *ngIf="!hideAddButton"
            (click)="onAddEntityClicked()"
            mat-button 
            class="bol-button-solid bol-default"
            data-cy="addEntityButton">
            <span translate>Add</span>
        </button>
    </mat-card-header>

        <mat-card-content class="bol-card-content">
            <ng-container *ngIf="!loadingError">
                <table 
                    *ngIf="dataSource?.data?.length > 0 && dataSource?.filteredData?.length !== 0"
                    mat-table
                    [dataSource]="dataSource" 
                    class="bol-table bol-table-striped-even bol-table-row-hover">

                    <ng-container *ngFor="let column of columnDefinition" [matColumnDef]="column.entityProperty">
                        <th mat-header-cell *matHeaderCellDef>
                            <span translate>{{column.columnDisplayName}}</span>
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index;">
                            <mat-spinner 
                                *ngIf="row.isLoading && row.id !== selectedEntityId" 
                                class="row-loading-spinner" 
                                diameter="16">
                            </mat-spinner>

                            <div class="entity-name">{{ row.displayName }}</div>

                            <div class="sorting-buttons">
                                <button
                                    mat-button
                                    class="bol-button-text icon-only"
                                    (click)="onMoveEntityUp(row, i)">
                                    <mat-icon>arrow_upward</mat-icon> 
                                </button>
        
                                <button
                                    mat-button
                                    class="bol-button-text icon-only"
                                    (click)="onMoveEntityDown(row, i)">
                                    <mat-icon>arrow_downward</mat-icon> 
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr 
                        mat-row 
                        (click)="onEntitySelected(row.id)" 
                        [class.selected]="row.id === selectedEntityId"
                        *matRowDef="let row;
                        columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator 
                    [ngClass]="{'hidden': this.dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0}"
                    [pageSize]="10" 
                    [hidePageSize]="true" 
                    showFirstLastButtons 
                    aria-label="Select page">
                </mat-paginator>
            </ng-container>

            <ng-container *ngIf="!loadingError && (this.dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0)">
                <p data-cy="entityNoEntitiesFound">{{ "NO-ENTITIES-FOUND" | translate: { entityName: entityName | translate | lowercase } }}</p>
            </ng-container>

            <ng-container *ngIf="loadingError">
                <h4 translate data-cy="entityLoadingError">ERROR.LOADING-ENTITIES-ERROR</h4>
            </ng-container>

        </mat-card-content>
</mat-card>