import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, map } from 'rxjs';

import { ManageTemplatesManagementStore, TemplatesManagementState } from './manage-templates-management.store';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';

@Injectable({
    providedIn: 'root'
})
export class ManageTemplatesManagementQuery extends Query<TemplatesManagementState> {
    constructor(
        protected store: ManageTemplatesManagementStore,
        protected manageTemplateQuery: ManageTemplateQuery
    ) {
        super(store);
    }

    public getInitialLoadingStateForGrid(): Observable<boolean> {
        return this.select(value => value.initialLoadingStateForGrid);
    }

    public getLengthInWeeksForFiltering(): Observable<Array<any>> {
        return this.manageTemplateQuery.getLengthInWeeks().pipe(
            map(weekInWeeks => {
                const entities = weekInWeeks.map(weekLength => ({
                    id: weekLength, 
                    displayName: weekLength.toString(),
                }));
    
                return entities;
            })
        );
    }
}
