import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { STATUS_NOT_REQUIRED, ExtendedScheduleActivity } from '../../stores/schedule-store/schedule.model';
import { ScheduleCalendarHelperService } from '../../schedule-helpers/schedule-calendar-helper.service';
import { ScheduleDetailDialogComponent, ScheduleDetailDialogData } from '../schedule-detail-dialog/schedule-detail-dialog.component';

export const SCHEDULE_ACTIVITY_EVENT_TAG = 'app-schedule-activity-event';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    selector: SCHEDULE_ACTIVITY_EVENT_TAG,
    templateUrl: './schedule-activity-event.component.html',
    styleUrls: ['./schedule-activity-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleActivityEventComponent {
    @Input() public set id(eventId: string){
        // eslint-disable-next-line radix
        const parsedId = Number(eventId);
        if (!Number.isNaN(parsedId)) {
            this.eventId = parsedId;
            this.setData(parsedId);
        }
    }

    public allLeaves: Array<ExtendedScheduleActivity | { name: string }> = [];
    public allLeavesAvailableAndRoot: Array<ExtendedScheduleActivity> = [];
    public activitiesWithMemoAndDescription: Array<ExtendedScheduleActivity> = [];
    public hasMemo: boolean;
    public hasDescription: boolean;
    public statusNotRequired: number = STATUS_NOT_REQUIRED;

    private eventId: number;

    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly scheduleCalendarHelperService: ScheduleCalendarHelperService,
        private readonly dialogService: MatDialog
    ) { }

    public onOpenDetailDialog(): void {
        this.dialogService.open(ScheduleDetailDialogComponent, {
            data: {
                eventId: this.eventId
            } as ScheduleDetailDialogData
        });
    }

    private setData(eventId: number): void {
        const showOptions = this.scheduleQuery.getActivityShowOptionsSync();
        const showActivityTime = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_TIME)?.state;
        const showActivityTimeLeaf = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVITY_TIME_LEAF)?.state;
        
        let allLeaves = this.scheduleQuery.getFlattenedScheduleActivitySync(eventId);
        this.allLeavesAvailableAndRoot = allLeaves;
        this.hasMemo = allLeaves[0]?.hasRecursedMemo || !!allLeaves[0]?.memo;
        this.hasDescription = allLeaves[0]?.hasRecursedDescription || !!allLeaves[0]?.description;

        if (this.hasMemo || this.hasDescription) {
            this.activitiesWithMemoAndDescription = this.allLeavesAvailableAndRoot.filter(act => !!act.memo || !!act.description);
        }

        allLeaves = allLeaves
            .map(leaf => ({
                ...leaf,
                name: this.scheduleCalendarHelperService.getNameForResourceActivity(
                    leaf, 
                    showActivityTime,
                    showActivityTimeLeaf,
                    leaf?.rootId === null,
                    leaf?.rootId === null && leaf.parentId === null && (leaf.childrenRecursedResourceIds === null || leaf.childrenRecursedResourceIds.length === 0)
                )})
            );

        const parentLeaf = allLeaves.find(leaf => leaf?.rootId === null);

        if (parentLeaf) {
            // Only include selected leaves for processing
            let allLeavesOfActivityType = this.activityTypeQuery.getFullActivityStructureAndSortByRootIdSync(parentLeaf.activityTypeId);
            const allSelectedLeafIds = this.scheduleQuery.getAllSelectedActivityTypesFromParentId(parentLeaf.activityTypeId)?.map(l => l.id) ?? [];
            allLeavesOfActivityType = allLeavesOfActivityType.filter(leaf => allSelectedLeafIds.includes(leaf.id));
            
            for (const leaf of allLeavesOfActivityType) {
                const matchingLeaves: Array<ExtendedScheduleActivity> = allLeaves.filter(l => l.activityTypeId === leaf.id);

                // Only one matching leaf found
                if (matchingLeaves && matchingLeaves.length === 1) {
                    this.allLeaves.push(matchingLeaves[0]);
                }
                // Multiple matching leaves found, add number
                else if (matchingLeaves && matchingLeaves.length > 1) {
                    const firstLeaf = matchingLeaves[0];
                    firstLeaf.name = '(' + matchingLeaves.length + '): ' + firstLeaf.name;

                    this.allLeaves.push(firstLeaf);
                }
                // No matching leaves found (leaf is deleted)
                else {
                    this.allLeaves.push({
                        name: '-'
                    })
                }
            }
        }
    }
}
