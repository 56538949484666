import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BolNotificationService } from '@ortec/bolster/notification';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private readonly notificationService: BolNotificationService,
        private readonly translateService: TranslateService,
    ) { }

    public showEntityDisabledMessage(entityNameNonCapital: string) {
        this.notificationService.warning(
            this.translateService.instant('NOTIFICATION.ENTITY-DESELECTED', 
                { entityName: this.translateService.instant(entityNameNonCapital) }
            ),
            'warning',
            '',
            { 
                duration: 5000
            }
        );
    }
}
