import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ActivityTypeCategory } from './activity-type-category.model';
import { ActivityTypeCategoryStore } from './activity-type-category.store';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeCategoryService {
    private readonly apiActivityTypeCategoryUrl = '/api/ActivityTypes/Categories';

    constructor(
        protected activityTypeCategoryStore: ActivityTypeCategoryStore,
        private readonly http: HttpClient,
    ) { }

    public getActivityTypeCategories(): Observable<Array<ActivityTypeCategory>> {
        this.activityTypeCategoryStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<ActivityTypeCategory>>(this.apiActivityTypeCategoryUrl).pipe(
            tap((categories) => {
                this.activityTypeCategoryStore.set(categories);
            }),
            tap(() => this.activityTypeCategoryStore.updateEntitiesLoadingState(false))
        );
    }
}
