import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { Permission } from 'src/app/shared/models/Enums';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { SkillLevelQuery } from 'src/app/shared/stores/skill-level-store/skill-level.query';
import { SkillLevelService } from 'src/app/shared/stores/skill-level-store/skill-level.service';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { SkillService } from 'src/app/shared/stores/skill-store/skill.service';

import { getSkillFormFields, getSkillSearchProperties } from './skills-form-definition';
import { SkillsManagementQuery } from './store/skills-management.query';
import { SkillsManagementService } from './store/skills-management.service';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillsComponent implements OnInit, OnDestroy {

    public filteredSkills$: Observable<Array<Skill>>;
    public loadingError$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public selectedUISkill$: Observable<EntityUI>;
    public selectedSkillId$: Observable<number>;
    public selectedSkill$ = new BehaviorSubject<Skill>(undefined);
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public initialLoadingFinished$: Observable<boolean>;
    public canDeleteSelectedEntity$: Observable<boolean>;
    public canAddNewEntity$: Observable<boolean>;

    public formFields: Array<FormlyFieldConfig>;
    public searchProperties: Array<string>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly skillService: SkillService,
        private readonly skillQuery: SkillQuery,
        private readonly skillLevelQuery: SkillLevelQuery,
        private readonly skillsManagementQuery: SkillsManagementQuery,
        private readonly skillsManagementService: SkillsManagementService,
        private readonly skillLevelService: SkillLevelService,
        private readonly organizationQuery: OrganizationUnitQuery,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly autosaveService: AutosaveService,
        private readonly notificationService: NotificationService,
    ) { }

    public ngOnInit(): void {
        this.skillService.getSkills().pipe(first()).subscribe();
        this.resourceTypeService.getResourceTypes().pipe(first()).subscribe();
        this.skillLevelService.getSkillLevels().pipe(first()).subscribe();

        this.searchProperties = getSkillSearchProperties();

        this.subscription.add(
            combineLatest([
                this.organizationQuery.getOrganizationsWithMaxPermissionForFiltering(Permission.readWrite),
                this.skillsManagementQuery.getFilteredResourceTypesForSelectedEntity(),
                this.skillLevelQuery.getSkillLevels()
            ]).pipe(
                filter(([units, resourceTypes, skillLevels]) =>
                    units && resourceTypes && skillLevels &&
                        resourceTypes.length > 0 && units.length > 0),
                tap(([units, resourceTypes, skillLevels])  => {
                    const mutableSkillLevels = skillLevels.map(unit => {
                        return { ...unit };
                    });

                    const selectedOrgUnitIds = this.skillsManagementQuery.getManageModeSync() === MANAGE_MODE.ADD ? [this.skillsManagementQuery.getSelectedOrganizationUnitSync()] : undefined;
                    const disableSkillsOptions = { relatedOrgUnitIds: this.getRelatedOrganizationUnitIdsForSelectedSkill(this.skillsManagementQuery.getCurrentSkillIdSync()) };

                    this.formFields = getSkillFormFields(
                        units,
                        selectedOrgUnitIds,
                        resourceTypes,
                        mutableSkillLevels,
                        disableSkillsOptions,
                        this.disableSkills
                    );
                })
            ).subscribe()
        );

        this.loadingError$ = this.skillQuery.selectError();
        this.selectedUISkill$ = this.skillsManagementQuery.getSelectedUISkill();
        this.selectedSkillId$ = this.skillsManagementQuery.getCurrentSkillId();
        this.manageMode$ = this.skillsManagementQuery.getManageMode();
        this.filteredSkills$ = this.skillsManagementQuery.getFilteredSkills();
        this.initialLoadingFinished$ = this.skillsManagementQuery.allEntitiesLoaded();
        this.canDeleteSelectedEntity$ = this.skillsManagementQuery.getCanDeleteSelectedEntityState();

        this.subscription.add(
            this.skillsManagementQuery.getSelectedSkill().pipe(
                tap(skill => this.selectedSkill$.next(skill))
            ).subscribe()
        );

        this.canAddNewEntity$ = this.skillsManagementQuery.getSelectedOrganizationUnit().pipe(
            map(unitId => {
                return this.organizationQuery.getOrganizationUnitByIdSync(unitId)?.maxPermissionForCurrentUser > 1; 
            })
        );
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
    }

    public onSelectedSkillChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.skillService.setSkillToCleanUIState(id);
        const manageMode = id === undefined ? undefined : MANAGE_MODE.EDIT;
        this.skillsManagementService.updateManageMode(manageMode);
        this.skillsManagementService.updateSelectedSkillId(id);
    }

    public onAddEntityClicked(): void {
        this.autosaveService.saveUnsavedChanges();
        this.skillsManagementService.updateManageMode(MANAGE_MODE.ADD);
        this.skillsManagementService.updateSelectedSkillId(this.skillsManagementQuery.getNewSkillObject().id);
    }

    public onCancelAdd(): void {
        this.skillsManagementService.updateManageMode(undefined);
        this.skillsManagementService.updateSelectedSkillId(undefined);
    };

    public onDeleteSkill(skillId: number): void {
        this.showLoadingSpinner$.next(true);
        this.skillService.deleteSkill(skillId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.skillsManagementService.updateSelectedSkillId(undefined);
                this.skillsManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onCloneSkill(skillId: number): void {
        this.showLoadingSpinner$.next(true);

        const lastCorrectVersionOfSkill = this.skillQuery.getSkillSync(skillId);
        this.skillService.cloneSkill(lastCorrectVersionOfSkill, this.skillQuery.getSkillsSync().map(opt => opt.displayName)).pipe(
            tap((clonedSkill: Skill) => {
                this.showLoadingSpinner$.next(false);
                this.skillsManagementService.updateSelectedSkillId(clonedSkill.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    }

    public onAddNewSkill(skill: Skill): void {
        this.showLoadingSpinner$.next(true);

        const lastCorrectVersionOfSkill = this.skillQuery.getSkillSync(skill.id);
        this.skillService.saveSkill(skill, lastCorrectVersionOfSkill).pipe(
            tap((addedSkill: Skill) => {
                this.showLoadingSpinner$.next(false);
                this.skillsManagementService.setStateForNewSkill(addedSkill.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onEditSkill(skill: Skill): void {
        this.skillService.setSkillToPendingChanges(skill.id);
        this.autosaveService.autoSave(skill, this.saveSkill.bind(this));
    }

    private readonly disableSkills = (selectedIds: any, entities: Array<any>, disableOptions: any): Array<any> => {
        if (selectedIds === null) {
            selectedIds = [];
        };

        if (selectedIds.length > 0) {
            const organizationUnitId = entities.find(entity => entity.id === selectedIds[0])?.organizationUnitId;

            return entities.filter(entity => entity.organizationUnitId === organizationUnitId);
        } else {
            if (disableOptions.relatedOrgUnitIds.length > 0) {
                return entities.filter(entity => disableOptions.relatedOrgUnitIds.indexOf(entity.organizationUnitId) > -1);
            } else {
                return entities;
            }
        }
    };

    private getRelatedOrganizationUnitIdsForSelectedSkill(skillId: number): Array<number> {
        const selectedSkill = this.skillQuery.getSkillSync(skillId);
        if (selectedSkill === undefined) {
            return [];
        }

        const relatedOrgUnitIds = [...selectedSkill.validOrganizationUnitIds];

        selectedSkill.validOrganizationUnitIds.forEach(id => {
            const allChildUnitsForUnit = this.organizationQuery.getAllChildUnitsForUnitSync(id);
            relatedOrgUnitIds.push(...allChildUnitsForUnit?.map(unit => unit.id));
        });

        return relatedOrgUnitIds;
    }

    private saveSkill(skill: Skill): void {
        const lastCorrectVersionOfSkill = this.skillQuery.getSkillSync(skill.id);

        this.skillService.saveSkill(skill, lastCorrectVersionOfSkill).pipe(
            first(),
        ).subscribe({
            next: () => {
                const skillVisible = this.skillsManagementQuery.isSkillVisible(skill.id);
                if (skill.id === this.skillsManagementQuery.getCurrentSkillIdSync() && !skillVisible) {
                    this.notificationService.showEntityDisabledMessage('skills');
                }
            },
            error: this.handleSaveError.bind(this)
        });
    }

    private handleSaveError(skill: Skill): void {
        if (this.skillsManagementQuery.getCurrentSkillIdSync() === skill.id) {
            this.selectedSkill$.next({...skill});
        }
    }

    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }
}
