import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ActivityType, IActivityTypeTree } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ConfigurationQuery } from 'src/app/shared/stores/configuration-store/configuration.query';

export interface IActivityTypeSortingModalData {
    activityTypes$?: Observable<Array<ActivityType | IActivityTypeTree>>;
    selectedActivityTypeIds: Array<number>;
    sortedActivityTypeIds: Array<number>;
    dialogTitle: string;
    entityName: string;
}

export interface SortableActivityTypes {
    id: number;
    displayName: string;
    shortName: string;
    level?: number;
    checked?: boolean;
}

@Component({
    selector: 'app-activity-type-sorting-dialog',
    templateUrl: './activity-type-sorting-dialog.component.html',
    styleUrls: ['./activity-type-sorting-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeSortingDialogComponent implements OnInit, OnDestroy {
    public filteredSortableActivityTypes$: Observable<Array<SortableActivityTypes>>;
    public selectActivityTypeEventSubject = new BehaviorSubject<boolean>(false);
    public selectActivityTypeEvent$: Observable<boolean>;
    public selectedActivityTypeIdsSet = new Set<number>();
    public selectedActivityTypeIds = [];
    public sortedActivityTypeIds = [];
    public dialogTitle: string;
    public entityName: string;
    public activityTypes: Array<SortableActivityTypes>;
    public filteredSortableActivityTypesSubject = new BehaviorSubject<Array<SortableActivityTypes>>([]);

    public columnDefinition: Array<any> = [];

    public noRootSelectedMessage = "Root not selected. Only one or more leaves selected.";

    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<IActivityTypeSortingModalData>,
        public activityTypeQuery: ActivityTypeQuery,
        @Inject(MAT_DIALOG_DATA) dialogData: IActivityTypeSortingModalData,
        private readonly configurationQuery: ConfigurationQuery,
    ) {
        this.dialogTitle = dialogData.dialogTitle;
        this.filteredSortableActivityTypes$ = this.filteredSortableActivityTypesSubject.asObservable();
        this.selectedActivityTypeIds = dialogData.selectedActivityTypeIds;
        this.sortedActivityTypeIds = dialogData.sortedActivityTypeIds;
        this.entityName = dialogData.entityName;

        this.subscription.add(
            dialogData.activityTypes$.pipe(
                filter(activityTypes => activityTypes !== undefined && activityTypes.length > 0),
                tap(activityTypes => {
                    this.activityTypes = this.updateSortedActivityType(activityTypes as any);
                    this.filteredSortableActivityTypesSubject.next(this.activityTypes);
                }),
            ).subscribe()
        );
    }

    public ngOnInit(): void {
        this.columnDefinition.push( 
            {
                columnDisplayName: 'Short name',
                entityProperty: 'shortName',
                stylingProperty: 'nameStyling',
            },
            {
                columnDisplayName: 'Display name',
                entityProperty: 'displayName',
                showInfoIcon: 'showInfoIcon'
            }
        );
    }
    
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                sortedActivityTypeIds: this.sortedActivityTypeIds,
            } as IActivityTypeSortingModalData
        });
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onReorderdEntitiesChanged(newSortOfActTypeIds: Array<number>): void {
        this.sortedActivityTypeIds = newSortOfActTypeIds;
    }

    private updateSortedActivityType(activityTypes: Array<ActivityType>): Array<SortableActivityTypes> {
        const sortableActivityTypes: Array<any> = [];
    
        this.sortedActivityTypeIds.forEach((id) => {
            const activityType = activityTypes.find(actType => actType.id === id);
    
            if (activityType) {
                const showInfoIcon = !this.selectedActivityTypeIds.some(actId => actId === id);

                sortableActivityTypes.push({
                    ...activityType,
                    nameStyling: {
                        backgroundColor: '#' + activityType.backColor,
                        color: '#' + activityType.textColor,
                    },
                    showInfoIcon
                });
            }
        });
    
        return sortableActivityTypes;
    }
}
