import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { adjustMomentDateForTimezone } from '../../../../shared/services/adjust-date-for-timezone';
import { COUNTERS_TOTALS_OPTIONS } from './enums';

@Injectable({
    providedIn: 'root',
})
export class CountersColumnHelperService {

    public includeTotalCount(totals: Array<COUNTERS_TOTALS_OPTIONS>): boolean {
        return totals.includes(COUNTERS_TOTALS_OPTIONS.TOTAL);
    }

    public includeYearCount(totals: Array<COUNTERS_TOTALS_OPTIONS>): boolean {
        return totals.includes(COUNTERS_TOTALS_OPTIONS.YEAR);
    }

    public includeHalfYearCount(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        startMonth: number,
        endMonth: number,
        isFirstYear: boolean,
        isLastYear: boolean,
        halfYearIdx: number
    ): boolean {
        if (!totals.includes(COUNTERS_TOTALS_OPTIONS.HALF_YEAR)) {
            return false;
        }
        if (isFirstYear && halfYearIdx === 1) {
            return startMonth <= 6;
        }
        if (isLastYear && halfYearIdx === 2) {
            return endMonth > 6;
        }

        return true;
    }

    public includeQuarterCount(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        startMonth: number,
        endMonth: number,
        isFirstYear: boolean,
        isLastYear: boolean,
        currentQuarter: number
    ): boolean {
        if (!totals.includes(COUNTERS_TOTALS_OPTIONS.QUARTERS)) {
            return false;
        }
        if (isFirstYear && startMonth > (currentQuarter * 3)) {
            return false;
        }
        if (isLastYear && endMonth <= ((currentQuarter - 1) * 3)) {
            return false;
        }

        return true;
    }

    public includeMonthCount(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        startMonth: number,
        endMonth: number,
        isFirstYear: boolean,
        isLastYear: boolean,
        currentMonth: number
    ): boolean {
        if (!totals.includes(COUNTERS_TOTALS_OPTIONS.MONTH)) {
            return false;
        }
        if (isFirstYear && startMonth > currentMonth) {
            return false;
        }
        if (isLastYear && endMonth < currentMonth) {
            return false;
        }

        return true;
    }

    public includeWeekCount(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        startMonth: number,
        endMonth: number,
        isFirstYear: boolean,
        isLastYear: boolean,
        currentMonth: number
    ): boolean {
        if (!totals.includes(COUNTERS_TOTALS_OPTIONS.WEEK)) {
            return false;
        }
        if (isFirstYear && startMonth > currentMonth) {
            return false;
        }
        if (isLastYear && endMonth < currentMonth) {
            return false;
        }

        return true;
    }

    public includeDayForWeekCount(totals: Array<COUNTERS_TOTALS_OPTIONS>): boolean {
        return totals.includes(COUNTERS_TOTALS_OPTIONS.DAY);
    }

    public includeDayForMonthCount(totals: Array<COUNTERS_TOTALS_OPTIONS>): boolean {
        return !totals.includes(COUNTERS_TOTALS_OPTIONS.WEEK) && totals.includes(COUNTERS_TOTALS_OPTIONS.DAY);
    }

    public includeWeek(
        totals: Array<COUNTERS_TOTALS_OPTIONS>,
        addedWeeks: Array<string>,
        currentWeek: string
    ): boolean {
        return totals.includes(COUNTERS_TOTALS_OPTIONS.WEEK) && !addedWeeks.includes(currentWeek);
    }

    public getYears(start: moment.Moment, end: moment.Moment): Array<number> {
        const years = [];
        const startYear = start.year();
        const endYear = end.year();

        years.push(startYear);
        if (startYear !== endYear) {
            const yearsToAdd = endYear - startYear;
            for (let i = 1; i < yearsToAdd + 1; i++) {
                years.push(startYear + i);
            }
        }

        return years;
    }

    public getWeeksForMonth(
        month: number,
        year: number,
        startDate: moment.Moment,
        endDate: moment.Moment,
        timeZone: string
    ): Array<string> {
        const weeks = [];
        const firstOfMonth = adjustMomentDateForTimezone(moment(1 + '-' + month + '-' + year, 'DDMMYYYY'), timeZone, false);
        let startWeekNumber = firstOfMonth.isoWeek();
        const endOfMonth = adjustMomentDateForTimezone(moment(1 + '-' + month + '-' + year, 'DDMMYYYY'), timeZone, false).endOf('month');
        const endWeekNumber = endOfMonth.isoWeek();
        let weeksToAdd;

        // NOTE there is some weird behaviour in moment.js, where "moment().tz(timeZone).year(year).isoWeek(currentWeek)" 
        // on a current date of 3-1-2022, with as params "year: 2021" and "currentWeek: 1" would set the date back in 2019.
        // Because of this, we start out with a mocked date of 1-6 to negate this issue. 

        // this is in case where 1-1 is in week 52 or 53
        if (startWeekNumber > 51 && month === 1) { 
            const startOfWeek = moment('1-6-' + (year - 1) , 'DDMMYYYY').tz(timeZone).isoWeek(startWeekNumber).startOf('isoWeek');
            const endOfWeek = moment('1-6-' + (year - 1) , 'DDMMYYYY').tz(timeZone).isoWeek(startWeekNumber).endOf('isoWeek');

            if (this.includeWeekInWeeks(startOfWeek, endOfWeek, startDate, endDate)) {
                weeks.push(year - 1 + '-' + startWeekNumber);
            }

            startWeekNumber = 1;
            weeksToAdd = endWeekNumber;
        }
        // when last day of december is in the new year, only happens when 1st dec is in the weekend, december will span 6 weeks
        else if (endWeekNumber === 1 && month === 12) { 
            weeksToAdd = 6;
        }
        else {
            weeksToAdd = endWeekNumber - startWeekNumber + 1;
        }

        for (let i = 0; i < weeksToAdd; i++) {
            const currentWeekNumber = startWeekNumber + i;
            const startOfWeek = moment('1-6-' + year, 'DDMMYYYY').tz(timeZone).isoWeek(currentWeekNumber).startOf('isoWeek');
            const endOfWeek = moment('1-6-' + year, 'DDMMYYYY').tz(timeZone).isoWeek(currentWeekNumber).endOf('isoWeek');

            if (this.includeWeekInWeeks(startOfWeek, endOfWeek, startDate, endDate)) {
                // when last day of year is in week 1 of next year
                if (endWeekNumber === 1 && month === 12 && i === 5) {
                    // only include this week if it is also the end date of the filter
                    // if not, it will be added for januari
                    if (endDate.year() === year) {
                        weeks.push(year + 1 + '-1');
                    }
                }
                else {
                    weeks.push(year + '-' + currentWeekNumber);
                }
            }
        }

        return weeks;
    }

    public getDaysForMonth(
        month: number,
        year: number,
        isFirstYear: boolean,
        isLastYear: boolean,
        startDate: moment.Moment,
        endDate: moment.Moment,
        timeZone: string
    ): Array<moment.Moment> {
        const daysInMonth = moment(1 + '-' + month + '-' + year, 'DDMMYYYY').daysInMonth();
        const days: Array<moment.Moment> = [];

        for (let d = 0; d < daysInMonth; d++) {
            const date = moment(1 + '-' + month + '-' + year, 'DDMMYYYY').add(d, 'd');
            const adjustedDate = adjustMomentDateForTimezone(date, timeZone, false);

            if (!(isFirstYear && adjustedDate.isBefore(startDate)) &&
                !(isLastYear && adjustedDate.isAfter(endDate))) {
                days.push(adjustedDate);
            }
        }
    
        return days;
    }

    public getDaysForWeek(
        week: number,
        month: number,
        year: number,
        isFirstYear: boolean,
        isLastYear: boolean,
        startDate: moment.Moment,
        endDate: moment.Moment,
        timeZone: string
    ): Array<moment.Moment> {
        const days: Array<moment.Moment> = [];
        for (let d = 0; d < 7; d++) {
            const yearBasedOnWeekNumber = month === 1 && week > 50 ? year - 1 : year;
            const date = moment('1-6-' + yearBasedOnWeekNumber, 'DDMMYYYY').isoWeek(week).startOf('isoWeek').add(d, 'd');
            const adjustedDate = adjustMomentDateForTimezone(date, timeZone, false);

            if (adjustedDate.month() === month - 1 &&
                !(isFirstYear && adjustedDate.isBefore(startDate)) &&
                !(isLastYear && adjustedDate.isAfter(endDate))) {
                days.push(adjustedDate);
            }
        }

        return days;
    }

    private includeWeekInWeeks(
        startOfWeek: moment.Moment,
        endOfWeek: moment.Moment,
        startDate: moment.Moment,
        endDate: moment.Moment) {
        return startOfWeek.isBefore(endDate) && endOfWeek.isAfter(startDate);
    }
}
