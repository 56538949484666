import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserInfoQuery } from '../../stores/user-info-store/user-info.query';

@Component({
    selector: 'app-no-permissions',
    templateUrl: './no-permissions.component.html',
    styleUrls: ['./no-permissions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoPermissionsComponent implements OnInit {
    public isFullUser: boolean;

    constructor(
        private readonly router: Router,
        private readonly userInfoQuery: UserInfoQuery
    ) {}

    public ngOnInit(): void {
        this.isFullUser = this.userInfoQuery.getIsFullUserSync();
    }

    public onRedirectToMainPage(): void {
        if (this.isFullUser) {
            window.location.href = window.location.protocol + '//' + window.location.host;
        } else {
            this.router.navigate(['/reports']);
        }
    }
}
