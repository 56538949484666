import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Daypart } from './day-part.model';
import { DaypartState, DaypartStore } from './day-part.store';

@Injectable({
    providedIn: 'root'
})
export class DaypartQuery extends QueryEntity<DaypartState> {

    constructor(
        protected store: DaypartStore
    ) {
        super(store);
    }

    public getDaypartsSync(): Array<Daypart> {
        return Object.values(this.getValue().entities);
    }

    public getDaypartsOrderByStartTime(): Array<Daypart> {
        const dayparts = this.getDaypartsSync();

        return dayparts.sort((a, b) => a.startInMinutes - b.startInMinutes);
    }

    public getDaypartsWithDisplayName(): Observable<Array<Daypart>> {
        return this.selectAll().pipe(
            map((dayparts) => dayparts
                .sort((a, b) => a.startInMinutes - b.startInMinutes)
                .map((daypart) => ({
                    ... daypart,
                    displayName: daypart.name
                })))
        );   
    }

    public getDayPartsForFilteringWithEmptyOption(): Observable<Array<Daypart>> {
        return this.getDaypartsWithDisplayName().pipe(
            map((dayparts) => {
                // Add an empty Daypart object at the beginning of the array
                const daypartsWithEmpty = [{ displayName: '', id: null, name: '', start: '' }, ...dayparts];

                return daypartsWithEmpty;
            })
        );
    }

    public getDayparts(): Observable<Array<Daypart>> {
        return this.selectAll();
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getDaypartsWithEndSync(): Array<Daypart> {
        const dayparts = this.getDaypartsOrderByStartTime();

        return dayparts.map((daypart) => {
            // Parse the start time string into hours and minutes
            const [startHours, startMinutes] = daypart.start.split(':').map(Number);
            const duration = daypart.endInMinutes-daypart.startInMinutes;

            // Calculate the end time in minutes
            const endMinutes = (startHours * 60 + startMinutes + duration) % 1440;

            // Format the end time string in the desired format (e.g. "HH:mm")
            const endHours = Math.floor(endMinutes / 60).toString().padStart(2, '0');
            const endMinutesString = (endMinutes % 60).toString().padStart(2, '0');
            const end = `${endHours}:${endMinutesString}`;

            return { ...daypart, end };
        });
    }

    public isActivityInDaypartInterval(starttime: Date, endtime: Date, dayPart: Daypart): boolean {
        const activitydurationinmin = (endtime.getTime() - starttime.getTime()) / (60 * 1000);
        if (activitydurationinmin >= 24 * 60) {
            return true;
        }
        const daypartStartMinutes = dayPart.startInMinutes;
        const daypartEndMinutes = dayPart.endInMinutes;

        let startTimeMinutes = starttime.getHours() * 60 + starttime.getMinutes();
        const endTimeMinutes = startTimeMinutes + activitydurationinmin;

        let starttimetest = startTimeMinutes;
        while (startTimeMinutes < endTimeMinutes) {
            const endtimetest = endTimeMinutes < 24 * 60 ? endTimeMinutes : 24 * 60;

            if (daypartEndMinutes <= daypartStartMinutes) {
                if (starttimetest < daypartEndMinutes || endtimetest > daypartStartMinutes) {
                    return true;
                }
            } else if (starttimetest < daypartEndMinutes && endtimetest > daypartStartMinutes) {
                return true;
            }

            startTimeMinutes += 24 * 60;
            starttimetest = 0;
        }

        return false;
    }
}
