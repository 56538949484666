import { Injectable } from '@angular/core';
import { HashMap, QueryEntity } from '@datorama/akita';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { cache } from 'src/app/core/rxjs-utils/cache.operator';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourcePropertyLanguage } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.model';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { GROUPING_OPTIONS, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';
import {
    Schedule,
    ScheduleActivity,
    ScheduleFilterSettings,
    ScheduleResource,
    ScheduleResourceDialog,
    ScheduleShowOption,
} from './schedule.model';
import { ScheduleState, ScheduleStore } from './schedule.store';

@Injectable({
    providedIn: 'root'
})
export class ScheduleQuery extends QueryEntity<ScheduleState> {
    constructor(
        protected store: ScheduleStore,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly utilsService: UtilsService,
        private readonly daypartQuery: DaypartQuery,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly resourceTypeQuery: ResourceTypeQuery,
    ) {
        super(store);
    }

    public getSchedule(): Observable<Array<Schedule>> {
        return this.selectAll();
    }

    public getScheduleStartDateSync(): string {
        const schedule: HashMap<Schedule> = this.getValue().entities;
        const date = Object.values(schedule)[0].startDate;

        return moment.utc(date, 'MM/DD/YYYY HH:mm:ss').toDate().toISOString();
    }

    public getRequestParameters(): Observable<ScheduleRequestParameters> {
        return this.select(state => state.ui.requestParameters);
    }

    public getRequestParametersSync(): ScheduleRequestParameters {
        return this.getValue().ui.requestParameters;
    }

    public getScheduleFiltersValiditySync(): boolean {
        const ui = this.getValue().ui;

        return ui.requestParameters.organizationUnitIds && ui.requestParameters.organizationUnitIds.length > 0 &&
            ui.requestParameters.baseResourceTypeIds && ui.requestParameters.baseResourceTypeIds.length > 0 &&
            ui.requestParameters.resourcePropertyIds && ui.requestParameters.resourcePropertyIds.length > 0 &&
            ui.activityTypeIds && ui.activityTypeIds.length > 0;
    }

    public getShowOptions(): Observable<Array<ScheduleShowOption>> {
        return this.select(state => state.ui.showOptions).pipe(
            map(showOptions => this.getValidShowOptions(showOptions))
        );
    }

    public getShowOptionsSync(): Array<ScheduleShowOption> {
        return this.getValidShowOptions(this.getValue().ui.showOptions);
    }

    public getRequestParametersStartDateSync(): string {
        return this.getValue().ui.requestParameters.startDate;
    }

    public getGroupingOptionType(): Observable<GROUPING_OPTIONS> {
        return this.select(state => state.ui.groupingOptionType);
    }

    public getGroupingOptionTypeSync(): GROUPING_OPTIONS {
        return this.getValue().ui.groupingOptionType;
    }

    public getSelectedActivityTypeCategoryIdsSync(): Array<number> | undefined {
        return this.getValue().ui.activityTypeCategoryIds;
    }

    public getSelectedActivityTypeCategoryIds(): Observable<Array<number>> {
        return this.select(state => state.ui.activityTypeCategoryIds);
    }

    public getSelectedActivityTypeIds(): Observable<Array<number>> {
        return this.select(state => state.ui.activityTypeIds);
    }

    public getNumberOfDays(): Observable<number> {
        return this.select(state => state.ui.numberOfDays);
    }

    public getSlotMinWidthSync(): number {
        return this.getValue().calendarOptions.slotMinWidth;
    }

    public getSlotMinWidth(): Observable<number> {
        return this.select(state => state.calendarOptions.slotMinWidth);
    }

    public getResourceAreaWidthSync(): number {
        return this.getValue().calendarOptions.resourceAreaWidth;
    }

    public getResourceAreaWidth(): Observable<number> {
        return this.select(state => state.calendarOptions.resourceAreaWidth);
    }

    public getScheduleStartDate(): Observable<Date> {
        return this.select(state => state.ui.scheduleDateNavigation.scheduleStartDate);
    }

    public getScheduleEndDate(): Observable<Date> {
        return this.select(state => state.ui.scheduleDateNavigation.scheduleEndDate);
    }

    public getScheduleLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.loadingState);
    }

    public getScheduleLoadingStateSync(): boolean {
        return  this.getValue().ui.loadingState;
    }

    public getScheduleFiltersLoadingState(): Observable<boolean> {
        return combineLatest([
            this.filterSettingQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.resourcePropertyLanguageQuery.getEntitiesLoadingState(),
            this.daypartQuery.getEntitiesLoadingState(),
        ]).pipe(
            map(([filterSettings, units, resourceTypes, resourceProperties, dayparts]) =>
                filterSettings || units || resourceTypes || resourceProperties || dayparts
            )
        );
    }

    public getScheduleNoOfDaysToNavigateSync(): number {
        return this.getValue().ui.scheduleDateNavigation.noOfDaysToNavigate;
    }

    public getScheduleStartDateNavigationSync(): Date {
        return this.getValue().ui.scheduleDateNavigation.scheduleStartDate;
    }

    public getScheduleEndDateNavigationSync(): Date {
        return this.getValue().ui.scheduleDateNavigation.scheduleEndDate;
    }

    public getResourcesDialog(): Observable<ScheduleResourceDialog> {
        return this.select(state => state.ui.resourcesDialog);
    }

    public getResourcesDialogSync(): ScheduleResourceDialog {
        return this.getValue().ui.resourcesDialog;
    }

    public getSelectedActivityId(): Observable<number> {
        return this.select(state => state.ui.selectedActivityId);
    }

    public getSelectedActivityIdSync(): number {
        return this.getValue().ui.selectedActivityId;
    }

    public getActivityDialogState(): Observable<boolean> {
        return this.select(state => state.calendarOptions.activityDialogState);
    }

    public getUIState(): Observable<any> {
        return this.select(state => state.ui);
    }

    public getActivityDialogStateSync(): boolean {
        return this.getValue().calendarOptions.activityDialogState;
    }

    public getSelectedFilterSettingId(): Observable<number> {
        return this.select(state => state.ui.selectedFilterSettingId);
    }

    public getSelectedOrganizationUnitIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestParameters.organizationUnitIds);
    }

    public getSelectedOrganizationUnitIdsSync(): Array<number> {
        return this.getValue().ui.requestParameters.organizationUnitIds;
    }

    public getScheduleLoadingStateActivityDetailsDialogSync(): boolean {
        return  this.getValue().ui.loadingStateActivityDetailsDialog;
    }

    public getScheduleLoadingStateActivityDetailsDialog(): Observable<boolean> {
        return  this.select(state => state.ui.loadingStateActivityDetailsDialog);
    }

    public getFullInformationForSelectedActivity(): Observable<ScheduleResourceDialog> {
        return combineLatest([this.getResourcesDialog(),
            this.getScheduleLoadingStateActivityDetailsDialog()
        ]).pipe(
            filter(([resourceDialog, state]) => !!resourceDialog?.transformedActivitiesForDialog && !!resourceDialog && state),
            map(([resourceDialog, _]) => {
                return {
                    transformedActivitiesForDialog: resourceDialog.transformedActivitiesForDialog
                };
            })
        );
    }

    public getFilteredScheduleResources(): Observable<Array<ScheduleResource>> {
        return combineLatest([
            this.getSchedule(),
            this.getGroupingOptionType(),
            this.getActivityTypeSelectedIds(),
            this.getNumberOfDays(),
            this.getActivityDialogState(),
            this.getShowOptions(), // we need show options changes to emit in order to trigger a new render action for FullCalendar
        ]).pipe(
            map(([schedules, groupingOptionType, activityTypeSelectedIds, _]) => {
                if (schedules.length <= 0) {
                    return null;
                }

                const scheduleDays = this.getAvailableScheduleDays();
                const resourcesWithDefaultProperties = this.scheduleHelperService.getResourcesWithDefaultProperties(
                    schedules[0].reportScheduleResources
                );

                const filteredResources: Array<ScheduleResource> = resourcesWithDefaultProperties.filter(resource =>
                    this.getFilteredBaseResourceTypesSync().map(rt => rt.id).includes(resource.resourceTypeId)
                );

                const resourcesWithActivitiesFiltered = filteredResources.map(resource => {
                    const activities = resource.activities.filter(activity => !this.isActivityHidden(activity, scheduleDays, activityTypeSelectedIds));

                    return {...resource, activities};
                });

                const filteredResourcePropertyIds = this.getFilteredResourcePropertiesSync()
                    .map(rp => rp.resourcePropertyId);

                if (groupingOptionType === GROUPING_OPTIONS.ORGANIZATION_UNIT) {
                    const selectedOrganizationUnits = this.getSelectedOrganizationsSync();
                    const resourcesForOrganizationUnitGrouping = this.scheduleHelperService.getResourcesForOrganizationUnitGrouping(
                        resourcesWithActivitiesFiltered,
                        selectedOrganizationUnits
                    );

                    return this.scheduleHelperService.getSortedResourcesByProperties(
                        resourcesForOrganizationUnitGrouping,
                        filteredResourcePropertyIds
                    );
                }

                if (groupingOptionType === GROUPING_OPTIONS.NO_GROUPING &&
                    this.getShowOptionState(SHOW_OPTIONS.SHOW_ME_ON_TOP)) {
                    const sortedResouces = this.scheduleHelperService.getSortedResourcesByProperties(
                        resourcesWithActivitiesFiltered,
                        filteredResourcePropertyIds
                    );

                    return this.scheduleHelperService.setCurrentUserResourcesOnTop(sortedResouces);
                }

                if (groupingOptionType === GROUPING_OPTIONS.DAY_PART) {
                    const resourcesWithCurrentUserOnTop = this.scheduleHelperService.setCurrentUserResourcesOnTop(
                        resourcesWithActivitiesFiltered
                    );
                    const resourcesForDayPartGrouping = this.scheduleHelperService.getResourcesForDaypartGrouping(
                        resourcesWithCurrentUserOnTop,
                        this.getFilteredExtraResourceTypesSync()
                    );

                    return this.scheduleHelperService.getSortedResourcesByProperties(
                        resourcesForDayPartGrouping,
                        filteredResourcePropertyIds
                    );
                }

                return this.scheduleHelperService.getSortedResourcesByProperties(
                    resourcesWithActivitiesFiltered,
                    filteredResourcePropertyIds
                );
            }),
            cache()
        );
    }

    public getScheduleFiltersValidity(): Observable<boolean> {
        return this.select(state => {
            const ui = state.ui;

            return ui.requestParameters.organizationUnitIds && ui.requestParameters.organizationUnitIds.length > 0 &&
                ui.requestParameters.baseResourceTypeIds && ui.requestParameters.baseResourceTypeIds.length > 0 &&
                ui.requestParameters.resourcePropertyIds && ui.requestParameters.resourcePropertyIds.length > 0 &&
                ui.activityTypeIds && ui.activityTypeIds.length > 0;
        });
    }

    public getShowOptionState(optionValue: SHOW_OPTIONS): boolean {
        if (!this.getShowOptionsSync() || this.getShowOptionsSync().length === 0) {
            return;
        }

        return this.getShowOptionsSync().find(x => x.value === optionValue).state;
    }

    public getAvailableScheduleDays(): Array<Date> {
        const noOfDays = this.getValue().ui.numberOfDays;

        const startDate = new Date(this.getRequestParametersStartDateSync());
        const endDate = new Date(startDate).setDate(startDate.getDate() + noOfDays - 1);

        if (!this.getShowOptionState(SHOW_OPTIONS.SHOW_SAT_SUN)) {
            const daysWithOutWeekEnd = [];
            let noOfDaysWithOutWeekEnd = 0;

            const newDate = new Date(startDate);
            while (noOfDaysWithOutWeekEnd < noOfDays) {
                if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
                    daysWithOutWeekEnd.push(new Date(newDate));
                    noOfDaysWithOutWeekEnd++;
                }

                newDate.setDate(newDate.getDate() + 1);
            }

            return daysWithOutWeekEnd;
        }

        let dates = [];
        const addDate = new Date(startDate);
        while (addDate < new Date(endDate)) {
            dates = [...dates, new Date(addDate)];
            addDate.setDate(addDate.getDate() + 1);
        }
        dates = [...dates, new Date(endDate)];

        return dates;
    }

    public getScheduleFilterSettingsFromState(): ScheduleFilterSettings {
        const state = this.getValue();
        const currentSettings: ScheduleFilterSettings = {
            requestParameters: state.ui.requestParameters,
            showOptions: state.ui.showOptions,
            groupingOptionType: state.ui.groupingOptionType,
            numberOfDays: state.ui.numberOfDays,
            slotMinWidth: state.calendarOptions.slotMinWidth,
            activityDialogState: state.calendarOptions.activityDialogState,
            resourceAreaWidth: state.calendarOptions.resourceAreaWidth,
            activityTypeIds: state.ui.activityTypeIds,
            activityTypeCategoryIds: state.ui.activityTypeCategoryIds,
            savedBaseResourceTypes: state.ui.savedBaseResourceTypes,
            savedExtraResourceTypes: state.ui.savedExtraResourceTypes,
            savedOrganizationUnits: state.ui.savedOrganizationUnits,
            savedResourceProperties: state.ui.savedResourceProperties
        };

        return currentSettings;
    }

    public getNewScheduleFilterSetting(settingName: string, scheduleSettings?: ScheduleFilterSettings): FilterSetting {
        const settings: ScheduleFilterSettings = scheduleSettings ? scheduleSettings : this.getScheduleFilterSettingsFromState();

        return {
            userId: this.userInfoQuery.getUserIdSync(),
            creationDate: moment(new Date()).toISOString(),
            settings: JSON.stringify(settings),
            settingType: FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING,
            displayName: settingName
        };
    }

    public getUpdatedScheduleFilterSetting(): FilterSetting {
        const newSettings: ScheduleFilterSettings = this.getScheduleFilterSettingsFromState();

        const currentFilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        return ({ ...currentFilterSetting, settings: JSON.stringify(newSettings) });
    }

    public getSelectedResourceProperties(): Observable<Array<ResourcePropertyLanguage>> {
        return combineLatest([
            this.select(),
            this.resourcePropertyLanguageQuery.getResourcePropertiesLanguage()
        ]).pipe(
            filter(([_, resourceProperties]) => resourceProperties.length !== 0),
            map(([state, resourceProperties]) =>
                state.ui.requestParameters.resourcePropertyIds
                    ?.map(id => resourceProperties.find(rp => rp.resourcePropertyId === id))
                    .filter(prop => prop !== undefined)
            )
        );
    }

    public getFilteredResourcePropertiesSync(): Array<ResourcePropertyLanguage> {
        const resourceProperties: Array<ResourcePropertyLanguage> = this.resourcePropertyLanguageQuery.getResourcePropertiesLanguageSync();
        const selectedResourceProperties = this.getValue().ui.requestParameters.resourcePropertyIds
            .map(id =>
                resourceProperties.find(rp => rp.resourcePropertyId === id)
            )
            .filter(prop => prop !== undefined);

        return selectedResourceProperties;
    }

    public getExtraResourceTypesForFiltering(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.resourceTypeQuery.getResourceTypes(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            map(([allResources, filteredOrganizationIds]) =>
                allResources.filter(resource => resource.validOrganizationUnitIds
                    .some(id => filteredOrganizationIds.includes(id))
                )
            )
        );
    }

    public getBaseResourceTypesForFiltering(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.resourceTypeQuery.getResourceTypes(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            map(([allResources, filteredOrganizationIds]) =>
                allResources.filter(resource => resource.validOrganizationUnitIds
                    .some(id => filteredOrganizationIds.includes(id))
                )
            )
        );
    }

    public getFilteredBaseResourceTypesSync(): Array<ResourceType> {
        const filteredBaseResourceIds = this.getValue().ui.requestParameters.baseResourceTypeIds;
        const filteredBaseResourceTypes = this.resourceTypeQuery.getResourceTypesSync();

        return filteredBaseResourceTypes.filter(resourceType => filteredBaseResourceIds.includes(resourceType.id));
    }

    public getFilteredExtraResourceTypesSync(): Array<ResourceType> {
        const filteredBaseResourceIds = this.getValue().ui.requestParameters.extraResourceTypeIds;
        const filteredBaseResourceTypes = this.resourceTypeQuery.getResourceTypesSync();

        return filteredBaseResourceTypes.filter(resourceType => filteredBaseResourceIds.includes(resourceType.id));
    }

    public getSelectedBaseResourceTypes(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.select(),
            this.resourceTypeQuery.getResourceTypes()
        ]).pipe(map(([state, resourceTypes]) =>
            resourceTypes.filter(resourceType => state.ui.requestParameters.baseResourceTypeIds.includes(resourceType.id))
        ));
    }

    public getSelectedExtraResourceTypes(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.select(),
            this.resourceTypeQuery.getResourceTypes()
        ]).pipe(map(([state, resourceTypes]) =>
            resourceTypes.filter(resourceType => state.ui.requestParameters.extraResourceTypeIds.includes(resourceType.id))
        ));
    }

    public getActivityTypeSelectedIds(): Observable<Array<number>> {
        return combineLatest([
            this.activityTypeQuery.getActivityTypes(),
            this.getSelectedActivityTypeIds(),
        ]).pipe(
            filter(([activityTypes, _]) => activityTypes !== undefined && activityTypes.length > 0),
            map(([activityTypes, ids]) => {
                return ids ? activityTypes.filter(actType => ids.includes(actType.id)).map(actType => actType.id) :
                    activityTypes.map(actType => actType.id);
            })
        );
    }

    public getSelectedOrganizations(): Observable<Array<OrganizationUnit>> {
        return combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.getSelectedOrganizationUnitIds()]
        ).pipe(
            filter(([_, filteredIds]) => !!filteredIds),
            map(([organizationUnits, filteredIds]) => {
                return organizationUnits.filter(unit => filteredIds.includes(unit.id));
            })
        );
    }

    public getSelectedOrganizationsSync(): Array<OrganizationUnit> {
        const organizationUnits = this.organizationUnitQuery.getOrganizationUnitsSync();
        const selectedOrganizationUnits = organizationUnits.filter(ou =>
            this.getValue().ui.requestParameters.organizationUnitIds.includes(ou.id)
        );

        return selectedOrganizationUnits;
    }

    public getOrganizationIdsToRetrieveResourceTypesSync(): Array<number> {
        const isFullUser = this.userInfoQuery.getIsFullUserSync();
        if (isFullUser) {
            return this.getSelectedOrganizationUnitIdsSync();
        }
        const selectedUnits = this.getSelectedOrganizationsSync();
        const filteredIdsAndParentIds = JSON.parse(JSON.stringify(this.getSelectedOrganizationUnitIdsSync()));
        selectedUnits.map(unit => {
            if (!filteredIdsAndParentIds.includes(unit.parentId) && unit.parentId) {
                filteredIdsAndParentIds.push(unit.parentId);
            }
        });

        return filteredIdsAndParentIds;
    }

    public currentFilterSettingChangesUnsavedSync(): boolean {
        const currentfilterSetting =  this.filterSettingQuery.getSelectedFilterSettingSync();
        const currentSettingsFromState = JSON.stringify(this.getScheduleFilterSettingsFromState());

        if (!currentfilterSetting) {
            return true;
        }

        const resourcePropertiesFromState = this.getScheduleFilterSettingsFromState().requestParameters.resourcePropertyIds;
        const resourcePropertiesFromCurrentFilterSetting = JSON.parse(currentfilterSetting.settings).requestParameters.resourcePropertyIds;

        const showOptionsFromState = this.getValidShowOptions(this.getScheduleFilterSettingsFromState().showOptions);
        const showOptionsFromCurrentFilterSetting = this.getValidShowOptions(JSON.parse(currentfilterSetting.settings).showOptions);

        return !(this.utilsService.getSortedObjectString(this.removeSettingPropertiesFromScheduleFilter(currentfilterSetting.settings)) ===
            this.utilsService.getSortedObjectString(this.removeSettingPropertiesFromScheduleFilter(currentSettingsFromState)) &&
            Object.keys(resourcePropertiesFromCurrentFilterSetting).length === Object.keys(resourcePropertiesFromState).length &&
            Object.keys(resourcePropertiesFromCurrentFilterSetting).every(option =>
                resourcePropertiesFromCurrentFilterSetting[option] === resourcePropertiesFromState[option]
            ) &&
            Object.keys(showOptionsFromCurrentFilterSetting).length === Object.keys(showOptionsFromState).length &&
            showOptionsFromCurrentFilterSetting.every(option =>
                showOptionsFromState.find(so => so.value === option.value).state === option.state
            ));
    }

    private isActivityHidden(activity: ScheduleActivity, scheduleDays: Array<Date>, activityTypeSelectedIds: Array<number>): boolean {
        const saturdayCheck = ((new Date(activity.startTime).getDay() === 6) && !this.getShowOptionState(SHOW_OPTIONS.SHOW_SAT_SUN));
        const sundayCheck = ((new Date(activity.startTime).getDay() === 0) && !this.getShowOptionState(SHOW_OPTIONS.SHOW_SAT_SUN));
        if (saturdayCheck || sundayCheck) {
            return true;
        }

        const activityStartDate = new Date(activity.startTime);
        const activityEndDate = new Date(activity.endTime);

        const startDateCheck = !scheduleDays.find(date =>
            date.getDate() === activityStartDate.getDate() &&
            date.getMonth() === activityStartDate.getMonth() &&
            date.getFullYear() === activityStartDate.getFullYear()
        );
        const endDateCheck = !scheduleDays.find(date =>
            date.getDate() === activityEndDate.getDate() &&
            date.getMonth() === activityEndDate.getMonth() &&
            date.getFullYear() === activityEndDate.getFullYear()
        );
        if (startDateCheck || endDateCheck) {
            return true;
        }

        if (activityTypeSelectedIds && !activityTypeSelectedIds.includes(activity.activityTypeId)) {
            return true;
        }
    }

    private removeSettingPropertiesFromScheduleFilter(settings: string): string {
        const parsedSettings = JSON.parse(settings);

        parsedSettings.requestParameters.startDate = undefined;
        parsedSettings.requestParameters.endDate = undefined;
        parsedSettings.languageCode = undefined;
        parsedSettings.slotMinWidth = undefined;
        parsedSettings.resourceAreaWidth = undefined;
        parsedSettings.activityDialogState = undefined;
        parsedSettings.showOptions = undefined;

        return JSON.stringify(parsedSettings);
    }

    private getValidShowOptions(showOptions: Array<ScheduleShowOption>): Array<ScheduleShowOption> {
        const validShowOptions: Array<ScheduleShowOption> = this.scheduleHelperService.getInitialShowOptions().map(initialShowOption => {
            const matchingShowOption = showOptions.find(opt => opt.value === initialShowOption.value);

            if (matchingShowOption) {
                initialShowOption.state = matchingShowOption.state;
            }

            return initialShowOption;
        });

        return validShowOptions;
    }
}
