import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ResourcePropertyLanguage } from './resource-property-language.model';


export interface ResourcePropertyLanguageState extends EntityState<ResourcePropertyLanguage> {
    ui: {
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource-property-language', idKey: 'resourcePropertyId' })
export class ResourcePropertyLanguageStore extends EntityStore<ResourcePropertyLanguageState> {
    constructor() {
        super(initialState);
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}
