import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SelectionClickEvent } from 'src/app/shared/components/entity-management/entity-list-panel/entity-list-panel.component';

import { OwsActivityTypeTableElement, OWSTreatmentTextTableElement } from './ows-activity-type-store/ows-activity-type.model';
import { OwsActivityTypeQuery } from './ows-activity-type-store/ows-activity-type.query';
import { OwsActivityTypeService } from './ows-activity-type-store/ows-activity-type.service';
import { OwsWorkTreatmentQuery } from './ows-work-treatment-store/ows-work-treatment.query';
import { OwsWorkTreatmentService } from './ows-work-treatment-store/ows-work-treatment.service';

@Component({
    selector: 'app-what-is-work',
    templateUrl: './what-is-work.component.html',
    styleUrls: ['./what-is-work.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatIsWorkComponent implements OnInit {
    public visibleOwsActivityTypes$: Observable<Array<OwsActivityTypeTableElement>>;
    public workTreatmentTexts$: Observable<Array<OWSTreatmentTextTableElement>>;
    public selectedWorkTreatmentTexts$: Observable<Array<string>>;
    public selectedWorkActivityTypes$: Observable<Array<number>>;

    constructor(
        private readonly owsActivityTypeQuery: OwsActivityTypeQuery,
        private readonly owsActivityTypeService: OwsActivityTypeService,
        private readonly owsWorkTreatmentQuery: OwsWorkTreatmentQuery,
        private readonly owsWorkTreatmentService: OwsWorkTreatmentService
    ) { }

    public ngOnInit() {
        this.owsActivityTypeService.get().pipe(first()).subscribe();
        this.owsWorkTreatmentService.get().pipe(first()).subscribe();

        this.visibleOwsActivityTypes$ = this.owsActivityTypeQuery.getOwsActivityTypeTableElements();
        this.workTreatmentTexts$ = this.owsActivityTypeQuery.getAllTreatmentText();
        this.selectedWorkTreatmentTexts$ = this.owsWorkTreatmentQuery.getWorkTreatmentTexts();
        this.selectedWorkActivityTypes$ = this.owsWorkTreatmentQuery.getWorkActivityTypes();
    }

    public onSelectWorkTreatmentText(event: SelectionClickEvent) {
        this.owsWorkTreatmentService.updateWorkTreatment(event.entity.displayName);
    }

    public onSelectOwsActivityType(event: SelectionClickEvent) {
        this.owsWorkTreatmentService.updateWorkActivityType(event.entity.id);
    }
}
