<div class="entity-list-dropdown-container" [attr.data-cy]="field.key">
    <div class="custom-entity-field" [matMenuTriggerFor]="menu">
        {{ props.placeholderText | translate }}
        <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
    </div>
</div>

<mat-menu #menu="matMenu" class="dropdown bol-select">
    <mat-form-field (click)="$event.stopPropagation();" class="bol-form-field dropdown-search" *ngIf="props.entities.length > 0">
        <input
            #input
            matInput
            [formControl]="searchControl"
            placeholder="{{ props.searchFieldPlaceholder ? props.searchFieldPlaceholder : 'Search' | translate }}"
            autocomplete="off"/>
        <button
            mat-button
            matSuffix
            disabled
            class="bol-button-text"
            *ngIf="!searchControl || !searchControl.value || searchControl.value.length === 0">
            <mat-icon class="search-icon">search</mat-icon>
        </button>

        <button
            mat-button
            matSuffix
            class="cancel-button bol-button-text icon-only bol-gray"
            *ngIf="searchControl && searchControl.value && searchControl.value.length > 0"
            (click)="onResetValue()">
            <mat-icon class="search-icon">cancel</mat-icon>
        </button>
    </mat-form-field>

    <div class="items-container">
        <button
            mat-menu-item
            *ngFor="let entity of filteredEntities$ | async"
            [value]="entity.id"
            [disabled]="props.disabled"
            (click)="$event.stopPropagation();">{{ entity.displayName }}</button>

            <div class="no-results-founds" *ngIf="(filteredEntities$ | async)?.length === 0" translate>No results found.</div>
    </div>

</mat-menu>
