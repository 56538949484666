import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { OwsIntervalSchedulingStore } from './ows-interval-scheduling.store';
import { OwsIntervalScheduling } from './ows-interval-scheduling.model';

@Injectable({
    providedIn: 'root'
})
export class OwsIntervalSchedulingService {
    constructor(
        protected owsIntervalSchedulingStore: OwsIntervalSchedulingStore,
        private readonly http: HttpClient,
        private readonly dateTimeUtility: DateTimeUtilityService,
    ) { }

    public get() {
        this.owsIntervalSchedulingStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<OwsIntervalScheduling>>('/api/SystemSchedule/OwsInterfaceSchedulePeriods').pipe(
            map((intervals) => {
                const modifiedIntervals = intervals.map((interval) => {
                    const startMinutesInHours = this.dateTimeUtility.convertMinutesToHours(interval.startMinutes);
                    const endMinutesInHours = this.dateTimeUtility.convertMinutesToHours(interval.endMinutes);
                    const intervalInMinutes = this.convertInMinutesBasedOnIntervalType(interval.intervalType, interval.interval);

                    return { ...interval, startMinutes: startMinutesInHours, endMinutes: endMinutesInHours, interval: intervalInMinutes };
                });

                this.owsIntervalSchedulingStore.set(modifiedIntervals as any);
                this.owsIntervalSchedulingStore.updateEntitiesLoadingState(false);

                return modifiedIntervals;
            })
        );
    }

    public saveInterval(interval: OwsIntervalScheduling): Observable<OwsIntervalScheduling> {
        const startMinutesInHours = this.dateTimeUtility.convertHoursToMinutes(interval.startMinutes as any);
        const endMinutesInHours = this.dateTimeUtility.convertHoursToMinutes(interval.endMinutes as any);
        interval = { ...interval, startMinutes: startMinutesInHours, endMinutes: endMinutesInHours };

        return this.http.put<OwsIntervalScheduling>('/api/SystemSchedule/OwsInterfaceSchedulePeriod', interval).pipe(
            map((int) => {
                this.owsIntervalSchedulingStore.upsert(int.id, int);

                return int;
            })
        );
    }

    public updateInterval(interval: OwsIntervalScheduling, lastCorrectVersionOfInterval: OwsIntervalScheduling): Observable<void> {
        const startMinutesInHours = this.dateTimeUtility.convertHoursToMinutes(interval.startMinutes as any);
        const endMinutesInHours = this.dateTimeUtility.convertHoursToMinutes(interval.endMinutes as any);
        interval = { ...interval, startMinutes: startMinutesInHours, endMinutes: endMinutesInHours };

        return this.http.post<void>('/api/SystemSchedule/OwsInterfaceSchedulePeriod', interval).pipe(
            catchError(() => {
                return throwError(lastCorrectVersionOfInterval);
            }),
            tap(() => {
                this.owsIntervalSchedulingStore.upsert(interval.id, interval);
            }),
        );
    }

    public deleteInterval(intervalId: string): Observable<void> {
        this.owsIntervalSchedulingStore.updateEntitiesLoadingState(true);
        const id = { id: intervalId };

        return this.http.delete<any>('/api/SystemSchedule/OwsInterfaceSchedulePeriod', { body: id }).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            tap((response) => {
                this.owsIntervalSchedulingStore.updateEntitiesLoadingState(false);
                if (response === null) { // no error was thrown
                    this.owsIntervalSchedulingStore.remove(intervalId);
                }
            }),
        );
    }

    private convertInMinutesBasedOnIntervalType(intervalType: number, interval: number): number {
        return intervalType === 1 ? interval * 60 : interval;
    }
}
