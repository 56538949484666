import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface BreadcrumbItem {
    caption: string;
    route?: string;
    dynamicPart?: string;
}

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    public breadcrumbs$: Observable<Array<BreadcrumbItem>>;
    private readonly breadcrumbsSubject = new BehaviorSubject<Array<BreadcrumbItem>>([]);

    constructor() {
        this.breadcrumbs$ = this.breadcrumbsSubject.asObservable();
    }

    public setBreadcrumbs(breadcrumbs: Array<BreadcrumbItem>): void {
        if (!breadcrumbs) {
            breadcrumbs = [];
        }
        this.breadcrumbsSubject.next(breadcrumbs);
    }

    public getBreadcrumbs(): Observable<Array<BreadcrumbItem>> {
        return this.breadcrumbsSubject.asObservable();
    }
}
