import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-bolster-input',
    templateUrl: './formly-bolster-input.component.html',
    styleUrls: ['./formly-bolster-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyBolsterInput extends FieldType implements OnInit {
    @Input() public type: string;
  
    public ngOnInit(): void {
        this.type = this.type || 'text';
    }
}