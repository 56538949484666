/* eslint-disable radix */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { of } from 'rxjs';
import { fieldNonEmptyValidatorKey } from '@ortec/soca-web-ui/validators';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { FILTER_TYPE, FilterEntityManageGridColumn } from 'src/app/shared/components/entity-management/entity-manage-grid/entity-manage-grid.model';
import { ManageTemplate } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { UtilsService } from 'src/app/shared/services/utils.service';

export function getManageTemplatesFormFields(): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'lengthInWeeks',
            type: 'bolster-input',
            props: {
                label: 'Length in weeks',
                required: true,
                min: 1, 
                max: 53,
                type: 'number',
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey],
                integerValueValidator: (control: AbstractControl): ValidationErrors | null => {
                    const lengthInWeeksValue = control.value;
                    if (Number.isInteger(lengthInWeeksValue)) {
                        return { 'integerValueValidator': true };
                    }             
            
                    return null;
                }
            },
            validation: {
                messages: {
                    integerValueValidator: 'VALIDATORS.WEEK-LENGTH-1-53',
                }
            }
        }
    ];
}

export function getManageTemplatesForCloningFormFields(): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        }
    ];
}


export function getManageTemplateGridColumns(lengthInWeeks: any, utilsService: UtilsService): Array<FilterEntityManageGridColumn> {
    return [
        {
            columnDef: 'displayName',
            header: 'Display name',
            filterType: FILTER_TYPE.SEARCH,
            cell: (template: ManageTemplate) => `${template.displayName}`,
        },
        {
            columnDef: 'lengthInWeeks',
            header: 'Length in weeks',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: { name: 'Length in weeks', entities: lengthInWeeks, selectedIds$: of([])},
            cell: (template: ManageTemplate) => `${template.lengthInWeeks}`,
            sortValue: (template: ManageTemplate) => `${template.lengthInWeeks}`,
        },
        {
            columnDef: 'isUsedOnActivityPlan',
            header: 'Used in plan',
            filterType: FILTER_TYPE.MULTISELECT,
            filterOptions: {name: 'Used in plan', entities: utilsService.getYesNoSearchValues(), selectedIds$: of([]), translationNeeded: true},
            cell: () => '',
            icon: (template: ManageTemplate) => { 
                if (template.isUsedOnActivityPlan) {
                    return 'check';
                }    
            },
            sortValue: (template: ManageTemplate) => `${template.isUsedOnActivityPlan}`,
        },
    ];
}


