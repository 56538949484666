import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { EntityListColumnDefinition, SelectionCheckBoxClickEvent, filterUndefined } from '@ortec/soca-web-ui';
import { filter, first, map, startWith, take, tap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Memoized } from '@ortec/utilities/core';

import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { IFilterChipListObject } from 'src/app/shared/components/inputs/filter-chip-list/filter-chip-list.component';
import { ResourcePropertyLanguageService } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.service';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { LanguageService } from 'src/app/shared/language';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

import { FilteringService } from '../filtering/store/filtering.service';
import { FilteringQuery } from '../filtering/store/filtering.query';
import { SortingService } from './store/sorting.service';
import { SortingQuery } from './store/sorting.query';
import { SortingHelperService } from './sorting-helpers/sorting-helpers.service';
import { SortableResource } from './store/sorting.model';

@Component({
    selector: 'app-sorting',
    templateUrl: './sorting.component.html',
    styleUrls: ['./sorting.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortingComponent implements OnInit, OnDestroy {
    public columnDefinition: Array<EntityListColumnDefinition> =  [{
        columnDisplayName: 'Display name',
        entityProperty: 'displayName'
    }];
    public searchProperties: Array<string> = ['displayName'];

    private readonly subscription: Subscription = new Subscription();
    
    constructor(
        private readonly filteringService: FilteringService,
        private readonly filteringQuery: FilteringQuery,
        private readonly autosaveService: AutosaveService,
        private readonly sortingService: SortingService,
        private readonly sortingQuery: SortingQuery,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly languageService: LanguageService,
        private readonly resourcePropertyLanguageService: ResourcePropertyLanguageService,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly sortingHelpersService: SortingHelperService,
        private readonly displaySettingQuery: DisplaySettingQuery,
        private readonly displaySettingService: DisplaySettingService,
    ){}

    public ngOnInit(): void {
        this.subscription.add(
            this.setCurrentLanguageResourceProperties()
        );
        this.filteringService.getResourcePropertySelection().pipe(first()).subscribe();
        this.resourceTypeService.getResourceTypesNewApi().pipe(first()).subscribe();

        this.setInitialStatesFromDisplaySettings();
    }
    
    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
        this.sortingService.resetStore();
    }

    public onFilteredResourcePropertiesChanged(ids): void {
        this.sortingService.updateSelectedResourcePropertiesIds(ids);
        this.sortingService.updatePageStatusState(STATUS.IS_LOADING);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.PLANBOARD_RESOURCE_PROPERTY_ID, ids).pipe(
            first(),
            tap(() => this.sortingService.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        ).subscribe();
    }

    public onSortDescending(event: MatCheckboxChange) : void {
        this.sortingService.updateSortDescending(event.checked);
        this.sortingService.updatePageStatusState(STATUS.IS_LOADING);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.SORT_DESCENDING, event.checked).pipe(
            first(),
            tap(() => this.sortingService.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        ).subscribe();
    }

    public onSelectedResourcesChange(ids: Array<number>): void {
        this.sortingService.updateSelectedSortableResourceIdsList(ids);
    }

    public onReorderdEntitiesChanged(resourceIds: Array<number>): void {
        this.sortingService.setSelectedSortableResourcesIds(resourceIds, true, true);
        this.sortingService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
        this.autosaveService.autoSave(resourceIds, this.updateSelectedSortedResourceIds.bind(this));
    }

    public onSelectedResourceTypeChange(id: number): void {
        this.sortingService.updateSelectedResourceTypeId(id);
        this.sortingService.getResources();
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.PLANBOARD_RESOURCE_SELECTION_ID, id).pipe(first()).subscribe();
    }

    public updateResourceStates(event: SelectionCheckBoxClickEvent): void {
        this.sortingService.updateSelectedUnsortedResourcesIdsList(event.entityIds as Array<number>);
    }

    public moveToSortableList(): void {
        const unsortedResourcesIdsList = this.sortingQuery.getSelectedUnsortedResourcesIdsListSync();
        this.sortingService.setSelectedSortableResourcesIds(unsortedResourcesIdsList, true);
        this.updateSelectedSortedResourceIds();
        this.sortingService.updateSelectedUnsortedResourcesIdsList([]);
    }

    public moveToUnsortedList(): void {
        const sortedResourcesIds = this.sortingQuery.getSelectedSortableResourceIdsListSync();
        this.sortingService.setSelectedSortableResourcesIds(sortedResourcesIds, false);
        this.updateSelectedSortedResourceIds();
        this.sortingService.updateSelectedSortableResourceIdsList([]);
    }

    @Memoized public get initialLoadingFinished$ (): Observable<boolean> {
        return combineLatest([
            this.sortingQuery.getEntitiesResourcesLoading(),
            this.sortingQuery.getEntitiesSelectedResourcesLoading(),
            this.resourcePropertyLanguageQuery.getEntitiesLoadingState(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([resourcesLoading, selectedResourcesLoading, resourcePropertiesLoading, resourceTypeLoading]) => {
                return !resourcesLoading && !selectedResourcesLoading && !resourcePropertiesLoading && !resourceTypeLoading;
            }),
            map(() => true),
            take(1),
            startWith(false)
        );
    }
    
    @Memoized public get allResourceProperties$(): Observable<Array<IFilterChipListObject>> {
        return combineLatest([
            this.resourcePropertyLanguageQuery.getResourcePropertiesLanguage(),
            this.filteringQuery.getSelectedVisibleColumns(),
        ]).pipe(
            map(([resourceProperties, selectedResourcePropertiesForFiltering]) => {
                // Filter the resourceProperties array based on selectedResourcePropertiesForFiltering and having the Display name and organization unit option also
                const filteredProperties = resourceProperties.filter(rp => selectedResourcePropertiesForFiltering.includes(rp.resourcePropertyId) || rp.resourcePropertyId < 0);
          
                const transformedProperties = filteredProperties.map(rp => ({
                    id: rp.resourcePropertyId,
                    displayName: rp.text,
                }));
          
                return transformedProperties;
            })
        );
    }

    @Memoized public get unsortableResources$(): Observable<Array<SortableResource>> {
        return this.sortingQuery.getUnsortableResources();
    }

    @Memoized public get sortableResources$(): Observable<Array<SortableResource>> {
        return this.sortingQuery.getSortableResources();
    }

    @Memoized public get selectedResourceTypeId$(): Observable<number> {
        return this.sortingQuery.getSelectedResourceTypeId();
    }

    @Memoized public get isMoveToSortableListEnabled$(): Observable<boolean> {
        return this.sortingQuery.getSelectedUnsortedResourcesIdsListState();
    }

    @Memoized public get isMoveToUnsortedListEnabled$(): Observable<boolean> {
        return this.sortingQuery.getSelectedSortedResourcesIdsListState();
    }

    @Memoized public get selectedSortableResourceIds$(): Observable<Array<number>> {
        return this.sortingQuery.getSelectedSortableResourceIdsList();
    }

    @Memoized public get resourceTypesForFiltering$(): Observable<Array<ResourceType>> {
        return this.resourceTypeQuery.getMutableResources();
    }

    @Memoized public get selectedResourcePropertiesLength$(): Observable<number> {
        return this.sortingQuery.getSelectedResourcePropertiesIds().pipe(map(rp => rp ? rp.length : undefined));
    }

    @Memoized public get selectedResourceProperties$(): Observable<Array<IFilterChipListObject>> {
        return combineLatest([
            this.sortingQuery.getSelectedResourcePropertiesIds(),
            this.filteringQuery.getSelectedVisibleColumns(),
            this.resourcePropertyLanguageQuery.getResourcePropertiesLanguage()
        ]).pipe(
            filter(([_, __, resourceProperties]) => resourceProperties.length > 0),
            map(([resourcePropertiesIds, resourcePropertyIdsFromFiltering, resourceProperties]) => {
                //we filter the resource property ids based on what we have selected also in the Filtering screen
                const filteredPropertiesIds = resourcePropertiesIds.filter(rpId => resourcePropertyIdsFromFiltering.includes(rpId) || rpId < 0);
                const filteredProperties = resourceProperties.filter(rp => filteredPropertiesIds.includes(rp.resourcePropertyId));
                const sortedProperties = resourcePropertiesIds.map(id => {
                    const matchingProperty = filteredProperties.find(rp => rp.resourcePropertyId === id);

                    return matchingProperty ? { id: matchingProperty.resourcePropertyId, displayName: matchingProperty.text } : null;
                }).filter(property => property !== null);

                return sortedProperties;
            })
        );
    }

    @Memoized public get sortDescending$(): Observable<boolean> {
        return this.sortingQuery.getSortDescending();
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return this.sortingQuery.getStatusPageState();
    }
    
    private updateSelectedSortedResourceIds(): void {
        const allSortableResourcesIds = this.sortingQuery.getSelectedSortableResourcesIdsSync();
        const selectedResourceType = this.sortingQuery.getSelectedResourceTypeIdSync();
        this.sortingService.updateSelectedSortedResourceIds(selectedResourceType, allSortableResourcesIds).pipe(first()).subscribe();
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.PLANBOARD_RESOURCE_SELECTION_ID, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => { this.sortingService.updateSelectedResourceTypeId(id); this.sortingService.getResources(); })
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<Array<number>>(DISPLAY_SETTING_NAMES.PLANBOARD_RESOURCE_PROPERTY_ID, 'array').pipe(
            filterUndefined(), 
            first(),
            tap(ids => { this.sortingService.updateSelectedResourcePropertiesIds(ids); })
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.SORT_DESCENDING, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => {
                this.sortingService.updateSortDescending(state);
            })
        ).subscribe();
    }

    private setCurrentLanguageResourceProperties(): Subscription {
        return this.languageService.currentLanguage$.subscribe((lang) => {
            const standardResporceProperties = this.sortingHelpersService.getStandardResourceProperties();
            this.resourcePropertyLanguageService.getResourcePropertiesByLanguageCode(lang.codeDto, standardResporceProperties).pipe(first()).subscribe();
        })
    }
}