import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'app-formly-tree-single-select',
    templateUrl: './formly-tree-single-select.component.html',
    styleUrls: ['./formly-tree-single-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTreeSingleSelectComponent extends FieldType implements OnInit {
    public preselectedEntityId: Observable<number>;

    public ngOnInit(): void {
        const initialSelectedId = this.model[this.field.key as string];

        this.preselectedEntityId = this.field.formControl.valueChanges.pipe(
            startWith(initialSelectedId)
        );
    }

    public onSelectedEntityChange(id: number): void {
        this.formControl.setValue(id);
    }
}