import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BolTimeSelectModule } from '@ortec/bolster/time-select';
import { SocaWebUiCommonModule } from '@ortec/soca-web-ui';

import { SharedModule } from 'src/app/shared/shared.module';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';

import { OwsRoutingModule } from './ows-interface-routing';
import { OwsInterfaceComponent } from './ows-interface.component';
import { OwsDepartmentMappingComponent } from './ows-department-mapping/ows-department-mapping.component';
import { OwsActivityTypeMappingComponent } from './ows-activity-type-mapping/ows-activity-type-mapping.component';
import { OwsActivityTypeMappingOldComponent } from './ows-activity-type-mapping-old/ows-activity-type-mapping.component';
import { OwsInterfaceSchedulingComponent } from './ows-interface-scheduling/ows-interface-scheduling.component';
import { OwsResourceTypeMappingComponent } from './ows-resource-mapping/ows-resource-type-mapping.component';
import { WhatIsWorkComponent } from './what-is-work/what-is-work.component';
import { OwsSkillMappingComponent } from './ows-skill-mapping/ows-skill-mapping.component';
import { ResourceActivityImportItemComponent } from './ows-interface-scheduling/components/resource-activity-import-item/resource-activity-import-item.component';
import { LoadingStatusIndicationComponent } from 'src/app/shared/components/loading-status-indication/loading-status-indication.component';
import { OwsOtherSettingsComponent } from './ows-other-settings/ows-other-settings.component';

@NgModule({
    declarations: [
        OwsInterfaceComponent,
        OwsDepartmentMappingComponent,
        OwsActivityTypeMappingComponent,
        OwsActivityTypeMappingOldComponent,
        OwsDepartmentMappingComponent,
        OwsInterfaceSchedulingComponent,
        OwsResourceTypeMappingComponent,
        OwsSkillMappingComponent,
        WhatIsWorkComponent,
        ResourceActivityImportItemComponent,
        OwsOtherSettingsComponent,
        
    ],
    imports: [
        CommonModule,
        HttpClientModule,

        BolTimeSelectModule,

        OwsRoutingModule,
        SharedModule,
        SocaWebUiCommonModule,
        MultiselectTreeComponent,
        LoadingStatusIndicationComponent
    ]
})
export class OwsInterfaceModule { }
