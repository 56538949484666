<div *ngIf="dataLoaded$ | async" class="content-wrapper">
    <div *ngIf="interfaceConfig$ | async as interfaceConfig" class="card-content">
        <div class="content-column">
            <div>
                <mat-card  class="bol-card" data-cy="ResourceImportsHolder">
                    <mat-card-content>
                        <div>
                            <div>
                                <h3 class="resource-activity-title" translate>Resource and Activity Import</h3>
                            </div>
                            
                            <div class="add-intervals">
                                <button
                                    mat-button
                                    data-cy="addIntervalButton"
                                    (click)="showAddIntervalContainer()"
                                    class="bol-button-solid bol-positive"
                                    [disabled]="addInterval$ | async">
                                    <div translate>Add interval</div>
                                </button>
                            </div>
                    
                            <div
                                class="intervals-list-container"
                                *ngIf="((intervals$ | async).length > 0) || (addInterval$ | async)">
                                <div class="intervals-list-header interval-row">
                                    <div class="interval-start" translate>
                                        Start time
                                    </div>
                                    <div class="interval-end" translate>
                                        End time
                                    </div>
                                    <div class="interval" translate>
                                        Interval (min)
                                    </div>
                                </div>
                    
                                <div *ngFor="let interval of (intervals$ | async)">
                                    <app-resource-activity-import-item
                                        [ngModel]="interval"
                                        [intervals]="intervals$ | async"
                                        (removeIntervalEvent)="onDeleteInterval($event)"
                                        (updateIntervalEvent)="onUpdateInterval($event)"
                                    ></app-resource-activity-import-item>
                                </div>
                                
                                <div *ngIf="addInterval$ | async">
                                    <app-resource-activity-import-item
                                        [ngModel]="newInterval"
                                        [intervals]="intervals$ | async"
                                        [addInterval]="true"
                                        (removeIntervalEvent)="resetAddIntervalContainer()"
                                        (addIntervalEvent)="onAddInterval($event)"
                                    ></app-resource-activity-import-item>
                                </div>
                            </div>    
                            <div
                                class="empty-state"
                                *ngIf="showEmptyState$ | async">
                                <mat-icon>timelapse</mat-icon>
                                <div class="empty-state-description" translate>
                                    No intervals available yet
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                   
            </div>
        </div>
    
        <div class="column__full-width">
            <mat-card  class="bol-card card__full-height">
                <h3 translate class="other-settings-title">Other Settings</h3>
                <mat-card-content class="bol-card-content">
                    <p>
                        <mat-form-field class="bol-form-field">
                            <mat-label translate>Instance name</mat-label>
                            <input matInput
                                placeholder="{{'name' | translate}}" 
                                [(ngModel)]="interfaceConfig.instanceName"
                                data-cy="InstanceName"
                                (change)="onConfigurationChange('instanceName', interfaceConfig.instanceName)">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field class="bol-form-field">
                            <mat-label translate>Read data from x days in the past</mat-label>
                            <input matInput 
                                placeholder="{{ 'amount of days in past' | translate }}"
                                [formControl]="formControls.daysInPast"
                                data-cy="ReadDataFromPast" 
                                type="number">
                            <mat-error *ngIf="formControls.daysInPast.invalid" translate>
                                {{ errorMessage }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field class="bol-form-field">
                            <mat-label translate>Read data up to x days in the future</mat-label>
                            <input matInput 
                                placeholder="{{ 'amount of days in future' | translate }}"
                                [formControl]="formControls.daysInFuture"
                                data-cy="ReadDataFromFuture" 
                                type="number">
                            <mat-error *ngIf="formControls.daysInFuture.invalid" translate>
                                {{ errorMessage }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-checkbox class="bol-checkbox" 
                            [(ngModel)]="interfaceConfig.miscSettings.weekend"
                            data-cy="MarkWeekendsAsAbsences"
                            (change)="onConfigurationChange('miscSettings.weekend', interfaceConfig.miscSettings.weekend)">
                            <span translate>Mark weekends as absences</span>
                        </mat-checkbox>
                    </p>
                    <p>
                        <mat-checkbox class="bol-checkbox" 
                            [(ngModel)]="interfaceConfig.miscSettings.deleteOld"
                            data-cy="RemovePastAbsences"
                            (change)="onConfigurationChange('miscSettings.deleteOld', interfaceConfig.miscSettings.deleteOld)">
                            <span translate>Remove past absences</span>
                        </mat-checkbox>
                    </p>
                    <p *ngIf="interfaceConfig.miscSettings.deleteOld">
                        <mat-form-field class="bol-form-field">
                            <mat-label translate>Remove absences older than</mat-label>
                            <input matInput 
                                placeholder="{{ 'amount of days old' | translate }}"
                                [formControl]="formControls.daysOld"
                                data-cy="RemovePastAbsencesInDays" 
                                type="number">
                            <mat-error *ngIf="formControls.daysOld.invalid" translate>
                                {{ errorMessage }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>