import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ActivityType } from './activity-type.model';
import { ActivityTypeTimeslotInfo } from '../manage-template-store/manage-template.model';

export interface ActivityTypeState extends EntityState<ActivityType> {
    ui: {
        selectedId: number;
        entitiesLoading: boolean;
    };
    activityTypeMap: Map<number, ActivityType>,
    activityTypeStructureAndSorting: Map<number, Array<ActivityType>>
}

const initialState = {
    ui: {
        selectedId: 0,
        entitiesLoading: false,
    },
    activitiesMap: undefined,
    activityTypeStructureAndSorting: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-type' })
export class ActivityTypeStore extends EntityStore<ActivityTypeState> {
    constructor() {
        super(initialState);
    }

    public updateActivityTypeMap(activityTypes: Array<ActivityType>): void {
        this.update(state => {
            const activityTypeMap = new Map(activityTypes.map(at => [at.id, at]));

            return { ...state, activityTypeMap };
        });
    }

    public updateActivityTypeStructureAndSorting(activityTypeStructureAndSorting: Map<number, Array<ActivityType>>): void {
        this.update(state => {
          
            return { ...state, activityTypeStructureAndSorting };
        });
    }
    
    // TODO: use this to map permissions?
    public selectActivityType(id: number) {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedId: id
            };

            return { ...state, ui };
        });
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}
