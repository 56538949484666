import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { OwsDepartment } from './ows-department.model';

export interface OwsDepartmentState extends EntityState<OwsDepartment> {
    ui: {
    };
}

const initialState = {
    ui: {
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-department' })
export class OwsDepartmentStore extends EntityStore<OwsDepartmentState> {
    constructor() {
        super(initialState);
    }
}
