<div class="event-container"
    (click)="(onOpenDetailDialog())"
    [style.maxHeight]="allLeaves.length * 20 + 'px'">
    <ng-container *ngFor="let act of allLeaves; let last = last">
        <div class="event-element"
            [bolPopoverHover]="popoverHover"
            [bolPopoverDisabled]="!hasMemo && !hasDescription"
            [class.has-memo]="hasMemo && last">
            {{act?.name}}
        </div>
    </ng-container>
</div>

<ng-template #popoverHover>
    <div class="activity-popover">
        <div *ngFor="let act of activitiesWithMemoAndDescription; let last = last; let first = first" [class.activity-details]="!last && !!act.memo">
            <div class="bol-text bol-text-bold" [ngClass]="{'description-row': !!act?.description || !!act?.memo}" *ngIf="!!act?.description && first">{{ act?.description }}</div>
            <div class="resource-name-row" *ngIf="!!act?.memo">{{ act?.resource }}</div>
            <div class="memo-row bol-text" *ngIf="!!act?.memo">{{ act?.memo }}</div>
        </div>
    </div>
</ng-template>