<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column" >
            <app-entity-filter-panel>
                <app-resource-types-filters></app-resource-types-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                <app-entity-list-panel
                    *ngIf="initialLoadingFinished$ | async"
                    entityName="Resource types"
                    [entities]="filteredResourceTypes$ | async"
                    [mode]="manageMode$ | async"
                    [searchProperties]="searchProperties"
                    [loadingError]="loadingError$ | async"
                    [selectedEntityId]="selectedResourceTypeId$ | async"
                    (selectedEntityIdChanged)="onSelectedResourceTypeChange($event)"
                    (addEntityClicked)="onAddNewResourceType()">
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Resource type"
                    canClone="true"
                    [mode]="manageMode$ | async"
                    [formFields]="formFields"
                    [model]="selectedResourceType$ | async"
                    [uiEntity]="selectedUIResourceType$ | async"
                    (cancelAdd)="onCancelAdd()"
                    [canDelete]="canDeleteSelectedEntity$ | async"
                    (deleteEntity)="onDeleteResourceType($event)"
                    (cloneEntity)="onCloneResourceType($event)"
                    (editEntity)="onEditResourceType($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>


<div class="overlay-spinner" *ngIf="((initialLoadingFinished$ | async) === false) || (showLoadingSpinner$ | async)">
    <mat-spinner diameter="100"></mat-spinner>
</div>

