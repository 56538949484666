import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Scenario } from './scenario.model';

export interface ScenarioState extends EntityState<Scenario> {
    ui: {
        selectedId: number;
        entitiesLoading: boolean;
    };
}

const initialState = {
    ui: {
        selectedId: 0,
        entitiesLoading: false,
    }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'scenario' })
export class ScenarioStore extends EntityStore<ScenarioState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedScenarioId(id: number) {
        this.update(state => {
            const ui = {
                ...state.ui,
                selectedId: id
            };

            return { ...state, ui };
        });
    }

    public updateEntitiesLoadingState(loading: boolean) {
        this.update(state => {
            const entitiesLoading = loading;
            const ui = { ...state.ui, entitiesLoading };

            return { ...state, ui };
        });
    }
}
