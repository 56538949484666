import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';

import { GROUPING_OPTIONS, SCHEDULE_STORAGE_KEYS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';

@Component({
    selector: 'app-schedule-filters-header-old',
    templateUrl: './schedule-filters-header.component.html',
    styleUrls: ['./schedule-filters-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleFiltersHeaderOldComponent implements OnInit, OnDestroy {
    @ViewChild('filterSettingSelect') public filterSettingSelect!: MatSelect;
    @Output() public readonly hideFiltersPanelOnSelectedFilterSetting = new EventEmitter<undefined>();

    public filterSettings$!: Observable<Array<FilterSetting>>;
    public selectedFilterSettingId$!: Observable<number>;
    public selectedGroupingOptionType$!: Observable<GROUPING_OPTIONS>;

    public filterSettingControl = new UntypedFormControl('', [Validators.required]);

    private readonly subscription = new Subscription();

    constructor(
        private readonly filterSettingService: FilterSettingService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly scheduleService: ScheduleService,
    ) { }

    public ngOnInit(): void {
        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING).pipe(
            map(filters => filters.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
        );
        this.selectedFilterSettingId$ = this.filterSettingQuery.getSelectedFilterSettingId();
        this.selectedGroupingOptionType$ = this.scheduleQuery.getGroupingOptionType();

        this.setCurrentFilterSetting();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openedFilterSettingSelect(state: boolean): void {
        if (state) {
            return;
        }

        const id = this.filterSettingControl.value;
        if (this.filterSettingQuery.getSelectedFilterSettingIdSync() !== id) {
            this.filterSettingService.updateSelectedFilterSettingsId(id);
            this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTER_SETTING_ID, id);

            this.getScheduleDataOnFilterChange();
        }
    }

    private setCurrentFilterSetting(): void {
        this.subscription.add(
            combineLatest([
                this.selectedFilterSettingId$,
                this.filterSettingQuery.getFilterSettings()
            ]).subscribe(([id, filterSettings]) => {
                if (filterSettings && filterSettings.length > 0 && !id) {
                    const filterSettingIdFromStorage = this.scheduleHelperService.getScheduleItemFromStorage<number>(SCHEDULE_STORAGE_KEYS.FILTER_SETTING_ID);
                    if (!!filterSettingIdFromStorage && filterSettings.some(setting => setting.id === filterSettingIdFromStorage)) {
                        this.setFilterSettingId(filterSettingIdFromStorage);
                    }
                    else {
                        const firstFilterId = this.filterSettingQuery.getFirstFilterSettingIdAvailableByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING);
                        this.setFilterSettingId(firstFilterId, this.filterSettingQuery.getFilterSettingsByTypeSync(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING)?.length !== 1);
                    }
                }

                if (id) {
                    this.filterSettingControl.setValue(id, { emitEvent: false });
                }
            })
        );
    }

    private setFilterSettingId(id: number, updateScheduleData: boolean = true) {
        this.filterSettingControl.setValue(id, { emitEvent: false });
        this.filterSettingService.updateSelectedFilterSettingsId(id);

        if (updateScheduleData) {
            this.getScheduleDataOnFilterChange();
        }
    }

    private getScheduleDataOnFilterChange() {
        this.scheduleQuery.getRequestParameters().pipe(
            filter(() => this.scheduleQuery.getScheduleFiltersValiditySync()),
            first(),
            switchMap((requestParameters) => {
                return this.scheduleService.get(requestParameters).pipe(
                    first(),
                    tap(() => this.hideFiltersPanelOnSelectedFilterSetting.emit())  
                );
            })
        ).subscribe();
    }
}
