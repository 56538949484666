import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Scenario } from './scenario.model';
import { ScenarioStore } from './scenario.store';

@Injectable({
    providedIn: 'root'
})
export class ScenarioService {
    constructor(
        protected scenarioStore: ScenarioStore,
        private readonly http: HttpClient,
    ) { }

    public getScenarios(): Observable<Array<Scenario>> {
        this.scenarioStore.updateEntitiesLoadingState(true);

        return this.http.get<Array<Scenario>>('/api/Scenarios').pipe(
            tap((scenarios) => {
                this.scenarioStore.set(scenarios);
            }),
            tap(() => this.scenarioStore.updateEntitiesLoadingState(false))
        );
    }

    public updateSelectedScenarioId(id: number) {
        this.scenarioStore.updateSelectedScenarioId(id);
    }
}
